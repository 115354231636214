import React, { PureComponent } from 'react';
import { Button, DatePickerRange } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { func, object, array, bool } from 'prop-types';

import Page from 'App/components/Page';
import { closePreviewTask } from 'App/components/CommonOverlays/PreviewTask';
import Filters, { FiltersServiceFactory } from 'App/components/Filters';
import { AddEditTask, ADD_EDIT_SIDEPANEL_ID } from 'Tasks/components/sidepanels';
import { openOverlay, closeOverlay } from 'App/services/overlayService';
import TasksAccordion from 'Tasks/components/TasksAccordion';
import Loader from 'App/components/Loader';

import './TasksPage.scss';

const propTypes = {
  date: object.isRequired,
  setDateQuery: func.isRequired,
  fetchTasks: func.isRequired,
  activeFilters: array.isRequired,
  resetFilters: func.isRequired,
  fetchTasksOptions: func.isRequired,
  overDue: object.isRequired,
  pastDue: object.isRequired,
  upcoming: object.isRequired,
  today: object.isRequired,
  resetAccordions: func.isRequired,
  filtersStore: object.isRequired,
  loadingOptions: bool.isRequired,
  addNewFilter: func.isRequired,
  match: object.isRequired,
};

const text = {
  overDue: 'Overdue tasks',
  today: 'Today',
  upcoming: 'Upcoming',
  pastDue: 'Past due',
  TITLE: 'Tasks',
};

const bread = [{ label: 'Tasks', route: '/tasks' }];

const accordionId = {
  overDue: 'overDue',
  pastDue: 'pastDue',
  today: 'today',
  upcoming: 'upcoming',
};

const filtersService = FiltersServiceFactory('/tasks/');

class TasksPage extends PureComponent {
  componentDidMount() {
    this.fetchTasksOptionsData();
    const { match } = this.props;
    const { action } = match.params;
    if (action) {
      openOverlay(ADD_EDIT_SIDEPANEL_ID, {
        type: 'add',
        onSuccess: this.fetchAllTasks,
      });
    }
  }

  componentWillUnmount() {
    this.resetFilters();
    closePreviewTask();
    closeOverlay(ADD_EDIT_SIDEPANEL_ID);
  }

  headerActions = () => (
    <Button
      onClick={() => openOverlay(ADD_EDIT_SIDEPANEL_ID, {
        type: 'add',
        onSuccess: this.fetchAllTasks,
      })
      }
    >
      Create task
    </Button>
  );

  applyDateFilter = (date) => {
    const { setDateQuery } = this.props;
    setDateQuery(date);
    this.fetchAllTasks();
  };

  resetFilters = () => {
    const { resetFilters } = this.props;
    resetFilters();
  };

  fetchOverDueTasks = async (resetData) => {
    const { fetchTasks } = this.props;
    await fetchTasks(accordionId.overDue, resetData);
  };

  fetchPastDueTasks = async (resetData) => {
    const { fetchTasks } = this.props;
    await fetchTasks(accordionId.pastDue, resetData);
  };

  fetchTodayTasks = async (resetData) => {
    const { fetchTasks } = this.props;
    await fetchTasks(accordionId.today, resetData);
  };

  fetchUpcomingTasks = async (resetData) => {
    const { fetchTasks } = this.props;
    await fetchTasks(accordionId.upcoming, resetData);
  };

  fetchAllTasks = async (resetData) => {
    const { resetAccordions } = this.props;
    resetAccordions();
    await Promise.all([
      this.fetchPastDueTasks(resetData),
      this.fetchTodayTasks(resetData),
      this.fetchUpcomingTasks(resetData),
      this.fetchOverDueTasks(resetData),
    ]);
  };

  fetchTasksOptionsData = async () => {
    const { fetchTasksOptions } = this.props;
    await fetchTasksOptions();
  };

 filterAction = action => async (value) => {
   action(value);
   this.fetchAllTasks();
 };

 render() {
   const {
     activeFilters,
     date,
     overDue,
     today,
     pastDue,
     upcoming,
     filtersStore,
     loadingOptions,
   } = this.props;
   return (
     <Page
       headerActions={this.headerActions}
       bread={bread}
       title={text.TITLE}
       style={{ overflow: 'none' }}
     >
       <div className="fiq-tasks">
         <div className="fiq-tasks__filters">
           <Filters
             activeFilters={activeFilters}
             filtersService={filtersService}
             fetchData={this.fetchAllTasks}
             filtersStore={filtersStore}
             applyFilter={this.filterAction(filtersStore.addNewFilter)}
             viewName={filtersStore.viewName}
             savedFilters={filtersStore.savedFilters}
           />
           <div className="fiq-tasks__filters__daterange">
             <DatePickerRange
               onChange={this.applyDateFilter}
               startDate={date.startDate}
               endDate={date.endDate}
               predefinedRange
               startDateModifiers={{
                 popperModifiers: {
                   offset: {
                     enabled: true,
                     offset: '-100px, 0px',
                   },
                 },
               }}
               endDateModifiers={{
                 popperModifiers: {
                   offset: {
                     enabled: true,
                     offset: '-220px, 0px',
                   },
                 },
               }}
             />
           </div>
         </div>
         <Loader visible={loadingOptions} />
         {!loadingOptions && (
         <div className="fiq-tasks__content">
           <TasksAccordion
             {...overDue}
             fetchTasks={this.fetchOverDueTasks}
             fetchAllTasks={this.fetchAllTasks}
             isOpen
             title={text.overDue}
             accordionId="overDue"
           />
           <TasksAccordion
             {...today}
             fetchTasks={this.fetchTodayTasks}
             fetchAllTasks={this.fetchAllTasks}
             isOpen
             title={text.today}
             accordionId="today"
           />
           <TasksAccordion
             {...upcoming}
             fetchTasks={this.fetchUpcomingTasks}
             fetchAllTasks={this.fetchAllTasks}
             title={text.upcoming}
             accordionId="upcoming"
           />
           <TasksAccordion
             {...pastDue}
             fetchTasks={this.fetchPastDueTasks}
             fetchAllTasks={this.fetchPastDueTasks}
             title={text.pastDue}
             accordionId="pastDue"
           />
         </div>
         )}
       </div>
       <AddEditTask />
     </Page>
   );
 }
}

TasksPage.propTypes = propTypes;
TasksPage.displayName = 'TasksPage';

export default inject(stores => ({
  setDateQuery: stores.tasks.setDateQuery,
  date: stores.tasks.date,
  fetchTasks: stores.tasks.fetchTasks,
  fetchTasksOptions: stores.tasks.fetchOptionsData,
  activeFilters: stores.tasks.filters.activeFilters,
  resetFilters: stores.tasks.resetFilters,
  filtersStore: stores.tasks.filters,
  overDue: stores.tasks.accordions.overDue,
  today: stores.tasks.accordions.today,
  upcoming: stores.tasks.accordions.upcoming,
  pastDue: stores.tasks.accordions.pastDue,
  resetAccordions: stores.tasks.resetAccordions,
  loadingOptions: stores.tasks.requestInProgress.options,
  addNewFilter: stores.tasks.filters.addNewFilter,
}))(observer(TasksPage));
