import React from 'react';
import { DatePickerRange } from 'tc-biq-design-system';
import { func, object, array } from 'prop-types';
import { inject, observer } from 'mobx-react';

import Filters, { FiltersServiceFactory } from 'App/components/Filters';

const propTypes = {
  applyDateFilter: func.isRequired,
  applyFilter: func.isRequired,
  date: object.isRequired,
  filtersStore: object.isRequired,
  activeFilters: array.isRequired,
  fetchActivities: func.isRequired,
  clearHighlights: func.isRequired,
};

const filtersService = FiltersServiceFactory('/activity/');

const ActivityStreamFilters = ({
  applyDateFilter,
  applyFilter,
  date,
  activeFilters,
  fetchActivities,
  filtersStore,
  clearHighlights,
}) => {
  const { startDate, endDate } = date;
  const filterAction = action => async (value) => {
    clearHighlights();
    action(value);
    await fetchActivities();
  };

  return (
    <div className="fiq-activity-stream__filters">
      <div className="fiq-activity-stream__filters-basic">
        <Filters
          activeFilters={activeFilters}
          filtersService={filtersService}
          fetchData={fetchActivities}
          filtersStore={filtersStore}
          applyFilter={filterAction(applyFilter)}
          viewName={filtersStore.viewName}
          savedFilters={filtersStore.savedFilters}
        />
        <DatePickerRange
          onChange={filterAction(applyDateFilter)}
          predefinedRange
          startDate={startDate}
          endDate={endDate}
          startDateModifiers={{
            popperModifiers: {
              offset: {
                enabled: true,
                offset: '-100px, 0px',
              },
            },
          }}
          endDateModifiers={{
            popperModifiers: {
              offset: {
                enabled: true,
                offset: '-220px, 0px',
              },
            },
          }}
        />
      </div>
    </div>
  );
};

ActivityStreamFilters.propTypes = propTypes;

export default inject(stores => ({
  date: stores.activityStream.date,
  fetchActivities: stores.activityStream.fetchActivities,
  applyFilter: stores.activityStream.filters.addNewFilter,
  applyDateFilter: stores.activityStream.setDateQuery,
  filtersStore: stores.activityStream.filters,
  viewName: stores.activityStream.viewName,
  activeFilters: stores.activityStream.filters.activeFilters,
  clearHighlights: stores.activityStream.clearHighlights,
}))(observer(ActivityStreamFilters));
