import React from 'react';

import { Panel } from 'tc-biq-design-system';
import { string, array } from 'prop-types';

import Section from './Section';
import './SettingsPanel.scss';

const propTypes = {
  title: string.isRequired,
  sections: array.isRequired,
};

const SettingsPanel = ({ title, sections }) => {
  const listSections = sections.map(section => <Section {...section} key={section.label} />);
  return (
    <Panel title={title}>
      <div className="system-panel">{listSections}</div>
    </Panel>
  );
};

SettingsPanel.propTypes = propTypes;

export default SettingsPanel;
