import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { Space, Icon } from 'tc-biq-design-system';
import { string, object } from 'prop-types';

const propTypes = {
  icon: string.isRequired,
  label: string.isRequired,
  route: string.isRequired,
  history: object.isRequired,
};

class Section extends PureComponent {
  render() {
    const { icon, label, route, history } = this.props;
    return (
      <div className="system-panel__section" onClick={() => history.push(route)}>
        <Icon name={icon} colorName="text-primary-500" size="extraLarge" />
        <Space size={8} />
        <span className="tc-paragraph-regular text-neutral-900">{label}</span>
      </div>
    );
  }
}

Section.propTypes = propTypes;

export default withRouter(Section);
