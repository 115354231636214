import React, { useState } from 'react';
import { object, bool, func } from 'prop-types';
import { Modal, notifier } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import SidepanelFooter from 'App/components/SidepanelFooter';
import buildBulkParams from 'App/services/utilities/buildBulkParams';
import { bulkArchive } from 'Settings/Sections/Users/services/UsersService';

const SIDEPANEL_ID = 'BULK_ARCHIVE';
const usersTableKey = 'users';

export const closeBulkArchive = () => closeOverlay(SIDEPANEL_ID);
export const openBulkArchive = (
  selectedItems, selectedAll,
) => openOverlay(SIDEPANEL_ID, { selectedItems, selectedAll });

const propTypes = {
  parameters: object,
  visible: bool,
  onSuccess: func,
};

const defaultProps = {
  parameters: null,
  visible: false,
  onSuccess: () => null,
};

const text = {
  TITLE: 'Archive users',
  DESCRIPTION: 'Are you sure you want to archive selected users?',
  SUCCESS_MESSAGE: 'Request for archiving Users is put for processing',
  ERROR_MESSAGE: 'Error while archiving user(s).',
  BUTTON_LABELS: {
    confirm: 'Archive',
    cancel: 'Cancel',
  },
};

const customFooter = ({ execute, close, submitInProgress }) => () => (
  <SidepanelFooter
    execute={execute}
    close={close}
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    style={{ justifyContent: 'flex-end', width: '100%' }}
    submitInProgress={submitInProgress}
  />
);

const BulkArchive = ({ visible, parameters, onSuccess }) => {
  const [submitInProgress, setInProgress] = useState(false);

  const close = closeBulkArchive;

  const onConfirm = async (selectedItems, selectedAll) => {
    const params = buildBulkParams(selectedItems, selectedAll, usersTableKey);
    try {
      setInProgress(true);
      await bulkArchive(params);
      onSuccess();
      notifier.success(text.SUCCESS_MESSAGE);
      setInProgress(false);
      close();
    } catch (err) {
      notifier.error(text.ERROR_MESSAGE);
      setInProgress(false);
    }
  };

  if (isEmpty(parameters)) return null;

  const { selectedItems, selectedAll } = parameters;
  return (
    <Modal
      title={text.TITLE}
      icon="ArrowDown"
      size="medium"
      visible={visible}
      footerRender={customFooter({
        execute: () => onConfirm(selectedItems, selectedAll),
        close,
        submitInProgress,
      })}
    >
      <div>{text.DESCRIPTION}</div>
    </Modal>
  );
};

BulkArchive.propTypes = propTypes;
BulkArchive.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
}))(observer(withRouter(BulkArchive)));
