import React from 'react';
import { Button, Space } from 'tc-biq-design-system';
import { any, func } from 'prop-types';

const propTypes = {
  value: any,
  onChange: func.isRequired,
};

const defaultProps = {
  value: false,
};

const text = {
  YES: 'Yes',
  NO: 'No',
};

const BUTTON_STYLE = {
  display: 'flex',
  alignItems: 'center',
};

const BooleanInput = (props) => {
  const { value, onChange } = props;
  if (value === '') onChange(true);
  const colorYes = value ? 'confirmation' : 'ghost';
  const colorNo = value ? 'ghost' : 'destructive';

  return (
    <div style={BUTTON_STYLE}>
      <Button
        style={{ margin: '0px', width: '90%', maxWidth: '80px' }}
        size="full-width"
        color={colorYes}
        onClick={() => onChange(true)}
      >
        {text.YES}
      </Button>
      <Space size={8} />
      <Button
        style={{ margin: '0px', width: '90%', maxWidth: '80px' }}
        size="full-width"
        color={colorNo}
        onClick={() => onChange(false)}
      >
        {text.NO}
      </Button>
    </div>
  );
};

BooleanInput.propTypes = propTypes;
BooleanInput.defaultProps = defaultProps;

export default BooleanInput;
