import React, { Fragment } from 'react';
import { Icon } from 'tc-biq-design-system';
import { bool } from 'prop-types';

import If from 'App/components/If';

const AccordionCarret = ({ visible }) => (
  <Fragment>
    <If condition={!visible}>
      <Icon name="CaretRight" />
    </If>
    <If condition={visible}>
      <Icon name="CaretDown" />
    </If>
  </Fragment>
);
AccordionCarret.propTypes = { visible: bool.isRequired };

export default AccordionCarret;
