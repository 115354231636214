import http from 'App/services/http';

const fetchPermissionMetadata = () => http.get('/users/permissions-metadata/');

const fetchRoleData = id => http.get(`/users/roles/${id}/`);

const editRole = (id, payload) => http.put(`/users/roles/${id}/`, payload);

const addRole = payload => http.post('/users/roles/', payload);

const deleteRole = id => http.delete(`/users/roles/${id}/`);

const fetchRoleBindings = (id, params) => http.get(`/users/roles/${id}/bindings/`, { params });

const addRoleBinding = (roleId, userId) => http.post(`/users/roles/${roleId}/bindings/`, [{ user: userId }]);

const removeRoleBinding = (roleId, roleBindingId) => http.delete(`/users/roles/${roleId}/bindings/${roleBindingId}/`);

export default {
  fetchPermissionMetadata,
  fetchRoleBindings,
  fetchRoleData,
  editRole,
  addRoleBinding,
  removeRoleBinding,
  addRole,
  deleteRole,
};
