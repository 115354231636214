/* eslint react/prop-types: 0 */
import React from 'react';
import { ToggleCell } from 'App/components/gridCellRenderers';
import { Space } from 'tc-biq-design-system';

const ToggleNameCell = onToggle => ({ data }) => {
  const ToggleCellComponent = ToggleCell(onToggle);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '50px' }}>
        <ToggleCellComponent value={data.is_active} data={data} />
      </div>
      <Space size={12} />
      <span>{data.name}</span>
    </div>
  );
};

export default ToggleNameCell;
