import React, { PureComponent } from 'react';
import { Tabs, Tab, ComboxProvider } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { func, number } from 'prop-types';

import Page from 'App/components/Page';
import ActivityStream from 'ActivityStream/components/ActivityStream';
import ManuallyLog from 'ActivityStream/components/ManuallyLog';

import './ActivityStreamPage.scss';

const propTypes = {
  fetchActivities: func.isRequired,
  fetchActivityOptions: func.isRequired,
  userId: number.isRequired,
  setInitialFilters: func.isRequired,
  resetActivities: func.isRequired,
  resetFilters: func.isRequired,
};

const text = {
  MANUALLY_LOG: 'Add manually log',
  PAGE_TITLE: 'Activity stream',
  ALL_ACTIVITY: 'All activity',
  MY_ACTIVITY: 'My activity',
};

const bread = [{ label: 'Activity stream', route: '/activity-stream' }];

class ActivityStreamPage extends PureComponent {
  componentDidMount() {
    const { resetFilters } = this.props;
    resetFilters();
    this.fetchActivityOptions();
  }

  componentWillUnmount() {
    const { resetActivities } = this.props;
    resetActivities();
  }

  fetchActivities = async (userId) => {
    const {
      fetchActivities,
      fetchActivityOptions,
      setInitialFilters,
      resetActivities,
    } = this.props;
    const fieldsToOmit = userId ? ['actor'] : [];
    resetActivities();
    setInitialFilters({ userId });
    await fetchActivityOptions(fieldsToOmit);
    await fetchActivities();
  };

  fetchActivityOptions = async () => {
    const { fetchActivityOptions } = this.props;
    await fetchActivityOptions();
  };

  headerActions = () => <ManuallyLog />;

  render() {
    const { userId } = this.props;
    return (
      <ComboxProvider>
        <Page
          headerActions={this.headerActions}
          bread={bread}
          title={text.PAGE_TITLE}
          style={{ margin: '0' }}
        >
          <Tabs styleTabsList={{ marginBottom: 0, backgroundColor: '#FFF' }}>
            <Tab title={text.ALL_ACTIVITY} onLoad={this.fetchActivities}>
              <ActivityStream />
            </Tab>
            <Tab title={text.MY_ACTIVITY} onLoad={() => this.fetchActivities(userId)}>
              <ActivityStream />
            </Tab>
          </Tabs>
        </Page>
      </ComboxProvider>
    );
  }
}

ActivityStreamPage.propTypes = propTypes;

export default inject(stores => ({
  activities: stores.activityStream.activities,
  fetchActivities: stores.activityStream.fetchActivities,
  fetchActivityOptions: stores.activityStream.fetchActivityOptions,
  userId: stores.loginStore.user.id,
  setInitialFilters: stores.activityStream.setInitialFilters,
  resetActivities: stores.activityStream.resetActivities,
  resetFilters: stores.activityStream.resetFilters,
}))(observer(ActivityStreamPage));
