import React from 'react';
import NotificationPreferencesTable from './NotificationPreferences';

export default {
  label: 'Notification preferences',
  id: 'notification-preferences',
  Component: () => <NotificationPreferencesTable />,
  // TODO: permissions are not needed here are all users
  //  can see theirs notification preferences.
  permission: 'users_user',
  visibility: ({ userId, loggedUserId }) => userId === loggedUserId,
};
