import React from 'react';
import { Row, Col, Icon, Button } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { object, bool, func } from 'prop-types';

import './ChangePassword.scss';
import FormPanel from 'App/components/FormPanel';

const text = {
  TITLE: 'Change password',
  INITIATE_PASSWORD_CHANGE: 'Initiate Password Change',
};

const Title = () => (
  <div className="change-password__title">
    <Icon name="Lock" />
    {text.TITLE}
  </div>
);

const Footer = inject(stores => ({
  initiatePasswordChange: stores.usersStore.initiatePasswordChange,
  requestInProgress: stores.usersStore.requestInProgress.initiatePasswordChange,
}))(
  observer(({ initiatePasswordChange, requestInProgress }) => (
    <Button
      disabled={requestInProgress}
      loading={requestInProgress}
      color="primary"
      onClick={initiatePasswordChange}
    >
      {text.INITIATE_PASSWORD_CHANGE}
    </Button>
  )),
);

Footer.wrappedComponent.propTypes = {
  changePassword: func.isRequired,
  form: object.isRequired,
  requestInProgress: bool.isRequired,
};

const ChangePassword = () => (
  <Row gutter={0} className="change-password">
    <Col sm="1/4" style={{ margin: '0 100px' }}>
      <FormPanel className="change-password__panel">
        <FormPanel.Header>
          <Title />
        </FormPanel.Header>
        <div className="change-password__submit">
          <Footer />
        </div>
      </FormPanel>
    </Col>
  </Row>
);

export default ChangePassword;
