import React, { useState, useEffect, Fragment } from 'react';
import { Pill, Icon } from 'tc-biq-design-system';
import classNames from 'classnames';
import { array, object, bool, func } from 'prop-types';

import Field, { fieldsResolverObject } from 'App/components/FieldRenderer';
import EventDataFilter from './EventDataFilter';
import EventTimeQuery from './EventTimeQuery';

import './ConditionEvent.scss';

const propTypes = {
  rule: object.isRequired,
  parentIdentifier: array.isRequired,
  isLastSibling: bool.isRequired,
  removeCondition: func.isRequired,
  updateEvent: func.isRequired,
};

const text = {
  EVENT: 'Event',
  REMOVE_ALL: 'Remove all',
};

const optionDef = {
  event: {
    type: 'related field',
    required: true,
    read_only: false,
    label: '',
    fieldLabel: text.EVENT,
    many: false,
    related_model: { value: 'events_eventtype', display_name: 'Event Type' },
  },
};
const fieldProps = fieldsResolverObject(optionDef);

const ConditionEvent = ({
  rule,
  parentIdentifier,
  isLastSibling,
  removeCondition,
  updateEvent,
}) => {
  const [event, setEventValue] = useState(rule ? rule.event_type_id : null);
  const [initialId, setInitialId] = useState(rule ? rule.event_type_id : null);
  const removeEventCondition = () => removeCondition(parentIdentifier);
  const selectEvent = (name, value) => {
    updateEvent({ event_type_id: value?.value }, parentIdentifier);
    setEventValue(value);
  };

  useEffect(() => {
    if (rule && rule.event_type_id) {
      setEventValue(rule.event_type_id);
      setInitialId(rule.event_type_id);
    }
  }, [rule]);

  return (
    <div className="fiq-condition-event">
      <div
        className={classNames({
          'fiq-condition-group__line-background': !isLastSibling,
          'fiq-condition-event__no-line-background': isLastSibling,
        })}
      >
        <Pill big style={{ marginRight: '0' }}>
          {text.EVENT}
        </Pill>
      </div>
      <div className={classNames('fiq-condition-event__body', { 'last-sibling': isLastSibling })}>
        <div className="fiq-condition-event__select-delete">
          <div className="fiq-condition-event__select">
            <Field
              {...fieldProps.event}
              onChange={selectEvent}
              value={event}
              afterFieldSpaceSize={0}
            />
          </div>
          <Icon
            name="Delete"
            size="small"
            onClick={removeEventCondition}
            style={{ cursor: 'pointer' }}
          />
        </div>
        {event && event.value && (
          <Fragment>
            <EventTimeQuery
              numOfTimes={rule.no_times}
              lastDaysNum={rule.last_n_days}
              parentIdentifier={parentIdentifier}
              updateEvent={updateEvent}
            />
            <EventDataFilter
              eventId={event.value}
              condition={rule.condition}
              rules={rule.rules}
              parentIdentifier={parentIdentifier}
              updateEvent={updateEvent}
              initialId={initialId}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

ConditionEvent.propTypes = propTypes;

export default ConditionEvent;
