import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { object, func, bool } from 'prop-types';
import { Button, Space } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';

import Field, { fieldsResolverObject } from 'App/components/FieldRenderer';
import BlockTitle from 'App/components/BlockTitle';
import WebhookTriggers from 'Settings/Sections/Webhooks/components/WebhookTriggers';
import { hasAccess } from 'App/services/permissionsService';

import './WebhookForm.scss';

const propTypes = {
  fieldsDef: object.isRequired,
  match: object.isRequired,
  history: object.isRequired,
  createWebhook: func.isRequired,
  editWebhook: func.isRequired,
  resetWebhookForm: func.isRequired,
  createInProgress: bool.isRequired,
  editInProgress: bool.isRequired,
  updateWebhookTriggers: func,
  validateForm: func.isRequired,
};

const defaultProps = {
  updateWebhookTriggers: null,
};

const text = {
  TRIGGER_EVENT: 'Trigger event',
  BUTTON_LABELS_CREATE: {
    confirm: 'Create webhook',
    cancel: 'Discard',
  },
  BUTTON_LABELS_EDIT: {
    confirm: 'Save changes',
    cancel: 'Discard changes',
  },
};

const checkPermission = permission => hasAccess('automations_webhook', permission);

const WebhookForm = ({
  fieldsDef,
  match,
  history,
  createWebhook,
  editWebhook,
  resetWebhookForm,
  createInProgress,
  editInProgress,
  validateForm,
}) => {
  const { webhookId } = match.params;
  const isCreate = !webhookId;
  const buttonLabels = isCreate ? text.BUTTON_LABELS_CREATE : text.BUTTON_LABELS_EDIT;
  const hasPermission = isCreate ? checkPermission('create') : checkPermission('update');
  const fields = fieldsResolverObject(fieldsDef);
  const onCancel = () => {
    resetWebhookForm();
    history.push('/settings/webhooks');
  };
  const create = () => validateForm() && createWebhook(history);
  const edit = () => validateForm() && editWebhook(webhookId, history);

  useEffect(() => () => {
    resetWebhookForm();
  }, []);

  return (
    <div className="fiq-webhook-form">
      <div className="fiq-webhook-form__section">
        <Field {...fields.name} name="name" formId="webhookForm" />
        <Field {...fields.url} name="url" formId="webhookForm" />
      </div>
      <div className="fiq-webhook-form__section">
        <BlockTitle icon="Action" title={text.TRIGGER_EVENT} />
        <Space size={16} />
        <WebhookTriggers />
      </div>
      <div className="fiq-webhook-form__footer">
        <Button onClick={onCancel} color="ghost">
          {buttonLabels.cancel}
        </Button>
        <Space size={4} />
        {hasPermission && (
          <Button
            loading={isCreate ? createInProgress : editInProgress}
            onClick={isCreate ? create : edit}
          >
            {buttonLabels.confirm}
          </Button>
        )}
      </div>
    </div>
  );
};

WebhookForm.propTypes = propTypes;
WebhookForm.defaultProps = defaultProps;

export default inject(stores => ({
  fieldsDef: stores.webhooks.fieldsDef,
  createWebhook: stores.webhooks.createWebhook,
  editWebhook: stores.webhooks.editWebhook,
  resetWebhookForm: stores.webhooks.resetWebhookForm,
  createInProgress: stores.webhooks.requestInProgress.createWebhook,
  editInProgress: stores.webhooks.requestInProgress.editWebhook,
  validateForm: stores.forms.webhookForm.validateForm,
}))(observer(withRouter(WebhookForm)));
