import React, { useState, useEffect } from 'react';
import GridComponentFactory from 'App/components/grid';
import { shape, string, object } from 'prop-types';

import modifiers from './modifiers';

const sizeOptions = [5];

const PositionTransactionsTable = ({ tableConfig, style, height }) => {
  const [{ GridComponent, gridActions }] = useState(GridComponentFactory(tableConfig));

  return (
    <div style={style}>
      <GridComponent
        defaultPageSize={5}
        sizeOptions={sizeOptions}
        height={height}
        modifiers={modifiers}
      />
    </div>
  );
};

PositionTransactionsTable.propTypes = {
  tableConfig: shape({
    tableUrl: string.isRequired,
    tableKey: string.isRequired,
  }).isRequired,
  style: object,
  height: string,
};

PositionTransactionsTable.defaultProps = {
  style: {},
  height: '150px',
};

export default PositionTransactionsTable;
