/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Table, Button, Tooltip } from 'tc-biq-design-system';
import { isObject } from 'lodash';

import { YesNoCell } from 'App/components/gridCellRenderers';
import { openOverlay } from 'App/services/overlayService';
import PreviewMetaData, {
  SIDEPANEL_ID,
} from 'App/components/CommonOverlays/PreviewMetaData';
import {
  LogDetailData,
  UpdateValues,
} from 'Settings/Sections/AuditLog/components/LogDetails';
import { TableColType } from 'tc-biq-design-system/types/Table/TableCell';

const text = {
  VIEW_METADATA: (fieldKey: string) => `View ${fieldKey}`,
};

const setFieldWidth = (key: string, width: string) => (data: LogDetailData) => (
  <div className="truncate-text" style={{ minWidth: width, maxWidth: '200px' }}>
    {data[key as keyof LogDetailData]}
  </div>
);

const List = ({ list }: { list: string[] }) => (
  <div style={{ maxHeight: '200px', overflow: 'auto', minWidth: '150px' }}>
    <ul>
      {list.sort().map((listItem: string) => (
        <li>{listItem}</li>
      ))}
    </ul>
  </div>
);

const RenderList = ({ list }: { list: string[] }) => {
  if (list.length < 30) return <span key={list.join()}>{list.join()}</span>;
  return (
    <Tooltip
      placement="bottom"
      tooltipOptions={{ interactive: true }}
      content={<List list={list} />}
    >
      <div className="truncate-text" style={{ maxWidth: '200px' }}>
        {list.join()}
      </div>
    </Tooltip>
  );
};

const formatValue = (key: string, value?: unknown) => {
  if (typeof value === 'boolean') return <YesNoCell value={value} />;
  if (!value) return '-';
  if (Array.isArray(value)) return <RenderList list={value} />;
  if (isObject(value)) {
    return (
      <Button
        color="transparent"
        onClick={() => openOverlay(SIDEPANEL_ID, { data: value, title: key })}
      >
        {text.VIEW_METADATA(key)}
      </Button>
    );
  }
  return value;
};

const columns = [
  {
    title: 'Field',
    key: 'fieldKey',
    render: setFieldWidth('fieldKey', '100px'),
  },
  {
    title: 'Old value',
    key: 'old_value',
    render: setFieldWidth('old_value', '200px'),
  },
  {
    title: 'New value',
    key: 'new_value',
    render: setFieldWidth('new_value', '200px'),
  },
] as TableColType[];

const formatTableData = (data: LogDetailData) => {
  if (!data) return [];
  return Object.keys(data.old_values).map((key, index) => ({
    id: index,
    fieldKey: key,
    old_value: formatValue(key, data.old_values[key as keyof UpdateValues]),
    new_value: formatValue(key, data.new_values[key as keyof UpdateValues]),
  }));
};

const AuditLogSingleTable = ({ data }: { data: LogDetailData }) => {
  const formattedData = formatTableData(data);
  if (formattedData.length === 0) return null;
  return (
    <div className="fiq-audit-log-single__main__table">
      <Table cols={columns} data={formattedData} />
      <PreviewMetaData />
    </div>
  );
};

export default AuditLogSingleTable;
