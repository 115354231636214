import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { configure } from 'mobx';

import App from 'App/components/App';
import store from 'App/rootStore';

require('./snippets/smartlook');
require('./snippets/smartlook-identify');

configure({ enforceActions: 'always' });

const app = document.getElementById('app');

ReactDOM.render(
  <Provider {...store}>
    <App />
  </Provider>,
  app,
);
