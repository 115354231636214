import React from 'react';
import Page from 'App/components/Page';
import { Button } from 'tc-biq-design-system';

import { openOverlay } from 'App/services/overlayService';
import { SIDEPANEL_ID } from 'Settings/Sections/Teams/components/sidepanels/CreateEditTeam';
import TeamsStructure from 'Settings/Sections/Teams/components/TeamsStructure';

const headerActions = () => {
  const createTeam = () => openOverlay(SIDEPANEL_ID);
  return <Button onClick={createTeam}>Create new team</Button>;
};

const TeamsPage = () => (
  <Page title="Teams" headerActions={headerActions} style={{ margin: '20px', overflow: 'auto' }}>
    <TeamsStructure />
  </Page>
);

export default TeamsPage;
