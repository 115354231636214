import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Sidepanel, Select, Icon, Card, Space, notifier } from 'tc-biq-design-system';
import SidepanelFooter from 'App/components/SidepanelFooter';

import { object, bool, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import http from 'App/services/http';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { bulkSetTags, bulkRemoveTags } from 'Contacts/services/contactsService';
import { DEBOUNCE_VALUE } from 'App/components/FieldRenderer/Inputs/SelectField';
import buildBulkParams from 'App/services/utilities/buildBulkParams';

import './BulkManageTags.scss';

const propTypes = {
  parameters: object,
  visible: bool,
  onSuccess: func,
};

const defaultProps = {
  parameters: {},
  visible: false,
  onSuccess: () => null,
};

const text = {
  MANAGE_TAGS: 'Manage Tags',
  ADD_TAGS: 'Add tags',
  REMOVE_TAGS: 'Remove existing tags',
  SUCCESS_MESSAGE: 'Request for updating Tags is put for processing',
  ERROR_MESSAGE: 'Error while updating tags',
  BUTTON_LABELS: {
    confirm: 'Save changes',
    cancel: 'Discard changes',
  },
};

const SIDEPANEL_ID = 'BULK_MANAGE_TAGS';
const contactsTableKey = 'contacts';

const CARD_STYLE = {
  header: { display: 'flex', alignItems: 'center', height: '44px' },
  content: { padding: '0', background: 'white' },
};

// eslint-disable-next-line max-len
export const openBulkManageTags = (selectedItems, selectedAll) => openOverlay(SIDEPANEL_ID, { selectedItems, selectedAll });
export const closeBulkManageTags = () => closeOverlay(SIDEPANEL_ID);

const headerTemplate = (title, icon) => (
  <div className="fiq-manage-tags__header">
    <Icon name={icon} colorName="text-primary-500" bgColorName="bck-primary-100" size="small" />
    <Space size={12} />
    <span className="tc-paragraph-strong">{title}</span>
  </div>
);

// eslint-disable-next-line react/prop-types
const customFooter = ({ execute, close, submitInProgress, buttonLabels, disableSubmit }) => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    disableSubmit={disableSubmit}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={buttonLabels}
    style={{ justifyContent: 'flex-end', width: '100%' }}
  />
);

const AddHeader = () => headerTemplate(text.ADD_TAGS, 'Plus');
const RemoveHeader = () => headerTemplate(text.REMOVE_TAGS, 'Delete');

// eslint-disable-next-line react/prop-types
const SelectTag = ({ creatable, placeholder, onChange, value }) => (
  <Select
    creatable={creatable}
    dataRecording
    loadOptions={handleInputChange}
    style={{ padding: '16px' }}
    type="multi"
    placeholder={placeholder}
    async
    onChange={onChange}
    className="tag-input"
    valueKey="value"
    labelKey="display_name"
    value={value}
    debounceInterval={DEBOUNCE_VALUE}
  />
);

const handleInputChange = async (name) => {
  const response = await http.get(`tags/?search=${name}`);
  return [
    ...response.data.results.map(result => ({
      value: result.id,
      display_name: result.label,
    })),
  ];
};

const BulkManageTags = ({ parameters, visible, onSuccess }) => {
  const [addedTags, handleAddTags] = useState([]);
  const [removedTags, handleRemoveTags] = useState([]);
  const [submitInProgress, setInProgress] = useState(false);

  const isSubmitDisabled = () => isEmpty(addedTags) && isEmpty(removedTags);

  const resetFieldsData = () => {
    handleAddTags([]);
    handleRemoveTags([]);
  };

  const close = () => {
    resetFieldsData();
    closeBulkManageTags();
  };

  const save = async (selectedItems, selectedAll) => {
    setInProgress(true);
    const addTagsNames = addedTags.map(tag => (tag.display_name ? tag.display_name : tag.label));
    const removeTagsNames = removedTags.map(tag => tag.display_name);
    const params = buildBulkParams(selectedItems, selectedAll, contactsTableKey);

    const setTagsPayload = {
      tags: addTagsNames,
      ...params,
    };
    const removeTagsPayload = {
      tags: removeTagsNames,
      ...params,
    };

    try {
      const requests = [];
      if (!isEmpty(addTagsNames)) {
        requests.push(bulkSetTags(setTagsPayload));
      }
      if (!isEmpty(removeTagsNames)) {
        requests.push(bulkRemoveTags(removeTagsPayload));
      }
      await Promise.all(requests);
      setInProgress(false);
      notifier.success(text.SUCCESS_MESSAGE);
      onSuccess();
      close();
    } catch (errors) {
      notifier.error(text.ERROR_MESSAGE);
      setInProgress(false);
    }
  };

  if (isEmpty(parameters)) return null;
  const { selectedItems, selectedAll } = parameters;
  return (
    <Sidepanel
      icon="Tag"
      title={text.MANAGE_TAGS}
      visible={visible}
      onCloseIconClick={close}
      footerRender={() => customFooter({
        execute: () => save(selectedItems, selectedAll),
        close,
        submitInProgress,
        buttonLabels: text.BUTTON_LABELS,
        disableSubmit: isSubmitDisabled(),
      })
      }
    >
      <Card headerTemplate={AddHeader} style={CARD_STYLE}>
        <SelectTag
          value={addedTags}
          placeholder={text.ADD_TAG}
          onChange={handleAddTags}
          creatable
        />
      </Card>
      <Space size={8} />
      <Card headerTemplate={RemoveHeader} style={CARD_STYLE}>
        <SelectTag value={removedTags} placeholder={text.REMOVE_TAGS} onChange={handleRemoveTags} />
      </Card>
    </Sidepanel>
  );
};

BulkManageTags.propTypes = propTypes;
BulkManageTags.defaultProps = defaultProps;

export default inject(stores => ({
  parameters: stores.overlayStore.overlay.parameters,
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
}))(observer(BulkManageTags));
