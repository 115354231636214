import { omit } from 'lodash';
import fieldTypes from 'App/enums/fieldTypes';

export default function extractNestedFields(data, keepReadOnly, omitNoLookups, excludeFilters) {
  let flattenedData = omit({ ...data }, excludeFilters);
  Object.keys(flattenedData).forEach((key) => {
    if (isNestedObject(flattenedData[key])) {
      flattenedData = {
        ...flattenedData,
        ...flattenNestedField(
          getChildren(flattenedData[key], keepReadOnly),
          key,
          data[key].label,
          flattenedData[key].child,
        ),
      };
      flattenedData = omit(flattenedData, key);
    } else if (omitNoLookups && (!flattenedData[key].lookups || _.isEmpty(flattenedData[key].lookups))) {
      // Remove any fields that don't have any available lookups, and are not
      // nested objects.
      flattenedData = omit(flattenedData, key);
    }
  });
  return flattenedData;
}

function flattenNestedField(nestedFields, fieldKey, fieldLabel, child) {
  const temp = {};
  Object.keys(nestedFields).forEach((childKey) => {
    const multiSelectType = child || nestedFields[childKey].many ? { multi: true } : {};
    if (childKey !== 'avatar') {
      temp[`${fieldKey}__${childKey}`] = {
        ...nestedFields[childKey],
        ...multiSelectType,
        label: `${fieldLabel} ${nestedFields[childKey].label}`,
      };
    }
  });
  return temp;
}

function isNestedObject(field) {
  return (
    field
    && (field.children || field.child)
    && (field.type === fieldTypes.NESTED_OBJECT || field.type === 'field')
  );
}

function getChildren(field, keepReadOnly) {
  let children = {};
  if (field && field.children) children = { ...field.children };
  if (field && field.child && field.child.children) children = { ...field.child.children };
  return keepReadOnly ? children : removeReadOnlyChildren(children);
}

function removeReadOnlyChildren(children) {
  const temp = {};
  Object.keys(children).forEach((key) => {
    if (!children[key].read_only) {
      temp[key] = children[key];
    }
  });
  return temp;
}
