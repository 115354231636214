import React, { Fragment } from 'react';
import { Space } from 'tc-biq-design-system';
import { func, array } from 'prop-types';

import NumberInput from './NumberInput';

const propTypes = {
  value: array,
  onChange: func.isRequired,
  onEnter: func,
};

const defaultProps = {
  value: [],
  onEnter: () => null,
};

const onNumberRangeChange = (newValue, range, index, cb) => {
  const newRangeValue = range ? [...range] : [];
  newRangeValue[index] = newValue;
  cb(newRangeValue);
};

const NumberRange = ({ onEnter, ...props }) => {
  const { value, onChange } = props;
  const range = value;
  return (
    <Fragment>
      <NumberInput
        {...props}
        onChange={newValue => onNumberRangeChange(newValue, value, 0, onChange)}
        value={range[0]}
        onEnter={() => onEnter(range)}
      />
      <Space size={8} />
      <NumberInput
        {...props}
        onChange={newValue => onNumberRangeChange(newValue, value, 1, onChange)}
        value={range[1]}
        onEnter={() => onEnter(range)}
      />
    </Fragment>
  );
};

NumberRange.propTypes = propTypes;
NumberRange.defaultProps = defaultProps;

export default NumberRange;
