import React from 'react';
import { object } from 'prop-types';

const propTypes = {
  value: object.isRequired,
};

const AccountCell = ({ value }) => (
  <div data-recording-sensitive className="truncate-text tc-paragraph-regular">{value.name || value.provider}</div>
);

AccountCell.propTypes = propTypes;
export default AccountCell;
