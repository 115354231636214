import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import withFilters from 'App/components/hoc/withFilters';

import LineChartLayout from 'Dashboard/components/layouts/LineChart';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import { buildQueryForPreviousInterval, buildNumeric } from 'Dashboard/store/utils';
import getGranularity from 'App/services/utilities/getGranularity';

const text = {
  TITLE: 'Active contacts',
  DESCRIPTION: 'Total number of active contacts in selected time period.',
};

const METRIC = 'active_contacts';

const propTypes = {
  setContactsData: func,
};

const defaultProps = {
  setContactsData: null,
};

const lines = [{ type: 'monotone', dataKey: 'Active', stroke: '#0EB55F', strokeWidth: 2, dot: false, key: 'active_contacts' }];

const ActiveContactsChart = inject(stores => ({
  dashboardInterval: stores.dashboardStore.dashboardInterval,
  addNewFilter: stores.tables.contacts.filters.addNewFilter,
  setContactsData: stores.dashboardStore.setContactsData,
}))(observer(
  class ActiveContactsChart extends Component {
    constructor() {
      super();
      this.state = {
        fetchInProgress: false,
        granularity: 'day',
        data: [],
        error: null,
      };
    }

    onQueryReady = async (query) => {
      const { start_date, end_date } = query;
      const { setContactsData } = this.props;
      const { error } = this.state;

      const granularity = getGranularity(start_date, end_date);

      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: start_date,
          end_of_interval: end_date,
          granularity,
          dimensions: null,
        },
      };


      const response = await Promise.all([
        fetchMetricsData(formattedQuery),
        fetchMetricsData(buildQueryForPreviousInterval(formattedQuery)),
      ]).catch(errorResponse => this.setState({ error: errorResponse }));

      if (error) return;
      const [current, previous] = response.map(item => item.data);
      const { currency } = current;

      const activeContactsData = current.map(item => ({ ...item, Active: item.count }));
      const currentactiveContactsCount = current.reduce((acc, item) => acc + item.count, 0);
      const previousactiveContactsCount = previous.reduce((acc, item) => acc + item.count, 0);
      const activeContactsNumericData = buildNumeric(
        currentactiveContactsCount,
        previousactiveContactsCount,
        currency,
      );

      setContactsData({ activeContactsNumericData });
      this.setState({
        granularity,
        fetchInProgress: false,
        data: activeContactsData,
      });
    }

    render() {
      const { granularity, fetchInProgress, data } = this.state;

      return (
        <LineChartLayout
          title={text.TITLE}
          description={text.DESCRIPTION}
          dimensions={[]}
          inProgress={fetchInProgress}
          onChangeDimension={this.onChangeDimension}
          data={data}
          xAxisKey={granularity}
          lines={lines}
        />
      );
    }
  },
));


ActiveContactsChart.wrappedComponent.propTypes = propTypes;
ActiveContactsChart.wrappedComponent.defaultProps = defaultProps;

export default {
  component: withRouter(withFilters(ActiveContactsChart)),
};
