import React from 'react';
import { Pill } from 'tc-biq-design-system';
import NoDataDash from 'App/components/NoDataDash';
import isEmpty from 'lodash/isEmpty';
import extractDisplayName from 'App/services/utilities/extractDisplayName';

// eslint-disable-next-line
const PillCell = ({ displayField }) => ({ value }) => {
  if (isEmpty(value)) {
    return <NoDataDash />;
  }
  if (Array.isArray(value)) {
    return value.map(element => (
      <Pill key={element.id}>{element[displayField] || extractDisplayName(element)}</Pill>
    ));
  }
  return <Pill key={value.id}>{value[displayField]}</Pill>;
};

export default PillCell;
