import React from 'react';
import { Pill } from 'tc-biq-design-system';

import {
  RouteCell,
  ToggleCell,
  UserCell,
  DateTimeCell,
  actionWrapper,
} from 'App/components/gridCellRenderers';
import hideCols from 'App/services/utilities/hideCols';
import { hasAccess } from 'App/services/permissionsService';
import NoDataDash from 'App/components/NoDataDash';

const coreEventsIconMapper = {
  Tasks: { icon: 'Tasks', status: 'status03' },
  Contacts: { icon: 'Client', status: 'primary' },
  Finances: { icon: 'Account', status: 'status02' },
  Communications: { icon: 'Chat', status: 'neutral' },
  Documents: { icon: 'KYC', status: 'status01' },
};

const defaultIcon = { icon: 'Close', status: 'neutral' };

const text = {
  IS_ACTIVE_HEADER_NAME: 'Visible in Activity Stream',
  GROUP_HEADER_NAME: 'Event group',
};

const modifier = ({ toggle, actions }) => ({
  ...hideCols(['updated']),
  id: {
    cellRendererFramework: ({ value }) => RouteCell('/settings/events/')({ value }),
  },
  is_activity_visible: {
    headerName: text.IS_ACTIVE_HEADER_NAME,
    cellRendererFramework: ToggleCell(toggle, hasAccess('events_eventtype', 'update')),
  },
  name: {
    cellRendererFramework: ({ data, value }) => RouteCell('/settings/events/')({ hrefValue: data.id, value }),
  },
  actions: {
    pinned: 'right',
    width: 20,
    enableFiltering: false,
    cellRendererFramework: actionWrapper(actions),
    label: '',
    sortable: false,
  },
  type: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => <Pill type="primary">{value}</Pill>,
  },
  group: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => {
      const Icon = coreEventsIconMapper[value] || defaultIcon;
      return (
        <Pill icon={Icon.icon} iconPosition="left" type={Icon.status}>
          {value}
        </Pill>
      );
    },
    headerName: text.GROUP_HEADER_NAME,
  },
  updated_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => data.updated_by ? (
      <UserCell
        id={data.updated_by.id}
        avatar={data.updated_byavatar}
        fullName={`${data.updated_by.first_name} ${data.updated_by.last_name}`}
        subTitle={<DateTimeCell value={data.updated} />}
      />
    ) : (
      <NoDataDash />
    ),
  },
});

export default modifier;
