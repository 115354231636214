import React from 'react';
import { withRouter } from 'react-router-dom';
import { Pill } from 'tc-biq-design-system';

const ContactCountCell = withRouter(({ addSegmentFilter, data, history }) => {
  const onClick = () => {
    addSegmentFilter({ value: data });
    history.push('/contacts/');
  };
  return (
    <Pill
      style={{ minWidth: '24px', display: 'flex', justifyContent: 'space-between' }}
      icon="User"
      color="neutral-400"
      onClick={onClick}
      onIconClick={onClick}
    >
      {data.contacts}
    </Pill>
  );
});

export default ContactCountCell;
