import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Tooltip } from 'tc-biq-design-system';
import { func, any, string, object, bool } from 'prop-types';

import AddEventAttributeDropdown from './AddEventAttributeDropdown';

import './AddEventAttribute.scss';

const propTypes = {
  updateFieldValue: func.isRequired,
  children: any.isRequired,
  fieldKey: string.isRequired,
  attributes: object.isRequired,
  onChange: func,
  disabled: bool,
  tooltip: string,
};

const defaultProps = {
  onChange: null,
  disabled: false,
  tooltip: '',
};

const text = {
  ADD_ATTRIBUTE: 'Add attribute',
};

const AddEventAttribute = ({
  updateFieldValue,
  fieldKey,
  children,
  attributes,
  onChange,
  disabled,
  tooltip,
}) => {
  const [addFilterInProgress, toggleDropdown] = useState(false);
  const open = () => toggleDropdown(true);
  const close = () => toggleDropdown(false);

  return (
    <div className="fiq-add-event-attribute">
      <AddEventAttributeDropdown
        updateFieldValue={updateFieldValue}
        hidden={!addFilterInProgress}
        closeDropdown={close}
        fieldKey={fieldKey}
        attributes={attributes}
        onChange={onChange}
      >
        {children}
      </AddEventAttributeDropdown>
      <div
        className={classNames('fiq-add-event-attribute__toggle-button', {
          hidden: addFilterInProgress,
        })}
      >
        {disabled
          ? (
            <Tooltip title={tooltip}>
              <div className="tooltip-wrapper">
                <Button size="small" icon="CaretDown" color="transparent" disabled={disabled} onClick={open}>
                  {text.ADD_ATTRIBUTE}
                </Button>
              </div>
            </Tooltip>
          )
          : (
            <Button size="small" icon="CaretDown" color="transparent" onClick={open}>
              {text.ADD_ATTRIBUTE}
            </Button>
          )
        }
      </div>
    </div>
  );
};

AddEventAttribute.propTypes = propTypes;
AddEventAttribute.defaultProps = defaultProps;

export default AddEventAttribute;
