import http from 'App/services/http';

const fetchActivities = query => http.get('/activity/', { params: query });

const fetchActivityOptions = () => http.options('/activity/');

const fetchContactActivities = (id, query) => http.get('/activity/', { params: { contact: id, ...query } });

const fetchActivity = id => http.get(`/activity/${id}/`);

const fetchActivityComment = (id, query) => http.get(`/activity/comment/${id}`, { params: query });

const fetchActivityComments = (id, query) => http.get(`/activity/${id}/comments/`, { params: query });

const addComment = (id, content) => http.post(`/activity/${id}/comments/`, { content });

const editComment = ({ id, commentId, content }) => http.patch(`/activity/${id}/comments/${commentId}/`, { content });

const deleteComment = ({ id, commentId }) => http.delete(`/activity/${id}/comments/${commentId}/`);

const fetchThread = ({ url, threadId, offset }) => http.get(
  url || `/activity/${threadId}/thread/`,
  url
    ? {}
    : {
      params: { offset, limit: 10 },
    },
);

const fetchCampaignActivities = (id, query) => http.get('/activity/', { params: { campaign: id, ...query } });

const fetchSegmentActivities = (id, query) => http.get('/activity/', { params: { segment_ids: id, ...query } });

export {
  fetchActivity,
  fetchActivities,
  fetchActivityOptions,
  fetchContactActivities,
  fetchActivityComment,
  fetchActivityComments,
  addComment,
  editComment,
  deleteComment,
  fetchThread,
  fetchCampaignActivities,
  fetchSegmentActivities,
};
