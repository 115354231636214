import React from 'react';
import { object } from 'prop-types';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';

import { openOverlay } from 'App/services/overlayService';
import { SIDEPANEL_ID } from 'Settings/Sections/Users/components/sidepanels/UploadEditAvatar/UploadEditAvatar';
import getInitials from 'App/services/utilities/getInitials';

const propTypes = {
  user: object.isRequired,
  loggedUser: object.isRequired,
};

const text = {
  EDIT: 'Edit',
};

const UserAvatar = ({ user, loggedUser }) => {
  const editAvatar = () => openOverlay(SIDEPANEL_ID, { user });
  const isLoggedUser = loggedUser.id === user.id;

  return (
    <div
      className={classnames('fiq-user-details__avatar-wrapper', {
        'fiq-user-details__logged-user': isLoggedUser,
      })}
      onClick={isLoggedUser ? editAvatar : null}
    >
      {user.avatar ? (
        <img
          src={user.avatar}
          width="84"
          height="84"
          alt={`${user.first_name} ${user.last_name}`}
        />
      ) : (
        <div className="tc-heading-l text-neutral-50">{getInitials(user)}</div>
      )}
      {isLoggedUser && (
        <div className="fiq-user-details__avatar-wrapper--edit">
          <span className="text-neutral-50">{text.EDIT}</span>
        </div>
      )}
    </div>
  );
};

UserAvatar.propTypes = propTypes;

export default inject(stores => ({
  loggedUser: stores.loginStore.user,
}))(observer(UserAvatar));
