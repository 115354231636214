import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { func, array, bool } from 'prop-types';
import withFilters from 'App/components/hoc/withFilters';

import {
  DashboardGridLayout,
} from 'tc-biq-design-system';

import './DashboardGrid.scss';

const propTypes = {
  fetchMetrics: func.isRequired,
  fetchFinanceNumericData: func.isRequired,
  widgets: array,
  layout: array,
  isFinance: bool,
};

const defaultProps = {
  widgets: null,
  layout: [],
  isFinance: false,
};

class DashboardGrid extends Component {
  componentDidMount() {
    const { fetchMetrics, fetchFinanceNumericData, isFinance } = this.props;
    fetchMetrics();

    if (isFinance) {
      fetchFinanceNumericData();
    }
  }

  onQueryReady = async () => {
    const { fetchFinanceNumericData, isFinance } = this.props;
    if (isFinance) fetchFinanceNumericData();
  }

  render() {
    const { widgets, layout } = this.props;
    if (!widgets) return null;
    const visibleWidgets = widgets.filter(widget => widget.isVisible());
    return (
      <DashboardGridLayout
        layout={layout}
        cols={6}
        rowHeight={50}
        onLayoutChange={() => null}
        isDraggable={false}
      >
        {
      visibleWidgets.map((Widget) => {
        const WidgetComponent = Widget.component;
        return (
          <div key={Widget.key}>
            <WidgetComponent title={Widget.label} description={Widget.description} />
          </div>
        );
      })
    }
      </DashboardGridLayout>
    );
  }
}


DashboardGrid.propTypes = propTypes;
DashboardGrid.defaultProps = defaultProps;

export default inject(stores => ({
  fetchMetrics: stores.dashboardStore.fetchMetrics,
  fetchFinanceNumericData: stores.dashboardStore.fetchFinanceNumericData,
}))(observer(withFilters(DashboardGrid)));
