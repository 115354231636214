import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { object, func } from 'prop-types';
import Stepper from 'App/components/Stepper';

// Components
import CampaignHeader from 'Marketing/components/CampaignHeader';
import CampaignJourneysTable from 'Marketing/components/CampaignJourneysTable';
import CampaignWorkflow from 'Marketing/components/CampaignWorkflow';
import CampaignOverview from 'Marketing/components/CampaignOverview';
import CommunicationKpi from 'Marketing/components/CommunicationKpi';

import PreviewCampaignDetails, {
  openCampaignDetails,
} from 'Marketing/components/overlays/PreviewCampaignDetails/PreviewCampaignDetails';
import {
  openPreviewCampaign,
} from 'Marketing/components/overlays/PreviewCampaign';
import { NoContentPage } from 'App/components/NoContentPage';


// Style
import './CampaignOverviewPage.scss';

const propTypes = {
  match: object.isRequired,
  campaign: object.isRequired,
  fetchCampaign: func.isRequired,
  fetchEventMetadata: func.isRequired,
  fetchSendToQueryBuilderMetadata: func.isRequired,
  resetCampaign: func.isRequired,
  fetchJourneyStats: func.isRequired,
  journeyStats: object.isRequired,
};

export const stepKeys = {
  DETAILS: 'details',
  WORKFLOW: 'workflow',
  GOAL: 'goal',
  COMMUNICATION_KPI: 'communication_kpi',
};

const text = {
  CAMPAIGN: 'Overview',
  JOURNEYS: 'Journeys',
  WORKFLOW: 'Workflow',
  COMMUNICATION_KPI: 'Communication',
};

const getSteps = (campaignId, steps, startDate) => [
  {
    key: stepKeys.DETAILS,
    title: text.CAMPAIGN,
    component: () => <CampaignOverview />,
  },
  {
    key: stepKeys.COMMUNICATION_KPI,
    title: text.COMMUNICATION_KPI,
    component: () => <CommunicationKpi campaignStartDate={startDate} id={campaignId} />,
  },
  {
    key: stepKeys.GOAL,
    title: text.JOURNEYS,
    component: () => <CampaignJourneysTable id={campaignId} />,
  },
  {
    key: stepKeys.WORKFLOW,
    title: text.WORKFLOW,
    component: isActive => (
      <CampaignWorkflow
        isActive={isActive}
        steps={steps}
        isOverview
      />
    ),
  },
];


const CampaignOverviewPage = ({
  campaign,
  match,
  fetchCampaign,
  fetchSendToQueryBuilderMetadata,
  fetchEventMetadata,
  resetCampaign,
  fetchJourneyStats,
  journeyStats,
}) => {
  const { campaignId } = match.params;
  const [activeStep, setActiveStep] = useState(stepKeys.DETAILS);

  const onStepChange = async (key) => {
    setActiveStep(key);
  };

  const openDetails = () => openCampaignDetails(campaign);

  const fetchData = async () => {
    await fetchCampaign(campaignId);
    await fetchJourneyStats(campaignId);
  };

  useEffect(() => {
    if (campaign.goal_event_type) fetchEventMetadata(campaign.goal_event_type.id);
    if (campaign.send_to_query) fetchSendToQueryBuilderMetadata();
  }, [campaign]);


  useEffect(() => {
    fetchData();
    return () => resetCampaign();
  }, []);

  if (campaign.status === 'Draft') return <NoContentPage />;

  return (
    <div className="fiq-campaign-overview">
      <CampaignHeader
        campaign={campaign}
        goBackIcon
        openDetails={openDetails}
        openPreviewCampaign={openPreviewCampaign}
      />
      <Stepper
        steps={getSteps(campaignId, journeyStats.steps, campaign.start_datetime)}
        onChange={onStepChange}
        activeStep={activeStep}
        hideIndex
      />
      <PreviewCampaignDetails />
    </div>
  );
};

CampaignOverviewPage.propTypes = propTypes;

export default inject(stores => ({
  fetchCampaign: stores.campaigns.fetchCampaignData,
  fetchJourneyStats: stores.campaigns.fetchJourneyStats,
  journeyStats: stores.campaigns.journeyStats,
  campaign: stores.campaigns.campaign,
  fetchSendToQueryBuilderMetadata: stores.campaigns.fetchSendToQueryBuilderMetadata,
  fetchEventMetadata: stores.campaigns.fetchEventMetadata,
  resetCampaign: stores.campaigns.resetCampaign,
}))(observer(withRouter(CampaignOverviewPage)));
