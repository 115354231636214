import React, { Component } from 'react';
import { Row, Col, CardList, HyperLink } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import compose from 'lodash/fp/compose';
import { openOverlay } from 'App/services/overlayService';
import moment from 'moment';
import { ContactCell } from 'App/components/gridCellRenderers';

import { object } from 'prop-types';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

const SIDEPANEL_ID = 'EDIT_BASIC_INFO';

const propTypes = {
  contactData: object.isRequired,
  contactInfoLayout: object.isRequired,
};

const replaceLoDash = key => key.split('_').join(' ');
const uppercaseFirstLetter = key => `${key[0].toUpperCase()}${key.slice(1)}`;

const formatKey = compose(
  uppercaseFirstLetter,
  replaceLoDash,
);

const renderValue = (key, value) => {
  const date = moment(value, moment.ISO_8601);
  const dateFormat = key === 'date_of_birth' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm';
  if (date.isValid()) return date.format(dateFormat);
  if (key === 'language' && value) {
    return value.display_name;
  }
  if (key === 'landing_page' && value) {
    return (
      <HyperLink>
        <a className="truncate-text" target="blank" href={value}>
          {value}
        </a>
      </HyperLink>
    );
  }
  if (key === 'referred_by' && value) {
    return (
      <ContactCell
        id={value.id}
        avatar={value.avatar}
        fullName={`${value.first_name} ${value.last_name}`}
        size="small"
      />
    );
  }
  return value ? value.toString() : '';
};

const formatFields = (fieldKeys, contactData) => fieldKeys
  .filter(key => Object.keys(contactData).includes(key))
  .map(key => ({
    name: formatKey(key),
    value: renderValue(key, (contactData[key] || contactData?.tracking_link?.[key])),
  }));

class UserData extends Component {
  generateSections = () => {
    const { contactInfoLayout, contactData } = this.props;
    return Object.keys(contactInfoLayout)
      .filter(key => !Object.keys(contactData).includes(key))
      .map(key => ({
        title: key,
        fields: formatFields(contactInfoLayout[key], contactData),
      }));
  };

  editBasicInfo = (title) => {
    openOverlay(SIDEPANEL_ID, title);
  };

  render() {
    const sections = this.generateSections();
    const countSections = sections.length;
    return (
      <Row>
        {sections.map(section => (
          <Col key={section.title} sm={`1/${countSections}`}>
            <div data-recording-sensitive>
              <CardList
                onClick={() => this.editBasicInfo(section.title)}
                title={section.title}
                items={section.fields}
                iconName="Pen"
              />
            </div>
          </Col>
        ))}
      </Row>
    );
  }
}

UserData.propTypes = propTypes;

export default withErrorBoundary(inject(stores => ({
  contactInfoLayout: stores.contact.profile.contactInfoLayout,
  contactData: stores.contact.profile.contactData,
}))(observer(UserData)));
