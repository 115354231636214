import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { object, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import withFilters from 'App/components/hoc/withFilters';

import Numeric from 'Dashboard/components/layouts/Numeric';
import { buildPreviousInterval, buildNumeric } from 'Dashboard/store/utils';
import { fetchCampaignsMetric } from 'Marketing/services/CampaignService';

const text = {
  TITLE: 'Active campaigns',
  DESCRIPTION: 'Total number of active campaigns in selected time period',
};

const propTypes = {
  dashboardInterval: object,
  addNewFilter: func,
  history: object.isRequired,
  setMarketingNumericsData: func,
};

const defaultProps = {
  dashboardInterval: {},
  addNewFilter: null,
  setMarketingNumericsData: null,
};

const filters = (startDate, endDate) => [
  {
    field: { label: 'Start datetime', value: 'start_datetime', type: 'datetime' },
    operator: { label: 'at or before', value: 'lte', related_models: null },
    value: `${endDate}T23:59:59Z`,
  },
  {
    field: { label: 'End datetime', value: 'end_datetime', type: 'datetime' },
    operator: { label: 'at or after', value: 'gte', related_models: null },
    value: `${startDate}T00:00:00Z`,
  },
];


const ActiveCampaignsNumeric = inject(stores => ({
  dashboardInterval: stores.dashboardStore.dashboardInterval,
  addNewFilter: stores.tables.campaigns.filters.addNewFilter,
  setMarketingNumericsData: stores.dashboardStore.setMarketingNumericsData,
}))(observer(
  class ActiveCampaignsNumeric extends Component {
    constructor() {
      super();
      this.state = {
        fetchInProgress: false,
        data: {
          value: 0,
          percentage: 0,
          isGrowing: false,
        },
      };
    }

    onQueryReady = async (query) => {
      const { error } = this.state;
      const { setMarketingNumericsData } = this.props;
      const { start_date, end_date } = query;

      this.setState({
        fetchInProgress: true,
      });

      const formattedQuery = {
        start_of_interval: start_date,
        end_of_interval: end_date,
      };

      const response = await Promise.all([
        fetchCampaignsMetric(formattedQuery),
        fetchCampaignsMetric(buildPreviousInterval(start_date, end_date)),
      ]).catch(errorResponse => this.setState({ error: errorResponse }));


      if (error) return;
      const [current, previous] = response.map(item => item.data);
      const { currency } = current;

      const activeCampaigns = buildNumeric(current.active, previous.active, currency);
      const finishedCampaigns = buildNumeric(current.finished, previous.finished, currency);

      setMarketingNumericsData('finishedCampaigns', finishedCampaigns);

      this.setState({
        fetchInProgress: false,
        data: activeCampaigns,
      });
    }

    handleCardClick = () => {
      const { addNewFilter, history, dashboardInterval } = this.props;
      const { startDate, endDate } = dashboardInterval;
      filters(startDate, endDate).forEach(addNewFilter);
      history.push('/marketing/campaigns');
    }

    render() {
      const { fetchInProgress, data } = this.state;
      const { value, percentage, isGrowing, currency } = data;
      return (
        <Numeric
          title={text.TITLE}
          description={text.DESCRIPTION}
          value={value}
          percentage={percentage}
          isGrowing={isGrowing}
          loading={fetchInProgress}
          onClick={this.handleCardClick}
          currency={currency}
          isCount
        />
      );
    }
  },
));


ActiveCampaignsNumeric.wrappedComponent.propTypes = propTypes;
ActiveCampaignsNumeric.wrappedComponent.defaultProps = defaultProps;

export default {
  component: withRouter(withFilters(ActiveCampaignsNumeric)),
};
