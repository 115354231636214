import {
  fetchContactOptions,
  fetchContactPayloadMetadata,
} from 'Contacts/services/contactsService';

const fetchContactQueryBuilderMetadata = async () => {
  const [response, metadataResponse] = await Promise.all([
    fetchContactOptions(),
    fetchContactPayloadMetadata(),
  ]);
  return {
    fields: formatFields(response.data.actions.GET, metadataResponse.data),
    fieldsMetadata: metadataResponse.data,
  };
};

function formatFields(optionFields, metadataFields) {
  const fields = {};
  Object.keys(metadataFields).forEach((key) => {
    fields[key] = {
      ...metadataFields[key],
      ...optionFields[key],
      label: key
        .split('.')[0]
        .toLowerCase()
        .split('_')
        .map((string, index) => {
          if (index === 0) {
            return string.charAt(0).toUpperCase() + string.substring(1);
          }
          return string;
        })
        .join(' '),
    };
  });
  return fields;
}

export default fetchContactQueryBuilderMetadata;
