import React from 'react';
import { DashboardNumeric, DashboardCard, Space } from 'tc-biq-design-system';
import { string, func, number, bool, oneOfType } from 'prop-types';
import currencyFormatter from 'App/services/utilities/currencyFormatter';

const propTypes = {
  title: string.isRequired,
  value: oneOfType([string, number]),
  percentage: oneOfType([string, number]),
  description: string,
  loading: bool,
  isGrowing: bool,
  onClick: func,
  isCount: bool,
  currency: string,
};

const defaultProps = {
  value: 0,
  percentage: '',
  description: '',
  loading: false,
  isGrowing: false,
  onClick: null,
  isCount: false,
  currency: 'USD',
};

const getColor = (percentage, isGrowing) => {
  if (!percentage) return 'neutral';
  if (isGrowing) return 'green';
  return 'red';
};

const getIcon = (percentage, isGrowing) => {
  if (!percentage) return 'Debit';
  if (isGrowing) return 'ArrowUp';
  return 'ArrowDown';
};

const Numeric = ({
  title, value, percentage, isGrowing, description, loading, onClick, isCount, currency = 'USD',
}) => (
  <DashboardCard
    title={title}
    description={description}
    loading={loading}
    onClick={onClick}
  >
    <DashboardNumeric>
      <DashboardNumeric.Price>
        {isCount ? value : currencyFormatter(value, currency)}
      </DashboardNumeric.Price>
      <Space size={8} />
      <DashboardNumeric.Number
        color={getColor(percentage, isGrowing)}
        iconName={getIcon(percentage, isGrowing)}
      >
        {`${percentage}%`}
      </DashboardNumeric.Number>
    </DashboardNumeric>
  </DashboardCard>
);

Numeric.propTypes = propTypes;
Numeric.defaultProps = defaultProps;

export default Numeric;
