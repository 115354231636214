import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { bool, func, object } from 'prop-types';
import { Space, Icon, notifier, Tooltip } from 'tc-biq-design-system';

import {
  fetchDerivedEventDefinitionFields,
} from 'Settings/Sections/Events/services/EventsService';
import { withRouter } from 'react-router-dom';

import Field, { fieldsResolverObject } from 'App/components/FieldRenderer';
import AddEventAttribute from 'App/components/AddEventAttribute';
import QueryBuilderFactory from 'App/components/QueryBuilderFactory';
import SidepanelFooter from 'App/components/SidepanelFooter';

import './DerivedEventForm.scss';

const text = {
  BUTTON_LABELS: {
    confirm: 'Create',
    cancel: 'Cancel',
  },
  TEMPLATE_LABEL: 'Template',
  DERIVED_EVENT: 'Derived Event',
  QUERY: 'Query',
  CORE_EVENTS: 'Core Events',
  CORE_EVENT_LABEL: 'Choose core event*',
  CORE_ADDITIONAL_EVENTS_LABEL: 'Choose additional core events',
  EMPTY_CORE_EVENT: 'Choose core event',
  TOOLTIP: 'Represents events from which the derived event is created. These events can only relate to a single type of model.',
};

const { QueryBuilder } = QueryBuilderFactory({ storeKey: 'events', initialConditionPlaceholder: '' });

const DerivedEventForm = ({
  updateFieldValue,
  fetchQueryBuilderMetadata,
  resetQueries,
  createDerivedEvent,
  history,
  resetFieldsData,
  createInProgress,
  validateForm,
}) => {
  const [derivedEventFields, setFields] = useState(null);
  const [selectedCoreEvent, setEvent] = useState(null);
  const [selectedAdditionalEvents, setAdditionalEvents] = useState([]);
  const [attributes, setAttributes] = useState({});

  const eventId = selectedCoreEvent?.id;
  const filterParams = selectedCoreEvent
    ? { content_type: selectedCoreEvent.content_type, 'id!': selectedCoreEvent.id } : {};

  useEffect(() => {
    resetQueries();
    if (eventId) {
      const { template, template_properties, message_properties } = selectedCoreEvent;
      updateFieldValue('template', template);
      fetchQueryBuilderMetadata(message_properties);
      setAttributes(template_properties);
    } else {
      updateFieldValue('template', '');
    }
  }, [eventId]);

  useEffect(() => {
    async function fetchFields() {
      const response = await fetchDerivedEventDefinitionFields();
      const fields = response.data.actions.POST;
      const formatedFields = fieldsResolverObject(fields);
      setFields(formatedFields);
    }
    fetchFields();
    return resetFieldsData();
  }, []);

  const selectCoreEvent = (name, value) => {
    setEvent(value);
  };

  const selectAdditionalEvents = (name, value) => {
    setAdditionalEvents(value);
  };

  const onSave = () => {
    if (!validateForm()) return null;
    if (!eventId) return notifier.error(text.EMPTY_CORE_EVENT);
    const coreEventIds = [eventId, ...selectedAdditionalEvents.map(event => event.id)];
    return createDerivedEvent(coreEventIds, history);
  };

  const onDiscard = () => history.push('/settings/events');

  if (!derivedEventFields) return null;

  return (
    <div className="fiq-derived-event-definition__form-wrapper">
      <div className="fiq-derived-event-definition__form">
        <div className="fiq-derived-event-definition__form-section">
          <div className="fiq-derived-event-definition__icon-name">
            <div className="fiq-derived-event-definition__icon">
              <Icon name="Action" colorName="text-primary-500" />
            </div>
            <Space size={8} />
            <div className="tc-heading-s">{text.DERIVED_EVENT}</div>
          </div>
          <Space size={24} />
          <Field {...derivedEventFields.name} formId="derivedEventForm" />
        </div>
        <div className="fiq-derived-event-definition__form-section">
          <div className="fiq-derived-event-definition__icon-name">
            <div className="tc-heading-s">{text.CORE_EVENTS}</div>
            <Tooltip title={text.TOOLTIP}>
              <Icon colorName="text-neutral-500" name="Warning" />
            </Tooltip>
          </div>
          <Space size={24} />
          <Field
            {...derivedEventFields.core_events}
            labelKey="name"
            valueKey="value"
            label={text.CORE_EVENT_LABEL}
            value={selectedCoreEvent}
            onChange={selectCoreEvent}
            many={false}
            disabled={selectedAdditionalEvents.length}
          />
          {selectedCoreEvent && (
          <Field
            key={selectedCoreEvent.id}
            {...derivedEventFields.core_events}
            labelKey="name"
            valueKey="value"
            name="additional-events"
            label={text.CORE_ADDITIONAL_EVENTS_LABEL}
            value={selectedAdditionalEvents}
            onChange={selectAdditionalEvents}
            filterParams={filterParams}
            many
          />
          )}
        </div>
        {eventId && (
        <div className="fiq-derived-event-definition__form-section">
          <div className="fiq-derived-event-definition__icon-name">
            <div className="tc-heading-s">{text.QUERY}</div>
          </div>
          <QueryBuilder />
        </div>
        )}
        <div className="fiq-derived-event-definition__form-section">
          <div className="fiq-derived-event-definition__icon-name">
            <div className="tc-heading-s">{text.TEMPLATE_LABEL}</div>
          </div>
          <Space size={16} />
          <AddEventAttribute updateFieldValue={updateFieldValue} fieldKey="template" attributes={attributes}>
            <Field {...derivedEventFields.template} type="textarea" formId="derivedEventForm" />
          </AddEventAttribute>
        </div>
        <div className="fiq-derived-event-definition__form-section">
          <Field {...derivedEventFields.is_activity_visible} formId="derivedEventForm" />
        </div>
        <div className="fiq-derived-event-definition__form-section--align-right">
          <SidepanelFooter
            submitInProgress={createInProgress}
            execute={onSave}
            close={onDiscard}
            cancelColor="ghost"
            confirmColor="primary"
            buttonLabels={text.BUTTON_LABELS}
          />
        </div>
      </div>
    </div>
  );
};

DerivedEventForm.propTypes = {
  updateFieldValue: func.isRequired,
  fetchQueryBuilderMetadata: func.isRequired,
  resetQueries: func.isRequired,
  createDerivedEvent: func.isRequired,
  history: object.isRequired,
  resetFieldsData: func.isRequired,
  createInProgress: bool.isRequired,
  validateForm: func.isRequired,
};


export default inject(stores => ({
  updateFieldValue: stores.forms.derivedEventForm.updateFieldValue,
  formData: stores.forms.derivedEventForm.data.core_events,
  fetchQueryBuilderMetadata: stores.events.fetchQueryBuilderMetadata,
  resetQueries: stores.events.queryBuilder.resetQueries,
  createDerivedEvent: stores.events.createDerivedEvent,
  resetFieldsData: stores.forms.derivedEventForm.resetFieldsData,
  createInProgress: stores.events.requestInProgress.createDerivedEvent,
  validateForm: stores.forms.derivedEventForm.validateForm,
}))(observer(withRouter(DerivedEventForm)));
