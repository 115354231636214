import React, { useEffect } from 'react';
import { object, bool, func } from 'prop-types';
import { Sidepanel, Space } from 'tc-biq-design-system';
import { isEmpty } from 'lodash';

import { inject, observer } from 'mobx-react';
import { closeOverlay } from 'App/services/overlayService';
import SidepanelFooter from 'App/components/SidepanelFooter';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';

const propTypes = {
  visible: bool,
  parameters: object,
  resetFields: func.isRequired,
  addDocumentType: func.isRequired,
  editDocumentType: func.isRequired,
  fetchDocumentTypeData: func.isRequired,
  fieldsDef: object.isRequired,
  onSuccess: func.isRequired,
  documentForm: object.isRequired,
  validateForm: object.isRequired,
};

const defaultProps = {
  visible: false,
  parameters: {},
};

const text = {
  ADD_TITLE: 'Add Document type',
  EDIT_TITLE: 'Edit Document type',
  EDIT_SUCCESS: 'Edited Document type successfully',
  ADD_SUCCESS: 'Created Document type successfully',
  BUTTON_LABELS: type => ({
    confirm: type === 'add' ? 'Add Document type' : 'Save changes',
    cancel: 'Discard',
  }),
};

export const SIDEPANEL_ID = 'ADD_EDIT_DOCUMENT_TYPE';

const sidepanelTitle = {
  add: text.ADD_TITLE,
  edit: text.EDIT_TITLE,
};

const sidepanelIcon = {
  add: 'Plus',
  edit: 'Edit',
};

const CustomFooter = inject(stores => ({
  errors: stores.forms.documentTypeForm.errors,
}))(
  observer(({ execute, close, type, errors }) => (
    <SidepanelFooter
      execute={execute}
      close={close}
      cancelColor="ghost"
      confirmColor="primary"
      buttonLabels={text.BUTTON_LABELS(type)}
      disableSubmit={!isEmpty(errors)}
    />
  )),
);

const AddEditDocumentType = ({
  parameters,
  documentForm,
  addDocumentType,
  editDocumentType,
  onSuccess,
  fetchDocumentTypeData,
  resetFields,
  fieldsDef,
  visible,
  validateForm,
}) => {
  useEffect(() => { if (parameters.id) fetchDocumentTypeData(parameters.id); }, [parameters.id]);

  if (!visible) return null;

  const formattedFields = fieldsResolver(fieldsDef).filter(({ id }) => id !== 'id');
  const { type } = parameters;

  const close = () => {
    closeOverlay(SIDEPANEL_ID);
    resetFields();
  };

  const submit = async () => {
    if (!validateForm()) return;
    if (type === 'edit') {
      await editDocumentType();
    } else {
      await addDocumentType();
    }

    if (isEmpty(documentForm.fieldErrors)) {
      if (onSuccess) onSuccess();
      close();
    }
  };
  return (
    <Sidepanel
      title={sidepanelTitle[type]}
      type="info"
      icon={sidepanelIcon[type]}
      visible={visible}
      onCloseIconClick={close}
      footerRender={() => <CustomFooter execute={submit} close={close} type={type} />}
    >
      <Space size={18} />
      {formattedFields.map(({ id, read_only, ...props }) => (
        <Field {...props} disabled={read_only} key={id} formId="documentTypeForm" />
      ))}
    </Sidepanel>
  );
};

AddEditDocumentType.propTypes = propTypes;
AddEditDocumentType.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay.ADD_EDIT_DOCUMENT_TYPE,
  parameters: stores.overlayStore.overlay.parameters,
  fetchDocumentTypeData: stores.documentTypes.fetchDocumentTypeData,
  resetFields: stores.forms.documentTypeForm.resetFieldsData,
  fieldsDef: stores.tables.documentTypes.optionFields,
  addDocumentType: stores.documentTypes.addDocumentType,
  editDocumentType: stores.documentTypes.editDocumentType,
  documentForm: stores.forms.documentTypeForm,
  validateForm: stores.forms.documentTypeForm.validateForm,
}))(observer(AddEditDocumentType));
