import React, { Fragment, CSSProperties, FC } from 'react';
import { Row, Col, Space } from 'tc-biq-design-system';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { UAParser } from 'ua-parser-js';

import {
  ActionTypeCell,
  UserCell,
  ContactCell,
} from 'App/components/gridCellRenderers';
import RenderIcon from 'App/components/RenderIcon';
import objectType from 'App/enums/objectType';
import extractDisplayName from 'App/services/utilities/extractDisplayName';

const FLEX_COLUMN = {
  display: 'flex',
  flexDirection: 'column',
} as CSSProperties;

const FLEX_ROW = {
  display: 'flex',
  alignItems: 'center',
} as CSSProperties;

const formatBroswerName = (uastring: string) => {
  const parser = new UAParser();
  return parser.setUA(uastring).getBrowser().name;
};

export interface User {
  object_type: string;
  id: number;
  avatar: string | null;
}

export interface LogDetailProps {
  title: string;
  user?: User;
  text?: string;
  helpText?: string;
  actionType?: string;
  browserIcon?: boolean;
}

// eslint-disable-next-line
const LogDetail: FC<LogDetailProps> = ({
  title,
  user,
  text,
  helpText,
  actionType,
  browserIcon,
}) => (
  <div className="fiq-audit-log-single__detail">
    <span className="tc-micro-regular text-neutral-500">{title}</span>
    <Space size={8} />
    <div style={FLEX_ROW}>
      {user && (
        <Fragment>
          {user.object_type === objectType.CONTACT && (
            <ContactCell
              id={user.id}
              fullName={extractDisplayName(user)}
              avatar={user.avatar}
            />
          )}
          {user.object_type === objectType.USER && (
            <UserCell
              id={user.id}
              fullName={extractDisplayName(user)}
              avatar={user.avatar}
            />
          )}
          <Space size={12} />
        </Fragment>
      )}
      {browserIcon && text && (
        <Fragment>
          <RenderIcon type="browser" icon={text} />
          <Space size={4} />
        </Fragment>
      )}
      {text && (
        <div style={FLEX_COLUMN}>
          <div className="tc-paragraph-regular" style={{ width: '100%' }}>
            {text}
          </div>

          {helpText && (
            <Fragment>
              <Space size={4} />
              <span className="tc-micro-regular text-neutral-500">
                {helpText}
              </span>
            </Fragment>
          )}
          {actionType && <ActionTypeCell value={actionType} />}
        </div>
      )}
    </div>
  </div>
);

interface Target {
  id: number;
  object_type: string;
  display_name: string;
  avatar: string | null;
}

export interface UpdateValues {
  updated: string;
  updated_by: number;
  send_to_type: string | null;
  start_datetime: string | null;
  send_to_segment: number | null;
}

interface Action {
  id: number;
  object_type: string;
  display_name: string;
  avatar: string;
}

export interface LogDetailData {
  action: string;
  action_type: string;
  actor: Action;
  browser: string;
  city: string;
  country: string;
  id: number;
  ip_address: string;
  new_values: UpdateValues;
  old_values: UpdateValues;
  target: Target;
  timestamp: string;
}

const LogDetails = ({ data }: { data: LogDetailData }) => {
  if (isEmpty(data)) return null;
  return (
    <div className="fiq-audit-log-single__log-details">
      <Row gutter={0}>
        <Col sm="25%">
          <LogDetail title="Actor" user={data.actor} />
        </Col>
        <Col sm="25%">
          <LogDetail title="Target" user={data.target} />
        </Col>
        <Col sm="25%">
          <LogDetail
            title="Timestamp"
            text={moment(data.timestamp).format('DD-MM-YYYY')}
            helpText={moment(data.timestamp).format('HH:mm:ss')}
          />
        </Col>
        <Col sm="25%">
          <LogDetail
            title="Action type"
            text={data.action}
            actionType={data.action_type}
          />
        </Col>
      </Row>
      <Row gutter={0}>
        <Col sm="25%">
          <LogDetail
            title="Location"
            text={data.country}
            helpText={data.city}
          />
        </Col>
        <Col sm="25%">
          <LogDetail
            title="Browser"
            browserIcon
            text={formatBroswerName(data.browser)}
          />
        </Col>
        <Col sm="25%">
          <LogDetail title="IP address" text={data.ip_address} />
        </Col>
      </Row>
    </div>
  );
};

export default LogDetails;
