import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

function useFiltersQuery() {
  const searchQuery = new URLSearchParams(useLocation().search);
  return [searchQuery.get('filters'), searchQuery];
}

function mapFiltersQuery() {
  const [filtersStringQuery] = useFiltersQuery()
  const filtersArrayQuery = filtersStringQuery?.split(';');
  const filters = filtersArrayQuery?.reduce((result, filter) => {
    const filterKeyValueArray = filter.split('=');
    result[filterKeyValueArray[0]] = filterKeyValueArray[1];
    return result;
  }, {});
  
  return filters;
}

function mapFiltersQueryToString(filters){
  const filtersArray = [];
  for (const [filterKey, filterValue] of Object.entries(filters)) {
    filtersArray.push(`${filterKey}=${filterValue}`);
  }

  return filtersArray.join(';');
}

function changeFiltersQuery(filters, history, searchQuery) {
  if (!isEmpty(filters)) {
    const newFiltersString = mapFiltersQueryToString(filters);
    searchQuery.set('filters', newFiltersString);
  } else searchQuery.delete('filters');
  history.push({ search: searchQuery.toString() });
}

function useFilterQueryData() {
  const history = useHistory();
  const currentFilters = mapFiltersQuery();
  const [, searchQuery] = useFiltersQuery();
  const updateFiltersQuery = React.useCallback(
    (filtersToUpdate = {}) => changeFiltersQuery(filtersToUpdate, history, searchQuery), [history, searchQuery]
  );

  return [currentFilters, updateFiltersQuery];
}

export default useFilterQueryData;
