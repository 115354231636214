import React, { useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { array, func, object } from 'prop-types';

import Filters, { FiltersServiceFactory } from 'App/components/Filters';

import './NotificationsFilters.scss';

const propTypes = {
  applyFilter: func.isRequired,
  filtersStore: object.isRequired,
  activeFilters: array.isRequired,
  fetchData: func.isRequired,
};

const filtersService = FiltersServiceFactory('/users/notifications/');

const NotificationsFilters = ({ applyFilter, activeFilters, filtersStore, fetchData }) => {
  const fetchOnApply = useCallback(async (newFilter) => {
    applyFilter(newFilter);
    await fetchData();
  });

  return (
    <div className="fiq-notifications-filters">
      <Filters
        activeFilters={activeFilters}
        filtersService={filtersService}
        fetchData={fetchData}
        filtersStore={filtersStore}
        applyFilter={fetchOnApply}
        viewName={filtersStore.viewName}
        savedFilters={filtersStore.savedFilters}
      />
    </div>
  );
};

NotificationsFilters.propTypes = propTypes;

export default inject(stores => ({
  applyFilter: stores.userNotifications.filters.addNewFilter,
  filtersStore: stores.userNotifications.filters,
  fieldsDef: stores.userNotifications.fieldsDef,
  viewName: stores.userNotifications.filters.viewName,
  activeFilters: stores.userNotifications.filters.activeFilters,
}))(observer(NotificationsFilters));
