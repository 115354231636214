import { observable, action, runInAction, computed, makeObservable } from 'mobx';
import { omit, find } from 'lodash';
import moment from 'moment';
import { notifier } from 'tc-biq-design-system';

import {
  fetchTasksData,
  fetchTasksOptionData,
  addTask,
  editTask,
  deleteTask,
} from 'Tasks/services/tasksService';
import { parseParamString } from 'App/components/Filters/filterStoreUtils';
import stores from 'App/rootStore';
import formatPayload from 'App/services/utilities/formatPayload';

const text = {
  ADD_TASK: 'Task created successfully',
  ADD_TASK_ERR: 'Failed to create task!',
  EDIT_TASK: 'Edited task successfully',
  EDIT_TASK_ERR: 'Failed to edit task!',
  DELETE_TASK: 'Task deleted successfully',
  DELETE_TASK_ERR: 'Failed to delete task!',
  COMPLETE_TASK: 'Changed task state successfully',
  COMPLETE_TASK_ERR: 'Failed to change task state',
};

const dateFormat = 'YYYY-MM-DD';

const initialValues = {
  date: {
    startDate: moment()
      .subtract(7, 'days')
      .format(dateFormat),
    endDate: moment()
      .add(7, 'days')
      .format(dateFormat),
  },
  accordion: {
    data: [],
    hasMore: false,
    count: 0,
    offset: 0,
    requestInProgress: false,
  },
};

const setDueQueries = () => {
  const today = moment().format(dateFormat);
  const tomorrow = moment()
    .add(1, 'days')
    .format(dateFormat);
  const yesterday = moment()
    .subtract(1, 'days')
    .format(dateFormat);
  return {
    overDue: { due_date__lt: today, completed: false },
    pastDue: { due_date__lt: today, completed: true },
    upcoming: { due_date__gt: tomorrow },
    today: { due_date__gt: yesterday, due_date__lt: tomorrow },
  };
};

export default class TasksStore {
  constructor(FiltersStore) {
    makeObservable(this, {
      fieldDefs: observable,
      optionFields: observable,
      date: observable,
      dateQuery: observable,
      paginationQuery: observable,
      accordions: observable,
      requestInProgress: observable,
      errors: observable,
      fetchTasks: action.bound,
      fetchOptionsData: action.bound,
      resetAccordions: action.bound,
      addTask: action.bound,
      editTask: action.bound,
      deleteTask: action.bound,
      completeTask: action.bound,
      setDateQuery: action.bound,
      resetFilters: action.bound,
      query: computed,
    });

    this.filters = FiltersStore;
  }

  fieldDefs = {};

  optionFields = {};

  date = {
    ...initialValues.date,
  };

  dateQuery = {};

  paginationQuery = {
    limit: 20,
  };

  accordions = {
    overDue: { ...initialValues.accordion },
    pastDue: { ...initialValues.accordion },
    today: { ...initialValues.accordion },
    upcoming: { ...initialValues.accordion },
  };

  requestInProgress = {
    options: false,
    taskAction: false,
    deleteTask: false,
    completeTask: false,
  };

  errors = {
    tasksData: null,
    deleteTask: null,
    completeTask: null,
  };

  async fetchTasks(accordionKey, resetData = true) {
    this.accordions[accordionKey].requestInProgress = true;
    try {
      this.paginationQuery.offset = resetData ? 0 : this.accordions[accordionKey].offset;
      const response = await fetchTasksData({
        ...setDueQueries()[accordionKey],
        ...this.query,
      });
      const { next, results, count } = response.data;
      runInAction(() => {
        if (next) {
          const params = parseParamString(next);
          this.accordions[accordionKey].offset = Number(params.offset);
        }
        this.accordions[accordionKey] = {
          ...this.accordions[accordionKey],
          hasMore: !!next,
          count,
          data: resetData ? results : [...this.accordions[accordionKey].data, ...results],
          requestInProgress: false,
        };
      });
    } catch (err) {
      runInAction(() => {
        this.errors[accordionKey] = err;
        this.accordions[accordionKey].requestInProgress = false;
      });
    }
  }

  async fetchOptionsData() {
    this.requestInProgress.options = true;
    this.resetAccordions();
    try {
      const response = await fetchTasksOptionData();
      runInAction(() => {
        this.fieldDefs = omit(response.data.actions.GET, 'due_date');
        this.optionFields = response.data.actions.POST;
        this.filters.updateViewName(response.data.view);
        this.filters.updateFields(this.fieldDefs);
      });
    } catch (err) {
      runInAction(() => {
        this.errors.options = err;
      });
    } finally {
      runInAction(() => {
        this.requestInProgress.options = false;
      });
    }
  }

  resetAccordions(key) {
    if (!key) {
      this.accordions = {
        overDue: { ...initialValues.accordion },
        pastDue: { ...initialValues.accordion },
        today: { ...initialValues.accordion },
        upcoming: { ...initialValues.accordion },
      };
    } else {
      this.accordions[key] = { ...initialValues.accordion };
    }
  }

  // Task actions

  async addTask() {
    const { data, setFieldsErrors } = stores.forms.addEditTaskForm;
    if (!data.due_date) data.due_date = moment().toISOString();
    this.requestInProgress.taskAction = true;
    try {
      await addTask(formatPayload(data));
      runInAction(() => {
        this.requestInProgress.taskAction = false;
        notifier.success(text.ADD_TASK);
      });
    } catch (err) {
      setFieldsErrors(err.response.data);
      runInAction(() => {
        this.requestInProgress.taskAction = false;
        notifier.error(text.ADD_TASK_ERR);
      });
    }
  }

  async editTask(id) {
    const { data, setFieldsErrors } = stores.forms.addEditTaskForm;
    this.requestInProgress.taskAction = true;
    try {
      await editTask(id, formatPayload(data));
      runInAction(() => {
        this.requestInProgress.taskAction = false;
        notifier.success(text.EDIT_TASK);
      });
    } catch (err) {
      runInAction(() => {
        this.errors.taskAction = err.response.data;
        setFieldsErrors(err.response.data);
        this.requestInProgress.taskAction = false;
        notifier.error(text.EDIT_TASK_ERR);
      });
    }
  }

  async deleteTask(id, onSuccess) {
    this.requestInProgress.deleteTask = true;
    try {
      await deleteTask(id);
      runInAction(() => {
        this.requestInProgress.deleteTask = false;
      });
      notifier.success(text.DELETE_TASK);
      onSuccess(true);
    } catch (err) {
      runInAction(() => {
        this.errors.deleteTask = err;
        this.requestInProgress.deleteTask = false;
      });
      notifier.error(text.DELETE_TASK_ERR);
    }
  }

  async completeTask(id, accordionId) {
    this.requestInProgress.completeTask = true;
    try {
      const touchedTask = find(this.accordions[accordionId].data, { id });
      const response = await editTask(id, {
        completed: !touchedTask.completed,
      });
      runInAction(() => {
        touchedTask.completed = response.data.completed;
        this.requestInProgress.completeTask = false;
      });
      notifier.success(text.COMPLETE_TASK);
    } catch (err) {
      runInAction(() => {
        this.errors.completeTask = err;
        this.requestInProgress.completeTask = false;
      });
      notifier.error(text.COMPLETE_TASK_ERR);
    }
  }

  // Filter actions

  setDateQuery = (date) => {
    const { startDate, endDate } = date;
    const startDateObject = moment(startDate, 'YYYY-MM-DD');
    const endDateObject = moment(endDate, 'YYYY-MM-DD');
    this.date = {
      startDate,
      endDate,
    };
    this.dateQuery = {
      due_date__gte: startDateObject.subtract(1, 'days').format('YYYY-MM-DD'),
      due_date__lte: endDateObject.add(1, 'days').format('YYYY-MM-DD'),
    };
  };

  resetFilters() {
    this.date = { ...initialValues.date };
  }

  get query() {
    return {
      ...this.dateQuery,
      ...this.filters.query,
      ...this.paginationQuery,
    };
  }
}
