import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button } from 'tc-biq-design-system';
import { object, func } from 'prop-types';

import Page from 'App/components/Page';
import SegmentsTable from 'Automation/components/SegmentsTable';

const propTypes = {
  history: object.isRequired,
  removeSegment: func.isRequired,
  createAudience: func.isRequired,
};

const text = {
  TITLE: 'Segments',
  CREATE_SEGMENT: 'Create new segment',
};

const bread = [{ label: 'Automation' }, { label: text.TITLE }];

const SegmentsPage = ({ history, removeSegment, createAudience }) => {
  const headerActions = () => (
    <Button onClick={() => history.push('/automation/segments/create')}>
      {text.CREATE_SEGMENT}
    </Button>
  );
  return (
    <Page
      bread={bread}
      style={{ margin: '0 20px' }}
      headerActions={headerActions}
      title={text.TITLE}
    >
      <SegmentsTable
        history={history}
        removeSegment={removeSegment}
        createAudience={createAudience}
      />
    </Page>
  );
};

SegmentsPage.propTypes = propTypes;

export default inject(stores => ({
  removeSegment: stores.segments.removeSegment,
  createAudience: stores.segments.createAudience,
}))(observer(withRouter(SegmentsPage)));
