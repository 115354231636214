import React from 'react';
import { MultiActions, Pill } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import hideCols from 'App/services/utilities/hideCols';

import { RouteCell, UserCell, DateTimeCell } from 'App/components/gridCellRenderers';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import NoDataDash from 'App/components/NoDataDash';


const TemplateNameCell = ({ data, value }) => RouteCell('/communications/templates/')({ hrefValue: data.id, value });

// eslint-disable-next-line react/prop-types
const MultiActionCell = getActions => withRouter(({ data, history }) => {
  const actions = getActions(data, history);
  if (actions.length === 0) return null;
  return <MultiActions actions={actions} />;
});

const modifiers = actions => ({
  ...hideCols([
    'id',
    'description',
    'subject',
    'content',
    'metadata',
    'is_shadow_copy',
    'created',
    'updated',
    'event_type',
  ]),
  actions: {
    cellRendererFramework: MultiActionCell(actions),
    pinned: 'right',
    width: 50,
    unSortIcon: false,
    headerName: '',
  },
  name: { cellRendererFramework: TemplateNameCell },
  updated_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => data.updated_by ? (
      <UserCell
        id={data.updated_by.id}
        avatar={data.updated_by.avatar}
        fullName={extractDisplayName(data.updated_by)}
        subTitle={<DateTimeCell value={data.updated} />}
      />
    ) : (
      <NoDataDash />
    ),
  },
  created_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => data.created_by ? (
      <UserCell
        id={data.created_by.id}
        avatar={data.created_by.avatar}
        fullName={extractDisplayName(data.created_by)}
        subTitle={<DateTimeCell value={data.created} />}
      />
    ) : <NoDataDash />,
  },
  // eslint-disable-next-line react/prop-types
  channel: { cellRendererFramework: ({ value }) => <Pill type="neutral">{value}</Pill> },
});

export default modifiers;
