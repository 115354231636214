import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { object, func } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Col, Space } from 'tc-biq-design-system';
import isEmpty from 'lodash/isEmpty';

import Page from 'App/components/Page';
import Loader from 'App/components/Loader';
import CodeAccordion from 'App/components/CodeAccordion';
import DetailsCardWrapper from 'App/components/DetailsCardWrapper';
import { PaymentDetails } from 'Transactions/components';

import './PaymentSinglePage.scss';

const propTypes = {
  match: object.isRequired,
  fetchPaymentData: func.isRequired,
  paymentData: object.isRequired,
};

const bread = id => [
  { label: 'Transactions' },
  { label: 'Payments', route: '/transactions/payments' },
  { label: id, route: `/transactions/payments/${id}` },
];

const text = {
  PAYMENT: 'Payment',
};

class PaymentSinglePage extends PureComponent {
  constructor(props) {
    super(props);
    this.id = props.match.params.paymentId;
  }

  componentDidMount() {
    const { fetchPaymentData } = this.props;
    fetchPaymentData(this.id);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.paymentId !== match.params.paymentId) {
      const { fetchPaymentData } = this.props;
      fetchPaymentData(match.params.paymentId);
    }
  }

  render() {
    const { paymentData } = this.props;
    if (isEmpty(paymentData)) return <Loader visible />;
    const { contact, state, metadata, wallet, external_id, payment_type } = paymentData;
    return (
      <Page
        bread={bread(this.id)}
        title={`${text.PAYMENT} ${payment_type} ${external_id || ''}`}
        style={{ marginTop: '24px', overflow: 'auto' }}
      >
        <div className="fiq-payment-single">
          <Col sm="100%" lg="70%">
            <DetailsCardWrapper
              contact={contact}
              wallet={wallet}
              title={text.PAYMENT}
              status={state}
            >
              <PaymentDetails />
            </DetailsCardWrapper>
            <Space size={12} />
            <CodeAccordion data={metadata || {}} toggled />
          </Col>
        </div>
      </Page>
    );
  }
}

PaymentSinglePage.propTypes = propTypes;

export default withRouter(inject(stores => ({
  fetchPaymentData: stores.transactions.fetchPaymentData,
  paymentData: stores.transactions.paymentData,
}))(observer(PaymentSinglePage)));
