import React, { Fragment } from 'react';
import moment from 'moment';
import { Row } from 'tc-biq-design-system';
import { array, string } from 'prop-types';

import DisplayDetail from 'App/components/DisplayDetail';

const formatDate = date => moment(date, moment.ISO_8601).format('MMMM Do YYYY, \\at HH:mm');

const propTypes = {
  from_phone_number: string.isRequired,
  to_phone_number: array.isRequired,
  external_id: string.isRequired,
  created: string.isRequired,
};

const text = {
  EXTERNAL_ID: 'External ID',
  FROM_PHONE_NUMBER: 'From Phone Number',
  TO_PHONE_NUMBER: 'To Phone Number',
  CREATED: 'Created',
};

const EmailLogDetails = ({ from_phone_number, to_phone_number, external_id, created }) => (
  <Fragment>
    <Row gutter={0}>
      <div style={{ display: 'flex', width: '100%' }}>
        <DisplayDetail header={text.FROM_PHONE_NUMBER} value={from_phone_number} />
        <DisplayDetail header={text.TO_PHONE_NUMBER} value={to_phone_number} />
        <DisplayDetail header={text.EXTERNAL_ID} value={external_id} />
        <DisplayDetail header={text.TIME} value={formatDate(created)} />
      </div>
    </Row>
  </Fragment>
);

EmailLogDetails.propTypes = propTypes;

export default EmailLogDetails;
