import React, { useState } from 'react';
import { object, func } from 'prop-types';
import { Sidepanel, Icon, Space, notifier } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { bulkSetAssignees, bulkRemoveAssigness } from 'Contacts/services/contactsService';
import buildBulkParams from 'App/services/utilities/buildBulkParams';
import SidepanelFooter from 'App/components/SidepanelFooter';
import AssignTo from './AssignTo';

import './BulkAssignTo.scss';

const SIDEPANEL_ID = 'BULK_ASSIGN_TO';
const contactsTableKey = 'contacts';

const text = {
  COMPLIANCE_MANAGER: 'Compliance manager',
  COMPLIANCE_MANAGER_INFO: 'Assign new compliance managers to contacts',
  DISCARD: 'Discard changes',
  RETENTION_MANAGER: 'Retention manager',
  RETENTION_MANAGER_INFO: 'Assign new retention managers to contacts',
  SALES_MANAGER: 'Sales manager',
  SALES_MANAGER_INFO: 'Assign new sales managers to contacts',
  SAVE: 'Save changes',
  SIDEPANEL_TITLE: 'Assign to',
  SUCCESS_MESSAGE: 'Request for updating Managers is put for processing',
  ERROR_MESSAGE: 'Error while updating managers',
};

export const openBulkAssignTo = (selectedItems, selectedAll) => {
  openOverlay(SIDEPANEL_ID, { selectedItems, selectedAll });
};

export const closeBulkAssignTo = () => {
  closeOverlay(SIDEPANEL_ID);
};

const footerRender = (submit, onClose, submitInProgress) => () => (
  <SidepanelFooter
    execute={submit}
    close={onClose}
    disableSubmit={submitInProgress}
    submitInProgress={submitInProgress}
    buttonLabels={{ confirm: text.SAVE, cancel: text.DISCARD }}
    confirmColor="primary"
  />
);

const formID = 'assignToForm';

const AssignManagerSidepanel = ({ overlay, form, resetFields, onSuccess }) => {
  const [submitInProgress, setInProgress] = useState(false);

  const { selectedItems, selectedAll } = overlay.parameters || {};
  const { data } = form;

  const submit = async () => {
    const {
      complianceAssign,
      complianceRemoveAssign,
      retentionAssign,
      retentionRemoveAssign,
      salesAssign,
      salesRemoveAssign,
    } = data;
    const params = buildBulkParams(selectedItems, selectedAll, contactsTableKey);

    const payload = {
      ...params,
      ...(salesAssign && { sales_manager: salesAssign.value }),
      ...(complianceAssign && { compliance_manager: complianceAssign.value }),
      ...(retentionAssign && { retention_manager: retentionAssign.value }),
    };

    const removePayload = {
      ...params,
      ...(salesRemoveAssign && { sales_manager: salesRemoveAssign.value }),
      ...(complianceRemoveAssign && { compliance_manager: complianceRemoveAssign.value }),
      ...(retentionRemoveAssign && { retention_manager: retentionRemoveAssign.value }),
    };

    try {
      setInProgress(true);

      const requests = [];
      if (Object.keys(payload).length > 1) {
        requests.push(bulkSetAssignees(payload));
      }
      if (Object.keys(removePayload).length > 1) {
        requests.push(bulkRemoveAssigness(removePayload));
      }
      await Promise.all(requests);

      notifier.success(text.SUCCESS_MESSAGE);
      onSuccess();
      onClose();
    } catch (err) {
      setInProgress(false);
      notifier.error(text.ERROR_MESSAGE);
    }
  };

  const onClose = () => {
    closeBulkAssignTo();
    resetFields();
    setInProgress(false);
  };

  return (
    <Sidepanel
      visible={overlay[SIDEPANEL_ID]}
      footerRender={footerRender(submit, onClose, submitInProgress)}
      title={text.SIDEPANEL_TITLE}
      onCloseIconClick={onClose}
      icon="User"
    >
      <div className="info-wrapper">
        <Icon colorName="text-status03-400" name="Warning" />
        <Space size={16} />
        <span className="info-wrapper__header tc-paragraph-strong">
          “Assign to” overwrites assignees to all selected contacts.
        </span>
      </div>
      <AssignTo
        assignName="salesAssign"
        headerText={text.SALES_MANAGER_INFO}
        removeAssignName="salesRemoveAssign"
        selectedItems={selectedItems}
        teamName="salesTeam"
        title={text.SALES_MANAGER}
      />
      <AssignTo
        assignName="complianceAssign"
        headerText={text.COMPLIANCE_MANAGER_INFO}
        removeAssignName="complianceRemoveAssign"
        selectedItems={selectedItems}
        teamName="complianceTeam"
        title={text.COMPLIANCE_MANAGER}
      />
      <AssignTo
        assignName="retentionAssign"
        headerText={text.RETENTION_MANAGER_INFO}
        removeAssignName="retentionRemoveAssign"
        selectedItems={selectedItems}
        teamName="retentionTeam"
        title={text.RETENTION_MANAGER}
      />
    </Sidepanel>
  );
};

AssignManagerSidepanel.propTypes = {
  overlay: object.isRequired,
  form: object.isRequired,
  resetFields: func.isRequired,
  onSuccess: func.isRequired,
};

export default inject(stores => ({
  overlay: stores.overlayStore.overlay,
  form: stores.forms.assignToForm,
  resetFields: stores.forms[formID].resetFieldsData,
}))(observer(AssignManagerSidepanel));
