import { useCallback } from 'react';

const code = {
  ENTER: 13,
};

function useEnterPress(onEnter) {
  const onKeyPress = useCallback((e) => {
    const { charCode, keyCode, target } = e;
    if (charCode === code.ENTER || keyCode === code.ENTER) {
      const { value } = target;
      onEnter(value);
    }
  }, [onEnter]);

  return onKeyPress;
}

export default useEnterPress;
