import AddNote from './AddNote';
import actionTypes from '../types';

const text = {
  LABEL: 'Add Note',
};

const ACTION_ICON = 'Notes';

export default {
  name: 'addNote',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.TOP,
  permission: 'contacts_note',
  handler: (comboxManager) => {
    const comboxOptions = {
      title: text.LABEL,
      id: 'add-note-combox',
    };

    comboxManager.add(AddNote, comboxOptions);
  },
};
