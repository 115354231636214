import hideCols from 'App/services/utilities/hideCols';

import { RouteCell, StatusCell, PaymentTypeCell } from 'App/components/gridCellRenderers';

export default {
  ...hideCols(['card', 'wallet', 'contact', 'processor', 'method']),
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/transactions/payments/'),
  },
  state: { cellRendererFramework: StatusCell },
  compliance_check: { cellRendererFramework: StatusCell },
  type: { cellRendererFramework: PaymentTypeCell },
  sortable: false,
};
