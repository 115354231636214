import React, { useState, useEffect, PureComponent, Fragment } from 'react';
import { func, object, bool, string, number } from 'prop-types';
import { ComboxFooter, Row, Col } from 'tc-biq-design-system';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import Field, { fieldsResolverObject } from 'App/components/FieldRenderer';
import Loader from 'App/components/Loader';
import http from 'App/services/http';

import './LogCall.scss';

const text = {
  CONFIRM_TEXT: 'Save log activity',
  DESCRTPTION: 'Description',
};

const DescriptionField = () => (
  <Field
    id="description"
    name="description"
    type="textarea"
    validate="required"
    label={text.DESCRTPTION}
    formId="logCall"
    className="tc-paragraph-regular"
    rows={10}
  />
);

const ObserverComboxFooter = inject(stores => ({
  error: stores.contact.profile.errors.logCall,
  logCall: stores.contact.profile.logCall,
  requestInProgress: stores.contact.profile.requestInProgress.logCall,
  data: toJS(stores.forms.logCall.data),
  validateForm: stores.forms.logCall.validateForm,
}))(observer(
  class ObserverComboxFooter extends PureComponent {
    onSuccess = async () => {
      const { logCall, validateForm } = this.props;
      if (!validateForm()) return;
      await logCall();
      this.close();
    };

    close = () => {
      const { closeCombox, error } = this.props;
      if (closeCombox && !error) closeCombox();
    };

    render() {
      const { requestInProgress, data } = this.props;
      const isDisabled = !data.destination_phone_number || !data.call_date;

      return (
        <ComboxFooter
          onConfirm={this.onSuccess}
          isLoading={requestInProgress}
          confirmText={text.CONFIRM_TEXT}
          isDisabled={isDisabled}
        />
      );
    }
  },
));


const PhoneField = inject(stores => ({
  contact: stores.forms.logCall.data.contact,
  updateFieldValue: stores.forms.logCall.updateFieldValue,
}))(
  observer(({ fieldProps, contact, updateFieldValue }) => {
    if (contact && contact.phone) {
      updateFieldValue('destination_phone_number', contact.phone);
    } else {
      updateFieldValue('destination_phone_number', '');
    }
    return <Field {...fieldProps} type="integer" formId="logCall" />;
  }),
);

ObserverComboxFooter.wrappedComponent.propTypes = {
  closeCombox: func,
  error: object,
  logCall: func,
  requestInProgress: bool,
  data: object,
  validateForm: func.isRequired,
};

ObserverComboxFooter.wrappedComponent.defaultProps = {
  closeCombox: null,
  error: null,
  logCall: null,
  requestInProgress: false,
  data: {},
};

const LogCall = ({ resetFields, closeCombox, contactPhone, updateFieldValue, contactId }) => {
  const [fieldsDef, setFieldsDef] = useState(null);
  const [loadingFieldsDef, setLoadingFieldsDef] = useState(false);

  const fetchFieldsDef = async () => {
    setLoadingFieldsDef(true);
    const response = await http.options('communications/call-logs/');
    setFieldsDef(fieldsResolverObject(response.data.actions.POST));
    setLoadingFieldsDef(false);
    if (contactId) setInitialData();
    const todaysDate = moment().format('YYYY-MM-DD');
    updateFieldValue('call_date', todaysDate);
  };

  const setInitialData = () => {
    updateFieldValue('contact', contactId);
    updateFieldValue('destination_phone_number', contactPhone);
  };

  useEffect(() => resetFields());
  useEffect(() => {
    fetchFieldsDef();
  }, []);

  return (
    <div className="fiq-logcall-combox">
      <Loader visible={loadingFieldsDef} />
      {!loadingFieldsDef && fieldsDef && (
        <Fragment>
          {!contactId && (
            <Row gutter={0}>
              <Col xs="50%">
                <Field
                  label="Contact"
                  id="contact"
                  name="contact"
                  type="related field"
                  valueKey="value"
                  labelKey="display_name"
                  related_model={{
                    display_name: 'Contact',
                    value: 'contacts_contact',
                  }}
                  formId="logCall"
                />
              </Col>
            </Row>
          )}
          <Row gutter={0}>
            <Col xs="40%">
              <PhoneField fieldProps={fieldsDef.destination_phone_number} />
            </Col>
            <Col xs="60%">
              <Field {...fieldsDef.outcome} formId="logCall" />
            </Col>
          </Row>
          <Row gutter={0}>
            <Col xs="40%">
              <Field {...fieldsDef.call_date} formId="logCall" />
            </Col>
            <Col xs="30%">
              <Field {...fieldsDef.start_time} type="time" formId="logCall" />
            </Col>
            <Col xs="30%">
              <Field {...fieldsDef.end_time} type="time" formId="logCall" />
            </Col>
          </Row>
          <Row gutter={0}>
            <Col xs="100%">
              <DescriptionField />
            </Col>
          </Row>
        </Fragment>
      )}
      <ObserverComboxFooter closeCombox={closeCombox} />
    </div>
  );
};

LogCall.propTypes = {
  resetFields: func.isRequired,
  closeCombox: func.isRequired,
  contactPhone: string,
  updateFieldValue: func.isRequired,
  contactId: number,
};

LogCall.defaultProps = {
  contactPhone: '',
  contactId: null,
};

export default inject(stores => ({
  resetFields: stores.forms.logCall.resetFieldsData,
  contactPhone: stores.contact.profile.contactData.phone,
  contactId: stores.contact.profile.contactData.id,
  updateFieldValue: stores.forms.logCall.updateFieldValue,
}))(observer(LogCall));
