import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import GridComponentFactory from 'App/components/grid';

import './index.scss';
import { hasAccess } from 'App/services/permissionsService';
import modifiers from './modifiers';

const tableConfig = {
  tableKey: 'segments',
  tableUrl: '/automations/segments/',
};

const text = {
  EDIT: 'Edit segment',
  CLONE: 'Clone segment',
  DELETE: 'Delete segment',
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google',
};

const adNetworkType = {
  FACEBOOK_ADS: 'Facebook Ads',
  GOOGLE_ADS: 'Google Ads',
};

const SegmentPopultingStatus = 'Populating';

const sizeOptions = [15, 30, 50, 100, 200];

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const listToOmit = ['description', 'created', 'updated'];

const customColumns = [
  {
    key: 'actions',
    headerName: '',
  },
];

const SegmentsTable = ({ history, removeSegment, createAudience }) => {
  const onSuccess = gridActions.fetchTableData;

  const actions = data => [
    {
      icon: 'Edit',
      label: text.EDIT,
      action: ({ id }) => {
        history.push(`/automation/segments/${id}`);
      },
      visible: hasAccess('automations_segment', 'update'),
    },
    {
      icon: 'Duplicate',
      label: text.CLONE,
      action: ({ id }) => {
        history.push({ pathname: '/automation/segments/create', state: { id } });
      },
      visible: hasAccess('automations_segment', 'create'),
    },
    {
      icon: 'Delete',
      label: text.DELETE,
      action: ({ id }) => {
        removeSegment(id, onSuccess);
      },
      visible:
          data.status !== SegmentPopultingStatus && hasAccess('automations_segment', 'delete'),
    },
    {
      header: true,
      label: 'Create Ad Audience',
      visible: hasAccess('automations_audience', 'create'),
      subActions: [
        {
          icon: 'Facebook',
          label: text.FACEBOOK,
          action: ({ id, audiences }) => {
            createAudience(id, audiences, adNetworkType.FACEBOOK_ADS, onSuccess);
          },
        },
        {
          icon: 'GoogleAds',
          label: text.GOOGLE,
          action: ({ id, audiences }) => {
            createAudience(id, audiences, adNetworkType.GOOGLE_ADS, onSuccess);
          },
        },
      ],
    },
  ].filter(({ visible }) => visible);

  return (
    <div className="segments-table">
      <GridComponent
        sizeOptions={sizeOptions}
        customColumns={customColumns}
        modifiers={modifiers(actions)}
        defaultPageSize={15}
        listToOmit={listToOmit}
        height="62vh"
      />
    </div>
  );
};

SegmentsTable.propTypes = {
  history: object.isRequired,
  removeSegment: func.isRequired,
  createAudience: func.isRequired,
};

export default SegmentsTable;
