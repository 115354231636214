import React, { Component } from 'react';
import { object } from 'prop-types';
import { Accordion, Code, Pill, Icon } from 'tc-biq-design-system';

import If from 'App/components/If';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

const kycStates = {
  INITIALIZED: 1,
  EXECUTING: 'Executing',
  PENDING_RESPONSE: 3,
  FINISHED: 4,
  ERROR: 5,
  MANUALLY_VALIDATED: 6,
  MANUALLY_FAILED: 7,
};

const statePillTypes = {
  [kycStates.INITIALIZED]: 'primary',
  [kycStates.EXECUTING]: 'primary',
  [kycStates.PENDING_RESPONSE]: 'status02',
  [kycStates.FINISHED]: 'status01',
  [kycStates.ERROR]: 'status04',
  [kycStates.MANUALLY_VALIDATED]: 'brand',
  [kycStates.MANUALLY_FAILED]: 'status04',
};

const propTypes = {
  kycCheck: object,
};

const defaultProps = {
  kycCheck: {},
};

class KycAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  toggleVisibility = () => {
    this.setState(({ visible }) => ({ visible: !visible }));
  };

  render() {
    const { visible } = this.state;
    const { kycCheck } = this.props;

    const { id, validation_gateway, running_status, communication } = kycCheck;

    const HeaderTemplate = (
      <div key={id} className="fiq-kyc__header" onClick={this.toggleVisibility}>
        <span className="fiq-kyc__title tc-paragraph-strong">
          {validation_gateway.toUpperCase()}
        </span>
        <span className="fiq-kyc__state-pill">
          <Pill type={statePillTypes[running_status]}>{running_status}</Pill>
        </span>
        <span className="fiq-kyc__header__caret-icon">
          <If condition={!visible}>
            <Icon name="CaretRight" />
          </If>

          <If condition={visible}>
            <Icon name="CaretDown" />
          </If>
        </span>
      </div>
    );

    return (
      <Accordion headerTemplate={HeaderTemplate} visible={visible}>
        <Code>{JSON.stringify(communication, undefined, 2)}</Code>
      </Accordion>
    );
  }
}

KycAccordion.propTypes = propTypes;
KycAccordion.defaultProps = defaultProps;
export default withErrorBoundary(KycAccordion);
