/* eslint-disable react/prop-types */
import React from 'react';
import { MultiActions, Pill, Space, Button } from 'tc-biq-design-system';
import hideCols from 'App/services/utilities/hideCols';
import RenderIcon from 'App/components/RenderIcon';
import { authorizeAdNetwork } from 'Settings/Sections/Integrations/services/utils';

const tokenExpiredSyncStatus = 'Access Token Expired';

const text = {
  RE_AUTHORIZE: 'Re-Authorize',
};

const ActionRenderer = (data, actions = []) => {
  const hasActions = !!actions.length;
  const gridActions = actions.map(a => ({
    ...a,
    action: () => a.action(data),
  }));
  return hasActions ? <MultiActions actions={gridActions} /> : null;
};

const AdNetworkCell = ({ data }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <RenderIcon icon={audienceTypeIconNameMap[data.ad_network]} iconSize={20} />
    <Space size={6} />
    {data.ad_network}
  </div>
);

const SyncedPill = (value, segmentContacts) => (
  <Pill type="neutral">
    {`${value && segmentContacts ? Math.round((value / segmentContacts) * 100) : 0}%`}
  </Pill>
);

const audienceTypeIconNameMap = {
  'Facebook Ads': 'Facebook',
  'Google Ads': 'GoogleAds',
};

const syncStatusPillTypeMap = {
  Queued: 'neutral',
  Syncing: 'status01',
  Synced: 'status01',
  'Sync Failed': 'status04',
  'Access Token Expired': 'status04',
};

const AudiencesRenderer = ({ data }) => (
  <div className="segments-table__audiences">
    <Pill type={syncStatusPillTypeMap[data.sync_status]}>{data.sync_status}</Pill>
    {data.sync_status === tokenExpiredSyncStatus && (
      <Button size="small" color="ghost" onClick={() => authorizeAdNetwork(data.ad_network)}>
        {text.RE_AUTHORIZE}
      </Button>
    )}
  </div>
);

export default (segmentContacts, actions) => ({
  ...hideCols(['id', 'segment', 'external_id']),
  ad_network: {
    headerName: 'Network',
    cellRendererFramework: AdNetworkCell,
  },
  sync_status: {
    headerName: 'Status',
    cellRendererFramework: AudiencesRenderer,
  },
  contacts_synced: {
    headerName: 'Synced',
    cellRendererFramework: ({ value }) => SyncedPill(value, segmentContacts),
  },
  updated: { headerName: 'Last sync' },
  actions: {
    cellRendererFramework: ({ data }) => ActionRenderer(data, actions),
    pinned: 'right',
    width: 25,
    unSortIcon: false,
    headerName: '',
  },
});
