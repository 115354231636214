import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { array, func, number, string, oneOfType, bool } from 'prop-types';

import Loader from 'App/components/Loader';
import NoData from 'App/components/NoData';
import If from 'App/components/If';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import KycAccordion from './KycAccordion';

import './KYC.scss';

const propTypes = {
  fetchKycData: func.isRequired,
  kycData: array.isRequired,
  id: oneOfType([string, number]).isRequired,
  requestInProgress: bool.isRequired,
};

const text = {
  NO_DATA: 'No KYC data',
};

const KYC = inject(stores => ({
  fetchKycData: stores.contact.kyc.fetchKycData,
  kycData: stores.contact.kyc.kycData,
  requestInProgress: stores.contact.kyc.requestInProgress,
}))(observer(
  class KYC extends PureComponent {
    componentDidMount() {
      this.fetchKycData();
    }

    fetchKycData = async () => {
      const { fetchKycData, id } = this.props;
      await fetchKycData(id);
    };

    render() {
      const { kycData, requestInProgress } = this.props;
      const data = kycData.slice();
      return (
        <div className="fiq-kyc">
          <Loader visible={requestInProgress} />
          <If condition={!requestInProgress && !!data.length}>
            {data.map(kyc => (
              <KycAccordion kycCheck={kyc} key={kyc.id} />
            ))}
          </If>
          <If condition={!requestInProgress && !data.length}>
            <div className="fiq-profile__sections__wrapper">
              <NoData text={text.NO_DATA} />
            </div>
          </If>
        </div>
      );
    }
  },
));


KYC.wrappedComponent.propTypes = propTypes;

export default withErrorBoundary(KYC);
