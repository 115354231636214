/* eslint-disable import/prefer-default-export */
import isEmpty from 'validator/es/lib/isEmpty';
import isEmail from 'validator/es/lib/isEmail';
import isString from 'lodash/isString';

const validatorEnums = {
  REQUIRED: 'required',
  EMAIL: 'email',
};

const messages = {
  [validatorEnums.REQUIRED]: 'This field is required',
  [validatorEnums.EMAIL]: 'Please enter valid email address',
};

const validatorMapper = {
  [validatorEnums.REQUIRED]: value => (isString(value) ? !isEmpty(value) : value !== undefined),
  [validatorEnums.EMAIL]: isEmail,
};

const parseValidators = (validators) => {
  const validatorList = validators.split('|');
  return validatorList.map(key => ({
    validator: validatorMapper[key],
    message: messages[key],
  }));
};

const validate = (value, validatorRules) => {
  const validators = parseValidators(validatorRules);
  const errors = validators.reduce((acc, { validator, message }) => {
    const isValid = validator(value || '');
    if (!isValid) return [...acc, message];
    return acc;
  }, []);

  const isValid = errors.length === 0;
  return [isValid, errors];
};

export {
  validate,
};
