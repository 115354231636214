import React from 'react';
import { bool, string, object } from 'prop-types';
import { Modal } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';

import { closeOverlay, openOverlay } from 'App/services/overlayService';

import './PreviewMessage.scss';

const SIDEPANEL_ID = 'PREVIEW_CAMPAIGN_MESSAGE';

export const closePreviewMessage = () => closeOverlay(SIDEPANEL_ID);
export const openPreviewMessage = customMessage => openOverlay(SIDEPANEL_ID, { customMessage });

const propTypes = {
  visible: bool,
  message: string,
  channel: object,
  parameters: object,
};

const defaultProps = {
  visible: false,
  message: '',
  channel: null,
  parameters: null,
};

const text = {
  TITLE: 'Preview HTML',
  PREVIEW_TEMPLATE: 'Template preview',
};

const PreviewMessage = ({
  visible,
  message,
  channel,
  parameters,
}) => (
  <Modal
    closable
    title={(parameters?.customMessage || channel) ? text.PREVIEW_TEMPLATE : text.TITLE}
    icon="Code"
    visible={visible}
    onCloseIconClick={closePreviewMessage}
    footerRender={() => null}
    size="large"
  >
    <div className="fiq-campaigns-preview-message">{ReactHtmlParser(parameters?.customMessage || message)}</div>
  </Modal>
);

PreviewMessage.propTypes = propTypes;
PreviewMessage.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  message: stores.forms.channelEmailForm.data?.content
  || stores.forms.channelMessengerForm.data?.template?.content,
  channel: stores.forms.campaignForm.data.channel,
}))(observer(PreviewMessage));
