import React, { useState } from 'react';
import { boolean, string, any } from 'prop-types';
import { Accordion, Icon, Space } from 'tc-biq-design-system';
import classNames from 'classnames';

import './DataAccordion.scss';

const propTypes = {
  toggled: boolean,
  title: string,
  children: any.isRequired,
};

const defaultProps = {
  toggled: false,
  title: 'Metadata',
};

const DataAccordion = ({ children, toggled, title }) => {
  const [visible, toggleAccordion] = useState(toggled);

  const HeaderTemplate = (
    <div
      className={classNames('fiq-meta-accordion__header', { open: visible })}
      onClick={() => toggleAccordion(!visible)}
    >
      <span className="fiq-meta-accordion__header__caret-icon">
        {visible ? <Icon name="CaretDown" /> : <Icon name="CaretRight" />}
      </span>
      <Space size={12} />
      <span className="tc-heading-s">{title}</span>
    </div>
  );

  return (
    <Accordion headerTemplate={HeaderTemplate} visible={visible} className="accordion-override">
      <div data-recording-sensitive className="fiq-meta-accordion__body">{children}</div>
    </Accordion>
  );
};

DataAccordion.propTypes = propTypes;
DataAccordion.defaultProps = defaultProps;

export default DataAccordion;
