import React from 'react';
import {
  ContactCell,
  PaymentTypeCell,
  RouteCell,
  StatusCell,
  WalletCell,
  AmountCell,
} from 'App/components/gridCellRenderers';
import NoDataDash from 'App/components/NoDataDash';

export default {
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/transactions/payments/'),
  },
  state: { cellRendererFramework: StatusCell },
  compliance_check: { cellRendererFramework: StatusCell },
  payment_type: { cellRendererFramework: PaymentTypeCell },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => <WalletCell data={value} />,
  },
  contact: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => value ? (
      <ContactCell
        id={value.id}
        avatar={value.avatar}
        fullName={`${value.first_name} ${value.last_name}`}
      />
    ) : (
      <NoDataDash />
    ),
  },
  card: {
    cellRendererFramework: RouteCell('/transactions/cards/'),
  },
  amount: {
    cellRendererFramework: AmountCell,
  },
  sortable: false,
};
