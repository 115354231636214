export default [
  {
    minH: 1,
    minW: 1,
    i: 'lead-to-live-numeric',
    h: 2,
    moved: false,
    maxW: 4,
    maxH: 3,
    static: true,
    w: 1,
    y: 0,
    x: 0,
  },
  {
    minH: 1,
    minW: 1,
    i: 'demo-to-live-numeric',
    h: 2,
    moved: false,
    maxW: 4,
    maxH: 3,
    static: true,
    w: 1,
    y: 0,
    x: 1,
  },
  {
    minH: 1,
    i: 'calls-numeric',
    h: 2,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 1,
    y: 0,
    x: 2,
    minW: 1,
  },
  {
    minH: 1,
    i: 'emails-numeric',
    h: 2,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 1,
    y: 0,
    x: 3,
    minW: 1,
  },
  {
    minH: 1,
    i: 'sms-numeric',
    h: 2,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 1,
    y: 0,
    x: 4,
    minW: 1,
  },
  {
    minH: 1,
    i: 'whats-app-numeric',
    h: 2,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 1,
    y: 0,
    x: 5,
    minW: 1,
  },
  {
    minH: 1,
    i: 'interactions',
    h: 4,
    moved: false,
    maxW: 5,
    maxH: 4,
    static: true,
    w: 4,
    y: 2,
    x: 2,
    minW: 1,
  },
  {
    minH: 1,
    i: 'high-value-contacts',
    h: 4,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 2,
    y: 6,
    x: 2,
    minW: 1,
  },
  {
    minH: 1,
    i: 'low-value-contacts',
    h: 4,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 2,
    y: 10,
    x: 2,
    minW: 1,
  },
  {
    minH: 1,
    i: 'contacts-by-state-chart',
    h: 4,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 2,
    y: 6,
    x: 4,
    minW: 1,
  },
  {
    minH: 1,
    i: 'contacts-per-status-chart',
    h: 4,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 2,
    y: 10,
    x: 4,
    minW: 1,
  },
  {
    minH: 1,
    i: 'new-contacts-numeric',
    h: 2,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 1,
    y: 6,
    x: 0,
    minW: 1,
  },
  {
    minH: 1,
    i: 'new-contacts',
    h: 4,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 2,
    y: 8,
    x: 0,
    minW: 1,
  },
  {
    minH: 1,
    i: 'leads-numeric',
    h: 2,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 1,
    y: 6,
    x: 1,
    minW: 1,
  },
  {
    minH: 1,
    i: 'leads-chart',
    h: 4,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 2,
    y: 2,
    x: 0,
    minW: 1,
  },
  {
    minH: 1,
    i: 'active-contacts',
    h: 4,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 2,
    y: 12,
    x: 0,
    minW: 1,
  },
  {
    minH: 1,
    i: 'active-contacts-numeric',
    h: 2,
    moved: false,
    maxW: 3,
    maxH: 4,
    static: true,
    w: 1,
    y: 14,
    x: 2,
    minW: 1,
  },
];
