import React from 'react';
import { string, array, bool } from 'prop-types';
import { Block } from 'tc-biq-design-system';
import { openOverlay } from 'App/services/overlayService';

import * as RenderIcons from 'App/components/RenderIcon/components';
import { hasAccess } from 'App/services/permissionsService';
import nylas from 'assets/images/nylas_logo.svg';
import helpscout from 'assets/images/helpscout-logo.svg';
import zendesk from 'assets/images/zendesk-logo.svg';
import facebook from 'assets/images/facebook-logo.svg';
import googleAds from 'assets/images/google-logo.svg';
import zapier from 'assets/images/zapier-logo.svg';

const propTypes = {
  name: string.isRequired,
  description: string.isRequired,
  config: array.isRequired,
  is_configured: bool.isRequired,
};

const SIDEPANEL_ID = 'EDIT_INTEGRATION';

const colorMapper = {
  SendGrid: 'text-primary-500',
  Twilio: 'text-status04-400',
  BrokerCloud: 'text-primary-700',
  BrokerIQ: 'text-primary-700',
};

const logoMapper = {
  Nylas: nylas,
  HelpScout: helpscout,
  Zendesk: zendesk,
  FacebookAds: facebook,
  GoogleAds: googleAds,
  Zapier: zapier,
};

const IntegrationBlock = ({ name, description, config, is_configured }) => {
  const footerButtons = is_configured
    ? [
      {
        buttonType: 'pill',
        text: 'Configured',
        type: 'status01',
        big: true,
        style: { fontWeight: '400', pointerEvents: 'None' },
        icon: 'Checkmark',
        iconPosition: 'left',
      },
    ]
    : [
      {
        buttonType: 'button',
        text: 'Configure',
        color: 'ghost',
        onClick: () => openOverlay(SIDEPANEL_ID, { config, name, description }),
        style: { fontWeight: '400', color: '#292D33', border: '1px solid #C3CDD9' },
      },
    ];

  const footerActions = is_configured && hasAccess('constance_config', 'update')
    ? [
      {
        colorName: 'text-primary-900',
        name: 'Edit',
        onClick: () => openOverlay(SIDEPANEL_ID, { config, name, description }),
      },
    ]
    : [];

  return (
    <Block
      title={name}
      description={description}
      footerActions={footerActions}
      footerButtons={hasAccess('constance_config', 'update') ? footerButtons : []}
      svgLogo={RenderIcons[name]}
      svgColor={colorMapper[name]}
      imgSrc={logoMapper[name]}
    />
  );
};

IntegrationBlock.propTypes = propTypes;

export default IntegrationBlock;
