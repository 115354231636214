import React, { useState } from 'react';
import { Modal, Button, Space, Icon, Pill } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { bool, object, func } from 'prop-types';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import Field from 'App/components/FieldRenderer';

import './ViewDoc.scss';

const propTypes = {
  visible: bool.isRequired,
  parameters: object,
  reviewDoc: func.isRequired,
  requestInProgress: bool.isRequired,
  fetchContactDocuments: func.isRequired,
};

const defaultProps = {
  parameters: {},
};

const MODAL_ID = 'PREVIEW_DOC';

export const openViewDoc = doc => openOverlay(MODAL_ID, { doc });
export const closeViewDoc = () => closeOverlay(MODAL_ID);

const text = {
  REJECT: 'Reject',
  REJECTED: 'Rejected',
  APPROVE: 'Approve',
  CANCEL: 'Cancel',
  REJECT_REASON: 'Rejection reason',
  PDF_PREVIEW: 'Click to preview document',
};

const ApproveRejectAction = ({
  rejectDoc,
  approveDoc,
  rejectFooter,
  toggleRejectFooter,
  requestInProgress,
}) => (
  <div className="fiq-documents__review-doc">
    {rejectFooter && (
      <Button color="ghost" onClick={toggleRejectFooter}>
        {text.CANCEL}
      </Button>
    )}
    <Button
      icon="Close"
      color="destructive"
      onClick={rejectFooter ? rejectDoc : toggleRejectFooter}
      disabled={requestInProgress}
      loading={requestInProgress}
    >
      {text.REJECT}
    </Button>
    <Space size={12} />
    {!rejectFooter && (
      <Button
        icon="Checkmark"
        color="confirmation"
        onClick={approveDoc}
        disabled={requestInProgress}
        loading={requestInProgress}
      >
        {text.APPROVE}
      </Button>
    )}
  </div>
);

ApproveRejectAction.propTypes = {
  rejectDoc: func.isRequired,
  approveDoc: func.isRequired,
  rejectFooter: bool.isRequired,
  toggleRejectFooter: func.isRequired,
  requestInProgress: bool.isRequired,
};

const customFooter = (
  rejectReason,
  status,
  approveDoc,
  rejectDoc,
  toggleRejectForm,
  rejectForm,
  requestInProgress,
) => () => {
  if (status === 'Pending') {
    return (
      <ApproveRejectAction
        approveDoc={approveDoc}
        rejectDoc={rejectDoc}
        toggleRejectFooter={toggleRejectForm}
        rejectFooter={rejectForm}
        requestInProgress={requestInProgress}
      />
    );
  }
  return (
    <div className="fiq-documents__view-doc">
      {rejectReason && (
        <div className="fiq-documents__doc-reject-reason">
          <Icon name="Reject" colorName="text-status04-500" />
          <Space size={8} />
          <span className="text-status04-500 tc-paragraph-strong">{rejectReason}</span>
          <div style={{ width: '100%' }} />
          <Pill type="status04">{text.REJECTED}</Pill>
        </div>
      )}
    </div>
  );
};

const isPDF = fileName => fileName.toLowerCase().endsWith('.pdf');

const renderFile = (url, fileName, downloadUrl) => {

  if (fileName && isPDF(fileName)) {
    return (
      <span>
        <h3>{`${text.PDF_PREVIEW}:`}</h3>
        <div>
          <a target="_blank" rel="noopener noreferrer" href={url}>
            {fileName}
          </a>
          <a title="Download" target="_blank" download={fileName} href={downloadUrl}><Icon style={{cursor: 'pointer', marginLeft: '10px'}} name="Download"/></a>
        </div>
      </span>
    );
  }
  return (
    <div className="file-content">
      <img src={url} alt="" />
      <div>     
        <span>{fileName}</span>
        <a title="Download" target="_blank" download={fileName} href={downloadUrl}><Icon style={{cursor: 'pointer', marginLeft: '10px'}} name="Download"/></a>
      </div>
    </div>
  );
};

const ViewDoc = ({ visible, parameters, reviewDoc, requestInProgress, fetchContactDocuments }) => {
  const [rejectForm, toggleRejectForm] = useState(false);
  const toggleRejectFooter = () => toggleRejectForm(reject => !reject);
  if (!parameters.doc) return null;
  const { doc, type } = parameters;
  const title = doc && doc.document_type && doc.document_type.name;
  const close = () => {
    closeViewDoc();
    if (type !== 'preview') fetchContactDocuments();
    toggleRejectForm(false);
  };
  const approveDocument = async () => {
    await reviewDoc(doc.id, 'approve');
    close();
  };
  const rejectDocument = async () => {
    await reviewDoc(doc.id, 'reject');
    close();
  };

  return (
    <Modal
      closable
      title={title || ''}
      icon="Duplicate"
      visible={visible}
      onCloseIconClick={close}
      footerRender={customFooter(
        doc.rejection_reason,
        doc.status,
        approveDocument,
        rejectDocument,
        toggleRejectFooter,
        rejectForm,
        requestInProgress,
      )}
    >
      <div data-recording-disable>
        {doc && doc.file && renderFile(doc.file, doc.filename, doc.file_download)}
        <Space size={24} />
        {doc && doc.file_backside && renderFile(doc.file_backside, doc.filename_backside, doc.file_backside_download)}
        {rejectForm && !doc.rejection_reason && (
          <Field
            type="text"
            formId="rejectDocForm"
            label={text.REJECT_REASON}
            name="reject_reason"
            validate="required"
          />
        )}
      </div>
    </Modal>
  );
};

ViewDoc.propTypes = propTypes;
ViewDoc.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[MODAL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  reviewDoc: stores.contact.documents.reviewDocument,
  requestInProgress: stores.contact.documents.requestInProgress.review,
  fetchContactDocuments: stores.contact.documents.fetchContactDocuments,
}))(observer(ViewDoc));
