import React from 'react';
import { string } from 'prop-types';
import { Icon, Space } from 'tc-biq-design-system';

const propTypes = {
  label: string.isRequired,
};

const text = {
  NO_MORE: label => `No ${label} tasks`,
};

const NoTasksBar = ({ label }) => (
  <div className="fiq-tasks__no-tasks">
    <Icon name="Warning" colorName="text-status03-400" />
    <Space size={12} />
    <div className="tc-paragraph-strong">{text.NO_MORE(label)}</div>
  </div>
);

NoTasksBar.propTypes = propTypes;

export default NoTasksBar;
