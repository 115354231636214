import React from 'react';
import RenderIcon from 'App/components/RenderIcon';

const positions = {
  TOP: 'top',
  BOTTOM: 'bottom',
} as const;

export interface Item {
  label?: string;
  id: string;
  iconActive?: () => JSX.Element;
  icon?: () => JSX.Element;
  route?: string;
  position?: 'top' | 'bottom';
  subItems?: SubItem[];
  style?: string;
  sections?: SubItem[][];
  separator?: boolean;
  permission?: string;
}

export interface SubItem {
    label: string;
    id: string;
    route: string;
    permission: string;
}

const routes = [
  {
    label: 'Dashboard',
    id: 'dashboard',
    iconActive: () => <RenderIcon icon="DashboardActive" iconSize={22} />,
    icon: () => <RenderIcon icon="Dashboard" />,
    position: positions.TOP,
    subItems: [
      {
        label: 'Finance',
        id: 'finance',
        route: '/dashboard/finance',
        permission: 'finances_paymenttransaction',
      },
      {
        label: 'Sales',
        id: 'sales',
        route: '/dashboard/sales',
        permission: 'events_activity',
      },
      {
        label: 'Marketing ',
        id: 'marketing',
        route: '/dashboard/marketing',
        permission: 'marketing_campaign',
      },
    ],
  },
  {
    label: 'Activity stream',
    id: 'activity_stream',
    iconActive: () => <RenderIcon icon="ActivityStreamActive" />,
    icon: () => <RenderIcon icon="ActivityStream" />,
    route: '/activity-stream',
    permission: 'events_activity',
  },
  {
    label: 'Tasks',
    id: 'tasks',
    iconActive: () => <RenderIcon icon="TasksActive" />,
    icon: () => <RenderIcon icon="Tasks" />,
    route: '/tasks',
    permission: 'tasks_task',
    position: positions.TOP,
  },
  { separator: true, position: positions.TOP, id: 'separator1' },
  {
    label: 'Campaigns',
    id: 'campaigns',
    iconActive: () => <RenderIcon icon="CampaignsActive" />,
    icon: () => <RenderIcon icon="Campaigns" />,
    route: '/marketing/campaigns',
    permission: 'marketing_campaign',
    position: positions.TOP,
  },
  {
    label: 'Tracking links',
    id: 'tracking_links',
    iconActive: () => <RenderIcon icon="TrackingLinkActive" />,
    icon: () => <RenderIcon icon="TrackingLink" />,
    route: '/marketing/tracking-links',
    permission: 'marketing_trackinglink',
    position: positions.TOP,
  },
  { separator: true, position: positions.TOP, id: 'separator2' },
  {
    label: 'Contacts',
    id: 'contacts',
    iconActive: () => <RenderIcon icon="ContactsActive" />,
    icon: () => <RenderIcon icon="Contacts" />,
    route: '/contacts',
    permission: 'contacts_contact',
    position: positions.TOP,
  },
  {
    label: 'Segments',
    id: 'segments',
    iconActive: () => <RenderIcon icon="SegmentsActive" iconSize={28} />,
    icon: () => <RenderIcon icon="Segments" />,
    route: '/automation/segments',
    permission: 'automations_segment',
    position: positions.TOP,
  },
  {
    label: 'Rules',
    id: 'rules',
    route: '/automation/rules',
    iconActive: () => <RenderIcon icon="RulesActive" iconSize={28} />,
    icon: () => <RenderIcon icon="Rules" />,
    permission: 'automations_rule',
    position: positions.TOP,
  },
  { separator: true, position: positions.BOTTOM, id: 'separator3' },
  {
    label: 'Transactions',
    id: 'transactions',
    iconActive: () => <RenderIcon icon="TransactionsActive" />,
    icon: () => <RenderIcon icon="Transactions" />,
    position: positions.BOTTOM,
    subItems: [
      {
        label: 'Trades',
        id: 'trades',
        route: '/transactions/trades',
        permission: 'finances_tradetransaction',
      },
      {
        label: 'Orders',
        id: 'orders',
        route: '/transactions/orders',
        permission: 'finances_tradeorder',
      },
      {
        label: 'Positions',
        id: 'positions',
        route: '/transactions/positions',
        permission: 'finances_tradeposition',
      },
      {
        label: 'Wallets',
        id: 'wallets',
        route: '/transactions/wallets',
        permission: 'finances_wallet',
      },
      {
        label: 'Payments',
        id: 'payments',
        route: '/transactions/payments',
        permission: 'finances_paymenttransaction',
      },
      {
        label: 'Withdrawals',
        id: 'withdrawals',
        route: '/transactions/withdrawals',
        permission: 'finances_withdrawrequest',
      },
      {
        label: 'Cards',
        id: 'cards',
        route: '/transactions/cards',
        permission: 'finances_card',
      },
    ],
  },
  {
    label: 'Communications',
    id: 'communications',
    iconActive: () => <RenderIcon icon="CommunicationsActive" />,
    icon: () => <RenderIcon icon="Communications" />,
    position: positions.BOTTOM,
    subItems: [
      {
        label: 'Email Logs',
        id: 'email_logs',
        route: '/communications/email-logs',
        permission: 'communications_emaillog',
      },
      {
        label: 'SMS Logs',
        id: 'sms_logs',
        route: '/communications/sms-logs',
        permission: 'communications_smslog',
      },
      {
        label: 'WhatsApp Logs',
        id: 'whatsapp_logs',
        route: '/communications/whatsapp-logs',
        permission: 'communications_whatsapplog',
      },
      {
        label: 'Call Logs',
        id: 'call_logs',
        route: '/communications/call-logs',
        permission: 'communications_calllog',
      },
      {
        label: 'Templates',
        id: 'templates',
        route: '/communications/templates',
        permission: 'communications_template',
      },
    ],
  },
  {
    label: 'Settings',
    id: 'settings',
    route: '/settings',
    iconActive: () => <RenderIcon icon="SettingsActive" />,
    icon: () => <RenderIcon icon="Settings" />,
    position: positions.BOTTOM,
    style: 'dark',
    sections: [
      [
        {
          label: 'Users',
          id: 'users',
          icon: 'User',
          route: '/settings/users',
          permission: 'users_user',
        },
        {
          label: 'Teams',
          id: 'teams',
          icon: 'Teams',
          route: '/settings/teams',
          permission: 'users_team',
        },
        {
          label: 'Roles',
          id: 'roles',
          icon: 'Roles',
          route: '/settings/roles',
          permission: 'users_role',
        },
        {
          label: 'Contact Access Group',
          id: 'contact_access_group',
          icon: 'Ib',
          route: '/settings/contact-access-groups',
          permission: 'contact_access_groups_contactaccessgroupbinding',
        },
      ],
      [
        {
          label: 'Audit logs',
          id: 'audit_logs',
          icon: 'Logs',
          route: '/settings/audit-logs',
          permission: 'audit_logs_auditlog',
        },
        {
          label: 'Events',
          id: 'events',
          icon: 'Action',
          route: '/settings/events',
          permission: 'events_eventtype',
        },
        {
          label: 'Saved filters',
          id: 'saved_filters',
          icon: 'Funnel',
          route: '/settings/saved-filters',
          permission: 'users_savedfilter',
        },
        {
          label: 'Custom properties',
          id: 'custom_properties',
          icon: 'CustomProperties',
          route: '/settings/custom-properties',
          permission: 'custom_properties_customproperty',
        },
        {
          label: 'API keys',
          id: 'api_keys',
          icon: 'ApiKeys',
          route: '/settings/api-keys',
          permission: 'users_apikey',
        },
      ],
      [
        {
          label: 'Documents',
          id: 'documents',
          icon: 'Duplicate',
          route: '/settings/document-types',
          permission: 'documents_documenttype',
        },
        {
          label: 'Webhooks',
          id: 'webhooks',
          icon: 'Chain',
          route: '/settings/webhooks',
          permission: 'automations_rule',
        },
        {
          label: 'Integrations',
          id: 'integrations',
          icon: 'InternalTransaction',
          route: '/settings/integrations',
          permission: 'constance_config',
        },
      ],
    ],
  },
];


export default routes;
