import React from 'react';
import { string, oneOfType, shape, object, array, any, func } from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';
import classNames from 'classnames';
import merge from 'lodash/merge';

import './CommentField.scss';

import extractDisplayName from 'App/services/utilities/extractDisplayName';
import { UserCell } from '../gridCellRenderers';

const text = {
  WRITE_COMMENT: 'Write a comment',
};

const propTypes = {
  value: string,
  onChange: func.isRequired,
  fetchData: func.isRequired,
  renderSuggestion: func,
  placeholder: string,
  inputRef: oneOfType([func, shape({ current: any })]),
  usersList: array,
  style: object,
};

const defaultProps = {
  value: '',
  renderSuggestion: null,
  placeholder: text.WRITE_COMMENT,
  inputRef: null,
  usersList: [],
  style: {},
};

const defaultStyle = {
  marginTop: '40px',
  input: {
    height: '80px',
    padding: 8,
  },
  heightligher: {
    padding: 8,
  },
  suggestions: {
    marginLeft: 15,
    borderRadius: 5,
    list: {
      width: 250,
      borderRadius: 5,
    },
    item: {
      padding: 5,
      '&focused': {
        backgroundColor: '#e3f0ff',
      },
    },
  },
};

const markupRegex = /@<(.+?)><(.+?)>/;
const markupRegexGlobal = /@<(.+?)><(.+?)>/g;

const CommentField = ({
  value,
  placeholder,
  onChange,
  inputRef,
  usersList,
  fetchData,
  style,
  renderSuggestion,
}) => {
  const defaultRenderSuggestion = (entry, search, highlightDisplay, index, focused) => {
    const user = usersList.find(u => u.id === entry.id);
    return (
      <React.Fragment>
        {user && (
          <div
            className={classNames('comment-field__suggestion-el', {
              'comment-field__suggestion-el--focused': focused,
            })}
          >
            <UserCell
              disableRouting
              id={user.id}
              fullName={extractDisplayName(user)}
              avatar={user.avatar}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <MentionsInput
      className="comment-field"
      style={merge(defaultStyle, style)}
      placeholder={placeholder}
      value={value}
      allowSuggestionsAboveCursor
      allowSpaceInQuery
      onChange={onChange}
      inputRef={inputRef}
    >
      <Mention
        trigger="@"
        data={fetchData}
        markup="@<__id__><__display__>"
        regex={markupRegex}
        displayTransform={(_, username) => `${username}`}
        renderSuggestion={renderSuggestion || defaultRenderSuggestion}
        style={{ backgroundColor: '#e3f0ff' }}
      />
    </MentionsInput>
  );
};

export const replaceMarkup = content => content.replace(markupRegexGlobal, '@<$1>');
export const convertToMarkup = (rawContent, mentions) => rawContent.replace(/@<(.+?)>/g, (match) => {
  const matchGroup = match.match(/@<(.+?)>/);
  const id = +matchGroup[1];
  const user = mentions.find(m => m.user.id === +id);
  if (user) {
    return `@<${id}><${extractDisplayName(user.user)}>`;
  }
  return '';
});

CommentField.propTypes = propTypes;
CommentField.defaultProps = defaultProps;

export default CommentField;
