import React from 'react';

import hideCols from 'App/services/utilities/hideCols';
import { actionWrapper, UserCell, DateTimeCell } from 'App/components/gridCellRenderers';
import NoDataDash from 'App/components/NoDataDash';
import extractDisplayName from 'App/services/utilities/extractDisplayName';


const modifier = actions => ({
  ...hideCols(['id', 'created', 'updated']),
  actions: {
    pinned: 'right',
    headerName: '',
    maxWidth: 50,
    enableFiltering: false,
    cellRendererFramework: actionWrapper(actions),
    label: '',
    sortable: false,
  },
  updated_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => data.updated_by ? (
      <UserCell
        id={data.updated_by.id}
        avatar={data.updated_by.avatar}
        fullName={extractDisplayName(data.updated_by)}
        subTitle={<DateTimeCell value={data.updated} />}
      />
    ) : (
      <NoDataDash />
    ),
  },
  created_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => (
      <UserCell
        id={data.created_by.id}
        avatar={data.created.avatar}
        fullName={extractDisplayName(data.created_by)}
        subTitle={<DateTimeCell value={data.created} />}
      />
    ),
  },
});

export default modifier;
