import React, { PureComponent } from 'react';
import { number, oneOfType, string } from 'prop-types';

import GridComponentFactory from 'App/components/grid';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import modifiers from './modifiers';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
};

const tableConfig = contactId => ({
  tableUrl: `/contacts/${contactId}/wallets/`,
  tableKey: 'contactWallets',
});

const sizeOptions = [10, 20, 50, 100, 200];
const customColumns = [
  {
    key: 'providerType',
    headerName: 'Provider Type',
  },
  {
    key: 'mode',
    headerName: 'Mode',
  },
  {
    key: 'group',
    headerName: 'Group',
  },
];

class ContactWalletsTable extends PureComponent {
  render() {
    const { id } = this.props;
    const { GridComponent } = GridComponentFactory(tableConfig(id));
    return (
      <div>
        <GridComponent
          modifiers={modifiers}
          customColumns={customColumns}
          sizeOptions={sizeOptions}
          defaultPageSize={10}
          height="calc(100vh - 450px)"
        />
      </div>
    );
  }
}

ContactWalletsTable.propTypes = propTypes;

export default withErrorBoundary(ContactWalletsTable);
