/**
 * @example
 * hideCols(['id', 'name'])
 * returns {id: { hide: true }, name: { hide: true }}
 * @returns {Object} Returns colDefs for ag-grid
 */
export default (columns = []) => {
  const res = {};
  columns.forEach((col) => {
    res[col] = { hide: true };
  });
  return res;
};
