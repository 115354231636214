import React from 'react';
import { withRouter } from 'react-router-dom';

import Page from 'App/components/Page';
import { EventDefinitionForm } from 'Settings/Sections/Events/components';
import { object } from 'prop-types';

const propTypes = {
  match: object.isRequired,
};

const text = {
  PAGE_TITLE: 'Event definition',
};

const EventDefinitionPage = ({ match }) => (
  <Page title={text.PAGE_TITLE} style={{ margin: '0 20px' }}>
    <EventDefinitionForm id={match.params.eventId} />
  </Page>
);

EventDefinitionPage.propTypes = propTypes;

export default withRouter(EventDefinitionPage);
