import React, { useEffect, Fragment } from 'react';
import { Button, Icon, Row, Col, Space } from 'tc-biq-design-system';
import { array, func, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';

import ManageUsersFactory from 'App/components/ManageUsersFactory';
import DataAccordion from 'App/components/DataAccordion';
import AddSignature from 'Settings/Sections/Users/components/UserSections/EmailIntegrations/AddSignature';
import InboxCard from './InboxCard';

import './EmailIntegrations.scss';

const propTypes = {
  emailInboxes: array.isRequired,
  sharedEmailInboxes: array.isRequired,
  fetchEmailInboxes: func.isRequired,
  connectEmailInbox: func.isRequired,
  connectInProgress: bool.isRequired,
};

const text = {
  TITLE: 'Email integrations',
  CONNECT: 'Connect an inbox',
  SHARE_WITH: 'Share with',
  SHARED_WITH_ME: 'Shared with me',
  EMPTY: 'No connected inbox',
};

const { ManageUsers } = ManageUsersFactory('usersStore');

const EmailIntegrations = ({
  emailInboxes,
  sharedEmailInboxes,
  fetchEmailInboxes,
  connectEmailInbox,
  connectInProgress,
}) => {
  const fetchData = async () => {
    await fetchEmailInboxes();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const connectInboxes = async () => {
    connectEmailInbox(window.location.href);
  };

  const onShareSuccess = async () => {
    await fetchEmailInboxes();
  };

  return (
    <Fragment>
      <Row gutter={0}>
        <Col md="100%" lg="80%" style={{ margin: '0 auto' }}>
          <div className="fiq-user-email-integrations">
            <div className="fiq-user-email-integrations__header">
              <div className="fiq-user-email-integrations__title">
                <Icon name="InternalTransaction" size="large" />
                <Space size={12} />
                <div className="tc-heading-s">{text.TITLE}</div>
              </div>
              <Button loading={connectInProgress} onClick={connectInboxes} color="ghost">
                {text.CONNECT}
              </Button>
            </div>
            {emailInboxes.length
              ? emailInboxes.map(inbox => (
                <InboxCard key={inbox.id} inbox={inbox} />
              )) : (
                <div className="fiq-user-email-integrations__empty">
                  <Icon colorName="text-status03-400" name="Warning" />
                  <Space size={8} />
                  <div>{text.EMPTY}</div>
                </div>
              )}
            <DataAccordion title={text.SHARED_WITH_ME} toggled>
              {sharedEmailInboxes.map(inbox => (
                <InboxCard key={inbox.id} inbox={inbox} />
              ))}
            </DataAccordion>
          </div>
        </Col>
      </Row>
      <ManageUsers
        onSuccess={onShareSuccess}
        permissionName="allow"
        title={text.SHARE_WITH}
        icon="Share"
      />
      <AddSignature />
    </Fragment>
  );
};

EmailIntegrations.propTypes = propTypes;

export default inject(stores => ({
  emailInboxes: stores.usersStore.emailInboxes,
  sharedEmailInboxes: stores.usersStore.sharedEmailInboxes,
  fetchEmailInboxes: stores.usersStore.fetchEmailInboxes,
  connectEmailInbox: stores.usersStore.connectEmailInbox,
  connectInProgress: stores.usersStore.loading.connectEmailInboxes,
}))(observer(EmailIntegrations));
