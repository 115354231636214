import React from 'react';
import { Pill, Icon, Space } from 'tc-biq-design-system';

const statusTypes = {
  ACTIVE: 'status01',
  PENDING: 'primary',
  ACCEPTED: 'status01',
};

// eslint-disable-next-line
const UserStateCell = resend => ({ data, value }) => (
  <div style={{ display: 'flex' }}>
    <Pill type={statusTypes[value.toUpperCase()]}>{value}</Pill>
    <Space size={12} />
    <div
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={() => resend(data.id)}
    >
      <Icon colorName="text-primary-500" name="Pending" />
      <Space size={8} />
      <span className="tc-paragraph-regular text-primary-500">Resend</span>
    </div>
  </div>
);

export default UserStateCell;
