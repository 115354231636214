import { inject, observer } from 'mobx-react';

import ConditionEvent from './ConditionEvent';

const ConditionEventFactory = (storeKey, storeFieldKey) => ({
  ConditionEvent: inject(stores => ({
    removeCondition: stores[storeKey][storeFieldKey].removeCondition,
    updateEvent: stores[storeKey][storeFieldKey].updateEvent,
    removeAllEventRules: stores[storeKey][storeFieldKey].removeAllEventRules,
  }))(observer(ConditionEvent)),
});

export default ConditionEventFactory;
