import React from 'react';
import { Detail, Tooltip, Icon, notifier } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { object } from 'prop-types';
import currencyFormatter from 'App/services/utilities/currencyFormatter';

const text = {
  TOTAL_INCOMING: 'Total incoming funds',
  TOTAL_NET: 'Total net funds',
  TOTAL_OUTGOING: 'Total outgoing funds',
  TOOLTIP: 'The data will be updated automatically every 15 min.',
  DATA_ERROR: 'Failed to retrieve contact finance report',
};

const propTypes = {
  contactKpiData: object.isRequired,
};

const ContactsKpi = ({ contactKpiData }) => {
  if (!contactKpiData) {
    notifier.error(text.DATA_ERROR);
    return null;
  }
  const { total_incoming_funds, total_net_funds, total_outgoing_funds, currency } = contactKpiData;

  return (
    <div className="contact-kpi">
      <Detail
        entity={text.TOTAL_INCOMING}
        value={currencyFormatter(total_incoming_funds || 0, currency)}
        reverse
      />
      <Detail
        entity={text.TOTAL_NET}
        value={currencyFormatter(total_net_funds || 0, currency)}
        reverse
      />
      <Detail
        entity={text.TOTAL_OUTGOING}
        value={currencyFormatter(total_outgoing_funds || 0, currency)}
        reverse
      />
      <Tooltip title={text.TOOLTIP}>
        <Icon colorName="text-neutral-500" style={{ marginLeft: '17px' }} name="Warning" />
      </Tooltip>
    </div>
  );
};

ContactsKpi.propTypes = propTypes;

export default inject(stores => ({ contactKpiData: stores.contact.profile.contactKpiData }))(
  observer(ContactsKpi),
);
