import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HasPermissionRoute from 'App/components/HasPermissionRoute';

// pages
import { ContactsPage, ContactProfilePage } from 'Contacts/pages';
import TasksPage from 'Tasks/pages/TasksPage';
import ActivityStreamPage from 'ActivityStream/pages/ActivityStreamPage';
import {
  WalletsPage,
  WalletSinglePage,
  TradesPage,
  PaymentsPage,
  TradeSinglePage,
  PaymentSinglePage,
  OrdersPage,
  OrderSinglePage,
  WithdrawalsPage,
  WithdrawalSinglePage,
  PositionsPage,
  PositionSinglePage,
  CardsPage,
  CardSinglePage,
} from 'Transactions/pages';
import { NoContentPage, NoPermissionPage } from 'App/components/NoContentPage';
import { hasAccess } from 'App/services/permissionsService';
import SettingsPage, {
  ContactAccessGroupPage,
  ContactAccessGroupSinglePage,
  TeamsPage,
  UserPage,
  UsersPage,
  InviteUsersPage,
  AuditLogPage,
  AuditLogSinglePage,
  DocumentTypesPage,
  EventDefinitionPage,
  EventsPage,
  DerivedEventPage,
  CustomEventPage,
  IntegrationsPage,
  RolesPage,
  RolesSinglePage,
  SavedFiltersPage,
  WebhooksPage,
  WebhookUpdatePage,
  WebhookSinglePage,
  CustomPropertiesPage,
  ApiKeysPage,
} from 'Settings/pages';
import {
  SegmentsSinglePage,
  SegmentsPage,
  RulesSinglePage,
  RulesPage,
  SegmentPreviewPage,
} from 'Automation/pages';

import {
  EmailLogsPage,
  SmsLogsPage,
  WhatsAppLogsPage,
  CallLogsPage,
  EmailLogSinglePage,
  SmsLogSinglePage,
  WhatsAppLogSinglePage,
  TemplatesPage,
  TemplatesSinglePage,
} from 'Communications/pages';
import FiqEditor from 'App/components/Editor/Editor';
import UserNotificationPage from 'UserNotifications/pages/UserNotificationPage';
import {
  CampaignsPage,
  CampaignsSinglePage,
  CampaignDetailsPage,
  CampaignCreatePage,
  CampaignOverviewPage,
  TrackingLinksPage,
  WorkflowPage,
} from 'Marketing/pages';
import { FinanceDashboardPage, SalesDashboardPage, MarketingDashboardPage } from 'Dashboard/pages';


// eslint-disable-next-line react/prop-types
const MainRouter = ({ homePage }) => (
  <Switch>
    <HasPermissionRoute
      hasPermission={hasAccess('finances_paymenttransaction', 'read')}
      path="/dashboard/finance"
      component={FinanceDashboardPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('contacts_contact', 'read')}
      path="/dashboard/sales"
      component={SalesDashboardPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('contacts_contact', 'read')}
      path="/dashboard/marketing"
      component={MarketingDashboardPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('contacts_contact', 'read')}
      path="/contacts/:contactId(\d+)/:sectionId?"
      component={ContactProfilePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('contacts_contact', 'read')}
      path="/contacts/:action([a-z]+)?"
      exact
      component={ContactsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('tasks_task', 'read')}
      path="/tasks/:action?"
      exact
      component={TasksPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('events_activity', 'read')}
      path="/activity-stream"
      exact
      component={ActivityStreamPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_tradetransaction', 'read')}
      path="/transactions/trades"
      exact
      component={TradesPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_tradetransaction', 'read')}
      path="/transactions/trades/:tradeId"
      exact
      component={TradeSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_tradeposition', 'read')}
      path="/transactions/positions"
      exact
      component={PositionsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_tradeposition', 'read')}
      path="/transactions/positions/:positionId"
      exact
      component={PositionSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_wallet', 'read')}
      path="/transactions/wallets"
      exact
      component={WalletsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_wallet', 'read')}
      path="/transactions/wallets/:walletId"
      exact
      component={WalletSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_paymenttransaction', 'read')}
      path="/transactions/payments"
      exact
      component={PaymentsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_paymenttransaction', 'read')}
      path="/transactions/cards"
      exact
      component={CardsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_card', 'read')}
      path="/transactions/cards/:cardId"
      exact
      component={CardSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_paymenttransaction', 'read')}
      path="/transactions/payments/:paymentId"
      exact
      component={PaymentSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_tradeorder', 'read')}
      path="/transactions/orders/"
      exact
      component={OrdersPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_tradeorder', 'read')}
      path="/transactions/orders/:orderId"
      exact
      component={OrderSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_withdrawrequest', 'read')}
      path="/transactions/withdrawals"
      exact
      component={WithdrawalsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('finances_withdrawrequest', 'read')}
      path="/transactions/withdrawals/:withdrawalId"
      exact
      component={WithdrawalSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('communications_emaillog', 'read')}
      path="/communications/email-logs"
      exact
      component={EmailLogsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('communications_emaillog', 'read')}
      path="/communications/email-logs/:emailLogId"
      exact
      component={EmailLogSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('communications_smslog', 'read')}
      path="/communications/sms-logs"
      exact
      component={SmsLogsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('communications_smslog', 'read')}
      path="/communications/sms-logs/:smsLogId"
      exact
      component={SmsLogSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('communications_whatsapplog', 'read')}
      path="/communications/whatsapp-logs"
      exact
      component={WhatsAppLogsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('communications_whatsapplog', 'read')}
      path="/communications/whatsapp-logs/:whatsappLogId"
      exact
      component={WhatsAppLogSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('communications_calllog', 'read')}
      path="/communications/call-logs"
      exact
      component={CallLogsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('communications_template', 'read')}
      path="/communications/templates"
      exact
      component={TemplatesPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('communications_template', 'read')}
      path="/communications/templates/:templateId/:cloneId?"
      exact
      component={TemplatesSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('marketing_campaign', 'read')}
      path="/marketing/campaigns"
      exact
      component={CampaignsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('marketing_campaign', 'read')}
      path="/marketing/campaigns/create"
      exact
      component={CampaignCreatePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('marketing_campaign', 'read')}
      path="/marketing/campaigns/:campaignId/setup/:step?"
      exact
      component={CampaignsSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('marketing_campaign', 'read')}
      path="/marketing/campaigns/:campaignId/:cloneId?"
      exact
      component={CampaignsSinglePage}
    />
    <HasPermissionRoute
      hasPermission
      path="/marketing/campaigns-details/:campaignId"
      exact
      component={CampaignDetailsPage}
    />
    <HasPermissionRoute
      hasPermission
      path="/marketing/campaigns-overview/:campaignId"
      exact
      component={CampaignOverviewPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('marketing_campaign', 'read')}
      path="/marketing/tracking-links/:action?"
      exact
      component={TrackingLinksPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('automations_rule', 'read')}
      path="/automation/rules"
      exact
      component={RulesPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('automations_rule', 'read')}
      path="/automation/rules/:ruleId/:cloneId?"
      exact
      component={RulesSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('automations_segment', 'read')}
      path="/automation/segments"
      exact
      component={SegmentsPage}
    />
    <HasPermissionRoute
      hasPermission={
        hasAccess('automations_segment', 'update') || hasAccess('automations_segment', 'create')
      }
      path="/automation/segments/:segmentId"
      exact
      component={SegmentsSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('automations_segment', 'read')}
      path="/automation/segments/preview/:segmentId"
      exact
      component={SegmentPreviewPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('documents_documenttype', 'read')}
      path="/settings/document-types"
      exact
      component={DocumentTypesPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('events_eventtype', 'read')}
      path="/settings/events"
      exact
      component={EventsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('events_eventtype', 'read')}
      path="/settings/events/:eventId"
      exact
      component={EventDefinitionPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('events_eventtype', 'create') && hasAccess('events_derivedevent', 'read')}
      path="/settings/events/derived-event/:eventId"
      exact
      component={DerivedEventPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('events_eventtype', 'create') && hasAccess('events_customevent', 'read')}
      path="/settings/events/custom-event/:eventId"
      exact
      component={CustomEventPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('users_user', 'read')}
      path="/settings/users/invite"
      exact
      component={InviteUsersPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('users_user', 'read')}
      path="/settings/users/:userId/:sectionId?"
      exact
      component={UserPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('users_user', 'read')}
      path="/settings/users"
      exact
      component={UsersPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('audit_logs_auditlog', 'read')}
      path="/settings/audit-logs/:logId"
      exact
      component={AuditLogSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('audit_logs_auditlog', 'read')}
      path="/settings/audit-logs"
      exact
      component={AuditLogPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('constance_config', 'read')}
      path="/settings/integrations"
      exact
      component={IntegrationsPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('users_role', 'read')}
      path="/settings/roles/:roleId"
      component={RolesSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('users_role', 'read')}
      path="/settings/roles"
      exact
      component={RolesPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('contact_access_groups_contactaccessgroup', 'update')}
      path="/settings/contact-access-groups/:cagId/:clone?"
      exact
      component={ContactAccessGroupSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('contact_access_groups_contactaccessgroup', 'create')}
      path="/settings/contact-access-groups/create-group"
      exact
      component={ContactAccessGroupSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('contact_access_groups_contactaccessgroup', 'read')}
      path="/settings/contact-access-groups"
      exact
      component={ContactAccessGroupPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('users_team', 'read')}
      path="/settings/teams"
      exact
      component={TeamsPage}
    />

    <HasPermissionRoute
      hasPermission={
        hasAccess('automations_webhook', 'read') || hasAccess('automations_webhooklog', 'read')
      }
      path="/settings/webhooks"
      exact
      component={WebhooksPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('automations_webhook', 'read')}
      path="/settings/webhooks/:webhookId"
      exact
      component={WebhookSinglePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('automations_webhook', 'create')}
      path="/settings/webhook/create"
      exact
      component={WebhookUpdatePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('automations_webhook', 'read')}
      path="/settings/webhooks/edit/:webhookId"
      exact
      component={WebhookUpdatePage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('users_savedfilter', 'read')}
      path="/settings/saved-filters"
      exact
      component={SavedFiltersPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('users_savedfilter', 'read')}
      path="/settings/custom-properties/:action?"
      exact
      component={CustomPropertiesPage}
    />
    <HasPermissionRoute
      hasPermission={hasAccess('users_apikey', 'read')}
      path="/settings/api-keys/:action?"
      exact
      component={ApiKeysPage}
    />
    <HasPermissionRoute
      hasPermission
      path="/user/notifications"
      exact
      component={UserNotificationPage}
    />
    <Route path="/workflow" exact component={WorkflowPage} />
    <Route path="/settings" exact component={SettingsPage} />
    <Route path="/editor" exact component={FiqEditor} />
    <Route path="/no-permission" exact component={NoPermissionPage} />
    <Redirect exact from="/" to={homePage} />
    <Route path="*" component={NoContentPage} />
  </Switch>
);

export default MainRouter;
