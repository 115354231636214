import React from 'react';
import { inject, observer } from 'mobx-react';
import { openOverlay, closeOverlay } from 'App/services/overlayService';
import { bool, any } from 'prop-types';

const OVERLAY_ID = 'OVERLAY';

export const showOverlay = () => openOverlay(OVERLAY_ID);
export const hideOverlay = () => closeOverlay(OVERLAY_ID);

const style = {
  position: 'absolute',
  top: 0,
  left: 210,
  right: 0,
  bottom: 0,
  zIndex: 2,
  backgroundColor: 'white',
};


const OverlayWrapper = ({ isOverlayVisible, children }) => {
  if (!isOverlayVisible) return null;
  return (
    <div style={style}>
      {children}
    </div>
  );
};

OverlayWrapper.propTypes = {
  isOverlayVisible: bool,
  children: any,
};

OverlayWrapper.defaultProps = {
  isOverlayVisible: false,
  children: null,
};

export default inject(stores => ({
  visible: stores.overlayStore.overlay[OVERLAY_ID],
}))(observer(OverlayWrapper));
