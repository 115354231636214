import React, { useEffect, FC } from 'react';
import { inject, observer } from 'mobx-react';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { Sidepanel, Space } from 'tc-biq-design-system';
import { IconName } from 'tc-biq-design-system/types/Icon';
import { isEmpty } from 'lodash';

import SidepanelFooter from 'App/components/SidepanelFooter';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import { StoreInterface } from 'App/rootStore';
import { FieldProps } from 'App/components/FieldRenderer/types';

export const SIDEPANEL_ID = 'ADD_EDIT_API_KEY';

const text = {
  ADD_TITLE: 'Add API key',
  EDIT_TITLE: 'Edit API key',
  BUTTON_LABELS: (type: string) => ({
    confirm: type === 'add' ? 'Add API key' : 'Save changes',
    cancel: 'Discard',
  }),
};

const visibleFields = ['name'];

type TitleType = {
  [key: string]: string;
};
const sidepanelTitle: TitleType = {
  add: text.ADD_TITLE,
  edit: text.EDIT_TITLE,
};

type IconType = {
  [key: string]: IconName;
};
const sidepanelIcon: IconType = {
  add: 'Plus',
  edit: 'Edit',
};
interface CustomFooterProps {
  execute: () => void;
  close: () => void;
  type: string;
  errors: any;
}

const CustomFooter: FC<CustomFooterProps> = inject((stores: StoreInterface) => ({
  errors: stores.forms.apiKeyForm.fieldErrors,
}))(
  observer((
    { execute, close, type, errors }:
    {execute: () => void, close: () => void, type: string, errors?: any },
  ) => (
    <SidepanelFooter
      execute={execute}
      close={close}
      cancelColor="ghost"
      confirmColor="primary"
      buttonLabels={text.BUTTON_LABELS(type)}
      disableSubmit={!isEmpty(errors)}
    />
  )),
);

type ApiKeyParameters = {
  type?: string;
  id?: number;
};

export interface CreateEditApiKeyProps {
  visible?: boolean;
  parameters?: ApiKeyParameters;
  resetFields?: () => void;
  addApiKey?: () => void;
  editApiKey?: () => void;
  fetchApiKeyData?: (id: number) => void;
  fieldsDef?: FieldProps;
  onSuccess?: () => Promise<void> | null;
  apiKeyForm?: any; // TODO: Open new ticket for mobx stores
  validateForm?: () => boolean;
}

const CreateEditApiKey: FC<CreateEditApiKeyProps> = ({
  parameters = {},
  visible,
  fetchApiKeyData,
  apiKeyForm = {},
  addApiKey,
  editApiKey,
  onSuccess = null,
  resetFields = () => null,
  fieldsDef,
  validateForm = () => true,
}) => {
  useEffect(() => {
    if (parameters?.id && fetchApiKeyData) fetchApiKeyData(parameters.id);
  }, [parameters?.id]);

  if (!visible) return null;

  const formattedFields = fieldsResolver(fieldsDef);
  const { type = 'add' } = parameters;
  const isEdit = type === 'edit';

  const close = () => {
    closeOverlay(SIDEPANEL_ID);
    if (!isEdit) openOverlay('API_KEYS_MODAL');
    resetFields();
  };

  const submit = async () => {
    if (!validateForm()) return;
    if (isEdit && editApiKey) {
      await editApiKey();
    } else if (addApiKey) await addApiKey();
    if (isEmpty(apiKeyForm.fieldErrors)) {
      if (onSuccess) onSuccess();
      close();
    }
  };

  return (
    <Sidepanel
      title={sidepanelTitle[type]}
      type="info"
      icon={sidepanelIcon[type]}
      visible={visible}
      onCloseIconClick={close}
      footerRender={() => (
        <CustomFooter execute={submit} close={close} type={type} errors={{}} />
      )}
    >
      <Space size={18} />
      {formattedFields
        .filter(({ id }: { id: string }) => visibleFields.includes(id))
        .map(
          ({ id, read_only, ...props }: { id: string; read_only: boolean }) => (
            <Field
              {...props}
              disabled={read_only}
              key={id}
              formId="apiKeyForm"
            />
          ),
        )}
    </Sidepanel>
  );
};

export default inject((stores: StoreInterface) => ({
  visible: stores.overlayStore.overlay.ADD_EDIT_API_KEY,
  parameters: stores.overlayStore.overlay.parameters,
  fetchApiKeyData: stores.apiKeys.fetchApiKeyData,
  resetFields: stores.forms.apiKeyForm.resetFieldsData,
  addApiKey: stores.apiKeys.addApiKey,
  editApiKey: stores.apiKeys.editApiKey,
  apiKeyForm: stores.forms.apiKeyForm,
  fieldsDef: stores.tables.apiKeys.optionFields,
  validateForm: stores.forms.apiKeyForm.validateForm,
}))(observer(CreateEditApiKey));
