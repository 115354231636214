import wait_block from 'assets/images/wait-block.svg';
import time_delay from 'assets/images/delay-block.svg';
import action from 'assets/images/action-block.svg';
import exit from 'assets/images/exit-block.svg';
import entry from 'assets/images/entry-block.svg';
import branch from 'assets/images/branch-block.svg';
import send_email from 'assets/images/send-email-block.svg';
import send_sms from 'assets/images/send-sms-block.svg';
import send_whatsapp from 'assets/images/send-whatsapp-block.svg';
import send_webhook from 'assets/images/send-webhook-block.svg';
import edit from 'assets/images/block-edit.svg';
import remove from 'assets/images/block-remove.svg';
import clone from 'assets/images/block-clone.svg';
import move from 'assets/images/block-move.svg';

const BLOCK_TYPE = {
  WAIT_UNTIL: 'Wait Until',
  TIME_DELAY: 'Time Delay',
  ACTION: 'Action',
  EXIT: 'Exit',
  BRANCH: 'Branch',
  TRIGGER: 'Trigger',
};

const blockTypeIconMap = {
  [BLOCK_TYPE.WAIT_UNTIL]: wait_block,
  [BLOCK_TYPE.TIME_DELAY]: time_delay,
  [BLOCK_TYPE.ACTION]: action,
  [BLOCK_TYPE.EXIT]: exit,
  [BLOCK_TYPE.BRANCH]: branch,
  [BLOCK_TYPE.TRIGGER]: entry,
};

const ACTION_ID = {
  SEND_EMAIL: 'send_email_to_contact',
  SEND_SMS: 'send_sms_to_contact',
  SEND_WHATSAPP: 'send_whatsapp_message_to_contact',
  SEND_WEBHOOK: 'send_webhook',
};

const actionIDIconMap = {
  [ACTION_ID.SEND_EMAIL]: send_email,
  [ACTION_ID.SEND_SMS]: send_sms,
  [ACTION_ID.SEND_WHATSAPP]: send_whatsapp,
  [ACTION_ID.SEND_WEBHOOK]: send_webhook,
};

const BLOCK_ACTION = {
  ADD: 'Add',
  EDIT: 'Edit',
  REMOVE: 'Remove',
  CLONE: 'Clone',
  MOVE: 'Move',
  TOGGLE_DROPDOWN: 'Toggle dropdown',
};
const blockActions = [
  BLOCK_ACTION.EDIT,
  BLOCK_ACTION.CLONE,
  BLOCK_ACTION.MOVE,
  BLOCK_ACTION.REMOVE,
];

const blockActionIconMap = {
  [BLOCK_ACTION.EDIT]: edit,
  [BLOCK_ACTION.REMOVE]: remove,
  [BLOCK_ACTION.CLONE]: clone,
  [BLOCK_ACTION.MOVE]: move,
};

const BLOCK_STATE = {
  DROPDOWN_OPEN: 'dropdown-open',
  BLOCK_SELECTED: 'block-selected',
  BLOCK_DISABLED: 'block-disabled',
};

const ADD_BLOCK_STATE = {
  SELECTED: 'add-block-selected',
};

const DOM_CLASS_NAME = {
  NODE: 'workflow-block',
  BLOCK_EXPAND_DROPDOWN: 'three-dots',
  BLOCK_ACTION: a => `bock-action-${a.toLowerCase()}`,
};

const blockDescriptionMap = {
  [BLOCK_TYPE.WAIT_UNTIL]:
    'Set a time period to wait or wait until the condition is fulfilled before proceeding to the next block. Condition is fulfilled once the contact enters or exists specified segment(s).',
  [BLOCK_TYPE.TIME_DELAY]: 'Set a time period to wait before the next block is performed.',
  [BLOCK_TYPE.ACTION]:
    'Set the action that will be performed for the contact, once the block is reached.',
  [BLOCK_TYPE.BRANCH]:
    'Set a condition, which result evaluates to true or false branch. The condition is evaluated once a contact reaches the block. Each result will start  a different path where new blocks can be added.',
  [BLOCK_TYPE.EXIT]: 'Completes a contact journey once the block is reached.',
};

const blockActionDescriptionMap = {
  [ACTION_ID.SEND_EMAIL]:
    'Send an email to a contact – once the block is reached – by setting an email marketing template.',
  [ACTION_ID.SEND_SMS]:
    'Send an SMS to a contact – once the block is reached – by setting an SMS marketing template.',
  [ACTION_ID.SEND_WHATSAPP]:
    'Send a WhatsApp to a contact – once the block is reached – by setting a WhatsApp marketing template.',
  [ACTION_ID.SEND_WEBHOOK]:
    'Send a webhook to the specified URL. The data of the webhook consists of a contact payload, actor containing campaign ID and metadata that has related block ID.',
};

export {
  BLOCK_TYPE,
  blockTypeIconMap,
  actionIDIconMap,
  blockActions,
  DOM_CLASS_NAME,
  BLOCK_ACTION,
  BLOCK_STATE,
  ADD_BLOCK_STATE,
  blockActionIconMap,
  blockDescriptionMap,
  blockActionDescriptionMap,
};
