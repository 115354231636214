import React, { Fragment } from 'react';
import Comments from './Comments';
import PreviewTask from './PreviewTask';

const CommonOverlays = () => (
  <Fragment>
    <Comments />
    <PreviewTask />
  </Fragment>
);

export default CommonOverlays;
