import React, { PureComponent } from 'react';
import { withI18n } from '@lingui/react';

import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';

export const tableConfig = {
  tableUrl: '/finances/cards/',
  tableKey: 'cards',
};

const { GridComponent } = GridComponentFactory(tableConfig);

class CardsTable extends PureComponent {
  render() {
    return <GridComponent modifiers={modifiers} enableSegmentFiltering />;
  }
}

export default withI18n()(CardsTable);
