import React from 'react';
import { Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import TemplatesTable from 'Communications/components/TemplatesTable';
import { hasAccess } from 'App/services/permissionsService';

const propTypes = {
  history: object.isRequired,
};

const text = {
  TITLE: 'Templates',
  CREATE_TEMPLATE: 'Create template',
};

const bread = [
  { label: 'Communications' },
  { label: text.TITLE },
];

const TemplatesPage = ({ history }) => {
  const routeToCreate = () => history.push('/communications/templates/create');
  const headerActions = () => (hasAccess('communications_template', 'create') ? <Button onClick={routeToCreate}>{text.CREATE_TEMPLATE}</Button> : null);
  return (
    <Page title={text.TITLE} bread={bread} headerActions={headerActions} style={{ margin: '0 20px' }}>
      <TemplatesTable />
    </Page>
  );
};


TemplatesPage.propTypes = propTypes;

export default withRouter(TemplatesPage);
