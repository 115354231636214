import React from 'react';

import { MultiActions } from 'tc-biq-design-system';
import hideCols from 'App/services/utilities/hideCols';
import { UserCell, DateTimeCell } from 'App/components/gridCellRenderers';
import NoDataDash from 'App/components/NoDataDash';
import extractDisplayName from 'App/services/utilities/extractDisplayName';

// eslint-disable-next-line react/prop-types
const RolesActionCell = actions => ({ data }) => {
  if (actions(data).length === 0) return null;
  return <MultiActions actions={actions(data)} />;
};

export default actions => ({
  ...hideCols(['id', 'permissions', 'created', 'updated']),
  actions: {
    cellRendererFramework: RolesActionCell(actions),
    pinned: 'right',
    maxWidth: 80,
    headerName: '',
    unSortIcon: false,
  },
  updated_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => data.updated_by ? (
      <UserCell
        id={data.updated_by.id}
        avatar={data.updated_by.avatar}
        fullName={extractDisplayName(data.updated_by)}
        subTitle={<DateTimeCell value={data.updated} />}
      />
    ) : (
      <NoDataDash />
    ),
  },
  created_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => (
      <UserCell
        id={data.created_by.id}
        avatar={data.created_by.avatar}
        fullName={extractDisplayName(data.created_by)}
        subTitle={<DateTimeCell value={data.created} />}
      />
    ),
  },
});
