import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { notifier } from 'tc-biq-design-system';
import { object, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import withFilters from 'App/components/hoc/withFilters';

import List from 'Dashboard/components/layouts/List';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import { fetchContactsByIds } from 'Contacts/services/contactsService';
import run from 'App/services/utilities/run';

const text = {
  TITLE: 'Contacts with highest net income',
  DESCRIPTION: 'Contacts with highest net income value in selected time period',
  ERROR_MESSAGE: 'Something went wrong. Please contact admin.',
};

const METRIC = 'contact_funds';

const propTypes = {
  dashboardInterval: object,
  addNewFilter: func,
  history: object.isRequired,
};

const defaultProps = {
  dashboardInterval: {},
  addNewFilter: null,
};


const filters = (startDate, endDate, contacts) => [
  {
    field: { label: 'Created', value: 'created', type: 'datetime' },
    ...(startDate === endDate
      ? { operator: { label: 'on', value: 'date', related_models: null } }
      : { operator: { label: 'between', value: 'range', related_models: null } }),
    ...(startDate === endDate
      ? { value: startDate }
      : { value: [`${startDate}T00:00:00Z`, `${endDate}T23:59:59Z`] }),
  },
  {
    field: { label: 'Contact ID', value: 'contact__id', type: 'integer' },
    operator: { label: 'is one of', value: 'in', related_models: null },
    value: contacts,
  },
  {
    field: { label: 'State', value: 'state', type: 'choice' },
    operator: { label: 'is equal to', related_models: null, value: 'exact' },
    value: { value: 'Success', display_name: 'Success' },
  },
  {
    field: { label: 'Payment type', value: 'payment_type', type: 'choice' },
    operator: { label: 'is equal to', related_models: null, value: 'exact' },
    value: { value: 'Deposit', display_name: 'Deposit' },
  },
];


const HighValueContactsList = inject(stores => ({
  dashboardInterval: stores.dashboardStore.dashboardInterval,
  addNewFilter: stores.tables.payments.filters.addNewFilter,
}))(observer(
  class HighValueContactsList extends Component {
    constructor() {
      super();
      this.state = {
        fetchInProgress: false,
        data: [],
      };
    }

    onQueryReady = async (query) => {
      const { start_date, end_date } = query;

      this.setState({
        fetchInProgress: true,
      });

      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: start_date,
          end_of_interval: end_date,
          granularity: 'all',
          dimensions: ['contact'],
          limit: 5,
          order_by: ['-total_net_funds'],
        },
      };

      const [err, metricData] = await run(fetchMetricsData(formattedQuery));

      if (err) {
        notifier.error(text.ERROR_MESSAGE);
        return;
      }

      const contactIDs = metricData.map(e => e.contact);
      if (isEmpty(contactIDs)) {
        this.setState({
          fetchInProgress: false,
          data: [],
        });
        return;
      }

      const [contactErr, contacts] = await run(fetchContactsByIds(contactIDs));
      if (contactErr) {
        notifier.error(text.ERROR_MESSAGE);
        return;
      }

      const data = metricData.map(e => ({
        contact: contacts.results.find(c => c.id === e.contact),
        value: e.total_net_funds,
      }));

      this.setState({
        fetchInProgress: false,
        data,
      });
    }

    handleCardClick = () => {
      const { addNewFilter, history, dashboardInterval } = this.props;
      const { data } = this.state;
      const selectedContacts = data.map(item => item.contact.id);
      const { startDate, endDate } = dashboardInterval;
      filters(startDate, endDate, selectedContacts).forEach(addNewFilter);
      history.push('/transactions/payments');
    }

    render() {
      const { fetchInProgress, data } = this.state;

      return (
        <List
          title={text.TITLE}
          description={text.DESCRIPTION}
          data={data}
          loading={fetchInProgress}
          onClick={this.handleCardClick}
        />
      );
    }
  },
));


HighValueContactsList.wrappedComponent.propTypes = propTypes;
HighValueContactsList.wrappedComponent.defaultProps = defaultProps;

export default {
  component: withRouter(withFilters(HighValueContactsList)),
};
