import FieldRendererStore from 'App/components/FieldRenderer/fieldRendererStore';

export default {
  addUser: new FieldRendererStore(),
  editUser: new FieldRendererStore(),
  addEmailInboxSignature: new FieldRendererStore(),
  contactNoteForm: new FieldRendererStore(),
  sendEmailForm: new FieldRendererStore(),
  sendSmsForm: new FieldRendererStore(),
  sendWhatsAppForm: new FieldRendererStore(),
  logCall: new FieldRendererStore(),
  editBasicInfoForm: new FieldRendererStore(),
  editIntegrationConfig: new FieldRendererStore(),
  addEditTaskForm: new FieldRendererStore(),
  editEventDefinition: new FieldRendererStore(),
  uploadDocForm: new FieldRendererStore(),
  rejectDocForm: new FieldRendererStore(),
  documentTypeForm: new FieldRendererStore(),
  createEditTeam: new FieldRendererStore(),
  userDetailsForm: new FieldRendererStore(),
  assignManagerForm: new FieldRendererStore(),
  savedFilterForm: new FieldRendererStore(),
  ruleForm: new FieldRendererStore(),
  webhookForm: new FieldRendererStore(),
  segmentForm: new FieldRendererStore(),
  campaignForm: new FieldRendererStore(),
  channelEmailForm: new FieldRendererStore(),
  channelMessengerForm: new FieldRendererStore(),
  assignToForm: new FieldRendererStore(),
  leadImport: new FieldRendererStore(),
  createEditTrackingLink: new FieldRendererStore(),
  templateForm: new FieldRendererStore(),
  sendTestMailForm: new FieldRendererStore(),
  createEditCustomProperty: new FieldRendererStore(),
  apiKeyForm: new FieldRendererStore(),
  derivedEventForm: new FieldRendererStore(),
  customEventForm: new FieldRendererStore(),
  editBlock: new FieldRendererStore(),
  customProperties: new FieldRendererStore(),
};
