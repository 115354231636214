import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Row, Space } from 'tc-biq-design-system';

import { PaymentTypeCell } from 'App/components/gridCellRenderers';
import DisplayDetail from 'App/components/DisplayDetail';

const formatDate = date => moment(date, moment.ISO_8601).format('MMMM Do YYYY, \\at HH:mm');

const PaymentDetails = inject(stores => ({
  created: stores.transactions.paymentData.created,
  type: stores.transactions.paymentData.payment_type,
  gateway: stores.transactions.paymentData.gateway,
  externalId: stores.transactions.paymentData.external_id,
  amount: stores.transactions.paymentData.amount,
  asset: stores.transactions.paymentData.amount_asset,
  account: stores.transactions.paymentData.wallet.account.provider,
}))(
  observer(({ created, type, amount, asset, gateway, externalId, account }) => {
    const time = created ? formatDate(created) : '';
    const typeValue = type ? () => <PaymentTypeCell value={type} /> : '';
    return (
      <Fragment>
        <Row gutter={0}>
          <div style={{ display: 'flex', width: '100%' }}>
            <DisplayDetail header="External ID" value={externalId} />
            <DisplayDetail header="Type" value={typeValue} />
            <DisplayDetail header="Asset" value={asset} />
            <DisplayDetail header="Amount" value={amount} />
            <DisplayDetail header="Gateway" value={gateway} />
            <DisplayDetail header="Time" value={time} />
          </div>
        </Row>
        <Space size={8} />
        <Row gutter={0}>
          <div style={{ display: 'flex', width: '100%' }}>
            <DisplayDetail header="Account" value={account} />
          </div>
        </Row>
      </Fragment>
    );
  }),
);

export default PaymentDetails;
