import React from 'react';
import { Icon, Button } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';

import { openOverlay } from 'App/services/overlayService';

import './InviteUsersHome.scss';

const SIDEPANEL_ID = 'ADD_USER';

const onAddUser = () => openOverlay(SIDEPANEL_ID);

const text = {
  ADD_USER: 'Add User',
};

const InviteUsersHome = inject(stores => ({
  hasUsers: stores.usersStore.hasUsers,
}))(
  observer(({ hasUsers }) => (!hasUsers ? (
    <div className="fiq-invite-users-home">
      <div className="fiq-invite-users-home__content">
        <Icon colorName="text-neutral-500" name="User" size="large" />
        <div className="fiq-invite-users-home__content__desc">
          <span className="tc-heading-m text-neutral-900">Add users to the inviting list</span>
        </div>
        <Button icon="Plus" onClick={onAddUser}>
          {text.ADD_USER}
        </Button>
      </div>
    </div>
  ) : null)),
);

export default InviteUsersHome;
