import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Sidepanel, Select, Icon, Card, Space, notifier } from 'tc-biq-design-system';
import SidepanelFooter from 'App/components/SidepanelFooter';

import { object, bool, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import http from 'App/services/http';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import buildBulkParams from 'App/services/utilities/buildBulkParams';
import { bulkAddRoles, bulkRemoveRoles } from 'Settings/Sections/Users/services/UsersService';
import { DEBOUNCE_VALUE } from 'App/components/FieldRenderer/Inputs/SelectField';

import './BulkManageRoles.scss';

const propTypes = {
  parameters: object,
  visible: bool,
  onSuccess: func,
};

const defaultProps = {
  parameters: {},
  visible: false,
  onSuccess: () => null,
};

const text = {
  MANAGE_ROLES: 'Manage Roles',
  ADD_ROLES: 'Add roles',
  REMOVE_ROLES: 'Remove existing roles',
  SUCCESS_MESSAGE: 'Request for updating Roles is put for processing',
  ERROR_MESSAGE: 'Error while updating user role(s).',
  BUTTON_LABELS: {
    confirm: 'Save changes',
    cancel: 'Discard changes',
  },
};

const SIDEPANEL_ID = 'BULK_MANAGE_ROLES';
const usersTableKey = 'users';

const CARD_STYLE = {
  header: { display: 'flex', alignItems: 'center', height: '44px' },
  content: { padding: '0', background: 'white' },
};

export const openBulkManageRoles = (
  selectedItems, selectedAll,
) => openOverlay(SIDEPANEL_ID, { selectedItems, selectedAll });
export const closeBulkManageRoles = () => closeOverlay(SIDEPANEL_ID);

const headerTemplate = (title, icon) => (
  <div className="fiq-manage-roles__header">
    <Icon name={icon} colorName="text-primary-500" bgColorName="bck-primary-100" size="small" />
    <Space size={12} />
    <span className="tc-paragraph-strong">{title}</span>
  </div>
);

// eslint-disable-next-line react/prop-types
const customFooter = ({ execute, close, submitInProgress, buttonLabels, disableSubmit }) => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    disableSubmit={disableSubmit}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={buttonLabels}
    style={{ justifyContent: 'flex-end', width: '100%' }}
  />
);

const AddHeader = () => headerTemplate(text.ADD_ROLES, 'Plus');
const RemoveHeader = () => headerTemplate(text.REMOVE_ROLES, 'Delete');

// eslint-disable-next-line react/prop-types
const SelectRole = ({ placeholder, onChange, value }) => (
  <Select
    dataRecording
    loadOptions={handleInputChange}
    style={{ padding: '16px' }}
    type="multi"
    placeholder={placeholder}
    async
    onChange={role => onChange(role)}
    className="roles-input"
    valueKey="value"
    labelKey="display_name"
    value={value}
    debounceInterval={DEBOUNCE_VALUE}
  />
);

const handleInputChange = async (name) => {
  const response = await http.get(`/users/roles/?search=${name}`);
  return [
    ...response.data.results.map(result => ({
      value: result.id,
      display_name: result.name,
    })),
  ];
};

const BulkManageRoles = ({ parameters, visible, onSuccess }) => {
  const [addedRoles, handleAddRoles] = useState([]);
  const [removedRoles, handleRemoveRoles] = useState([]);
  const [submitInProgress, setInProgress] = useState(false);

  const isSubmitDisabled = () => isEmpty(addedRoles) && isEmpty(removedRoles);

  const resetFieldsData = () => {
    handleAddRoles([]);
    handleRemoveRoles([]);
  };

  const close = () => {
    resetFieldsData();
    closeBulkManageRoles();
  };

  const save = async (selectedItems, selectedAll) => {
    const addedRoleIds = addedRoles.map(role => role.value);
    const removedRoleIds = removedRoles.map(role => role.value);
    const params = buildBulkParams(selectedItems, selectedAll, usersTableKey);
    const addRolePayload = {
      ...params,
      roles: addedRoleIds,
    };

    const removeRolePayload = {
      ...params,
      roles: removedRoleIds,
    };

    try {
      setInProgress(true);
      const requests = [];
      if (!isEmpty(addedRoles)) {
        requests.push(bulkAddRoles(addRolePayload));
      }
      if (!isEmpty(removedRoles)) {
        requests.push(bulkRemoveRoles(removeRolePayload));
      }
      await Promise.all(requests);
      onSuccess();
      notifier.success(text.SUCCESS_MESSAGE);
      setInProgress(false);
      close();
    } catch (errors) {
      notifier.error(text.ERROR_MESSAGE);
      setInProgress(false);
    }
  };

  if (isEmpty(parameters)) return null;
  const { selectedItems, selectedAll } = parameters;
  return (
    <Sidepanel
      icon="Settings"
      title={text.MANAGE_ROLES}
      visible={visible}
      onCloseIconClick={close}
      footerRender={() => customFooter({
        execute: () => save(selectedItems, selectedAll),
        close,
        submitInProgress,
        buttonLabels: text.BUTTON_LABELS,
        disableSubmit: isSubmitDisabled(),
      })
      }
    >
      <Card headerTemplate={AddHeader} style={CARD_STYLE}>
        <SelectRole value={addedRoles} placeholder={text.ADD_ROLES} onChange={handleAddRoles} />
      </Card>
      <Space size={8} />
      <Card headerTemplate={RemoveHeader} style={CARD_STYLE}>
        <SelectRole
          value={removedRoles}
          placeholder={text.REMOVE_ROLES}
          onChange={handleRemoveRoles}
        />
      </Card>
    </Sidepanel>
  );
};

BulkManageRoles.propTypes = propTypes;
BulkManageRoles.defaultProps = defaultProps;

export default inject(stores => ({
  parameters: stores.overlayStore.overlay.parameters,
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
}))(observer(BulkManageRoles));
