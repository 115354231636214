/* eslint react/prop-types: 0 */
import React from 'react';

import {
  SelectField,
  InputField,
  CheckboxField,
  TextAreaField,
  DatePickerField,
  DurationField,
  NumberField,
} from './Inputs';
import FieldDataProvider from './FieldDataProvider';
import FiqEditor from '../Editor';

const UnControlledField = (props) => {
  const { type } = props;
  switch (type) {
    case 'choice':
    case 'generic relation':
    case 'related field':
      return <SelectField {...props} type="search" />;
    case 'boolean':
      return <CheckboxField {...props} />;
    case 'textarea':
      return <TextAreaField {...props} />;
    case 'date':
    case 'datetime':
      return <DatePickerField {...props} />;
    case 'editor':
      return <FiqEditor {...props} />;
    case 'duration':
      return <DurationField {...props} />;
    case 'float':
      return <NumberField {...props} />;
    default:
      return <InputField {...props} />;
  }
};

const ControlledField = (props) => {
  const { type, name, formId } = props;
  const SelectWithData = FieldDataProvider(SelectField, formId, name);
  const InputWithData = FieldDataProvider(InputField, formId, name);
  const CheckboxWithData = FieldDataProvider(CheckboxField, formId, name);
  const TextAreaWithData = FieldDataProvider(TextAreaField, formId, name);
  const DatePickerWithData = FieldDataProvider(DatePickerField, formId, name);
  const FiqEditorWithData = FieldDataProvider(FiqEditor, formId, name);
  const DurationWithData = FieldDataProvider(DurationField, formId, name);
  const NumberWithData = FieldDataProvider(NumberField, formId, name);

  switch (type) {
    case 'choice':
    case 'generic relation':
    case 'related field':
      return <SelectWithData {...props} type="search" />;
    case 'boolean':
      return <CheckboxWithData {...props} />;
    case 'textarea':
      return <TextAreaWithData {...props} />;
    case 'date':
    case 'datetime':
      return <DatePickerWithData {...props} />;
    case 'editor':
      return <FiqEditorWithData {...props} />;
    case 'duration':
      return <DurationWithData {...props} />;
    case 'float':
      return <NumberWithData {...props} />;
    default:
      return <InputWithData {...props} />;
  }
};

const Field = (props) => {
  const { formId } = props;
  return formId ? ControlledField(props) : UnControlledField(props);
};

export default Field;
