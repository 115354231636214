/* eslint react/prop-types: 0 */
import React from 'react';
import { DatePicker, DateTimePicker, Space } from 'tc-biq-design-system';
import { string, object, oneOfType, bool, func, number } from 'prop-types';
import moment from 'moment';

const propTypes = {
  name: string.isRequired,
  placeholder: string,
  label: string,
  value: oneOfType([string, object]),
  hasError: bool,
  helpText: string,
  onChange: func.isRequired,
  type: string.isRequired,
  disabled: bool,
  afterFieldSpaceSize: number,
};

const defaultProps = {
  placeholder: '',
  label: '',
  hasError: false,
  helpText: '',
  disabled: false,
  afterFieldSpaceSize: 18,
  value: null,
};

const withTime = type => type === 'datetime';

const DatePickerField = (props) => {
  const {
    name,
    placeholder,
    label,
    value,
    hasError,
    helpText,
    onChange,
    type,
    disabled,
    afterFieldSpaceSize,
  } = props;

  const format = 'YYYY-MM-DD';

  const date = value ? value || moment().format(format) : null;

  const inputData = {
    name,
    placeholder,
    label,
    hasError,
    helpText,
    disabled,
  };

  const Component = withTime(type) ? DateTimePicker : DatePicker;

  return (
    <div data-cy={name}>
      <Component
        {...props}
        date={date}
        inputProps={inputData}
        onChange={selectedDate => onChange(name, selectedDate)}
      />
      {afterFieldSpaceSize && <Space size={afterFieldSpaceSize} />}
    </div>
  );
};

DatePickerField.propTypes = propTypes;
DatePickerField.defaultProps = defaultProps;

export default DatePickerField;
