import React from 'react';
import { any, object } from 'prop-types';


const DefaultCell = ({ value }) => {
  if (value instanceof Object) value = JSON.stringify(value);

  return (
    <div className="truncate-text">
      {value || '-'}
    </div>
  )
};

DefaultCell.propTypes = { value: any };
DefaultCell.defaultProps = { value: null };
export default DefaultCell;
