import React, { PureComponent } from 'react';
import { withI18n } from '@lingui/react';
import { number, oneOfType, string } from 'prop-types';
import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';

const propTypes = {
  contactId: oneOfType([number, string]).isRequired,
  tableKey: string.isRequired,
  stateQuery: string.isRequired,
  ordering: string.isRequired,
};

const tableConfig = (contactId, tableKey) => ({
  tableUrl: `/contacts/${contactId}/trade-positions/`,
  tableKey,
});

const sizeOptions = [10, 20, 50, 100, 200];

class PositionsTable extends PureComponent {
  componentDidMount() {
    this.getTableComponent();
  }

  componentDidUpdate(prevProps) {
    const { tableKey } = this.props;
    if (prevProps.tableKey !== tableKey) {
      this.getTableComponent();
    }
  }

  getTableComponent = () => {
    const { contactId, tableKey } = this.props;
    const { GridComponent } = GridComponentFactory(tableConfig(contactId, tableKey));
    return GridComponent;
  };

  render() {
    const { stateQuery, ordering } = this.props;
    const GridComponent = this.getTableComponent();

    return (
      <GridComponent
        modifiers={modifiers}
        sizeOptions={sizeOptions}
        defaultFilters={{ state__exact: stateQuery }}
        defaultOrdering={ordering}
        defaultPageSize={10}
        height="calc(100vh - 450px)"
      />
    );
  }
}

PositionsTable.propTypes = propTypes;
export default withI18n()(PositionsTable);
