import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DatePickerRange, Select, Space } from 'tc-biq-design-system';
import { object, string } from 'prop-types';

import Loader from 'App/components/Loader';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import moment from 'moment';
import getGranularity from 'App/services/utilities/getGranularity';
import { formatKey } from 'App/helpers/format';
import Performance from './Performance';
import TablePerTemplate from './TablePerTemplate';

import './CommunicationKpi.scss';

const propTypes = {
  match: object.isRequired,
  campaignStartDate: string,
};

const defaultProps = {
  campaignStartDate: null,
};

const toPercentage = (all, part) => (part ? `${Math.round((part / all) * 100)}%` : null);

const failedTypes = ['reported_as_spam', 'unsubscribed', 'bounced', 'undelivered'];
const formatKpiData = (data) => {
  if (!data) return [];
  const { count, ...otherProps } = data;
  const newObj = { sent: count, ...otherProps }; // Rename 'count' to 'sent'

  return Object.keys(newObj)
    .filter(item => !failedTypes.includes(item))
    .map(dataKey => ({
      display_name: formatKey(dataKey),
      value: newObj[dataKey] || 0,
      percent: dataKey !== 'sent' ? toPercentage(newObj.sent, newObj[dataKey]) : null,
    }));
};

const options = [{
  display_name: 'Emails',
  value: 'emails',
}, {
  display_name: 'Sms messages',
  value: 'sms_messages',
}, {
  display_name: 'WhatsApp messages',
  value: 'whatsapp_messages',
}];

const initialChannel = { display_name: 'Emails', value: 'emails' };

const CommunicationKpi = ({ match, campaignStartDate }) => {
  const [metricsData, setMetricsData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState({
    startDate: null,
    endDate: moment().format('YYYY-MM-DD'),
  });
  const [selectedChannel, setChannel] = useState(initialChannel);
  const [chartData, setChartData] = useState([]);
  const [metricsDataPerTemplate, setMetricsDataPerTemplate] = useState([]);
  const [isLoading, setLoader] = useState(false);

  const { campaignId } = match.params;

  const granularity = getGranularity(selectedInterval.startDate, selectedInterval.endDate);

  const payloadPerTemplate = {
    metric: selectedChannel.value,
    query: {
      start_of_interval: selectedInterval.startDate,
      end_of_interval: selectedInterval.endDate,
      granularity: 'all',
      dimensions: ['template_id', 'template_name'],
      filter_by: {
        actor_type: 'marketing_campaign', // hardcoded
        actor_id: campaignId,
      },
    },
  };

  const getPayload = selectedGranularity => ({
    metric: selectedChannel.value,
    query: {
      start_of_interval: selectedInterval.startDate,
      end_of_interval: selectedInterval.endDate,
      granularity: selectedGranularity,
      dimensions: [],
      filter_by: {
        actor_type: 'marketing_campaign', // hardcoded
        actor_id: campaignId,
      },
    },
  });

  useEffect(() => {
    setSelectedInterval(prevState => ({
      ...prevState,
      startDate: campaignStartDate ? moment(campaignStartDate).format('YYYY-MM-DD') : null,
    }));
  }, [campaignStartDate]);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedInterval.startDate) return;
      setLoader(true);
      const [
        allPerformanceData,
        performanceByDateData,
        performancePerTemplate,
      ] = await Promise.all([
        fetchMetricsData(getPayload('all')),
        fetchMetricsData(getPayload(granularity)),
        fetchMetricsData(payloadPerTemplate),
      ]);

      setMetricsData(formatKpiData(allPerformanceData.data));
      setMetricsDataPerTemplate(performancePerTemplate.data);
      setChartData(performanceByDateData.data);

      setLoader(false);
    };
    fetchData();
  }, [selectedChannel, selectedInterval]);


  return (
    <>
      <div className="fiq-campaign-overview__header">
        <div>
          <Select
            type="single"
            labelKey="display_name"
            valueKey="value"
            options={options}
            value={selectedChannel}
            clearable={false}
            onChange={setChannel}
          />
        </div>
        <div>
          <DatePickerRange
            onChange={e => setSelectedInterval(e)}
            startDate={selectedInterval.startDate}
            endDate={selectedInterval.endDate}
            predefinedRange
            startDateModifiers={{
              popperModifiers: {
                offset: {
                  enabled: true,
                },
              },
            }}
            endDateModifiers={{
              popperModifiers: {
                offset: {
                  enabled: true,
                },
              },
            }}
          />
        </div>
      </div>
      {isLoading ? <Loader visible /> : (
        <>
          <Performance
            metricsData={metricsData}
            granularity={granularity}
            chartData={chartData}
          />
          <Space size={18} />
          <TablePerTemplate data={metricsDataPerTemplate} />
        </>
      )}
    </>
  );
};

CommunicationKpi.propTypes = propTypes;
CommunicationKpi.defaultProps = defaultProps;

export default withRouter(CommunicationKpi);
