import React, { PureComponent } from 'react';
import { Modal, Button, Space } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { bool, func, object } from 'prop-types';
import { closeOverlay } from 'App/services/overlayService';

import Field, { fieldsResolver } from 'App/components/FieldRenderer';

import Upload from './Upload';
import Errors from './UploadDocErrors';

const propTypes = {
  visible: bool.isRequired,
  requestInProgress: bool.isRequired,
  optionFields: object.isRequired,
  uploadDocument: func.isRequired,
  fetchContactDocuments: func.isRequired,
  resetFields: func.isRequired,
  validateForm: func.isRequired,
};

export const MODAL_ID = 'UPLOAD_DOC';

const text = {
  CANCEL: 'Cancel',
  UPLOAD: 'Upload',
  TITLE: 'Upload document',
};

const filterParams = { is_active: true };

const close = () => closeOverlay(MODAL_ID);

const customFooter = (uploadDoc, closeModal, requestInProgress, disableSubmit) => () => (
  <div className="fiq-documents__upload-doc">
    <Button
      disabled={requestInProgress}
      loading={requestInProgress}
      icon="Close"
      color="ghost"
      onClick={closeModal}
    >
      {text.CANCEL}
    </Button>
    <Space size={12} />
    <Button
      disabled={disableSubmit}
      loading={requestInProgress}
      icon="Checkmark"
      onClick={uploadDoc}
      disableSubmit={disableSubmit}
    >
      {text.UPLOAD}
    </Button>
  </div>
);


const UploadDoc = inject(stores => ({
  visible: !!stores.overlayStore.overlay[MODAL_ID],
  requestInProgress: stores.contact.documents.requestInProgress.upload,
  optionFields: stores.contact.documents.fieldDefs,
  uploadDocument: stores.contact.documents.uploadDocument,
  fetchContactDocuments: stores.contact.documents.fetchContactDocuments,
  resetFields: stores.forms.uploadDocForm.resetFieldsData,
  validateForm: stores.forms.uploadDocForm.validateForm,
}))(observer(
  class UploadDoc extends PureComponent {
    uploadDoc = async () => {
      const { uploadDocument, validateForm } = this.props;

      if (!validateForm()) return;

      await uploadDocument();
      this.close();
    };

    close = () => {
      const { fetchContactDocuments, resetFields } = this.props;
      fetchContactDocuments();
      resetFields();
      close();
    };

    render() {
      const { optionFields, visible, requestInProgress } = this.props;
      if (!optionFields.document_type) return null;
      const [fieldProps] = fieldsResolver({
        document_type: optionFields.document_type,
      });

      return (
        <Modal
          closable
          title={text.TITLE}
          icon="Duplicate"
          visible={visible}
          onCloseIconClick={close}
          footerRender={customFooter(this.uploadDoc, close, requestInProgress, false)}
        >
          <Field {...fieldProps} filterParams={filterParams} formId="uploadDocForm" />
          <Upload />
          <Space size={12} />
          <Errors />
        </Modal>
      );
    }
  },
));


UploadDoc.wrappedComponent.propTypes = propTypes;

export default UploadDoc;
