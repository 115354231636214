// Remove any global styles from parsed html
// eslint-disable-next-line consistent-return
function removeGlobalStyles(node) {
  if (node.type === 'style' && node.children.length) {
    const hasGlobalStyles = node.children.some(child => child.data.includes('* '));
    if (hasGlobalStyles) return null;
  }
}

export default removeGlobalStyles;
