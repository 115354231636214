import React from 'react';
import {
  RouteCell,
  ContactCell,
  StatusCell,
  GenericObjectCell,
  SmartlookCell,
} from 'App/components/gridCellRenderers';
import NoDataDash from 'App/components/NoDataDash';

export default {
  actor: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => value ? <GenericObjectCell value={value} /> : <NoDataDash />,
  },
  created: {
    width: 160,
  },
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/communications/sms-logs/'),
  },
  status: { cellRendererFramework: StatusCell },
  contact: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => value ? (
      <ContactCell
        id={value.id}
        avatar={value.avatar}
        fullName={`${value.first_name} ${value.last_name}`}
      />
    ) : (
      <NoDataDash />
    ),
  },
  from_phone_number: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => value ? <SmartlookCell value={value} /> : <NoDataDash />,
  },
  to_phone_number: {
    cellRendererFramework: SmartlookCell,
  },
  sortable: false,
};
