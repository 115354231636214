import AddNote from './AddNote';
import SendEmail from './SendEmail';
import SendSms from './SendSms';
import SendWhatsApp from './SendWhatsApp';
import approveToActions from './ApproveTo';
import ManuallyLog from './ManuallyLog';
import PhoneCall from './PhoneCall';

export default contactStatus => [
  SendEmail,
  SendSms,
  SendWhatsApp,
  AddNote,
  PhoneCall,
  ManuallyLog,
  ...approveToActions(contactStatus),
];
