import React from 'react';
import { number, func } from 'prop-types';

import SearchAll from './SearchAll';
import UserDropdown from './UserDropdown';
import UserNotifications from './UserNotifications';

import './AppHeader.scss';

const AppHeader = ({ count, getSocketNotifications }) => (
  <div className="fiq-app-header">
    <SearchAll />
    <div className="fiq-app-header__user">
      <UserNotifications getSocketNotifications={getSocketNotifications} count={count} />
      <UserDropdown />
    </div>
  </div>
);

AppHeader.propTypes = {
  count: number.isRequired,
  getSocketNotifications: func.isRequired,
};

export default AppHeader;
