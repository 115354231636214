import { groupBy } from 'lodash';
import moment from 'moment';

const getChartData = (data, granularity, selectedDimension, value) => {
  const groupedData = groupBy(data, granularity);
  const groupedKeys = Object.keys(groupedData);
  const formatedData = groupedKeys.map(date => groupedData[date].reduce((result, item) => ({
    ...result,
    currency: item.currency,
    [item[selectedDimension]]: Number(item[value]),
    ...(granularity === 'month'
      ? { [granularity]: moment(item[granularity]).format('MMM') }
      : { [granularity]: item[granularity] }),
  }), {}));

  const granArray = ['day', 'week', 'month', 'year', 'currency'];

  const barsFormated = [];
  formatedData.map(tes => Object.keys(tes).forEach(key => barsFormated.push(key)));
  const uniqBarKeys = [...new Set(barsFormated)].filter(uniq => !granArray.includes(uniq));

  return { uniqBarKeys, formatedData };
};

const formatPieChartData = (data, property, count = 'count') => {
  const groupByProperty = data.reduce((acc, item) => ({
    ...acc,
    [item[property]]: acc[item[property]]
      ? (acc[item[property]] + item[count])
      : item[count],
  }), {});

  const keys = Object.keys(groupByProperty);
  return keys.map(key => ({
    key,
    name: key,
    color: 'black',
    value: groupByProperty[key],
  })).filter(item => !!item.key);
};

export { getChartData, formatPieChartData };
