import React, { PureComponent } from 'react';

import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';

export const tableConfig = {
  tableUrl: '/communications/email-logs/',
  tableKey: 'emailLogs',
};

const { GridComponent } = GridComponentFactory(tableConfig);

class EmailLogsTable extends PureComponent {
  render() {
    return <GridComponent modifiers={modifiers} enableSegmentFiltering />;
  }
}

export default EmailLogsTable;
