import React, { Component } from 'react';
import { func, bool, object, array, oneOfType, shape, any } from 'prop-types';

const propTypes = {
  fetchData: func.isRequired,
  isLoading: bool.isRequired,
  hasMore: bool.isRequired,
  style: object.isRequired,
  children: oneOfType([object, array]).isRequired,
  scrollRef: oneOfType([func, shape({ current: any })]),
};

const defaultProps = {
  scrollRef: null,
};

class InfiniteScroll extends Component {
  constructor(props) {
    super(props);
    const { scrollRef } = props;
    this.nodeRef = scrollRef || React.createRef();
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    this.nodeRef.current.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    this.nodeRef.current.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll(event) {
    const { isLoading, hasMore, fetchData } = this.props;
    const { scrollTop } = event.target;
    if (isLoading || !hasMore) {
      return;
    }
    if (scrollTop >= this.nodeRef.current.scrollHeight - this.nodeRef.current.offsetHeight) {
      fetchData();
    }
  }

  render() {
    const { children, style } = this.props;
    return (
      <div style={style} className="fiq-infinite-scroll" ref={this.nodeRef}>
        {children}
      </div>
    );
  }
}

InfiniteScroll.propTypes = propTypes;
InfiniteScroll.defaultProps = defaultProps;

export default InfiniteScroll;
