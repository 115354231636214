import React from 'react';
import { Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { hasAccess } from 'App/services/permissionsService';

import Page from 'App/components/Page';
import EventsTable, { tableConfig } from 'Settings/Sections/Events/components/EventsTable';
import { object } from 'prop-types';

const text = {
  SYSTEM_LABEL: 'Settings',
  EVENTS_LABEL: 'Events',
  CREATE_DERIVED_EVENT: 'Create Derived Event',
  CREATE_CUSTOM_EVENT: 'Create Custom Event',
};

const bread = [
  { label: text.SYSTEM_LABEL, route: '/settings' },
  { label: text.EVENTS_LABEL, route: '/settings/events' },
];
const exportDataOptions = { permission: 'events_eventtype', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

const propTypes = {
  history: object.isRequired,
};

const EventsPage = ({ history }) => {
  const headerActions = () => {
    if (hasAccess('events_derivedevent', 'create') && hasAccess('events_derivedevent', 'read')) {
      return (
        <>
          <Button onClick={() => history.push('/settings/events/derived-event/create')}>{text.CREATE_DERIVED_EVENT}</Button>
          <Button onClick={() => history.push('/settings/events/custom-event/create')}>{text.CREATE_CUSTOM_EVENT}</Button>
        </>
      );
    }
    return null;
  };

  return (
    <Page
      bread={bread}
      title={text.EVENTS_LABEL}
      style={{ margin: '0 20px' }}
      exportDataOptions={exportDataOptions}
      headerActions={headerActions}
    >
      <EventsTable />
    </Page>
  );
};

EventsPage.propTypes = propTypes;

export default withRouter(EventsPage);
