import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { bool } from 'prop-types';

const propTypes = {
  hasPermission: bool,
};

const defaultProps = {
  hasPermission: false,
};

const HasPermissionRoute = ({ hasPermission, ...props }) => (hasPermission ? <Route {...props} /> : <Redirect to="/no-permission" />);

HasPermissionRoute.propTypes = propTypes;
HasPermissionRoute.defaultProps = defaultProps;

export default HasPermissionRoute;
