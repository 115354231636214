import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { object, func } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Col, Space } from 'tc-biq-design-system';
import isEmpty from 'lodash/isEmpty';

import Page from 'App/components/Page';
import Loader from 'App/components/Loader';
import { CardDetails, CardInstancesTable, CardPaymentsTable } from 'Transactions/components';
import DetailsCardWrapper from 'App/components/DetailsCardWrapper';
import CodeAccordion from 'App/components/CodeAccordion';

import './CardSinglePage.scss';

const propTypes = {
  match: object.isRequired,
  fetchCardData: func.isRequired,
  cardData: object.isRequired,
};

const bread = id => [
  { label: 'Transactions' },
  { label: 'Cards', route: '/transactions/cards' },
  { label: id, route: `/transactions/cards/${id}` },
];

const text = {
  CARD: 'Card',
};

class CardSinglePage extends PureComponent {
  constructor(props) {
    super(props);
    this.id = props.match.params.cardId;
  }

  componentDidMount() {
    const { fetchCardData } = this.props;
    fetchCardData(this.id);
  }

  render() {
    const { cardData } = this.props;
    if (isEmpty(cardData)) return <Loader visible />;
    const { contact, status, metadata, wallet, name, instances } = cardData;
    return (
      <Page
        bread={bread(this.id)}
        title={`${name}`}
        style={{ marginTop: '24px', overflow: 'auto' }}
      >
        <div className="fiq-trade-single">
          <Col sm="100%" lg="70%">
            <DetailsCardWrapper contact={contact} wallet={wallet} title={text.CARD} status={status}>
              <CardDetails />
            </DetailsCardWrapper>
            <Space size={12} />
            <CardInstancesTable instances={instances} />
            <Space size={12} />
            <CardPaymentsTable id={this.id} />
            <Space size={12} />
            <CodeAccordion data={metadata || {}} />
          </Col>
        </div>
      </Page>
    );
  }
}

CardSinglePage.propTypes = propTypes;

export default withRouter(inject(stores => ({
  fetchCardData: stores.transactions.fetchCardData,
  cardData: stores.transactions.cardData,
}))(observer(CardSinglePage)));
