import React, { PureComponent } from 'react';
import { notifier } from 'tc-biq-design-system';

import GridComponentFactory from 'App/components/grid';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import http from 'App/services/http';
import modifiers from './modifiers';

import './NotificationPreferences.scss';

const tableConfig = {
  tableUrl: '/users/notification-settings/',
  tableKey: 'notificationPreferences',
};

const text = {
  UPDATE_SUCCESS: 'Successfully updated Notification preferences',
  UPDATE_ERROR: 'Failed to change Notification preferences',
};

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const toggleActiveState = (id, value) => http.patch(`/users/notification-settings/${id}/`, { in_app: value });

class NotificationPreferencesTable extends PureComponent {
  toggleActive = async (data, event) => {
    try {
      await toggleActiveState(data.id, event.target.checked);
      gridActions.fetchTableData();
      notifier.success(text.UPDATE_SUCCESS);
    } catch (err) {
      notifier.error(text.ERROR);
    }
  };

  render() {
    return (
      <div className="fiq-user-notification-preferences">
        <GridComponent
          height="calc(100vh - 400px)"
          modifiers={modifiers({ toggle: this.toggleActive })}
        />
      </div>
    );
  }
}

export default withErrorBoundary(NotificationPreferencesTable);
