import React from 'react';
import { Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import RulesTable from 'Automation/components/RulesTable';
import { hasAccess } from 'App/services/permissionsService';

const propTypes = {
  history: object.isRequired,
};

const text = {
  TITLE: 'Rules',
  CREATE: 'Create new rule',
};

const bread = [
  { label: 'Automation' },
  { label: text.TITLE },
];

const RulesPage = ({ history }) => {
  const headerActions = () => {
    if (hasAccess('automations_rule', 'create')) {
      return <Button onClick={() => history.push('/automation/rules/create')}>{text.CREATE}</Button>;
    }
    return null;
  };

  return (
    <Page bread={bread} headerActions={headerActions} title={text.TITLE} style={{ margin: '0 20px' }}>
      <RulesTable />
    </Page>
  );
};

RulesPage.propTypes = propTypes;

export default withRouter(RulesPage);
