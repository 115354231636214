import React from 'react';

import Page from 'App/components/Page';
import SmsLogsTable, { tableConfig } from 'Communications/components/SmsLogsTable';

const bread = [
  { label: 'Communications' },
  { label: 'SMS Logs', route: '/communications/sms-logs' },
];

const exportDataOptions = { permission: 'communications_smslog', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

const text = {
  SMS_LOGS: 'SMS Logs',
};

const SmsLogsPage = () => (
  <Page bread={bread} title={text.SMS_LOGS} style={{ margin: '0 20px' }} exportDataOptions={exportDataOptions}>
    <SmsLogsTable />
  </Page>
);

export default SmsLogsPage;
