import moment from 'moment';

export default function getGranularity(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  const duration = moment.duration(end.diff(start));
  const days = duration.asDays();
  if (days < 8) return 'day';
  if (days < 31) return 'week';
  return 'month';
}
