import React from 'react';
import { Avatar } from 'tc-biq-design-system';

import {
  ToggleCell,
  actionWrapper,
  UserStateCell,
  PillCell,
  RouteCell,
  SmartlookCell,
} from 'App/components/gridCellRenderers';
import hideCols from 'App/services/utilities/hideCols';
import { hasAccess } from 'App/services/permissionsService';
import operatorEnum from 'App/enums/operatorEnum';

const modifier = ({ toggle, resend, actions }) => ({
  ...hideCols(['is_deleted']),
  id: {
    cellRendererFramework: RouteCell('/settings/users/'),
  },
  state: {
    minWidth: 180,
    cellRendererFramework: UserStateCell(resend),
  },
  is_active: {
    width: 80,
    pinned: 'left',
    enableFiltering: false,
    cellRendererFramework: ToggleCell(toggle, hasAccess('users_user', 'update')),
  },
  actions: {
    pinned: 'right',
    width: 80,
    enableFiltering: false,
    cellRendererFramework: actionWrapper(actions),
    label: '',
    sortable: false,
  },
  email: {
    minWidth: 240,
    cellRendererFramework: SmartlookCell,
    quickFilter: {
      operator: operatorEnum.CONTAINS,
    },
  },
  created: { minWidth: 180 },
  updated: { minWidth: 180 },
  role_bindings: {
    cellRendererFramework: PillCell({ displayField: 'role_name' }),
  },
  contact_access_group_bindings: {
    cellRendererFramework: PillCell({ displayField: 'contact_access_group_name' }),
  },
  team_binding: {
    cellRendererFramework: PillCell({ displayField: 'team_name' }),
  },
  avatar: {
    // eslint-disable-next-line react/prop-types
    cellRendererFramework: ({ value }) => <Avatar src={value} />,
  },
  first_name: {
    cellRendererFramework: SmartlookCell,
    quickFilter: {
      operator: operatorEnum.CONTAINS,
    },
  },
  last_name: {
    cellRendererFramework: SmartlookCell,
    quickFilter: {
      operator: operatorEnum.CONTAINS,
    },
  },
  telephone: { cellRendererFramework: SmartlookCell },
  username: { cellRendererFramework: SmartlookCell },
});

export default modifier;
