import React, { useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import Page from 'App/components/Page';
import { func } from 'prop-types';

import NotificationsFilters from 'UserNotifications/components/NotificationsFilters';
import NotificationsBody from 'UserNotifications/components/NotificationsBody';

import './UserNotificationPage.scss';

const propTypes = {
  fetchNotifications: func.isRequired,
  fetchFieldsDef: func.isRequired,
};

const text = {
  TITLE: 'Notifications',
};

const UserNotificationPage = ({ fetchNotifications, fetchFieldsDef }) => {
  useEffect(() => {
    fetchFieldsDef();
  }, []);

  const fetchData = useCallback(() => fetchNotifications({}));

  return (
    <Page title={text.TITLE}>
      <NotificationsFilters fetchData={fetchData} />
      <div className="fiq-user-notifications-page">
        <NotificationsBody />
      </div>
    </Page>
  );
};

UserNotificationPage.propTypes = propTypes;

export default inject(stores => ({
  fetchNotifications: stores.userNotifications.fetchNotifications,
  notifications: stores.userNotifications.notifications,
  fetchFieldsDef: stores.userNotifications.fetchNotificationsOptions,
}))(observer(UserNotificationPage));
