import React, { useState, useEffect } from 'react';
import { Select as SelectComponent } from 'tc-biq-design-system';
import { func, array, oneOfType, object, string, number } from 'prop-types';
import { omit } from 'lodash';

import loadOptions from 'App/services/utilities/loadOptions';
import setInitialValue from 'App/services/utilities/selectFieldSetInitalValue';
import NoPermissionInfo from 'App/components/NoPermissionInfo';

const propTypes = {
  onChange: func.isRequired,
  relatedModels: oneOfType([array, object]),
  options: array,
  value: oneOfType([object, string, array, number]),
  type: string.isRequired,
  filterParams: object,
};

const defaultProps = {
  relatedModels: null,
  options: [],
  value: null,
  filterParams: {},
};

const searchableSelectInputs = ['related field', 'generic relation'];

const Select = (props) => {
  const { onChange, relatedModels, type, options, value, filterParams } = props;
  const [noPermission, setNoPermission] = useState(false);
  const [initialValue, updateInitialValue] = useState(null);
  const fieldProps = options
    ? omit(props, ['read_only', 'formId', 'afterFieldSpaceSize'])
    : omit(props, ['read_only', 'formId', 'afterFieldSpaceSize', 'options']);

  const selectType = searchableSelectInputs.includes(type) ? 'search' : type;
  const onError = () => setNoPermission(true);
  // eslint-disable-next-line max-len
  const asyncProps = relatedModels
    ? { async: true, loadOptions: loadOptions(filterParams, relatedModels, onError) }
    : {};
  const onSelected = (fieldValue) => {
    updateInitialValue(fieldValue);
    onChange(fieldValue);
  };

  useEffect(() => {
    if (value) {
      setInitialValue({
        value,
        options,
        relatedModels,
        updateInitialValue: onSelected,
        type,
      });
    }
  }, []);

  if (noPermission) {
    const requiredPermissions = relatedModels.map(model => model.display_name).join(', ');
    return (
      <NoPermissionInfo label={fieldProps.fieldLabel} requiredPermissions={requiredPermissions} />
    );
  }

  return (
    <SelectComponent
      type={selectType}
      clearable={false}
      onChange={onSelected}
      labelKey="display_name"
      valueKey="value"
      value={initialValue}
      options
      {...asyncProps}
      {...fieldProps}
    />
  );
};

Select.defaultProps = defaultProps;
Select.propTypes = propTypes;

export default Select;
