import React from 'react';

import { DashboardCard, Chart, Icon } from 'tc-biq-design-system';
import NoData from 'App/components/NoData/NoData';
import { string, bool, array, func } from 'prop-types';

import './BarChart.scss';

const propTypes = {
  title: string,
  description: string,
  dimensions: array,
  data: array,
  bars: array,
  xAxisKey: string,
  inProgress: bool,
  valueRenderer: func,
  selectedDimension: string,
  handleCardClick: func,
  hideTotal: bool,
  renderTooltip: func,
};

const defaultProps = {
  title: '',
  description: '',
  dimensions: [],
  data: [],
  bars: [],
  xAxisKey: '',
  inProgress: false,
  valueRenderer: () => null,
  selectedDimension: '',
  handleCardClick: null,
  hideTotal: false,
  renderTooltip: null,
};

const customList = (dimensions, selectedDimension) => dimensions.map(dimension => ({
  item: action => (
    <div onClick={action} className="list-item">
      <div className="icon-wrapper">
        {(dimension.value === selectedDimension)
        && <Icon colorName="text-primary-400" name="Checkmark" />}
      </div>
      <p className="tc-paragraph-regular">
        {dimension.label}
      </p>
    </div>
  ),
  onClick: dimension.onClick,
}));

const BarChart = ({
  valueRenderer,
  title,
  selectedDimension,
  description,
  dimensions,
  data,
  bars,
  xAxisKey,
  inProgress,
  handleCardClick,
  hideTotal,
  renderTooltip,
}) => {
  const formatedList = customList(dimensions, selectedDimension);
  return (
    <DashboardCard
      title={title}
      description={description}
      loading={inProgress}
      actions={dimensions}
      customList={formatedList}
      onClick={handleCardClick}
    >
      {data.length ? (
        <Chart
          xAxisKey={xAxisKey}
          height={190}
          bars={bars}
          data={data}
          stacked
          valueRenderer={valueRenderer}
          tickFormatter={value => `${value}`}
          barSize={24}
          hideTotal={hideTotal}
          renderTooltip={renderTooltip}
        />
      ) : <NoData />}
    </DashboardCard>
  );
};

BarChart.propTypes = propTypes;
BarChart.defaultProps = defaultProps;

export default BarChart;
