import React from 'react';
import { Code } from 'tc-biq-design-system';
import DataAccordion from 'App/components/DataAccordion';
import { object } from 'prop-types';

const propTypes = {
  data: object.isRequired,
};

const CodeAccordion = ({ data, ...props }) => (
  <DataAccordion {...props}>
    <Code data={data || {}} />
  </DataAccordion>
);

CodeAccordion.propTypes = propTypes;

export default CodeAccordion;
