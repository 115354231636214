import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { object, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import withFilters from 'App/components/hoc/withFilters';

import Numeric from 'Dashboard/components/layouts/Numeric';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import { buildQueryForPreviousInterval, buildNumeric } from 'Dashboard/store/utils';
import getGranularity from 'App/services/utilities/getGranularity';

const text = {
  TITLE: 'Emails',
  DESCRIPTION: 'Total number of emails in selected time period',
};

const METRIC = 'emails';

const propTypes = {
  dashboardInterval: object,
  addNewFilter: func,
  history: object.isRequired,
  setInteractionsData: func,
};

const defaultProps = {
  dashboardInterval: {},
  addNewFilter: null,
  setInteractionsData: null,
};


const filters = (startDate, endDate) => [
  {
    field: { label: 'Created', value: 'created', type: 'datetime' },
    ...(startDate === endDate
      ? { operator: { label: 'on', value: 'date', related_models: null } }
      : { operator: { label: 'between', value: 'range', related_models: null } }),
    ...(startDate === endDate
      ? { value: startDate }
      : { value: [`${startDate}T00:00:00Z`, `${endDate}T23:59:59Z`] }),
  },
];


const CallsNumeric = inject(stores => ({
  dashboardInterval: stores.dashboardStore.dashboardInterval,
  addNewFilter: stores.tables.emailLogs.filters.addNewFilter,
  setInteractionsData: stores.dashboardStore.setInteractionsData,
}))(observer(
  class CallsNumeric extends Component {
    constructor() {
      super();
      this.state = {
        fetchInProgress: false,
        data: {
          value: 0,
          percentage: 0,
          isGrowing: false,
        },
      };
    }

    onQueryReady = async (query) => {
      const { error } = this.state;
      const { start_date, end_date } = query;
      const { setInteractionsData } = this.props;

      const granularity = getGranularity(start_date, end_date);

      this.setState({
        fetchInProgress: true,
      });

      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: start_date,
          end_of_interval: end_date,
          granularity,
        },
      };

      const response = await Promise.all([
        fetchMetricsData(formattedQuery),
        fetchMetricsData(buildQueryForPreviousInterval(formattedQuery)),
      ]).catch(errorResponse => this.setState({ error: errorResponse }));


      if (error) return;
      const [current, previous] = response.map(item => item.data);
      const { currency } = current;

      const currentCount = current.reduce((acc, item) => acc + item.count, 0);
      const previousCount = previous.reduce((acc, item) => acc + item.count, 0);

      const data = buildNumeric(currentCount, previousCount, currency);

      setInteractionsData({ Emails: current });
      this.setState({
        fetchInProgress: false,
        data,
      });
    }

    handleCardClick = () => {
      const { addNewFilter, history, dashboardInterval } = this.props;
      const { startDate, endDate } = dashboardInterval;
      filters(startDate, endDate).forEach(addNewFilter);
      history.push('/communications/email-logs');
    }

    render() {
      const { fetchInProgress, data } = this.state;
      const { value, percentage, isGrowing } = data;
      return (
        <Numeric
          title={text.TITLE}
          description={text.DESCRIPTION}
          value={value}
          percentage={percentage}
          isGrowing={isGrowing}
          loading={fetchInProgress}
          onClick={this.handleCardClick}
          isCount
        />
      );
    }
  },
));

CallsNumeric.wrappedComponent.propTypes = propTypes;
CallsNumeric.wrappedComponent.defaultProps = defaultProps;

export default {
  component: withRouter(withFilters(CallsNumeric)),
};
