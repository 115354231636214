import React from 'react';

import GridComponentFactory from 'App/components/grid';
import { notifier } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

import { hasAccess } from 'App/services/permissionsService';
import { updateRule, deleteRule } from 'Automation/services/rulesService';

import modifiers from './modifiers';

const propTypes = {
  history: object.isRequired,
};

const tableConfig = {
  tableUrl: '/automations/rules/',
  tableKey: 'rules',
};

const listToOmit = ['name', 'created_by', 'trigger_event', 'last_triggered', 'updated_by', 'is_active', 'actions'];

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const text = {
  EDIT: 'Edit',
  CLONE: 'Clone',
  DELETE: 'Delete',
  STATUS_ERROR: 'Failed to change Rule status',
  STATUS_SUCCESS: 'Succesfully updated Rule status',
  DELETE_SUCCESS: 'Rule deleted successfully',
  DELETE_ERROR: 'Failed to delete rule',
};

const singleActions = ({ onEdit, onDelete, onClone }) => data => [
  {
    data,
    icon: 'Edit',
    action: onEdit,
    label: text.EDIT,
    visibility: hasAccess('automations_rule', 'update'),
  },
  {
    data: data.id,
    label: text.CLONE,
    action: onClone,
    icon: 'Duplicate',
    visibility: hasAccess('automations_rule', 'create'),
  },
  {
    data: data.id,
    label: text.DELETE,
    action: onDelete,
    icon: 'Delete',
    visibility: hasAccess('automations_rule', 'delete'),
  },
].filter(action => action.visibility);

const customColumns = [
  {
    key: 'actions_column',
    headerName: '',
  },
];

const RulesTable = ({ history }) => {
  const toggleActive = async (data, value) => {
    const payload = { ...data, trigger_event: data.trigger_event.id, is_active: value };
    try {
      await updateRule(data.id, payload);
      notifier.success(text.STATUS_SUCCESS);
      gridActions.fetchTableData();
    } catch (err) {
      notifier.error(text.STATUS_ERROR);
    }
  };

  const editRule = (data) => {
    history.push(`/automation/rules/${data.id}`);
  };

  const addCloneRule = (ruleId) => {
    history.push(`/automation/rules/${ruleId}/clone`);
  };

  const deleteRuleAction = async (id) => {
    try {
      await deleteRule(id);
      gridActions.fetchTableData();
      notifier.success(text.DELETE_SUCCESS);
    } catch {
      notifier.error(text.STATUS_ERROR);
    }
  };

  return (
    <GridComponent
      modifiers={modifiers({
        toggle: toggleActive,
        actions: singleActions({
          onEdit: editRule,
          onDelete: deleteRuleAction,
          onClone: addCloneRule,
        }),
      })}
      listToOmit={listToOmit}
      customColumns={customColumns}
    />
  );
};

RulesTable.propTypes = propTypes;

export default withRouter(RulesTable);
