import http from 'App/services/http';

const toggleActiveState = (id, value) => http.patch(`/events/${id}/`, { is_activity_visible: value });

const fetchEventDefinitionData = id => http.get(`/events/${id}/`);

const fetchEventDefinitionFields = id => http.options(`/events/${id}/`);

const editEventDefinition = (id, payload) => http.patch(`/events/${id}/`, payload);

const fetchDerivedEventDefinitionFields = () => http.options('/events/derived/');

const createDerivedEvent = payload => http.post('/events/derived/', { ...payload });

const fetchCustomEventDefinitionFields = () => http.options('/events/custom/');

const createCustomEvent = payload => http.post('/events/custom/', { ...payload });

export {
  toggleActiveState,
  fetchEventDefinitionData,
  fetchEventDefinitionFields,
  editEventDefinition,
  fetchDerivedEventDefinitionFields,
  createDerivedEvent,
  fetchCustomEventDefinitionFields,
  createCustomEvent,
};
