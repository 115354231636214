import React from 'react';

import { MultiActions, Pill } from 'tc-biq-design-system';
import hideCols from 'App/services/utilities/hideCols';
import { openOverlay } from 'App/services/overlayService';
import { SIDEPANEL_ID } from 'App/components/ManageUsersFactory/ManageUsersFactory';
import { RouteCell } from 'App/components/gridCellRenderers';
import { hasAccess } from 'App/services/permissionsService';

// eslint-disable-next-line react/prop-types
const RolesActionCell = actions => ({ data }) => {
  if (actions(data).length === 0) return null;
  return <MultiActions actions={actions(data)} />;
};

// eslint-disable-next-line react/prop-types
const RenderUsersNum = ({ data }) => {
  if (!hasAccess('users_rolebinding', 'read')) return null;
  return (
    <Pill
      icon="User"
      iconPosition="left"
      onIconClick={() => openOverlay(SIDEPANEL_ID, { id: data.id, name: data.name })}
      onClick={() => openOverlay(SIDEPANEL_ID, { id: data.id, name: data.name })}
    >
      {` ${data.bindings}`}
    </Pill>
  );
};

const RoleRouteCell = ({ data, value }) => {
  if (!data.is_editable || !hasAccess('users_rolebinding', 'read')) return <span>{value}</span>;
  return RouteCell('/settings/roles/')({ hrefValue: data.id, value: data.name });
};

export default actions => ({
  ...hideCols(['id', 'permissions']),
  actions: {
    cellRendererFramework: RolesActionCell(actions),
    pinned: 'right',
    maxWidth: 80,
    headerName: '',
    unSortIcon: false,
  },
  name: {
    unSortIcon: false,
    cellRendererFramework: RoleRouteCell,
  },
  bindings: {
    maxWidth: 120,
    unSortIcon: false,
    headerName: 'Users',
    cellRendererFramework: RenderUsersNum,
  },
});
