import React, { useMemo } from 'react';
import { Avatar, Space } from 'tc-biq-design-system';
import { number, oneOfType, string, object, func } from 'prop-types';
import getInitials from 'App/services/utilities/getInitials';

import GridComponentFactory from 'App/components/grid';
import { RouteCell } from 'App/components/gridCellRenderers';

const propTypes = {
  contactId: oneOfType([number, string]).isRequired,
  tableKey: string.isRequired,
  renderCustomFilterComponent: func,
  defaultFilters: object,
  initialFilters: object,
};

const defaultProps = {
  defaultFilters: {},
  initialFilters: {},
  renderCustomFilterComponent: () => null,
};

const tableConfig = (contactId, tableKey) => ({
  tableUrl: `/contacts/${contactId}/referrals/`,
  tableKey,
});

// eslint-disable-next-line react/prop-types
const RenderIdAvatar = ({ value, data }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div>
      <Avatar src={data.avatar} initials={getInitials(data)} />
    </div>
    <Space size={8} />
    {RouteCell('/contacts/')({ hrefValue: `${value}/activityStream`, value })}
  </div>
);

const modifiers = {
  id: {
    cellRendererFramework: RenderIdAvatar,
  },
};

const sizeOptions = [10, 20, 50, 100, 200];

const getTableComponent = ({ contactId, tableKey }) => {
  const { GridComponent, gridActions } = GridComponentFactory(tableConfig(contactId, tableKey));
  return { GridComponent, gridActions };
};

const ReferralsTable = ({
  contactId, tableKey, renderCustomFilterComponent, initialFilters, defaultFilters,
}) => {
  const { GridComponent, gridActions } = useMemo(
    () => getTableComponent({ contactId, tableKey }), [contactId, tableKey]
  );

  return (
    <GridComponent
      renderCustomFilterComponent={() => renderCustomFilterComponent(gridActions.fetchTableData)}
      modifiers={modifiers}
      sizeOptions={sizeOptions}
      defaultFilters={{ ...defaultFilters, ...initialFilters }}
      defaultPageSize={10}
      height="calc(100vh - 450px)"
      useOffset
    />
  );
};

ReferralsTable.propTypes = propTypes;
ReferralsTable.defaultProps = defaultProps;
export default ReferralsTable;
