import React from 'react';
import { UserCell, ContactCell, SmartlookCell } from 'App/components/gridCellRenderers';
import NoDataDash from 'App/components/NoDataDash';
import StatusCell from 'App/components/gridCellRenderers/StatusCell';

export default {
  logged_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => value ? (
      <UserCell
        id={value.id}
        avatar={value.avatar}
        fullName={`${value.first_name} ${value.last_name}`}
      />
    ) : (
      <NoDataDash />
    ),
  },
  contact: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => value ? (
      <ContactCell
        id={value.id}
        avatar={value.avatar}
        fullName={`${value.first_name} ${value.last_name}`}
      />
    ) : (
      <NoDataDash />
    ),
  },
  outcome: { cellRendererFramework: StatusCell },
  provider: { cellRendererFramework: StatusCell },
  destination_phone_number: { cellRendererFramework: SmartlookCell },
  created: {
    width: 160,
  },
  sortable: false,
};
