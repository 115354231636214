/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import { Button, Row, Col } from 'tc-biq-design-system';
import { func, bool, object, string } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import Field, { fieldsResolverObject } from 'App/components/FieldRenderer';
import Loader from 'App/components/Loader';
import AddEventAttribute from 'App/components/AddEventAttribute';
import { openPreviewMessage } from 'Marketing/components/overlays/PreviewMessage';

const propTypes = {
  loadingData: bool,
  loadingFields: bool,
  fieldsDef: object,
  updateFieldValue: func.isRequired,
  attributes: object,
  onDiscard: func.isRequired,
  onCreateContent: func.isRequired,
  selectedContent: object,
  handleOnChange: func.isRequired,
  resetContent: func.isRequired,
  channelValue: string,
};

const defaultProps = {
  loadingData: false,
  loadingFields: false,
  fieldsDef: {},
  attributes: {},
  selectedContent: {},
  channelValue: null,
};

const text = {
  DESIGN_TEMPLATE: 'Design template',
  EDIT_TEMPLATE: 'Preview and edit template',
  DISCARD: 'Discard',
  SAVE_CHANGES: 'Add content',
  PREVIEW_TEMPLATE: 'Preview template',
};

const renderMailContent = ({
  attributes,
  updateFieldValue,
  fieldsProps,
  selectedContent,
  handleOnChange,
}) => (
  <>
    <AddEventAttribute
      updateFieldValue={updateFieldValue}
      fieldKey="subject"
      attributes={attributes}
      onChange={handleOnChange}
    >
      <Field
        {...fieldsProps.content__subject}
        value={selectedContent?.subject}
        label="Subject*"
        name="subject"
        onChange={handleOnChange}
      />
    </AddEventAttribute>
    <AddEventAttribute
      updateFieldValue={updateFieldValue}
      fieldKey="content"
      attributes={attributes}
      onChange={handleOnChange}
    >
      <Field
        {...fieldsProps.content__content}
        label="Content*"
        name="content"
        value={selectedContent?.content}
        onChange={handleOnChange}
        type="textarea"
      />
    </AddEventAttribute>
    <Button
      type="transparent"
      icon="View"
      onClick={() => openPreviewMessage(selectedContent.content)}
    >
      {text.PREVIEW_TEMPLATE}
    </Button>
  </>
);

const renderSmsContent = ({
  fieldsProps,
  updateFieldValue,
  handleOnChange,
  attributes,
  selectedContent,
}) => (
  <AddEventAttribute
    updateFieldValue={updateFieldValue}
    fieldKey="content"
    attributes={attributes}
    onChange={handleOnChange}
  >
    <Field
      {...fieldsProps.content__content}
      label="Content*"
      name="content"
      value={selectedContent?.content}
      onChange={handleOnChange}
      type="textarea"
    />
  </AddEventAttribute>
);

const TemplateContentForm = ({
  loadingData,
  loadingFields,
  fieldsDef,
  updateFieldValue,
  attributes,
  openPlugin,
  onDiscard,
  onCreateContent,
  selectedContent,
  handleOnChange,
  resetContent,
  channelValue,
}) => {
  const fieldsProps = useMemo(() => fieldsResolverObject(fieldsDef), [
    fieldsDef,
  ]);
  if (loadingData || !fieldsProps || loadingFields) return <Loader visible />;

  useEffect(() => () => resetContent(), []);

  const isDisabled = !selectedContent?.language || !selectedContent?.content;

  return (
    <Row gutter={0}>
      <div className="fiq-template-form--content-form">
        <Col xs="100%" md="90%" lg="100%">
          <div className=" fiq-section-wrapper">
            <div className="fiq-template-form__field-wrapper">
              <Field
                {...fieldsProps.content__language}
                value={selectedContent?.language}
                name="language"
                label="Language*"
                multi={false}
                onChange={handleOnChange}
              />
              {channelValue === 'Email'
                ? renderMailContent({
                  fieldsProps,
                  selectedContent,
                  handleOnChange,
                  openPlugin,
                  attributes,
                  updateFieldValue,
                })
                : renderSmsContent({
                  fieldsProps,
                  selectedContent,
                  updateFieldValue,
                  handleOnChange,
                  attributes,
                })}
            </div>
            <div className="fiq-template-form__footer">
              <Button onClick={onDiscard} color="ghost">
                {text.DISCARD}
              </Button>
              <Button onClick={onCreateContent} disabled={isDisabled}>
                {text.SAVE_CHANGES}
              </Button>
            </div>
          </div>
        </Col>
      </div>
    </Row>
  );
};

TemplateContentForm.propTypes = propTypes;
TemplateContentForm.defaultProps = defaultProps;

export default inject(stores => ({
  fieldsDef: stores.templates.fieldsDef,
}))(observer(withRouter(TemplateContentForm)));
