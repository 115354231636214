import { StatusCell } from 'App/components/gridCellRenderers';
import hideCols from 'App/services/utilities/hideCols';

import DownloadFile from './DownloadFile';

const modifiers = fetchTableData => ({
  ...hideCols(['id']),
  requested_datetime: {
    minWidth: 200,
  },
  filename: {
    minWidth: 200,
  },
  state: { cellRendererFramework: StatusCell },
  // eslint-disable-next-line react/prop-types
  file: {
    headerName: '',
    unSortIcon: false,
    cellRendererFramework: DownloadFile(fetchTableData),
    width: 120,
    pinned: 'right',
    cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  },
});

export default modifiers;
