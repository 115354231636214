import React, { useCallback, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { string, object, func } from 'prop-types';
import { Icon, Button } from 'tc-biq-design-system';
import get from 'lodash/get';

import './Fields.scss';

const getFieldKey = (key, name) => key.replace(`${name}__`, '');

const formatFields = (row, fields, name, index, fieldErrors, onChange) => Object.keys(fields)
  .reduce((acc, key) => {
    const fieldKey = getFieldKey(key, name);
    const fieldValue = row[fieldKey];
    const fieldError = get(fieldErrors, `${name}[${index}].${fieldKey}`);

    return {
      ...acc,
      [key]: {
        ...fields[key],
        onChange: (_, val) => onChange(fieldKey, name, val, index),
        hasError: !!fieldError,
        helpText: fieldError,
        value: fieldValue,
      },
    };
  }, {});

const propTypes = {
  name: string.isRequired,
  form: object.isRequired,
  renderRow: func.isRequired,
  onChange: func,
  fields: object,
  fieldErrors: object,
  validate: object,
};

const defaultProps = {
  fields: {},
  onChange: null,
  fieldErrors: null,
  validate: null,
};

const Fields = ({ fields, name, form, renderRow, onChange, fieldErrors, validate }) => {
  const { data, updateFieldValue, setFieldValidators } = form;
  const values = data[name] || [{}];
  const handleChange = useCallback((key, property, value, index) => {
    if (onChange) {
      onChange({ property, index, key, value, values, updateFieldValue });
    } else {
      updateFieldValue(property, value, index, key);
    }
  }, [values]);

  const onAddRow = useCallback(() => {
    updateFieldValue(name, [...values, {}]);
  });

  const onRemoveRow = useCallback((indexTarget) => {
    const newValues = values.reduce((acc, value, index) => {
      if (indexTarget === index) return acc;
      return [
        ...acc,
        value,
      ];
    }, []);

    updateFieldValue(name, newValues);
  });

  useEffect(() => {
    if (validate) {
      Object.keys(validate)
        .forEach(fieldKey => setFieldValidators(`${name}__${fieldKey}`, validate[fieldKey]));
    }
  }, [validate, name]);

  return (
    <div className="fiq-fields">
      {fields && values.map((row, index) => (
        <div className="fiq-fields__wrapper">
          {renderRow(formatFields(row, fields, name, index, fieldErrors, handleChange))}
          {index > 0 && (
          <div className="fiq-fields__wrapper__delete" onClick={() => onRemoveRow(index)}>
            <Icon name="Delete" />
          </div>
          )}
        </div>
      ))}
      <Button onClick={onAddRow}>Add</Button>
    </div>
  );
};

Fields.propTypes = propTypes;
Fields.defaultProps = defaultProps;

const fieldsFactory = formId => inject(stores => ({
  form: stores.forms[formId],
  fieldErrors: stores.forms[formId].fieldErrors,
}))(
  observer(props => <Fields {...props} />),
);

export default fieldsFactory;
