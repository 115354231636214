import React from 'react';
import { withRouter } from 'react-router-dom';
import { MultiActions } from 'tc-biq-design-system';

import { ToggleCell, UserCell, PillCell, RouteCell, DateTimeCell } from 'App/components/gridCellRenderers';
import NoDataDash from 'App/components/NoDataDash';
import hideCols from 'App/services/utilities/hideCols';
import { hasAccess } from 'App/services/permissionsService';
import extractDisplayName from 'App/services/utilities/extractDisplayName';

// eslint-disable-next-line react/prop-types
const RulesActionCell = actions => withRouter(({ data }) => {
  if (actions(data).length === 0) return null;
  return <MultiActions actions={actions(data)} />;
});

const modifier = ({ toggle, actions }) => ({
  ...hideCols(['created', 'updated', 'query', 'action_parameters', 'actions']),
  id: {
    headerName: 'ID',
    pinned: 'left',
    width: 50,
    resizable: false,
    cellRendererFramework: ({ data }) => RouteCell('/automation/rules/')({ hrefValue: data.id, value: data.id }),
  },
  name: {
    cellRendererFramework: ({ data }) => RouteCell('/automation/rules/')({ hrefValue: data.id, value: data.name }),
  },
  is_active: {
    width: 50,
    enableFiltering: false,
    headerName: '',
    pinned: 'left',
    unSortIcon: false,
    cellRendererFramework: ToggleCell(toggle, hasAccess('automations_rule', 'update')),
  },
  actions_column: {
    pinned: 'right',
    width: 15,
    enableFiltering: false,
    cellRendererFramework: RulesActionCell(actions),
    headerName: '',
    unSortIcon: false,
    sortable: false,
  },
  trigger_event: {
    cellRendererFramework: PillCell({ displayField: 'name' }),
  },
  updated_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => data.updated_by ? (
      <UserCell
        id={data.updated_by.id}
        avatar={data.updated_by.avatar}
        fullName={extractDisplayName(data.updated_by)}
        subTitle={<DateTimeCell value={data.updated} />}
      />
    ) : (
      <NoDataDash />
    ),
  },
  created_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => (
      <UserCell
        id={data.created_by.id}
        avatar={data.created_by.avatar}
        fullName={extractDisplayName(data.created_by)}
        subTitle={<DateTimeCell value={data.created} />}
      />
    ),
  },
});

export default modifier;
