import React from 'react';

import Page from 'App/components/Page';
import Workflow from 'Marketing/components/Workflow';

const text = {
  TITLE: 'Workflow',
};

const bread = [
  { label: text.TITLE },
];

const WorkflowPage = () => (
  <Page title={text.TITLE} bread={bread} style={{ margin: '0 20px' }}>
    <div style={{ height: 800 }}>
      <Workflow />
    </div>
  </Page>
);

export default WorkflowPage;
