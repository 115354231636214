export default [
  {
    minH: 1,
    minW: 1,
    i: 'new-leads-chart',
    h: 4,
    moved: false,
    maxW: 6,
    maxH: 5,
    static: true,
    w: 2,
    y: 0,
    x: 0,
  },
  {
    minH: 1,
    minW: 1,
    i: 'lead-to-demo-chart',
    h: 4,
    moved: false,
    maxW: 6,
    maxH: 5,
    static: true,
    w: 2,
    y: 0,
    x: 2,
  },
  {
    minH: 1,
    minW: 1,
    i: 'lead-to-live-chart',
    h: 4,
    moved: false,
    maxW: 6,
    maxH: 5,
    static: true,
    w: 2,
    y: 0,
    x: 4,
  },
  {
    minH: 1,
    minW: 1,
    i: 'conversion-campaign-chart',
    h: 4,
    moved: false,
    maxW: 6,
    maxH: 5,
    static: true,
    w: 5,
    y: 4,
    x: 0,
  },
  {
    minH: 1,
    minW: 1,
    i: 'active-campaigns-numeric',
    h: 2,
    moved: false,
    maxW: 6,
    maxH: 5,
    static: true,
    w: 1,
    y: 4,
    x: 6,
  },
  {
    minH: 1,
    minW: 1,
    i: 'finished-campaigns-numeric',
    h: 2,
    moved: false,
    maxW: 6,
    maxH: 5,
    static: true,
    w: 1,
    y: 5,
    x: 6,
  },
  {
    minH: 1,
    minW: 1,
    i: 'segments-enter-rate',
    h: 5,
    moved: false,
    maxW: 6,
    maxH: 5,
    static: true,
    w: 2,
    y: 8,
    x: 0,
  },
  {
    minH: 1,
    minW: 1,
    i: 'segments-exit-rate',
    h: 5,
    moved: false,
    maxW: 6,
    maxH: 5,
    static: true,
    w: 2,
    y: 8,
    x: 2,
  },
  {
    minH: 1,
    minW: 1,
    i: 'segments-retention-rate',
    h: 5,
    moved: false,
    maxW: 6,
    maxH: 5,
    static: true,
    w: 2,
    y: 8,
    x: 4,
  },
  {
    minH: 1,
    minW: 1,
    i: 'tracking-links-chart',
    h: 4,
    moved: false,
    maxW: 12,
    maxH: 5,
    static: true,
    w: 3,
    y: 13,
    x: 0,
  },
  {
    minH: 1,
    minW: 1,
    i: 'tracking-links-visits-chart',
    h: 4,
    moved: false,
    maxW: 12,
    maxH: 5,
    static: true,
    w: 3,
    y: 13,
    x: 4,
  },
];
