import React from 'react';

export const SavedFiltersContext = React.createContext(null);

// eslint-disable-next-line react/prop-types
const FiltersProvider = ({ value, children }) => (
  <SavedFiltersContext.Provider value={value}>{children}</SavedFiltersContext.Provider>
);

export default FiltersProvider;
