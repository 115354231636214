import React from 'react';
import { array, func } from 'prop-types';
import { Space, Icon } from 'tc-biq-design-system';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

const ColumnCard = sortableElement(({ column, toggle }) => (
  <div className="sort-box" key={column.key} style={{ zIndex: '10000' }}>
    <Icon name="Burger" size="small" />
    <span className="sort-box-label">{column.label || column.key}</span>
    <span onClick={() => toggle(column.key)} className="sort-box-close">
      <Icon name="Close" size="small" />
    </span>
  </div>
));

const ColumnsList = sortableContainer(({ children }) => <div>{children}</div>);

const propTypes = {
  columns: array.isRequired,
  onSortChange: func.isRequired,
  toggle: func.isRequired,
};

const ColumnBoxView = ({ columns, toggle, onSortChange }) => (
  <div className="columns" id="sortable">
    <Space size={5} />
    <ColumnsList getContainer={() => document.getElementById('sortable')} onSortEnd={onSortChange}>
      {columns.map((column, index) => (
        <ColumnCard column={column} key={`item-${column.key}`} index={index} toggle={toggle} />
      ))}
    </ColumnsList>
  </div>
);

ColumnBoxView.propTypes = propTypes;
export default ColumnBoxView;
