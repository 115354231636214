import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import { Input, Button, Space, Checkbox, Tooltip, Icon } from 'tc-biq-design-system';
import { func, object, bool, string } from 'prop-types';

import Page from 'App/components/Page';
import QueryBuilderFactory from 'App/components/QueryBuilderFactory';

import './ContactAccessGroupSinglePage.scss';
import LoadingPage from 'App/components/LoadingPage';

const propTypes = {
  requestInProgress: bool.isRequired,
  fetchCagData: func.isRequired,
  match: object.isRequired,
  resetCagData: func.isRequired,
  fields: object.isRequired,
  fetchQueryBuilderMetadata: func.isRequired,
};

const text = {
  CREATE_TITLE: 'Create contact access group',
  EDIT_TITLE: 'Edit contact access group',
  NAME: 'Name',
  PLACEHOLDER: 'Enter group name...',
  BUTTON_LABELS_EDIT: {
    cancel: 'Discard changes',
    confirm: 'Save changes',
  },
  BUTTON_LABELS_ADD: {
    cancel: 'Discard',
    confirm: 'Create access group',
  },
  MANAGER_VISIBILITY: 'Visible for assigned managers',
  MANAGER_VISIBILITY_HELP_TEXT: 'Users can see only contacts assigned to them.',
};

const { QueryBuilder } = QueryBuilderFactory({ storeKey: 'cag' });

const GroupNameField = inject(stores => ({
  cagName: stores.cag.cagName,
  updateCagName: stores.cag.updateCagName,
  error: stores.cag.errors.cagName,
}))(
  observer(({ cagName, updateCagName, error }) => (
    <div className="fiq-cag-single-page__name-field">
      <Input
        onChange={updateCagName}
        label={text.NAME}
        placeholder={text.PLACEHOLDER}
        value={cagName}
        hasError={!!error}
        helpText={error}
      />
    </div>
  )),
);

GroupNameField.wrappedComponent.propTypes = {
  cagName: string.isRequired,
  updateCagName: func.isRequired,
};

const GroupManagerVisibilityField = inject(stores => ({
  cagManagerVisiblity: stores.cag.cagManagerVisiblity,
  updateCagManagerVisibility: stores.cag.updateCagManagerVisibility,
}))(
  observer(({ cagManagerVisiblity, updateCagManagerVisibility }) => (
    <div className="fiq-cag-single-page__manager-visibility-field">
      <Checkbox onChange={updateCagManagerVisibility} checked={cagManagerVisiblity}>
        {text.MANAGER_VISIBILITY}
      </Checkbox>
      <Space size={12} />
      <Tooltip title={text.MANAGER_VISIBILITY_HELP_TEXT} placement="right">
        <Icon colorName="text-neutral-500" size="small" name="Info" />
      </Tooltip>
    </div>
  )),
);

GroupManagerVisibilityField.wrappedComponent.propTypes = {
  cagManagerVisiblity: bool.isRequired,
  updateCagManagerVisibility: func.isRequired,
};

const Footer = withRouter(
  inject(stores => ({
    addEditContactAccessGroup: stores.cag.addEditContactAccessGroup,
    requestInProgress: stores.cag.requestInProgress.contactAccessGroupAction,
    resetCagData: stores.cag.resetCagData,
  }))(
    observer(({ addEditContactAccessGroup, requestInProgress, history, resetCagData, match }) => {
      const { clone, cagId } = match.params;
      const isAdd = cagId === 'create-group' || !!clone;
      const routeToCagTable = () => {
        resetCagData();
        history.push('/settings/contact-access-groups');
      };
      const addEditGroup = async () => {
        if (isAdd) {
          await addEditContactAccessGroup(null, history);
        } else {
          await addEditContactAccessGroup(cagId, history);
        }
      };
      return (
        <div className="fiq-cag-single-page__footer">
          <Button onClick={routeToCagTable} color="ghost">
            {isAdd ? text.BUTTON_LABELS_ADD.cancel : text.BUTTON_LABELS_EDIT.cancel}
          </Button>
          <Space size={8} />
          <Button onClick={addEditGroup} loading={requestInProgress} disabled={requestInProgress}>
            {isAdd ? text.BUTTON_LABELS_ADD.confirm : text.BUTTON_LABELS_EDIT.confirm}
          </Button>
        </div>
      );
    }),
  ),
);

Footer.wrappedComponent.propTypes = {
  addEditContactAccessGroup: func.isRequired,
  requestInProgress: bool.isRequired,
  resetCagData: func.isRequired,
};

const ContactAccessGroupSinglePage = ({
  match,
  fetchCagData,
  requestInProgress,
  resetCagData,
  fields,
  fetchQueryBuilderMetadata,
}) => {
  const { cagId, clone } = match.params;
  const fetchData = async () => {
    await fetchCagData(cagId);
  };

  useEffect(() => {
    if (cagId && cagId !== 'create-group' && !requestInProgress) fetchData();
    return resetCagData();
  }, [cagId]);

  if (isEmpty(fields) && !requestInProgress) fetchQueryBuilderMetadata();
  if (requestInProgress) return <LoadingPage />;
  return (
    <Page
      title={cagId && cagId !== 'create-group' ? text.EDIT_TITLE : text.CREATE_TITLE}
      style={{ margin: '20px' }}
      goBackIcon={false}
    >
      <div className="fiq-cag-single-page">
        <GroupNameField />
        <GroupManagerVisibilityField />
        <QueryBuilder />
        <Footer isClone={clone} />
      </div>
    </Page>
  );
};

ContactAccessGroupSinglePage.propTypes = propTypes;

export default inject(stores => ({
  fetchCagData: stores.cag.fetchCagData,
  requestInProgress: stores.cag.requestInProgress.cagData,
  contactFields: stores.cag.contactFields,
  resetCagData: stores.cag.resetCagData,
  fields: stores.cag.fields,
  fetchQueryBuilderMetadata: stores.cag.fetchQueryBuilderMetadata,
}))(observer(withRouter(ContactAccessGroupSinglePage)));
