import React, { Fragment } from 'react';
import { Modal, Button } from 'tc-biq-design-system';
import { object, bool, func } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { closeOverlay } from 'App/services/overlayService';

const propTypes = {
  visible: bool,
  parameters: object,
  deleteCag: func.isRequired,
  requestInProgress: bool.isRequired,
  onSuccess: func.isRequired,
};

const defaultProps = {
  visible: false,
  parameters: null,
};

export const SIDEPANEL_ID = 'DELETE_CAG';

const text = {
  DELETE_Q: roleName => `Are you sure you want to delete Contact access group ${roleName}`,
  TITLE: 'Delete contact access group',
  DELETE_CAG: 'Delete',
  CANCEL: 'Cancel',
};

const close = () => closeOverlay(SIDEPANEL_ID);

const customFooter = (confirm, cancel, isLoading) => () => (
  <Fragment>
    <Button color="ghost" onClick={cancel}>
      {text.CANCEL}
    </Button>
    <Button disabled={isLoading} color="destructive" onClick={confirm} loading={isLoading}>
      {text.DELETE_CAG}
    </Button>
  </Fragment>
);

const DeleteContactAccessGroup = ({
  visible,
  parameters,
  deleteCag,
  requestInProgress,
  onSuccess,
}) => {
  if (!parameters) return null;
  const { cagName, cagId } = parameters;
  const removeCag = async () => {
    await deleteCag(cagId);
    if (onSuccess) onSuccess();
    close();
  };
  return (
    <Modal
      closable
      visible={visible}
      title={text.TITLE}
      confirmText={text.DELETE_CAG}
      icon="Delete"
      type="error"
      footerRender={customFooter(removeCag, close, requestInProgress)}
      onCloseIconClick={close}
    >
      {text.DELETE_Q(cagName)}
    </Modal>
  );
};

DeleteContactAccessGroup.propTypes = propTypes;
DeleteContactAccessGroup.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  deleteCag: stores.cag.removeContactAccessGroup,
  requestInProgress: stores.cag.requestInProgress.contactAccessGroupAction,
}))(observer(DeleteContactAccessGroup));
