import React from 'react';
import { NavCard } from 'tc-biq-design-system';
import { number, oneOfType, string } from 'prop-types';
import CampaignActivityStream from './CampaignActivityStream';
import CampaignRecipientsTable from './CampaignRecipientsTable';


const propTypes = {
  campaignId: oneOfType([number, string]).isRequired,
};

const tabTypes = {
  ACTIVITY_STREAM: 'activity_stream',
  RECIPIENTS: 'recipients',
};

const tabs = [
  { title: 'Activity Stream', id: tabTypes.ACTIVITY_STREAM },
  { title: 'Recipients', id: tabTypes.RECIPIENTS },
];

const CampaignTabs = ({ campaignId }) => (
  <div style={{ padding: '0 20px 20px 20px' }}>
    <NavCard tabs={tabs}>
      {(active) => {
        if (active === tabTypes.ACTIVITY_STREAM) return <CampaignActivityStream id={campaignId} />;
        if (
          active === tabTypes.RECIPIENTS
        ) return <CampaignRecipientsTable campaignId={campaignId} />;
        return null;
      }}
    </NavCard>
  </div>
);

CampaignTabs.propTypes = propTypes;

export default CampaignTabs;
