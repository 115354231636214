import React from 'react';
import { string, oneOf, number } from 'prop-types';
import classNames from 'classnames';

import * as RenderIcons from './components';

import './RenderIcon.scss';

const propTypes = {
  icon: string.isRequired,
  type: oneOf(['browser', 'asset', 'custom']),
  iconSize: number,
  colorName: string,
};

const defaultProps = {
  iconSize: 24,
  colorName: null,
  type: 'custom',
};

const RenderIcon = ({ icon, type, iconSize, colorName }) => {
  const Icon = RenderIcons[icon] ? RenderIcons[icon] : RenderIcons[type];
  const size = type === 'asset' ? '16px' : iconSize;

  return (
    <span
      className={classNames('fiq-render-icon', {
        browser: type === 'browser',
        [colorName]: colorName,
      })}
    >
      <svg width={size} height={size} viewBox="0 0 24 24" version="1.1">
        {Icon ? <Icon /> : null}
      </svg>
    </span>
  );
};

RenderIcon.propTypes = propTypes;
RenderIcon.defaultProps = defaultProps;

export default RenderIcon;
