import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { object, func } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { fetchContactAttributes } from 'Communications/services/CommunicationsService';
import { fetchEventDefinitionData } from 'Settings/Sections/Events/services/EventsService';
import { fetchSendGridMail } from 'Settings/Sections/Integrations/services/utils';
import run from 'App/services/utilities/run';

import Page from 'App/components/Page';
import OverlayWrapper from 'App/components/OverlayWrapper';
import TemplateContentForm from 'Communications/components/TemplateContentForm';
import TemplateForm from 'Communications/components/TemplateForm';
import CopyTemplateContent, {
  openCopyTemplateContent,
} from 'Communications/components/overlays/CopyTemplateContent';
import channelEnum from 'App/enums/channelEnum';
import PreviewMessage from 'Marketing/components/overlays/PreviewMessage';

const propTypes = {
  match: object.isRequired,
  updateFieldValue: func.isRequired,
  eventType: object,
  template: object,
  fetchTemplate: func.isRequired,
  channel: object,
};

const defaultProps = {
  eventType: null,
  template: null,
  channel: null,
};

const text = {
  TITLE: 'Templates',
  SINGLE_TITLE: 'Template',
};

const bread = id => [
  { label: text.TITLE, route: '/communications/templates' },
  { label: id },
];

const TemplatesSinglePage = ({
  template,
  fetchTemplate,
  match,
  updateFieldValue,
  eventType,
  channel,
}) => {
  const [currentContents, setContents] = useState([]);
  const [selectedContent, selectContent] = useState({});

  const [isOverlayVisible, toggleOverlay] = useState(false);

  const [responseData, setData] = useState(null);

  const { templateId, cloneId } = match.params;

  const channelValue = channel?.value;

  const setDefaultContent = (item) => {
    const contents = currentContents.map(content => ({
      ...content,
      is_default: content.language.value === item.language.value,
    }));
    setContents(contents);
    updateFieldValue('content', contents);
  };

  const handleOnChange = (name, value) => {
    selectContent(prevState => ({ ...prevState, [name]: value }));
  };

  const createContent = () => {
    let contents;
    const existContent = currentContents.some(
      item => item.language === selectedContent.language.value,
    );
    if (existContent) {
      contents = currentContents.map(
        item => (item.language === selectedContent.language.value
          ? {
            ...selectedContent,
            language: selectedContent?.language.value,
            is_default: item.is_default,
          }
          : item),
      );
    } else {
      // Set first content to be default one
      const formatedContent = {
        ...selectedContent,
        is_default: !currentContents.length,
        language: selectedContent?.language.value,
      };
      contents = [...currentContents, formatedContent];
    }
    setContents(contents);
    updateFieldValue('content', contents);
    toggleOverlay(false);
  };

  const onEditContent = (item) => {
    selectContent(item);
    toggleOverlay(true);
  };

  const onDelete = (item) => {
    const filteredContents = currentContents.filter(
      content => content.language !== item.language,
    );
    setContents(filteredContents);
    updateFieldValue('content', filteredContents);
  };

  const resetContent = () => {
    selectContent({});
  };

  const setDefaultMail = value => updateFieldValue('email_from', value);

  const onAddContent = () => {
    const defaultTemplate = currentContents.find(item => item.is_default);
    if (defaultTemplate) {
      openCopyTemplateContent({
        content: defaultTemplate.content,
        metadata: defaultTemplate?.metadata,
      });
      return;
    }
    toggleOverlay(true);
  };

  // Set template to local store
  useEffect(() => {
    setContents(template?.content || []);
  }, [template?.id]);

  // Fetch template if exist
  useEffect(() => {
    if (templateId && templateId !== 'create') {
      fetchTemplate(templateId, cloneId);
    } else if (channelValue === channelEnum.EMAIL) {
      fetchSendGridMail(setDefaultMail);
    }
  }, [templateId]);

  // Fetch attributes for subject and email content
  useEffect(() => {
    async function getAttributes() {
      const [err, data] = await run(fetchContactAttributes());
      if (err) {
        return;
      }
      setData(data);
    }
    getAttributes();
  }, []);

  // Fetch events for TRANSACTIONAL templates
  useEffect(() => {
    async function getEventFields() {
      const { data } = await fetchEventDefinitionData(eventType.id);
      setData(prevState => ({ ...prevState, ...data.template_properties }));
    }
    if (eventType) getEventFields();
  }, [eventType]);

  // Clear contents after changing channel
  useEffect(() => {
    if (channel?.value !== template?.channel) {
      setContents([]);
      updateFieldValue('content', null);
    }
  }, [channel]);

  return (
    <Page
      bread={bread(templateId)}
      title={`${text.SINGLE_TITLE} ${templateId}`}
    >
      <TemplateForm
        contentList={currentContents}
        attributes={responseData}
        onToggle={onAddContent}
        onEditContent={onEditContent}
        onDelete={onDelete}
        setDefaultContent={setDefaultContent}
      />
      <OverlayWrapper isOverlayVisible={isOverlayVisible}>
        <TemplateContentForm
          attributes={responseData}
          onDiscard={() => toggleOverlay(false)}
          updateFieldValue={updateFieldValue}
          onCreateContent={createContent}
          selectedContent={selectedContent}
          handleOnChange={handleOnChange}
          resetContent={resetContent}
          channelValue={channelValue}
        />
      </OverlayWrapper>
      <CopyTemplateContent
        handleOnChange={handleOnChange}
        onToggle={toggleOverlay}
      />
      <PreviewMessage />
    </Page>
  );
};

TemplatesSinglePage.propTypes = propTypes;
TemplatesSinglePage.defaultProps = defaultProps;

export default inject(stores => ({
  eventType: stores.forms.templateForm.data.event_type,
  updateFieldValue: stores.forms.templateForm.updateFieldValue,
  sendTestEmail: stores.templates.sendTestEmail,
  template: stores.templates.template,
  channel: stores.forms.templateForm.data?.channel,
  fetchTemplate: stores.templates.fetchTemplateData,
}))(observer(withRouter(TemplatesSinglePage)));
