import React from 'react';
import { Input, Space } from 'tc-biq-design-system';
import { func, string, number } from 'prop-types';
import omit from 'lodash/omit';

const propTypes = {
  onChange: func.isRequired,
  name: string.isRequired,
  afterFieldSpaceSize: number,
};

const defaultProps = {
  afterFieldSpaceSize: 18,
};

const InputField = (props) => {
  const { onChange, name, afterFieldSpaceSize } = props;
  const fieldProps = omit(props, ['read_only', 'formId', 'visible', 'many', 'afterFieldSpaceSize']);
  return (
    <React.Fragment>
      <Input
        {...fieldProps}
        onChange={e => onChange(name, e.target.value)}
        data-recording-ignore="mask"
      />
      <Space size={afterFieldSpaceSize} />
    </React.Fragment>
  );
};

InputField.propTypes = propTypes;
InputField.defaultProps = defaultProps;

export default InputField;
