import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'tc-biq-design-system';
import { func, object, bool } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';

const propTypes = {
  createTemplate: func.isRequired,
  updateTemplate: func.isRequired,
  requestInProgress: bool.isRequired,
  history: object.isRequired,
  match: object.isRequired,
  errors: object.isRequired,
  isDataEmpty: bool.isRequired,
  isEdit: bool.isRequired,
  validateForm: func.isRequired,
};

const text = {
  DISCARD: 'Discard',
  CREATE_TEMPLATE: 'Create template',
  SAVE_AS_DRAFT: 'Save as draft',
  SAVE_CHANGES: 'Save changes',
  DISCARD_CHANGES: 'Discard changes',
};

const TemplateFormFooter = ({
  createTemplate,
  updateTemplate,
  requestInProgress,
  history,
  match,
  errors,
  isDataEmpty,
  isEdit,
  validateForm,
}) => {
  const { templateId } = match.params;
  const onDiscard = () => history.push('/communications/templates');
  const onStart = async () => {
    if (!validateForm()) return;
    if (isEdit) {
      updateTemplate(templateId, { history });
    } else {
      createTemplate({ history });
    }
  };

  return (
    <div className="fiq-campaign-form__footer">
      <Button onClick={onDiscard} color="ghost">
        {isEdit ? text.DISCARD_CHANGES : text.DISCARD}
      </Button>
      <Button
        disabled={requestInProgress || !isEmpty(errors) || isDataEmpty}
        loading={requestInProgress}
        onClick={onStart}
      >
        {isEdit ? text.SAVE_CHANGES : text.CREATE_TEMPLATE}
      </Button>
    </div>
  );
};

TemplateFormFooter.propTypes = propTypes;

export default inject(stores => ({
  createTemplate: stores.templates.createTemplate,
  updateTemplate: stores.templates.updateTemplate,
  errors: stores.templates.errors,
  requestInProgress: stores.templates.requestInProgress.updateCreateTemplate,
  isDataEmpty: isEmpty(stores.forms.templateForm.data),
  validateForm: stores.forms.templateForm.validateForm,
}))(observer(withRouter(TemplateFormFooter)));
