import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { func, array, number, string, oneOfType, bool } from 'prop-types';
import { Button } from 'tc-biq-design-system';

import { openOverlay } from 'App/services/overlayService';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import If from 'App/components/If';
import InfiniteScroll from 'App/components/InfiniteScroll';
import { hasAccess } from 'App/services/permissionsService';
import DocAccordion from './DocAccordion';
import UploadDoc, { MODAL_ID } from './overlays/UploadDoc';

import './Documents.scss';

const propTypes = {
  documents: array.isRequired,
  fetchContactDocuments: func.isRequired,
  fetchDocumentsOptions: func.isRequired,
  id: oneOfType([number, string]).isRequired,
  hasMore: bool.isRequired,
  requestInProgress: bool.isRequired,
};

const text = {
  UPLOAD_DOC: 'Upload documents',
};

const Documents = ({
  id,
  documents,
  fetchContactDocuments,
  fetchDocumentsOptions,
  hasMore,
  requestInProgress,
}) => {
  useEffect(() => {
    async function fetchData() {
      await fetchContactDocuments();
      await fetchDocumentsOptions();
    }
    fetchData();
  }, [id]);

  const uploadDoc = () => {
    openOverlay(MODAL_ID);
  };

  const fetchDataOnScroll = async () => {
    await fetchContactDocuments(true);
  };

  return (
    <div className="fiq-documents">
      <If condition={hasAccess('documents_document', 'create')}>
        <div className="fiq-documents__upload-btn">
          <Button icon="Upload" color="transparent" onClick={uploadDoc}>
            {text.UPLOAD_DOC}
          </Button>
        </div>
      </If>
      <If condition={!!documents.length}>
        <InfiniteScroll
          style={{ height: '300px', overflowY: 'auto' }}
          hasMore={hasMore}
          isLoading={requestInProgress}
          fetchData={fetchDataOnScroll}
        >
          {documents.map(doc => (
            <DocAccordion contactId={id} key={doc.id} doc={doc} />
          ))}
        </InfiniteScroll>
      </If>
      <UploadDoc />
    </div>
  );
};

Documents.propTypes = propTypes;

export default inject(stores => ({
  documents: stores.contact.documents.data,
  fetchContactDocuments: stores.contact.documents.fetchContactDocuments,
  fetchDocumentsOptions: stores.contact.documents.fetchDocumentsOptions,
  requestInProgress: stores.contact.documents.requestInProgress.data,
  hasMore: stores.contact.documents.hasMore,
}))(observer(withErrorBoundary(Documents)));
