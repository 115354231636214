import React, { useState, useEffect } from 'react';
import { Dropdown } from 'tc-biq-design-system';
import { func, object } from 'prop-types';
import { inject, observer } from 'mobx-react';

import './DepthFilter.scss';

const ALL_LEVELS = 'All Sub-Levels';

const levels = [2, 3, 4, 5, 6, 7, 8, 9, 10, ALL_LEVELS];

const text = {
  DROPDOWN_TITLE: level => getLabel(level),
};

const getLabel = level => (level === ALL_LEVELS ? ALL_LEVELS : `Sub-Level ${level - 1}`);

const renderItem = (handleOnClick, level, activeLevel) => (
  <div
    className={`depth-list__item ${(activeLevel === level) ? 'active' : ''}`}
    onClick={() => handleOnClick({ depth: level })}
  >
    {getLabel(level)}
  </div>
);

const list = (handleOnClick, activeLevel) => levels.map(level => ({
  item: onClick => renderItem(onClick, level, activeLevel),
  onClick: () => handleOnClick({ depth: level }),
}));

const DepthFilter = ({ gridStore, fetchTableData }) => {
  const [activeLevel, setActiveLevel] = useState(2);

  const applyDepthFilter = (depthQuery) => {
    gridStore.resetCursor();
    if (depthQuery.depth === ALL_LEVELS) {
      gridStore.addCustomQuery({ depth: null, 'depth!': 1 });
    } else {
      gridStore.addCustomQuery(depthQuery);
    }
    setActiveLevel(depthQuery.depth);
    fetchTableData();
  };

  useEffect(() => gridStore.resetCustomQuery(), []);

  return (
    <Dropdown
      customList={list(applyDepthFilter, activeLevel)}
      title={text.DROPDOWN_TITLE(activeLevel)}
    />
  );
};

DepthFilter.propTypes = {
  gridStore: object.isRequired,
  fetchTableData: func.isRequired,
};


export default inject(stores => ({
  gridStore: stores.tables.contactSubReferrals,
}))(observer(DepthFilter));
