import React, { useCallback, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const FieldDataProvider = (Component, formId, fieldKey) => inject(stores => ({
  form: stores.forms[formId],
}))(
  observer(({ onChange, ...props }) => {
    const { data, fieldErrors, updateFieldValue, validateField, setFieldValidators } = props.form;

    const onBlur = useCallback(() => {
      validateField(fieldKey);
    }, [fieldKey]);

    const handleOnChange = useCallback((...value) => {
      if (onChange) {
        onChange(...value, updateFieldValue);
      } else {
        updateFieldValue(...value);
      }
    }, [updateFieldValue, onChange]);

    useEffect(() => {
      setFieldValidators(fieldKey, props.validate);
    }, [props.validate, fieldKey]);

    return (
      <Component
        {...props}
        onChange={handleOnChange}
        value={data[fieldKey]}
        hasError={!!fieldErrors[fieldKey]}
        helpText={fieldErrors[fieldKey] || props.helpText}
        onBlur={props.validate && onBlur}
      />
    );
  }),
);

export default FieldDataProvider;
