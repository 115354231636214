import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { bool, func, object } from 'prop-types';
import { Space, Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import Page from 'App/components/Page';
import Loader from 'App/components/Loader';
import Field, { fieldsResolverObject } from 'App/components/FieldRenderer';
import QueryBuilderFactory from 'App/components/QueryBuilderFactory';

import './SegmentsSinglePage.scss';

const propTypes = {
  fetchSegment: func.isRequired,
  fetchFieldsDef: func.isRequired,
  loadingData: bool.isRequired,
  match: object.isRequired,
  loadingFields: bool.isRequired,
  fieldsDef: object.isRequired,
  location: object.isRequired,
  resetFieldsData: func.isRequired,
  queryBuilder: object.isRequired,
};

const text = {
  TITLE: 'Segments',
  TITLE_CREATE: 'Create a segment',
  TITLE_EDIT: 'Edit a segment',
  BUTTON_LABELS_CREATE: { confirm: 'Create segment', cancel: 'Discard' },
  BUTTON_LABELS_EDIT: { confirm: 'Save changes', cancel: 'Discard changes' },
};

const bread = id => [
  { label: 'Automation' },
  { label: text.TITLE, route: '/automation/segments/' },
  { label: id },
];

const { QueryBuilder } = QueryBuilderFactory({
  storeKey: 'segments',
  isEventBased: true,
});

const SegmentsFooter = inject(stores => ({
  createInProgress: stores.segments.requestInProgress.create,
  editInProgress: stores.segments.requestInProgress.edit,
  createSegment: stores.segments.createSegment,
  editSegment: stores.segments.editSegment,
  validateForm: stores.forms.segmentForm.validateForm,
}))(
  observer(
    withRouter(
      ({
        history,
        match,
        location,
        editSegment,
        createSegment,
        createInProgress,
        editInProgress,
        validateForm,
      }) => {
        const { segmentId } = match.params;
        const { id } = location.state || {};
        const isCreate = segmentId === 'create' || !!id;
        const buttonLabels = isCreate ? text.BUTTON_LABELS_CREATE : text.BUTTON_LABELS_EDIT;
        const loading = isCreate ? createInProgress : editInProgress;

        const confirmAction = isCreate
          ? () => validateForm() && createSegment(history)
          : () => validateForm() && editSegment(segmentId, history);
        const goToSegments = () => history.push('/automation/segments');
        return (
          <div className="fiq-segments-single-page__footer">
            <Button onClick={goToSegments} color="ghost">
              {buttonLabels.cancel}
            </Button>
            <Space size={4} />
            <Button loading={!!loading} onClick={confirmAction}>
              {buttonLabels.confirm}
            </Button>
          </div>
        );
      },
    ),
  ),
);

const SegmentsSinglePage = ({
  fetchSegment,
  fetchFieldsDef,
  loadingData,
  loadingFields,
  fieldsDef,
  match,
  location,
  resetFieldsData,
  queryBuilder,
}) => {
  const { segmentId } = match.params;
  const isCreate = segmentId === 'create' || !!id;
  const { id } = location.state || {};
  const fieldsProps = fieldsResolverObject(fieldsDef);

  useEffect(() => {
    resetFieldsData();
    queryBuilder.resetQueries();
    if ((!Number.isNaN(+segmentId) || id) && !loadingData) fetchSegment(id || segmentId);
    fetchFieldsDef();
  }, []);

  return (
    <Page
      bread={bread(segmentId)}
      title={isCreate ? text.TITLE_CREATE : text.TITLE_EDIT}
      goBackIcon={null}
    >
      <div className="fiq-segments-single-page">
        <Loader visible={loadingData || loadingFields} />
        {!isEmpty(fieldsDef) && (
          <div className="fiq-segments-single-page__section">
            <Field {...fieldsProps.name} name="name" formId="segmentForm" />
            <Field {...fieldsProps.description} name="description" formId="segmentForm" />
            <div className="fiq-segments-single-page__querybuilder">
              <QueryBuilder />
            </div>
          </div>
        )}
        <SegmentsFooter />
      </div>
    </Page>
  );
};

SegmentsSinglePage.propTypes = propTypes;

export default inject(stores => ({
  fetchSegment: stores.segments.fetchSegment,
  fetchFieldsDef: stores.segments.fetchFieldsDef,
  loadingData: stores.segments.requestInProgress.fetchSegment,
  loadingFields: stores.segments.requestInProgress.fieldsDef,
  createSegment: stores.segments.createSegment,
  queryBuilder: stores.segments.queryBuilder,
  fieldsDef: stores.segments.fieldsDef,
  resetFieldsData: stores.forms.segmentForm.resetFieldsData,
}))(observer(withRouter(SegmentsSinglePage)));
