import React, { useEffect, useState } from 'react';
import { object, string, func, oneOfType, number } from 'prop-types';
import { Button, Icon, Space } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';

import './AssignManager.scss';
import { hasAccess } from 'App/services/permissionsService';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import UserCell from 'App/components/gridCellRenderers/UserCell';
import SelectManagerDropdown from './SelectManagerDropdown';

const text = {
  SALES_MANAGER: 'Sales manager',
  COMPLIANCE_MANAGER: 'Compliance manager',
  RETENTION_MANAGER: 'Retention manager',
  ASSIGN_TO: 'Assign to',
  ASSING_SALES_TITLE: 'Assign as Sales manager',
  ASSING_RETENTION_TITLE: 'Assign as Retention manager',
  ASSING_COMPLIANCE_MANAGER_TITLE: 'Assign as Compliance manager',
};

const managersEnum = {
  SALES_MANAGER: {
    title: text.SALES_MANAGER,
    key: 'sales_manager',
    sidepanelTitle: text.ASSING_SALES_TITLE,
  },
  COMPLIANCE_MANAGER: {
    title: text.COMPLIANCE_MANAGER,
    key: 'compliance_manager',
    sidepanelTitle: text.ASSING_COMPLIANCE_MANAGER_TITLE,
  },
  RETENTION_MANAGER: {
    title: text.RETENTION_MANAGER,
    key: 'retention_manager',
    sidepanelTitle: text.ASSING_RETENTION_TITLE,
  },
};

const AssignManager = ({ manager, title, managerKey, assignManagerToContact }) => {
  const [isOpen, toggleDropdown] = useState(null);
  const [selectedManager, selectManager] = useState(null);
  const isEditable = hasAccess('contacts_contact', 'update');

  useEffect(() => {
    const formatedManager = manager
      ? {
        ...manager,
        display_name: `${manager.first_name} ${manager.last_name}`,
        value: manager.id,
      }
      : null;
    selectManager(formatedManager);
  }, []);

  const submit = async (item) => {
    await assignManagerToContact(item?.id || null, managerKey);
    close();
  };

  const onChange = (name, value) => {
    selectManager(value);
    if (!value) submit(value);
  };

  const open = key => toggleDropdown(key);
  const close = () => toggleDropdown(null);

  return (
    <div className="fiq-assign-manager">
      <div data-recording-ignore="mask" className="fiq-assign-manager__title truncate-text">
        {title}
        {isEditable && !!manager && <Icon name="Pen" size="small" onClick={open} />}
      </div>

      <div className="fiq-assign-manager__profile">
        {!selectedManager && (
          <div>
            <Button disabled={!isEditable} onClick={open} color="transparent" icon="Plus">
              {text.ASSIGN_TO}
            </Button>
          </div>
        )}
        {selectedManager && (
          <div className="fiq-assign-manager__avatar">
            <Space size={4} />
            <UserCell
              id={selectedManager.id}
              avatar={selectedManager.avatar}
              fullName={extractDisplayName(selectedManager)}
            />
          </div>
        )}
        <SelectManagerDropdown
          showUnassignButton={!!manager}
          value={selectedManager}
          onChange={onChange}
          onAdd={submit}
          hidden={!isOpen && isOpen !== managerKey}
          closeDropdown={close}
        />
      </div>
    </div>
  );
};

AssignManager.propTypes = {
  manager: oneOfType([object, number]),
  title: string.isRequired,
  managerKey: string.isRequired,
  assignManagerToContact: func,
};

AssignManager.defaultProps = {
  manager: null,
  assignManagerToContact: null,
};

const AssignManagerList = ({ contactData, assignManagerToContact }) => (
  <div className="fiq-assign-manager__list">
    {Object.keys(managersEnum).map(managerKey => (
      <React.Fragment key={managerKey}>
        <AssignManager
          title={managersEnum[managerKey].title}
          sidepanelTitle={managersEnum[managerKey].sidepanelTitle}
          manager={contactData[managersEnum[managerKey].key]}
          managerKey={managersEnum[managerKey].key}
          key={managerKey}
          assignManagerToContact={assignManagerToContact}
        />
      </React.Fragment>
    ))}
  </div>
);

AssignManagerList.propTypes = {
  contactData: object.isRequired,
  assignManagerToContact: func,
};

AssignManagerList.defaultProps = {
  assignManagerToContact: null,
};

export default inject(stores => ({
  contactData: stores.contact.profile.contactData,
  assignManagerToContact: stores.contact.profile.assignManagerToContact,
}))(observer(AssignManagerList));
