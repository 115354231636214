import React, { Fragment, PureComponent } from 'react';
import classNames from 'classnames';
import { Button, Icon } from 'tc-biq-design-system';
import Field from 'App/components/FieldRenderer';
import { object, func, bool, string, number } from 'prop-types';

const propTypes = {
  hidden: bool.isRequired,
  closeDropdown: func.isRequired,
  onAdd: func,
  value: object,
  onChange: func,
  eventId: number,
  channel: string,
};

const defaultProps = {
  value: null,
  onChange: null,
  onAdd: null,
  eventId: null,
  channel: null,
};

const text = {
  CHOOSE_TEMPLATE: 'Choose template',
  CHOOSE: 'Choose',
};

// eslint-disable-next-line
const TemplateField = ({ channel, eventId, value, onChange, onAdd }) => (
  <Fragment>
    <Field
      type="related field"
      labelKey="display_name"
      valueKey="value"
      id="template"
      name="template"
      placeholder={text.PLACEHOLDER}
      related_model={{
        display_name: 'template',
        value: 'email_templates',
      }}
      filterParams={{
        is_active: true,
        is_shadow_copy: false,
        type: 'Transactional',
        event_type: eventId,
        channel__exact: channel,
      }}
      value={value}
      onChange={onChange}
    />
    <Button onClick={() => onAdd(value)}>{text.CHOOSE}</Button>
  </Fragment>

);


function isReactSelect(element) {
  if (!element) return false;
  const isCloseIcon = element.classList.contains('Select-value-icon');
  const isReactSelectEl = element.id.indexOf('react-select') !== -1;
  if (isReactSelectEl || isCloseIcon) {
    return true;
  }
  return false;
}

class AddTemplateDropdown extends PureComponent {
  close = () => {
    const { closeDropdown } = this.props;
    closeDropdown();
  };

  handleClick = ({ target }) => {
    if (this.filterDropdownRef?.contains(target) || isReactSelect(target)) return;
    this.close();
  };

  render() {
    const { hidden, value, onChange, onAdd, eventId, channel } = this.props;
    return (
      <div
        ref={(node) => {
          this.filterDropdownRef = node;
        }}
        className={classNames('fiq-templates__add-template__template-dropdown', {
          hidden,
        })}
      >
        <div className="fiq-templates__add-template__template-dropdown__heading">
          <span className="tc-paragraph-strong">{text.CHOOSE_TEMPLATE}</span>
          <span onClick={this.close}>
            <Icon name="Close" size="small" />
          </span>
        </div>
        {!hidden
        && (
        <TemplateField
          channel={channel}
          eventId={eventId}
          value={value}
          onChange={onChange}
          onAdd={onAdd}
        />
        )}
      </div>
    );
  }
}

AddTemplateDropdown.propTypes = propTypes;
AddTemplateDropdown.defaultProps = defaultProps;

export default AddTemplateDropdown;
