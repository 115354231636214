import React, { Fragment } from 'react';
import { Sidepanel, Space, Tooltip, Icon } from 'tc-biq-design-system';
import { func, object, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import {
  actionIDIconMap,
  blockTypeIconMap,
  blockDescriptionMap,
  blockActionDescriptionMap,
} from '../../libs/consts';

import './SelectBlock.scss';

const propTypes = {
  blocks: object,
  onBlockSelected: func,
  visible: bool,
  onClose: func,
  insertExitNode: bool,
};

const defaultProps = {
  blocks: {},
  onBlockSelected: () => null,
  visible: false,
  onClose: () => null,
  insertExitNode: false,
};

const text = {
  TITLE: 'Insert',
};

const SIDEPANEL_ID = 'SELECT_BLOCK';

export const openSelectBlockSidepanel = (insertExitNode) => {
  openOverlay(SIDEPANEL_ID, { insertExitNode });
};

export const closeSelectBlockSidepanel = () => {
  closeOverlay(SIDEPANEL_ID);
};

const SelectBlock = ({ blocks, visible, onBlockSelected, onClose, insertExitNode }) => {
  if (!visible) return null;
  const { Exit, ...otherBlocks } = blocks;
  const filteredBlocks = insertExitNode ? blocks : otherBlocks;
  return (
    <Sidepanel
      visible={visible}
      title={text.TITLE}
      size="medium"
      hideFooter
      className="fiq-select-block-sidepanel"
      onCloseIconClick={() => {
        closeSelectBlockSidepanel();
        onClose();
      }}
    >
      <div className={classnames('fiq-select-node')}>
        {Object.keys(filteredBlocks).map(section => (
          <Fragment key={section}>
            {filteredBlocks[section].map(node => (
              <div
                className="fiq-select-node__block"
                key={node.name}
                onClick={() => onBlockSelected(node)}
              >
                <img
                  src={actionIDIconMap[node?.actionID] || blockTypeIconMap[node.type]}
                  alt="email-block"
                />
                <Space size={20} />
                <span>{node.name}</span>
                <Space size={8} />
                <Tooltip
                  title={
                    (node?.actionID && blockActionDescriptionMap[node.actionID])
                    || blockDescriptionMap[node.type]
                  }
                  placement="bottom"
                >
                  <Icon colorName="text-neutral-500" size="regular" name="Info" />
                </Tooltip>
              </div>
            ))}
          </Fragment>
        ))}
      </div>
    </Sidepanel>
  );
};

SelectBlock.propTypes = propTypes;
SelectBlock.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  insertExitNode: stores.overlayStore.overlay.parameters?.insertExitNode,
}))(observer(SelectBlock));
