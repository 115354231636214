/* eslint-disable prefer-template */
/* eslint-disable import/prefer-default-export */

import { intersection, orderBy } from 'lodash';
import moment from 'moment';

import {
  BLOCK_TYPE,
  BLOCK_ACTION,
  blockTypeIconMap,
  actionIDIconMap,
  blockActions,
} from './consts';

const expandIcon = (x, y, r) => [
  ['M', x - r, y],
  ['a', r, r, 0, 1, 0, r * 2, 0],
  ['a', r, r, 0, 1, 0, -r * 2, 0],
  ['M', x - r + 8, y],
  ['L', x - r + 2 * r - 8, y],
  ['M', x - r + r, y - r + 8],
  ['L', x, y + r - 8],
];

export const colors = {
  EDGE: {
    STROKE: '#D1D5DB',
    // FILL: 'rgba(255, 255, 255, 0)',
    TEXT: '#000',
    TEXT_FILL: '#FFFFFF',
    SELECTED: {
      STROKE: '#1c91ff',
    },
  },
  NODE: {
    SELECTED: {
      STROKE: '#1c91ff',
    },
  },
  ICONS: {
    PLUS: {
      FILL: '#FFFFFF',
      BACKGROUND: '#516DFF',
    },
  },
};

export const drawPlusIcon = (group, x, y) => {
  group.addShape('circle', {
    lineWidth: 1,
    attrs: {
      x,
      y,
      r: 13,
      fill: colors.ICONS.PLUS.BACKGROUND,
      lineWidth: 1,
      cursor: 'pointer',
      opacity: 0.7,
    },
    name: 'plus-shape-background',
  });

  group.addShape('marker', {
    attrs: {
      x,
      y,
      r: 13,
      stroke: colors.ICONS.PLUS.FILL,
      lineWidth: 2,
      symbol: expandIcon,
      cursor: 'pointer',
    },
    name: 'plus-shape',
  });
};

const buildBlockDescription = (type, properties) => {
  const numberOfRules = properties?.condition?.rules?.length;
  switch (type) {
    case BLOCK_TYPE.TIME_DELAY:
      return `Delay for ${moment.duration(properties.duration).humanize()}`;
    case BLOCK_TYPE.WAIT_UNTIL:
      return `Wait for ${moment.duration(properties.duration).humanize()}`;
    case BLOCK_TYPE.BRANCH:
      if (!numberOfRules) {
        return 'No conditions set';
      }
      return `${numberOfRules} conditions set`;
    case BLOCK_TYPE.ACTION:
      if (!properties.action_parameters) {
        return 'No parameters set';
      }
      return `${Object.keys(properties.action_parameters).length} parameters set`;
    default:
      return null;
  }
};

export const truncateText = (input, length = 20) => (input && input.length > length ? `${input.substring(0, length)}...` : input);

// eslint-disable-next-line
export const getBlockIcon = (type, properties) => actionIDIconMap[properties?.action_id] || blockTypeIconMap[type];

const normalizeData = (data, steps, isOverview) => {
  const numOfExits = data.blocks.reduce(
    (acc, block) => (block.type === BLOCK_TYPE.EXIT ? acc + 1 : acc),
    0,
  );
  const blockNumOfParentsMapping = data.edges.reduce(
    (acc, { child }) => ({ ...acc, [child]: (acc[child] || 0) + 1 }),
    {},
  );
  const resolveActions = (blockID, type) => {
    if (type === BLOCK_TYPE.EXIT && numOfExits > 1 && blockNumOfParentsMapping[blockID] === 1) {
      return [BLOCK_ACTION.REMOVE];
    }

    if ([BLOCK_TYPE.EXIT, BLOCK_TYPE.TRIGGER].includes(type)) {
      return [];
    }

    return blockActions;
  };

  const getNumOfPeople = (blockId, blockType) => {
    let parentId = null;
    if (blockType === BLOCK_TYPE.BRANCH) {
      parentId = data.edges.find(item => item.child === blockId)?.parent;
      if (!parentId) {
        return 'N/A';
      }
    }
    const numberOfPeople = steps.find(item => item.block_id === (parentId || blockId))?.count || 0;
    return `${numberOfPeople} People`;
  };

  const nodes = data.blocks.map(block => ({
    id: block.id + '',
    text: block.name,
    blockType: block.type,
    description: steps
      ? getNumOfPeople(block.id, block.type)
      : buildBlockDescription(block.type, block.properties),
    payload: block,
    actions: resolveActions(block.id, block.type),
    isOverview,
  }));

  const edges = data.edges.map(edge => ({
    source: edge.parent + '',
    target: edge.child + '',
    payload: edge,
  }));

  return {
    nodes,
    edges,
  };
};

const insertTriggerNode = (data) => {
  const rootNode = getRootNode(data);
  const targetNode = {
    id: 'trigger',
    text: null,
    blockType: BLOCK_TYPE.TRIGGER,
    type: 'workflowTriggerNode',
    payload: {},
    actions: [],
  };

  const targetEdge = {
    source: targetNode.id,
    target: rootNode.id,
  };

  const nodes = [...data.nodes, targetNode];

  const edges = [...data.edges, targetEdge];

  return {
    nodes,
    edges,
  };
};

// Find nodes that doesn't have parent
const getRootNode = ({ edges, nodes }) => {
  const hashedTargets = edges.reduce((acc, { target }) => ({ ...acc, [target]: true }), {});
  return nodes.find(({ id }) => !hashedTargets[id]);
};

export const prepareData = (rawData, journeyStats, isOverview) => {
  const normalizedData = normalizeData(rawData, journeyStats, isOverview);
  const data = insertTriggerNode(normalizedData);
  // Create hashed edges and nodes.
  const edges = {};
  const nodes = data.nodes.reduce(
    (acc, node) => ({
      ...acc,
      [node.id]: node,
    }),
    {},
  );

  // Insert direct temp nodes
  const insertTempNode = (sourceEdge) => {
    const { source, target, payload = {} } = sourceEdge;
    const suffix = payload.index !== undefined ? payload.index + '' : 'direct';
    const nodeId = `${source}_${target}_${suffix}`;

    if (!isOverview) {
      nodes[nodeId] = {
        id: nodeId,
        type: 'workflowAddNode',
        size: 20,
        temp: true,
      };

      [
        {
          source,
          target: nodeId,
          payload,
          style: {
            endArrow: false,
          },
        },
        {
          source: nodeId,
          target,
        },
      ].forEach((edge) => {
        edges[`${edge.source}_${edge.target}`] = edge;
      });
    } else {
      edges[`${source}_${target}`] = sourceEdge;
    }
  };

  const insertBranchNode = ([yesEdge, noEdge], child, branchNodeId) => {
    const nodeId = `${yesEdge.source}_${noEdge.source}`;
    nodes[nodeId] = {
      id: nodeId,
      type: 'workflowAddNode',
      size: 20,
      temp: true,
      payload: {
        branchNode: branchNodeId,
      },
    };

    [yesEdge, noEdge].forEach((edge) => {
      delete edges[`${edge.source}_${edge.target}`];
    });

    [
      {
        ...noEdge,
        target: nodeId,
        style: {
          endArrow: false,
        },
      },
      {
        ...yesEdge,
        target: nodeId,
        style: {
          endArrow: false,
        },
      },
      {
        source: nodeId,
        target: child,
      },
    ].forEach((edge) => {
      edges[`${edge.source}_${edge.target}`] = edge;
    });
  };

  // Create direct temp nodes
  data.edges.forEach(insertTempNode);

  let directNodeEdges = Object.keys(edges).map(key => edges[key]);

  const findChildren = (id) => {
    const yesNodeCounter = [];
    const noNodeCounter = [];

    // eslint-disable-next-line
    const findBranchEdge = type => directNodeEdges.find(edge => edge.source === id && edge?.payload?.index === type);

    const yesEdge = findBranchEdge(1);
    const noEdge = findBranchEdge(0);

    const counter = (sourceId, nodeCounter) => {
      const children = directNodeEdges.filter(edge => sourceId === edge.source);
      children.forEach(({ target }) => {
        // eslint-disable-next-line no-param-reassign
        nodeCounter.push(target);
        counter(target, nodeCounter);
      });
    };

    const mergeArrays = (arrA, arrB) => {
      const hash = {};
      [...arrA, ...arrB].forEach((item) => {
        hash[item] = true;
      });

      return Object.keys(hash);
    };

    counter(yesEdge.target, yesNodeCounter);
    counter(noEdge.target, noNodeCounter);

    const [destinationNodeId] = intersection(yesNodeCounter, noNodeCounter);
    const yesIndex = yesNodeCounter.indexOf(destinationNodeId);
    const noIndex = noNodeCounter.indexOf(destinationNodeId);
    const yesChildren = [yesEdge.target, ...yesNodeCounter.slice(0, yesIndex + 1)];
    const noChildren = [noEdge.target, ...noNodeCounter.slice(0, noIndex + 1)];
    const children = mergeArrays(yesChildren, noChildren);

    return {
      children,
      destinationNodeId,
      yesEdge,
      noEdge,
    };
  };

  // Find Branches
  const branches = directNodeEdges.reduce((acc, edge) => {
    if (edge?.payload?.type === 'branch') {
      return { ...acc, [edge.source]: true };
    }

    return acc;
  }, {});

  // Sort branches
  const sortedBranches = Object.keys(branches)
    .map((key) => {
      const branchInfo = findChildren(key);
      return {
        ...branchInfo,
        count: branchInfo.children.length,
        key,
      };
    })
    .sort((a, b) => (a.count > b.count ? 1 : -1));

  if (!isOverview) {
    sortedBranches.forEach(({ key }) => {
      const { children, destinationNodeId } = findChildren(key);
      const branchEdges = directNodeEdges.filter(
        ({ target, source }) => target === destinationNodeId && children.includes(source),
      );

      if (destinationNodeId) {
        insertBranchNode(branchEdges, destinationNodeId, key);
      }
      directNodeEdges = Object.keys(edges).map(edgeKey => edges[edgeKey]);
    });
  }

  const sortedEdges = orderBy(
    directNodeEdges,
    [o => o.source, o => o.payload?.index],
    ['desc', 'desc'],
  );

  return {
    nodes: Object.keys(nodes).map(key => nodes[key]),
    edges: sortedEdges,
  };
};
