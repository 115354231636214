import ApproveTo from './ApproveTo';
import actionTypes from '../types';

const text = {
  APPROVE: 'Approve contact',
  REJECT: 'Reject contact',
};

const stateConfig = {
  COMPLETE: {
    name: 'ApproveContact',
    label: text.APPROVE,
    icon: 'Approve',
  },
  REJECTED: {
    name: 'RejectContact',
    label: text.REJECT,
    icon: 'Reject',
  },
};

const approveActions = [
  {
    statuses: ['Complete', 'Rejected'],
    ...stateConfig.COMPLETE,
    type: actionTypes.DROPDOWN,
    visible: true,
    handler: (sidepanelManager) => {
      const sidepanelOptions = {
        icon: stateConfig.COMPLETE.icon,
        type: 'success',
        title: text.APPROVE,
        sidepanelManager,
        visible: true,
        actionType: 'approve',
      };

      sidepanelManager.add(ApproveTo, sidepanelOptions);
    },
  },
  {
    statuses: ['Complete'],
    ...stateConfig.REJECTED,
    type: actionTypes.DROPDOWN,
    visible: true,
    handler: (sidepanelManager) => {
      const sidepanelOptions = {
        icon: stateConfig.REJECTED.icon,
        type: 'error',
        title: text.REJECT,
        sidepanelManager,
        visible: true,
        actionType: 'reject',
      };

      sidepanelManager.add(ApproveTo, sidepanelOptions);
    },
  },
];

export default status => approveActions.filter(action => action.statuses.includes(status));
