import React from 'react';
import { ActivityDateGroup } from 'tc-biq-design-system';
import { string, any } from 'prop-types';

import './DateGroup.scss';

const propTypes = {
  groupTitle: string.isRequired,
  children: any.isRequired,
};

const DateGroup = ({ groupTitle, children }) => (
  <div className="fiq-notifications-date-group">
    <ActivityDateGroup text={groupTitle} />
    {children}
  </div>
);

DateGroup.propTypes = propTypes;

export default DateGroup;
