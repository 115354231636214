import SendSms from './SendSms';
import actionTypes from '../types';

const text = {
  LABEL: 'Send message',
};

const ACTION_ICON = 'SingleChat';

export default {
  name: 'sendSms',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.TOP,
  handler: (comboxManager) => {
    const comboxOptions = {
      title: text.LABEL,
      id: 'send-sms-combox',
    };

    comboxManager.add(SendSms, comboxOptions);
  },
};
