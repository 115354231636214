import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Row, Space } from 'tc-biq-design-system';

import DisplayDetail from 'App/components/DisplayDetail';

const formatDate = date => moment(date, moment.ISO_8601).format('MMMM Do YYYY, \\at HH:mm');

const OrderDetails = inject(stores => ({
  created: stores.transactions.orderData.created,
  side: stores.transactions.orderData.side,
  externalId: stores.transactions.orderData.external_id,
  amount: stores.transactions.orderData.amount,
  symbol: stores.transactions.orderData.symbol,
  account: stores.transactions.orderData.wallet.account.provider,
}))(
  observer(({ created, amount, symbol, side, externalId, account }) => (
    <Fragment>
      <Row gutter={0}>
        <div style={{ display: 'flex', width: '100%' }}>
          <DisplayDetail header="External ID" value={externalId} />
          <DisplayDetail header="Symbol" value={symbol} />
          <DisplayDetail header="Amount" value={amount} />
          <DisplayDetail header="Side" value={side} />
          <DisplayDetail header="Time" value={formatDate(created)} />
        </div>
      </Row>
      <Space size={8} />
      <Row gutter={0}>
        <div style={{ display: 'flex', width: '100%' }}>
          <DisplayDetail header="Account" value={account} />
        </div>
      </Row>
    </Fragment>
  )),
);

export default OrderDetails;
