
import React, { Fragment } from 'react';
import { Table, Icon, Space } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { array, func } from 'prop-types';

import { hasAccess } from 'App/services/permissionsService';
import { openPreviewMessage } from 'Marketing/components/overlays/PreviewMessage';

const propTypes = {
  onEditContent: func.isRequired,
  onDelete: func.isRequired,
  setDefaultContent: func.isRequired,
  allLanguages: array,
  contentList: array,
};

const defaultProps = {
  allLanguages: [],
  contentList: [],
};

// eslint-disable-next-line react/prop-types
const renderActions = ({ onEdit, onDelete, onPreview }) => {
  if (!hasAccess('custom_properties_customproperty', 'update')) return null;
  return (
    <div style={{ display: 'flex', marginRight: '32px' }}>
      <div onClick={onPreview}>
        <Icon size="small" name="View" />
      </div>
      <Space size={16} />
      <div onClick={onEdit}>
        <Icon size="small" name="Edit" />
      </div>
      <Space size={16} />
      <div onClick={onDelete}>
        <Icon size="small" name="Delete" />
      </div>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const renderItem = ({ fullLang, onEdit, onDelete, onPreview }) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ minWidth: '200px' }}>{fullLang}</span>
    </div>
    {renderActions({ onEdit, onDelete, onPreview })}
  </div>
);

const columns = [
  {
    id: 0,
    title: 'Language',
    key: 'language',
    render: data => renderItem(data),
  },
];

const formatedItems = (items, onEdit, onDelete, allLanguages = []) => items.map((item, index) => ({
  ...item,
  id: index,
  fullLang: `${allLanguages.find(lang => lang.value === item.language).display_name}${item.is_default ? ' (default)' : ''}`,
  onEdit: (e) => { onEdit(item); e.stopPropagation(); },
  onDelete: (e) => { onDelete(item); e.stopPropagation(); },
  onPreview: (e) => { openPreviewMessage(item.content); e.stopPropagation(); },
}));

const LocalizedTemplatesTable = ({
  setDefaultContent,
  onEditContent,
  onDelete,
  contentList,
  allLanguages,
}) => (
  <Fragment>
    <Table
      striped
      data={formatedItems(contentList, onEditContent, onDelete, allLanguages)}
      cols={columns}
      onRowClick={setDefaultContent}
    />
    <Space size={16} />
  </Fragment>
);

LocalizedTemplatesTable.propTypes = propTypes;
LocalizedTemplatesTable.defaultProps = defaultProps;

export default inject(stores => ({
  allLanguages: stores.templates.fieldsDef?.content?.child?.children?.language?.choices,
}))(observer(LocalizedTemplatesTable));
