const errors = {
  'Wrong rule parameters in: {}.': 'You have empty fields',
  "Wrong rule parameters in: {'is_attribute': True}.": 'You have empty fields',
  '`no_times` must be set for group with an event type.': 'You have empty fields',
  'None is not a rule operator.': 'You have empty fields',
  "SegmentGroup(condition='AND', nodes=[], event_type_id=None, no_times=None, last_n_days=None) without event type can not have empty rules.":
    'Please add condition',
  'date is not a rule operator.': 'You have empty fields',
  "SegmentGroup(condition='OR', nodes=[], event_type_id=None, no_times=None, last_n_days=None) without event type can not have empty rules.":
    'Please add condition',
  'Value cannot be empty for exact operator.': 'Value cannot be empty for exact operator.',
  'Value `a string` in a list cannot be converted to the integer type': 'Value `a string` in a list cannot be converted to the integer type',
  "Value can't be an empty list for in operator.": "Value can't be an empty list for in operator.",
  'Value in a list cannot be empty for in operator.': 'Value in a list cannot be empty for in operator.',
  "`rules` are empty in: Group(condition='AND', nodes=[]).": "Query isn't defined.",
  'Email channel must have either ``email_message`` or ``template`` defined.': 'Message template is required',
  "`rules` are empty in: Group(condition='OR', nodes=[]).": "Query isn't defined.",
  default: 'An error has occurred, please contact support.',
};

const getErrorMessage = (err) => {
  if (errors[err]) return errors[err];
  return err;
};

export default getErrorMessage;
