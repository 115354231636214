import http from 'App/services/http';
import store from 'App/rootStore';

export default function (apiUrl, tableKey) {
  const gridStore = store.tables[tableKey];
  const fetchTableOptions = () => http.options(`${apiUrl}`);

  const fetchTableData = async () => {
    const response = await http.get(`${apiUrl}`, { params: gridStore.query });
    const { next, previous, count } = response.data;
    gridStore.setCursors(next, previous);
    gridStore.setData(response.data.results);
    if (gridStore.withCount) gridStore.setWithCount(null);
    if (Number.isInteger(count)) gridStore.setCount(count);
  };

  const submitColumnsState = payload => http.post('/users/managed-columns/', { path: `/api${apiUrl}`, fields: payload });

  return {
    fetchTableData,
    fetchTableOptions,
    submitColumnsState,
  };
}
