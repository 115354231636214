/* eslint-disable import/prefer-default-export */

const capitalize = s => s.charAt(0).toUpperCase() + s.substring(1);

export const formatKey = (key) => {
  if (!key) return '';

  const label = key
    .split('_')
    .map(s => capitalize(s))
    .join(' ');

  return label;
};
