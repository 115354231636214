import React from 'react';
import { number, oneOfType, string } from 'prop-types';
import { Tab, Tabs, Space } from 'tc-biq-design-system';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import PositionsTable from './PositionsTable';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
};

const Positions = ({ id }) => (
  <Tabs>
    <Tab title="Opened Positions">
      <Space size={12} />
      <PositionsTable
        contactId={id}
        tableKey="contactOpenedPositions"
        stateQuery="Opened"
        ordering="-open_date"
      />
    </Tab>

    <Tab title="Closed Positions">
      <Space size={12} />
      <PositionsTable
        contactId={id}
        tableKey="contactClosedPositions"
        stateQuery="Closed"
        ordering="-close_date"
      />
    </Tab>
  </Tabs>
);

Positions.propTypes = propTypes;
export default withErrorBoundary(Positions);
