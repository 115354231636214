import React from 'react';
import { CheckboxCell } from 'App/components/gridCellRenderers';
import { Pill } from 'tc-biq-design-system';
import hideCols from 'App/services/utilities/hideCols';

const modifier = ({ toggle }) => ({
  ...hideCols(['id']),
  group: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => <Pill type="primary">{value}</Pill>,
  },
  in_app: {
    cellRendererFramework: CheckboxCell(toggle, true),
  },
});

export default modifier;
