import React, { PureComponent, Fragment } from 'react';
import { Pill } from 'tc-biq-design-system';
import { array, string, func, bool, any } from 'prop-types';

import ConditionGroupHeader from './components/ConditionGroupHeader';

import './ConditionGroup.scss';

const propTypes = {
  rules: array.isRequired,
  condition: string.isRequired,
  addCondition: func.isRequired,
  removeCondition: func.isRequired,
  updateCondition: func.isRequired,
  initial: bool,
  initialPlaceholder: string,
  parentIdentifier: array,
  lastSibiling: bool,
  isEventBased: bool,
  defaultConditionValue: string,
  liteConditionHeader: bool,
};

const defaultProps = {
  initial: false,
  initialPlaceholder: null,
  parentIdentifier: [],
  lastSibiling: false,
  isEventBased: false,
  defaultConditionValue: null,
  liteConditionHeader: false,
};

const text = {
  AND: 'All conditions',
  OR: 'At least one condition',
  PLACEHOLDER: 'Click Add condition button to start creating your query',
};

// eslint-disable-next-line react/prop-types
const ConditionLine = ({ condition }) => (
  <div className="fiq-condition-group__condition-line">
    <div className="fiq-condition-group__line-background">
      {condition && (
        <Pill style={{ marginRight: '0px' }} type="neutral">
          {condition}
        </Pill>
      )}
    </div>
  </div>
);

// eslint-disable-next-line max-len
const isGroupCondition = (isEventBased, rule) => (isEventBased ? rule.condition && !rule.event_type_id : rule.condition);

const ConditionGroupBody = ({
  rules,
  condition,
  parentIdentifier,
  initial,
  initialPlaceholder,
  lastSibiling,
  children,
  isEventBased,
  ...props
}) => (
  <div className="fiq-condition-group__body">
    <div className="fiq-condition-group__offset">
      {!initial && rules.length !== 0 && !lastSibiling && <ConditionLine />}
    </div>
    <div className="fiq-condition-group__body-content">
      {rules.length === 0 && (
        <div className="fiq-condition-group__placeholder">
          {initialPlaceholder || text.PLACEHOLDER}
        </div>
      )}
      {rules.map((rule, index) => {
        const identifier = parentIdentifier ? [...parentIdentifier, index] : [];
        const groupCondition = isGroupCondition(isEventBased, rule);
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`${parentIdentifier.toString()}-${index}`}>
            <ConditionLine condition={condition} />
            {groupCondition ? (
              <ConditionGroup
                {...props}
                rules={rule.rules}
                condition={rule.condition}
                parentIdentifier={identifier}
                lastSibiling={rules.length === index + 1}
                isEventBased={isEventBased}
              >
                {children}
              </ConditionGroup>
            ) : (
              children(rule, identifier, rules.length === index + 1)
            )}
          </Fragment>
        );
      })}
    </div>
  </div>
);

ConditionGroupBody.propTypes = {
  rules: array.isRequired,
  parentIdentifier: array.isRequired,
  condition: string.isRequired,
  initial: bool.isRequired,
  initialPlaceholder: string,
  lastSibiling: bool.isRequired,
  children: any.isRequired,
  isEventBased: bool.isRequired,
};

ConditionGroupBody.defaultProps = {
  initialPlaceholder: null,
};

class ConditionGroup extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      identifier: props.parentIdentifier,
    };
  }

  updateCondition = (condition) => {
    const { updateCondition } = this.props;
    const { identifier } = this.state;
    updateCondition(condition, identifier);
  };

  removeCondition = () => {
    const { removeCondition, initial } = this.props;
    const { identifier } = this.state;
    removeCondition(identifier, initial);
  };

  addCondition = (data) => {
    const newConditionData = data && !data.target ? data : {};
    const { addCondition } = this.props;
    const { identifier } = this.state;
    addCondition(newConditionData, identifier);
  };

  addConditionGroup = () => {
    const newGroupData = { rules: [], condition: 'AND' };
    const { addCondition } = this.props;
    const { identifier } = this.state;
    addCondition(newGroupData, identifier);
  };

  render() {
    const {
      initial,
      initialPlaceholder,
      condition,
      rules,
      isEventBased,
      defaultConditionValue,
      liteConditionHeader,
    } = this.props;
    const { identifier } = this.state;
    return (
      <Fragment>
        <ConditionGroupHeader
          updateCondition={this.updateCondition}
          condition={condition}
          addCondition={this.addCondition}
          addConditionGroup={this.addConditionGroup}
          removeCondition={this.removeCondition}
          initial={initial}
          noRules={!rules.length}
          isEventBased={isEventBased}
          defaultConditionValue={defaultConditionValue}
          lite={liteConditionHeader}
        />
        <ConditionGroupBody
          {...this.props}
          initial={initial}
          initialPlaceholder={initialPlaceholder}
          rules={rules}
          condition={condition}
          parentIdentifier={identifier}
          isEventBased={isEventBased}
        />
      </Fragment>
    );
  }
}

ConditionGroup.propTypes = propTypes;
ConditionGroup.defaultProps = defaultProps;

export default ConditionGroup;
