import React from 'react';

import Page from 'App/components/Page';
import PaymentsTable, { tableConfig } from 'Transactions/components/PaymentsTable';

const bread = [{ label: 'Transactions' }, { label: 'Payments', route: '/transactions/payments' }];
const exportDataOptions = { permission: 'finances_paymenttransaction', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

const PaymentsPage = () => (
  <Page bread={bread} title="Payments" style={{ margin: '0 20px' }} exportDataOptions={exportDataOptions}>
    <PaymentsTable />
  </Page>
);

export default PaymentsPage;
