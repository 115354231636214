import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { object, func, bool } from 'prop-types';
import { find, isEmpty } from 'lodash';
import { Row, Col } from 'tc-biq-design-system';

import Navigation from 'App/components/Navigation';
import { hasAccess } from 'App/services/permissionsService';
import If from 'App/components/If';
import LoadingPage from 'App/components/LoadingPage';
import Page from 'App/components/Page';
import sections from 'Settings/Sections/Users/components/UserSections';

import './UserPage.scss';

const propTypes = {
  fetchUser: func.isRequired,
  loadingUser: bool.isRequired,
  loggedUser: object.isRequired,
  fetchUserFields: func.isRequired,
  user: object.isRequired,
};

const text = {
  MY_PROFILE: 'My Profile',
  USER: 'User',
};

const UserPage = inject(stores => ({
  fetchUser: stores.usersStore.fetchUser,
  loadingUser: stores.usersStore.requestInProgress.user,
  fetchUserFields: stores.usersStore.fetchUserFields,
  loggedUser: stores.loginStore.user,
  user: stores.usersStore.user,
}))(observer(
  class UserPage extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        display: 'my-details',
      };
    }

    componentDidMount() {
      this.fetchUser();
      this.setInitialSection();
    }

    componentDidUpdate(prevProps) {
      const { match } = this.props;
      if (prevProps.match.params.userId !== match.params.userId) {
        this.fetchUser(match.params.userId);
      }
      if (match.params.sectionId && prevProps.match.params.sectionId !== match.params.sectionId) {
        this.onSectionChange(match.params.sectionId);
      }
    }

    onSectionChange = (section) => {
      const { history, match } = this.props;
      const { userId } = match.params;
      history.push(`/settings/users/${userId}/${section}`);
      this.setState({ display: section });
    };

    setInitialSection = () => {
      const { match } = this.props;
      const { sectionId } = match.params;
      if (sectionId) this.onSectionChange(sectionId);
    };

    fetchUser = () => {
      const { match, fetchUser, fetchUserFields } = this.props;
      const { userId } = match.params;
      fetchUser(userId, true);
      fetchUserFields();
    };

    getSectionComponent = () => {
      const { display } = this.state;
      if (isEmpty(this.sections) || !display) {
        return null;
      }
      const section = find(this.sections, { id: display });
      return section ? section.Component() : null;
    };

    render() {
      const { loadingUser, match, loggedUser, user } = this.props;
      if (loadingUser) return <LoadingPage />;
      const { display } = this.state;

      const userId = Number(match.params.userId);
      const loggedUserId = Number(loggedUser.id);
      this.sections = sections.filter(
        section => hasAccess(section.permission, 'read')
          && section.visibility({
            userId,
            loggedUserId,
          }),
      );

      return (
        <Page
          title={
            userId === loggedUserId
              ? text.MY_PROFILE
              : `${text.USER} ${user.first_name} ${user.last_name}`
          }
          style={{ padding: '26px 30px' }}
        >
          <If condition={!loadingUser && this.sections.length > 0}>
            <div className="fiq-user-profile">
              <Row gutter={0} style={{ width: '100%' }}>
                <Col sm="30%" md="20%">
                  <Navigation
                    sections={this.sections}
                    onSectionChange={this.onSectionChange}
                    active={display}
                  />
                </Col>

                <Col sm="70%" md="80%">
                  <div className="fiq-user-profile__section">{this.getSectionComponent()}</div>
                </Col>
              </Row>
            </div>
          </If>
        </Page>
      );
    }
  },
));

UserPage.wrappedComponent.propTypes = propTypes;

export default withRouter(UserPage);
