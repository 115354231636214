import React from 'react';
import { object } from 'prop-types';
import { HyperLink, MultiActions, Pill, Tooltip, Icon } from 'tc-biq-design-system';
import { Link, withRouter } from 'react-router-dom';
import hideCols from 'App/services/utilities/hideCols';
import UserCell from 'App/components/gridCellRenderers/UserCell';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import stores from 'App/rootStore';
import NoDataDash from 'App/components/NoDataDash';
import RenderIcon from 'App/components/RenderIcon';
import { authorizeAdNetwork } from 'Settings/Sections/Integrations/services/utils';
import { StatusCell, DateTimeCell } from 'App/components/gridCellRenderers';

const text = {
  NAME_LABEL: 'Segments',
  CONTACT_COUNT: 'The number may be inaccurate for more than 10k Contacts',
};

const contactsTableKey = 'contacts';
const audienceTokenExpiredSyncStatus = 'Access Token Expired';

const syncStatusPillTypeMap = {
  Queued: 'queued',
  Syncing: 'syncing',
  Synced: 'synced',
  'Sync Failed': 'failed',
  'Access Token Expired': 'failed',
};

const audienceTypeIconNameMap = {
  'Facebook Ads': 'Facebook',
  'Google Ads': 'GoogleAds',
};

const NameRenderer = ({ data }) => (
  <div className="name-renderer">
    <div>
      <HyperLink>
        <Link to={`/automation/segments/preview/${data.id}`}>{data.name}</Link>
      </HyperLink>
    </div>
    <div>{data.description}</div>
  </div>
);

NameRenderer.propTypes = {
  data: object.isRequired,
};

const ActionRenderer = (data, actions) => {
  // eslint-disable-next-line react/destructuring-assignment
  const hasActions = !!actions.length;
  // eslint-disable-next-line react/destructuring-assignment
  const gridActions = actions.map((a) => {
    // Provide data to sub-actions
    if (a.header) {
      return {
        ...a,
        subActions: [
          ...a.subActions.map(item => ({
            ...item,
            action: () => item.action(data),
          })),
        ],
      };
    }
    return {
      ...a,
      action: () => a.action(data),
    };
  });
  return hasActions ? <MultiActions actions={gridActions} /> : null;
};

const ContactCountRenderer = withRouter(({ data, history }) => {
  const onClick = () => {
    stores.tables[contactsTableKey].filters.addSegmentFilter({ value: data });
    history.push('/contacts/');
  };
  return (
    <div style={{ display: 'flex' }}>
      <Pill
        style={{ width: '65px', display: 'flex', justifyContent: 'space-between' }}
        icon="Client"
        color="neutral-400"
        onClick={onClick}
        onIconClick={onClick}
      >
        {data.contacts}
      </Pill>
      {data.contacts > 10000 && (
        <Tooltip title={text.CONTACT_COUNT}>
          <Icon colorName="text-neutral-500" style={{ marginTop: '4px' }} name="Warning" />
        </Tooltip>
      )}
    </div>
  );
});

// eslint-disable-next-line
const AudiencesRenderer = ({ value }) => value.length ? (
  <div className="segments-table__audiences">
    {value.map((audience) => {
      const isTokenExpired = audience.sync_status === audienceTokenExpiredSyncStatus;
      return (
        <div
          key={audience.id}
          className="segments-table__audience"
          onClick={isTokenExpired ? () => authorizeAdNetwork(audience.ad_network) : null}
          style={isTokenExpired ? { cursor: 'pointer' } : {}}
        >
          <Tooltip
            title={
                isTokenExpired
                  ? `${audience.sync_status} – Click to Re-Authorize`
                  : audience.sync_status
              }
          >
            <RenderIcon icon={audienceTypeIconNameMap[audience.ad_network]} iconSize={20} />
            <div className={`status ${syncStatusPillTypeMap[audience.sync_status]}`} />
          </Tooltip>
        </div>
      );
    })}
  </div>
) : (
  <NoDataDash />
);

const RenderUserCell = ({ data, dataKey, timeKey }) => {
  if (!data[dataKey]) return null;
  return UserCell({
    ...data[dataKey],
    fullName: extractDisplayName(data[dataKey]),
    subTitle: <DateTimeCell value={data[timeKey]} />,
  });
};

ContactCountRenderer.propTypes = {
  data: object.isRequired,
};

export default actions => ({
  ...hideCols(['id', 'description', 'created', 'updated', 'created_by']),
  name: {
    headerName: text.NAME_LABEL,
    cellRendererFramework: NameRenderer,
  },
  updated_by: {
    cellRendererFramework: ({ data }) => RenderUserCell({ data, dataKey: 'updated_by', timeKey: 'updated' }),
  },
  created_by: {
    cellRendererFramework: ({ data }) => RenderUserCell({ data, dataKey: 'created_by', timeKey: 'created' }),
  },
  contacts: {
    cellRendererFramework: ContactCountRenderer,
  },
  audiences: {
    cellRendererFramework: AudiencesRenderer,
  },
  status: {
    cellRendererFramework: StatusCell,
  },
  actions: {
    pinned: 'right',
    enableFiltering: false,
    hide: false,
    visible: true,
    width: 15,
    cellStyle: {
      display: 'flex',
      justifyContent: 'center',
    },
    headerName: '',
    sortable: false,
    cellRendererFramework: ({ data }) => ActionRenderer(data, actions(data)),
  },
});
