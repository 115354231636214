import React from 'react';
import { string } from 'prop-types';

const propTypes = {
  value: string.isRequired,
};

const AmountCell = ({ value }) => (
  <div className="amount-cell">{value || '-'}</div>
);

AmountCell.propTypes = propTypes;
export default AmountCell;
