import React, { PureComponent, Fragment } from 'react';
import GridComponentFactory from 'App/components/grid';
import { notifier } from 'tc-biq-design-system';

import { toggleActiveState } from 'Settings/Sections/Documents/services/DocumentsService';
import AddEditDocumentType from 'Settings/Sections/Documents/components/sidepanels/AddEditDocumentType';
import { openOverlay } from 'App/services/overlayService';
import { hasAccess } from 'App/services/permissionsService';
import modifiers from './modifiers';

const customColumns = [
  {
    key: 'actions',
    headerName: '',
  },
];

export const tableConfig = {
  tableUrl: '/documents/types/',
  tableKey: 'documentTypes',
};

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const singleActions = ({ onEdit }) => [
  {
    icon: { name: 'Edit', color: 'text-neutral-900', size: 'small' },
    onClick: onEdit,
    label: 'Edit',
    visibility: hasAccess('documents_documenttype', 'update'),
  },
].filter(action => action.visibility);

class DocumentTypesTable extends PureComponent {
  toggleActive = async (data, value) => {
    try {
      await toggleActiveState(data.id, value);
      gridActions.fetchTableData();
      notifier.success('Successfully changed Document type status');
    } catch (err) {
      notifier.error('Failed to change Document type status');
    }
  };

  editDocumentType = ({ id }) => {
    openOverlay('ADD_EDIT_DOCUMENT_TYPE', { id, type: 'edit' });
  };

  render() {
    return (
      <Fragment>
        <GridComponent
          modifiers={modifiers({
            toggle: this.toggleActive,
            actions: singleActions({ onEdit: this.editDocumentType }),
          })}
          customColumns={customColumns}
        />
        <AddEditDocumentType onSuccess={gridActions.fetchTableData} />
      </Fragment>
    );
  }
}

export default DocumentTypesTable;
