import { action, observable, runInAction, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import { isEmpty } from 'lodash';

import stores from 'App/rootStore';
import {
  fetchIntegrations,
  setIntegrationConfig,
  initiateOAuthLogin,
} from 'Settings/Sections/Integrations/services/IntegrationsService';

export default class IntegrationsStore {
  integrationsData = [];

  requestInProgress = {
    editIntegration: false,
  };

  constructor() {
    makeObservable(this, {
      integrationsData: observable,
      requestInProgress: observable,
      fetchIntegrationsData: action.bound,
      editIntegrationConfig: action.bound,
      initiateOAuthLogin: action.bound,
    });
  }

  async fetchIntegrationsData() {
    const response = await fetchIntegrations();
    runInAction(() => {
      this.integrationsData = response.data;
    });
  }

  async editIntegrationConfig(initialData, hasOAuth) {
    this.requestInProgress.editIntegration = true;
    try {
      const { data } = stores.forms.editIntegrationConfig;
      const keys = Object.keys(data);
      // Check if all the fields are if we are doing the OAuth authorization.
      if (hasOAuth && keys.some(key => isEmpty(data[key]))) {
        notifier.error('All fields must be set in order to start the authorization!');
        this.requestInProgress.editIntegration = false;
        return false;
      }
      // eslint-disable-next-line
      for (const key of keys) if (initialData[key] !== data[key]) await setIntegrationConfig(key, data[key]);
      // Don't show the successfull notificaiton if we're doing OAuth, since we
      // have another request after this one.
      if (!hasOAuth) {
        runInAction(() => {
          this.fetchIntegrationsData();
          this.requestInProgress.editIntegration = false;
          notifier.success('Edited integration config successfully');
        });
      }
    } catch (err) {
      runInAction(() => {
        this.fetchIntegrationsData();
        this.requestInProgress.editIntegration = false;
        return err.data;
      });
      notifier.error('Failed to edit integration config');
      return false;
    }
    return true;
  }

  async initiateOAuthLogin(loginPath) {
    this.requestInProgress.editIntegration = true;
    // Initiate the OAuth login that will redirect the user to a different page.
    try {
      const response = await initiateOAuthLogin(loginPath.replace(APP_ROOT, ''));
      window.location.href = response.data.auth_url;
    } catch (err) {
      runInAction(() => {
        this.fetchIntegrationsData();
        this.requestInProgress.editIntegration = false;
      });
      notifier.error('Failed to start the authorization!');
    }
  }
}
