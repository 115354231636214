import http from 'App/services/http';

const toggleActiveState = (id, value) => http.patch(`/documents/types/${id}/`, { is_active: value });

const fetchDocumentTypeData = id => http.get(`/documents/types/${id}/`);

const fetchDocumentTypeFields = () => http.options('/documents/types/');

const addDocumentType = payload => http.post('/documents/types/', payload);

const editDocumentType = (id, payload) => http.patch(`/documents/types/${id}/`, payload);

export {
  toggleActiveState,
  fetchDocumentTypeData,
  fetchDocumentTypeFields,
  addDocumentType,
  editDocumentType,
};
