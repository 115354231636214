import React from 'react';
import { Space } from 'tc-biq-design-system';
import { string, oneOfType, func } from 'prop-types';

import './DisplayDetail.scss';

const propTypes = {
  header: string.isRequired,
  value: oneOfType([string, func]),
};

const defaultProps = {
  value: '',
};

const DisplayDetail = ({ header, value }) => (
  <div className="fiq-display-detail">
    <div className="tc-micro-regular text-neutral-500">{header}</div>
    <Space size={4} />
    <div data-recording-sensitive className="tc-paragraph-regular content">
      {typeof value === 'function' ? value() : value}
    </div>
  </div>
);

DisplayDetail.propTypes = propTypes;
DisplayDetail.defaultProps = defaultProps;

export default DisplayDetail;
