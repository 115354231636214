import React, { useEffect, useState, useRef } from 'react';
import { Sidepanel, Button } from 'tc-biq-design-system';
import { bool, object, func, array } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import { openOverlay, closeOverlay } from 'App/services/overlayService';
import SidepanelFooter from 'App/components/SidepanelFooter';
import InfiniteScroll from 'App/components/InfiniteScroll';
import CommentField from 'App/components/CommentField';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import { searchUsers } from 'Settings/Sections/Users/services/UsersService';
import Loader from 'App/components/Loader';
import { hasAccess } from 'App/services/permissionsService';
import ActivityCard from './ActivityCard';
import CommentCard from './CommentCard';

import './Comments.scss';

const propTypes = {
  visible: bool,
  parameters: object,
  fetchActivityComments: func.isRequired,
  addComment: func.isRequired,
  isLoading: bool.isRequired,
  hasMore: bool.isRequired,
  comments: array.isRequired,
  updateActivity: func.isRequired,
  resetCommentsCursor: func.isRequired,
};

const defaultProps = {
  visible: false,
  parameters: null,
};

const text = {
  TITLE: 'Comments',
  BUTTON_LABELS: { confirm: 'Comment' },
  LOAD_LATEST: 'Load latest comments',
  ADD_COMMENT: {
    SUCCESS: 'Successfully added comment',
    ERROR: 'Failed to add comment',
  },
};

const SIDEPANEL_ID = 'COMMENTS';

export const openComments = data => openOverlay(SIDEPANEL_ID, data);
export const closeComments = () => closeOverlay(SIDEPANEL_ID);

const customFooter = ({ execute, submitInProgress, permission }) => () => (permission ? (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    style={{ justifyContent: 'flex-end', width: '100%' }}
  />
) : null);

const LoadLatestComments = inject(stores => ({
  fetchPreviousComments: stores.activityStream.fetchPreviousComments,
  hasPrevious: stores.activityStream.commentsPagination.hasPrevious,
  loadingPrevious: stores.activityStream.requestInProgress.fetchPreviousComments,
}))(
  observer(({ hasPrevious, fetchPreviousComments, loadingPrevious, commentId }) => {
    if (!hasPrevious) return null;

    const fetchData = () => fetchPreviousComments({ commentId });
    return (
      <div className="fiq-activity-comments__load-previous">
        <Button
          loading={loadingPrevious}
          disabled={loadingPrevious}
          color="transparent"
          size="small"
          onClick={fetchData}
        >
          {text.LOAD_LATEST}
        </Button>
      </div>
    );
  }),
);

const INFINITE_SCROLL_STYLE = {
  maxHeight: 'calc(100vh - 550px)',
  minHeight: 'calc(100vh - 550px)',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
};

const Comments = ({
  visible,
  parameters,
  fetchActivityComments,
  addComment,
  isLoading,
  comments,
  hasMore,
  updateActivity,
  resetCommentsCursor,
}) => {
  const [content, setContentValue] = useState('');
  const inputRef = useRef();
  const [users, setUsers] = useState([]);
  const [touched, setTouchedState] = useState(false);
  const commentRef = useRef(null);
  const commentsWrapperRef = useRef(null);
  // eslint-disable-next-line max-len
  const scrollToComment = () => {
    commentsWrapperRef.current.scrollTo(0, commentRef.current.offsetTop);
  };

  const createCommentPermission = hasAccess('events_activitycomment', 'create');

  const fetchData = async (isOnScroll) => {
    const {
      activity: { id },
      commentId,
    } = parameters;
    await fetchActivityComments({ id, isOnScroll, commentId });
    if (commentId && comments.length === 0) setTimeout(scrollToComment, 200);
  };

  const fetchUsers = async (query, cb) => {
    try {
      const response = await searchUsers({ search: query, limit: 6 });
      const res = response.data.results;
      setUsers(res);
      cb(
        res.map(user => ({
          id: user.id,
          display: extractDisplayName(user),
        })),
      );
    } catch {
      cb([]);
    }
  };
  const fetchOnScroll = () => fetchData(true);

  const addComments = async () => {
    const {
      activity: { id },
    } = parameters;

    await addComment({ id, content, messages: text.ADD_COMMENT });
    setContentValue('');
    setTouchedState(true);
    updateActivity(id);
  };

  const updateContentValue = ({ target }) => {
    setContentValue(target.value);
  };

  const close = () => {
    const {
      activity: { id },
    } = parameters;
    if (touched) updateActivity(id);
    setTouchedState(false);
    resetCommentsCursor();
    closeComments();
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    if (parameters && parameters.activity) {
      fetchData();
    }
  }, [parameters]);

  if (!parameters || isEmpty(parameters)) return null;

  return (
    <Sidepanel
      title={text.TITLE}
      icon="SingleMessage"
      size="large"
      visible={visible}
      onCloseIconClick={close}
      footerRender={customFooter({
        execute: addComments,
        submitInProgress: false,
        permission: createCommentPermission,
      })}
    >
      {parameters && (
        <ActivityCard activity={parameters.activity} groupTitle={parameters.groupTitle} />
      )}
      <div className="fiq-activity-comments__content">
        {parameters.commentId && (
          <div style={INFINITE_SCROLL_STYLE} ref={commentsWrapperRef}>
            <LoadLatestComments commentId={parameters.commentId} />
            {comments.map(comment => (
              <CommentCard
                key={comment.id}
                refProp={+parameters.commentId === comment.id ? commentRef : null}
                comment={comment}
                highlight={+parameters.commentId === comment.id}
                groupTitle={parameters.groupTitle || ''}
                activityId={parameters.activity.id}
              />
            ))}
          </div>
        )}
        {!parameters.commentId && (
          <InfiniteScroll
            style={INFINITE_SCROLL_STYLE}
            fetchData={fetchOnScroll}
            hasMore={hasMore}
            isLoading={isLoading}
          >
            {comments.map(comment => (
              <CommentCard
                key={comment.id}
                comment={comment}
                highlight={+parameters.commentId === comment.id}
                groupTitle={parameters.groupTitle || ''}
                activityId={parameters.activity.id}
              />
            ))}
            <Loader visible={isLoading} />
          </InfiniteScroll>
        )}
      </div>
      {createCommentPermission && (
        <div className="fiq-activity-comments__comment-box">
          <CommentField
            onChange={updateContentValue}
            value={content}
            fetchData={fetchUsers}
            inputRef={inputRef}
            usersList={users}
          />
        </div>
      )}
    </Sidepanel>
  );
};

Comments.propTypes = propTypes;
Comments.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  fetchActivityComments: stores.activityStream.fetchActivityComments,
  comments: stores.activityStream.comments,
  isLoading: stores.activityStream.requestInProgress.fetchActivityComments,
  addComment: stores.activityStream.addComment,
  hasMore: stores.activityStream.commentsPagination.hasMore,
  hasPrevious: stores.activityStream.commentsPagination.hasPrevious,
  updateActivity: stores.activityStream.updateActivity,
  resetCommentsCursor: stores.activityStream.resetCommentsCursor,
}))(observer(Comments));
