import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { object, func } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Col, Space } from 'tc-biq-design-system';
import isEmpty from 'lodash/isEmpty';

import Page from 'App/components/Page';
import Loader from 'App/components/Loader';
import { TradeDetails } from 'Transactions/components';
import DetailsCardWrapper from 'App/components/DetailsCardWrapper';
import CodeAccordion from 'App/components/CodeAccordion';

import './TradeSinglePage.scss';

const propTypes = {
  match: object.isRequired,
  fetchTradeData: func.isRequired,
  tradeData: object.isRequired,
};

const bread = id => [
  { label: 'Transactions' },
  { label: 'Trades', route: '/transactions/trades' },
  { label: id, route: `/transactions/trades/${id}` },
];

const text = {
  TRADE: 'Trade',
};

class TradeSinglePage extends PureComponent {
  constructor(props) {
    super(props);
    this.id = props.match.params.tradeId;
  }

  componentDidMount() {
    const { fetchTradeData } = this.props;
    fetchTradeData(this.id);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.tradeId !== match.params.tradeId) {
      const { fetchTradeData } = this.props;
      fetchTradeData(match.params.tradeId);
    }
  }

  render() {
    const { tradeData } = this.props;
    if (isEmpty(tradeData)) return <Loader visible />;
    const { contact, side, metadata, wallet } = tradeData;
    return (
      <Page
        bread={bread(this.id)}
        title={`${text.TRADE} ${tradeData.symbol} ${tradeData.external_id || ''}`}
        style={{ marginTop: '24px', overflow: 'auto' }}
      >
        <div className="fiq-trade-single">
          <Col sm="100%" lg="70%">
            <DetailsCardWrapper contact={contact} wallet={wallet} side={side} title={text.TRADE}>
              <TradeDetails />
            </DetailsCardWrapper>
            <Space size={12} />
            <CodeAccordion data={metadata} toggled />
          </Col>
        </div>
      </Page>
    );
  }
}

TradeSinglePage.propTypes = propTypes;

export default withRouter(inject(stores => ({
  fetchTradeData: stores.transactions.fetchTradeData,
  tradeData: stores.transactions.tradeData,
}))(observer(TradeSinglePage)));
