import React, { PureComponent } from 'react';
import { number, oneOfType, string } from 'prop-types';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
};

const tableConfig = contactId => ({
  tableUrl: `/finances/trade-order/?contact__id=${contactId}`,
  tableKey: 'contactOrders',
});

const sizeOptions = [10, 20, 50, 100, 200];

class OrdersTable extends PureComponent {
  render() {
    const { id } = this.props;
    const { GridComponent } = GridComponentFactory(tableConfig(id));
    return <GridComponent sizeOpitons={sizeOptions} height="calc(100vh - 450px)" modifiers={modifiers} />;
  }
}

OrdersTable.propTypes = propTypes;
export default withErrorBoundary(OrdersTable);
