import React, { Component } from 'react';
import withFilters from 'App/components/hoc/withFilters';
import run from 'App/services/utilities/run';

import LineChartLayout from 'Dashboard/components/layouts/LineChart';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import getGranularity from 'App/services/utilities/getGranularity';

const text = {
  TITLE: 'New leads per Publisher',
  DESCRIPTION: 'Total number of new leads per Publisher in selected time period. Only leads that came through a Publisher are shown.',
};

const METRIC = 'new_contacts';

const getLines = keys => keys.map(key => ({
  type: 'monotone',
  dataKey: key,
  stroke: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
  strokeWidth: 2,
  dot: false,
  key,
}));

const formatData = (data, granularity) => {
  const initalValues = data
    .filter(item => item.publisher)
    .map(item => item.publisher)
    .reduce((accumulator, item) => ({ ...accumulator, [item]: 0 }), {});
  const formatedData = data.filter(item => item.publisher).reduce((acc, elem) => {
    const { publisher } = elem;
    const selectedDate = elem[granularity];
    const obj = {
      ...acc,
      [selectedDate]: {
        ...initalValues,
        ...acc[selectedDate],
        [publisher]: acc[selectedDate] && acc[selectedDate][publisher]
          ? (acc[selectedDate][publisher] += elem.count_lead)
          : (elem.count_lead),
      } };
    return obj;
  }, {});
  return Object.keys(formatedData).map(key => ({ [granularity]: key, ...formatedData[key] }));
};

const lines = [
  {
    type: 'monotone',
    dataKey: 'Facebook',
    stroke: '#0579FC',
    strokeWidth: 2,
    dot: true,
    key: 'Facebook',
  },
  {
    type: 'monotone',
    dataKey: 'Zoom',
    stroke: '#F76868',
    strokeWidth: 2,
    dot: true,
    key: 'Zoom',
  },
  {
    type: 'monotone',
    dataKey: 'Google',
    stroke: '#0EB55F',
    strokeWidth: 2,
    dot: true,
    key: 'Google',
  },
];

const publishersToOmit = ['Facebook', 'Google', 'Zoom'];


class NewLeadsChart extends Component {
  constructor() {
    super();
    this.state = {
      fetchInProgress: false,
      granularity: 'day',
      data: [],
      publishers: [],
    };
  }

    onQueryReady = async (query) => {
      const { start_date, end_date } = query;

      const granularity = getGranularity(start_date, end_date);
      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: start_date,
          end_of_interval: end_date,
          granularity,
          dimensions: ['publisher'],
        },
      };

      const [err, data] = await run(fetchMetricsData(formattedQuery));
      if (err) return;

      const publishers = [...new Set(
        data
          .filter(item => item.publisher && !publishersToOmit.includes(item.publisher))
          .map(item => item.publisher),
      )];

      this.setState({
        granularity,
        fetchInProgress: false,
        data: formatData(data, granularity),
        publishers,
      });
    }

    render() {
      const { granularity, fetchInProgress, data, publishers } = this.state;

      return (
        <LineChartLayout
          title={text.TITLE}
          description={text.DESCRIPTION}
          dimensions={[]}
          inProgress={fetchInProgress}
          onChangeDimension={this.onChangeDimension}
          data={data}
          xAxisKey={granularity}
          lines={[...getLines(publishers), ...lines]}
        />
      );
    }
}

export default {
  component: withFilters(NewLeadsChart),
};
