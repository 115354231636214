import React from 'react';
import { Popconfirm, Icon, Button } from 'tc-biq-design-system';
import { func, string } from 'prop-types';

const text = {
  ATTACHED: 'files attached',
  CANCEL: 'Cancel',
  CONFIRM: 'Delete',
  REVIEW: 'Review',
  DELETE: 'Are you sure you want to delete this document?',
};

const DeleteAction = ({ deleteDoc }) => (
  <Popconfirm
    type="destructive"
    placement="bottom"
    icon="Delete"
    label={text.DELETE}
    onConfirm={deleteDoc}
    buttonLabels={{ cancel: text.CANCEL, confirm: text.CONFIRM }}
  >
    <div style={{ maxHeight: '16px' }}>
      <Icon name="Delete" />
    </div>
  </Popconfirm>
);
DeleteAction.propTypes = { deleteDoc: func.isRequired };

const ViewPreviewAction = ({ previewDoc, backside, status }) => (
  <div className="fiq-documents__view-preview-action">
    {status === 'Pending' && (
      <Button size="small" icon="Duplicate" onClick={previewDoc}>
        {text.REVIEW}
      </Button>
    )}
    {status !== 'Pending' && (
      <Button size="small" onClick={previewDoc} color="transparent">
        <span style={{ whiteSpace: 'nowrap' }}>{`${backside ? 2 : 1} ${text.ATTACHED}`}</span>
      </Button>
    )}
  </div>
);

ViewPreviewAction.propTypes = {
  previewDoc: func.isRequired,
  backside: string,
  status: string.isRequired,
};
ViewPreviewAction.defaultProps = { backside: '' };

export { ViewPreviewAction, DeleteAction };
