import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

import GridComponentFactory from 'App/components/grid';
import { DeleteRole } from 'Settings/Sections/Roles/components';
import ManageUsersFactory from 'App/components/ManageUsersFactory';
import { openOverlay } from 'App/services/overlayService';
import { hasAccess } from 'App/services/permissionsService';
import modifiers from './modifiers';

const propTypes = {
  history: object.isRequired,
};

const text = {
  EDIT: 'Edit role',
  CLONE: 'Clone',
  DELETE: 'Delete',
  MANAGE_USERS: 'Manage users',
};

const tableConfig = {
  tableUrl: '/users/roles/',
  tableKey: 'roles',
};

const customColumns = [
  {
    key: 'actions',
    headerName: '',
  },
];

const singleActions = history => data => [
  {
    data: data.id,
    label: text.EDIT,
    action: id => history.push(`/settings/roles/${id}`),
    icon: 'Edit',
    visibility: data.is_editable && hasAccess('users_role', 'update'),
  },
  {
    data: data.id,
    label: text.CLONE,
    action: id => history.push(`/settings/roles/${id}`, { clone: true }),
    icon: 'Duplicate',
    visibility: hasAccess('users_role', 'create'),
  },
  {
    data: { roleName: data.name, roleId: data.id },
    label: text.DELETE,
    action: actionData => openOverlay('DELETE_ROLE', actionData),
    icon: 'Delete',
    visibility: hasAccess('users_role', 'delete'),
  },
  {
    data: { name: data.name, id: data.id },
    label: text.MANAGE_USERS,
    action: actionData => openOverlay('MANAGE_USERS_BINDINGS', actionData),
    icon: 'User',
    visibility: hasAccess('users_rolebinding', 'read'),
  },
].filter(action => action.visibility);

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);
const { ManageUsers } = ManageUsersFactory('roles');

const RolesTable = ({ history }) => (
  <Fragment>
    <GridComponent customColumns={customColumns} modifiers={modifiers(singleActions(history))} />
    <ManageUsers onSuccess={gridActions.fetchTableData} permissionName="users_rolebinding" />
    <DeleteRole onSuccess={gridActions.fetchTableData} />
  </Fragment>
);

RolesTable.propTypes = propTypes;

export default withRouter(RolesTable);
