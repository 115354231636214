import React, { useState } from 'react';
import { any, bool, string } from 'prop-types';
import { Accordion, Icon } from 'tc-biq-design-system';
import classNames from 'classnames';

import './SavedFiltersAccordion.scss';

const propTypes = {
  children: any.isRequired,
  isOpen: bool,
  title: string.isRequired,
};

const defaultProps = {
  isOpen: false,
};

const SavedFiltersAccordion = ({ children, isOpen, title }) => {
  const [visible, toggleAccordion] = useState(isOpen);

  const HeaderTemplate = (
    <div
      className={classNames('fiq-saved-filters-accordion__header', { open: visible })}
      onClick={() => toggleAccordion(!visible)}
    >
      <div className="tc-paragraph-strong">{title}</div>
      <div className="fiq-saved-filters-accordion__header__caret-icon">
        {visible ? (
          <Icon colorName="text-neutral-500" name="CaretDown" size="small" />
        ) : (
          <Icon colorName="text-neutral-500" name="CaretRight" size="small" />
        )}
      </div>
    </div>
  );

  return (
    <Accordion
      title=""
      headerTemplate={HeaderTemplate}
      visible={visible}
      className="accordion-override"
    >
      {children}
    </Accordion>
  );
};

SavedFiltersAccordion.propTypes = propTypes;
SavedFiltersAccordion.defaultProps = defaultProps;

export default SavedFiltersAccordion;
