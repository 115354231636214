/* eslint-disable react/jsx-pascal-case */
import React, { PureComponent } from 'react';
import { number, object, func, string, oneOfType } from 'prop-types';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import { notifier, Space, Icon, Pill, Table } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';

import {
  fetchEventDefinitionData,
  fetchEventDefinitionFields,
  editEventDefinition,
} from 'Settings/Sections/Events/services/EventsService';
import Loader from 'App/components/Loader';
import NonFieldErrors from 'App/components/NonFieldErrors';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import SidepanelFooter from 'App/components/SidepanelFooter';
import AddEventAttribute from 'App/components/AddEventAttribute';
import formatPayload from 'App/services/utilities/formatPayload';
import RuleList from 'App/components/RuleList';
import {
  formatEventLabel,
  formatEventNestedKeys,
  formatFields,
} from 'App/components/QueryBuilderFactory/queryBuilderStoreUtils';
import { YesNoCell } from 'App/components/gridCellRenderers';

import './EventDefinitionForm.scss';

const propTypes = {
  id: oneOfType([string, number]).isRequired,
  setFieldsData: func.isRequired,
  setFieldsErrors: func.isRequired,
  fieldsData: object.isRequired,
  updateFieldValue: func.isRequired,
};

const text = {
  EDIT_EVENT: 'Edited event successfully',
  EDIT_EVENT_ERR: 'Failed to edit event!',
  BUTTON_LABELS: { confirm: 'Save changes', cancel: 'Discard changes' },
  TEMPLATE_LABEL: 'Template',
  ADD_PROPERTY: 'Add event property',
  IS_ACTIVITY_VISIBLE: 'Visible on activity stream',
  EVENT: 'Event',
  QUERY: 'Query',
  CORE_EVENTS: 'Core Events',
  PROPERTIES: 'Properties',
};

const columns = [
  { title: 'Label', key: 'label' },
  { title: 'Type', key: 'type' },
  // eslint-disable-next-line react/prop-types
  { title: 'Required', key: 'required', render: ({ required }) => <YesNoCell value={required} /> },
];

const fieldFormatter = (fields) => {
  const fieldComponents = {};
  fields.forEach((fieldProps) => {
    fieldComponents[fieldProps.id] = props => (
      <Field formId="editEventDefinition" {...{ ...fieldProps, ...props }} />
    );
  });
  return fieldComponents;
};

const formatQuery = (queryFields) => {
  if (isEmpty(queryFields)) return null;
  const { old_payload, payload, ...restFields } = queryFields;

  const fields = old_payload ? { old_payload, payload } : { payload, ...restFields };

  return formatFields(
    formatEventNestedKeys(fields),
    formatEventLabel,
  );
};

const EventDefinitionForm = inject(stores => ({
  setFieldsData: stores.forms.editEventDefinition.setFieldsData,
  setFieldsErrors: stores.forms.editEventDefinition.setFieldsErrors,
  updateFieldValue: stores.forms.editEventDefinition.updateFieldValue,
  fieldsData: stores.forms.editEventDefinition.data,
  fetchQueryBuilderMetadata: stores.events.fetchQueryBuilderMetadata,
}))(observer(
  class EventDefinitionForm extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        data: null,
        requestInProgress: false,
        fields: null,
        nonFieldErrors: null,
      };
    }

    componentDidMount() {
      this.fetchData();
    }

    fetchData = async () => {
      const { id, setFieldsData } = this.props;
      this.setState({ requestInProgress: true });
      const response = await Promise.all([
        fetchEventDefinitionData(id),
        fetchEventDefinitionFields(id),
      ]);
      setFieldsData(response[0].data);
      this.setState({
        data: response[0].data,
        fields: response[1].data.actions.PUT,
        requestInProgress: false,
      });
      const { data } = this.state;
      document.title = `${text.EVENT} ${data.name}`;
    };

    submit = async () => {
      const { fieldsData, setFieldsErrors, id, history } = this.props; //eslint-disable-line
      const { template, is_activity_visible } = formatPayload(fieldsData);
      this.setState({ requestInProgress: true, nonFieldErrors: null });
      await editEventDefinition(id, { template, is_activity_visible }).then(
        () => {
          this.setState({ requestInProgress: false });
          history.push('/settings/events/');
          notifier.success(text.EDIT_EVENT);
        },
        (err) => {
          this.setState({ requestInProgress: false });
          const errors = err.response.data;
          setFieldsErrors(errors);
          if (errors.non_field_errors) {
            this.setState({ nonFieldErrors: errors.non_field_errors });
          }
          notifier.error(text.EDIT_EVENT_ERR);
        },
      );
    };

    cancel = () => {
      const { history } = this.props; //eslint-disable-line
      history.push('/settings/events/');
    };

    render() {
      const { data, requestInProgress, fields, nonFieldErrors } = this.state;
      const { updateFieldValue } = this.props;

      const queryFields = formatQuery(data?.message_properties);

      if (requestInProgress || !data || isEmpty(fields)) {
        return <Loader visible />;
      }

      const templateField = {
        id: 'template',
        name: 'template',
        type: 'text',
      };

      const FieldComponents = fieldFormatter(fieldsResolver({
        template: templateField, ...fields,
      }));

      return (
        <div className="fiq-event-definition__form-wrapper">
          <div className="fiq-event-definition__form">
            <div className="fiq-event-definition__form-section">
              <div className="fiq-event-definition__icon-name">
                <div className="fiq-event-definition__icon">
                  <Icon name="Action" colorName="text-primary-500" />
                </div>
                <Space size={8} />
                <div className="tc-heading-s">{`${data.type}`}</div>
              </div>
              <Space size={24} />
              <FieldComponents.name disabled />
              <FieldComponents.group disabled />
            </div>
            {data?.core_events && (
              <div className="fiq-event-definition__form-section">
                <div className="fiq-event-definition__icon-name">
                  <div className="tc-heading-s">{text.CORE_EVENTS}</div>
                </div>
                <Space size={16} />
                {data.core_events.map(event => <Pill>{event.name}</Pill>)}
              </div>
            )}
            {data?.query && queryFields && (
              <div className="fiq-event-definition__form-section">
                <div className="fiq-event-definition__icon-name">
                  <div className="tc-heading-s">{text.QUERY}</div>
                </div>
                <Space size={16} />
                <RuleList item={data.query} formattedFields={queryFields} />
              </div>
            )}
            {data?.properties && (
              <div className="fiq-event-definition__form-section">
                <div className="fiq-event-definition__icon-name">
                  <div className="tc-heading-s">{text.PROPERTIES}</div>
                </div>
                <Space size={16} />
                <Table
                  cols={columns}
                  data={data?.properties.map((item, index) => ({ ...item, id: index }))}
                />
              </div>
            )}
            <div className="fiq-event-definition__form-section">
              <div className="fiq-event-definition__icon-name">
                <div className="tc-heading-s">{text.TEMPLATE_LABEL}</div>
              </div>
              <Space size={16} />
              <AddEventAttribute updateFieldValue={updateFieldValue} fieldKey="template" attributes={data.template_properties}>
                <Field {...fields.template} {...templateField} type="textarea" formId="editEventDefinition" />
              </AddEventAttribute>
              <NonFieldErrors errors={nonFieldErrors} />
            </div>
            <div className="fiq-event-definition__form-section--center">
              <FieldComponents.is_activity_visible description={text.IS_ACTIVITY_VISIBLE} />
            </div>
            <div className="fiq-event-definition__form-section--align-right">
              <SidepanelFooter
                submitInProgress={requestInProgress}
                execute={this.submit}
                close={this.cancel}
                cancelColor="ghost"
                confirmColor="primary"
                buttonLabels={text.BUTTON_LABELS}
              />
            </div>
          </div>
        </div>
      );
    }
  },
));


EventDefinitionForm.wrappedComponent.propTypes = propTypes;

export default withRouter(EventDefinitionForm);
