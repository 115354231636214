import React from 'react';
import { string, func, number } from 'prop-types';
import { TextArea, Space } from 'tc-biq-design-system';

const propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  afterFieldSpaceSize: number,
};

const defaultProps = {
  afterFieldSpaceSize: 12,
};

const TextAreaField = (props) => {
  const { onChange, name, id, afterFieldSpaceSize } = props;
  return (
    <div
      data-cy={name}
      data-recording-ignore="mask"
    >
      <TextArea
        {...props}
        key={id}
        onChange={e => onChange(name, e.target.value)}
        className="tc-paragraph-regular"
      />
      <Space size={afterFieldSpaceSize} />
    </div>
  );
};

TextAreaField.propTypes = propTypes;
TextAreaField.defaultProps = defaultProps;

export default TextAreaField;
