import { action, runInAction, observable, makeObservable } from 'mobx';

import { fetchContactSignupData } from 'Contacts/services/contactsService';

export default class SignupDetailsStore {
  signupData = {};

  requestInProgress = false;

  errors = null;

  constructor() {
    makeObservable(this, {
      signupData: observable,
      requestInProgress: observable,
      errors: observable,
      fetchSignupData: action.bound,
    });
  }

  async fetchSignupData(id) {
    this.requestInProgress = true;
    try {
      const response = await fetchContactSignupData(id);
      runInAction(() => {
        if (response.data.results.length) {
          this.signupData = response.data.results[0].signup_info;
        }
        this.requestInProgress = false;
      });
    } catch (err) {
      runInAction(() => {
        this.requestInProgress = false;
        this.errors = err.data;
      });
    }
  }
}
