import React from 'react';

import { PillCell, StatusCell } from 'App/components/gridCellRenderers';
import { openPreviewLog } from 'Settings/Sections/Webhooks/components/overlays/PreviewLog';

// eslint-disable-next-line react/prop-types
const PreviewLog = ({ value, data }) => {
  const openPreview = () => openPreviewLog(value, data.status);
  return (
    <div onClick={openPreview} className="like-a-link">
      {value}
    </div>
  );
};

const modifiers = {
  id: {
    cellRendererFramework: PreviewLog,
    minWidth: 150,
  },
  delivery: {
    minWidth: 150,
  },
  webhook: {
    // eslint-disable-next-line react/prop-types
    cellRendererFramework: ({ value }) => <span>{value.name}</span>,
    minWidth: 200,
  },
  attempt: {
    minWidth: 150,
  },
  event: {
    cellRendererFramework: PillCell({ displayField: 'name' }),
    minWidth: 150,
  },
  status: { cellRendererFramework: StatusCell, minWidth: 150 },
};

export default modifiers;
