import React from 'react';
import { Select } from 'tc-biq-design-system';
import { func } from 'prop-types';

import { dateTimeChoices } from 'App/services/utilities/timeAgo';

const propTypes = {
  onChange: func.isRequired,
};

const MinutesAgo = (props) => {
  const { onChange } = props;
  return (
    <Select
      valueKey="value"
      labelKey="display_name"
      {...props}
      options={dateTimeChoices}
      onChange={selected => onChange(selected.value)}
    />
  );
};

MinutesAgo.propTypes = propTypes;

export default MinutesAgo;
