import React, { useContext } from 'react';
import { Button, Dropdown, notifier } from 'tc-biq-design-system';

import { openSaveEditFilter } from '../sidepanels/SaveEditFilter';
import { SavedFiltersContext } from '../FiltersProvider';

import './SaveFilter.scss';

const text = {
  SAVE: 'Save',
  SAVE_AS: 'Save as',
  SAVE_FILTER: 'Save filter',
  SAVE_ERROR: 'Failed to save filter',
  SAVE_SUCCESS: 'Saved filter successfully',
  NO_ACTIVE_FILTER: 'No active filter selected. Use SaveAs for saving new filters',
};

const renderFilterItem = (label, action) => (
  <div className="fiq-save-filter-item tc-paragraph-regular" onClick={action}>
    {label}
  </div>
);

const customList = (save, saveAs) => [
  { item: () => renderFilterItem(text.SAVE, save) },
  { item: () => renderFilterItem(text.SAVE_AS, saveAs) },
];

const customHeader = toggle => (
  <Button iconPosition="left" icon="Save" color="transparent" onClick={toggle}>
    {text.SAVE_FILTER}
  </Button>
);

const SaveFilter = () => {
  const {
    service: { saveFilter },
    filtersStore: { activeFilter, filterQuery },
    fetchSavedFilters,
  } = useContext(SavedFiltersContext);

  const save = async () => {
    const payload = {
      ...activeFilter.filter,
      filters: filterQuery,
    };
    if (!payload.id) {
      notifier.warning(text.NO_ACTIVE_FILTER);
      return;
    }
    try {
      await saveFilter(payload, payload.id);
      notifier.success(text.SAVE_SUCCESS);
      fetchSavedFilters();
    } catch {
      notifier.error(text.SAVE_ERROR);
    }
  };

  return <Dropdown customList={customList(save, openSaveEditFilter)} customHeader={customHeader} />;
};

export default SaveFilter;
