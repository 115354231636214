import React from 'react';
import { string } from 'prop-types';

const propTypes = {
  errors: string,
};

const defaultProps = {
  errors: '',
};

const NonFieldErrors = ({ errors }) => (
  <div className="tc-heading-s text-status04-600">{errors}</div>
);

NonFieldErrors.propTypes = propTypes;
NonFieldErrors.defaultProps = defaultProps;

export default NonFieldErrors;
