import React, { Fragment } from 'react';

import GridComponentsFactory from 'App/components/grid';
import { hasAccess } from 'App/services/permissionsService';
import ManageUsersFactory from 'App/components/ManageUsersFactory';
import { SIDEPANEL_ID } from 'App/components/ManageUsersFactory/ManageUsersFactory';
import DeleteContactAccessGroup, {
  SIDEPANEL_ID as DELETE_SIDEPANEL_ID,
} from 'Settings/Sections/ContactAccessGroup/components/sidepanels/DeleteContactAccessGroup';
import { openOverlay } from 'App/services/overlayService';
import modifiers from './modifiers';

const tableConfig = {
  tableUrl: '/contact-access-groups/',
  tableKey: 'cag',
};

const text = {
  CAG_LABEL: 'Contact access groups',
  HEADER_ACTION: 'Create access group',
  EDIT: 'Edit contact access group',
  CLONE: 'Clone',
  DELETE: 'Delete',
  MANAGE_USERS: 'Manage users',
};

const customColumns = [
  {
    key: 'actions',
    headerName: '',
  },
];

const singleActions = (data, history) => [
  {
    data: data.id,
    label: text.EDIT,
    action: id => history.push(`/settings/contact-access-groups/${id}`),
    icon: 'Edit',
    visibility:
        data.is_editable && hasAccess('contact_access_groups_contactaccessgroup', 'update'),
  },
  {
    data: data.id,
    label: text.CLONE,
    action: id => history.push(`/settings/contact-access-groups/${id}/clone`),
    icon: 'Duplicate',
    visibility: hasAccess('contact_access_groups_contactaccessgroup', 'create'),
  },
  {
    data: { name: data.name, cagId: data.id },
    label: text.DELETE,
    action: actionData => openOverlay(DELETE_SIDEPANEL_ID, actionData),
    icon: 'Delete',
    visibility:
        data.is_editable && hasAccess('contact_access_groups_contactaccessgroup', 'delete'),
  },
  {
    data: { name: data.name, id: data.id, bindings: data.bindings },
    label: text.MANAGE_USERS,
    action: actionData => openOverlay(SIDEPANEL_ID, actionData),
    icon: 'User',
    visibility: hasAccess('contact_access_groups_contactaccessgroupbinding', 'update'),
  },
].filter(action => action.visibility);

const { GridComponent, gridActions } = GridComponentsFactory(tableConfig);
const { ManageUsers } = ManageUsersFactory('cag');

const CagTable = () => {
  const manageUsers = data => openOverlay(SIDEPANEL_ID, {
    id: data.id,
    name: data.name,
    bindings: data.bindings,
  });
  return (
    <Fragment>
      <GridComponent
        modifiers={modifiers(singleActions, manageUsers)}
        customColumns={customColumns}
      />

      <ManageUsers
        onSuccess={gridActions.fetchTableData}
        permissionName="contact_access_groups_contactaccessgroupbinding"
      />
      <DeleteContactAccessGroup onSuccess={gridActions.fetchTableData} />
    </Fragment>
  );
};

export default CagTable;
