import React, { useEffect } from 'react';
import { number, oneOfType, string, func } from 'prop-types';
import { inject, observer } from 'mobx-react';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import ActivityStream from 'ActivityStream/components/ActivityStream';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
  setInitialFilters: func,
  fetchActivityOptions: func,
  resetFilters: func,
  resetActivities: func,
};

const defaultProps = {
  resetActivities: () => null,
  resetFilters: () => null,
  fetchActivityOptions: () => null,
  setInitialFilters: () => null,
};

const CampaignActivityStream = inject(stores => ({
  setInitialFilters: stores.activityStream.setInitialFilters,
  fetchActivityOptions: stores.activityStream.fetchActivityOptions,
  resetFilters: stores.activityStream.resetFilters,
  resetActivities: stores.activityStream.resetActivities,
}))(
  observer(({ resetFilters, setInitialFilters, fetchActivityOptions, resetActivities, id }) => {
    useEffect(() => {
      resetFilters();
      setInitialFilters({ campaignId: id });
      fetchActivityOptions();

      return () => {
        resetActivities();
      };
    }, []);

    return <ActivityStream />;
  }),
);


CampaignActivityStream.propTypes = propTypes;
CampaignActivityStream.defaultProps = defaultProps;

export default withErrorBoundary(CampaignActivityStream);
