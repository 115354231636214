import React, { PureComponent } from 'react';
import { number, oneOfType, string, func } from 'prop-types';
import { inject, observer } from 'mobx-react';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import ActivityStream from 'ActivityStream/components/ActivityStream';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
  setInitialFilters: func.isRequired,
  fetchActivityOptions: func.isRequired,
  resetFilters: func.isRequired,
  resetActivities: func.isRequired,
};


const ContactActivityStream = inject(stores => ({
  setInitialFilters: stores.activityStream.setInitialFilters,
  fetchActivityOptions: stores.activityStream.fetchActivityOptions,
  resetFilters: stores.activityStream.resetFilters,
  resetActivities: stores.activityStream.resetActivities,
}))(observer(
  class ContactActivityStream extends PureComponent {
    componentDidMount() {
      const { resetFilters, fetchActivityOptions, id, setInitialFilters } = this.props;
      resetFilters();
      setInitialFilters({ contactId: id });
      fetchActivityOptions();
    }

    componentWillUnmount() {
      const { resetActivities } = this.props;
      resetActivities();
    }

    render() {
      return <ActivityStream />;
    }
  },
));


ContactActivityStream.wrappedComponent.propTypes = propTypes;

export default withErrorBoundary(ContactActivityStream);
