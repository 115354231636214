import React from 'react';

import Page from 'App/components/Page';
import WithdrawalsTable, { tableConfig } from 'Transactions/components/WithdrawalsTable';

const bread = [
  { label: 'Transactions' },
  { label: 'Withdrawals', route: '/transactions/withdrawals' },
];
const exportDataOptions = { permission: 'finances_withdrawrequest', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

const text = {
  WITHDRAWALS: 'Withdrawals',
};

const WithdrawalsPage = () => (
  <Page bread={bread} title={text.WITHDRAWALS} style={{ margin: '0 20px' }} exportDataOptions={exportDataOptions}>
    <WithdrawalsTable />
  </Page>
);

export default WithdrawalsPage;
