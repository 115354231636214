import React from 'react';
import { Icon, Tooltip, Popconfirm } from 'tc-biq-design-system';
import { object, string, shape, func, arrayOf } from 'prop-types';

import extractDisplayName from 'App/services/utilities/extractDisplayName';

const iconObjectShape = {
  color: string.isRequired,
  name: string.isRequired,
};

const actionObjectShape = {
  label: string.isRequired,
  icon: shape(iconObjectShape),
  onClick: func.isRequired,
  data: object.isRequired,
};

const propTypes = {
  actions: arrayOf(actionObjectShape),
};

const ACTIONS_STYLE = {
  display: 'flex',
  justifyContent: 'space-evenly',
  height: '100%',
  width: '100%',
};

const formatDataCy = dataString => dataString
  .toLowerCase()
  .split(' ')
  .join('-');

// eslint-disable-next-line
const actionWrapper = actions => ({ data }) => (
  <div style={ACTIONS_STYLE}>
    {actions.map(action => (
      <div
        style={{ margin: '0 4px' }}
        data-cy={formatDataCy(`${action.label} ${extractDisplayName(data)}`)}
        key={action.label}
      >
        <Tooltip title={action.label} placement="top">
          {!action.popconfirm && (
            <Icon
              onClick={() => action.onClick(data)}
              colorName={action.icon.color}
              name={action.icon.name}
              size={action.icon.size}
            />
          )}
          {action.popconfirm && (
            <Popconfirm
              type={action.popconfirm.type}
              placement={action.popconfirm.placement}
              icon={action.popconfirm.icon}
              label={action.popconfirm.label}
              onConfirm={() => action.onClick(data)}
              buttonLabels={action.popconfirm.buttonLabels}
            >
              <Icon colorName={action.icon.color} name={action.icon.name} size={action.icon.size} />
            </Popconfirm>
          )}
        </Tooltip>
      </div>
    ))}
  </div>
);

actionWrapper.propTypes = propTypes;

export default actionWrapper;
