import React from 'react';
import { Input } from 'tc-biq-design-system';
import { func, number, string, oneOfType } from 'prop-types';
import useEnterPress from './hooks/useEnterPress';

const propTypes = {
  onChange: func.isRequired,
  onEnter: func,
  value: oneOfType([number, string]),
};
const defaultProps = {
  value: '',
  onEnter: () => null,
};

const NumberInput = ({ onEnter, ...props }) => {
  const { onChange, value } = props;

  const disableNonNumbers = (event) => {
    if (!value && (event.keyCode === 69 || event.keyCode === 190)) {
      event.preventDefault();
    }
  };

  // Validates number input so that it matches the following regexp
  // It matches any amount of numbers followed by an optional dot and up to 8 numbers after the dot
  const validateInput = (event) => {
    const nextValue = event.target.value;
    const pattern = new RegExp(/^\d*.?\d{0,8}$/);
    if (nextValue && pattern.test(nextValue)) return parseFloat(nextValue);
    return '';
  };

  const onKeyPress = useEnterPress(onEnter);

  return (
    <Input
      type="number"
      {...props}
      onKeyDown={e => disableNonNumbers(e)}
      onChange={e => onChange(validateInput(e))}
      onKeyPress={onKeyPress}
    />
  );
};

NumberInput.propTypes = propTypes;
NumberInput.defaultProps = defaultProps;

export default NumberInput;
