import React from 'react';
import { Tooltip } from 'tc-biq-design-system';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { any, string } from 'prop-types';

import './CopyToClipboardWrapper.scss';

const CopyToClipboardWrapper = ({ children, textToCopy }) => (
  <div className="fiq-copy-to-clipboard-wrapper">
    <Tooltip
      tooltipOptions={{
        trigger: 'click',
        // Make Tooltip disappear after 1 second.
        onShow(instance) {
          setTimeout(() => {
            instance.hide();
          }, 1000);
        },
      }}
      content="copied"
    >
      <CopyToClipboard text={textToCopy}>{children}</CopyToClipboard>
    </Tooltip>
  </div>
);

CopyToClipboardWrapper.propTypes = { children: any.isRequired, textToCopy: string };
CopyToClipboardWrapper.defaultProps = { textToCopy: null };

export default CopyToClipboardWrapper;
