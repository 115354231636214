import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'tc-biq-design-system';
import { array, func } from 'prop-types';

import Page from 'App/components/Page';
import { IntegrationBlock, EditIntegration } from 'Settings/Sections/Integrations/components';

const propTypes = {
  fetchIntegrationsData: func.isRequired,
  integrationsData: array.isRequired,
};

const text = {
  TITLE: 'Integrations',
};

const bread = [
  { label: 'Settings', route: '/settings' },
  { label: 'Integrations', route: '/settings/integrations' },
];


const IntegrationsPage = inject(stores => ({
  fetchIntegrationsData: stores.integrations.fetchIntegrationsData,
  integrationsData: stores.integrations.integrationsData,
}))(observer(
  class IntegrationsPage extends PureComponent {
    componentDidMount() {
      const { fetchIntegrationsData } = this.props;
      fetchIntegrationsData();
    }

    render() {
      const { integrationsData } = this.props;
      return (
        <Page bread={bread} title={text.TITLE}>
          <Row gutter={11}>
            {integrationsData.map(({ name, description, config, configured }) => (
              <Col key={name} sm="1/3">
                <IntegrationBlock
                  name={name}
                  description={description}
                  config={config}
                  is_configured={configured}
                />
              </Col>
            ))}
          </Row>
          <EditIntegration />
        </Page>
      );
    }
  },
));

IntegrationsPage.wrappedComponent.propTypes = propTypes;

export default IntegrationsPage;
