import React, { PureComponent } from 'react';
import { number, oneOfType, string } from 'prop-types';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
};

const tableConfig = contactId => ({
  tableUrl: `/contacts/${contactId}/withdraw-requests/`,
  tableKey: 'contactWithdrawals',
});

const sizeOptions = [10, 20, 50, 100, 200];

class WithdrawalsTable extends PureComponent {
  render() {
    const { id } = this.props;
    const { GridComponent } = GridComponentFactory(tableConfig(id));
    return <GridComponent sizeOpitons={sizeOptions} height="calc(100vh - 450px)" modifiers={modifiers} />;
  }
}

WithdrawalsTable.propTypes = propTypes;
export default withErrorBoundary(WithdrawalsTable);
