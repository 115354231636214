import React, { Fragment, PureComponent } from 'react';
import classNames from 'classnames';
import { Button, Icon } from 'tc-biq-design-system';
import Field from 'App/components/FieldRenderer';
import { object, func, bool } from 'prop-types';

const propTypes = {
  hidden: bool.isRequired,
  closeDropdown: func.isRequired,
  onAdd: func,
  value: object,
  onChange: func,
  showUnassignButton: bool,
};

const defaultProps = {
  value: null,
  onChange: null,
  onAdd: null,
  showUnassignButton: false,
};

const text = {
  SET_MANAGER: 'Set manager',
  UNASSIGN: 'Unassign',
  CHOOSE: 'Choose',
};

// eslint-disable-next-line
const UsersField = ({ showUnassignButton,value, onChange, onAdd, managerKey }) => (
  <Fragment>
    <Field
      clearable={false}
      async
      type="related field"
      labelKey="display_name"
      valueKey="value"
      id="users"
      name="users"
      placeholder={text.PLACEHOLDER}
      related_model={{
        display_name: 'users',
        value: 'users_user',
      }}
      value={value}
      onChange={onChange}
    />
    <div className="button-wrapper">
      <Button onClick={() => onAdd(value)}>{text.CHOOSE}</Button>
      {showUnassignButton && <Button color="transparent" onClick={() => onChange(null)}>{text.UNASSIGN}</Button>}
    </div>
  </Fragment>

);


function isReactSelect(element) {
  if (!element) return false;
  const isCloseIcon = element.classList.contains('Select-value-icon');
  const isReactSelectEl = element.id.indexOf('react-select') !== -1;
  if (isReactSelectEl || isCloseIcon) {
    return true;
  }
  return false;
}

class SelectManagerDropdown extends PureComponent {
  componentDidUpdate() {
    const { hidden } = this.props;
    if (hidden) {
      document.removeEventListener('mousedown', this.handleClick, false);
    } else {
      document.addEventListener('mousedown', this.handleClick, false);
    }
  }

  close = () => {
    const { closeDropdown } = this.props;
    closeDropdown();
  };

  handleClick = ({ target }) => {
    if (this.filterDropdownRef?.contains(target) || isReactSelect(target)) return;
    this.close();
  };

  render() {
    const { hidden, value, onChange, onAdd, showUnassignButton } = this.props;
    return (
      <div
        ref={(node) => {
          this.filterDropdownRef = node;
        }}
        className={classNames('fiq-assign-manager__add-user__user-dropdown', {
          hidden,
        })}
      >
        <div className="fiq-assign-manager__add-user__user-dropdown__heading">
          <span className="tc-paragraph-strong">{text.SET_MANAGER}</span>
          <span onClick={this.close}>
            <Icon name="Close" size="small" />
          </span>
        </div>
        {!hidden
        && (
        <UsersField
          showUnassignButton={showUnassignButton}
          value={value}
          onChange={onChange}
          onAdd={onAdd}
        />
        )}
      </div>
    );
  }
}

SelectManagerDropdown.propTypes = propTypes;
SelectManagerDropdown.defaultProps = defaultProps;

export default SelectManagerDropdown;
