import G6 from '@antv/g6';

import three_dots from 'assets/images/block-three-dots.svg';
import checkmark from 'assets/images/edge-checkmark.svg';
import cross from 'assets/images/edge-cross.svg';

import { colors, getBlockIcon } from '../utils';
import { BLOCK_TYPE, DOM_CLASS_NAME, blockActionIconMap } from '../consts';

import './workflowDefaultNode';
import './workflowAddNode';

const text = {
  TRUE: 'True',
  FALSE: 'False',
};

G6.registerEdge(
  'workflowEdge',
  {
    afterDraw(cfg, group) {
      if (cfg.payload?.type === 'branch') {
        const shape = group.get('children')[0];
        const midPoint = shape.getPoint(0.5);
        const isTrueBranch = !!cfg.payload.index;
        group.addShape('rect', {
          attrs: {
            width: 60,
            height: 24,
            radius: 6,
            x: midPoint.x - 20,
            y: midPoint.y - 10,
            fill: colors.EDGE.TEXT_FILL,
            stroke: colors.EDGE.STROKE,
          },
        });

        group.addShape('image', {
          attrs: {
            x: midPoint.x - 15,
            y: midPoint.y - 5,
            width: 14,
            height: 14,
            img: `${isTrueBranch ? checkmark : cross}`,
          },
          name: 'checkmark',
        });

        group.addShape('text', {
          attrs: {
            textBaseline: 'top',
            x: midPoint.x + 5,
            y: midPoint.y - 3,
            lineHeight: 20,
            text: isTrueBranch ? text.TRUE : text.FALSE,
            fill: colors.EDGE.TEXT,
          },
          name: 'title',
        });
      }
    },
    update: undefined,
  },
  'polyline',
);

G6.registerNode(
  'dom-node',
  {
    draw: (cfg, group) => {
      let width = cfg.size[0];
      let height = cfg.size[1];
      if (cfg.blockType === BLOCK_TYPE.EXIT) {
        width *= 0.6;
        height *= 0.75;
      } else if (cfg.blockType === BLOCK_TYPE.TRIGGER) {
        width = 40;
        height = 40;
      }
      const styleClass = cfg.blockType !== BLOCK_TYPE.TRIGGER ? 'block' : 'trigger';
      return group.addShape('dom', {
        attrs: {
          // Move the shape to make the center of the DOM rect align to the
          // origin of the custom node.
          x: -width / 2,
          y: -height / 2,
          width,
          height,
          style: 'overflow: visible',
          // DOM's html with onclick event.
          html: `
            <div class="${DOM_CLASS_NAME.NODE} fiq-workflow__canvas__${styleClass}" id=${cfg.id}>
              ${getBlockImage(cfg.blockType, cfg.payload?.properties)}
              ${getBlockContent(cfg.text, cfg.description)}
              ${getBlockDropdown(cfg.actions, cfg.id)}
            </div>
          `,
        },
        draggable: false,
      });
    },
  },
  'single-node',
);

const getBlockImage = (type, properties) => (type
  ? `
  <img src="${getBlockIcon(type, properties)}" alt="${type}"/>`
  : '');

const getBlockContent = (label, description) => (label
  ? `
  <div class="fiq-workflow__canvas__block__content">
    <div>${label}</div>
    ${getBlockDescription(description)}
  </div>
  `
  : '');

const getBlockDescription = description => (description ? `<div class="text-neutral-500">${description}</div>` : '');

const getThreeDots = id => `
  <div class="${DOM_CLASS_NAME.BLOCK_EXPAND_DROPDOWN} fiq-workflow__canvas__block__dropdown__three-dots" id=${id}>
    <img src="${three_dots}" alt="Three Dots" />
  </div>`;

const getBlockDropdown = (actions, id) => (actions.length
  ? `
  <div class="fiq-workflow__canvas__block__dropdown">
    ${getThreeDots(id)}
    <ul class="fiq-workflow__canvas__block__dropdown__content ${id}-dropdown">
      ${getBlockActions(actions, id)}
    </ul>
  </div>`
  : '');

const getBlockActions = (actions, id) => actions.reduce(
  (acc, action) => `${acc}<li class="${DOM_CLASS_NAME.BLOCK_ACTION(
    action,
  )} fiq-workflow__canvas__block__dropdown__content__item" id=${id}>
    <img src=${blockActionIconMap[action]} alt=${action}/>
    <p class="tc-paragraph-regular">${action}</p>
  </li>`,
  '',
);
