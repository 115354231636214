import React, { useCallback } from 'react';
import { Modal, Button, Space } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { bool, array } from 'prop-types';
import { closeOverlay } from 'App/services/overlayService';

const propTypes = {
  visible: bool.isRequired,
  requestInProgress: bool.isRequired,
  users: array.isRequired,
};

const MODAL_ID = 'ADD_SINGLE_USER';

const text = {
  CANCEL: 'Cancel',
  INVITE: 'Invite user',
  TITLE: 'Invite user',
};

const close = () => closeOverlay(MODAL_ID);

const customFooter = (inviteUsers) => () => {
  const onSubmit = useCallback(() => {
    inviteUsers();
    close();
  }, [inviteUsers, close]);

  return (
    <div className="fiq-documents__upload-doc">
      <Button
        icon="Close"
        color="ghost"
        onClick={close}
      >
        {text.CANCEL}
      </Button>
      <Space size={12} />
      <Button
        icon="Checkmark"
        onClick={onSubmit}
      >
        {text.INVITE}
      </Button>
    </div>
  )
}

const AddSingleUser = inject(stores => ({
  visible: !!stores.overlayStore.overlay[MODAL_ID],
  users: stores.usersStore.users,
  inviteUsers: stores.usersStore.inviteUsers,
}))(
  observer(({ visible, inviteUsers, users}) => {    
    return (
      <Modal
        closable
        title={text.TITLE}
        icon="Plus"
        visible={visible}
        onCloseIconClick={close}
        footerRender={customFooter(inviteUsers)}
      >
        <span>Do you want to send invitation to user(s)/emails:</span><br/>     
        {users.map((user, index) => <b key={index}>{user.email}<br/></b>)}
      </Modal>
    );
  })
);

AddSingleUser.wrappedComponent.propTypes = propTypes;

export default AddSingleUser;
