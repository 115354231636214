import React from 'react';
import { Tabs, Tab, Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import WebhooksTable from 'Settings/Sections/Webhooks/components/WebhooksTable';
import WebhookLogsTable from 'Settings/Sections/Webhooks/components/WebhookLogsTable';
import { hasAccess } from 'App/services/permissionsService';

const propTypes = {
  history: object.isRequired,
};

const text = {
  WEBHOOK_TITLE: 'Webhooks',
  LOGS_TITLE: 'Logs',
  TITLE: 'Webhook management',
  CREATE_WEBHOOK: 'Create webhook',
};

const tableConfig = {
  tableUrl: '/automations/webhooks/logs/',
  tableKey: 'webhookLogs',
};

const WebhooksPage = ({ history }) => {
  const headerActions = () => {
    if (hasAccess('automations_webhook', 'create')) {
      const routeToWebhook = () => history.push('/settings/webhook/create');
      return <Button onClick={routeToWebhook}>{text.CREATE_WEBHOOK}</Button>;
    }
    return null;
  };
  return (
    <Page goBackIcon={null} title={text.TITLE} headerActions={headerActions}>
      <Tabs style={{ overflow: 'auto' }}>
        <Tab visible={hasAccess('automations_webhook', 'read')} title={text.WEBHOOK_TITLE}>
          <WebhooksTable />
        </Tab>
        <Tab visible={hasAccess('automations_webhooklog', 'read')} title={text.LOGS_TITLE}>
          <WebhookLogsTable style={{ margin: '0 20px' }} tableConfig={tableConfig} />
        </Tab>
      </Tabs>
    </Page>
  );
};

WebhooksPage.propTypes = propTypes;

export default withRouter(WebhooksPage);
