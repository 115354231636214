import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { object, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import withFilters from 'App/components/hoc/withFilters';

import LineChartLayout from 'Dashboard/components/layouts/LineChart';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import { buildQueryForPreviousInterval, buildNumeric } from 'Dashboard/store/utils';
import getGranularity from 'App/services/utilities/getGranularity';

const text = {
  TITLE: 'New contacts',
  DESCRIPTION: 'Total number of new contacts in selected time period.',
};

const METRIC = 'new_contacts';

const propTypes = {
  dashboardInterval: object,
  addNewFilter: func,
  history: object.isRequired,
  setContactsData: func,
};

const defaultProps = {
  dashboardInterval: {},
  addNewFilter: null,
  setContactsData: null,
};

const lines = [{ type: 'monotone', dataKey: 'New', stroke: '#0579FC', strokeWidth: 2, dot: false, key: 'new_contacts' }];

const filters = (startDate, endDate) => [
  {
    field: { label: 'Created', value: 'created', type: 'datetime' },
    ...(startDate === endDate
      ? { operator: { label: 'on', value: 'date', related_models: null } }
      : { operator: { label: 'between', value: 'range', related_models: null } }),
    ...(startDate === endDate
      ? { value: startDate }
      : { value: [`${startDate}T00:00:00Z`, `${endDate}T23:59:59Z`] }),
  },
  {
    field: { label: 'Status', value: 'status', type: 'choice' },
    operator: { label: 'is not equal to', value: 'exact!', related_models: null },
    value: { value: 'Lead', display_name: 'Lead' },
  },
];

const NewContactsChart = inject(stores => ({
  dashboardInterval: stores.dashboardStore.dashboardInterval,
  addNewFilter: stores.tables.contacts.filters.addNewFilter,
  setContactsData: stores.dashboardStore.setContactsData,
}))(observer(
  class NewContactsChart extends Component {
    constructor() {
      super();
      this.state = {
        fetchInProgress: false,
        granularity: 'day',
        data: [],
        error: null,
      };
    }

    onQueryReady = async (query) => {
      const { start_date, end_date } = query;
      const { setContactsData } = this.props;
      const { error } = this.state;

      const granularity = getGranularity(start_date, end_date);

      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: start_date,
          end_of_interval: end_date,
          granularity,
          dimensions: null,
        },
      };


      const response = await Promise.all([
        fetchMetricsData(formattedQuery),
        fetchMetricsData(buildQueryForPreviousInterval(formattedQuery)),
      ]).catch(errorResponse => this.setState({ error: errorResponse }));

      if (error) return;
      const [current, previous] = response.map(item => item.data);
      const { currency } = current;

      const newContactsData = current.map(item => ({ ...item, New: item.count_regular }));
      const currentNewContactsCount = current.reduce((acc, item) => acc + item.count_regular, 0);
      const previousNewContactsCount = previous.reduce((acc, item) => acc + item.count_regular, 0);
      const newContactsNumericData = buildNumeric(
        currentNewContactsCount,
        previousNewContactsCount,
        currency,
      );

      const leadsChartData = current.map(item => ({ ...item, Leads: item.count_lead }));
      const currentLeadsCount = current.reduce((acc, item) => acc + item.count_lead, 0);
      const previousLeadsCount = previous.reduce((acc, item) => acc + item.count_lead, 0);
      const leadsNumericData = buildNumeric(currentLeadsCount, previousLeadsCount, currency);

      setContactsData({ newContactsNumericData, leadsChartData, leadsNumericData });
      this.setState({
        granularity,
        fetchInProgress: false,
        data: newContactsData,
      });
    }

    handleCardClick = () => {
      const { addNewFilter, history, dashboardInterval } = this.props;
      const { startDate, endDate } = dashboardInterval;
      filters(startDate, endDate).forEach(addNewFilter);
      history.push('/contacts');
    };

    render() {
      const { granularity, fetchInProgress, data } = this.state;

      return (
        <LineChartLayout
          title={text.TITLE}
          description={text.DESCRIPTION}
          dimensions={[]}
          inProgress={fetchInProgress}
          onChangeDimension={this.onChangeDimension}
          data={data}
          xAxisKey={granularity}
          handleCardClick={this.handleCardClick}
          lines={lines}
        />
      );
    }
  },
));

NewContactsChart.wrappedComponent.propTypes = propTypes;
NewContactsChart.wrappedComponent.defaultProps = defaultProps;

export default {
  component: withRouter(withFilters(NewContactsChart)),
};
