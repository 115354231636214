import { action, observable, computed, makeObservable } from 'mobx';
import { cloneDeep, omit, isEmpty } from 'lodash';

import errorFormatter from 'App/services/utilities/errorFormatter';
import { validate } from 'App/services/validationService';
import updateNestedValue, { updateNestedError } from 'App/services/utilities/updateNestedField';

export type Validators = {
  [key: string]: string,
}

export type Data = {
  [key: string]: any,
}

export type Errors = {
  [key: string]: string[]
}

export type FieldErrors = {
  [key: string]: string,
}

export interface IFieldRendererStore {
  data: Data;
  fieldErrors: FieldErrors;
  validators: Validators;
}

class FieldRendererStore implements IFieldRendererStore {
  data: Data = {};

  fieldErrors: FieldErrors = {};

  validators: Validators = {};

  constructor() {
    makeObservable(this, {
      data: observable,
      fieldErrors: observable,
      validators: observable,
      updateFieldValue: action.bound,
      setFieldsData: action.bound,
      setFieldsErrors: action.bound,
      setFieldError: action.bound,
      resetFieldError: action.bound,
      resetFieldsData: action.bound,
      setFieldValidators: action.bound,
      validateField: action.bound,
      validateForm: action.bound,
      hasErrors: computed,
    });
  }

  updateFieldValue(
    key: string, value: string | number | { [key: string]: any }, index?: number, prop?: string,
  ) {
    if (index === undefined && prop === undefined) {
      this.resetFieldError(key);
      (this.data as Data)[key] = value;
    } else {
      this.resetFieldError(key, index, prop);
      const values = (this.data as Data)[key] || [];
      const newValue = updateNestedValue(values, index, prop, value);
      (this.data as Data)[key] = newValue;
    }

    return (this.data as Data)[key];
  }

  setFieldsData(data: Data) {
    this.data = cloneDeep(data);
  }

  setFieldsErrors(errors: Errors) {
    this.fieldErrors = errorFormatter(errors) as FieldErrors;
  }

  setFieldError(
    key: string, value: string, index?: number, prop?: string,
  ) {
    if (index === undefined && prop === undefined) {
      this.fieldErrors = { ...this.fieldErrors, [key]: value };
    } else {
      const fieldErrors = (prop && (this.fieldErrors as FieldErrors)[prop]) || [];
      const errors = updateNestedError(fieldErrors, index, key, value);
      this.fieldErrors = {
        ...this.fieldErrors,
        ...(prop && { [prop]: errors }),
      };
    }
  }

  resetFieldError(key: string, index?: number, prop?: string) {
    if (index === undefined && prop === undefined) {
      this.fieldErrors = omit(this.fieldErrors, [key]);
    } else {
      this.fieldErrors = omit(this.fieldErrors, [`${key}[${index}].${prop}`]);
    }
  }

  resetFieldsData() {
    this.data = {};
    this.fieldErrors = {};
  }

  setFieldValidators(key: string, validators: string) {
    (this.validators as Validators)[key] = validators;
  }

  validateField(key: string) {
    const validators = (this.validators as Validators)[key];
    if (!validators) return true;
    const [fieldKey, prop] = key.split('__');

    if (prop) {
      const propData = (this.data as Data)[fieldKey] || [{}];
      const isValidProp = propData.reduce((
        acc: boolean, data: { [x: string]: any; }, index: number | undefined,
      ) => {
        const [isValid, errors] = validate(data[prop], validators);
        if (!isValid) {
          const [firstError] = errors;
          this.setFieldError(prop, firstError, index, fieldKey);
          return isValid;
        }
        return acc;
      }, true);

      return isValidProp;
    }

    const [isValid, errors] = validate((this.data as Data)[fieldKey], validators);
    if (!isValid) {
      const [firstError] = errors;
      this.setFieldError(fieldKey, firstError);
    }
    return isValid;
  }

  validateForm() {
    const invalidFields = Object.keys(this.validators)
      .map(key => this.validateField(key))
      .filter(isValid => !isValid);
    return invalidFields.length === 0;
  }

  get hasErrors() {
    return !isEmpty(this.fieldErrors);
  }
}

export default FieldRendererStore;
