import React, { PureComponent } from 'react';
import { number, oneOfType, string } from 'prop-types';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import GridComponentFactory from 'App/components/grid';
import PreviewMetaData from 'App/components/CommonOverlays/PreviewMetaData';
import modifiers from './modifiers';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
};

const tableConfig = contactId => ({
  tableUrl: `/finances/bank-accounts/?contact__id=${contactId}`,
  tableKey: 'contactBankAccounts',
});

const sizeOptions = [10, 20, 50, 100, 200];
const customColumns = [
  {
    key: 'accountDetails',
    headerName: 'Account Details',
  },
];

class BankAccountsTable extends PureComponent {
  render() {
    const { id } = this.props;
    const { GridComponent } = GridComponentFactory(tableConfig(id));
    return (
      <div>
        <GridComponent
          sizeOpitons={sizeOptions}
          modifiers={modifiers}
          customColumns={customColumns}
          height="calc(100vh - 450px)"
        />
        <PreviewMetaData />
      </div>
    );
  }
}

BankAccountsTable.propTypes = propTypes;
export default withErrorBoundary(BankAccountsTable);
