import React, { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { MultiActions, Row, Col, Space } from 'tc-biq-design-system';
import { object, bool, func } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { hasAccess } from 'App/services/permissionsService';

import './index.scss';
import Page from 'App/components/Page';
import SegmentTabs from 'Automation/components/SegmentTabs';
import SegmentDropdown from 'Automation/components/SegmentPreviewDropdown';
import { ContactCountCell } from 'Automation/components/SegmentCells';
import { StatusCell } from 'App/components/gridCellRenderers';

const text = {
  TITLE: 'Segment preview',
  CREATE_AD_AUDIENCE: 'Create Ad Audience',
  EDIT: 'Edit',
  CLONE: 'Clone',
  DELETE: 'Delete',
};

const propTypes = {
  match: object.isRequired,
  history: object.isRequired,
  segment: object.isRequired,
  loadingData: bool.isRequired,
  fetchSegment: func.isRequired,
  createAudience: func.isRequired,
  addCustomQuery: func.isRequired,
  removeSegment: func.isRequired,
  resetCustomQuery: func.isRequired,
  addSegmentFilter: func.isRequired,
};

const dropdownActions = [
  {
    icon: 'Facebook',
    displayName: 'Facebook',
    type: 'Facebook Ads',
  },
  {
    icon: 'GoogleAds',
    displayName: 'Google',
    type: 'Google Ads',
  },
];

const actions = ({ id, removeSegment, history }) => [
  {
    icon: 'Edit',
    label: text.EDIT,
    action: () => history.push(`/automation/segments/${id}`),
    visible: true,
  },
  {
    icon: 'Duplicate',
    label: text.CLONE,
    action: () => {
      history.push({ pathname: '/automation/segments/create', state: { id } });
    },
    visible: hasAccess('automations_segment', 'create'),
  },
  {
    icon: 'Delete',
    label: text.DELETE,
    action: () => {
      removeSegment(id, () => history.push({ pathname: '/automation/segments' }));
    },
    visible: hasAccess('automations_segment', 'delete'),
  },
].filter(({ visible }) => visible);

const HeaderCard = ({ segment, createNewAudience, removeSegment, history, addSegmentFilter }) => {
  const { id, name, description, status } = segment;
  return (
    <div className="segment-preview__header-card">
      <div>
        <p className="tc-heading-s">{name}</p>
        <p className="segment-preview__description">{description}</p>
      </div>
      <Space size={24} />
      <div style={{ display: 'flex', marginBottom: 'auto' }}>
        <ContactCountCell addSegmentFilter={addSegmentFilter} data={segment} />
        <StatusCell value={status} />
      </div>
      <div className="segment-preview__actions">
        <SegmentDropdown onClick={createNewAudience} events={dropdownActions} />
        <MultiActions actions={actions({ removeSegment, history, id })} />
      </div>
    </div>
  );
};

HeaderCard.propTypes = {
  createNewAudience: func,
  history: object,
  segment: object,
  removeSegment: func,
  addSegmentFilter: func,
};

HeaderCard.defaultProps = {
  createNewAudience: null,
  history: {},
  segment: null,
  removeSegment: null,
  addSegmentFilter: null,
};

const SegmentPreviewPage = ({
  match,
  segment,
  loadingData,
  fetchSegment,
  createAudience,
  history,
  addCustomQuery,
  removeSegment,
  addSegmentFilter,
  resetCustomQuery,
}) => {
  const { segmentId } = match.params;

  const fetchData = useCallback(async () => {
    await fetchSegment(segmentId);
  }, [fetchSegment, segmentId]);

  const createNewAudience = useCallback((item) => {
    createAudience(segmentId, segment.audiences, item.type, fetchData);
  }, [createAudience, segmentId, segment?.audiences, fetchData]);

  useEffect(() => {
    fetchData();
    addCustomQuery({ send_to_segment: segmentId });

    return () => resetCustomQuery();
  }, []);

  return (
    <Page title={text.TITLE}>
      {!loadingData && (
        <Row gutter={140}>
          <Col lg="100%">
            {segment && (
              <HeaderCard
                segment={segment}
                history={history}
                createNewAudience={createNewAudience}
                createAudience={createAudience}
                removeSegment={removeSegment}
                addSegmentFilter={addSegmentFilter}
              />
            )}
          </Col>
          <Col lg="100%">
            <SegmentTabs segmentId={segmentId} />
          </Col>
        </Row>
      )}
    </Page>
  );
};

SegmentPreviewPage.propTypes = propTypes;

export default inject(stores => ({
  fetchSegment: stores.segments.fetchSegment,
  loadingData: stores.segments.requestInProgress.fetchSegment,
  segment: stores.segments.segment,
  createAudience: stores.segments.createAudience,
  addCustomQuery: stores.tables.campaigns.addCustomQuery,
  resetCustomQuery: stores.tables.campaigns.resetCustomQuery,
  removeSegment: stores.segments.removeSegment,
  addSegmentFilter: stores.tables.contacts.filters.addSegmentFilter,
}))(observer(withRouter(SegmentPreviewPage)));
