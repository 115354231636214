import React from 'react';
import RenderIcon from 'App/components/RenderIcon';
import { Space, HyperLink } from 'tc-biq-design-system';
import classNames from 'classnames';

import { Link } from 'react-router-dom';

import * as RenderIcons from 'App/components/RenderIcon/components';

import './RenderIconNameCell.scss';

// eslint-disable-next-line
const RenderIconNameCell = ({ type, route, iconSize, backgroundSize }) => ({ value, data }) => {
  const hasIcon = !!RenderIcons[value];
  // eslint-disable-next-line
  const objectData = route ? (
    <HyperLink>
      <Link to={`${route}${data.id}`}>{value}</Link>
    </HyperLink>
  ) : (
    <span className="tc-paragraph-regular">{value}</span>
  );
  return (
    <div className="fiq-render-icon-cell">
      <div
        className={classNames('fiq-render-icon-cell__icon', {
          [`asset-${value}`]: hasIcon,
          'asset-default': !hasIcon,
        })}
        style={{ minWidth: backgroundSize || '24px', height: backgroundSize || '24px' }}
      >
        <RenderIcon icon={value} type={type} iconSize={iconSize || 24} />
      </div>
      <Space size={4} />
      {objectData}
    </div>
  );
};

export default RenderIconNameCell;
