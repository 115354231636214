import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Row, Space } from 'tc-biq-design-system';
import isEmpty from 'lodash/isEmpty';
import NoDataDash from 'App/components/NoDataDash';

import DisplayDetail from 'App/components/DisplayDetail';

const formatDate = date => moment(date, moment.ISO_8601).format('MMMM Do YYYY, \\at HH:mm');

const TradeDetails = inject(stores => ({
  created: stores.transactions.tradeData.created,
  symbol: stores.transactions.tradeData.symbol,
  amount: stores.transactions.tradeData.amount,
  side: stores.transactions.tradeData.side,
  rate: stores.transactions.tradeData.rate,
  externalId: stores.transactions.tradeData.external_id,
  wallet: stores.transactions.tradeData.wallet,
}))(
  observer(({ created, symbol, amount, rate, externalId, side, wallet }) => (
    <Fragment>
      <Row gutter={0}>
        <div style={{ display: 'flex', width: '100%' }}>
          <DisplayDetail header="External ID" value={externalId} />
          <DisplayDetail header="Symbol" value={symbol} />
          <DisplayDetail header="Amount" value={amount} />
          <DisplayDetail header="Rate" value={rate} />
          <DisplayDetail header="Side" value={side} />
          <DisplayDetail header="Time" value={formatDate(created)} />
        </div>
      </Row>
      <Space size={8} />
      <Row gutter={0}>
        <div style={{ display: 'flex', width: '100%' }}>
          <DisplayDetail
            header="Account"
            value={
              wallet && wallet.account && !isEmpty(wallet.account) ? (
                wallet.account.provider
              ) : (
                <NoDataDash />
              )
            }
          />
        </div>
      </Row>
    </Fragment>
  )),
);

export default TradeDetails;
