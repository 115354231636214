import { find } from 'lodash';

import stores from 'App/rootStore';
import http from 'App/services/http';

const fetchUserPermissions = () => http.get('/users/permissions/');

function hasAccess(objectType, value) {
  if (!objectType) return false;
  if (objectType === 'allow') return true;
  const { userPermissions } = stores.permissions;
  if (Array.isArray(userPermissions) && userPermissions.length === 0) return false;
  const permission = find(userPermissions, { object_type: objectType });
  return permission ? permission[value] : false;
}

export { hasAccess, fetchUserPermissions };
