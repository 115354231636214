import React from 'react';
import { SideCell, RouteCell, WalletCell } from 'App/components/gridCellRenderers';

const modifier = {
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/transactions/trades/'),
  },
  side: {
    cellRendererFramework: SideCell,
  },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => (
      <WalletCell data={value} />
    ),
  },
};

export default modifier;
