import React from 'react';

import Page from 'App/components/Page';
import WalletsTable, { tableConfig } from 'Transactions/components/WalletsTable';

const bread = [{ label: 'Transactions' }, { label: 'Wallets', route: '/transactions/wallets' }];
const exportDataOptions = { permission: 'finances_wallet', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

const text = {
  WALLETS: 'Wallets',
};

const WalletsPage = () => (
  <Page bread={bread} title={text.WALLETS} style={{ margin: '0 20px' }} exportDataOptions={exportDataOptions}>
    <WalletsTable />
  </Page>
);

export default WalletsPage;
