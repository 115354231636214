import React, { PureComponent } from 'react';
import { number, oneOfType, string } from 'prop-types';
import GridComponentFactory from 'App/components/grid';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import modifiers from './modifiers';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
};

const tableConfig = contactId => ({
  tableUrl: `/contacts/${contactId}/tickets/`,
  tableKey: 'contactTickets',
});

const customColumns = [
  {
    key: 'asigned_agent',
    headerName: 'Assigned Agent',
  },
];

const sizeOptions = [10, 20, 50, 100, 200];

class ContactTicketsTable extends PureComponent {
  render() {
    const { id } = this.props;
    const { GridComponent } = GridComponentFactory(tableConfig(id));
    return (
      <div>
        <GridComponent
          modifiers={modifiers}
          sizeOptions={sizeOptions}
          defaultPageSize={10}
          customColumns={customColumns}
          height="calc(100vh - 450px)"
        />
      </div>
    );
  }
}

ContactTicketsTable.propTypes = propTypes;

export default withErrorBoundary(ContactTicketsTable);
