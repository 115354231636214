import React, { Fragment, useContext } from 'react';
import { Dropdown, Button, Space } from 'tc-biq-design-system';

import SavedFiltersDropdown from './SavedFiltersDropdown';
import { SavedFiltersContext } from '../FiltersProvider';

const text = {
  SAVED_FILTERS: 'Saved filters',
};

const customList = [
  {
    item: () => <SavedFiltersDropdown />,
  },
];

const SavedFilters = () => {
  const {
    filtersStore: { activeFilter },
  } = useContext(SavedFiltersContext);
  const activeFilterName = activeFilter.filter ? activeFilter.filter.name : null;
  return (
    <Fragment>
      <Dropdown
        customHeader={toggle => (
          <Button style={{ display: 'flex' }} color="ghost" icon="Funnel" onClick={toggle}>
            <div style={{ maxWidth: '200px', marginLeft: '8px' }} className="truncate-text">
              {activeFilterName || text.SAVED_FILTERS}
            </div>
          </Button>
        )}
        customList={customList}
      />
      <Space size={8} />
    </Fragment>
  );
};

export default SavedFilters;
