import run from 'App/services/utilities/run';
import { notifier } from 'tc-biq-design-system';
import { fetchIntegration, initiateOAuthLogin } from './IntegrationsService';

const text = {
  SENDGRID_DEFAULT_FROM_EMAIL: 'SENDGRID_DEFAULT_FROM_EMAIL',
  AUTHORIZE_INTEGRATION_NOT_FOUND: 'Integration not found, please contact admin.',
  AUTHORIZE_ERROR: 'Failed to start the authorization!',
};

const fetchSendGridMail = async (callback) => {
  const [err, data] = await run(fetchIntegration('SendGrid'));
  if (err) return;
  const sendGridMail = data[0].config.find(item => item.key === text.SENDGRID_DEFAULT_FROM_EMAIL)
    .value;
  callback(sendGridMail);
};

const authorizeAdNetwork = async (adNetwork) => {
  let integration;
  switch (adNetwork) {
    case 'Facebook Ads':
      integration = 'FacebookAds';
      break;
    case 'Google Ads':
      integration = 'GoogleAds';
      break;
    default:
      notifier.error(text.AUTHORIZE_INTEGRATION_NOT_FOUND);
      return;
  }
  try {
    const { data } = await fetchIntegration(integration);
    const loginPath = data[0].config.find(field => field.oauth_login_path).oauth_login_path;
    const response = await initiateOAuthLogin(loginPath.replace(APP_ROOT, ''));
    window.location.href = response.data.auth_url;
  } catch (err) {
    notifier.error(text.AUTHORIZE_ERROR);
  }
};

export { fetchSendGridMail, authorizeAdNetwork };
