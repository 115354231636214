import React, { PureComponent, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { array, func, bool, number, oneOfType, string } from 'prop-types';
import { Space } from 'tc-biq-design-system';

import Loader from 'App/components/Loader';
import If from 'App/components/If';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import NoData from 'App/components/NoData';
import { hasAccess } from 'App/services/permissionsService';
import InfiniteScroll from 'App/components/InfiniteScroll/InfiniteScroll';
import { newPusher } from 'App/services/pusherService';
import Note from './Note';
import AddNote from './AddNote';

import './Notes.scss';

const propTypes = {
  fetchNotesData: func.isRequired,
  notesData: array.isRequired,
  requestInProgress: bool.isRequired,
  hasMore: bool.isRequired,
  id: oneOfType([number, string]).isRequired,
  userId: number.isRequired,
  fetchNote: func.isRequired,
  getToken: func.isRequired,
};

const text = {
  NO_NOTES: 'No results found.',
};


const Notes = inject(stores => ({
  fetchNotesData: stores.contact.notes.fetchNotesData,
  requestInProgress: stores.contact.notes.requestInProgress.notes,
  hasMore: stores.contact.notes.pagination.hasMore,
  notesData: stores.contact.notes.notesData,
  fetchNote: stores.contact.notes.fetchNote,
  userId: stores.loginStore.user.id,
  getToken: stores.loginStore.getToken,
}))(observer(
  class Notes extends PureComponent {
    async componentDidMount() {
      const { userId, id, fetchNote, getToken } = this.props;
      this.fetchNotesData();
      const token = await getToken();
      this.pusher = await newPusher(token);
      this.channel = this.pusher.subscribe(`private-note-${userId}`);
      this.channel.bind('new-note', (data) => {
        fetchNote(id, data.id);
      });
    }

    componentWillUnmount() {
      this.channel.unbind();
      this.pusher.unsubscribe(this.channel.name);
    }

    fetchNotesData = async (isOnScroll) => {
      const { fetchNotesData, id } = this.props;
      await fetchNotesData(id, isOnScroll);
    };

    render() {
      const { notesData, requestInProgress, id, hasMore } = this.props;
      const data = notesData.slice();

      return (
        <Fragment>
          <If condition={!requestInProgress && !data.length}>
            <Space size={10} />
            <NoData icon="Notes" iconColor="text-neutral-500" text={text.NO_NOTES} />
          </If>
          <InfiniteScroll
            fetchData={() => this.fetchNotesData(true)}
            isLoading={requestInProgress}
            hasMore={hasMore}
            style={{ maxHeight: '430px', overflowY: 'auto' }}
          >
            <div className="fiq-notes">
              {data.map((noteData, index) => (
                <Note key={noteData.id} {...noteData} contactId={id} noteIndex={index} />
              ))}
            </div>
          </InfiniteScroll>
          <If condition={requestInProgress}>
            <Loader visible />
          </If>
          <If condition={hasAccess('contacts_note', 'create')}>
            <AddNote />
          </If>
        </Fragment>
      );
    }
  },
));


Notes.wrappedComponent.propTypes = propTypes;

export default withErrorBoundary(Notes);
