import http from 'App/services/http';

const fetchTeamsData = () => http.get('/users/teams-tree/');

const fetchTeamData = id => http.get(`/users/teams/${id}/`);

const fetchTeamsOptions = () => http.options('/users/teams/');

const createTeam = data => http.post('/users/teams/', data);

const editTeam = (id, data) => http.patch(`/users/teams/${id}/`, data);

const deleteTeam = id => http.delete(`/users/teams/${id}/`);

export { fetchTeamsData, fetchTeamsOptions, createTeam, deleteTeam, fetchTeamData, editTeam };
