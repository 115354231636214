import React, { Fragment } from 'react';
import { Table, Icon } from 'tc-biq-design-system';
import { YesNoCell } from 'App/components/gridCellRenderers';
import { inject, observer } from 'mobx-react';
import { object } from 'prop-types';
import moment from 'moment';

import { hasAccess } from 'App/services/permissionsService';
import EditCustomPropertySidepanel, { openEditCustomPropertySidepanel } from './EditCustomPropertySidepanel';

const propTypes = {
  contactData: object.isRequired,
  contactOptions: object.isRequired,
};

// eslint-disable-next-line react/prop-types
const renderField = ({ field, name, description }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <span>
      {field}
      <span className="text-neutral-500">{` (${name})`}</span>
    </span>
    <span className="tc-micro-regular text-neutral-500">{description}</span>
  </div>
);

const renderActions = (data) => {
  if (!hasAccess('custom_properties_customproperty', 'update')) return null;
  return (
    <div style={{ float: 'right' }} onClick={() => openEditCustomPropertySidepanel(data)}>
      <Icon size="small" name="Edit" />
    </div>
  );
};

const renderValue = (value, type) => {
  if (value === null) return <div>-</div>;
  if (type === 'datetime') return <div>{moment(value).utc().format('DD-MM-YYYY HH:mm')}</div>;
  if (type === 'date') return <div>{moment(value).format('DD-MM-YYYY')}</div>;
  if (type === 'boolean') return <YesNoCell value={value} />;
  return <div>{value}</div>;
};

const columns = [
  {
    id: 0,
    title: 'Field',
    key: 'field',
    render: ({ field, name, description }) => renderField({ field, name, description }),
  },
  {
    id: 1,
    title: 'Value',
    key: 'value',
    render: ({ value, type }) => renderValue(value, type),
  },
  {
    id: 2,
    title: '',
    key: 'actions',
    render: data => renderActions(data),
  },
];

const formatCustomPropertiesData = (contactOptions, contactData) => Object.keys(contactOptions)
  .filter(key => contactOptions[key].custom_property && key)
  .map((item, index) => ({
    id: index,
    name: item,
    description: contactOptions[item].help_text,
    field: contactOptions[item].label,
    type: contactOptions[item].type,
    value: contactData[item],
  }));

const CustomPropertiesTable = ({ contactData, contactOptions }) => (
  <Fragment>
    <Table
      striped
      data={formatCustomPropertiesData(contactOptions, contactData)}
      cols={columns}
    />
    <EditCustomPropertySidepanel contactId={contactData.id} />
  </Fragment>
);

CustomPropertiesTable.propTypes = propTypes;

export default inject(stores => ({
  contactData: stores.contact.profile.contactData,
  contactOptions: stores.contact.profile.contactOptions,
}))(observer(CustomPropertiesTable));
