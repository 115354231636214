import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { object, bool, number } from 'prop-types';
import { Modal, Space, Code } from 'tc-biq-design-system';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { fetchEventDefinitionData } from 'Settings/Sections/Events/services/EventsService';
import Loader from 'App/components/Loader';
import NonFieldErrors from 'App/components/NonFieldErrors';

import './PreviewParameters.scss';

const SIDEPANEL_ID = 'PREVIEW_PARAMAETERS';

export const openPreviewParameters = event => openOverlay(SIDEPANEL_ID, { event });
export const closePreviewParameters = () => closeOverlay(SIDEPANEL_ID);

const propTypes = {
  visible: bool,
  parameters: object,
};

const text = {
  TITLE: 'Preview parameters',
  CLOSE: 'Close',
  EVENT: 'Event',
  PAYLOAD: 'Payload',
};

const defaultProps = {
  visible: false,
  parameters: null,
};

const normalizeData = data => Object.keys(data).reduce((acc, key) => {
  if (data[key].type === 'nested object') {
    return { ...acc, [key]: normalizeData(data[key].children) };
  }
  return { ...acc, [key]: data[key].type };
}, {});

const Payload = ({ eventId }) => {
  const [payloadData, setPayloadData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [errors, setErrors] = useState(null);
  const fetchEventMetadata = async () => {
    setLoadingData(true);
    try {
      const response = await fetchEventDefinitionData(eventId);
      setPayloadData(normalizeData(response.data.message_properties));
    } catch (e) {
      setErrors(e.data);
    } finally {
      setLoadingData(false);
    }
  };
  Payload.propTypes = { eventId: number.isRequired };

  useEffect(() => {
    fetchEventMetadata();
  }, []);

  return (
    <div className="payload">
      <Loader visible={loadingData} />
      {!loadingData && !errors && <Code data={payloadData} />}
      {errors && <NonFieldErrors errors={errors[0]} />}
    </div>
  );
};

const PreviewParameters = ({ visible, parameters }) => {
  if (!parameters || !parameters.event) return null;
  const { name, id } = parameters.event;
  return (
    <Modal
      icon="Code"
      closable
      hideFooter
      visible={visible}
      title={text.TITLE}
      onCloseIconClick={closePreviewParameters}
    >
      <div className="fiq-preview-parameters">
        <div className="text-neutral-500">{text.EVENT}</div>
        <div className="tc-pargraph-regular">{name}</div>
        <Space size={32} />
        <div className="tc-heading-s">{text.PAYLOAD}</div>
        <Space size={12} />
        <Payload eventId={id} />
      </div>
    </Modal>
  );
};

PreviewParameters.propTypes = propTypes;
PreviewParameters.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
}))(observer(PreviewParameters));
