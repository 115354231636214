import React, { useEffect, PureComponent } from 'react';
import { func, object, bool } from 'prop-types';
import { ComboxFooter } from 'tc-biq-design-system';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import Field from 'App/components/FieldRenderer';

import './AddNote.scss';

const text = {
  CONFIRM_TEXT: 'Send',
};

const propTypes = {
  closeCombox: func,
  error: object,
  addNote: func,
  requestInProgress: bool,
  data: object,
};

const defaultProps = {
  closeCombox: null,
  error: null,
  addNote: null,
  requestInProgress: false,
  data: {},
};


const NoteField = () => (
  <Field
    id="note"
    name="note"
    type="textarea"
    formId="contactNoteForm"
    className="tc-paragraph-regular"
    rows={20}
  />
);

const ObserverComboxFooter = inject(stores => ({
  error: stores.forms.contactNoteForm.fieldErrors.note,
  addNote: stores.contact.notes.addNote,
  requestInProgress: stores.contact.notes.requestInProgress.addNote,
  data: toJS(stores.forms.contactNoteForm.data),
}))(observer(
  class ObserverComboxFooter extends PureComponent {
    onSuccess = async () => {
      const { closeCombox, error, addNote } = this.props;
      await addNote('note');
      if (closeCombox && !error) closeCombox();
    };

    render() {
      const { requestInProgress, data } = this.props;
      const isDisabled = !data.note;

      return (
        <ComboxFooter
          onConfirm={this.onSuccess}
          isLoading={requestInProgress}
          confirmText={text.CONFIRM_TEXT}
          isDisabled={isDisabled}
        />
      );
    }
  },
));


ObserverComboxFooter.wrappedComponent.propTypes = propTypes;
ObserverComboxFooter.wrappedComponent.defaultProps = defaultProps;

const AddNote = inject(stores => ({
  resetFields: stores.forms.contactNoteForm.resetFieldsData,
}))(
  observer(({ resetFields, closeCombox }) => {
    // reset field value when component unmounts
    useEffect(() => resetFields());

    return (
      <div className="fiq-note-combox">
        <NoteField />
        <ObserverComboxFooter closeCombox={closeCombox} />
      </div>
    );
  }),
);

AddNote.wrappedComponent.propTypes = {
  resetFields: func.isRequired,
  closeCombox: func.isRequired,
};

export default AddNote;
