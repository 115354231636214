import React, { FC } from 'react';
import { Modal, Code, Button } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';

import { closeOverlay } from 'App/services/overlayService';

const text = { CLOSE: 'Close' };

export const SIDEPANEL_ID = 'PREVIEW_META_DATA';

const close = () => closeOverlay(SIDEPANEL_ID);

const renderFooter = () => (
  <Button onClick={close} color="ghost">
    {text.CLOSE}
  </Button>
);

export interface PreviewMetaDataProps {
  visible?: boolean;
  parameters?: any;
}

const PreviewMetaData: FC<PreviewMetaDataProps> = ({ visible, parameters }) => {
  if (!parameters) return null;
  const { data, title } = parameters;
  return (
    <Modal
      visible={visible}
      title={title}
      closable
      icon="Code"
      footerRender={renderFooter}
      onCloseIconClick={close}
    >
      <div style={{ maxHeight: '500px', overflow: 'auto' }}>
        <Code data={data} />
      </div>
    </Modal>
  );
};

export default inject((stores: any) => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
}))(observer(PreviewMetaData));
