import React, { Fragment, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { object, func, bool } from 'prop-types';

import QueryBuilderFactory from 'App/components/QueryBuilderFactory';
import Loader from 'App/components/Loader';

const propTypes = {
  triggerEvent: object,
  fetchEventMetadata: func.isRequired,
  loadingMetadata: bool.isRequired,
  resetQueries: func.isRequired,
};

const defaultProps = {
  triggerEvent: null,
};

const { QueryBuilder } = QueryBuilderFactory({ storeKey: 'rules' });

const RuleTriggerQueryBuilder = ({
  triggerEvent,
  fetchEventMetadata,
  loadingMetadata,
  resetQueries,
}) => {
  const fetchMetadata = async () => {
    await fetchEventMetadata(triggerEvent.value);
  };

  useEffect(() => {
    if (!triggerEvent) {
      resetQueries();
    }
    if (triggerEvent && !loadingMetadata) {
      fetchMetadata();
    }
  }, [triggerEvent]);

  return (
    <Fragment>
      <Loader visible={loadingMetadata} />
      {!loadingMetadata && triggerEvent && (
        <QueryBuilder style={{ marginTop: '0', padding: '0', overflow: 'visible' }} />
      )}
    </Fragment>
  );
};

RuleTriggerQueryBuilder.propTypes = propTypes;
RuleTriggerQueryBuilder.defaultProps = defaultProps;

export default inject(stores => ({
  triggerEvent: stores.forms.ruleForm.data.trigger_event,
  fetchEventMetadata: stores.rules.fetchEventMetadata,
  loadingMetadata: stores.rules.requestInProgress.eventMetadata,
  resetQueries: stores.rules.queryBuilder.resetQueries,
}))(observer(RuleTriggerQueryBuilder));
