import React from 'react';
import ChangePassword from './ChangePassword';

export default {
  label: 'Security',
  id: 'change-password',
  Component: () => <ChangePassword />,
  visibility: ({ userId, loggedUserId }) => userId === loggedUserId,
  permission: 'users_user',
};
