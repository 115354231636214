import React from 'react';
import { MultiInput } from 'tc-biq-design-system';
import { array, func, string } from 'prop-types';

const propTypes = {
  value: array,
  onChange: func.isRequired,
  placeholder: string,
};

const defaultProps = {
  value: [],
  placeholder: '',
};

const NumberMulti = ({ value, onChange, placeholder }) => {
  const updateValue = (entries) => {
    onChange(entries.map(entry => entry.value || entry));
  };

  return <MultiInput placeholder={placeholder} value={value} onChange={updateValue} />;
};

NumberMulti.propTypes = propTypes;
NumberMulti.defaultProps = defaultProps;

export default NumberMulti;
