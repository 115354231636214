import React from 'react';
import { Dropdown } from 'tc-biq-design-system';
import { func, array } from 'prop-types';

const propTypes = {
  onClick: func.isRequired,
  events: array.isRequired,
};

const text = {
  MORE: '+ More',
};

const customHeader = toggle => (
  <div onClick={toggle} style={{ width: '50px', cursor: 'pointer' }}>
    <span className="text-primary-500 tc-paragraph-strong">{text.MORE}</span>
  </div>
);

const formatEventList = (onClick, events) => events.map(event => ({
  item: action => (
    <div onClick={action} className="fiq-activity-stream__add-more-actions">
      <span className="text-primary-500">{event.displayName}</span>
    </div>
  ),
  onClick: () => onClick(event),
}));

const AddMore = ({ onClick, events }) => {
  const customList = formatEventList(onClick, events);
  return <Dropdown customHeader={customHeader} customList={customList} />;
};

AddMore.propTypes = propTypes;

export default AddMore;
