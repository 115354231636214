import React from 'react';
import { Icon, MultiActions, Row, Col, Space, Tooltip } from 'tc-biq-design-system';
import { func, object, string } from 'prop-types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import { UserCell, ContactCell } from 'App/components/gridCellRenderers';
import { openOverlay } from 'App/services/overlayService';
import { ADD_EDIT_SIDEPANEL_ID } from 'Tasks/components/sidepanels';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import NoDataDash from 'App/components/NoDataDash';
import { openPreviewTask } from 'App/components/CommonOverlays/PreviewTask';

const propTypes = {
  accordionId: string.isRequired,
  deleteTask: func.isRequired,
  completeTask: func.isRequired,
  task: object.isRequired,
  fetchData: func.isRequired,
};

const text = {
  TODAY: 'Today',
  EDIT: 'Edit task',
  DUPLICATE: 'Duplicate',
  DELETE: 'Delete',
  STATE: 'Change task state',
  MARK_AS_DONE: 'Mark as done',
  REOPEN: 'Reopen',
};

const actions = (data, deleteTask, completeTask, fetchData, accordionId) => [
  {
    action: task => openOverlay(ADD_EDIT_SIDEPANEL_ID, {
      task,
      type: 'edit',
      onSuccess: fetchData,
    }),
    data,
    label: text.EDIT,
    icon: 'Edit',
  },
  {
    action: task => openOverlay(ADD_EDIT_SIDEPANEL_ID, {
      task,
      type: 'clone',
      onSuccess: fetchData,
    }),
    data,
    label: text.DUPLICATE,
    icon: 'Duplicate',
  },
  {
    action: task => deleteTask(task.id, fetchData),
    data,
    label: text.DELETE,
    icon: 'Delete',
  },
  {
    action: task => completeTask(task.id, accordionId),
    data,
    label: data.completed ? text.REOPEN : text.MARK_AS_DONE,
    icon: 'Checkmark',
  },
];

// eslint-disable-next-line
const DueDate = ({ value }) => {
  const dueDate = moment(new Date(value));
  if (!dueDate.isValid()) return null;
  const date = dueDate.format('MMM DD YYYY');
  const isToday = date === moment().format('MMM DD YYYY');
  const time = dueDate.format('HH:mm \\G\\MT(Z\\)');
  return (
    <div className="fiq-tasks__task-due-date">
      <span className="tc-paragraph-regular">{isToday ? text.TODAY : date}</span>
      <span className="tc-paragraph-regular text-neutral-500">{time}</span>
    </div>
  );
};

const TaskRow = inject(stores => ({
  deleteTask: stores.tasks.deleteTask,
  completeTask: stores.tasks.completeTask,
}))(
  observer(({ task, deleteTask, completeTask, fetchData, accordionId }) => {
    const { title, assigned_to, connected_to, due_date, id, description, completed } = task;
    const markAsComplete = () => completeTask(id, accordionId);
    const previewTask = () => openPreviewTask(task);
    return (
      <div className="fiq-tasks__task">
        <Row gutter={0} style={{ width: '100%' }}>
          <Col xs="3%" gutter={0}>
            <div onClick={markAsComplete} className="fiq-tasks__task-complete">
              <Tooltip title={completed ? text.REOPEN : text.MARK_AS_DONE}>
                <Icon
                  size="large"
                  name="Approve"
                  colorName={completed ? 'text-status01-500' : 'text-neutral-500'}
                />
              </Tooltip>
            </div>
          </Col>
          <Col xs="49%" gutter={0}>
            <div className="align-center">
              <Space size={4} />
              <div className="fiq-tasks__task-desc">
                <div onClick={previewTask} className="tc-paragraph-regular">
                  <div className="like-a-link">{title}</div>
                </div>
                <div className="tc-micro-regular text-neutral-700  fiq-tasks__task-desc-body">
                  {description}
                </div>
              </div>
            </div>
          </Col>
          <Col xs="15%" gutter={0}>
            <div className="align-center">
              {assigned_to ? (
                <UserCell
                  fullName={extractDisplayName(assigned_to)}
                  id={assigned_to.id}
                  avatar={assigned_to.avatar}
                />
              ) : (
                <NoDataDash />
              )}
            </div>
          </Col>
          <Col xs="15%" gutter={0}>
            <div className="align-center">
              {connected_to ? (
                <ContactCell
                  fullName={extractDisplayName(connected_to)}
                  id={connected_to.id}
                  avatar={connected_to.avatar}
                />
              ) : (
                <NoDataDash />
              )}
            </div>
          </Col>
          <Col xs="15%" gutter={0}>
            <div style={{ width: '100%' }}>
              <DueDate value={due_date} />
            </div>
          </Col>
          <Col xs="3%" gutter={0}>
            <div className="align-center">
              <MultiActions
                key={task.completed + task.id}
                icon="More"
                actions={actions(task, deleteTask, completeTask, fetchData, accordionId)}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }),
);

TaskRow.wrappedComponent.propTypes = propTypes;
TaskRow.displayName = 'TaskRow';

export default TaskRow;
