import React, { Component } from 'react';
import { string } from 'prop-types';
import { inject, observer } from 'mobx-react';

export default function withFilters(WrappedComponent, { removeDateFilters } = {}) {
  const propTypes = {
    startDate: string.isRequired,
    endDate: string.isRequired,
  };

  class FilterComponent extends Component {
    componentDidMount() {
      this.prepareQuery();
    }

    componentDidUpdate(prevProps) {
      const startDateChanged = this.isChanged(prevProps, 'startDate');
      const endDateChanged = this.isChanged(prevProps, 'endDate');

      if (startDateChanged || endDateChanged) this.onFilterChange();
    }

    onFilterChange = () => {
      this.prepareQuery();
    }

    prepareQuery = async () => {
      const { startDate, endDate } = this.props;
      let query = [];

      if (!removeDateFilters) {
        query = {
          start_date: startDate,
          end_date: endDate,
        };
      }

      this.wrappedComponent.onQueryReady(query);
    }

    // eslint-disable-next-line react/destructuring-assignment
    isChanged = (prevProps, key) => prevProps[key] !== this.props[key]

    render() {
      return (
        <WrappedComponent
          ref={(instance) => { this.wrappedComponent = instance; }}
          {...this.props}
        />
      );
    }
  }

  FilterComponent.propTypes = propTypes;


  return inject(stores => ({
    startDate: stores.dashboardStore.dashboardInterval.startDate,
    endDate: stores.dashboardStore.dashboardInterval.endDate,
  }))(observer(FilterComponent));
}
