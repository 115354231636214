import React from 'react';
import { Table } from 'tc-biq-design-system';
import { array } from 'prop-types';
import { RouteCell } from 'App/components/gridCellRenderers';

const columns = [
  {
    key: 'template_name',
    title: 'Template name',
    render: ({ template_id, template_name }) => RouteCell('/communications/templates/')({ hrefValue: template_id, value: template_name }),
  },
  {
    key: 'count',
    title: 'Sent',
  },
  {
    key: 'delivered',
    title: 'Delivered',
  },
  {
    key: 'opened',
    title: 'Opened',
  },
  {
    key: 'clicked',
    title: 'Clicked',
  },
  {
    key: 'unsubscribed',
    title: 'Unsubscribed',
  },
];

const TablePerTemplate = ({ data }) => (
  <div className="fiq-campaign-overview__content">
    <Table
      striped
      data={data.map(item => ({ ...item, id: item.template_id }))}
      cols={columns}
    />
  </div>
);

TablePerTemplate.propTypes = { data: array.isRequired };

export default TablePerTemplate;
