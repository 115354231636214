import React, { useState } from 'react';
import { object } from 'prop-types';
import { ComboxFooter } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { callContact } from 'Contacts/services/contactsService';
import run from 'App/services/utilities/run';
import displayError from 'App/services/utilities/displayError';

const text = {
  CONFIRM_TEXT: 'Call',
  CONTENT_TEXT: number => `Contacts phone number: ${number}`,
};

const PhoneCall = ({ contactData: { phone, id } }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const onCall = async () => {
    setIsLoading(true);
    const [err] = await run(callContact(id));
    if (err) {
      displayError(err.response.data);
      setIsDisabled(true);
    }
    setTimeout(() => { setIsLoading(false); }, 500);
  };

  return (
    <div>
      <div>{text.CONTENT_TEXT(phone)}</div>
      <ComboxFooter
        onConfirm={onCall}
        confirmText={text.CONFIRM_TEXT}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </div>
  );
};

PhoneCall.propTypes = {
  contactData: object.isRequired,
};

export default inject(stores => ({
  contactData: stores.contact.profile.contactData,
}))(observer(PhoneCall));
