import React, { PureComponent, Fragment } from 'react';
import classNames from 'classnames';
import { Select, Space, Icon, Button } from 'tc-biq-design-system';
import isEmpty from 'lodash/isEmpty';
import { object, func, bool } from 'prop-types';

import FilterInput from './FilterInput';
import { isNonValue, isAnniversary } from './filterStoreUtils';
import formatOperators from '../../services/utilities/formatOperators';

const propTypes = {
  fields: object.isRequired,
  applyFilter: func.isRequired,
  closeDropdown: func.isRequired,
  hidden: bool.isRequired,
  initialValues: object,
};

const defaultProps = {
  initialValues: {},
};

const text = {
  ADD_FILTER: 'Add filter',
  PLACEHOLDER: 'Select field',
  APPLY_FILTER: 'Apply filter',
  ADD_NEW_FILTER: 'Add new filter',
  VALUE_ERROR: 'This field is required',
};

// eslint-disable-next-line
const FieldInput = ({ fields, selectedField, onChange }) => {
  const fieldOptions = Object.keys(fields).map(key => ({
    label: fields[key].label,
    value: key,
    type: fields[key].type,
  }));

  return (
    <Select
      placeholder={text.PLACEHOLDER}
      clearable={false}
      type="search"
      valueKey="value"
      value={selectedField}
      options={fieldOptions}
      onChange={onChange}
      joinValues
    />
  );
};

// eslint-disable-next-line
const OperatorField = ({ selectedOperator, operators, onChange }) => {
  return (
    <Select
      placeholder="Select operator... "
      clearable={false}
      type="search"
      valueKey="value"
      value={selectedOperator}
      onChange={onChange}
      options={operators}
      joinValues
    />
  );
};

function isReactSelect(element) {
  if (!element) return false;
  const isCloseIcon = element.classList.contains('Select-value-icon');
  const isReactSelectEl = element.id.indexOf('react-select') !== -1;
  if (isReactSelectEl || isCloseIcon) {
    return true;
  }
  return false;
}

function showApplyFilterButton(operator, filterValue) {
  let hasFilterValue;
  if (typeof filterValue === 'object') {
    hasFilterValue = !isEmpty(filterValue);
  } else if (typeof filterValue === 'boolean' || typeof filterValue === 'number') {
    hasFilterValue = true;
  } else {
    hasFilterValue = !!filterValue;
  }
  return (
    operator && (hasFilterValue || isNonValue(operator.value) || isAnniversary(operator.value))
  );
}

class AddFilterDropdown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedField: null,
      selectedOperator: null,
      operators: [],
      filterValue: '',
    };
  }

  componentDidUpdate() {
    const { hidden } = this.props;
    if (hidden) {
      document.removeEventListener('mousedown', this.handleClick, false);
    } else {
      document.addEventListener('mousedown', this.handleClick, false);
    }
  }

  setField = (selectedField) => {
    const { fields } = this.props;
    const operators = formatOperators(selectedField, fields);
    this.setState({ selectedField, operators, selectedOperator: null, filterValue: '' });
    const hasExactOperator = operators.find(operator => operator.value === 'exact');
    if (hasExactOperator) {
      this.setOperator({ label: 'is equal to', related_models: null, value: 'exact' });
    }
  };

  setOperator = (selectedOperator) => {
    this.setState({ selectedOperator, filterValue: '' });
  };

  setFilterValue = (value) => {
    this.setState({ filterValue: value });
  };

  setInitialValues = () => {
    const { selectedField, operator, filterValue } = this.state;
    const { initialValues } = this.props;
    if (!selectedField && !operator && !filterValue) {
      this.setField(initialValues.field);
      this.setOperator(initialValues.operator);
      this.setFilterValue(initialValues.value);
    }
  };

  applyFilter = () => {
    const { applyFilter } = this.props;
    const { selectedField, selectedOperator, filterValue } = this.state;
    applyFilter({
      field: selectedField,
      operator: selectedOperator,
      value: filterValue,
    });
    this.close();
  };

  resetFilter = () => {
    this.setState({
      selectedField: null,
      selectedOperator: null,
      operators: [],
      filterValue: '',
    });
  };

  close = () => {
    const { closeDropdown } = this.props;
    this.resetFilter();
    closeDropdown();
  };

  handleClick = ({ target }) => {
    if (this.filterDropdownRef?.contains(target) || isReactSelect(target)) return;
    this.close();
  };

  render() {
    const { selectedField, selectedOperator, operators, filterValue } = this.state;
    const { fields, hidden, initialValues } = this.props;
    if (isEmpty(fields)) return null;
    if (!isEmpty(initialValues)) this.setInitialValues();

    return (
      <div
        ref={(node) => {
          this.filterDropdownRef = node;
        }}
        className={classNames('fiq-filters__add-filter__filter-dropdown', {
          hidden,
        })}
      >
        <div className="fiq-filters__add-filter__filter-dropdown__heading">
          <span className="tc-paragraph-strong">{text.ADD_NEW_FILTER}</span>
          <span onClick={this.close}>
            <Icon name="Close" size="small" />
          </span>
        </div>
        <FieldInput
          fields={fields}
          selectedField={selectedField}
          onChange={this.setField}
          initialValue={initialValues.field}
        />
        <Space size={12} />
        {!!selectedField && (
          <OperatorField
            selectedOperator={selectedOperator}
            operators={operators}
            onChange={this.setOperator}
          />
        )}
        <Space size={12} />
        {!!selectedOperator && (
          <Fragment>
            <FilterInput
              fieldLabel={selectedField.label}
              value={filterValue}
              selectedField={selectedField}
              fields={fields}
              operator={selectedOperator}
              onChange={this.setFilterValue}
              relatedModels={selectedOperator.related_models}
            />
            <Space size={12} />
          </Fragment>
        )}
        {showApplyFilterButton(selectedOperator, filterValue) && (
          <Fragment>
            <Button style={{ margin: '0' }} onClick={this.applyFilter}>
              {text.APPLY_FILTER}
            </Button>
            <Space size={12} />
          </Fragment>
        )}
      </div>
    );
  }
}

AddFilterDropdown.propTypes = propTypes;
AddFilterDropdown.defaultProps = defaultProps;

export default AddFilterDropdown;
