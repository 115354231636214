import React, { Component } from 'react';
import { notifier } from 'tc-biq-design-system';
import withFilters from 'App/components/hoc/withFilters';

import PieChart from 'Dashboard/components/layouts/PieChart';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import run from 'App/services/utilities/run';
import getGranularity from 'App/services/utilities/getGranularity';
import { formatPieChartData } from 'App/services/utilities/chartUtils';

const text = {
  TITLE: 'Conversion from lead to live',
  DESCRIPTION: 'Total number of contacts who moved from lead to live in selected time period.  Only leads that came through a Publisher are shown.',
  ERROR_MESSAGE: 'Something went wrong',
};

const METRIC = 'contact_live_conversions';

class LeadToLiveChart extends Component {
  constructor() {
    super();
    this.state = {
      fetchInProgress: false,
      data: [],
    };
  }

  onQueryReady = async (query) => {
    const { start_date, end_date } = query;

    const granularity = getGranularity(start_date, end_date);

    const formattedQuery = {
      metric: METRIC,
      query: {
        start_of_interval: start_date,
        end_of_interval: end_date,
        granularity,
        dimensions: ['publisher'],
      },
    };

    this.setState({
      fetchInProgress: true,
    });

    const [err, data] = await run(fetchMetricsData(formattedQuery));

    if (err) {
      notifier.error(text.ERROR_MESSAGE);
    } else {
      const filteredData = data.filter(item => item.publisher && item.count_lead_to_live);
      const chartData = formatPieChartData(filteredData, 'publisher', 'count_lead_to_live');
      this.setState({
        fetchInProgress: false,
        data: chartData,
      });
    }
  };

  render() {
    const { fetchInProgress, selectedDimension, data } = this.state;

    return (
      <PieChart
        selectedDimension={selectedDimension}
        title={text.TITLE}
        description={text.DESCRIPTION}
        dimensions={[]}
        inProgress={fetchInProgress}
        onChangeDimension={this.onChangeDimension}
        data={data}
        color="#6DABF2"
      />
    );
  }
}

export default {
  component: withFilters(LeadToLiveChart),
};
