import React, { useEffect, useState, useCallback, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { bool, func, object, array } from 'prop-types';
import { Space, Icon } from 'tc-biq-design-system';
import { pick } from 'lodash';

import {
  fetchCustomEventDefinitionFields,
} from 'Settings/Sections/Events/services/EventsService';
import { withRouter } from 'react-router-dom';

import Field, { fieldsResolverObject } from 'App/components/FieldRenderer';
import AddEventAttribute from 'App/components/AddEventAttribute';
import SidepanelFooter from 'App/components/SidepanelFooter';

import fieldsFactory from '../../../../../App/components/FieldRenderer/fieldsFactory';
import toSnakeCase from '../../../../../App/services/utilities/toSnakeCase';

import './CustomEventForm.scss';

const Fields = fieldsFactory('customEventForm');

const text = {
  BUTTON_LABELS: {
    confirm: 'Create',
    cancel: 'Cancel',
  },
  TEMPLATE_LABEL: 'Template',
  CUSTOM_EVENT: 'Custom Event',
  PROPERTIES: 'Properties',
  LABEL: 'Label',
  NAME: 'Name',
  TYPE: 'Type',
  REQUIRED: 'Required',
  ADD_PROPERTY: 'Add Property',
};

const property_fields = ['properties__label', 'properties__name', 'properties__type', 'properties__required'];

const formatAttributes = options => ({
  payload: {
    type: 'nested object',
    children: {
      data: {
        type: 'nested object',
        children: {
          ...options.reduce((acc, item) => ({
            ...acc,
            [item.name]: ({ ...item, value: item.name }),
          }), {}),
        },
      },
    },
  },
});

const CustomEventForm = ({
  updateFieldValue,
  createCustomEvent,
  history,
  resetFieldsData,
  propertiesData,
  createInProgress,
  validateForm,
}) => {
  const [customEventFields, setFields] = useState(null);
  const [propertiesFields, setPropertiesFields] = useState(null);
  const [attributes, setAttributes] = useState({});

  useEffect(() => {
    async function fetchFields() {
      const response = await fetchCustomEventDefinitionFields();
      const fields = response.data.actions.POST;
      const formatedFields = fieldsResolverObject(fields);
      setFields(formatedFields);
      setPropertiesFields(pick(formatedFields, property_fields));
    }
    fetchFields();
    return resetFieldsData();
  }, []);

  useEffect(() => {
    const formatedAttributes = formatAttributes(propertiesData);
    setAttributes(formatedAttributes);
  }, [propertiesData]);

  const onSave = () => {
    if (!validateForm()) return;
    createCustomEvent(history);
  };

  const onDiscard = () => history.push('/settings/events');

  const ref = useRef({
    isNameEdited: {},
  });

  const handleOnChange = useCallback(({ property, key, index, value }) => {
    if (key === 'label' && !ref.current.isNameEdited[index]) {
      const nameValue = toSnakeCase(value);
      updateFieldValue(property, nameValue, index, 'name');
    }

    if (key === 'name') {
      ref.current.isNameEdited[index] = true;
    }

    updateFieldValue(property, value, index, key);
  }, []);

  if (!customEventFields) return null;

  return (
    <div className="fiq-custom-event-definition__form-wrapper">
      <div className="fiq-custom-event-definition__form">
        <div className="fiq-custom-event-definition__form-section">
          <div className="fiq-custom-event-definition__icon-name">
            <div className="fiq-custom-event-definition__icon">
              <Icon name="Action" colorName="text-primary-500" />
            </div>
            <Space size={8} />
            <div className="tc-heading-s">{text.CUSTOM_EVENT}</div>
          </div>
          <Space size={24} />
          <Field {...customEventFields.name} formId="customEventForm" />
        </div>

        <div className="fiq-custom-event-definition__form-section">
          <div className="fiq-custom-event-definition__icon-name">
            <div className="tc-heading-s">{text.PROPERTIES}</div>
          </div>
          <Space size={16} />
          <Fields
            fields={propertiesFields}
            name="properties"
            onChange={handleOnChange}
            addLabel={text.ADD_PROPERTY}
            validate={{
              name: 'required',
              type: 'required',
            }}
            renderRow={({
              properties__label, properties__name,
              properties__type, properties__required,
            }) => (
              <>
                <div className="fiq-custom-event-definition__prop">
                  <Field {...properties__label} label={text.LABEL} />
                </div>
                <div className="fiq-custom-event-definition__prop">
                  <Field {...properties__name} label={text.NAME} validate="required" />
                </div>
                <div className="fiq-custom-event-definition__prop">
                  <Field {...properties__type} multi={false} label={text.TYPE} />
                </div>
                <div className="fiq-custom-event-definition__prop">
                  <Field {...properties__required} description={text.REQUIRED} />
                </div>
              </>
            )}
          />
        </div>

        <div className="fiq-custom-event-definition__form-section">
          <div className="fiq-custom-event-definition__icon-name">
            <div className="tc-heading-s">{text.TEMPLATE_LABEL}</div>
          </div>
          <Space size={16} />
          <AddEventAttribute updateFieldValue={updateFieldValue} fieldKey="template" attributes={attributes}>
            <Field {...customEventFields.template} type="textarea" formId="customEventForm" />
          </AddEventAttribute>
        </div>

        <div className="fiq-custom-event-definition__form-section">
          <Field {...customEventFields.is_activity_visible} formId="customEventForm" />
        </div>

        <div className="fiq-custom-event-definition__form-section--align-right">
          <SidepanelFooter
            submitInProgress={createInProgress}
            execute={onSave}
            close={onDiscard}
            cancelColor="ghost"
            confirmColor="primary"
            buttonLabels={text.BUTTON_LABELS}
          />
        </div>
      </div>
    </div>
  );
};

CustomEventForm.propTypes = {
  updateFieldValue: func.isRequired,
  createCustomEvent: func.isRequired,
  history: object.isRequired,
  resetFieldsData: func.isRequired,
  createInProgress: bool.isRequired,
  propertiesData: array,
  validateForm: func.isRequired,
};

CustomEventForm.defaultProps = {
  propertiesData: [],
};


export default inject(stores => ({
  updateFieldValue: stores.forms.customEventForm.updateFieldValue,
  propertiesData: stores.forms.customEventForm.data.properties,
  createCustomEvent: stores.events.createCustomEvent,
  resetFieldsData: stores.forms.customEventForm.resetFieldsData,
  validateForm: stores.forms.customEventForm.validateForm,
  createInProgress: stores.events.requestInProgress.createDerivedEvent,
}))(observer(withRouter(CustomEventForm)));
