import React, { FC, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import GridComponentFactory from 'App/components/grid';
import { hasAccess } from 'App/services/permissionsService';
import { openOverlay } from 'App/services/overlayService';
import CreateEditApiKey from 'Settings/Sections/ApiKeys/components/sidepanels/CreateEditApiKey';
import ApiKeysModal from 'Settings/Sections/ApiKeys/components/sidepanels/ApiKeysModal';
import modifiers from './modifiers';

const customColumns = [
  {
    key: 'actions',
    headerName: '',
  },
];

const listToOmit = ['created', 'updated'];

const tableConfig = {
  tableUrl: '/api-keys/',
  tableKey: 'apiKeys',
};

const text = {
  DELETE_API_KEY: 'Are you sure you want to delete this API key?',
  CONFIRM: 'Delete',
  CANCEL: 'No',
};

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const singleActions = ({ onEdit, onDelete }) => [
  {
    icon: { name: 'Edit', color: 'text-neutral-900', size: 'small' },
    onClick: onEdit,
    label: 'Edit',
    visibility: hasAccess('users_apikey', 'update'),
  },
  {
    icon: { name: 'Delete', color: 'text-neutral-900', size: 'small' },
    onClick: onDelete,
    popconfirm: {
      type: 'destructive',
      placement: 'bottom',
      icon: 'Delete',
      label: text.DELETE_API_KEY,
      buttonLabels: { cancel: text.CANCEL, confirm: text.CONFIRM },
    },
    visibility: hasAccess('users_apikey', 'delete'),
  },
].filter(action => action.visibility);


const ApiKeysTable = ({ deleteApiKey = () => null }) => {
  
  const editApiKey = ({ id }) => {
    openOverlay('ADD_EDIT_API_KEY', { id, type: 'edit' });
  };

  const deleteKey = async ({ id }) => {
    await deleteApiKey(id);
    await gridActions.fetchTableData();
  };

  const onSuccess = gridActions.fetchTableData;

  return (
    <Fragment>
      <GridComponent
        modifiers={modifiers(singleActions({
          onEdit: editApiKey,
          onDelete: deleteKey,
        }))}
        customColumns={customColumns}
        listToOmit={listToOmit}
      />
      <CreateEditApiKey onSuccess={onSuccess} />
      <ApiKeysModal />
    </Fragment>
  );
};

export default inject((stores) => ({
  deleteApiKey: stores.apiKeys.deleteApiKey,
}))(observer(ApiKeysTable));
