import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { observer, inject } from 'mobx-react';

import { getOperatorObject } from 'App/components/Filters/filterStoreUtils';
import QuickFilter from '../../components/QuickFilter/QuickFilter';


const getQuickFilters = (filtersStore, modifiers, tableKey, actions) => {
  const { fields } = filtersStore || {};
  if (isEmpty(fields) || isEmpty(modifiers)) return {};
  return Object.keys(modifiers).reduce((acc, key) => {
    const modifier = modifiers[key];
    const field = fields[key];

    if (!field) return acc;
    if (!modifier) return acc;
    if (!modifier.quickFilter) return acc;

    const { operator } = modifier.quickFilter;
    const operatorObj = getOperatorObject(field, operator);

    if (!operatorObj) return acc;

    const selectedField = formatFilterFiled(key, field);

    const ObservedQuickFilter = inject(stores => ({
      activeFilters: stores.tables[tableKey].filters.activeFilters,
    }))(observer(QuickFilter));

    return {
      ...acc,
      [key]: (
        <ObservedQuickFilter
          field={selectedField}
          operator={operatorObj}
          fields={fields}
          applyFilter={actions.applyFilter}
          removeFilter={actions.removeFilter}
        />
      ),
    };
  }, {});
};

const formatFilterFiled = (key, field) => ({
  label: field.label,
  type: field.type,
  value: key,
});

export default getQuickFilters;
