import { action, runInAction, observable, makeObservable } from 'mobx';

import { fetchContactKyc } from 'Contacts/services/contactsService';

export default class KycStore {
  kycData = [];

  requestInProgress = false;

  errors = null;

  constructor() {
    makeObservable(this, {
      kycData: observable,
      requestInProgress: observable,
      errors: observable,
      fetchKycData: action.bound,
    });
  }

  async fetchKycData(id) {
    this.requestInProgress = true;
    try {
      const response = await fetchContactKyc(id);
      runInAction(() => {
        this.kycData = response.data.results;
        this.requestInProgress = false;
      });
    } catch (err) {
      runInAction(() => {
        this.requestInProgress = false;
        this.errors = err.data;
      });
    }
  }
}
