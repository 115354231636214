import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ActivityBox, Space, Popconfirm, Button, HyperLink } from 'tc-biq-design-system';
import { object, string, func, oneOfType, number, bool, shape, any } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { UserCell } from 'App/components/gridCellRenderers';
import { formatDate } from 'App/services/utilities/dateGroupUtils';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import { hasAccess } from 'App/services/permissionsService';

import './CommentCard.scss';
import { searchUsers } from 'Settings/Sections/Users/services/UsersService';
import CommentField from 'App/components/CommentField/CommentField';

const propTypes = {
  comment: object,
  groupTitle: string.isRequired,
  activityId: oneOfType([string, number]).isRequired,
  highlight: bool,
  refProp: shape({ current: any }),
};

const defaultProps = {
  comment: {},
  highlight: false,
  refProp: null,
};

const text = {
  EDIT: 'Edit',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  REMOVE: 'Remove',
  POP_Q: 'Are you sure you want to remove this comment?',
  BUTTON_LABELS: {
    confirm: 'Delete',
    cancel: 'Cancel',
  },
};

const replaceHyperlink = (content) => {
  const mentionString = '(?:@<(?:[\\d]+?)><(?:[\\d+\\.@\\w\\s]+?)>)';
  const urlString = '(?:(?:https?|ftp)://[^\\s/$.?#].[^\\s]*)';
  const mentionRegex = new RegExp(mentionString);
  const urlRegex = new RegExp(urlString);
  const parts = content.split(new RegExp(`(${mentionString}|${urlString})`));
  return parts.map((p) => {
    if (mentionRegex.test(p)) {
      const groups = p.match(/(@<([\d]+?)><([\d+\\.@\w\s]+?)>)/);
      return (
        <HyperLink>
          <Link to={`/settings/users/${groups[2]}`}>{groups[3]}</Link>
        </HyperLink>
      );
    }

    if (urlRegex.test(p)) {
      const groups = p.match(urlRegex);
      return (
        <HyperLink>
          <a target="_blank" rel="noopener noreferrer" href={groups[0]}>
            {groups[0]}
          </a>
        </HyperLink>
      );
    }
    return p;
  });
};

const CommentContent = inject(stores => ({
  user: stores.loginStore.user,
  deleteComment: stores.activityStream.deleteComment,
  updateActivity: stores.activityStream.updateActivity,
}))(
  observer(({ user, comment, toggleEditMode, deleteComment, activityId, updateActivity }) => {
    const { owner, content, id } = comment;

    const updateCommentPermission = hasAccess('events_activitycomment', 'update');
    const isOwner = owner.id === user.id;
    const canEdit = updateCommentPermission && isOwner;

    const removeComment = async () => {
      await deleteComment({ id: activityId, commentId: id });
      updateActivity(activityId);
    };

    return (
      <Fragment>
        <div className="fiq-comment-card__body">{replaceHyperlink(content)}</div>
        {canEdit && (
          <div className="fiq-comment-card__actions">
            <span style={{ marginTop: '2px' }} onClick={toggleEditMode} className="tc-micro-strong">
              {text.EDIT}
            </span>
            <Space size={8} />
            <Popconfirm
              type="destructive"
              placement="bottom"
              icon="Delete"
              label={text.POP_Q}
              onConfirm={removeComment}
              buttonLabels={text.BUTTON_LABELS}
            >
              <span className="tc-micro-strong">{text.REMOVE}</span>
            </Popconfirm>
          </div>
        )}
      </Fragment>
    );
  }),
);

CommentContent.wrappedComponent.propTypes = {
  user: object.isRequired,
  comment: object.isRequired,
  toggleEditMode: func.isRequired,
  deleteComment: func.isRequired,
  activityId: oneOfType([string, number]).isRequired,
  updateActivity: func.isRequired,
};

const EditComment = inject(stores => ({
  editComment: stores.activityStream.editComment,
}))(
  observer(({ editComment, toggleEditMode, activityId, comment }) => {
    const { content, id } = comment;
    const [value, setContentValue] = useState(content);
    const updateContentValue = ({ target }) => setContentValue(target.value);
    const [users, setUsers] = useState([]);

    const updateComment = async () => {
      await editComment({ id: activityId, commentId: id, content: value });
      toggleEditMode();
    };

    const fetchUsers = async (query, cb) => {
      try {
        const response = await searchUsers({ search: query, limit: 6 });
        const res = response.data.results;
        setUsers(res);
        cb(
          res.map(user => ({
            id: user.id,
            display: extractDisplayName(user),
          })),
        );
      } catch {
        cb([]);
      }
    };

    return (
      <div className="fiq-comment-card__edit">
        <CommentField
          fetchData={fetchUsers}
          usersList={users}
          value={value}
          onChange={updateContentValue}
          style={{ marginTop: '0', input: { height: '80px' } }}
        />
        <Space size={8} />
        <div className="fiq-comment-card__edit-buttons">
          <Button color="ghost" size="small" onClick={toggleEditMode}>
            {text.CANCEL}
          </Button>
          <Space size={8} />
          <Button size="small" onClick={updateComment}>
            {text.SAVE}
          </Button>
        </div>
      </div>
    );
  }),
);

EditComment.wrappedComponent.propTypes = {
  editComment: func.isRequired,
  toggleEditMode: func.isRequired,
  activityId: oneOfType([string, number]).isRequired,
  comment: object.isRequired,
};

const CommentCard = ({ comment, groupTitle, activityId, highlight, refProp }) => {
  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  if (comment && !comment.owner) return null;

  const { owner, created } = comment;

  return (
    <ActivityBox highlight={highlight}>
      <div className="fiq-comment-card" ref={refProp}>
        <div className="fiq-comment-card__header">
          <UserCell id={owner.id} fullName={extractDisplayName(owner)} avatar={owner.avatar} />
          <div className="fiq-comment-card__date">{formatDate(groupTitle, created)}</div>
        </div>
        {!editMode && (
          <CommentContent
            activityId={activityId}
            toggleEditMode={toggleEditMode}
            comment={comment}
          />
        )}
        {editMode && (
          <EditComment activityId={activityId} toggleEditMode={toggleEditMode} comment={comment} />
        )}
      </div>
    </ActivityBox>
  );
};

CommentCard.propTypes = propTypes;
CommentCard.defaultProps = defaultProps;

export default CommentCard;
