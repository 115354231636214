import React from 'react';
import { string, number, bool, func } from 'prop-types';
import { Icon } from 'tc-biq-design-system';
import classnames from 'classnames';

const displayIndex = index => `0${index + 1}`;

const propTypes = {
  title: string.isRequired,
  description: string,
  index: number,
  done: bool,
  active: bool,
  disabled: bool,
  onClick: func,
  hideIndex: bool,
};

const defaultProps = {
  description: null,
  index: 0,
  done: false,
  active: false,
  disabled: false,
  onClick: () => null,
  hideIndex: false,
};

const StepHeader = ({ title, description, index, done, active, disabled, onClick, hideIndex }) => (
  <div
    className={classnames('fiq-stepper__header__step', {
      'fiq-stepper__header__step--active': active,
      'fiq-stepper__header__step--done': done,
      'fiq-stepper__header__step--disabled': disabled,
    })}
    onClick={!disabled ? onClick : () => null}
  >
    {!hideIndex && (
    <div className="fiq-stepper__header__step__icon tc-paragraph-regular">
      {done && <Icon colorName="text-neutral-50" size="regular" name="Checkmark" />}
      <span className="fiq-stepper__header__step__icon__index">{displayIndex(index)}</span>
    </div>
    )}
    <div className="fiq-stepper__header__step__content">
      <h3 className="fiq-stepper__header__step__title tc-micro-strong">{title}</h3>
      {description && (
        <p className="fiq-stepper__header__step__desc tc-paragraph-strong">{description}</p>
      )}
    </div>
  </div>
);

StepHeader.propTypes = propTypes;
StepHeader.defaultProps = defaultProps;

export default StepHeader;
