import React, { Fragment } from 'react';
import { Header, Breadcrumb, Space } from 'tc-biq-design-system';
import { string, object, arrayOf, shape, node, bool } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import GoBack from 'App/components/GoBack';

const propTypes = {
  pageTitle: string.isRequired,
  breads: arrayOf(shape({ label: string.isRequired, route: string })).isRequired,
  actions: node,
  style: object,
  goBackIcon: bool,
};

const defaultProps = {
  actions: null,
  style: {},
  goBackIcon: false,
};

const renderBreads = breads => (
  <Breadcrumb key="pageBreadcrumb">
    {breads.map(item => (
      <Breadcrumb.Item key={item.label}>
        {item.route && <Link to={item.route}>{item.label}</Link>}
        {!item.route && <span style={{ cursor: 'default' }}>{item.label}</span>}
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

const PageHeader = ({ pageTitle, breads, actions, style, goBackIcon }) => (
  <div className="fiq-page__header" style={style}>
    {goBackIcon && (
      <Fragment>
        <Space size={12} />
        <GoBack />
      </Fragment>
    )}
    <Header title={pageTitle} breadcrumbs={renderBreads(breads)} actions={actions} />
  </div>
);

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default withRouter(PageHeader);
