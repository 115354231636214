import React from 'react';
import { bool, object } from 'prop-types';
import { Sidepanel } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { RouteCell } from 'App/components/gridCellRenderers';

const SIDEPANEL_ID = 'SEGMENTS_SIDEPANEL';

const text = {
  SIDEPANEL_TITLE: 'Segments',
};

export const openSegmentsSidepanel = (segments) => {
  openOverlay(SIDEPANEL_ID, { segments });
};

export const closeSegmentsSidepanel = () => {
  closeOverlay(SIDEPANEL_ID);
};

const SegmentNameCell = ({ data, value }) => RouteCell('/automation/segments/preview/')({ hrefValue: data.id, value });

// eslint-disable-next-line react/prop-types
const SegmentsTable = ({ segments }) => (
  <div className="info-sidepanel__table">
    {segments.map(item => (
      <div key={item.id} className="info-sidepanel__table__item">
        <SegmentNameCell value={item.name} data={item} />
      </div>
    ))}
  </div>
);

const SegmentsSidepanel = ({
  visible,
  parameters,
}) => {
  if (!visible) return null;
  return (
    <Sidepanel
      visible={visible}
      hideFooter
      title={text.SIDEPANEL_TITLE}
      onCloseIconClick={closeSegmentsSidepanel}
      icon="Segments"
    >
      <SegmentsTable segments={parameters.segments} />
    </Sidepanel>
  );
};

SegmentsSidepanel.propTypes = {
  visible: bool,
  parameters: object,
};

SegmentsSidepanel.defaultProps = {
  visible: false,
  parameters: {},
};

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  addSegmentFilter: stores.tables.contacts.filters.addSegmentFilter,
}))(observer(SegmentsSidepanel));
