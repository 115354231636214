import React from 'react';
import { withComboxManager, withSidepanelManager, MultiActions, Space } from 'tc-biq-design-system';
import { object } from 'prop-types';

import { hasAccess } from 'App/services/permissionsService';
import ActionItem from './ActionItem';
import ActionItemDropdown from './ActionItemDropdown';
import ContactActions, { actionTypes } from '../../ContactActions';

import './HeaderActions.scss';

const propTypes = {
  comboxManager: object.isRequired,
  sidepanelManager: object.isRequired,
};

const mapActionsAttributes = actions => actions.map((action) => {
  const { icon, label, name, handler, permission } = action;
  return {
    icon,
    label,
    data: name,
    action: handler,
    permission,
  };
});

const renderActions = actions => actions
  .filter(action => (action.permission ? hasAccess(action.permission, 'create') : true))
  .map(action => (
    <div key={action.name} className="fiq-contact-actions__top">
      {action.handler && (
      <ActionItem icon={action.icon} label={action.label} onClick={action.handler} />
      )}
      {action.dropdownActions && (
      <ActionItemDropdown
        icon={action.icon}
        label={action.label}
        actions={action.dropdownActions}
      />
      )}
    </div>
  ));

const renderDropdownActions = actions => (actions.length ? (
  <div className="fiq-contact-actions__dropdown">
    <MultiActions key="dropdown-actions" actions={mapActionsAttributes(actions)} />
  </div>
) : null);

const HeaderActions = props => (
  <div className="fiq-contact-actions">
    <ContactActions type={actionTypes.TOP} {...props}>
      {renderActions}
    </ContactActions>
    <Space size={12} />
    <ContactActions type={actionTypes.DROPDOWN} {...props}>
      {renderDropdownActions}
    </ContactActions>
  </div>
);

HeaderActions.propTypes = propTypes;

export default withComboxManager(withSidepanelManager(HeaderActions));
