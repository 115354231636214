import React from 'react';

import { DashboardCard, LineChart } from 'tc-biq-design-system';
import NoData from 'App/components/NoData/NoData';
import { string, bool, array, func, number } from 'prop-types';

import './LineChart.scss';

const propTypes = {
  title: string,
  description: string,
  dimensions: array,
  data: array,
  xAxisKey: string,
  inProgress: bool,
  handleCardClick: func,
  lines: array,
  renderTooltip: func,
  height: number,
};

const defaultProps = {
  title: '',
  description: '',
  dimensions: [],
  data: [],
  xAxisKey: '',
  inProgress: false,
  handleCardClick: null,
  lines: [],
  renderTooltip: null,
  height: 190,
};

const LineChartLayout = ({
  title,
  description,
  dimensions,
  data,
  xAxisKey,
  inProgress,
  handleCardClick,
  lines,
  renderTooltip,
  height,
}) => (
  <DashboardCard
    title={title}
    description={description}
    loading={inProgress}
    actions={dimensions}
    onClick={handleCardClick}
  >
    {data.length ? (
      <LineChart
        data={data}
        dataKeyX={xAxisKey}
        lines={lines}
        aspect={3}
        height={height}
        renderTooltip={renderTooltip}
      />
    ) : <NoData />}
  </DashboardCard>
);

LineChartLayout.propTypes = propTypes;
LineChartLayout.defaultProps = defaultProps;

export default LineChartLayout;
