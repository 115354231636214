import React from 'react';
import { object, func, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Numeric from 'Dashboard/components/layouts/Numeric';

const text = {
  TITLE: 'FTDs number',
  DESCRIPTION: 'Total number of processed first time deposits in selected time period',
};

const propTypes = {
  data: object,
  fetchMetricsDataInProgress: bool,
  addNewFilter: func,
  history: object.isRequired,
  dashboardInterval: object.isRequired,
};

const defaultProps = {
  data: {},
  fetchMetricsDataInProgress: false,
  addNewFilter: null,
};

const filters = (startDate, endDate) => [
  {
    field: { label: 'Account type', value: 'account_type', type: 'string' },
    operator: { label: 'is equal to', related_models: null, value: 'exact' },
    value: 'Live',
  },
  {
    field: { label: 'State', value: 'state', type: 'choice' },
    operator: { label: 'is equal to', related_models: null, value: 'exact' },
    value: { value: 'Success', display_name: 'Success' },
  },
  {
    field: { label: 'Payment type', value: 'payment_type', type: 'choice' },
    operator: { label: 'is equal to', related_models: null, value: 'exact' },
    value: { value: 'Deposit', display_name: 'Deposit' },
  },
  { field: { label: 'Created', value: 'created', type: 'datetime' },
    ...(startDate === endDate
      ? { operator: { label: 'on', value: 'date', related_models: null } }
      : { operator: { label: 'between', value: 'range', related_models: null } }),
    ...(startDate === endDate
      ? { value: startDate }
      : { value: [`${startDate}T00:00:00Z`, `${endDate}T23:59:59Z`] }),
  },
];

const FTDCountNumeric = ({
  data, fetchMetricsDataInProgress, addNewFilter, history, dashboardInterval,
}) => {
  const onClick = () => {
    const { startDate, endDate } = dashboardInterval;
    filters(startDate, endDate).forEach(addNewFilter);
    history.push('/transactions/payments');
  };
  return (
    <Numeric
      title={text.TITLE}
      description={text.DESCRIPTION}
      value={data.value}
      percentage={data.percentage}
      isGrowing={data.isGrowing}
      loading={fetchMetricsDataInProgress}
      onClick={onClick}
      isCount
      currency={data.currency}
    />
  );
};

FTDCountNumeric.propTypes = propTypes;
FTDCountNumeric.defaultProps = defaultProps;

export default {
  component: inject(stores => ({
    data: stores.dashboardStore.financeNumericsData.FTDCount,
    fetchMetricsDataInProgress: stores.dashboardStore.fetchMetricsDataInProgress,
    addNewFilter: stores.tables.payments.filters.addNewFilter,
    dashboardInterval: stores.dashboardStore.dashboardInterval,
  }))(observer(withRouter(FTDCountNumeric))),
};
