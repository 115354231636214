import React from 'react';
import { arrayOf, shape, string, func, oneOfType, number } from 'prop-types';
import { SortBox } from 'tc-biq-design-system';

import './Navigation.scss';

const sectionShape = shape({
  label: string.isRequired,
  id: oneOfType([string, number]).isRequired,
});

const propTypes = {
  sections: arrayOf(sectionShape),
  onSectionChange: func.isRequired,
  active: string,
};

const defaultProps = {
  sections: [],
  active: '',
};

const Navigation = ({ sections, onSectionChange, active }) => sections.map(({ label, id }) => (
  <SortBox key={id} onClick={() => onSectionChange(id)} highlight={active === id}>
    {label}
  </SortBox>
));

Navigation.propTypes = propTypes;
Navigation.defaultProps = defaultProps;
export default Navigation;
