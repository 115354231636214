import React, { PureComponent } from 'react';
import { Avatar, withComboxManager, Space, Col, Row, Button, Pill } from 'tc-biq-design-system';
import { object } from 'prop-types';
import { inject, observer } from 'mobx-react';

import HeaderActions from 'Contacts/components/ContactHeader/Actions';
import { StatusCell } from 'App/components/gridCellRenderers';
import GoBack from 'App/components/GoBack';

import './ContactHeader.scss';
import { hasAccess } from 'App/services/permissionsService';
import { fetchTrackingLink } from 'Marketing/services/TrackingLinksService';
import If from 'App/components/If';
import { openSegmentsSidepanel } from 'Contacts/components/overlays/Segments/SegmentsSidepanel';
import { openCampaignsSidepanel } from 'Contacts/components/overlays/Campaigns/CampaignsSidepanel';
import { openPreviewTrackingLink } from 'Marketing/components/overlays/PreviewTrackingLink';
import ContactTags from './ContactTags';
import AssignManager from './AssignManager';
import ContactsKpi from './ContactKpi';

const propTypes = {
  contactData: object.isRequired,
};

const text = {
  CONTACT: 'Contact',
  CAMPAIGNS: 'Campaigns',
  SEGMENTS: 'Segments',
  TRACKING_LINK: 'Tracking link',
};

const riskLevelMap = {
  Low: 'status02',
  Medium: 'status03',
  High: 'status04',
};

class ContactHeader extends PureComponent {
  componentDidMount() {
    const { contactData } = this.props;
    const { id, first_name, last_name } = contactData;
    document.title = `${text.CONTACT}  ${id} ${first_name} ${last_name}`;
  }

  getLocation = () => {
    const { contactData } = this.props;
    const { city, country } = contactData;
    const subTitle = [];

    if (city && city !== '/') subTitle.push(city);
    if (country) subTitle.push(country);

    return subTitle.join(', ');
  };

  showTrackingLink = async () => {
    const { contactData } = this.props;
    const { data } = await fetchTrackingLink(contactData.tracking_link.id);
    openPreviewTrackingLink(data);
  };

  render() {
    const { contactData } = this.props;
    const {
      status,
      first_name,
      last_name,
      campaigns,
      segments,
      tracking_link,
      avatar,
      publisher,
      type,
      risk_level,
    } = contactData;
    const initals = first_name && last_name ? `${first_name[0]}${last_name[0]}` : null;
    const showCampaigns = () => openCampaignsSidepanel(campaigns);
    const showSegments = () => openSegmentsSidepanel(segments);
    return (
      <div className="fiq-user-header">
        <div data-recording-sensitive className="fiq-user-header__profile">
          <div className="fiq-user-header__profile-user">
            <GoBack />
            <Space size={16} />
            <div className="fiq-user-header__profile-avatar">
              <Avatar size="large" initials={initals} src={avatar} />
            </div>
            <div className="fiq-user-header__profile-basic">
              <div className="fiq-user-header__profile-username">
                <h4 className="tc-heading-m">
                  {first_name && last_name ? `${first_name} ${last_name}` : 'N/A'}
                </h4>
                <div className="fiq-user-header__profile-status">
                  {status && <StatusCell value={status} />}
                </div>
                {publisher && (
                  <div>
                    <Pill>{publisher}</Pill>
                  </div>
                )}
                {type && (
                  <div>
                    <Pill>{type}</Pill>
                  </div>
                )}
                {risk_level && (
                  <div>
                    <Pill type={riskLevelMap[risk_level] || 'neutral'}>Risk: {risk_level}</Pill>
                  </div>
                )}
                <div className="fiq-user-header__profile-more-info">
                  {segments && (
                    <Button
                      icon="Segments"
                      disabled={!segments.length}
                      size="small"
                      color="transparent"
                      onClick={showSegments}
                    >
                      {`${text.SEGMENTS} (${segments.length})`}
                    </Button>
                  )}
                  {campaigns && (
                    <Button
                      icon="Marketing"
                      disabled={!campaigns.length}
                      size="small"
                      color="transparent"
                      onClick={showCampaigns}
                    >
                      {`${text.CAMPAIGNS} (${campaigns.length})`}
                    </Button>
                  )}
                  {tracking_link && (
                    <Button
                      icon="Chain"
                      size="small"
                      color="transparent"
                      onClick={this.showTrackingLink}
                    >
                      {`${text.TRACKING_LINK}: ${tracking_link.name}`}
                    </Button>
                  )}
                </div>
              </div>
              <span className="tc-paragraph-regular text-neutral-900">{this.getLocation()}</span>
            </div>
          </div>
          <HeaderActions />
        </div>
        <If condition={hasAccess('contacts_tag', 'read')}>
          <div className="fiq-user-header__tags">
            <ContactTags />
          </div>
        </If>
        <If condition={hasAccess('contacts_contact', 'read')}>
          <Row style={{ width: '100% ', maxWidth: '100%', marginRight: 0, marginLeft: 0 }}>
            <Col lg="60%" md="50%" style={{ margin: 0 }}>
              <ContactsKpi />
            </Col>
            <Col lg="40%" md="50%" gutter={0} style={{ margin: 0 }}>
              <AssignManager />
            </Col>
          </Row>
        </If>
      </div>
    );
  }
}

ContactHeader.propTypes = propTypes;

export default withComboxManager(inject(stores => ({
  contactData: stores.contact.profile.contactData,
}))(observer(ContactHeader)));
