import React, { useEffect } from 'react';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { Auth0Provider } from '@auth0/auth0-react';
import { changeLanguage, locals } from 'App/helpers/i18n';

import AppRouter from './AppRouter';

import 'App/services/utilities/integrations';

import 'tc-biq-design-system/build/index.css';
import './App.scss';

const App = () => {
  useEffect(() => { changeLanguage(locals.EN); }, []);
  return (
    <I18nProvider i18n={i18n}>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={AUTH0_AUDIENCE}
        useRefreshTokens
        cacheLocation="localstorage"
        scope="all"
      >
        <AppRouter />
      </Auth0Provider>
    </I18nProvider>
  );
};

export default App;
