import PhoneCall from './PhoneCall';
import actionTypes from '../types';

const text = {
  LABEL: 'Call contact',
};

const ACTION_ICON = 'Phone';

export default {
  name: 'phone-call',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.TOP,
  handler: (comboxManager) => {
    const comboxOptions = {
      title: text.LABEL,
      id: 'phone-call-combox',
    };

    comboxManager.add(PhoneCall, comboxOptions);
  },
};
