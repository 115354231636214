import React, { Component } from 'react';
import { object, bool, func, array, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import { hasAccess } from 'App/services/permissionsService';
import FiltersProvider from './FiltersProvider';
import SaveEditFilter from './sidepanels';
import AddFilter from './AddFilter';
import ActiveFilters from './ActiveFilters';
import SavedFilters from './SavedFilters';
import SaveFilter from './SaveFilter';
import SegmentFilter from './SegmentFilter';

import './Filters.scss';

const propTypes = {
  applyFilter: func,
  applySegmentFilter: func,
  filtersStore: object.isRequired,
  activeSegment: object,
  activeFilters: array.isRequired,
  fetchData: func.isRequired,
  filtersService: object.isRequired,
  user: object.isRequired,
  savedFilters: object.isRequired,
  viewName: string,
  enableSegmentFiltering: bool,
  renderCustomFilterComponent: func,
  setWithCount: func,
};

const defaultProps = {
  viewName: null,
  enableSegmentFiltering: false,
  renderCustomFilterComponent: null,
  applyFilter: null,
  applySegmentFilter: null,
  activeSegment: null,
  setWithCount: null,
};

class Filters extends Component {
  componentDidUpdate() {
    const { filtersStore, viewName, fetchData } = this.props;
    const { rawSavedFilters, fields } = filtersStore;
    if (!isEmpty(fields) && !rawSavedFilters && viewName) {
      if (hasAccess('users_savedfilteruserbinding', 'read')) {
        this.fetchSavedFilters();
      } else {
        fetchData();
      }
    }
  }

  componentWillUnmount() {
    this.resetFilters();
  }

  fetchSavedFilters = async (event) => {
    const { filtersStore, user, filtersService } = this.props;
    const { updateSavedFilters, viewName } = filtersStore;
    const response = await filtersService.fetchSavedFilters({
      userId: user.id,
      viewName,
      search: event ? event.target.value : '',
    });
    updateSavedFilters(response.data.results);
    this.setDefaultSavedFilter();
  };

  setDefaultSavedFilter = () => {
    const { filtersStore, fetchData } = this.props;
    const { defaultActiveFilter, activeFilter, setActiveFilter } = filtersStore;
    if (!isEmpty(defaultActiveFilter) && isEmpty(activeFilter)) {
      setActiveFilter(defaultActiveFilter.id);
    }
    fetchData();
  };

  fetchSavedFilter = (id) => {
    const { filtersService, viewName } = this.props;
    return filtersService.fetchSavedFilter(id, viewName);
  };

  fetchSavedFilterOptions = (id) => {
    const { filtersService } = this.props;
    return filtersService.fetchSavedFilterOptions(id);
  };

  createEditFilters = (name, savedFilter = {}) => {
    const { filtersService, filtersStore } = this.props;
    let payload = {};
    if (savedFilter && savedFilter.id) {
      return filtersService.editSavedFilter(savedFilter.id, savedFilter);
    }
    payload = {
      ...savedFilter,
      name,
      filters: filtersStore.query,
    };
    return filtersService.saveFilters(payload);
  };

  removeFilter = (filter) => {
    const { filtersStore, fetchData, setWithCount } = this.props;
    filtersStore.removeFilter(filter);
    setWithCount(true);
    fetchData();
  };

  removeSegmentFilter = () => {
    const { filtersStore, fetchData, setWithCount } = this.props;
    filtersStore.removeSegmentFilter();
    setWithCount(true);
    fetchData();
  };

  resetFilters = () => {
    const { filtersStore } = this.props;
    filtersStore.resetFilters();
  };

  render() {
    const {
      applyFilter,
      applySegmentFilter,
      filtersStore,
      filtersService,
      fetchData,
      activeFilters,
      activeSegment,
      savedFilters,
      enableSegmentFiltering,
      renderCustomFilterComponent,
      setWithCount,
    } = this.props;
    const { fields, viewName } = filtersStore;

    return (
      <div className="fiq-filters">
        <FiltersProvider
          value={{
            service: filtersService,
            filtersStore,
            fetchData,
            fetchSavedFilters: this.fetchSavedFilters,
            savedFilters,
            viewName,
            setWithCount,
          }}
        >
          {hasAccess('users_savedfilteruserbinding', 'read') && <SavedFilters />}
          {renderCustomFilterComponent && renderCustomFilterComponent()}
          {enableSegmentFiltering && hasAccess('automations_segment', 'read') && (
            <SegmentFilter
              activeSegment={activeSegment}
              applyFilter={applySegmentFilter}
              removeFilter={this.removeSegmentFilter}
            />
          )}
          <ActiveFilters
            applyFilter={applyFilter}
            activeFilters={activeFilters}
            fields={fields}
            resetFilters={this.resetFilters}
            removeFilter={this.removeFilter}
          />
          <AddFilter fields={fields} applyFilter={applyFilter} />
          <SaveFilter />
        </FiltersProvider>
        <SaveEditFilter
          fetchSavedFilters={this.fetchSavedFilters}
          fetchSavedFilter={this.fetchSavedFilter}
          fetchSavedFilterOptions={this.fetchSavedFilterOptions}
          createEditFilters={this.createEditFilters}
          onSuccess={fetchData}
        />
      </div>
    );
  }
}

Filters.propTypes = propTypes;
Filters.defaultProps = defaultProps;

export default inject(stores => ({
  user: stores.loginStore.user,
}))(observer(withErrorBoundary(Filters)));
