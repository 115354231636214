import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { func, array, number, string, oneOfType, bool } from 'prop-types';

import If from 'App/components/If';
import Loader from 'App/components/Loader';
import NoData from 'App/components/NoData';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import IdentityCode from './IdentityCode';

import './MIFID.scss';

const propTypes = {
  fetchMifidData: func.isRequired,
  mifidData: array.isRequired,
  requestInProgress: bool.isRequired,
  id: oneOfType([number, string]).isRequired,
};

const text = {
  INFO_TEXT: 'No MIFID data for this user.',
  NATIONALITY: 'Nationality',
  IDENTITY_CODES: 'Nationality identity codes',
};

const MIFID = inject(stores => ({
  mifidData: stores.contact.mifid.mifidData,
  fetchMifidData: stores.contact.mifid.fetchMifidData,
  requestInProgress: stores.contact.mifid.requestInProgress,
}))(observer(
  class MIFID extends PureComponent {
    componentDidMount() {
      this.fetchMifidData();
    }

    fetchMifidData = async () => {
      const { id, fetchMifidData } = this.props;
      await fetchMifidData(id);
    };

    render() {
      const { mifidData, requestInProgress } = this.props;
      const data = mifidData.slice();
      return (
        <div className="fiq-profile__sections__wrapper fiq-mifid">
          <Loader visible={requestInProgress} />

          <If condition={!requestInProgress && !data.length}>
            <NoData text={text.INFO_TEXT} />
          </If>

          <If condition={!requestInProgress && !!data.length}>
            <div className="fiq-mifid__header">
              <p className="text-neutral-900 tc-heading-m">{text.NATIONALITY}</p>
              <p className="text-neutral-900 tc-paragraph-regular">
                {data.length && data[0].nationality}
              </p>
            </div>

            <div className="fiq-mifid__content">
              <p className="text-neutral-900 tc-heading-s">{text.IDENTITY_CODES}</p>
              <div className="m-t fiq-mifid__identity-codes">
                {data.map(mifidRecord => (
                  <IdentityCode key={mifidRecord.id} {...mifidRecord} />
                ))}
              </div>
            </div>
          </If>
        </div>
      );
    }
  },
));


MIFID.wrappedComponent.propTypes = propTypes;

export default withErrorBoundary(MIFID);
