import React from 'react';
import { ActivityBox, Icon } from 'tc-biq-design-system';
import { string, object } from 'prop-types';

import { formatDate, formatTitle } from 'App/services/utilities/dateGroupUtils';

import './ActivityCard.scss';

const propTypes = {
  activity: object,
  groupTitle: string,
};

const defaultProps = {
  groupTitle: '',
  activity: null,
};

const ActivityCard = ({ activity, groupTitle }) => (
  <ActivityBox>
    <div className="fiq-activity-card">
      <div>
        <div className="fiq-activity-card__text tc-paragraph-regular">{formatTitle(activity)}</div>
        <div className="fiq-activity-card__date">
          <Icon colorName="text-neutral-400" name="Clock" size="small" />
          <span className="tc-paragraph-regular text-neutral-400">
            {formatDate(groupTitle, activity.timestamp)}
          </span>
        </div>
      </div>
    </div>
  </ActivityBox>
);

ActivityCard.propTypes = propTypes;
ActivityCard.defaultProps = defaultProps;

export default ActivityCard;
