import React, { PureComponent } from 'react';
import { array, func, bool, string } from 'prop-types';
import { Space } from 'tc-biq-design-system';

import Loader from 'App/components/Loader';
import If from 'App/components/If/index';
import InfiniteScroll from 'App/components/InfiniteScroll';
import TaskRow from './TaskRow';

const propTypes = {
  accordionId: string.isRequired,
  tasks: array.isRequired,
  fetchData: func.isRequired,
  fetchAllData: func.isRequired,
  isLoading: bool,
  hasMore: bool,
};

const defaultProps = {
  isLoading: false,
  hasMore: true,
};

const BODY_STYLE = {
  maxHeight: '262px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
};

const text = {
  NO_MORE: accordion => `No more ${accordion.toLowerCase()} tasks`,
  LOADING: 'Loading tasks...',
};

class TasksAccordionBody extends PureComponent {
  fetchDataOnScroll = () => {
    const { fetchData } = this.props;
    fetchData(false);
  };

  render() {
    const { tasks, isLoading, hasMore, fetchAllData, accordionId } = this.props;
    return (
      <InfiniteScroll
        hasMore={hasMore}
        isLoading={isLoading}
        fetchData={this.fetchDataOnScroll}
        style={BODY_STYLE}
      >
        {tasks.map(task => (
          <TaskRow key={task.id} task={task} fetchData={fetchAllData} accordionId={accordionId} />
        ))}
        <If condition={isLoading}>
          <div className="fiq-tasks__accordion-loading">
            <Loader visible={isLoading} />
            <Space size={8} />
            <div className="tc-paragraph-regular">{text.LOADING}</div>
          </div>
        </If>
        <If condition={!hasMore && tasks.length > 5}>
          <div className="fiq-tasks__no-more-tasks">
            <div className="tc-paragraph-strong">{text.NO_MORE(accordionId)}</div>
          </div>
        </If>
      </InfiniteScroll>
    );
  }
}

TasksAccordionBody.propTypes = propTypes;
TasksAccordionBody.defaultProps = defaultProps;

export default TasksAccordionBody;
