import React from 'react';
import { object, func } from 'prop-types';
import { Checkbox } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { find } from 'lodash';

const propTypes = {
  column: object.isRequired,
  onChange: func.isRequired,
};

const style = {
  container: {
    marginBottom: '10px',
  },
  label: {
    marginLeft: '7px',
  },
};

const VisibilityCheckbox = ({ column, onChange }) => {
  const { key, label, visible } = column;
  return (
    <div style={style.container}>
      <Checkbox id={key} checked={visible} onChange={() => onChange(key)} disabled={key === 'id'}>
        <span style={style.label}>{label || key}</span>
      </Checkbox>
    </div>
  );
};

VisibilityCheckbox.propTypes = propTypes;

const VisibilityCheckboxFactory = ({ tableId, fieldKey }) => inject(stores => ({
  column: find(stores.tables[tableId].columnManager.allFields, { key: fieldKey }),
}))(observer(VisibilityCheckbox));

export default VisibilityCheckboxFactory;
