import React from 'react';
import { Icon, Space } from 'tc-biq-design-system';
import { string, number } from 'prop-types';

const BlockTitle = ({ icon, title, count }) => (
  <div className="fiq-rules-single-page__block-title">
    <Icon bgColorName="bck-primary-100" name={icon} colorName="text-primary-500" />
    <Space size={12} />
    <span className="tc-heading-s text">{title}</span>
    {count && <div className="count">{`${count}/10`}</div>}
  </div>
);

BlockTitle.propTypes = { icon: string.isRequired, title: string.isRequired, count: number };
BlockTitle.defaultProps = { count: null };

export default BlockTitle;
