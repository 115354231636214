import React, { PureComponent } from 'react';
import { Sidepanel } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { object, func, bool } from 'prop-types';
import find from 'lodash/find';

import { closeOverlay } from 'App/services/overlayService';
import SidepanelFooter from 'App/components/SidepanelFooter';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';

const propTypes = {
  sectionKeys: object.isRequired,
  overlay: object.isRequired,
  fields: object.isRequired,
  fieldValues: object.isRequired,
  setFieldsData: func.isRequired,
  editBasicInfo: func.isRequired,
  resetFieldsData: func.isRequired,
  requestInProgress: bool.isRequired,
  errors: object,
};

const defaultProps = {
  errors: null,
};

const SIDEPANEL_ID = 'EDIT_BASIC_INFO';

const text = {
  TITLE: 'Edit basic info',
  BUTTON_LABELS: {
    confirm: 'Save changes',
    cancel: 'Discard changes',
  },
};

const customFooter = (execute, close, submitInProgress) => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

// eslint-disable-next-line
const getFieldsPerSection = (sectionKeys, fields) => sectionKeys.map(key => find(fields, field => field.name === key)).filter(field => field);

const EditBasicInfo = inject(stores => ({
  sectionKeys: stores.contact.profile.contactInfoLayout,
  overlay: stores.overlayStore.overlay,
  fields: stores.contact.profile.contactOptions,
  fieldValues: stores.contact.profile.contactData,
  setFieldsData: stores.forms.editBasicInfoForm.setFieldsData,
  resetFieldsData: stores.forms.editBasicInfoForm.resetFieldsData,
  editBasicInfo: stores.contact.profile.editBasicInfo,
  requestInProgress: stores.contact.profile.requestInProgress.editBasicInfo,
  errors: stores.contact.profile.errors,
}))(observer(
  class EditBasicInfo extends PureComponent {
    filterPerSection = () => {
      const { fields, sectionKeys, overlay } = this.props;
      const formattedFields = fieldsResolver(fields);
      return getFieldsPerSection(sectionKeys[overlay.parameters], formattedFields);
    };

    setInitialData = (fieldsPerSection) => {
      const { fieldValues, setFieldsData } = this.props;
      const visibleFieldsData = {};
      fieldsPerSection.forEach((field) => {
        const { id } = field;
        if (fieldValues[id]) visibleFieldsData[id] = fieldValues[id];
      });
      setFieldsData(visibleFieldsData);
    };

    close = () => {
      const { resetFieldsData } = this.props;
      closeOverlay(SIDEPANEL_ID);
      resetFieldsData();
    };

    submit = async () => {
      const { editBasicInfo, errors } = this.props;
      await editBasicInfo();
      if (!errors.editBasicInfo) this.close();
    };

    render() {
      const { overlay, requestInProgress } = this.props;

      if (!overlay[SIDEPANEL_ID]) return null;
      const fieldsPerSection = this.filterPerSection();
      this.setInitialData(fieldsPerSection);

      return (
        <Sidepanel
          visible={overlay[SIDEPANEL_ID]}
          title={text.TITLE}
          icon="Pen"
          onCloseIconClick={this.close}
          footerRender={() => customFooter(this.submit, this.close, requestInProgress)}
        >
          <div data-recording-ignore="mask">
            {fieldsPerSection.map(({ id, read_only, ...props }) => (
              <Field {...props} disabled={read_only} formId="editBasicInfoForm" key={id} />
            ))}
          </div>
        </Sidepanel>
      );
    }
  },
));


EditBasicInfo.wrappedComponent.propTypes = propTypes;
EditBasicInfo.wrappedComponent.defaultProps = defaultProps;

export default EditBasicInfo;
