import React, { Fragment } from 'react';
import { Sidepanel, InfoBox, Space, Button } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { func, bool, object } from 'prop-types';
import { isEmpty, omit } from 'lodash';
import googleIcon from 'assets/images/btn_google_signin_dark_normal_web.png';
import CopyToClipboardWrapper from 'App/components/CopyToClipboardWrapper';
import { closeOverlay } from 'App/services/overlayService';
import SidepanelFooter from 'App/components/SidepanelFooter';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import { AutoGeneratedSecretField } from './Fields';

const propTypes = {
  visible: bool,
  parameters: object,
  setFieldsData: func.isRequired,
  editIntegrationConfig: func.isRequired,
  requestInProgress: bool.isRequired,
  fetchIntegrationsData: func.isRequired,
  initiateOAuthLogin: func.isRequired,
};

const defaultProps = {
  visible: false,
  parameters: {},
};

const SIDEPANEL_ID = 'EDIT_INTEGRATION';

const text = {
  COPY: 'Copy to clipboard',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  AUTHORIZE: 'Authorize',
};

const btnStyle = {
  border: 0, 
  padding: 0, 
  background: 'none', 
  width: '180px', 
  height: '33px', 
  display: 'flex', 
  justifyContent: 'end', 
  alignItems: 'center',
};

const customFooter = (execute, close, submitInProgress, isReadOnlyForm, hasOAuth, name) => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={isReadOnlyForm ? () => null : execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    btnConfirmStyle={
      name === 'GoogleAds' ? btnStyle : {}
    }
    buttonLabels={
      isReadOnlyForm && !hasOAuth
        ? { cancel: text.CANCEL }
        : { confirm: name === 'GoogleAds' ? <img src={googleIcon} width={'147px'} /> : hasOAuth ? text.AUTHORIZE : text.SAVE, cancel: text.CANCEL }
    }
  />
);

const configToFieldsData = (config) => {
  const fieldsData = {};
  config
    .filter(({ oauth_login_path }) => !oauth_login_path)
    .forEach(({ key, value }) => {
      fieldsData[key] = value;
    });
  return fieldsData;
};

const normalizeKey = key => key
  .toLowerCase()
  .split('_')
  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');

const configToFields = (config) => {
  const fields = {};
  config.forEach(
    ({ key, help_text, auto_generated_secret, read_only, oauth_login_path, value }) => {
      fields[key] = {
        label: normalizeKey(key),
        placeholder: help_text,
        type: 'string',
        read_only,
        auto_generated_secret,
        oauth_login_path,
        key,
        initialValue: value,
      };
    },
  );
  return fields;
};

const EditIntegration = ({
  visible,
  parameters,
  setFieldsData,
  editIntegrationConfig,
  requestInProgress,
  fetchIntegrationsData,
  initiateOAuthLogin,
}) => {
  if (isEmpty(parameters)) return null;

  const { config, name, description } = parameters;

  const initialFieldsData = configToFieldsData(config);
  setFieldsData(initialFieldsData);
  const formattedFields = fieldsResolver(configToFields(config));
  const isReadOnlyForm = formattedFields.every(field => field.read_only);

  // NOTE: If an OAuth field exist on the integration, we need to use its oauth
  // login path to authorize a user. The authorization is initiated by using
  // the confirm button on the footer, to make the process natural.
  const oauthField = formattedFields.find(field => field.oauth_login_path);

  const fields = formattedFields.filter(({ oauth_login_path }) => !oauth_login_path);

  const closeSidepanel = () => {
    if (isReadOnlyForm) fetchIntegrationsData();
    closeOverlay(SIDEPANEL_ID);
  };
  const submit = async () => {
    const hasOAuth = !!oauthField;
    // Edit the configuration configs in all cases.
    const success = await editIntegrationConfig(initialFieldsData, hasOAuth);
    // If the config are edited successfully, start the OAuth authorization.
    if (success && hasOAuth) {
      initiateOAuthLogin(oauthField.oauth_login_path);
    }
    if (success && !hasOAuth) closeSidepanel();
  };

  return (
    <Sidepanel
      title={name}
      visible={visible}
      icon="Edit"
      onCloseIconClick={() => closeOverlay(SIDEPANEL_ID)}
      footerRender={() => customFooter(
        submit, closeSidepanel, requestInProgress, isReadOnlyForm, !!oauthField, name
      )}
    >
      <InfoBox header={name}>{description}</InfoBox>
      <Space size={32} />
      <div>
        {fields.map(({ id, auto_generated_secret, initialValue, ...fieldProps }, index) => {
          if (auto_generated_secret) {
            return (
              <AutoGeneratedSecretField
                key={id}
                value={initialValue}
                {...fieldProps}
                isLast={index === fields.length - 1}
              />
            );
          }
          return (
            <Fragment>
              <Field
                {...omit(fieldProps, ['auto_generated_secret', 'oauth_login_path'])}
                value={formattedFields[id]}
                formId="editIntegrationConfig"
                key={id}
              />
              {fieldProps.read_only && (
                <CopyToClipboardWrapper textToCopy={initialValue}>
                  <Button onClick={e => e.preventDefault()} size="small" icon="Duplicate">
                    {text.COPY}
                  </Button>
                </CopyToClipboardWrapper>
              )}
            </Fragment>
          );
        })}
      </div>
    </Sidepanel>
  );
};

EditIntegration.propTypes = propTypes;
EditIntegration.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  setFieldsData: stores.forms.editIntegrationConfig.setFieldsData,
  editIntegrationConfig: stores.integrations.editIntegrationConfig,
  requestInProgress: stores.integrations.requestInProgress.editIntegration,
  fetchIntegrationsData: stores.integrations.fetchIntegrationsData,
  initiateOAuthLogin: stores.integrations.initiateOAuthLogin,
}))(observer(EditIntegration));
