import React from 'react';

import { RouteCell, StatusCell, WalletCell, AmountCell } from 'App/components/gridCellRenderers';

export default {
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/transactions/withdrawals/'),
  },
  state: { cellRendererFramework: StatusCell },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => (
      <WalletCell data={value} />
    ),
  },
  amount: {
    cellRendererFramework: AmountCell,
  },
  sortable: false,
};
