import React from 'react';
import { bool, oneOf } from 'prop-types';
import { Spinner } from 'tc-biq-design-system';

import './Loader.scss';

const propTypes = {
  visible: bool.isRequired,
  size: oneOf(['large', 'extraSmall', 'regular', 'small']),
};

const defaultProps = {
  size: 'large',
};

const Loader = ({ size, visible }) => (visible ? (
  <div className="fiq-loader">
    <Spinner size={size} />
  </div>
) : null);

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;
