import { BLOCK_ACTION, BLOCK_STATE, ADD_BLOCK_STATE, BLOCK_TYPE } from './consts';

const events = {
  ON_EDGE_SELECTED: 'onEdgeSelected',
  ON_NODE_SELECTED: 'onNodeSelected',
  ON_NODE_REMOVE: 'onNodeRemove',
  ON_NODE_EDIT: 'onNodeEdit',
  ON_NODE_CLONE: 'onNodeClone',
  ON_NODE_MOVE: 'onNodeMove',
};

const insertModes = {
  ABOVE: 'Above',
  BELOW: 'Below',
  BELOW_BRANCH: 'Below Branch',
};

const defaultZoom = 0.9;
class GraphEvents {
  constructor(graph) {
    this.graph = graph;
  }

  init = () => {
    this.graph.on('node:click', this.onNodeClick.bind(this));

    this.graph.on('afterlayout', () => {
      const node = this.focusNode || this.getRootNode();
      this.graph.zoomTo(this.zoom || defaultZoom);
      this.graph.focusItem(node);
    });
  };

  getRootNode = () => this.graph.getNodes().find((n) => {
    const edges = n.get('edges');
    return edges.find(e => e.get('source').get('model').id.includes('trigger'));
  });

  onNodeClick = (e) => {
    const node = e.item;
    if (this.isClickedOnPlus(e)) {
      this.selectEdges(node.get('edges'), node);
    } else {
      this.selectNode(e);
    }
  };

  onCanvasClick = (e) => {
    const { item } = e;
    if (!item) {
      this.resetEdges();
    }
  };

  selectNode = (e) => {
    const { target, item: node } = e;
    const targetAttrs = target.get('attrs');
    const { action } = targetAttrs;
    const { blockType } = node.get('model');

    switch (action) {
      case BLOCK_ACTION.EDIT:
        this.nodeEventHandler(events.ON_NODE_EDIT, node);
        this.closeAllDropdowns();
        this.setNodeSelectedState(node, true);
        break;
      case BLOCK_ACTION.REMOVE:
        this.nodeEventHandler(events.ON_NODE_REMOVE, node);
        this.closeAllDropdowns();
        break;
      case BLOCK_ACTION.CLONE:
        this.nodeEventHandler(events.ON_NODE_CLONE, node);
        this.setAllNodesDisabled();
        this.closeAllDropdowns();
        break;
      case BLOCK_ACTION.MOVE:
        this.nodeEventHandler(events.ON_NODE_MOVE, node);
        this.setAllNodesDisabled();
        this.closeAllDropdowns();
        break;
      case BLOCK_ACTION.TOGGLE_DROPDOWN: {
        const isDisabledState = node.hasState(BLOCK_STATE.BLOCK_DISABLED);
        if (isDisabledState) return;

        // handle dropdown state
        const hasState = node.hasState(BLOCK_STATE.DROPDOWN_OPEN);
        if (hasState) {
          this.graph.clearItemStates(node, BLOCK_STATE.DROPDOWN_OPEN);
          this.closeDropdown(node);
        } else {
          this.graph.setItemState(node, BLOCK_STATE.DROPDOWN_OPEN, true);
          this.openDropdown(node);
        }
        break;
      }
      default:
        if (![
          BLOCK_TYPE.EXIT,
          BLOCK_TYPE.TRIGGER,
        ].includes(blockType)) {
          this.nodeEventHandler(events.ON_NODE_SELECTED, node);
          this.setNodeSelectedState(node, true);
        }
    }
  };


  selectEdges = (edges, node) => {
    const payload = this.resolveInsertMode(edges, node);
    this.edgeSelectedHandler(payload, edges, node);
    this.setAddNodeSelectedState(node, true);
  };

  setEventHandlers = (eventHandlers) => {
    this.eventHandlers = eventHandlers;
  };

  saveView = (node) => {
    this.focusNode = node;
    this.zoom = this.graph.getZoom();
  };

  edgeSelectedHandler = (payload, edges, node) => {
    const handler = this.eventHandlers[events.ON_EDGE_SELECTED];
    if (handler) {
      handler(payload, edges, node);
    }
  };

  nodeEventHandler = (event, node) => {
    const handler = this.eventHandlers[event];
    if (handler) {
      if (node) {
        handler(node.get('model'), node.get('edges'));
      }
    }
  };

  resetNodes = () => {
    this.graph.getNodes().forEach((n) => {
      this.graph.setItemState(n, BLOCK_STATE.BLOCK_SELECTED, false);
      this.graph.setItemState(n, ADD_BLOCK_STATE.SELECTED, false);
      this.graph.setItemState(n, BLOCK_STATE.BLOCK_DISABLED, false);
      this.graph.updateItem(n, {
        disabled: false,
        dropdown: {
          open: false,
        },
      });
    });
  };

  resolveInsertMode = (edges, node) => {
    // Detect branch nodes
    const nodeModel = node.get('model');
    const branchNode = nodeModel?.payload?.branchNode;
    if (branchNode) {
      return {
        insert_mode: insertModes.BELOW_BRANCH,
        block: branchNode,
        insert_exit_node: false,
      };
    }

    // Detect direct nodes
    const hashedNodes = this.graph.getNodes().reduce((acc, n) => {
      const model = n.get('model');
      if (model.temp || model.id === 'trigger') return acc;
      return {
        ...acc,
        [model.id]: model,
      };
    }, []);

    return edges.reduce((acc, edge) => {
      const model = edge.get('model');
      if (acc) return acc;

      const sourceNode = hashedNodes[model.source];
      const targetNode = hashedNodes[model.target];

      if (sourceNode) {
        return {
          insert_mode: insertModes.BELOW,
          block: sourceNode.id,
          index: model.payload?.index,
          insert_exit_node: targetNode?.type !== BLOCK_TYPE.EXIT,
        };
      }

      if (targetNode) {
        return {
          insert_mode: insertModes.ABOVE,
          block: targetNode.id,
          insert_exit_node: false,
        };
      }

      return null;
    }, null);
  };

  isClickedOnPlus = ({ target }) => ['plus-shape-background', 'plus-shape'].includes(target.get('name'));

  setAllNodesDisabled = () => {
    this.graph.cfg.nodes.forEach((NODE) => {
      this.graph.setItemState(NODE, BLOCK_STATE.BLOCK_DISABLED, true);
      this.graph.updateItem(NODE, {
        disabled: true,
        dropdown: {
          open: false,
        },
      });
    });
  }

  setNodeSelectedState = (node, state = false) => {
    this.graph.setAutoPaint(false);
    this.resetNodes();
    this.graph.setItemState(node, BLOCK_STATE.BLOCK_SELECTED, state);
    this.graph.paint();
    this.graph.setAutoPaint(true);
  };

  setAddNodeSelectedState = (node, state = false) => {
    this.graph.setAutoPaint(false);
    this.resetNodes();
    this.graph.setItemState(node, ADD_BLOCK_STATE.SELECTED, state);
    this.graph.paint();
    this.graph.setAutoPaint(true);
  };

  toggleDropdown = (node, open) => this.graph.updateItem(node, {
    dropdown: {
      open,
    },
  });

  openDropdown = node => this.toggleDropdown(node, true);

  closeDropdown = node => this.toggleDropdown(node, false);

  closeAllDropdowns = () => {
    this.graph.setAutoPaint(false);
    this.graph.getNodes().forEach((n) => {
      this.toggleDropdown(n, false);
    });
    this.graph.paint();
    this.graph.setAutoPaint(true);
  }
}

export default GraphEvents;
