import React from 'react';
import { Button } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';

import Field from 'App/components/FieldRenderer/Field';

const text = {
  ADD_NOTE: 'Add note',
  PLACEHOLDER: 'Type your note here...',
};

const AddNote = inject(stores => ({
  addNote: stores.contact.notes.addNote,
  requestInProgress: stores.contact.notes.requestInProgress.addNote,
}))(
  observer(({ addNote, requestInProgress }) => (
    <div className="fiq-notes__add-note">
      <Field
        id="contact-note"
        name="contact-note"
        formId="contactNoteForm"
        placeholder={text.PLACEHOLDER}
        style={{ flex: 1 }}
      />
      <Button
        disabled={requestInProgress}
        loading={requestInProgress}
        onClick={() => addNote('contact-note')}
      >
        {text.ADD_NOTE}
      </Button>
    </div>
  )),
);

export default AddNote;
