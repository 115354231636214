import React from 'react';
import { Table } from 'tc-biq-design-system';
import { array } from 'prop-types';
import { DateTimeCell, YesNoCell } from 'App/components/gridCellRenderers';

import './CardInstancesTable.scss';

const propTypes = {
  instances: array.isRequired,
};

const text = {
  CARD_INSTANCES: 'Card Instances',
};

const cols = [
  { title: 'Name', key: 'name' },
  { title: 'PAN', key: 'pan' },
  {
    title: 'Expiry date',
    render: ({ expiry_year, expiry_month }) => `${expiry_month}/${expiry_year}`,
  },
  // eslint-disable-next-line
  { title: 'Active', key: 'is_active', render: ({ is_active }) => <YesNoCell value={is_active} /> },
  {
    title: 'Virtual',
    key: 'is_virtual',
    // eslint-disable-next-line
    render: ({ is_virtual }) => <YesNoCell value={is_virtual} />,
  },
  {
    title: 'Primary',
    key: 'is_primary',
    // eslint-disable-next-line
    render: ({ is_primary }) => <YesNoCell value={is_primary} />,
  },
  {
    title: 'Created at',
    key: 'created',
    // eslint-disable-next-line
    render: ({ created }) => <DateTimeCell value={created} />,
  },
];

const CardInstancesTable = ({ instances }) => (
  <div className="card-instances-wrapper">
    <div className="card-instances-wrapper__header">
      <span className="tc-heading-s">{text.CARD_INSTANCES}</span>
    </div>
    <div className="card-instances-wrapper__table">
      <Table data={instances} cols={cols} striped fixedHeader />
    </div>
  </div>
);

CardInstancesTable.propTypes = propTypes;

export default CardInstancesTable;
