import React, { PureComponent } from 'react';
import { Button } from 'tc-biq-design-system';
import Page from 'App/components/Page';
import { openOverlay } from 'App/services/overlayService';
import ApiKeysTable from 'Settings/Sections/ApiKeys/components/ApiKeysTable';
import { hasAccess } from 'App/services/permissionsService';
import { RouteComponentProps } from 'react-router-dom';

const text = {
  PAGE_TITLE: 'API keys',
  CREATE_API_KEY: 'Create API key',
};

const bread = [
  { label: 'Settings', route: '/settings' },
  { label: 'API keys', route: '/settings/api-keys' },
];

type TParams = { action: string };

class ApiKeysPage extends PureComponent<RouteComponentProps<TParams>> {
  componentDidMount() {
    const { match } = this.props;
    if (match.params.action === 'create') {
      openOverlay('ADD_EDIT_API_KEY', { type: 'add' });
    }
  }

  headerActions = () => {
    if (!hasAccess('users_apikey', 'create')) return null;
    return (
      <Button onClick={() => openOverlay('ADD_EDIT_API_KEY', { type: 'add' })}>
        {text.CREATE_API_KEY}
      </Button>
    );
  };

  render() {
    return (
      <Page
        goBackIcon
        style={{ margin: '0 20px' }}
        title={text.PAGE_TITLE}
        headerActions={this.headerActions}
        bread={bread}
      >
        <ApiKeysTable />
      </Page>
    );
  }
}

export default ApiKeysPage;
