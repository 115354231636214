import React from 'react';
import { object, bool, array, func } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import LineChartLayout from 'Dashboard/components/layouts/LineChart';
import getGranularity from 'App/services/utilities/getGranularity';


const text = {
  TITLE: 'Leads',
  DESCRIPTION: 'Total number of leads, in selected time period.',
};

const propTypes = {
  data: array,
  fetchMetricsDataInProgress: bool,
  history: object.isRequired,
  dashboardInterval: object.isRequired,
  addNewFilter: func,
};

const defaultProps = {
  data: [],
  fetchMetricsDataInProgress: false,
  addNewFilter: null,
};

const lines = [{ type: 'monotone', dataKey: 'Leads', stroke: '#F76868', strokeWidth: 2, dot: false, key: 'leads' }];

const filters = (startDate, endDate) => [
  {
    field: { label: 'Created', value: 'created', type: 'datetime' },
    ...(startDate === endDate
      ? { operator: { label: 'on', value: 'date', related_models: null } }
      : { operator: { label: 'between', value: 'range', related_models: null } }),
    ...(startDate === endDate
      ? { value: startDate }
      : { value: [`${startDate}T00:00:00Z`, `${endDate}T23:59:59Z`] }),
  },
  {
    field: { label: 'Status', value: 'status', type: 'choice' },
    operator: { label: 'is equal to', value: 'exact', related_models: null },
    value: { value: 'Lead', display_name: 'Lead' },
  },
];

const LeadsChart = ({
  data, fetchMetricsDataInProgress, history, dashboardInterval, addNewFilter,
}) => {
  const onClick = () => {
    const { startDate, endDate } = dashboardInterval;
    filters(startDate, endDate).forEach(addNewFilter);
    history.push('/contacts');
  };
  const { startDate, endDate } = dashboardInterval;
  const granularity = getGranularity(startDate, endDate);

  return (
    <LineChartLayout
      title={text.TITLE}
      description={text.DESCRIPTION}
      dimensions={[]}
      inProgress={fetchMetricsDataInProgress}
      data={data}
      xAxisKey={granularity}
      handleCardClick={onClick}
      lines={lines}
    />
  );
};

LeadsChart.propTypes = propTypes;
LeadsChart.defaultProps = defaultProps;

export default {
  component: inject(stores => ({
    data: stores.dashboardStore.contactsData.leadsChartData,
    fetchMetricsDataInProgress: stores.dashboardStore.fetchMetricsDataInProgress,
    addNewFilter: stores.tables.contacts.filters.addNewFilter,
    dashboardInterval: stores.dashboardStore.dashboardInterval,
  }))(observer(withRouter(LeadsChart))),
};
