import React from 'react';
import { Dropdown, Icon, NotificationCount } from 'tc-biq-design-system';
import { number } from 'prop-types';

import UserNotificationsDropdown from './UserNotificationDropdown';

import './UserNotifications.scss';

const customHeader = (toggleHandler, count) => (
  <div
    key="usernotifications-header"
    className="fiq-user-notifications__custom-header"
    onClick={toggleHandler}
  >
    <NotificationCount count={count}>
      <Icon colorName="text-neutral-500" size="large" name="Bell" />
    </NotificationCount>
  </div>
);

const customList = [
  {
    item: () => <UserNotificationsDropdown />,
  },
];

const Notifications = ({ count }) => (
  <div className="fiq-user-notifications">
    <Dropdown customHeader={handler => customHeader(handler, count)} customList={customList} />
  </div>
);

Notifications.propTypes = {
  count: number,
};

Notifications.defaultProps = {
  count: 0,
};

export default Notifications;
