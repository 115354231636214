import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { func, object } from 'prop-types';
import { Button, Space } from 'tc-biq-design-system';

import Page from 'App/components/Page';
import { PermissionsSection, NameField, SearchField, SelectAllPermissions } from 'Settings/Sections/Roles/components';

import './RolesSinglePage.scss';

const propTypes = {
  fetchRolesData: func.isRequired,
  addEditRole: func.isRequired,
  history: object.isRequired,
  match: object.isRequired,
  location: object.isRequired,
};

const text = {
  ROLE: 'Role',
  PERMISSIONS: 'Permissions',
  EXPAND_ALL: 'Expand all',
  COLLAPSE_ALL: 'Collapse all',
  CREATE: 'Create role',
  EDIT: 'Edit role',
  DISCARD: 'Discard',
};

const ToggleAllButton = inject(stores => ({
  allVisible: stores.roles.fields.accordions.visible,
  updateFieldValue: stores.roles.updateFieldValue,
}))(
  observer(({ allVisible, updateFieldValue }) => {
    const toggle = () => updateFieldValue('accordions', { visible: !allVisible });
    const buttonConfig = {
      icon: allVisible ? 'CaretUp' : 'CaretDown',
      label: allVisible ? text.COLLAPSE_ALL : text.EXPAND_ALL,
    };
    return (
      <Button onClick={toggle} color="transparent" iconPosition="left" icon={buttonConfig.icon}>
        {buttonConfig.label}
      </Button>
    );
  }),
);


const RolesSinglePage = inject(stores => ({
  fetchRolesData: stores.roles.fetchRolesData,
  addEditRole: stores.roles.addEditRole,
}))(observer(
  class RolesSinglePage extends PureComponent {
    constructor(props) {
      super(props);
      this.id = props.match.params.roleId === 'create' ? null : props.match.params.roleId;
      this.isClone = props.location.state && props.location.state.clone;
    }

    componentDidMount() {
      this.fetchRoles();
    }

    fetchRoles = () => {
      const { fetchRolesData } = this.props;
      fetchRolesData(this.id);
    };

    routeToRoles = () => {
      const { history } = this.props;
      history.push('/settings/roles');
    };

    addEditRole = () => {
      const { addEditRole } = this.props;
      addEditRole({ onSuccess: this.routeToRoles, id: this.id, isClone: this.isClone });
    };

    render() {
      const operationText = this.id && !this.isClone ? text.EDIT : text.CREATE;
      return (
        <Page goBackIcon style={{ overflow: 'auto' }} title={operationText}>
          <div className="fiq-roles-single">
            <div className="fiq-roles-single__name">
              <NameField />
            </div>
            <div className="fiq-roles-single__search">
              <div className="fiq-roles-single__search-label">
                <div className="tc-heading-s">{text.PERMISSIONS}</div>
                <ToggleAllButton />
              </div>
              <div style={{ display: 'flex' }}>
                <SearchField />
                <SelectAllPermissions />
              </div>
            </div>
            <PermissionsSection />
            <div className="fiq-roles-single__submit">
              <Button color="ghost" onClick={this.routeToRoles}>
                {text.DISCARD}
              </Button>
              <Space size={8} />
              <Button onClick={this.addEditRole}>{operationText}</Button>
            </div>
          </div>
        </Page>
      );
    }
  },
));


RolesSinglePage.wrappedComponent.propTypes = propTypes;

export default withRouter(RolesSinglePage);
