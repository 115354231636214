import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { notifier } from 'tc-biq-design-system';
import { object, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import withFilters from 'App/components/hoc/withFilters';

import PieChart from 'Dashboard/components/layouts/PieChart';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import run from 'App/services/utilities/run';
import getGranularity from 'App/services/utilities/getGranularity';
import { formatPieChartData } from 'App/services/utilities/chartUtils';

const text = {
  TITLE: 'Contacts by state',
  DESCRIPTION: 'Total number of contacts per state, in selected time period.',
  ERROR_MESSAGE: 'Something went wrong',
};

const METRIC = 'contact_statuses';

const propTypes = {
  dashboardInterval: object,
  addNewFilter: func,
  history: object.isRequired,
};

const defaultProps = {
  dashboardInterval: {},
  addNewFilter: null,
};

const filters = (startDate, endDate) => [
  {
    field: { label: 'Created', value: 'created', type: 'datetime' },
    ...(startDate === endDate
      ? { operator: { label: 'on', value: 'date', related_models: null } }
      : { operator: { label: 'between', value: 'range', related_models: null } }),
    ...(startDate === endDate ? { value: startDate } : { value: [`${startDate}T00:00:00Z`, `${endDate}T23:59:59Z`] }),
  },
];

const ContactsByStateChart = inject(stores => ({
  dashboardInterval: stores.dashboardStore.dashboardInterval,
  addNewFilter: stores.tables.contacts.filters.addNewFilter,
}))(observer(
  class ContactsByStateChart extends Component {
    constructor() {
      super();
      this.state = {
        fetchInProgress: false,
        data: [],
      };
    }

    onQueryReady = async (query) => {
      const { start_date, end_date } = query;

      const granularity = getGranularity(start_date, end_date);

      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: start_date,
          end_of_interval: end_date,
          granularity,
          dimensions: ['status'],
        },
      };

      this.setState({
        fetchInProgress: true,
      });

      const [err, data] = await run(fetchMetricsData(formattedQuery));

      if (err) {
        notifier.error(text.ERROR_MESSAGE);
      } else {
        const chartData = formatPieChartData(data, 'status');
        this.setState({
          fetchInProgress: false,
          data: chartData,
        });
      }
    };

    handleCardClick = () => {
      const { addNewFilter, history, dashboardInterval } = this.props;
      const { startDate, endDate } = dashboardInterval;
      filters(startDate, endDate).forEach(addNewFilter);
      history.push('/contacts');
    };

    render() {
      const { fetchInProgress, selectedDimension, data } = this.state;

      return (
        <PieChart
          selectedDimension={selectedDimension}
          title={text.TITLE}
          description={text.DESCRIPTION}
          dimensions={[]}
          inProgress={fetchInProgress}
          onChangeDimension={this.onChangeDimension}
          data={data}
          handleCardClick={this.handleCardClick}
          color="#F76868"
        />
      );
    }
  },
));


ContactsByStateChart.wrappedComponent.propTypes = propTypes;
ContactsByStateChart.wrappedComponent.defaultProps = defaultProps;

export default {
  component: withRouter(withFilters(ContactsByStateChart)),
};
