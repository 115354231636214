import React from 'react';
import { number, oneOfType, string } from 'prop-types';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';

const propTypes = {
  campaignId: oneOfType([number, string]).isRequired,
};

const tableConfig = campaignId => ({
  tableUrl: `/marketing/campaigns/${campaignId}/memberships/`,
  tableKey: 'recipients',
});

const customColumns = [
  { key: 'status', headerName: '' },
  { key: 'latest_delivery_time', headerName: '' },
  { key: 'opened', headerName: '' },
  { key: 'clicked', headerName: '' },
  { key: 'reported_as_spam', headerName: '' },
  { key: 'goal_completed', headerName: '' },
  { key: 'unsubscribed', headerName: '' },
];

const sizeOptions = [10, 20, 50, 100, 200];

const CampaignRecipientsTable = ({ campaignId }) => {
  const { GridComponent } = GridComponentFactory(tableConfig(campaignId));
  return (
    <GridComponent
      hideColumnManager
      sizeOpitons={sizeOptions}
      height="40vh"
      modifiers={modifiers}
      customColumns={customColumns}
    />
  );
};

CampaignRecipientsTable.propTypes = propTypes;
export default withErrorBoundary(CampaignRecipientsTable);
