/* eslint react/prop-types: 0 */
import React from 'react';
import { ToggleCell } from 'App/components/gridCellRenderers';
import { Space, HyperLink } from 'tc-biq-design-system';

import { Link } from 'react-router-dom';

const ToggleRouteCell = (onToggle, href, hasAccess) => ({ data }) => {
  const ToggleCellComponent = ToggleCell(onToggle, hasAccess);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '50px' }}>
        <ToggleCellComponent value={data.is_active} data={data} />
      </div>
      <Space size={12} />
      <span>
        <HyperLink>
          <Link to={`${href}${data.id}`}>{data.name}</Link>
        </HyperLink>
      </span>
    </div>
  );
};

export default ToggleRouteCell;
