import React, { useState } from 'react';
import { Button } from 'tc-biq-design-system';
import classNames from 'classnames';

import AddFilterDropdown from './AddFilterDropdown';

const text = {
  ADD_FILTER: 'Add filter',
};

const AddFilter = (props) => {
  const [addFilterInProgress, toggleDropdown] = useState(false);
  const open = () => toggleDropdown(true);
  const close = () => toggleDropdown(false);

  return (
    <div className="fiq-filters__add-filter">
      <div className={classNames({ hidden: addFilterInProgress })}>
        <Button icon="Plus" color="transparent" onClick={open}>
          {text.ADD_FILTER}
        </Button>
      </div>
      <AddFilterDropdown hidden={!addFilterInProgress} closeDropdown={close} {...props} />
    </div>
  );
};

export default AddFilter;
