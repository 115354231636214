import React from 'react';
import DashboardPage from 'Dashboard/components/DashboardPage';
import { financeDashboard } from 'Dashboard/components/widgets';
import layout from './layout';

const text = {
  HEADER: 'Finance',
  TOOLTIP: 'The dashboard data will be updated automatically every 15 min.',
};

const FinanceDashboardPage = () => (
  <DashboardPage
    widgets={financeDashboard}
    title={text.HEADER}
    tooltip={text.TOOLTIP}
    layout={layout}
  />
);

export default FinanceDashboardPage;
