import React from 'react';
import ExportData from './ExportData';

export default {
  label: 'My data exports',
  id: 'export-data',
  Component: () => <ExportData />,
  permission: 'users_user',
  visibility: ({ userId, loggedUserId }) => userId === loggedUserId,
};
