import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { Sidepanel } from 'tc-biq-design-system';
import { object, bool, func } from 'prop-types';
import { isEmpty } from 'lodash';

import { closeOverlay } from 'App/services/overlayService';
import SidepanelFooter from 'App/components/SidepanelFooter';
import Field, { fieldsResolverObject } from 'App/components/FieldRenderer';
import truncateText from 'App/services/utilities/truncateText';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

const propTypes = {
  visible: bool,
  parameters: object,
  optionFields: object.isRequired,
  setFieldsData: func.isRequired,
  addTask: func.isRequired,
  editTask: func.isRequired,
  resetFields: func.isRequired,
  form: object.isRequired,
};

const defaultProps = {
  visible: false,
  parameters: null,
};

const text = {
  ADD_TITLE: 'Add task',
  EDIT_TITLE: 'Edit task',
  CLONE_TITLE: 'Clone task',
  BUTTON_LABELS: type => ({
    confirm: type === 'add' ? 'Add task' : 'Save changes',
    cancel: 'Discard',
  }),
};

export const SIDEPANEL_ID = 'ADD_EDIT_TASK';

const sidepanelTitle = {
  add: text.ADD_TITLE,
  edit: text.EDIT_TITLE,
  clone: text.CLONE_TITLE,
};

const sidepanelIcon = {
  add: 'CalendarCreate',
  edit: 'Edit',
  clone: 'Duplicate',
};

const CustomFooter = inject(stores => ({
  requestInProgress: stores.tasks.requestInProgress.taskAction,
}))(
  observer(({ execute, close, requestInProgress, type, isDisabled }) => (
    <SidepanelFooter
      submitInProgress={requestInProgress}
      execute={execute}
      close={close}
      cancelColor="ghost"
      confirmColor="primary"
      buttonLabels={text.BUTTON_LABELS(type)}
      disableSubmit={isDisabled}
    />
  )),
);

class AddEditTask extends PureComponent {
  componentDidUpdate() {
    const { visible } = this.props;
    if (visible) this.setInitialValues();
  }

  close = () => {
    const { resetFields } = this.props;
    closeOverlay(SIDEPANEL_ID);
    resetFields();
  };

  submit = async () => {
    const {
      addTask,
      editTask,
      parameters: { onSuccess, type, task },
      form,
    } = this.props;

    const { validateForm } = form;

    if (!validateForm()) return;

    if (type === 'edit') {
      await editTask(task.id);
    } else {
      await addTask();
    }

    if (isEmpty(form.fieldErrors)) {
      this.close();
      if (onSuccess) onSuccess();
    }
  };

  setInitialValues = () => {
    const {
      parameters: { task, type },
      setFieldsData,
    } = this.props;
    if (task && (type === 'edit' || type === 'clone')) {
      const initalValues = formatGenericValue(task);
      setFieldsData(initalValues);
    }
  };

  render() {
    const { visible, parameters, optionFields } = this.props;
    if (!parameters || isEmpty(parameters)) return null;
    const { type, task } = parameters;
    const fieldProps = fieldsResolverObject(optionFields);
    const title = (task ? truncateText(task.title) : sidepanelTitle[type]) || '';
    return (
      <Sidepanel
        type="success"
        icon={sidepanelIcon[type]}
        title={title}
        visible={visible}
        onCloseIconClick={this.close}
        footerRender={() => <CustomFooter execute={this.submit} close={this.close} type={type} />}
      >
        <Field {...fieldProps.title} formId="addEditTaskForm" />
        <Field {...fieldProps.description} formId="addEditTaskForm" type="textarea" />
        <Field {...fieldProps.assigned_to} formId="addEditTaskForm" />
        <Field {...fieldProps.connected_to} formId="addEditTaskForm" />
        <Field {...fieldProps.due_date} formId="addEditTaskForm" />
      </Sidepanel>
    );
  }
}

AddEditTask.propTypes = propTypes;
AddEditTask.defaultProps = defaultProps;

export default withErrorBoundary(inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  optionFields: stores.tasks.optionFields,
  setFieldsData: stores.forms.addEditTaskForm.setFieldsData,
  resetFields: stores.forms.addEditTaskForm.resetFieldsData,
  form: stores.forms.addEditTaskForm,
  addTask: stores.tasks.addTask,
  editTask: stores.tasks.editTask,
}))(observer(AddEditTask)));

function formatGenericValue(task) {
  return Object.keys(task).reduce((acc, key) => {
    if (key === 'assigned_to' || key === 'connected_to') {
      return {
        ...acc,
        [key]: { display_name: extractDisplayName(task[key]), value: task[key] && task[key].id },
      };
    }
    return {
      ...acc,
      [key]: task[key],
    };
  }, {});
}
