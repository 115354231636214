import React, { useEffect, useMemo, Fragment } from 'react';
import { Button, Row, Col, Icon, Space } from 'tc-biq-design-system';
import { func, bool, object, array, string } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import Field, { fieldsResolverObject } from 'App/components/FieldRenderer';
import Loader from 'App/components/Loader';
import channelEnum from 'App/enums/channelEnum';
import LocalizedTemplatesTable from './LocalizedTemplatesTable';

import TemplateFormFooter from '../TemplateFormFooter';

import './TemplateForm.scss';

const propTypes = {
  loadingData: bool.isRequired,
  fieldsDef: object.isRequired,
  fetchFieldsDef: func.isRequired,
  fetchTemplate: func.isRequired,
  match: object.isRequired,
  resetForm: func.isRequired,
  onToggle: func.isRequired,
  metadata: object,
  attributes: object,
  updateFieldValue: func.isRequired,
  templateType: string,
  channel: object,
  loadingFields: bool,
  contentList: array,
  onEditContent: func.isRequired,
  onDelete: func.isRequired,
  setDefaultContent: func.isRequired,
};

const defaultProps = {
  metadata: null,
  contentList: [],
  attributes: {},
  templateType: '',
  channel: null,
  loadingFields: false,
};

const text = {
  DESIGN_TEMPLATE: 'Design template',
  EDIT_TEMPLATE: 'Preview and edit template',
  MESSAGE_CONTENT: 'Message content',
  ADD_CONTENT_LANGUAGE: 'Add content language',
};

const TEMPLATE_TRANSACTION_TYPE = 'Transactional';

const TemplateForm = ({
  loadingData,
  loadingFields,
  resetForm,
  fetchFieldsDef,
  fieldsDef,
  onToggle,
  templateType,
  channel,
  contentList,
  onEditContent,
  onDelete,
  setDefaultContent,
  match,
}) => {
  const fieldsProps = useMemo(() => fieldsResolverObject(fieldsDef), [fieldsDef]);
  const channelValue = channel?.value;
  const { templateId, cloneId } = match.params;
  const isEdit = templateId !== 'create' && !cloneId;

  useEffect(() => {
    if (isEmpty(fieldsDef)) fetchFieldsDef();
  }, [fieldsDef]);

  useEffect(() => () => resetForm(), []);

  if (loadingData || !fieldsProps || loadingFields) return <Loader visible />;
  return (
    <Row gutter={0}>
      <div className="fiq-template-form">
        <Col xs="100%" md="90%" lg="60%">
          <div className="fiq-template-form__field-wrapper fiq-section-wrapper">
            <Field {...fieldsProps.name} required={false} type="text" formId="templateForm" />
            <Field {...fieldsProps.description} formId="templateForm" />
            <Field {...fieldsProps.type} disabled={isEdit} formId="templateForm" />
            {templateType === TEMPLATE_TRANSACTION_TYPE && (
              <Field {...fieldsProps.event_type} formId="templateForm" />
            )}
            <Field {...fieldsProps.channel} disabled={isEdit} formId="templateForm" />

            {channelValue === channelEnum.EMAIL && (
              <Fragment>
                <Field {...fieldsProps.email_from} formId="templateForm" />
                <Field {...fieldsProps.name_from} formId="templateForm" />
                <Field {...fieldsProps.reply_to} formId="templateForm" />
              </Fragment>
            )}

            {channelValue && (
              <div>
                <div className="fiq-template-form__content">
                  <div>
                    <Icon name="Mail" bgColorName="bck-primary-100" colorName="text-primary-500" />
                  </div>
                  <Space size={8} />
                  <div className="tc-heading-s">{text.MESSAGE_CONTENT}</div>
                  <div className="fiq-template-form__content__language">
                    <Button color="transparent" icon="Plus" onClick={onToggle}>
                      {text.ADD_CONTENT_LANGUAGE}
                    </Button>
                  </div>
                </div>
                <Space size={8} />
                <LocalizedTemplatesTable
                  contentList={contentList}
                  onEditContent={onEditContent}
                  onDelete={onDelete}
                  setDefaultContent={setDefaultContent}
                />
              </div>
            )}
            <Field {...fieldsProps.is_active} formId="templateForm" />
          </div>
          <TemplateFormFooter isEdit={isEdit} />
        </Col>
      </div>
    </Row>
  );
};

TemplateForm.propTypes = propTypes;
TemplateForm.defaultProps = defaultProps;

export default inject(stores => ({
  loadingData: stores.templates.requestInProgress.fetchTemplate,
  fetchFieldsDef: stores.templates.fetchTemplatesOptions,
  loadingFields: stores.templates.requestInProgress.fetchOptions,
  fetchTemplate: stores.templates.fetchTemplateData,
  fieldsDef: stores.templates.fieldsDef,
  resetForm: stores.templates.resetForm,
  metadata: stores.templates.template.metadata,
  updateFieldValue: stores.forms.templateForm.updateFieldValue,
  templateType: stores.forms.templateForm.data?.type?.value,
  channel: stores.forms.templateForm.data?.channel,
}))(observer(withRouter(TemplateForm)));
