import React from 'react';
import { arrayOf, node, shape, string, func, bool, oneOfType } from 'prop-types';
import classnames from 'classnames';
import StepHeader from './components/StepHeader';

import './Stepper.scss';

const propTypes = {
  activeStep: string,
  disabledSteps: arrayOf(string),
  doneSteps: arrayOf(string),
  steps: arrayOf(
    shape({
      key: string.isRequired,
      title: string.isRequired,
      description: string,
      component: oneOfType([func, node]).isRequired,
    }),
  ),
  onChange: func,
  hideIndex: bool,
};

const defaultProps = {
  activeStep: null,
  steps: [],
  disabledSteps: [],
  doneSteps: [],
  onChange: () => null,
  hideIndex: false,
};

const Stepper = ({ steps, activeStep, disabledSteps, doneSteps, onChange, hideIndex }) => (
  <div className="fiq-stepper">
    <div className="fiq-stepper__header">
      {steps.map(({ title, description, key }, index) => (
        <StepHeader
          key={key}
          active={activeStep === key}
          disabled={disabledSteps.includes(key)}
          done={doneSteps.includes(key)}
          title={title}
          description={description}
          index={index}
          onClick={() => onChange(key)}
          hideIndex={hideIndex}
        />
      ))}
    </div>
    <div className="fiq-stepper__panel">
      <div className="fiq-stepper__panel__scene">
        {steps.map(({ key, component }) => (
          <div key={key} className={classnames('fiq-stepper__panel__item', { 'fiq-stepper__panel__item--active': activeStep === key })}>
            {component(activeStep === key)}
          </div>
        ))}
      </div>
    </div>
  </div>
);

Stepper.propTypes = propTypes;
Stepper.defaultProps = defaultProps;

export default Stepper;
