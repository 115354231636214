import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { notifier } from 'tc-biq-design-system';
import { object, array, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import withFilters from 'App/components/hoc/withFilters';

import BarChart from 'Dashboard/components/layouts/BarChart';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import run from 'App/services/utilities/run';
import getGranularity from 'App/services/utilities/getGranularity';
import { getChartData } from 'App/services/utilities/chartUtils';
import getRandomColor from 'App/services/utilities/getRandomColor';

const text = {
  TITLE: 'Number of incoming funds',
  DESCRIPTION: 'Total number of processed incoming funds made in selected period',
  TOTAL_AMOUNT: 'Total amount',
  TOTAL_LABEL: 'Total',
  CURRENCY: 'currency',
  ERROR_MESSAGE: 'Something went wrong',
};

const METRIC = 'incoming_funds';

const propTypes = {
  dimensions: array,
  dashboardInterval: object,
  addNewFilter: func,
  history: object.isRequired,
};

const defaultProps = {
  dimensions: [],
  dashboardInterval: {},
  addNewFilter: null,
};

const filters = (startDate, endDate) => [
  {
    field: { label: 'Payment type', value: 'payment_type', type: 'choice' },
    operator: { label: 'is equal to', related_models: null, value: 'exact' },
    value: { value: 'Deposit', display_name: 'Deposit' },
  },
  {
    field: { label: 'State', value: 'state', type: 'choice' },
    operator: { label: 'is equal to', related_models: null, value: 'exact' },
    value: { value: 'Success', display_name: 'Success' },
  },
  {
    field: { label: 'Account type', value: 'account_type', type: 'string' },
    operator: { label: 'is equal to', related_models: null, value: 'exact' },
    value: 'Live',
  },
  { field: { label: 'Created', value: 'created', type: 'datetime' },
    ...(startDate === endDate
      ? { operator: { label: 'on', value: 'date', related_models: null } }
      : { operator: { label: 'between', value: 'range', related_models: null } }),
    ...(startDate === endDate
      ? { value: startDate }
      : { value: [`${startDate}T00:00:00Z`, `${endDate}T23:59:59Z`] }),
  },
];

const valueRenderer = (key, value) => {
  if (key === 'true') return `FTD: ${value}`;
  if (key === 'false') return `Non-FTD: ${value}`;
  if (!key || key === 'null') return `None: ${value}`;
  return `${key}: ${value}`;
};


const IncomingFundsTotal = inject(stores => ({
  dashboardInterval: stores.dashboardStore.dashboardInterval,
  dimensions: stores.dashboardStore.metrics?.incoming_funds?.dimensions || [],
  addNewFilter: stores.tables.payments.filters.addNewFilter,
}))(observer(
  class IncomingFundsTotal extends Component {
    constructor() {
      super();
      this.state = {
        selectedDimension: 'is_first_time_deposit',
        fetchInProgress: false,
        granularity: 'day',
        formatedData: [],
        uniqBarKeys: [],
      };
    }

    onQueryReady = async (query) => {
      const { selectedDimension } = this.state;
      const { start_date, end_date } = query;

      const granularity = getGranularity(start_date, end_date);

      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: start_date,
          end_of_interval: end_date,
          granularity,
          dimensions: selectedDimension && [selectedDimension],
        },
      };

      const [err, data] = await run(fetchMetricsData(formattedQuery));
      const { uniqBarKeys, formatedData } = getChartData(data, granularity, selectedDimension, 'count');

      if (err) {
        notifier.error(text.ERROR_MESSAGE);
      } else {
        this.setState({
          granularity,
          fetchInProgress: false,
          uniqBarKeys,
          formatedData,
        });
      }
    }

    onChangeDimension = async (selectedDimension) => {
      const { dashboardInterval } = this.props;
      const { startDate, endDate } = dashboardInterval;

      const granularity = getGranularity(startDate, endDate);
      const dimension = selectedDimension === 'date' ? null : selectedDimension;

      this.setState({
        selectedDimension: dimension,
        fetchInProgress: true,
      });

      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: startDate,
          end_of_interval: endDate,
          granularity,
          dimensions: dimension && [dimension],
        },
      };


      const [err, data] = await run(fetchMetricsData(formattedQuery));
      const { uniqBarKeys, formatedData } = getChartData(data, granularity, selectedDimension, 'count');

      if (err) {
        notifier.error(text.ERROR_MESSAGE);
      } else {
        this.setState({
          granularity,
          fetchInProgress: false,
          formatedData,
          uniqBarKeys,
        });
      }
    }

    handleCardClick = () => {
      const { addNewFilter, history, dashboardInterval } = this.props;
      const { startDate, endDate } = dashboardInterval;
      filters(startDate, endDate).forEach(addNewFilter);
      history.push('/transactions/payments');
    }

    render() {
      const { dimensions } = this.props;
      const {
        uniqBarKeys, formatedData, granularity, fetchInProgress, selectedDimension,
      } = this.state;
      const actions = dimensions.map(dimension => ({
        ...dimension,
        onClick: () => this.onChangeDimension(dimension.value),
      }));
      const formatedBars = uniqBarKeys.map((bar, index) => ({ key: bar, label: bar, color: uniqBarKeys.length < 7 ? `status01-${7 - index}00` : getRandomColor('green') }));

      return (
        <BarChart
          selectedDimension={selectedDimension}
          title={text.TITLE}
          valueRenderer={valueRenderer}
          description={text.DESCRIPTION}
          dimensions={actions}
          inProgress={fetchInProgress}
          onChangeDimension={this.onChangeDimension}
          bars={[...formatedBars, { key: 'total', label: text.TOTAL_LABEL }]}
          data={formatedData}
          xAxisKey={granularity}
          handleCardClick={this.handleCardClick}
        />
      );
    }
  },
));


IncomingFundsTotal.wrappedComponent.propTypes = propTypes;
IncomingFundsTotal.wrappedComponent.defaultProps = defaultProps;

export default {
  component: withRouter(withFilters(IncomingFundsTotal)),
};
