import React from 'react';
import { DashboardCard } from 'tc-biq-design-system';
import { string, func, bool, array } from 'prop-types';

import NoData from 'App/components/NoData/NoData';
import { RouteCell } from 'App/components/gridCellRenderers';

import './SegmentList.scss';

const propTypes = {
  title: string.isRequired,
  description: string,
  loading: bool,
  onClick: func,
  data: array,
  tableTitle: string,
};

const defaultProps = {
  description: '',
  loading: false,
  data: [],
  onClick: null,
  tableTitle: '',
};

const SegmentList = ({ title, tableTitle, data, description, loading, onClick }) => {
  if (!data.length) return <NoData />;
  return (
    <DashboardCard
      title={title}
      description={description}
      loading={loading}
      onClick={onClick}
    >
      <div className="segment-list__header">
        <span className="tc-paragraph-strong">Name</span>
        <span className="tc-paragraph-strong">{tableTitle}</span>
      </div>
      <div className="segment-list__body">
        {data.map(({ segment, value }) => (
          <div key={segment.id} className="item">
            {RouteCell('/automation/segments/preview/')({ hrefValue: segment.id, value: segment.name })}
            <span>{value}</span>
          </div>
        ))}
      </div>
    </DashboardCard>
  );
};

SegmentList.propTypes = propTypes;
SegmentList.defaultProps = defaultProps;

export default SegmentList;
