import React from 'react';
import { string } from 'prop-types';
import { Space, Icon } from 'tc-biq-design-system';

const docStatusEnum = {
  Approved: { icon: 'Approve', color: 'text-status01-500' },
  Rejected: { icon: 'Reject', color: 'text-status04-500' },
};

const DocStatus = ({ status }) => {
  const statusConfig = docStatusEnum[status];
  if (!statusConfig) return null;
  const { icon, color } = statusConfig;
  return (
    <div className="fiq-documents__status">
      <Icon colorName={color} name={icon} />
      <Space size={8} />
      <span className={`tc-paragraph-strong ${color}`}>{status}</span>
    </div>
  );
};
DocStatus.propTypes = { status: string.isRequired };

export default DocStatus;
