import React, { Component } from 'react';
import { DataGrid, Button } from 'tc-biq-design-system';
import { array, func, string, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { PillCell, actionWrapper, SmartlookCell } from 'App/components/gridCellRenderers';

import { openOverlay } from 'App/services/overlayService';

import './InviteUsersTable.scss';

const propTypes = {
  users: array.isRequired,
  resetUsersState: func.isRequired,
  inviteRequestStatus: string.isRequired,
  setInviteGridToReady: func.isRequired,
  isInviteUsersTableReady: bool.isRequired,
  removeUser: func.isRequired,
};

const singleActions = ({ onEdit, onDelete }) => [
  {
    icon: { name: 'Edit', color: 'text-neutral-900', size: 'small' },
    onClick: onEdit,
    label: 'Edit',
  },
  {
    icon: { name: 'Delete', color: 'text-neutral-900', size: 'small' },
    onClick: onDelete,
    label: 'Delete',
  },
];

const text = {
  ADD_USER: 'Add User',
};

const columns = actions => [
  {
    headerName: 'Email',
    field: 'email',
    sortable: true,
    cellRendererFramework: SmartlookCell,
  },
  {
    headerName: 'First name',
    field: 'first_name',
    sortable: true,
    cellRendererFramework: SmartlookCell,
  },
  {
    headerName: 'Last name',
    field: 'last_name',
    sortable: true,
    cellRendererFramework: SmartlookCell,
  },
  {
    headerName: 'Roles',
    field: 'roles',
    sortable: false,
    cellRendererFramework: PillCell({ displayField: 'display_name' }),
  },
  {
    headerName: 'Team',
    field: 'team',
    sortable: false,
    cellRendererFramework: PillCell({ displayField: 'display_name' }),
  },
  {
    headerName: 'Contact Access Groups',
    field: 'contact_access_groups',
    sortable: false,
    cellRendererFramework: PillCell({ displayField: 'display_name' }),
  },
  {
    pinned: 'right',
    width: 80,
    suppressSizeToFit: true,
    enableFiltering: false,
    cellRendererFramework: actionWrapper(actions),
    headerName: '',
    field: 'actions',
  },
];


const InviteUsersTable = inject(stores => ({
  users: stores.usersStore.users,
  resetUsersState: stores.usersStore.resetUsersState,
  setInviteGridToReady: stores.usersStore.setInviteGridToReady,
  isInviteUsersTableReady: stores.usersStore.isInviteUsersTableReady,
  inviteRequestStatus: stores.usersStore.inviteRequestStatus,
  removeUser: stores.usersStore.removeUser,
}))(observer(
  class InviteUsersTable extends Component {
    constructor(props) {
      super(props);
      this.state = {
        current: 1,
      };
    }

    componentWillUnmount() {
      const { resetUsersState } = this.props;
      resetUsersState();
    }

    onGridReady = (params) => {
      const { setInviteGridToReady } = this.props;
      this.gridApi = params.api;
      setInviteGridToReady();
      this.sizeColumnsToFit();
    };

    addUser = () => {
      openOverlay('ADD_USER');
    };

    editUser = (data) => {
      const { id } = data;
      openOverlay('ADD_USER', { id });
    };

    deleteUser = ({ id }) => {
      const { removeUser } = this.props;
      removeUser(id);
    };

    setCurrentPage = (current) => {
      this.setState({ current });
    };

    sizeColumnsToFit = () => {
      const { users } = this.props;
      if (this.gridApi) {
        this.gridApi.setRowData(users);
        this.gridApi.redrawRows(users);
        this.gridApi.sizeColumnsToFit();
      }
    };

    render() {
      const { users, inviteRequestStatus, isInviteUsersTableReady } = this.props;
      const { current } = this.state;
      const paginationProps = {
        current,
        total: users.length,
        onChange: page => this.setCurrentPage(page),
      };
      if (users.length === 0) return null;
      const actions = singleActions({ onEdit: this.editUser, onDelete: this.deleteUser });

      return (
        <div className="fiq-invite-users-table">
          <div className="fiq-invite-users-table__add-user">
            <Button icon="Plus" onClick={this.addUser}>
              {text.ADD_USER}
            </Button>
          </div>
          <DataGrid
            gridOptions={{ onViewportChanged: this.sizeColumnsToFit }}
            onGridReady={this.onGridReady}
            columnDefs={columns(actions)}
            rowData={isInviteUsersTableReady ? users : []}
            pagination={paginationProps}
            onRowDataChanged={this.onDataChanged}
          />
          {inviteRequestStatus === 'success' && <Redirect to="/settings/users" />}
        </div>
      );
    }
  },
));


InviteUsersTable.wrappedComponent.propTypes = propTypes;

export default InviteUsersTable;
