import React from 'react';
import { withI18n } from '@lingui/react';
import { Button, Space } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object, string, bool } from 'prop-types';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import page_not_found from 'assets/images/page-not-found.svg';

import './NoContentPage.scss';

const text = {
  PAGE_NOT_FOUND: 'This page could not be found.',
  PREVIOUS_PAGE_DESCRIPTION: 'You can return to previous page by clicking the button below.',
  PREVIOUS_PAGE_BUTTON: 'Back to Previous page',
  LOGOUT: 'Logout',
};

const propTypes = {
  history: object.isRequired,
  body: string,
  title: string,
  image: object,
  i18n: object.isRequired,
  fullScreen: bool,
};

const defaultProps = {
  body: null,
  title: null,
  image: null,
  fullScreen: false,
};

const NoContentPage = ({ history, body, title, image, i18n, fullScreen }) => {
  const { logout } = useAuth0();

  return (
    <div
      className={classNames('fiq-not-found', 'tc-paragraph-regular', {
        'fiq-not-found__with-background': fullScreen,
      })}
    >
      {image || <img src={page_not_found} alt="Not Found" />}
      <Space size={16} />
      <div className="tc-heading-l">{title || i18n._(text.PAGE_NOT_FOUND)}</div>
      <Space size={8} />
      <span className="tc-paragraph-regular text-neutral-700">
        {body || i18n._(text.PREVIOUS_PAGE_DESCRIPTION)}
      </span>
      <Space size={24} />
      <Button onClick={history.goBack} color="primary">
        {text.PREVIOUS_PAGE_BUTTON}
      </Button>
      <Space size={24} />
      {fullScreen && (
        <Button
          onClick={() => logout({ returnTo: window.location.origin })}
          iconPosition="left"
          icon="Logout"
        >
          {text.LOGOUT}
        </Button>
      )}
    </div>
  );
};

NoContentPage.propTypes = propTypes;
NoContentPage.defaultProps = defaultProps;

export default withI18n()(withRouter(NoContentPage));
