import UserDetails from './UserDetails';
import ChangePassword from './ChangePassword';
import EmailIntegrations from './EmailIntegrations';
import NotificationPreferences from './NotificationPreferences';
import ExportData from './ExportData';

const sections = [
  UserDetails,
  ChangePassword,
  EmailIntegrations,
  NotificationPreferences,
  ExportData,
];

export default sections;
