/* eslint-disable react/prop-types */
import React, { Fragment, useState, useCallback } from 'react';
import { withI18n } from '@lingui/react';

import { Button, Space } from 'tc-biq-design-system';

import BulkManageTags, {
  openBulkManageTags,
} from 'Contacts/components/overlays/ManageTags/BulkManageTags';
import BulkAssignTo, { openBulkAssignTo } from 'Contacts/components/overlays/AssignTo/BulkAssignTo';
import LeadImportModal from 'Contacts/components/overlays/LeadImport';
import BulkLeadImportModal from 'Contacts/components/overlays/BulkLeadImport';

import PreviewTrackingLink from 'Marketing/components/overlays/PreviewTrackingLink';
import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';

export const tableConfig = {
  tableUrl: '/contacts/',
  tableKey: 'contacts',
};

const filterOptions = {
  excluded: ['avatar'],
};

const SIDEPANEL_HANDLERS = {
  BULK_MANAGE_TAGS: openBulkManageTags,
  BULK_ASSIGN_TO: openBulkAssignTo,
};

const BulkActionWrapper = ({ icon, label, sidepanelId }) => ({ selectedItems, selectedAll }) => (
  <Fragment>
    <Space size={12} />
    <Button
      color="transparent"
      size="small"
      onClick={() => SIDEPANEL_HANDLERS[sidepanelId](selectedItems, selectedAll)}
      icon={icon}
    >
      {label}
    </Button>
  </Fragment>
);

const bulkActions = [
  {
    Component: BulkActionWrapper({
      icon: 'Tag',
      label: 'Manage tags',
      sidepanelId: 'BULK_MANAGE_TAGS',
    }),
  },
  {
    Component: BulkActionWrapper({
      icon: 'User',
      label: 'Assign to',
      sidepanelId: 'BULK_ASSIGN_TO',
    }),
  },
];
const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const ContactsTable = () => {
  const [apiGrid, setApiGrid] = useState(null);

  const onSuccess = useCallback(() => {
    apiGrid.deselectAll();
    gridActions.fetchTableData();
  }, [apiGrid, gridActions]);

  return (
    <Fragment>
      <GridComponent
        checkboxSelection
        modifiers={modifiers}
        filterOptions={filterOptions}
        enableSegmentFiltering
        bulkActions={bulkActions}
        getGridApi={gridApi => setApiGrid(gridApi)}
      />
      <BulkManageTags onSuccess={onSuccess} />
      <BulkAssignTo onSuccess={onSuccess} />
      <LeadImportModal onSuccess={onSuccess} />
      <BulkLeadImportModal />
      <PreviewTrackingLink />
    </Fragment>
  );
};

export default withI18n()(ContactsTable);
