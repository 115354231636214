import { action, observable, runInAction, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';

import {
  fetchTradeData,
  fetchPaymentData,
  fetchWalletData,
  fetchLedgerData,
  fetchWalletBalance,
  fetchOrderData,
  fetchWithdrawalData,
  fetchPositionData,
  fetchCardData,
} from 'Transactions/services/transactionsService';
import { parseParamString } from 'App/components/Filters/filterStoreUtils';

export default class TransactionsStore {
  tradeData = {};

  paymentData = {};

  withdrawalData = {};

  orderData = {};

  walletData = {};

  ledgerData = [];

  positionData = {};

  cardData = {};

  requestInProgress = {
    ledgerData: false,
  };

  hasMore = true;

  ledgerQuery = {
    cursor: '',
    limit: 20,
  };

  walletBalanceData = {};

  constructor() {
    makeObservable(this, {
      tradeData: observable,
      paymentData: observable,
      withdrawalData: observable,
      orderData: observable,
      walletData: observable,
      ledgerData: observable,
      positionData: observable,
      cardData: observable,
      requestInProgress: observable,
      hasMore: observable,
      ledgerQuery: observable,
      walletBalanceData: observable,
      fetchTradeData: action.bound,
      fetchPaymentData: action.bound,
      fetchWithdrawalData: action.bound,
      fetchOrderData: action.bound,
      fetchWalletData: action.bound,
      fetchPositionData: action.bound,
      fetchCardData: action.bound,
      fetchLedgerData: action.bound,
      resetData: action.bound,
      fetchWalletBalance: action.bound,
    });
  }

  async fetchTradeData(id) {
    const response = await fetchTradeData(id);
    runInAction(() => {
      this.tradeData = response.data;
    });
  }

  async fetchPaymentData(id) {
    const response = await fetchPaymentData(id);
    runInAction(() => {
      this.paymentData = response.data;
    });
  }

  async fetchWithdrawalData(id) {
    const response = await fetchWithdrawalData(id);
    runInAction(() => {
      this.withdrawalData = response.data;
    });
  }

  async fetchOrderData(id) {
    const response = await fetchOrderData(id);
    runInAction(() => {
      this.orderData = response.data;
    });
  }

  async fetchWalletData(id) {
    try {
      const response = await fetchWalletData(id);
      runInAction(() => {
        this.walletData = response.data;
      });
    } catch (err) {
      notifier.error('Failed to fetch wallet data');
    }
  }

  async fetchPositionData(id) {
    const response = await fetchPositionData(id);
    runInAction(() => {
      this.positionData = response.data;
    });
  }

  async fetchCardData(id) {
    const response = await fetchCardData(id);
    runInAction(() => {
      this.cardData = response.data;
    });
  }

  async fetchLedgerData(id, isOnScroll) {
    this.requestInProgress.ledgerData = true;
    const response = await fetchLedgerData(id, this.ledgerQuery);
    const { next, results } = response.data;
    const params = parseParamString(next);
    runInAction(() => {
      this.hasMore = !!next;
      this.ledgerQuery.cursor = params.cursor;
      this.ledgerData = isOnScroll ? [...this.ledgerData, ...results] : response.data.results;
      this.requestInProgress.ledgerData = false;
    });
  }

  resetData() {
    this.ledgerData = [];
    this.orderData = {};
    this.ledgerQuery.cursor = '';
    this.tradeData = {};
    this.walletData = {};
    this.paymentData = {};
    this.positionData = {};
  }

  async fetchWalletBalance(id) {
    try {
      const response = await fetchWalletBalance(id);
      runInAction(() => {
        this.walletBalanceData = response.data;
      });
    } catch (err) {
      // TODO: Show different error if trading provider isn't configured.
      notifier.error('Failed to retrieve wallet balance');
      runInAction(() => {
        this.walletBalanceData = {
          available: '-',
          reserved: '-',
          total: '-',
        };
      });
    }
  }
}
