import React from 'react';

import { Dropdown, Space, Icon } from 'tc-biq-design-system';
import { func, array } from 'prop-types';

const text = {
  CREATE_AD_AUDIENCE: 'Create Ad Audience',
};

const customHeader = toggle => (
  <div onClick={toggle} style={{ display: 'flex', alignItems: 'center', width: '150px', cursor: 'pointer' }}>
    <span className="text-primary-500 tc-paragraph-strong">{text.CREATE_AD_AUDIENCE}</span>
    <Space size={6} />
    <Icon size="small" colorName="text-primary-500" name="CaretDown" />
  </div>
);

const formatEventList = (onClick, events) => events.map(event => ({
  item: action => (
    <div onClick={action} className="segment-preview__dropdown__list-item">
      <Icon name={event.icon} />
      <Space size={8} />
      <span>{event.displayName}</span>
    </div>
  ),
  onClick: () => onClick(event),
}));

const SegmentDropdown = ({ onClick, events }) => {
  const customList = formatEventList(onClick, events);
  return <Dropdown customHeader={customHeader} customList={customList} />;
};

SegmentDropdown.propTypes = {
  onClick: func.isRequired,
  events: array.isRequired,
};


export default SegmentDropdown;
