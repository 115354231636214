import React from 'react';
import { withComboxManager, Dropdown } from 'tc-biq-design-system';
import { object } from 'prop-types';

import { hasAccess } from 'App/services/permissionsService';
import LogCall from 'Contacts/components/ContactActions/actions/ManuallyLog/LogCall';

import './ManuallyLog.scss';

const propTypes = {
  comboxManager: object.isRequired,
};

const text = {
  LABEL: 'Add manual log',
  CALL_LABEL: 'Log a call',
};

const actions = {
  logCall: (comboxManager) => {
    const comboxOptions = {
      title: text.LABEL,
      id: 'log-call',
    };
    comboxManager.add(LogCall, comboxOptions);
  },
};

const customList = comboxManager => [
  {
    item: onSelected => <div className="fiq-manually-log__list-item" onClick={onSelected}>{text.CALL_LABEL}</div>,
    permission: hasAccess('communications_calllog', 'create'),
    onClick: () => actions.logCall(comboxManager),
  },
].filter(action => action.permission);

const ManuallyLog = ({ comboxManager }) => {
  if (customList(comboxManager).length === 0) return null;
  return (
    <Dropdown openLeft title={text.LABEL} customList={customList(comboxManager)} />
  );
};

ManuallyLog.propTypes = propTypes;

export default withComboxManager(ManuallyLog);
