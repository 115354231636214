import React, { useState, useEffect } from 'react';
import Field from 'App/components/FieldRenderer';
import { object, func, array, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Space, Select, Toggle, Tooltip, Icon } from 'tc-biq-design-system';

const propTypes = {
  fieldsProps: object.isRequired,
  days: array,
  updateFieldValue: func.isRequired,
  recurring_type: object,
  toggleRecurringCampaign: func.isRequired,
  isEnabled: bool.isRequired,
};

const defaultProps = {
  recurring_type: null,
  days: null,
};

const text = {
  DAYS_PLACEHOLDER: 'Choose days of the week',
  DAYS_LABEL: 'Days',
  TIME_LABEL: 'Time',
  TYPE_LABEL: 'Interval',
  TOGGLE_DESCRIPTION: 'Is contact able to enter the campaign multiple times?',
  RECURRING_DESCRIPTION: 'How often should a contact re-enter the campaign?',
  RECURRING_DESCRIPTION_HELP_TEXT:
    'Contact can start a new journey only if he exited previous one.',
};

const WEEKLY_RECURRING_TYPE = 'Weekly';

const DAYS_OPTIONS = [
  { id: 1, label: 'Monday', group: 'Days' },
  { id: 2, label: 'Tuesday', group: 'Days' },
  { id: 3, label: 'Wednesday', group: 'Days' },
  { id: 4, label: 'Thursday', group: 'Days' },
  { id: 5, label: 'Friday', group: 'Days' },
  { id: 6, label: 'Saturday', group: 'Days' },
  { id: 7, label: 'Sunday', group: 'Days' },
];

const RecurringForm = ({
  days,
  updateFieldValue,
  fieldsProps,
  recurring_type,
  toggleRecurringCampaign,
  isEnabled,
}) => {
  const value = days ? days.map(day => DAYS_OPTIONS.find(o => o.id === day)) : null;
  const [selectedDays, selectDays] = useState(value);

  // Handle the case of value from the API and from the select (choice) field.
  const recurringType = recurring_type && (recurring_type.value || recurring_type);
  // Reset the value if weekly type isn't set.

  const resetRecurringData = () => {
    updateFieldValue('recurring_type_value', null);
    updateFieldValue('recurring_time', null);
    updateFieldValue('recurring_type', null);
  };

  useEffect(() => {
    if (recurringType && recurringType !== WEEKLY_RECURRING_TYPE) {
      updateFieldValue('recurring_type_value', null);
    }
  }, [recurringType]);

  useEffect(() => () => {
    resetRecurringData();
    toggleRecurringCampaign(false);
  }, []);

  const onChange = (selected) => {
    updateFieldValue(
      'recurring_type_value',
      selected.map(day => day.id),
    );
    selectDays(selected);
  };

  return (
    <div>
      <div className="fiq-campaign-form__description-wrapper">
        <Toggle checked={isEnabled} onChange={toggleRecurringCampaign} />
        <Space size={12} />
        <span className="tc-paragraph-regular">{text.TOGGLE_DESCRIPTION}</span>
      </div>
      <Space size={20} />
      {isEnabled && (
        <div>
          <div className="fiq-campaign-form__description-wrapper">
            <span className="tc-paragraph-regular">{text.RECURRING_DESCRIPTION}</span>
            <Space size={12} />
            <Tooltip title={text.RECURRING_DESCRIPTION_HELP_TEXT} placement="right">
              <Icon colorName="text-neutral-500" size="small" name="Info" />
            </Tooltip>
          </div>
          <Space size={20} />
          <div className="fiq-campaign-form__recurring-type-form">
            <div style={{ width: '30%' }}>
              <Field
                {...fieldsProps.recurring_type}
                label={text.TYPE_LABEL}
                formId="campaignForm"
                fieldKey="recurring_type"
              />
            </div>
            <Space size={20} />
            {recurringType && recurringType === WEEKLY_RECURRING_TYPE && (
              <div style={{ width: '65%' }}>
                <Select
                  type="multi"
                  placeholder={text.DAYS_PLACEHOLDER}
                  label={text.DAYS_LABEL}
                  options={DAYS_OPTIONS}
                  valueKey="id"
                  onChange={onChange}
                  value={selectedDays}
                />
              </div>
            )}
          </div>
          <Space size={4} />
          <div style={{ width: '30%' }}>
            <Field
              {...fieldsProps.recurring_time}
              label={text.TIME_LABEL}
              type="time"
              formId="campaignForm"
              fieldKey="recurring_time"
            />
          </div>
        </div>
      )}
    </div>
  );
};

RecurringForm.propTypes = propTypes;
RecurringForm.defaultProps = defaultProps;

export default inject(stores => ({
  recurring_type: stores.forms.campaignForm.data.recurring_type,
  days: stores.forms.campaignForm.data.recurring_type_value,
  updateFieldValue: stores.forms.campaignForm.updateFieldValue,
  toggleRecurringCampaign: stores.campaigns.toggleRecurringCampaign,
  isEnabled: stores.campaigns.isRecurringEnabled,
}))(observer(RecurringForm));
