import React, { useEffect, Fragment } from 'react';
import { object, arrayOf, shape, string, func, node, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import LoadingPage from 'App/components/LoadingPage';
import ExportData from 'App/components/ExportData';
import * as CommandbarService from 'App/services/commandbarService';
import PageHeader from './PageHeader';

import './Page.scss';

const BREAD = {
  label: string.isRequired,
  route: string,
};

const EXPORT = {
  path: string.isRequired,
  permission: string.isRequired,
  tableKey: string.isRequired,
};

const propTypes = {
  bread: arrayOf(shape(BREAD)),
  headerActions: func,
  children: node.isRequired,
  style: object,
  hideHeader: bool,
  title: string,
  goBackIcon: bool,
  requestInProgress: bool,
  exportDataOptions: shape(EXPORT),
};

const defaultProps = {
  bread: [],
  headerActions: null,
  style: null,
  hideHeader: false,
  title: null,
  goBackIcon: true,
  requestInProgress: false,
  exportDataOptions: null,
};

const text = {
  HOME_LABEL: 'Infuse',
};

const getHeaderProps = ({ headerActions, bread, title, goBackIcon, exportDataOptions }) => ({
  pageTitle: title || '',
  breads: bread.length ? [{ label: text.HOME_LABEL }, ...bread] : [],
  actions: headerActions ? headerActions() : null,
  goBackIcon,
  exportDataOptions,
});

const setTitle = (title) => {
  if (title) {
    document.title = title;
  }
};

const exportDataDecorator = exportOptions => inject(stores => ({
  exportData: stores.tables[exportOptions.tableKey].exportData,
}))(observer(({ exportData, actions, ...props }) => {
  const { permission, path } = exportOptions;
  const ActionsWithExport = (
    <div className="fiq-page__header-actions">
      <ExportData exportData={exportData} permission={permission} path={path} />
      {actions}
    </div>
  );
  return <PageHeader {...props} actions={ActionsWithExport} />;
}));

const Page = ({
  children,
  headerActions,
  bread,
  style,
  hideHeader,
  title,
  goBackIcon,
  requestInProgress,
  exportDataOptions,
}) => {
  if (requestInProgress) return <LoadingPage />;
  const history = useHistory();
  useEffect(() => setTitle(title), [title]);
  useEffect(() => {
    const routerFunc = url => history.push(url);
    CommandbarService.addRouter(routerFunc);
  }, []);

  const PageHeaderWithExport = exportDataOptions ? exportDataDecorator(exportDataOptions) : null;
  return (
    <div className="fiq-page">
      {!hideHeader && (
        <Fragment>
          {!exportDataOptions && (
          <PageHeader
            {...getHeaderProps({ headerActions, bread, title, goBackIcon })}
          />
          )}
          {exportDataOptions && (
          <PageHeaderWithExport
            {...getHeaderProps({ headerActions, bread, title, goBackIcon })}
          />
          )}
        </Fragment>
      )}
      <div className="fiq-page__content" style={style}>
        {children}
      </div>
    </div>
  );
};

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default withErrorBoundary(Page);
