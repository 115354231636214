import React, { Fragment } from 'react';
import moment from 'moment';
import { Row, Col } from 'tc-biq-design-system';
import { array, string } from 'prop-types';

import DisplayDetail from 'App/components/DisplayDetail';

const formatDate = date => moment(date, moment.ISO_8601).format('MMMM Do YYYY, \\at HH:mm');

const propTypes = {
  from_email_address: string.isRequired,
  to_email_addresses: array.isRequired,
  subject: string.isRequired,
  external_id: string.isRequired,
  created: string.isRequired,
};

const text = {
  EXTERNAL_ID: 'External ID',
  FROM_EMAIL_ADDRESS: 'From Email Address',
  TO_EMAIL_ADDRESSES: 'To Email Addresses',
  SUBJECT: 'Subject',
  CREATED: 'Created',
};

const formatEmailAddresses = emails => emails.map(email => <div style={{ width: '100%' }}>{email}</div>);

const EmailLogDetails = ({
  from_email_address,
  to_email_addresses,
  subject,
  external_id,
  created,
}) => (
  <Fragment>
    <Row gutter={0}>
      <Col xs="20%" gutter={0}>
        <DisplayDetail header={text.FROM_EMAIL_ADDRESS} value={from_email_address} />
      </Col>
      <Col xs="20%" gutter={0}>
        <DisplayDetail
          header={text.TO_EMAIL_ADDRESSES}
          value={formatEmailAddresses(to_email_addresses)}
        />
      </Col>
      <Col xs="20%" gutter={0}>
        <DisplayDetail header={text.SUBJECT} value={subject} />
      </Col>
      <Col xs="20%" gutter={0}>
        <DisplayDetail header={text.EXTERNAL_ID} value={external_id} />
      </Col>
      <Col xs="20%" gutter={0}>
        <DisplayDetail header={text.CREATED} value={formatDate(created)} />
      </Col>
    </Row>
  </Fragment>
);

EmailLogDetails.propTypes = propTypes;

export default EmailLogDetails;
