import NotesStore from './Notes/NotesStore';
import KycStore from './KYC/KycStore';
import SignupDetailsStore from './SignupDetails/SignupDetailsStore';
import MifidStore from './MIFID/MIFIDStore';
import DocumentsStore from './Documents/DocumentsStore';

export default {
  notes: new NotesStore(),
  kyc: new KycStore(),
  signupDetails: new SignupDetailsStore(),
  mifid: new MifidStore(),
  documents: new DocumentsStore(),
};
