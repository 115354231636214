import React from 'react';
import { withRouter } from 'react-router-dom';

import Page from 'App/components/Page';
import { CustomEventForm } from 'Settings/Sections/Events/components';
import { object } from 'prop-types';

const propTypes = {
  match: object.isRequired,
};

const text = {
  PAGE_TITLE: 'Create Custom Event',
};

const CustomEventPage = ({ match }) => (
  <Page title={text.PAGE_TITLE} style={{ margin: '0 20px' }}>
    <CustomEventForm id={match.params.eventId} />
  </Page>
);

CustomEventPage.propTypes = propTypes;

export default withRouter(CustomEventPage);
