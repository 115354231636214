import React, { useState } from 'react';
import { Icon, Space, Toggle, Popconfirm } from 'tc-biq-design-system';
import { func, object } from 'prop-types';
import { inject, observer } from 'mobx-react';

import Loader from 'App/components/Loader';
import { openOverlay } from 'App/services/overlayService';
import { SIDEPANEL_ID as MANAGE_USERS_SIDEPANEL_ID } from 'App/components/ManageUsersFactory/ManageUsersFactory';
import { SIDEPANEL_ID as ADD_SIGNATURE_SIDEPANEL_ID } from 'Settings/Sections/Users/components/UserSections/EmailIntegrations/AddSignature';
import InboxInfo from 'Settings/Sections/Users/components/UserSections/EmailIntegrations/InboxInfo';
import { UserCell } from 'App/components/gridCellRenderers';
import extractDisplayName from 'App/services/utilities/extractDisplayName';

const propTypes = {
  deleteEmailInbox: func.isRequired,
  inbox: object.isRequired,
  revokeEmailInbox: func.isRequired,
  reconnectEmailInbox: func.isRequired,
};

const text = {
  DELETE_Q: 'Are you sure you want to delete inbox',
  BUTTON_LABELS: {
    confirm: 'Delete',
    cancel: 'Cancel',
  },
  SHARE_WITH: 'Share with',
  SHARED_BY: 'Shared by',
  ADD_SIGNATURE: 'Add signature',
  EDIT_SIGNATURE: 'Edit signature',
};

const InboxCard = ({ inbox, deleteEmailInbox, revokeEmailInbox, reconnectEmailInbox }) => {
  const { id, email, provider, is_active, shared_bindings, shared_by, signature } = inbox;
  const [isActive, toggleIsActive] = useState(is_active);
  const [isLoading, setLoading] = useState(false);

  const toggleInbox = async (checked) => {
    toggleIsActive(checked);
    setLoading(true);
    if (checked) {
      await reconnectEmailInbox(email, window.location.href);
    } else {
      await revokeEmailInbox(id, () => toggleIsActive(true));
      setLoading(false);
    }
  };

  const deleteInbox = async () => {
    setLoading(true);
    await deleteEmailInbox(id);
    setLoading(false);
  };

  const share = () => {
    openOverlay(MANAGE_USERS_SIDEPANEL_ID, {
      name: text.SHARE_WITH,
      id,
      bindings: shared_bindings,
    });
  };

  const addSignature = () => {
    openOverlay(ADD_SIGNATURE_SIDEPANEL_ID, { ...inbox });
  };

  return (
    <div className="fiq-user-email-integrations__card" data-recording-sensitive>
      {!shared_by && <Toggle checked={isActive} onChange={toggleInbox} />}
      {!shared_by && <Space size={32} />}
      <InboxInfo email={email} provider={provider} />
      <div className="fiq-user-email-integrations__email">
        <Space size={4} />
        <Loader visible={isLoading} />
      </div>
      {!shared_by && (
        <div className="fiq-user-email-integrations__add-signature-inbox" onClick={addSignature}>
          <span className="tc-paragraph-strong text-primary-500">
            {(signature && text.EDIT_SIGNATURE) || text.ADD_SIGNATURE}
          </span>
        </div>
      )}
      {!shared_by && <Space size={32} />}
      {!shared_by && (
        <div className="fiq-user-email-integrations__share-inbox" onClick={share}>
          <Icon name="Share" size="small" />
          <Space size={8} />
          <span className="tc-paragraph-strong">{`(${shared_bindings})`}</span>
        </div>
      )}
      {!shared_by && <Space size={32} />}
      {!shared_by && (
        <div className="fiq-user-email-integrations__delete-inbox">
          <Popconfirm
            type="destructive"
            buttonLabels={text.BUTTON_LABELS}
            icon="Delete"
            label={text.DELETE_Q}
            onConfirm={deleteInbox}
            placement="bottom"
          >
            <Icon name="Delete" size="small" />
          </Popconfirm>
        </div>
      )}
      {shared_by && (
        <div className="fiq-user-email-integrations__shared-by">
          <span className="tc-micro-regular text-neutral-700 fiq-user-email-integrations__shared-by--text">
            {text.SHARED_BY}
          </span>
          <Space size={8} />
          <UserCell fullName={extractDisplayName(shared_by)} {...shared_by} />
        </div>
      )}
    </div>
  );
};

InboxCard.propTypes = propTypes;

export default inject(stores => ({
  deleteEmailInbox: stores.usersStore.deleteEmailInbox,
  revokeEmailInbox: stores.usersStore.revokeEmailInbox,
  reconnectEmailInbox: stores.usersStore.reconnectEmailInbox,
}))(observer(InboxCard));
