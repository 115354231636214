import React from 'react';

import {
  RouteCell,
  StatusCell,
  PaymentTypeCell,
  WalletCell,
  AmountCell,
} from 'App/components/gridCellRenderers';

export default {
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/transactions/payments/'),
  },
  state: { cellRendererFramework: StatusCell },
  type: { cellRendererFramework: PaymentTypeCell },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => (
      <WalletCell data={value} />
    ),
  },
  amount: {
    cellRendererFramework: AmountCell,
  },
  sortable: false,
};
