import { observable, action, computed, makeObservable } from 'mobx';
import { find } from 'lodash';

import {
  notSame,
  formatToQueries,
  extractNestedFields,
  formatSavedFilters,
  setDefaultFilter,
  extractActiveFilters,
  matchFilter,
} from 'App/components/Filters/filterStoreUtils';
import stores from 'App/rootStore';

const initialValues = {
  savedFilters: {
    created: [],
    favorites: [],
    shared: [],
  },
};

export default class FiltersStore {
  fields = {};

  activeFilters = [];

  activeFilter = {};

  defaultActiveFilter = {};

  savedFilters = initialValues.savedFilters;

  rawSavedFilters = null;

  filterQuery = {};

  activeSegment = null;

  segmentFilterQuery = {};

  viewName = null;

  constructor() {
    makeObservable(this, {
      fields: observable,
      activeFilters: observable,
      activeFilter: observable,
      defaultActiveFilter: observable,
      savedFilters: observable,
      rawSavedFilters: observable,
      filterQuery: observable,
      activeSegment: observable,
      segmentFilterQuery: observable,
      viewName: observable,
      addNewFilter: action.bound,
      removeFilter: action.bound,
      resetFilters: action.bound,
      updateFilterQuery: action.bound,
      updateActiveFilters: action.bound,
      updateFields: action.bound,
      updateViewName: action.bound,
      updateSavedFilters: action.bound,
      setActiveFilter: action.bound,
      emptyActiveFilter: action.bound,
      addSegmentFilter: action.bound,
      removeSegmentFilter: action.bound,
      query: computed,
    });
  }

  // Filter actions

  addNewFilter(newFilter) {
    const formattedFilter = formatToQueries([newFilter]);
    Object.keys(formattedFilter).forEach((key) => {
      if (this.filterQuery[key] !== undefined || this.filterQuery[key] === false) {
        this.activeFilters = this.activeFilters.map(filter =>
          // eslint-disable-next-line
          (matchFilter(filter, newFilter) ? { ...filter, value: newFilter.value } : filter));
      } else {
        this.activeFilters = this.activeFilters.slice().concat(newFilter);
      }
    });
    this.filterQuery = { ...formatToQueries(this.activeFilters) };
  }

  removeFilter(filter) {
    this.activeFilters = this.activeFilters
      .slice()
      .filter(activeFilter => notSame(activeFilter, filter));
    this.filterQuery = { ...formatToQueries(this.activeFilters) };
  }

  resetFilters() {
    this.activeFilters = [];
    this.activeFilter = {};
    this.segmentFilterQuery = {};
    this.activeSegment = null;
    this.filterQuery = {};
    this.savedFilters = initialValues.savedFilters;
    this.rawSavedFilters = null;
    this.defaultActiveFilter = {};
    this.viewName = null;
  }

  updateFilterQuery(filterQuery) {
    this.filterQuery = { ...filterQuery };
  }

  updateActiveFilters(activeFilters) {
    this.activeFilters = [...activeFilters];
  }

  updateFields(fields, excludeFilters) {
    this.fields = extractNestedFields({ ...fields }, true, true, excludeFilters);
  }

  updateViewName(name) {
    this.viewName = name;
  }

  updateSavedFilters(savedFilters) {
    this.savedFilters = formatSavedFilters(savedFilters, stores.loginStore.user.id);
    this.rawSavedFilters = savedFilters;
    this.defaultActiveFilter = setDefaultFilter(this.activeFilter, savedFilters);
  }

  setActiveFilter(filterId) {
    const activeFilter = find(this.rawSavedFilters, { id: filterId });
    this.activeFilter = activeFilter;
    this.filterQuery = { ...activeFilter.filter.filters };
    this.activeFilters = extractActiveFilters(activeFilter.filter.filters, this.fields);
  }

  mapFiltersFromUrl(filters) {
    this.activeFilters = extractActiveFilters(filters, this.fields);
    this.filterQuery = { ...filters };
  }

  emptyActiveFilter() {
    this.activeFilter = {};
  }

  // Segment Filter actions

  addSegmentFilter(segmentFilter) {
    this.segmentFilterQuery = {
      segment_ids: segmentFilter.value.id,
    };
    this.activeSegment = segmentFilter.value;
  }

  removeSegmentFilter() {
    this.segmentFilterQuery = {};
    this.activeSegment = null;
  }

  get query() {
    return { ...this.filterQuery, ...this.segmentFilterQuery };
  }
}
