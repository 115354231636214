import React from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import { notifier } from 'tc-biq-design-system';

import GridComponentFactory from 'App/components/grid';
import { hasAccess } from 'App/services/permissionsService';
import { deleteWebhook } from 'Settings/Sections/Webhooks/services/webhooksService';
import modifiers from './modifiers';

const propTypes = {
  history: object.isRequired,
};

const tableConfig = {
  tableUrl: '/automations/webhooks/',
  tableKey: 'webhooks',
};

const listToOmit = ['created', 'updated', 'url', 'last_delivery_status'];

const text = {
  DELETE_WEBHOOK: 'Are you sure you want to delete this webhook',
  CANCEL: 'Cancel',
  CONFIRM: 'Delete',
  DELETE_SUCCESS: 'Successfully deleted webhook',
  DELETE_ERROR: 'Failed to delete webhook',
};

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const singleActions = ({ onEdit, onDelete }) => [
  {
    icon: { name: 'Edit', color: 'text-neutral-900', size: 'small' },
    onClick: onEdit,
    label: 'Edit',
    visibility: hasAccess('automations_webhook', 'update'),
  },
  {
    icon: { name: 'Delete', color: 'text-neutral-900', size: 'small' },
    onClick: onDelete,
    label: 'Delete',
    visibility: hasAccess('automations_webhook', 'delete'),
    popconfirm: {
      type: 'destructive',
      placement: 'bottom',
      icon: 'Delete',
      label: text.DELETE_WEBHOOK,
      buttonLabels: { cancel: text.CANCEL, confirm: text.CONFIRM },
    },
  },
].filter(action => action.visibility);

const customColumns = [
  {
    key: 'test_connection',
    label: 'Test connection',
  },
  {
    key: 'actions',
    label: '',
  },
];

const WebhooksTable = ({ history }) => {
  const onEdit = (data) => {
    history.push(`/settings/webhooks/edit/${data.id}/`);
  };
  const onDelete = async (data) => {
    try {
      await deleteWebhook(data.id);
      notifier.success(text.DELETE_SUCCESS);
      gridActions.fetchTableData();
    } catch {
      notifier.error(text.DELETE_ERROR);
    }
  };
  return (
    <div style={{ margin: '0 20px' }}>
      <GridComponent
        customColumns={customColumns}
        modifiers={modifiers(singleActions({ onEdit, onDelete }))}
        listToOmit={listToOmit}
      />
    </div>
  );
};

WebhooksTable.propTypes = propTypes;

export default withRouter(WebhooksTable);
