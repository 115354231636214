import React, { Component } from 'react';
import { Banner } from 'tc-biq-design-system';
import { Trans } from '@lingui/macro';
import * as Sentry from '@sentry/browser';

Sentry.init({ dsn: SENTRY_DSN });

const renderError = () => (
  <Banner close={false} type="error">
    <Trans>Oops, something went wrong. Please reload the page.</Trans>
  </Banner>
);

const getDisplayName = WrappedComponent => WrappedComponent.displayName || WrappedComponent.name || 'Component';

export const withErrorBoundary = (WrappedComponent) => {
  class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
      this.setState({ hasError: true });
      if (!['', 'localhost', '127.0.0.1', 'fix-fiq'].includes(window.location.hostname)) {
        Sentry.withScope((scope) => {
          Object.keys(info).forEach((key) => {
            scope.setExtra(key, info[key]);
          });
          Sentry.captureException(error);
        });
      }
    }

    render() {
      const { hasError } = this.state;
      return hasError ? renderError() : <WrappedComponent {...this.props} />;
    }
  }
  ErrorBoundary.displayName = `WithErrorBoundary(${getDisplayName(WrappedComponent)})`;
  return ErrorBoundary;
};

export default withErrorBoundary;
