import React from 'react';
import { Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import { RolesTable } from 'Settings/Sections/Roles/components';

const propTypes = {
  history: object.isRequired,
};

const text = {
  PAGE_TITLE: 'Roles',
  CREATE_ROLE: 'Create new role',
};
const bread = [{ label: 'Settings', route: '/settings' }, { label: 'Roles', route: '/settings/roles' }];

const RolesPage = ({ history }) => {
  const headerActions = () => (
    <Button onClick={() => history.push('/settings/roles/create')}>{text.CREATE_ROLE}</Button>
  );
  return (
    <Page
      goBackIcon
      style={{ margin: '0 20px' }}
      title={text.PAGE_TITLE}
      headerActions={headerActions}
      bread={bread}
    >
      <RolesTable />
    </Page>
  );
};

RolesPage.propTypes = propTypes;

export default withRouter(RolesPage);
