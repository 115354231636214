import React from 'react';
import { inject, observer } from 'mobx-react';
import { Sidepanel, Space } from 'tc-biq-design-system';
import { object, bool } from 'prop-types';
import moment from 'moment';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { UserCell, ContactCell } from 'App/components/gridCellRenderers';
import truncateText from 'App/services/utilities/truncateText';
import NoDataDash from 'App/components/NoDataDash';
import extractDisplayName from 'App/services/utilities/extractDisplayName';

const propTypes = {
  parameters: object,
  visible: bool,
};

const defaultProps = {
  parameters: {},
  visible: false,
};

const text = {
  ASSIGNED_TO: 'Assigned to',
  CONNECTED_TO: 'Connected to',
  DUE_DATE: 'Due date',
};

const SIDEPANEL_ID = 'PREVIEW_TASK';

export const openPreviewTask = task => openOverlay(SIDEPANEL_ID, { task });
export const closePreviewTask = () => closeOverlay(SIDEPANEL_ID);

// eslint-disable-next-line
const TaskDetail = ({ children, title }) => (
  <div className="fiq-tasks__preview-task__wrapper__detail">
    <div className="tc-micro-regular text-neutral-500">{title}</div>
    <Space size={8} />
    {children}
  </div>
);

const PreviewTask = inject(stores => ({
  parameters: stores.overlayStore.overlay.parameters,
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
}))(
  observer(({ parameters, visible }) => {
    if (!parameters?.task || !visible) return null;
    const { task } = parameters;
    const { assigned_to, connected_to } = task;
    const forrmatedDueDate = moment(task.due_date, moment.ISO_8601).format(
      'YYYY-MM-DD \\· HH:mm \\(\\G\\MT Z\\)',
    );
    return (
      <Sidepanel
        icon="CalendarCreate"
        title={truncateText(task.title)}
        visible={visible}
        onCloseIconClick={() => closeOverlay(SIDEPANEL_ID)}
        footerRender={() => null}
      >
        <div className="fiq-tasks__preview-task">
          <div className="fiq-tasks__preview-task__wrapper">
            <div className="tc-paragraph-regular">{task.title}</div>
            <Space size={12} />
            <div className="tc-micro-regular text-neutral-700">{task.description}</div>
          </div>
          <div className="fiq-tasks__preview-task__wrapper">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '50%' }}>
                <TaskDetail title={text.ASSIGNED_TO}>
                  {assigned_to ? (
                    <UserCell
                      fullName={extractDisplayName(assigned_to)}
                      id={assigned_to.id}
                      avatar={assigned_to.avatar}
                    />
                  ) : (
                    <NoDataDash />
                  )}
                </TaskDetail>
              </div>
              <div style={{ width: '50%' }}>
                <TaskDetail title={text.CONNECTED_TO}>
                  {connected_to ? (
                    <div className="align-center">
                      <ContactCell
                        fullName={extractDisplayName(connected_to)}
                        id={connected_to.id}
                        avatar={connected_to.avatar}
                      />
                    </div>
                  ) : (
                    <NoDataDash />
                  )}
                </TaskDetail>
              </div>
            </div>
          </div>
          <div className="fiq-tasks__preview-task__wrapper">
            <TaskDetail title={text.DUE_DATE}>
              <div>{forrmatedDueDate}</div>
            </TaskDetail>
          </div>
        </div>
      </Sidepanel>
    );
  }),
);

PreviewTask.wrappedComponent.propTypes = propTypes;
PreviewTask.wrappedComponent.defaultProps = defaultProps;

export default PreviewTask;
