import React from 'react';
import { node, any, bool } from 'prop-types';

const propTypes = {
  condition: bool,
  children: any,
};

const defaultProps = {
  condition: false,
  children: node,
};

const If = ({ condition, children }) => (
  condition ? <React.Fragment>{children}</React.Fragment> : null
);

If.propTypes = propTypes;
If.defaultProps = defaultProps;

export default If;
