import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Space, notifier } from 'tc-biq-design-system';
import { object, bool, func, array, shape, string } from 'prop-types';

import { closeOverlay } from 'App/services/overlayService';
import UsersList from 'App/components/UsersList/UsersList';
import SidepanelFooter from 'App/components/SidepanelFooter';
import NonFieldErrors from 'App/components/NonFieldErrors';

import './ManageUsers.scss';
import { hasAccess } from 'App/services/permissionsService';

const propTypes = {
  visible: bool,
  parameters: object,
  permissionName: string.isRequired,
  lists: shape(listsShape).isRequired,
  fetchListData: func.isRequired,
  addUserBindings: func.isRequired,
  removeUserBindings: func.isRequired,
  removeAllBindings: func.isRequired,
  updateUserBindings: func.isRequired,
  scrollProps: object.isRequired,
  requestInProgress: object.isRequired,
  resetLists: func.isRequired,
  onSuccess: func.isRequired,
  errors: object.isRequired,
  bindingType: string,
  disableMultiBinding: bool,
  title: string,
  icon: string,
};

const listsShape = {
  users: array.isRequired,
  bindUsers: array.isRequired,
};

const defaultProps = {
  visible: false,
  parameters: null,
  bindingType: '',
  disableMultiBinding: false,
  title: 'Manage users',
  icon: 'User',
};

const text = {
  ALL_USERS: 'All users',
  SUCCESS: 'Successfully updated users',
  ERROR: 'Failed to update users',
  BUTTON_LABELS: {
    confirm: 'Save',
    cancel: 'Cancel',
  },
};

const customFooter = (execute, close, submitInProgress, hasPermission) => (
  <SidepanelFooter
    submitInProgress={submitInProgress.userAction}
    disableSubmit={!hasPermission}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

export const SIDEPANEL_ID = 'MANAGE_USERS_BINDINGS';

const ManageUsersFactory = (storeKey) => {
  const ManageUsers = inject(stores => ({
    visible: stores.overlayStore.overlay[SIDEPANEL_ID],
    parameters: stores.overlayStore.overlay.parameters,
    lists: stores[storeKey].manageUsers.lists,
    fetchListData: stores[storeKey].manageUsers.fetchListData,
    scrollProps: stores[storeKey].manageUsers.scrollProps,
    updateUserBindings: stores[storeKey].manageUsers.updateUserBindings,
    removeUserBindings: stores[storeKey].manageUsers.removeUserBindings,
    addUserBindings: stores[storeKey].manageUsers.addUserBindings,
    requestInProgress: stores[storeKey].manageUsers.requestInProgress,
    resetLists: stores[storeKey].manageUsers.resetLists,
    errors: stores[storeKey].manageUsers.errors,
    removeAllBindings: stores[storeKey].manageUsers.removeAllBindings,
  }))(observer(
    class ManageUsers extends PureComponent {
      constructor(props) {
        super(props);
        this.editPermission = hasAccess(props.permissionName, 'update') && hasAccess(props.permissionName, 'create');
        this.state = {
          initialDataFetched: false,
          addedUsers: [],
          removedUsers: [],
          bindingCount: 0,
          isRemoveAll: false,
        };
      }

      addUser = (selectedUser) => {
        const { addUserBindings } = this.props;
        const { addedUsers, bindingCount } = this.state;
        this.setState({
          addedUsers: [selectedUser, ...addedUsers],
          bindingCount: bindingCount + 1,
        });
        addUserBindings(selectedUser);
      };

      removeUser = (selectedUser) => {
        const { removeUserBindings } = this.props;
        const { removedUsers, bindingCount } = this.state;
        const currentBindingCount = bindingCount - 1;
        this.setState({
          removedUsers: [selectedUser, ...removedUsers],
          bindingCount: currentBindingCount,
        });
        const bindingsLength = removeUserBindings(selectedUser);
        if (!bindingsLength && currentBindingCount) this.fetchBindings('', true);
      };

      removeAll = () => {
        const { removeAllBindings } = this.props;
        this.setState({ bindingCount: 0, isRemoveAll: true });
        removeAllBindings();
      };

      initialFetch = async () => {
        const {
          parameters: { bindings },
        } = this.props;
        this.setState({ bindingCount: bindings, initialDataFetched: true });
        await Promise.all([this.fetchBindings(), this.fetchUsers()]);
      };

      fetchUsers = async (searchTerm, isOnScroll) => {
        const { fetchListData } = this.props;
        await fetchListData('users', searchTerm, isOnScroll);
      };

      fetchBindings = async (searchTerm, isOnScroll) => {
        const {
          fetchListData,
          parameters: { id },
        } = this.props;
        await fetchListData('bindUsers', searchTerm, isOnScroll, id);
      };

      close = () => {
        closeOverlay(SIDEPANEL_ID);
        const { resetLists } = this.props;
        resetLists();
        this.setState({ initialDataFetched: false });
      };

      updateUserBindings = async () => {
        const {
          updateUserBindings,
          onSuccess,
          parameters: { id },
        } = this.props;

        const { addedUsers, removedUsers, isRemoveAll } = this.state;
        await updateUserBindings({ addedUsers, removedUsers, id, isRemoveAll });
        const { errors } = this.props;
        this.setState({ addedUsers: [], removedUsers: [] });
        if (!errors.updateBindings) {
          if (onSuccess) onSuccess();
          this.close();
          notifier.success(text.SUCCESS);
        }
      };

      render() {
        const {
          visible,
          parameters,
          lists,
          scrollProps,
          requestInProgress,
          bindingType,
          disableMultiBinding,
          errors,
          title,
          icon,
        } = this.props;
        const { bindingCount } = this.state;
        if (!parameters) return null;
        const { initialDataFetched } = this.state;
        const { name } = parameters;
        if (visible && !initialDataFetched) {
          this.initialFetch();
        }

        return (
          <Modal
            visible={visible}
            icon={icon}
            size="large"
            title={title}
            footerRender={() => customFooter(
              this.updateUserBindings,
              this.close,
              requestInProgress,
              this.editPermission,
            )
            }
          >
            <div className="fiq-manage-users">
              <UsersList
                title={text.ALL_USERS}
                users={lists.users}
                onUserClick={this.addUser}
                fetchDataOnSearch={this.fetchUsers}
                icon="Plus"
                bindingType={bindingType}
                disableAction={disableMultiBinding}
                hasMore={scrollProps.users.hasMore}
                isLoading={requestInProgress.users}
              />
              <Space size={12} />
              <UsersList
                title={name || ''}
                users={lists.bindUsers}
                onUserClick={this.removeUser}
                removeAll={this.removeAll}
                userCount={bindingCount}
                fetchDataOnSearch={this.fetchBindings}
                icon="Delete"
                hasMore={scrollProps.bindUsers.hasMore}
                isLoading={requestInProgress.bindUsers}
              />
            </div>
            <Space size={12} />
            {errors.updateBindings && <NonFieldErrors errors={errors.updateBindings} />}
          </Modal>
        );
      }
    },
  ));

  ManageUsers.wrappedComponent.propTypes = propTypes;
  ManageUsers.wrappedComponent.defaultProps = defaultProps;

  return { ManageUsers };
};

export default ManageUsersFactory;
