import React, { PureComponent } from 'react';

import Page from 'App/components/Page';
import OrdersTable, { tableConfig } from 'Transactions/components/OrdersTable';

const bread = [
  { label: 'Transactions' },
  { label: 'Orders', route: '/transactions/orders' },
];

const exportDataOptions = { permission: 'finances_tradeorder', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

class OrdersPage extends PureComponent {
  render() {
    return (
      <Page bread={bread} title="Orders" style={{ margin: '0 20px' }} exportDataOptions={exportDataOptions}>
        <OrdersTable />
      </Page>
    );
  }
}

export default OrdersPage;
