import React, { useState, useEffect } from 'react';
import { Icon, Tooltip, Button } from 'tc-biq-design-system';
import { func, number, string } from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { fetchTemplate } from 'Communications/services/CommunicationsService';
import PreviewMessage, { openPreviewMessage } from 'Marketing/components/overlays/PreviewMessage';

import AddTemplateDropdown from './AddTemplateDropdown';

import './AddTemplate.scss';

const text = {
  CHOOSE_TEMPLATE: 'Choose template',
  TOOLTIP: 'Trigger must be set in order to choose the transactional template related to those events.',
  PREVIEW_TEMPLATE: 'Preview template',
};

const AddTemplate = ({ templateId, eventId, channel, onSave }) => {
  const [isOpen, toggleDropdown] = useState(false);
  const [template, setTemplate] = useState(null);
  const [selectedTemplate, selectTemplate] = useState(null);


  const onChange = (name, temp) => {
    selectTemplate(temp);
    if (!temp) chooseTemplate(temp);
  };

  const chooseTemplate = (value) => {
    setTemplate(value);
    onSave(value?.id);
    close();
  };

  const open = () => toggleDropdown(true);
  const close = () => toggleDropdown(false);

  useEffect(() => {
    async function getTemplate() {
      if (templateId) {
        const singleTemplate = await fetchTemplate(templateId);
        const formatedSingleTemplate = {
          ...singleTemplate.data,
          display_name: singleTemplate.data.name,
          value: singleTemplate.data.id,
        };
        selectTemplate(formatedSingleTemplate);
        setTemplate(formatedSingleTemplate);
      }
    }
    getTemplate();
  }, [templateId]);

  return (
    <div className="fiq-templates__add-template">
      <div className={classNames({ disabled: !eventId, hidden: isOpen }, 'button-wrapper')}>
        <span onClick={open} className="tc-paragraph-strong text-primary-500 add-button">
          {isEmpty(template)
            ? text.CHOOSE_TEMPLATE
            : `Selected template: ${template.name}`}
        </span>
        <Tooltip title={text.TOOLTIP}>
          <Icon colorName="text-neutral-500" name="Warning" />
        </Tooltip>
      </div>
      <AddTemplateDropdown
        value={selectedTemplate}
        onChange={onChange}
        onAdd={chooseTemplate}
        hidden={!isOpen}
        closeDropdown={close}
        eventId={eventId}
        channel={channel}
      />
      {!isEmpty(template) && (
      <Button
        color="transparent"
        icon="View"
        onClick={() => openPreviewMessage(selectedTemplate.content?.[0]?.content)}
      >
        {text.PREVIEW_TEMPLATE}
      </Button>
      )
    }
      <PreviewMessage />
    </div>
  );
};

AddTemplate.propTypes = {
  onSave: func.isRequired,
  eventId: number,
  templateId: number,
  channel: string,
};

AddTemplate.defaultProps = {
  templateId: null,
  eventId: null,
  channel: null,
};

export default AddTemplate;
