import React, { PureComponent } from 'react';
import { number, oneOfType, string } from 'prop-types';

import GridComponentFactory from 'App/components/grid';
import JourneyPreview from 'Contacts/components/overlays/JourneyPreview';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import modifiers from './modifiers';
import { openJourneyPreviewSidepanel } from '../../overlays/JourneyPreview/JourneyPreviewSidepanel';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
};

const tableConfig = contactId => ({
  tableUrl: `/marketing/journeys/?contact__id=${contactId}`,
  tableKey: 'contactJourneys',
});

const sizeOptions = [10, 20, 50, 100, 200];

const filterOptions = {
  excluded: ['contact'],
};

class JourneysTable extends PureComponent {
  constructor(props) {
    super(props);

    this.modifiers = modifiers({
      onOpenJourneyPreview: openJourneyPreviewSidepanel,
    });
  }

  render() {
    const { id } = this.props;
    const { GridComponent } = GridComponentFactory(tableConfig(id));
    return (
      <>
        <GridComponent
          sizeOpitons={sizeOptions}
          height="calc(100vh - 450px)"
          modifiers={this.modifiers}
          filterOptions={filterOptions}
        />
        <JourneyPreview />
      </>
    );
  }
}

JourneysTable.propTypes = propTypes;
export default withErrorBoundary(JourneysTable);
