import React, { Component } from 'react';
import { array, oneOfType, string, number, func } from 'prop-types';
import { Pill, Icon, Select, notifier } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';

import If from 'App/components/If';
import { hasAccess } from 'App/services/permissionsService';
import http from 'App/services/http';
import { DEBOUNCE_VALUE } from 'App/components/FieldRenderer/Inputs/SelectField';

const text = {
  SUCCESS_MESSAGE: 'Tags updated successfully',
  ERROR_MESSAGE: 'Error while updaing tags',
  ADD_TAG: 'Add tag',
};

const propTypes = {
  id: oneOfType([string, number]),
  fetchContactData: func.isRequired,
  tags: array,
};

const defaultProps = {
  id: '',
  tags: [],
};

const renderTagPills = (tags, onIconClick) => tags.map((tag, index) => {
  const onClick = () => onIconClick(tag);
  return (
      // eslint-disable-next-line
      <span className="tag-pill" key={`tag-pill${index}`}>
        <If condition={hasAccess('contacts_tag', 'delete')}>
          <Pill icon="Close" onIconClick={onClick}>
            {tag.label}
          </Pill>
        </If>
        <If condition={!hasAccess('contacts_tag', 'delete')}>
          <Pill>{tag.label}</Pill>
        </If>
      </span>
  );
});

class ContactTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inlineEditingActive: false,
    };
  }

  handleSelect = (tag) => {
    const { id, fetchContactData } = this.props;
    http.post(`/contacts/${id}/tags/`, { label: tag.display_name || tag.label }).then(
      () => {
        fetchContactData(id).then(() => notifier.success(text.SUCCESS_MESSAGE));
      },
      () => {
        notifier.error(text.ERROR_MESSAGE);
      },
    );
  };

  handleRemove = (tag) => {
    const { id, fetchContactData } = this.props;
    http.delete(`/contacts/${id}/tags/${tag.id}/`).then(
      () => {
        fetchContactData(id).then(() => notifier.success(text.SUCCESS_MESSAGE));
      },
      () => {
        notifier.error(text.ERROR_MESSAGE);
      },
    );
  };

  handleInputChange = async (name) => {
    const response = await http.get(`tags/?search=${name}`);
    return [
      ...response.data.results.map(result => ({
        value: result.id,
        display_name: result.label,
      })),
    ];
  };

  toggleInlineEditing = () => {
    this.setState(({ inlineEditingActive }) => ({ inlineEditingActive: !inlineEditingActive }));
  };

  render() {
    const { id, tags } = this.props;
    const { inlineEditingActive } = this.state;
    if (!id) return null;

    return (
      <div className="tag-selector-container">
        {renderTagPills(tags, this.handleRemove)}
        <If condition={!inlineEditingActive && hasAccess('contacts_tag', 'create')}>
          <span className="add-btn" onClick={this.toggleInlineEditing}>
            <Icon name="Plus" size="small" />
            <span className="tc-paragraph-strong add-text">{text.ADD_TAG}</span>
          </span>
        </If>
        <If condition={inlineEditingActive}>
          <Select
            autoFocus
            onBlur={this.toggleInlineEditing}
            placeholder={text.ADD_TAG}
            type="search"
            creatable
            disable
            async
            loadOptions={this.handleInputChange}
            onChange={this.handleSelect}
            className="tag-input"
            valueKey="value"
            labelKey="display_name"
            debounceInterval={DEBOUNCE_VALUE}
          />
        </If>
      </div>
    );
  }
}

ContactTags.propTypes = propTypes;
ContactTags.defaultProps = defaultProps;

export default inject(stores => ({
  id: stores.contact.profile.contactData.id,
  tags: stores.contact.profile.contactData.tags,
  fetchContactData: stores.contact.profile.fetchContactData,
}))(observer(ContactTags));
