import React, { Component } from 'react';
import { Pill, Row, Col, Spinner, Popconfirm, Icon, Space } from 'tc-biq-design-system';
import { func, array, bool, string, number, any } from 'prop-types';
import { inject, observer } from 'mobx-react';

import ManageUsersFactory, {
  SIDEPANEL_ID,
} from 'App/components/ManageUsersFactory/ManageUsersFactory';
import CreateEditTeam, {
  SIDEPANEL_ID as EDIT_TEAM_SIDEPANEL_ID,
} from 'Settings/Sections/Teams/components/sidepanels/CreateEditTeam';
import { openOverlay } from 'App/services/overlayService';
import { hasAccess } from 'App/services/permissionsService';
import TreeItem from './TreeItem';
import TreeWrapper from './TreeWrapper';

const propTypes = {
  fetchTeams: func.isRequired,
  teams: array.isRequired,
  isLoading: bool.isRequired,
  deleteTeam: func.isRequired,
};

const text = {
  DELETE_Q: name => `Are you sure you want to delete team ${name}?`,
  POP_LABELS: {
    confirm: 'Delete team',
    cancel: 'Cancel',
  },
};

const { ManageUsers } = ManageUsersFactory('teams');

const DeleteTeam = ({ deleteTeam, name }) => {
  if (!hasAccess('users_team', 'delete')) return null;
  return (
    <div style={{ paddingTop: '4px' }} onClick={e => e.stopPropagation()}>
      <Popconfirm
        type="destructive"
        placement="bottom"
        icon="Delete"
        label={text.DELETE_Q(name)}
        onConfirm={deleteTeam}
        buttonLabels={text.POP_LABELS}
      >
        <Icon size="small" name="Delete" />
      </Popconfirm>
    </div>
  );
};
DeleteTeam.propTypes = { deleteTeam: func.isRequired, name: string.isRequired };

const EditTeam = ({ id, parentId }) => {
  if (!hasAccess('users_team', 'update')) return null;
  return (
    <div style={{ paddingTop: '4px' }} onClick={e => e.stopPropagation()}>
      <Icon
        name="Edit"
        size="small"
        onClick={() => openOverlay(EDIT_TEAM_SIDEPANEL_ID, { id, parentId })}
      />
    </div>
  );
};
EditTeam.propTypes = { id: number.isRequired, parentId: any.isRequired };

class TeamsStructure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };
  }

  componentDidMount() {
    this.loadTeams();
  }

  loadTeams = async () => {
    const { fetchTeams } = this.props;
    await fetchTeams();
  };

  onClick = (id) => {
    const { expanded } = this.state;
    const isExpanded = expanded.includes(id);
    const newArr = isExpanded ? expanded.filter(item => item !== id) : [...expanded, id];
    this.setState({
      expanded: newArr,
    });
  };

  deleteTeam = async (id) => {
    const { deleteTeam } = this.props;
    await deleteTeam(id);
    this.loadTeams();
  };

  renderTeam = (teams, deleteTeam, level = 0, parentId = 0) => {
    const { expanded } = this.state;
    return (
      <TreeWrapper reset={!level}>
        {teams.map(({ children, id, name, bindings, description }) => (
          <TreeWrapper.Item key={id} collapsed={!expanded.includes(id)}>
            <TreeItem
              onClick={children.length ? () => this.onClick(id) : null}
              type={!level ? 'root' : `level-${level}`}
              title={name}
              description={description}
              teamId={id}
            >
              {hasAccess('users_teambinding', 'read') && (
                <Pill
                  onClick={() => openOverlay(SIDEPANEL_ID, { name, id, bindings })}
                  onIconClick={() => openOverlay(SIDEPANEL_ID, { name, id, bindings })}
                  icon="Teams"
                >
                  {` ${bindings}`}
                </Pill>
              )}
              <EditTeam id={id} parentId={parentId} />
              <Space size={8} />
              <DeleteTeam deleteTeam={() => deleteTeam(id)} name={name} />
            </TreeItem>
            {children.length ? this.renderTeam(children, deleteTeam, level + 1, id) : null}
          </TreeWrapper.Item>
        ))}
        <ManageUsers
          onSuccess={this.loadTeams}
          permissionName="users_teambinding"
          bindingType="team"
          disableMultiBinding
        />
      </TreeWrapper>
    );
  };

  render() {
    const { teams, isLoading } = this.props;

    return (
      <Row>
        <Col sm="1/7" />
        <Col sm="5/7">
          {isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <Spinner />
            </div>
          ) : (
            this.renderTeam(teams, this.deleteTeam)
          )}
          <CreateEditTeam />
        </Col>
      </Row>
    );
  }
}

TeamsStructure.propTypes = propTypes;

export default inject(stores => ({
  fetchTeams: stores.teams.fetchTeamsData,
  teams: stores.teams.teamsData,
  isLoading: stores.teams.requestInProgress.teamsData,
  deleteTeam: stores.teams.deleteTeam,
}))(observer(TeamsStructure));
