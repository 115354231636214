import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Input } from 'tc-biq-design-system';
import { func, string } from 'prop-types';

const propTypes = {
  updateFieldValue: func.isRequired,
  searchTerm: string.isRequired,
  resetFieldsValue: func.isRequired,
};

const text = {
  SEARCH: 'Search',
};

const SearchField = ({ searchTerm, updateFieldValue, resetFieldsValue }) => {
  useEffect(() => () => resetFieldsValue(), []);
  return (
    <Input
      style={{ width: '95%' }}
      value={searchTerm}
      name="searchTerm"
      iconPosition="left"
      icon="Search"
      placeholder={text.SEARCH}
      onChange={e => updateFieldValue('searchTerm', e.target.value)}
    />
  );
};

SearchField.propTypes = propTypes;

export default inject(stores => ({
  searchTerm: stores.roles.fields.searchTerm,
  updateFieldValue: stores.roles.updateFieldValue,
  resetFieldsValue: stores.roles.resetFieldsValue,
}))(observer(SearchField));
