import React, { useState } from 'react';
import { Accordion, Space, Code } from 'tc-biq-design-system';
import { object, func } from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import If from 'App/components/If/index';
import ViewDoc, { openViewDoc } from 'App/components/CommonOverlays/ViewDoc';
import { hasAccess } from 'App/services/permissionsService';
import {
  DocStatus,
  // AccordionCarret,
  DateRecord,
  ViewPreviewAction,
  DeleteAction,
} from './components';

const propTypes = {
  doc: object.isRequired,
  deleteDocument: func.isRequired,
  fetchContactDocuments: func.isRequired,
};

const text = {
  UPLOADED: 'Uploaded',
  REVIEWED: 'Reviewed by',
};

const stopPropagation = e => e.stopPropagation();

const headerTemplate = (doc, visible, previewDoc, deleteDoc) => (
  <div className={classNames('fiq-documents__header', { 'flex-wrap': doc.status })}>
    <div className={classNames('fiq-documents__title', { 'flex-basic-100': doc.status })}>
      {/* <span className="fiq-documents__caret-icon">
          <AccordionCarret visible={visible} />
        </span>
  <Space size={20} /> */}
      {doc && doc.document_type && (
        <div className="tc-paragraph-strong">{doc.document_type.name}</div>
      )}
      <Space size={12} />
      {doc && doc.filename && (
        <div className="tc-micro-regular text-neutral-700">{doc.filename}</div>
      )}
      <Space size={12} />
      {doc && doc.filename_backside && (
        <div className="tc-micro-regular text-neutral-700">{doc.filename_backside}</div>
      )}
      <Space size={12} />
      <DocStatus status={doc.status} />
    </div>
    <div className={classNames('fiq-documents__doc-details', { 'flex-basic-100': doc.status })} onClick={stopPropagation}>
      {doc.reviewed_by && (
        <DateRecord dateString={doc.updated} label={text.REVIEWED} reviewer={doc.reviewed_by} />
      )}
      <DateRecord dateString={doc.created} label={text.UPLOADED} />
      <If condition={hasAccess('documents_document', 'update')}>
        <ViewPreviewAction
          previewDoc={previewDoc}
          status={doc.status}
          backside={doc.file_backside}
        />
      </If>
      <If condition={hasAccess('documents_document', 'delete')}>
        <DeleteAction deleteDoc={deleteDoc} />
      </If>
    </div>
  </div>
);

const DocAccordion = ({ doc, deleteDocument, fetchContactDocuments }) => {
  const [visible, toggleVisibility] = useState(false);
  const toggleAccordion = () => toggleVisibility(!visible);

  const previewDoc = () => {
    openViewDoc(doc);
  };
  const deleteDoc = async () => {
    await deleteDocument(doc.id);
    fetchContactDocuments();
  };

  return (
    <Accordion
      title=""
      headerTemplate={headerTemplate(doc, visible, previewDoc, deleteDoc)}
      visible={false}
      onClick={toggleAccordion}
    >
      <div className="fiq-documents__accordion-body">
        <div className="fiq-documents__body-header">
          {doc.reviewed_by && (
            <DateRecord dateString={doc.updated} label={text.REVIEWED} reviewer={doc.reviewed_by} />
          )}
          <div className="fiq-documents__body-code">
            <Code data={doc} />
          </div>
        </div>
      </div>
      <ViewDoc />
    </Accordion>
  );
};

DocAccordion.propTypes = propTypes;
export default inject(stores => ({
  deleteDocument: stores.contact.documents.deleteDocument,
  fetchContactDocuments: stores.contact.documents.fetchContactDocuments,
}))(observer(DocAccordion));
