import { func, object } from 'prop-types';
import actions from './actions';
import actionTypes from './actions/types';

const getActions = (comboxManager, sidepaneManager) => (type, contactStatus) => {
  const overlayManager = {
    [actionTypes.TOP]: comboxManager,
    [actionTypes.DROPDOWN]: sidepaneManager,
  };

  return actions(contactStatus)
    .filter(action => action.type === type)
    .map(action => ({
      ...action,
      label: typeof action.label === 'function' ? action.label() : action.label,
      handler: action.handler ? () => action.handler(overlayManager[type]) : null,
      dropdownActions: action.dropdownActions ? action.dropdownActions(overlayManager[type]) : null,
    }));
};

const propTypes = {
  children: func.isRequired,
  comboxManager: object.isRequired,
};

// eslint-disable-next-line
const ContactActions = ({ type, children, comboxManager, sidepanelManager, contactStatus }) => children(getActions(comboxManager, sidepanelManager)(type, contactStatus));

ContactActions.propTypes = propTypes;
export default ContactActions;
