import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { object, func } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Col, Space } from 'tc-biq-design-system';
import isEmpty from 'lodash/isEmpty';

import Page from 'App/components/Page';
import Loader from 'App/components/Loader';
import { WithdrawalDetails } from 'Transactions/components';
import CodeAccordion from 'App/components/CodeAccordion';
import DetailsCardWrapper from 'App/components/DetailsCardWrapper';

import './WithdrawalSinglePage.scss';

const propTypes = {
  match: object.isRequired,
  fetchWithdrawalData: func.isRequired,
  withdrawalData: object.isRequired,
};

const bread = id => [
  { label: 'Transactions' },
  { label: 'Withdrawals', route: '/transactions/withdrawals' },
  { label: id, route: `/transactions/withdrawals/${id}` },
];

const text = {
  WITHDRAWAL: 'Withdrawal',
};

class WithdrawalSinglePage extends PureComponent {
  constructor(props) {
    super(props);
    this.id = props.match.params.withdrawalId;
  }

  componentDidMount() {
    const { fetchWithdrawalData } = this.props;
    fetchWithdrawalData(this.id);
  }

  render() {
    const { withdrawalData } = this.props;
    if (isEmpty(withdrawalData)) return <Loader visible />;
    const { contact, state, metadata, wallet, external_id } = withdrawalData;
    return (
      <Page
        bread={bread(this.id)}
        title={`${text.WITHDRAWAL} ${external_id || ''}`}
        style={{ marginTop: '24px', overflow: 'auto' }}
      >
        <div className="fiq-withdrawal-single">
          <Col sm="100%" lg="70%">
            <DetailsCardWrapper
              contact={contact}
              wallet={wallet}
              title={text.WITHDRAWAL}
              status={state}
            >
              <WithdrawalDetails />
            </DetailsCardWrapper>
            <Space size={12} />
            <CodeAccordion data={metadata || {}} toggled />
          </Col>
        </div>
      </Page>
    );
  }
}

WithdrawalSinglePage.propTypes = propTypes;

export default withRouter(inject(stores => ({
  fetchWithdrawalData: stores.transactions.fetchWithdrawalData,
  withdrawalData: stores.transactions.withdrawalData,
}))(observer(WithdrawalSinglePage)));
