import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Detail, Chart, PieChart } from 'tc-biq-design-system';
import { object, bool } from 'prop-types';
import { formatPieChartData } from 'App/services/utilities/chartUtils';
import { isEmpty } from 'lodash';
import Loader from 'App/components/Loader';

import './CampaignOverview.scss';

const propTypes = {
  journeyData: object.isRequired,
  inProgress: bool,
};

const defaultProps = {
  inProgress: false,
};

const text = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  CONVERTED: 'Converted',
  STARTED: 'Started',
  TOTAL: 'Total',
  JOURNEYS_BREAKDOWN: 'Journeys Breakdown',
  JOURNEY_CHART: 'Contacts in Journeys by Steps',
};

const bars = [
  { label: text.CONVERTED, key: 'count_converted', color: 'primary-700' },
  { label: text.COMPLETED, key: 'count_completed', color: 'primary-600' },
  { label: text.IN_PROGRESS, key: 'count_in_progress', color: 'primary-500' },
  { label: text.STARTED, key: 'count_started', color: 'primary-400' },
  { label: text.TOTAL, key: 'total', color: 'black' },
];

const valueRenderer = (key, value) => `${key}: ${value}`;

const toPercentage = (all, part) => (part ? `${Math.round((part / all) * 100)}%` : null);

const formatKpiData = (data) => {
  if (!data) return [];
  const { count_completed, count_converted, count_failed, count_started } = data;

  const count_in_progress = count_started - (count_failed + count_completed);

  const completedPercent = toPercentage(count_started, count_completed);
  const convertedPercent = toPercentage(count_started, count_converted);
  const inProgressPercent = toPercentage(count_started, count_in_progress);

  return [
    { text: text.STARTED, percent: null, value: count_started || 0 },
    { text: text.IN_PROGRESS, percent: inProgressPercent, value: count_in_progress || 0 },
    { text: text.CONVERTED, percent: convertedPercent, value: count_converted || 0 },
    { text: text.COMPLETED, percent: completedPercent, value: count_completed || 0 },
  ];
};

const CampaignOverview = ({ journeyData, inProgress }) => {
  const [kpiData, setKpiData] = useState([]);
  const [stepsData, setStepsData] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setKpiData(formatKpiData(journeyData?.total));
    setStepsData(formatPieChartData(journeyData?.steps || [], 'block_name'));
    setChartData(journeyData?.per_date || []);
  }, [journeyData]);

  if (inProgress || isEmpty(journeyData)) return <Loader visible />;

  return (
    <div className="fiq-campaign-overview__content">
      <div className="fiq-campaign-overview__content__kpi-list">
        {kpiData.map(item => (
          <div key={item.text} className="fiq-campaign-overview__content__kpi-list__item">
            <Detail entity={item.text} value={item.value} reverse />
            {item.percent && <div className="percent">{item.percent}</div>}
          </div>
        ))}
      </div>
      <div className="fiq-campaign-overview__content__goal-conversion">
        <div className="fiq-campaign-overview__content__goal-conversion__header tc-heading-s">
          {text.JOURNEYS_BREAKDOWN}
        </div>
        <Chart
          hideTotal
          xAxisKey="date"
          height={190}
          bars={bars}
          data={chartData}
          stacked
          valueRenderer={valueRenderer}
          tickFormatter={value => `${value}`}
          barSize={24}
        />
      </div>
      <div className="fiq-campaign-overview__content__journey-chart">
        <div className="fiq-campaign-overview__content__journey-chart__header tc-heading-s">
          {text.JOURNEY_CHART}
        </div>
        <PieChart
          data={stepsData}
          height={146}
          innerRadius={30}
          outerRadius={50}
          width={146}
          withLegend
          color="#F76868"
        />
      </div>
    </div>
  );
};

CampaignOverview.propTypes = propTypes;
CampaignOverview.defaultProps = defaultProps;

export default inject(stores => ({
  journeyData: stores.campaigns.journeyStats,
  inProgress: stores.campaigns.requestInProgress.fetchJourneyStats,
}))(observer(withRouter(CampaignOverview)));
