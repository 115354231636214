import React from 'react';
import { withRouter } from 'react-router-dom';
import { MultiActions, Pill } from 'tc-biq-design-system';

import hideCols from 'App/services/utilities/hideCols';
import { hasAccess } from 'App/services/permissionsService';
import { RouteCell } from 'App/components/gridCellRenderers';

// eslint-disable-next-line react/prop-types
const MultiActionCell = actions => withRouter(({ data, history }) => {
  if (actions(data, history).length === 0) return null;
  return <MultiActions actions={actions(data, history)} />;
});

// eslint-disable-next-line react/prop-types
const UsersNum = ({ hasPermission, onClick }) => ({ data, value }) => {
  if (!hasPermission) {
    return (
      <Pill icon="User" iconPosition="left">
        {value}
      </Pill>
    );
  }
  return (
    <Pill
      icon="User"
      iconPosition="left"
      onIconClick={() => onClick(data)}
      onClick={() => onClick(data)}
    >
      {` ${value}`}
    </Pill>
  );
};

const NameRouteCell = href => ({ data, value }) => {
  if (!data.is_editable) return <span>{value}</span>;
  return RouteCell(href)({ hrefValue: data.id, value: data.name });
};

const modifiers = (singleActions, manageUsers) => ({
  ...hideCols(['id', 'query']),
  name: {
    cellRendererFramework: NameRouteCell('/settings/contact-access-groups/'),
  },
  bindings: {
    cellRendererFramework: UsersNum({
      hasPermission: hasAccess('contact_access_groups_contactaccessgroupbinding', 'read'),
      onClick: manageUsers,
    }),
  },
  actions: {
    pinned: 'right',
    maxWidth: 80,
    cellRendererFramework: MultiActionCell(singleActions),
    headerName: '',
    unSortIcon: false,
  },
});

export default modifiers;
