import React, { PureComponent } from 'react';
import { Button } from 'tc-biq-design-system';
import { withI18n } from '@lingui/react';
import { withRouter } from 'react-router-dom';

import Page from 'App/components/Page';
import { hasAccess } from 'App/services/permissionsService';
import UsersTable, { tableConfig } from 'Settings/Sections/Users/components/UsersTable';

const bread = [{ label: 'Settings', route: '/settings' }, { label: 'Users', route: '/settings/users' }];
const exportDataOptions = { permission: 'users_user', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };
class UsersPage extends PureComponent {
  headerActions = () => {
    const { history } = this.props; //eslint-disable-line
    const redirect = () => history.push('/settings/users/invite');
    if (!hasAccess('users_user', 'create')) return null;
    return <Button onClick={redirect}>Invite users</Button>;
  };

  render() {
    return (
      <Page
        headerActions={this.headerActions}
        bread={bread}
        title="Users"
        style={{ margin: '0 20px' }}
        exportDataOptions={exportDataOptions}
      >
        <UsersTable />
      </Page>
    );
  }
}

UsersPage.displayName = 'UsersPage';

export default withI18n()(withRouter(UsersPage));
