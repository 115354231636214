/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from 'react';
import http from 'App/services/http';
import { Select, Icon, Space } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { object } from 'prop-types';
import { ContactCell, RouteCell } from 'App/components/gridCellRenderers';

import extractDisplayName from 'App/services/utilities/extractDisplayName';
import { DEBOUNCE_VALUE } from 'App/components/FieldRenderer/Inputs/SelectField';

import './SearchAll.scss';

const propTypes = {
  history: object.isRequired,
};

const text = {
  SHOW_MORE: 'Show more',
};

const getIcon = (name) => {
  const map = {
    Contacts: 'User',
    'Payment Transactions': 'Trade',
    'Trade Transactions': 'Trade',
    Wallets: 'Account',
  };
  return map[name] || 'User';
};

const getHighlightedText = (searchText, highlight) => {
  if (!searchText) return '';
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = `${searchText}`.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      {parts.map((part, index) => (part.toLowerCase() === highlight.toLowerCase() ? <mark key={`${part}${index}`}>{part}</mark> : part))}
    </span>
  );
};

const renderTableComponent = (item, searchTerm, keys) => (
  <div className="item">
    {keys.map(key => (key?.renderComponent ? (
      <Fragment key={key.key}>
        {key.renderComponent(item, searchTerm)}
      </Fragment>
    ) : (
      <Fragment key={key}>
        <div className="item__cell truncate-text">{getHighlightedText(item[key], searchTerm)}</div>
      </Fragment>
    )))}
  </div>
);

// Object key represents the name of the model from Backend.
const searchAllConfig = {
  contact: {
    getDisplayName: (item, searchTerm) => renderTableComponent(item, searchTerm, [
      { key: 'contact',
        renderComponent: value => (
          <div className="item__cell--contact">
            <ContactCell
              fullName={extractDisplayName(value)}
              id={value.id}
              avatar={null}
              displaySubString={value.email}
              hideAvatar
            />
          </div>
        ) },
      'status',
      'id',
    ]),
    groupName: 'Contacts',
    getPath: id => `/contacts/${id}`,
  },
  paymenttransaction: {
    getDisplayName: (item, searchTerm) => renderTableComponent(item, searchTerm, ['amount', 'amount_asset', 'state', 'payment_type', 'id']),
    groupName: 'Payment Transactions',
    getPath: id => `/transactions/payments/${id}`,
  },
  tradetransaction: {
    getDisplayName: (item, searchTerm) => renderTableComponent(item, searchTerm, ['amount', 'symbol', 'side', 'id']),
    groupName: 'Trade Transactions',
    getPath: id => `/transactions/trades/${id}`,
  },
  wallet: {
    getDisplayName: (item, searchTerm) => renderTableComponent(item, searchTerm, ['asset', 'id', { key: 'contact_id',
      // eslint-disable-next-line react/prop-types
      renderComponent: ({ contact_id }) => (
        <div className="item__cell trucate-text" onClick={e => e.stopPropagation()}>
          <div className="item__cell--wallet">
            <Icon colorName="text-primary-400" bgColorName="bck-primary-100" size="small" name="User" />
            {RouteCell('/contacts/')({ hrefValue: contact_id,
              value: getHighlightedText(contact_id, searchTerm),
            })}
          </div>
        </div>
      ) }]),
    groupName: 'Wallets',
    getPath: id => `/transactions/wallets/${id}`,
  },
};

const buildSearchItem = (groupName, item, displayName) => ({
  group: groupName,
  display_name: displayName,
  id: item.id,
});

const customGroupComponent = (prop, activeItem, setActiveItem) => {
  const { children, data: { label } } = prop;

  const isActive = activeItem === label;
  const itemsLength = children.length;

  if (activeItem && !isActive) return null;
  const sliceValue = isActive ? itemsLength : 3;

  return (
    <div className="custom-group">
      <div className="custom-group__header">
        <Icon colorName="text-primary-500" name={getIcon(label)} />
        <Space size={8} />
        <span className="tc-heading-s tc-regular-strong">{label}</span>
      </div>
      <div className="custom-group__options-wrapper">
        {children.slice(0, sliceValue)}
      </div>
      {(!isActive && itemsLength > 3) && (
        <div className="custom-group__button tc-paragraph-strong" onClick={() => setActiveItem(label)}>
          {text.SHOW_MORE}
        </div>
      )}
    </div>

  );
};

const onSearchResponse = (response, searchTerm) => Object.keys(response.data).reduce((acc, key) => {
  const option = response.data[key];
  if (option && option.length) {
    const { groupName, getDisplayName } = searchAllConfig[key.split('_').pop()];
    option.forEach((item) => {
      acc.push(buildSearchItem(groupName, item, getDisplayName(item, searchTerm)));
    });
  }
  return acc;
}, []);

const onSearchSelected = (selected, history) => {
  if (get(selected, 'display_name') && selected.display_name !== 'no results') {
    const config = Object.keys(searchAllConfig).find(
      key => searchAllConfig[key].groupName === selected.group,
    );
    if (config) {
      history.push(searchAllConfig[config].getPath(selected.id));
    }
  }
};


const loadOptions = searchTerm => http.get(`search/?search=${encodeURIComponent(searchTerm)}`).then(res => onSearchResponse(res, searchTerm));

const customStyles = {
  menuList: base => ({
    ...base,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: '80vh',
  }),
};

const SearchAll = ({ history }) => {
  const [activeItem, setActiveItem] = useState(null);

  const onChange = (selectedOption) => {
    onSearchSelected(selectedOption, history);
  };

  const handleInputChange = (searchTerm) => {
    // limit input on 128 characters per FIQ-152
    setActiveItem(null);
    if (searchTerm.length > 128) return searchTerm.slice(0, 128);
    if (!searchTerm || searchTerm.length < 2) return null;
    return searchTerm;
  };

  return (
    <div style={{ width: '40%' }} data-recording-ignore="mask">
      <Select
        dataRecording
        autoFocus
        className="fiq-search-all"
        name="app-header"
        placeholder="Search anything..."
        type="search"
        async
        labelKey="display_name"
        valueKey="id"
        group
        defaultInputValue=""
        defaultOptions
        onInputChange={handleInputChange}
        loadOptions={loadOptions}
        debounceInterval={DEBOUNCE_VALUE}
        onChange={onChange}
        pageSize={10}
        styles={customStyles}
        components={{ Group: prop => customGroupComponent(prop, activeItem, setActiveItem) }}
      />
    </div>
  );
};

SearchAll.propTypes = propTypes;

export default withRouter(SearchAll);
