import React from 'react';
import { object, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Numeric from 'Dashboard/components/layouts/Numeric';

const text = {
  TITLE: 'Active contacts',
  DESCRIPTION: 'Total number of active contacts in selected time period',
};

const propTypes = {
  data: object,
  fetchMetricsDataInProgress: bool,
};

const defaultProps = {
  data: {},
  fetchMetricsDataInProgress: false,
};


const ActiveContactsNumeric = ({ data, fetchMetricsDataInProgress }) => {
  const { value, percentage, isGrowing } = data;

  return (
    <Numeric
      title={text.TITLE}
      description={text.DESCRIPTION}
      value={value}
      percentage={percentage}
      isGrowing={isGrowing}
      loading={fetchMetricsDataInProgress}
      isCount
    />
  );
};

ActiveContactsNumeric.propTypes = propTypes;
ActiveContactsNumeric.defaultProps = defaultProps;

export default {
  component: inject(stores => ({
    data: stores.dashboardStore.contactsData.activeContactsNumericData,
    fetchMetricsDataInProgress: stores.dashboardStore.fetchMetricsDataInProgress,
    addNewFilter: stores.tables.contacts.filters.addNewFilter,
    dashboardInterval: stores.dashboardStore.dashboardInterval,
  }))(observer(withRouter(ActiveContactsNumeric))),
};
