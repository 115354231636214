import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Row } from 'tc-biq-design-system';

import './CardDetails.scss';

import DisplayDetail from 'App/components/DisplayDetail';

const formatDate = date => moment(date, moment.ISO_8601).format('MMMM Do YYYY, \\at HH:mm');

const CardDetails = inject(stores => ({
  created: stores.transactions.cardData.created,
  externalId: stores.transactions.cardData.external_id,
  productId: stores.transactions.cardData.product_id,
  currency: stores.transactions.cardData.currency,
  country: stores.transactions.cardData.country,
}))(
  observer(({ created, productId, currency, country, externalId }) => {
    const time = created ? formatDate(created) : '';
    return (
      <Fragment>
        <Row gutter={0}>
          <div className="card-details-row">
            <DisplayDetail header="Currency" value={currency} />
            <DisplayDetail header="Country" value={country} />
            <DisplayDetail header="Time" value={time} />
            <DisplayDetail header="External ID" value={externalId} />
            <DisplayDetail header="Product ID" value={productId} />
          </div>
        </Row>
      </Fragment>
    );
  }),
);

export default CardDetails;
