import React from 'react';
import { Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import CampaignsTable from 'Marketing/components/CampaignsTable';
import { hasAccess } from 'App/services/permissionsService';

const propTypes = {
  history: object.isRequired,
};

const text = {
  TITLE: 'Campaigns',
  CREATE_CAMPAIGN: 'Create campaign',
};

const bread = [
  { label: text.TITLE },
];

const CampaignsPage = ({ history }) => {
  const routeToCreate = () => history.push('/marketing/campaigns/create');
  const headerActions = () => (hasAccess('marketing_campaign', 'create') ? <Button onClick={routeToCreate}>{text.CREATE_CAMPAIGN}</Button> : null);
  return (
    <Page title={text.TITLE} bread={bread} headerActions={headerActions} style={{ margin: '0 20px' }}>
      <CampaignsTable />
    </Page>
  );
};

CampaignsPage.propTypes = propTypes;

export default withRouter(CampaignsPage);
