import React, { useState } from 'react';
import { array, object, func } from 'prop-types';
import { Pill } from 'tc-biq-design-system';
import classNames from 'classnames';

import extractDisplayName from 'App/services/utilities/extractDisplayName';
import AddFilterDropdown from './AddFilterDropdown';

const propTypes = {
  activeFilters: array.isRequired,
  removeFilter: func.isRequired,
};

const activeFilterPropTypes = {
  filter: object.isRequired,
  removeFilter: func.isRequired,
  applyFilter: func.isRequired,
  fields: object.isRequired,
};

const renderValue = (data) => {
  if (typeof data === 'object' && data.display_name) return data.display_name;
  if (Array.isArray(data)) return data.map(record => extractDisplayName(record)).join(', ');
  return data;
};

const ActiveFilter = ({ filter, removeFilter, applyFilter, fields }) => {
  const [editFilterInProgress, toggleDropdown] = useState(false);
  const open = () => toggleDropdown(true);
  const close = () => toggleDropdown(false);

  return (
    <div className="fiq-filters__active-filter__edit-filter">
      <div className={classNames({ hidden: editFilterInProgress })} data-recording-sensitive>
        <Pill big onClick={open} onIconClick={() => removeFilter(filter)} icon="Close">
          {`${filter.field.label} ${filter.operator.label} ${renderValue(filter.value)}`}
        </Pill>
      </div>
      <AddFilterDropdown
        initialValues={filter}
        applyFilter={applyFilter}
        fields={fields}
        closeDropdown={close}
        hidden={!editFilterInProgress}
      />
    </div>
  );
};

const ActiveFilters = ({ activeFilters, removeFilter, ...props }) => (
  <div className="fiq-filters__active-filter" data-recording-sensitive>
    {activeFilters.map(filter => (
      <ActiveFilter
        key={`${renderValue(filter.value)} ${filter.operator.value}`}
        filter={filter}
        removeFilter={removeFilter}
        {...props}
      />
    ))}
  </div>
);

ActiveFilter.propTypes = activeFilterPropTypes;
ActiveFilters.propTypes = propTypes;

export default ActiveFilters;
