import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Tooltip, Space, Icon } from 'tc-biq-design-system';
import { string, object, bool, func } from 'prop-types';

import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import { isEmpty, pick } from 'lodash';
import AddCustomPropertyDropdown from './AddCustomPropertyDropdown';

import './AddCustomProperty.scss';

const propTypes = {
  formId: string.isRequired,
  fields: object.isRequired,
  disabled: bool,
  tooltip: string,
  updateFieldValue: func,
};

const defaultProps = {
  disabled: false,
  tooltip: '',
  updateFieldValue: null,
};

const text = {
  ADD_CUSTOM_PROPERTY: 'Add custom property',
};

const AddCustomProperty = ({
  formId,
  fields,
  disabled,
  tooltip,
  updateFieldValue,
}) => {
  const [addFilterInProgress, toggleDropdown] = useState(false);
  const [selectedProperties, setProperty] = useState([]);

  const handleOnChange = ({ name }) => {
    setProperty(prevState => [...prevState, name]);
  };

  const removeProperty = (value) => {
    const properties = selectedProperties.filter(property => property !== value);
    updateFieldValue(value, null);
    setProperty(properties);
  };

  const open = () => toggleDropdown(true);
  const close = () => toggleDropdown(false);


  const allCustomPropertyFieldsKeys = Object.keys(fields)
    .filter(field => fields[field].custom_property);

  const allCustomPropertyFields = pick(fields, allCustomPropertyFieldsKeys);
  const formattedAllCustomPropertyFields = isEmpty(allCustomPropertyFields)
    ? []
    : fieldsResolver(allCustomPropertyFields);

  const selectedCustomPropertiesFields = formattedAllCustomPropertyFields
    .filter(field => selectedProperties.includes(field.name));


  return (
    <div className="fiq-add-custom-property">
      <div
        className={classNames('fiq-add-custom-property__toggle-button', {
          hidden: addFilterInProgress,
        })}
      >
        {disabled
          ? (
            <Tooltip title={tooltip}>
              <div className="tooltip-wrapper">
                <Button size="small" icon="CaretDown" color="transparent" disabled={disabled} onClick={open}>
                  {text.ADD_CUSTOM_PROPERTY}
                </Button>
              </div>
            </Tooltip>
          )
          : (
            <Button size="small" icon="CaretDown" color="transparent" onClick={open}>
              {text.ADD_CUSTOM_PROPERTY}
            </Button>
          )
        }
      </div>
      <AddCustomPropertyDropdown
        hidden={!addFilterInProgress}
        closeDropdown={close}
        onChange={handleOnChange}
      />
      <Space size={32} />
      {selectedCustomPropertiesFields.map(({ id, name, read_only, ...props }) => (
        <div key={id} className="custom-property-field">
          <div>
            <Field
              {...props}
              validate={id === 'email' && 'required|email'}
              disabled={read_only}
              formId={formId}
              key={id}
              name={name}
              data-recording-ignore="mask"
            />
          </div>
          <div className="icon-wrapper" onClick={() => removeProperty(name)}>
            <Icon name="Delete" />
          </div>
        </div>
      ))}
    </div>
  );
};

AddCustomProperty.propTypes = propTypes;
AddCustomProperty.defaultProps = defaultProps;

export default AddCustomProperty;
