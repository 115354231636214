import React, { PureComponent, Fragment } from 'react';
import { Space, Button, Icon } from 'tc-biq-design-system';
import { func, bool, object } from 'prop-types';
import Select from 'App/components/Filters/FilterInputs/Select';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

const propTypes = {
  applyFilter: func.isRequired,
  closeDropdown: func.isRequired,
  hidden: bool.isRequired,
  initialSegment: object,
};

const defaultProps = {
  initialSegment: null,
};

const text = {
  PLACEHOLDER: 'Select segment',
  APPLY_FILTER: 'Apply filter',
  ADD_SEGMENT_FILTER: 'Add segment filter',
};

function isReactSelect(element) {
  if (!element) return false;
  const isCloseIcon = element.classList.contains('Select-value-icon');
  const isReactSelectEl = element.id.indexOf('react-select') !== -1;
  if (isReactSelectEl || isCloseIcon) {
    return true;
  }
  return false;
}

class SegmentFilterDropdown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
    };
  }

  componentDidUpdate() {
    const { hidden } = this.props;
    if (hidden) {
      this.resetFilter();
      document.removeEventListener('mousedown', this.handleClick, false);
    } else {
      document.addEventListener('mousedown', this.handleClick, false);
    }
  }

  componentWillUnmount() {
    this.resetFilter();
  }

  resetFilter = () => {
    this.setState({
      filterValue: '',
    });
  };

  close = () => {
    const { closeDropdown } = this.props;
    this.resetFilter();
    closeDropdown();
  };

  applyFilter = () => {
    const { applyFilter } = this.props;
    const { filterValue } = this.state;
    applyFilter({
      value: filterValue,
    });
    this.close();
  };

  setFilterValue = (value) => {
    this.setState({ filterValue: value });
  };

  handleClick = ({ target }) => {
    if (this.filterDropdownRef.contains(target) || isReactSelect(target)) return;
    this.close();
  };

  render() {
    const { hidden, initialSegment } = this.props;
    const { filterValue } = this.state;
    if (!isEmpty(initialSegment) && !filterValue) {
      this.setFilterValue(initialSegment);
    }

    return (
      <div
        ref={(node) => {
          this.filterDropdownRef = node;
        }}
        className={classNames('fiq-filters__add-filter__filter-dropdown', {
          hidden,
        })}
      >
        <div className="fiq-filters__add-filter__filter-dropdown__heading">
          <span className="tc-paragraph-strong">{text.ADD_SEGMENT_FILTER}</span>
          <span onClick={this.close}>
            <Icon name="Close" size="small" />
          </span>
        </div>
        <Select
          placeholder={text.PLACEHOLDER}
          type="related field"
          value={filterValue}
          onChange={this.setFilterValue}
          relatedModels={[{ value: 'automations_segment' }]}
        />
        <Space size={12} />
        <Fragment>
          <Button style={{ margin: '0' }} onClick={this.applyFilter}>
            {text.APPLY_FILTER}
          </Button>
          <Space size={12} />
        </Fragment>
      </div>
    );
  }
}

SegmentFilterDropdown.propTypes = propTypes;
SegmentFilterDropdown.defaultProps = defaultProps;

export default SegmentFilterDropdown;
