import React from 'react';
import { NavCard } from 'tc-biq-design-system';
import { number, oneOfType, string } from 'prop-types';
import CampaignsTable from 'Marketing/components/CampaignsTable';
import AudiencesTable from './AudiencesTable';
import SegmentActivityStream from './SegmentActivityStream';

const propTypes = {
  segmentId: oneOfType([number, string]).isRequired,
};

const tabTypes = {
  AD_AUDIENCES: 'ad_audiences',
  CAMPAIGNS: 'campaigns',
  ACTIVITY_STREAM: 'activity_stream',
};

const tabs = [
  { title: 'Ad Audiences', id: tabTypes.AD_AUDIENCES },
  { title: 'Campaigns', id: tabTypes.CAMPAIGNS },
  { title: 'Activity Stream', id: tabTypes.ACTIVITY_STREAM },
];

const CampaignTabs = ({ segmentId }) => (
  <div>
    <NavCard tabs={tabs}>
      {(active) => {
        if (active === tabTypes.AD_AUDIENCES) { return <AudiencesTable segmentId={segmentId} />; }
        if (active === tabTypes.CAMPAIGNS) return <CampaignsTable />;
        if (active === tabTypes.ACTIVITY_STREAM) return <SegmentActivityStream id={segmentId} />;
        return null;
      }}
    </NavCard>
  </div>
);

CampaignTabs.propTypes = propTypes;

export default CampaignTabs;
