import React from 'react';
import { DatePickerRange } from 'tc-biq-design-system';
import { array, func, oneOfType, string, bool } from 'prop-types';

const propTypes = {
  value: oneOfType([array, string]).isRequired,
  onChange: func.isRequired,
  withPortal: bool,
};

const defaultProps = {
  withPortal: false,
};

const DateRange = ({ withPortal, ...props }) => {
  const { value, onChange } = props;
  const start = value[0];
  const end = value[1];
  return (
    <DatePickerRange
      {...props}
      startDateModifiers={{ portalId: withPortal ? 'app' : null }}
      endDateModifiers={{ portalId: withPortal ? 'app' : null }}
      startDate={start}
      endDate={end}
      onChange={({ startDate, endDate }) => onChange([startDate, endDate])}
    />
  );
};

DateRange.propTypes = propTypes;
DateRange.defaultProps = defaultProps;

export default DateRange;
