import http from 'App/services/http';

const fetchContactDocuments = (id, query) => http.get(`contacts/${id}/documents/`, { params: query });

const fetchSingleDocument = (contactId, docId) => http.get(`contacts/${contactId}/documents/${docId}/`);

const fetchContactDocumentsOptions = id => http.options(`contacts/${id}/documents/`);

const uploadDocument = (id, data) => {
  const payload = {};
  if (data.file) {
    payload.file = data.file;
    payload.filename = data.file.name;
  }

  if (data.file_backside) {
    payload.file_backside = data.file_backside;
    payload.filename_backside = data.file_backside.name;
  }

  if (data.document_type) payload.document_type = data.document_type.value;

  const body = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    body.append(key, value);
  });

  const config = { headers: { 'content-type': 'multipart/form-data' } };

  return http.post(`contacts/${id}/documents/`, body, config);
};

const approveDocument = (id, documentId) => http.patch(`contacts/${id}/documents/${documentId}/approve-document/`);

const rejectDocument = (id, documentId, reason) => http.patch(`contacts/${id}/documents/${documentId}/reject-document/`, {
  rejection_reason: reason,
});

const deleteDocument = (contactId, docId) => http.delete(`contacts/${contactId}/documents/${docId}/`);

const fetchDocumentTypeChoices = (url, value) => http.get(url, { search: value });

export {
  fetchContactDocuments,
  fetchSingleDocument,
  fetchContactDocumentsOptions,
  fetchDocumentTypeChoices,
  uploadDocument,
  approveDocument,
  rejectDocument,
  deleteDocument,
};
