import React, { PureComponent } from 'react';

import GridComponentFactory from 'App/components/grid';
import { notifier } from 'tc-biq-design-system';
import { toggleActiveState } from 'Settings/Sections/Events/services/EventsService';
import { withRouter } from 'react-router-dom';
import { hasAccess } from 'App/services/permissionsService';
import modifiers from './modifiers';

export const tableConfig = {
  tableUrl: '/events/',
  tableKey: 'events',
};

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const singleActions = ({ onEdit }) => [
  {
    icon: { name: 'Edit', color: 'text-neutral-900', size: 'small' },
    onClick: onEdit,
    label: 'Edit',
    visibility: hasAccess('events_eventtype', 'update'),
  },
].filter(action => action.visibility);

const customColumns = [
  {
    key: 'actions',
    headerName: '',
  },
];

const listToOmit = ['updated'];

class EventsTable extends PureComponent {
  toggleActive = async (data, value) => {
    try {
      await toggleActiveState(data.id, value);
      gridActions.fetchTableData();
    } catch (err) {
      notifier.error('Failed to change Event status');
    }
  };

  editEvent = (data) => {
    const { history } = this.props; //eslint-disable-line
    history.push(`/settings/events/${data.id}`);
  };

  render() {
    return (
      <GridComponent
        modifiers={modifiers({
          toggle: this.toggleActive,
          actions: singleActions({ onEdit: this.editEvent }),
        })}
        customColumns={customColumns}
        listToOmit={listToOmit}
      />
    );
  }
}

export default withRouter(EventsTable);
