import React, { useState, useEffect } from 'react';
import GridComponentFactory from 'App/components/grid';
import { shape, string, object } from 'prop-types';

import PreviewLog from '../overlays/PreviewLog';
import modifiers from './modifiers';

const WebhookLogsTable = ({ tableConfig, style, height }) => {
  const [{ GridComponent, gridActions }] = useState(GridComponentFactory(tableConfig));

  return (
    <div style={style}>
      <GridComponent height={height} modifiers={modifiers} />
      <PreviewLog />
    </div>
  );
};

WebhookLogsTable.propTypes = {
  tableConfig: shape({
    tableUrl: string.isRequired,
    tableKey: string.isRequired,
  }).isRequired,
  style: object,
  height: string,
};

WebhookLogsTable.defaultProps = {
  style: {},
  height: 'calc(100vh - 260px)',
};

export default WebhookLogsTable;
