import { useState, useRef } from 'react';

const useStore = (initialValue) => {
  const [state, _setState] = useState(initialValue);
  const store = useRef(state);

  const setState = (value) => {
    _setState(value);
    store.current = value;
  };

  return [store, state, setState];
};

export default useStore;
