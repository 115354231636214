import {
  ActionTypeCell,
  GenericObjectCell,
  RouteCell,
} from 'App/components/gridCellRenderers';

const modifier = {
  id: {
    cellRendererFramework: RouteCell('/settings/audit-logs/'),
  },
  actor: {
    cellRendererFramework: GenericObjectCell,
    sortable: false,
  },
  action_type: {
    cellRendererFramework: ActionTypeCell,
  },
  target: {
    cellRendererFramework: GenericObjectCell,
    sortable: false,
  },
};

export default modifier;
