import { action, observable, runInAction, computed, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';

import {
  fetchNotifications,
  fetchNotificationsOptions,
  fetchNotificationByIds,
} from 'UserNotifications/services/userNotificationService';
import { parseParamString } from 'App/components/Filters/filterStoreUtils';

const text = {
  GET: 'Failed to fetch notifications',
};

export default class UserNotificationsStore {
  constructor(FiltersStore) {
    makeObservable(this, {
      fieldsDef: observable,
      notifications: observable,
      socketNotificationsIds: observable,
      socketNotifications: observable,
      socketNotificationsCount: observable,
      paginationQuery: observable,
      hasMore: observable,
      requestInProgress: observable,
      errors: observable,
      addSocketNotificationId: action.bound,
      getSocketNotifications: action.bound,
      fetchNotifications: action.bound,
      fetchNotificationsOptions: action.bound,
      query: computed,
    });

    this.filters = FiltersStore;
  }

  fieldsDef = {};

  notifications = [];

  socketNotificationsIds = [];

  socketNotifications = [];

  socketNotificationsCount = 0;

  paginationQuery = {
    cursor: '',
    limit: 20,
  };

  hasMore = false;

  requestInProgress = {
    notifications: false,
    fieldsDef: false,
  };

  errors = {
    notifications: null,
    fieldsDef: null,
  };

  addSocketNotificationId(id) {
    this.socketNotificationsIds = [id, ...this.socketNotificationsIds];
    this.socketNotificationsCount += 1;
  }

  async getSocketNotifications() {
    if (!this.socketNotificationsIds.length) {
      return;
    }

    this.requestInProgress.notifications = true;
    try {
      const res = await fetchNotificationByIds(this.socketNotificationsIds);
      runInAction(() => {
        this.socketNotifications = [
          ...res.data.results.map(n => ({ ...n, highlighted: true })),
          ...this.socketNotifications.map(n => ({ ...n, highlighted: false })),
        ];
        this.socketNotificationsIds = [];
        this.socketNotificationsCount = 0;
      });
    } catch {
      notifier.error(text.GET);
    } finally {
      runInAction(() => {
        this.requestInProgress.notifications = false;
      });
    }
  }

  async fetchNotifications({ query, isScrollFetch }) {
    if (!isScrollFetch) this.paginationQuery.cursor = '';
    this.requestInProgress.notifications = true;
    try {
      const response = await fetchNotifications({ ...this.query, ...query });
      const { next, results } = response.data;
      const params = next ? parseParamString(next) : {};
      runInAction(() => {
        this.paginationQuery.cursor = params.cursor || '';
        this.notifications = isScrollFetch
          ? [...this.notifications, ...results]
          : response.data.results;
        this.hasMore = !!next;
      });
    } catch (e) {
      notifier.error(text.GET);
      runInAction(() => {
        this.errors = e;
      });
    } finally {
      runInAction(() => {
        this.requestInProgress.notifications = false;
      });
    }
  }

  async fetchNotificationsOptions() {
    this.requestInProgress.fieldsDef = true;
    try {
      const response = await fetchNotificationsOptions();
      runInAction(() => {
        this.fieldsDef = response.data.actions.GET;
        this.filters.updateViewName(response.data.view);
        this.filters.updateFields(this.fieldsDef);
      });
    } catch (err) {
      runInAction(() => {
        this.errors.fieldsDef = err;
      });
    } finally {
      runInAction(() => {
        this.requestInProgress.fieldsDef = false;
      });
    }
  }

  get query() {
    return {
      ...this.filters.filterQuery,
      ...this.paginationQuery,
    };
  }
}
