import React, { Fragment } from 'react';
import { Modal, Button, notifier } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { bool, object, func } from 'prop-types';

import run from 'App/services/utilities/run';
import formatPayload from 'App/services/utilities/formatPayload';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import { isEmpty, pick } from 'lodash';
import { importLead } from 'Contacts/services/contactsService';
import AddCustomProperty from 'App/components/AddCustomProperty';
import { hasAccess } from 'App/services/permissionsService';

import './LeadImportModal.scss';

const propTypes = {
  visible: bool,
  form: object,
  setFieldsErrors: func,
  userFields: object,
  fetchLeadImportFields: func,
  resetFieldsData: func,
  onSuccess: func,
  getFieldsInProgress: bool,
  updateFieldValue: func,
  validateForm: func,
};

const defaultProps = {
  visible: false,
  form: {},
  setFieldsErrors: null,
  userFields: {},
  fetchLeadImportFields: null,
  resetFieldsData: null,
  onSuccess: null,
  getFieldsInProgress: false,
  updateFieldValue: null,
  validateForm: null,
};

const MODAL_ID = 'IMPORT_LEAD';

const FIELDS = [
  'first_name',
  'last_name',
  'email',
  'phone',
  'country',
  'city',
  'address',
  'zip',
  'tags',
  'language',
  'publisher',
];

const validators = {
  email: 'required|email',
};

export const openLeadImportModal = () => openOverlay(MODAL_ID);
export const closeLeadImportModal = () => closeOverlay(MODAL_ID);

const text = {
  ADD: 'Add',
  CANCEL: 'Cancel',
  TITLE: 'Import lead',
  ADD_SUCCESS: 'Lead successfully added.',
  ADD_ERROR: 'Something went wrong.',
};

const CustomFooter = inject(stores => ({
  hasErrors: !isEmpty(stores.forms.leadImport.fieldErrors),
}))(
  observer(({ execute, cancel, executeButtonLabel, hasErrors }) => (
    <Fragment>
      <Button color="ghost" onClick={cancel}>
        {text.CANCEL}
      </Button>
      <Button disabled={hasErrors} onClick={execute}>
        {executeButtonLabel}
      </Button>
    </Fragment>
  )),
);

const LeadImportModal = ({
  visible,
  form,
  setFieldsErrors,
  userFields,
  fetchLeadImportFields,
  getFieldsInProgress,
  resetFieldsData,
  updateFieldValue,
  validateForm,
  onSuccess,
}) => {
  const hasCreateAccess = React.useMemo(() => hasAccess('contacts_contact', 'create'), []);
  const close = () => {
    closeLeadImportModal();
    resetFieldsData();
  };

  const submit = async () => {
    const { data } = form;
    const isValid = validateForm();
    if (!isValid) return;
    const payload = formatPayload(data);
    const [err, response] = await run(importLead(payload));
    if (err) {
      setFieldsErrors(err.response.data);
      notifier.error(text.ADD_ERROR);
      return;
    }
    if (response) {
      onSuccess();
      notifier.success(text.ADD_SUCCESS);
      close();
    }
  };

  if (hasCreateAccess && isEmpty(userFields) && !getFieldsInProgress) fetchLeadImportFields();
  const fields = pick(userFields, FIELDS);
  const formattedFields = isEmpty(userFields) ? [] : fieldsResolver(fields);

  return (
    <Modal
      closable
      title={text.TITLE}
      icon="Plus"
      visible={visible}
      onCloseIconClick={close}
      footerRender={() => (
        <CustomFooter execute={submit} cancel={close} executeButtonLabel={text.ADD} />
      )}
    >
      <div className="lead-import">
        <div className="lead-import__fields">
          {formattedFields.map(({ id, read_only, ...props }) => (
            <div key={id} className="field-wrapper">
              <Field
                {...props}
                validate={validators[id] || null}
                disabled={read_only}
                formId="leadImport"
                key={id}
                data-recording-ignore="mask"
              />
            </div>
          ))}
        </div>
        <AddCustomProperty
          formId="leadImport"
          fields={userFields}
          updateFieldValue={updateFieldValue}
        />
      </div>
    </Modal>
  );
};

LeadImportModal.propTypes = propTypes;
LeadImportModal.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[MODAL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  fetchLeadImportFields: stores.contact.profile.fetchLeadImportFields,
  userFields: stores.contact.profile.userFields,
  getFieldsInProgress: stores.contact.profile.requestInProgress.getFields,
  resetFieldsData: stores.forms.leadImport.resetFieldsData,
  updateFieldValue: stores.forms.leadImport.updateFieldValue,
  setFieldsErrors: stores.forms.leadImport.setFieldsErrors,
  validateForm: stores.forms.leadImport.validateForm,
  form: stores.forms.leadImport,
}))(observer(LeadImportModal));
