import React from 'react';
import { Pill, notifier } from 'tc-biq-design-system';
import { actionWrapper, PillCell, YesNoCell } from 'App/components/gridCellRenderers';
import hideCols from 'App/services/utilities/hideCols';
import { withRouter } from 'react-router-dom';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import UserCell from 'App/components/gridCellRenderers/UserCell';
import viewNameToRouteEnum from 'App/enums/viewNameToRouteEnum';

const text = {
  NO_PATH_ERROR: 'No route for this saved filter.',
};

const NameRenderer = withRouter(({ data, history }) => {
  const onClick = () => {
    const path = viewNameToRouteEnum[data.view_name];
    if (path) {
      history.push(path);
    } else {
      notifier.error(text.NO_PATH_ERROR);
    }
  };
  return (
    <Pill color="neutral-400" onClick={onClick} onIconClick={onClick}>
      {data.name}
    </Pill>
  );
});

const modifier = actions => ({
  ...hideCols(['filters', 'path', 'view_name']),
  name: { cellRendererFramework: NameRenderer },
  table: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => <Pill type="primary">{value}</Pill>,
  },
  teams: {
    cellRendererFramework: PillCell({ displayName: 'label' }),
  },
  roles: {
    cellRendererFramework: PillCell({ displayName: 'label' }),
  },
  users: {
    cellRendererFramework: PillCell({ displayName: 'label' }),
  },
  owner: {
    // eslint-disable-next-line react/prop-types
    cellRendererFramework: ({ value }) => (
      <UserCell id={value.id} avatar={value.avatar} fullName={extractDisplayName(value)} />
    ),
  },
  available_for_owner: { cellRendererFramework: YesNoCell },
  actions: {
    pinned: 'right',
    width: 80,
    headerName: '',
    enableFiltering: false,
    cellRendererFramework: actionWrapper(actions),
    label: '',
    sortable: false,
    filter: 'agTextColumnFilter',
  },
});

export default modifier;
