import React from 'react';

import { DashboardCard, PieChart } from 'tc-biq-design-system';
import NoData from 'App/components/NoData/NoData';
import { string, bool, array, func } from 'prop-types';

import './PieChart.scss';

const propTypes = {
  title: string,
  description: string,
  dimensions: array,
  data: array,
  inProgress: bool,
  handleCardClick: func,
  color: string,
};

const defaultProps = {
  title: '',
  description: '',
  dimensions: [],
  data: [],
  inProgress: false,
  handleCardClick: null,
  color: null,
};

const PieChartLayout = ({
  title,
  description,
  dimensions,
  data,
  inProgress,
  handleCardClick,
  color,
}) => (
  <DashboardCard
    title={title}
    description={description}
    loading={inProgress}
    actions={dimensions}
    onClick={handleCardClick}
  >
    {data.length ? (
      <PieChart
        data={data}
        height={190}
        innerRadius={30}
        outerRadius={50}
        width={120}
        withLegend
        color={color}
        paddingAngle={0.5}
      />
    ) : <NoData />}
  </DashboardCard>
);

PieChartLayout.propTypes = propTypes;
PieChartLayout.defaultProps = defaultProps;

export default PieChartLayout;
