import { observable, action, makeObservable } from 'mobx';

export interface ITranslateStore {
  lang: string;
}

export default class TranslateStore implements ITranslateStore {
  lang = 'en';

  constructor() {
    makeObservable(this, {
      lang: observable,
      setLanguage: action.bound,
    });
  }

  async setLanguage(lang: string) {
    this.lang = lang;
  }
}
