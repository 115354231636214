import React, { PureComponent } from 'react';
import { func, object, bool, string, number, array } from 'prop-types';
import { toJS } from 'mobx';
import { ComboxFooter, Space, Avatar, Pill, notifier } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import get from 'lodash/get';

import Field from 'App/components/FieldRenderer';
import { getEmptyState } from 'App/components/Editor';
import { searchUsers, fetchEmailInboxes } from 'Contacts/services/contactsService';

import './SendEmail.scss';

const propTypes = {
  closeCombox: func,
  errors: object,
  requestInProgress: bool,
  sendEmail: func,
  data: object,
  files: array.isRequired,
  objectId: number,
  contactId: number,
};

const defaultProps = {
  closeCombox: null,
  errors: null,
  requestInProgress: false,
  sendEmail: null,
  data: {},
  objectId: null,
  contactId: null,
};

const text = {
  CONFIRM_TEXT: 'Send',
  SUBJECT: 'Subject',
  BODY: 'Body',
  FROM: 'From',
};

const BccEnum = {
  CC: {
    label: 'Cc',
    key: 'cc',
  },
  BCC: {
    label: 'Bcc',
    key: 'bcc',
  },
};

const formatInitials = (first, last) => (first && last ? `${first[0]}${first[0]}` : null);

const SubjectField = ({ disabled }) => (
  <Field
    id="subject"
    name="subject"
    placeholder={text.SUBJECT}
    formId="sendEmailForm"
    disabled={disabled}
    className="tc-paragraph-regular"
    rows={10}
  />
);

SubjectField.propTypes = {
  disabled: bool.isRequired,
};

const MessageBodyField = ({ onDropFiles }) => (
  <Field
    id="body"
    name="body"
    type="editor"
    formId="sendEmailForm"
    className="tc-paragraph-regular"
    placeholder={text.BODY}
    onDropFiles={onDropFiles}
  />
);

MessageBodyField.propTypes = {
  onDropFiles: func.isRequired,
};

const BccField = ({ id, name, placeholder }) => {
  const loadUsers = async (query) => {
    try {
      const response = await searchUsers(query);
      return response.data.results
        .map(user => ({
          value: user.id,
          display_name: user.email,
          ...user,
        }))
        .filter(({ email }) => !!email);
    } catch {
      return [];
    }
  };

  return (
    <Field
      id={id}
      async
      loadOptions={loadUsers}
      name={name}
      placeholder={placeholder}
      formId="sendEmailForm"
      className="tc-paragraph-regular"
      type="related field"
      multi
      valueKey="value"
      labelKey="display_name"
    />
  );
};

BccField.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  placeholder: string.isRequired,
};

const From = () => {
  const searchInboxes = async () => {
    try {
      const res = await fetchEmailInboxes();
      return res.data.results.map(({ email, id, provider }) => ({
        value: id,
        display_name: `${email} [${provider}]`,
      }));
    } catch (error) {
      const detail = get(error, 'response.data.detail');
      if (detail) {
        notifier.error(detail);
      }
      return [];
    }
  };

  return (
    <Field
      async
      loadOptions={searchInboxes}
      id="from"
      name="email_inbox"
      placeholder={text.FROM}
      formId="sendEmailForm"
      type="related field"
      valueKey="value"
      labelKey="display_name"
    />
  );
};

// eslint-disable-next-line
const To = ({ label, avatar, firstName, lastName, addresses }) => (
  <div className="fiq-send-email__to">
    <span className="tc-micro-regular text-neutral-500">{`${label}:`}</span>
    <div className="fiq-send-email__to-array">
      {addresses
        && addresses.map(address => (
          <div key={address} className="fiq-send-email__to-wrapper">
            <Space size={8} />
            <Avatar src={avatar} initials={formatInitials(firstName, lastName)} />
            <Space size={4} />
            <span className="tc-paragraph-regular">{address}</span>
            <Space size={4} />
          </div>
        ))}
    </div>
    {!addresses && (
      <React.Fragment>
        <Space size={8} />
        <Avatar src={avatar} initials={formatInitials(firstName, lastName)} />
        <Space size={4} />
        <span className="tc-paragraph-regular">
          {firstName && lastName ? `${firstName} ${lastName}` : 'N/A'}
        </span>
      </React.Fragment>
    )}
  </div>
);

const BccButton = ({ label, valueKey, onClick }) => (
  <div
    onClick={() => {
      onClick(valueKey);
    }}
    tabIndex="0"
    role="button"
    aria-pressed="false"
  >
    {label}
  </div>
);

BccButton.propTypes = {
  label: string.isRequired,
  onClick: func.isRequired,
  valueKey: string.isRequired,
};


const ObserverComboxFooter = inject(stores => ({
  errors: stores.contact.profile.errors,
  sendEmail: stores.contact.profile.sendEmail,
  requestInProgress: stores.contact.profile.requestInProgress.sendSms,
  data: toJS(stores.forms.sendEmailForm.data),
}))(observer(
  class ObserverComboxFooter extends PureComponent {
    onSuccess = async () => {
      const { sendEmail, closeCombox, errors, files, objectId, contactId } = this.props;

      await sendEmail(files, !!objectId, contactId);
      if (closeCombox && !errors.sendEmail) {
        closeCombox();
      }
    };

    render() {
      const { requestInProgress, data, objectId } = this.props;

      // eslint-disable-next-line max-len
      const isDisabled = !data.body || (!data.subject && !objectId) || !data.body.getCurrentContent().hasText();

      return (
        <ComboxFooter
          onConfirm={this.onSuccess}
          isLoading={requestInProgress}
          confirmText={text.CONFIRM_TEXT}
          isDisabled={isDisabled}
        />
      );
    }
  },
));


ObserverComboxFooter.wrappedComponent.propTypes = propTypes;
ObserverComboxFooter.wrappedComponent.defaultProps = defaultProps;

const SendEmail = inject(stores => ({
  resetFields: stores.forms.sendEmailForm.resetFieldsData,
  updateFieldValue: stores.forms.sendEmailForm.updateFieldValue,
  user: stores.loginStore.user,
  contact: stores.contact.profile.contactData,
  getEmailLog: stores.contact.profile.getEmailLog,
  isLoadingGetEmaiLog: stores.contact.profile.requestInProgress.getEmailLog,
}))(observer(
  class SendEmail extends PureComponent {
    constructor({ updateFieldValue, resetFields, objectId }) {
      super();
      this.state = {
        showCc: !!objectId,
        showBcc: !!objectId,
        files: [],
        objectId,
        from_email_address: null,
        to_email_addresses: null,
        inboxesNum: 0,
        contactObject: {},
      };
      resetFields();
      updateFieldValue('body', getEmptyState());
    }

    componentDidMount() {
      const { objectId } = this.state;

      if (objectId) {
        this.prefillEmailData();
      }
    }

    prefillEmailData = async () => {
      const { getEmailLog, updateFieldValue } = this.props;
      const { objectId } = this.state;
      const responses = await Promise.all([getEmailLog(objectId), fetchEmailInboxes()]);
      const {
        subject,
        contact,
        cc,
        bcc,
        email_inbox,
        from_email_address,
        to_email_addresses,
      } = responses[0];

      const isInboxSameAsReply = email_inbox.email === from_email_address;

      this.setState({
        from_email_address: responses[1].data.results[0].email,
        to_email_addresses: isInboxSameAsReply ? to_email_addresses : [from_email_address],
        contactObject: contact,
        inboxesNum: responses[1].data.results.length,
      });

      updateFieldValue('subject', subject);
      updateFieldValue('cc', cc.map(c => ({ email: c, display_name: c })));
      updateFieldValue('bcc', bcc.map(b => ({ email: b, display_name: b })));
      updateFieldValue('email_inbox', {
        value: email_inbox.id,
        display_name: email_inbox.email,
      });
      updateFieldValue('reply_to', objectId);
    };

    onDropFiles = (dropedFiles) => {
      const { files } = this.state;
      this.setState({ files: [...files, ...dropedFiles] });
    };

    onRemoveFile = (index) => {
      const { files } = this.state;
      this.setState({ files: files.filter((_, i) => i !== index) });
    };

    onClickBcc = (key) => {
      if (key === BccEnum.CC.key) {
        this.setState({ showCc: true });
      } else {
        this.setState({ showBcc: true });
      }
    };

    render() {
      const { user, contact, closeCombox, isLoadingGetEmaiLog, objectId } = this.props;
      const {
        showCc,
        contactObject,
        showBcc,
        files,
        to_email_addresses,
        from_email_address,
        inboxesNum,
      } = this.state;

      return (
        <div className="fiq-send-email">
          {inboxesNum <= 1 && from_email_address && !isLoadingGetEmaiLog && (
            <To label="From" addresses={[from_email_address]} />
          )}
          {(!from_email_address || inboxesNum > 1) && !isLoadingGetEmaiLog && (
            <From label="From" firstName={user.first_name} lastName={user.last_name} />
          )}
          <Space size={8} />
          <div className="fiq-send-email__bcc-wrapper">
            {!to_email_addresses && !isLoadingGetEmaiLog && (
              <To label="To" firstName={contact.first_name} lastName={contact.last_name} />
            )}
            {to_email_addresses && !isLoadingGetEmaiLog && (
              <To
                label="To"
                firstName={contact.first_name}
                lastName={contact.last_name}
                addresses={to_email_addresses}
              />
            )}
            <div className="fiq-send-email__bcc">
              {!showCc && (
                <BccButton
                  onClick={this.onClickBcc}
                  label={BccEnum.CC.label}
                  valueKey={BccEnum.CC.key}
                />
              )}
              {!showBcc && (
                <BccButton
                  onClick={this.onClickBcc}
                  label={BccEnum.BCC.label}
                  valueKey={BccEnum.BCC.key}
                />
              )}
            </div>
          </div>
          <Space size={18} />
          {showCc && (
            <BccField id={BccEnum.CC.key} name={BccEnum.CC.key} placeholder={BccEnum.CC.label} />
          )}
          {showBcc && (
            <BccField id={BccEnum.BCC.key} name={BccEnum.BCC.key} placeholder={BccEnum.BCC.label} />
          )}
          <Space size={18} />
          <SubjectField disabled={!!objectId} />
          <div className="fiq-send-email__files">
            {files.map(({ name }, index) => (
              <Pill
                onIconClick={() => {
                  this.onRemoveFile(index);
                }}
                key={name}
                icon="Close"
                iconPosition="right"
              >
                {name}
              </Pill>
            ))}
          </div>
          <Space size={8} />
          <MessageBodyField onDropFiles={this.onDropFiles} />
          <ObserverComboxFooter
            files={files}
            className="fiq-send-email__combox"
            closeCombox={closeCombox}
            objectId={objectId}
            contactId={contactObject.id}
          />
        </div>
      );
    }
  },
));


SendEmail.wrappedComponent.propTypes = {
  updateFieldValue: func.isRequired,
  resetFields: func.isRequired,
  user: object.isRequired,
  contact: object.isRequired,
  closeCombox: func.isRequired,
  objectId: number,
  getEmailLog: func.isRequired,
  isLoadingGetEmaiLog: bool.isRequired,
};

SendEmail.wrappedComponent.defaultProps = {
  objectId: null,
};

export default SendEmail;
