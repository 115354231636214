import React from 'react';

import {
  ActivityStreamFilters,
  ActivityStreamTypeFilter,
} from 'ActivityStream/components/ActivityStreamFilters';
import ActivityStreamBody from 'ActivityStream/components/ActivityStreamBody';

const ActivityStream = () => (
  <div className="fiq-activity-stream">
    <ActivityStreamFilters />
    <ActivityStreamTypeFilter />
    <ActivityStreamBody />
  </div>
);

export default ActivityStream;
