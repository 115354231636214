import http from 'App/services/http';

const fetchNotificationByIds = ids => http.get('/users/notifications/', {
  params: { id__in: ids.join(',') },
});

const fetchNotifications = query => http.get('/users/notifications/', { params: query });

const fetchNotificationsOptions = () => http.options('/users/notifications/');

export { fetchNotifications, fetchNotificationsOptions, fetchNotificationByIds };
