import React from 'react';
import DashboardPage from 'Dashboard/components/DashboardPage';
import { salesDashboard } from 'Dashboard/components/widgets';
import layout from './layout';

const text = {
  HEADER: 'Sales',
  TOOLTIP: 'The dashboard data will be updated automatically every 15 min.',
};

const SalesDashboardPage = () => (
  <DashboardPage
    widgets={salesDashboard}
    title={text.HEADER}
    tooltip={text.TOOLTIP}
    layout={layout}
  />
);

export default SalesDashboardPage;
