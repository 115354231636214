import React, { useState } from 'react';
import { DataGrid, Button, Tooltip, Space } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { any, bool, array, object, string, number, func, shape } from 'prop-types';

import { openOverlay } from 'App/services/overlayService';
import If from 'App/components/If';
import { hasAccess } from 'App/services/permissionsService';
import RenderIcon from 'App/components/RenderIcon';
import useFilterQueryData from 'App/hooks/useFiltersQuery';
import { isPaginationDisabled } from './gridStoreUtils';
import { DateTimeCell, YesNoCell, DecimalCell, DefaultCell } from '../gridCellRenderers';
import Filters, { FiltersServiceFactory } from '../Filters';
import GridServiceFactory from './GridServiceFactory';
import CursorPagination from './CursorPagination';
import ColumnManager from './column-manager';
import getQuickFilters from '../../services/utilities/getQuickFilters';
import './Grid.scss';


const propTypes = {
  gridStore: any.isRequired,
  sizeColumnsToFit: bool,
  customColumns: array,
  bulkActions: array,
  modifiers: object,
  checkboxSelection: bool,
  height: string,
  sizeOptions: array,
  defaultFilters: object,
  defaultOrdering: string,
  defaultPageSize: number,
  onTableFetchCallback: func,
  filterOptions: shape({
    excluded: array,
  }),
  hideColumnManager: bool,
  enableSegmentFiltering: bool,
  getGridApi: func,
  listToOmit: array,
  renderCustomFilterComponent: func,
  useOffset: bool,
};

const defaultProps = {
  sizeColumnsToFit: false,
  customColumns: [],
  bulkActions: [],
  modifiers: {},
  checkboxSelection: false,
  height: 'calc(100vh - 260px)',
  sizeOptions: [25, 50, 100, 200, 300],
  defaultFilters: null,
  defaultOrdering: '',
  defaultPageSize: null,
  onTableFetchCallback: null,
  filterOptions: {},
  hideColumnManager: false,
  enableSegmentFiltering: false,
  getGridApi: () => null,
  listToOmit: null,
  renderCustomFilterComponent: null,
  useOffset: false,
};

const GRID_OPTIONS = {
  reactNext: true,
  enableCellTextSelection: true,
  suppressDragLeaveHidesColumns: true,
};

const setDefaultRenderer = ({ type }) => {
  const defaults = {
    boolean: YesNoCell,
    datetime: DateTimeCell,
    decimal: DecimalCell,
  };

  return {
    cellRendererFramework: defaults[type] || DefaultCell,
    width: type === 'boolean' ? 80 : 160,
  };
};

const buildSortQuery = (sortModel, gridApi) => {
  if (sortModel.length === 0) return null;
  const sortBy = sortModel[0];
  const colDef = gridApi.getColumnDef(sortBy.colId);
  const { field } = colDef;
  const orderPrefix = sortBy.sort === 'desc' ? '-' : '';
  return `${orderPrefix}${field}`;
};

export default function (tableConfig) {
  const { tableKey, tableUrl } = tableConfig;

  const { fetchTableData, fetchTableOptions, submitColumnsState } = GridServiceFactory(
    tableUrl,
    tableKey,
  );

  const filtersService = FiltersServiceFactory(tableUrl);

  const GridComponent = inject(stores => ({
    gridStore: stores.tables[tableKey],
  }))(
    observer(({ gridStore, defaultFilters, defaultOrdering, defaultPageSize, getGridApi,
      filterOptions, modifiers, customColumns, onTableFetchCallback, sizeColumnsToFit, useOffset,
      checkboxSelection, bulkActions, height, sizeOptions, hideColumnManager,
      enableSegmentFiltering, listToOmit, renderCustomFilterComponent,
    }) => {
      const [filtersFromUrl, updateFiltersQuery] = useFilterQueryData();
      const [gridApi, setGridApi] = useState();
      const [columnApi, setColumnApi] = useState();

      React.useEffect(() => {
        applyDefaultParams();
        return () => gridStore.resetTable();
      }, []);

      const applyDefaultParams = () => {
        if (defaultFilters) gridStore.addInitialFilters(defaultFilters);
        if (defaultOrdering) gridStore.setOrdering(defaultOrdering);
        if (defaultPageSize) gridStore.changePageSize(defaultPageSize);
      };

      const onGridReady = async (params) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        await getColumns();
        getGridApi(params.api);
        if (filtersFromUrl) gridStore.mapFiltersFromUrl(filtersFromUrl);
      };

      const getColumns = async () => {
        const response = await fetchTableOptions();
        gridStore.setOptionsFields({
          viewName: response.data.view,
          fields: response.data.actions.GET,
          modifiers,
          customColumns,
          setDefaultRenderer,
          filterOptions,
        });
        sizeColumnsToFitHandler();
      };

      const fetchTableDataLocal = async () => {
        if (gridApi) gridApi.showLoadingOverlay();
        await fetchTableData();
        sizeColumnsToFitHandler();
        updateFiltersQuery(gridStore.currentFilters);
        if (onTableFetchCallback) {
          await onTableFetchCallback(gridStore.data, gridStore.setData);
        }
      };

      const sizeColumnsToFitHandler = () => {
        if (gridApi && columnApi) {
          const columns = columnApi.getAllColumns().filter(col => col.visible);
          const totalColumns = columns.length;
          const displayedColumns = columnApi.getAllDisplayedVirtualColumns().length;
          const smallerThanViewport = totalColumns === displayedColumns;
          if (sizeColumnsToFit || smallerThanViewport) {
            gridApi.sizeColumnsToFit();
          } else {
            const allColumnIds = columns.reduce((ids, current) => {
              const currentColDef = current.colDef;
              const currentColId = current.colId;
              if (!currentColDef.width) ids.push(currentColId);
              return ids;
            }, []);
            columnApi.autoSizeColumns(allColumnIds);
          }
        }
      };

      const nextPage = () => {
        const { count, setDirection, cursor, query: { offset } } = gridStore;
        if (isPaginationDisabled(useOffset, offset, cursor, 'next', count)) return;
        setDirection('next', useOffset);
        fetchTableDataLocal();
      };

      const prevPage = () => {
        const { count, setDirection, cursor, query: { offset } } = gridStore;
        if (isPaginationDisabled(useOffset, offset, cursor, 'prev', count)) return;
        setDirection('prev', useOffset);
        fetchTableDataLocal();
      };

      const changePageSize = (pageSize) => {
        gridStore.changePageSize(pageSize);
        fetchTableDataLocal();
      };

      const applyFilter = (newFilter) => {
        gridStore.resetCursor();
        gridStore.filters.addNewFilter(newFilter);
        gridStore.setWithCount(true);
        fetchTableDataLocal();
      };

      const removeFilter = (filter) => {
        gridStore.filters.removeFilter(filter);
        gridStore.setWithCount(true);
        fetchTableDataLocal();
      };

      const applySegmentFilter = (segmentFilter) => {
        gridStore.resetCursor();
        gridStore.filters.addSegmentFilter(segmentFilter);
        gridStore.setWithCount(true);
        fetchTableDataLocal();
      };

      const onSortChange = (data) => {
        const sortModel = data.api.getSortModel();
        const ordering = buildSortQuery(sortModel, data.api);
        gridStore.setOrdering(ordering);
        fetchTableDataLocal();
      };


      const columnsState = listToOmit
        ? gridStore.columnManager.columnsState.filter(column => !listToOmit.includes(column.key))
        : gridStore.columnManager.columnsState;

      const allFields = listToOmit
        ? gridStore.columnManager.allFields.filter(field => !listToOmit.includes(field.key))
        : gridStore.columnManager.allFields;

      const quickFilters = getQuickFilters(gridStore.filters, modifiers, tableKey, {
        applyFilter,
        removeFilter,
      });

      return (
        <div>
          <div className="fiq-grid__header">
            <Filters
              activeFilters={gridStore.filters.activeFilters}
              enableSegmentFiltering={enableSegmentFiltering}
              activeSegment={gridStore.filters.activeSegment}
              filtersService={filtersService}
              fetchData={fetchTableDataLocal}
              filtersStore={gridStore.filters}
              applyFilter={applyFilter}
              applySegmentFilter={applySegmentFilter}
              savedFilters={gridStore.filters.savedFilters}
              viewName={gridStore.filters.viewName}
              renderCustomFilterComponent={renderCustomFilterComponent}
              setWithCount={gridStore.setWithCount}
            />
            <Space size={32} />
            <If condition={hasAccess('users_managedcolumns', 'read') && !hideColumnManager}>
              <Tooltip title="Manage columns">
                <Button
                  color="ghost"
                  onClick={() => openOverlay('MANAGE_GRID_COLUMNS')}
                  style={{ padding: '8px 0 4px 0', minWidth: '42px' }}
                >
                  <RenderIcon colorName="text-primary-500" icon="Columns" iconSize={16} />
                </Button>
              </Tooltip>
            </If>
          </div>

          <DataGrid
            gridOptions={{
              ...GRID_OPTIONS,
              onViewportChanged: sizeColumnsToFitHandler,
              onGridSizeChanged: sizeColumnsToFitHandler,
              onFirstDataRendered: sizeColumnsToFitHandler,
              onSortChanged: onSortChange,
            }}
            checkboxSelection={checkboxSelection}
            bulkActions={bulkActions}
            bulkSelectAll
            onGridReady={onGridReady}
            rowData={gridStore.data}
            columnDefs={toJS(gridStore.columns)}
            showDefaultPagination={false}
            height={height}
            quickFilters={quickFilters}
          />
          <CursorPagination
            onNext={nextPage}
            onPrev={prevPage}
            cursor={gridStore.cursor}
            onPageSizeChange={changePageSize}
            pageSize={gridStore.query.limit}
            sizeOptions={sizeOptions}
            useOffset={useOffset}
            offset={gridStore.query.offset}
            count={gridStore.count}
          />
          <ColumnManager
            visible={false}
            fetchTableData={getColumns}
            allFields={allFields}
            columnsState={columnsState}
            resetFields={gridStore.resetColumnsState}
            tableId={tableKey}
            submit={submitColumnsState}
            updateFieldVisibility={gridStore.updateFieldVisibility}
            toggleAllFieldsVisibility={gridStore.toggleAllFieldsVisibility}
            setColumnsState={gridStore.setColumnsState}
          />
        </div>
      );
    }),
  );

  GridComponent.wrappedComponent.defaultProps = defaultProps;
  GridComponent.wrappedComponent.propTypes = propTypes;

  return {
    GridComponent,
    gridActions: {
      fetchTableData,
      fetchTableOptions,
    },
  };
}
