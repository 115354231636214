import React from 'react';
import { node, oneOf, func, string, number, object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Icon, HyperLink } from 'tc-biq-design-system';
import classNames from 'classnames';
import store from 'App/rootStore';

import './TreeItem.scss';

const types = {
  ROOT: 'root',
  LEVEL_1: 'level-1',
  LEVEL_2: 'level-2',
  LEVEL_3: 'level-3',
  LEVEL_4: 'level-4',
  LEVEL_5: 'level-5',
  LEVEL_6: 'level-6',
};

const TreeItem = ({ teamId, title, type, children, onClick, description, history }) => {
  const onTeamClick = () => {
    store.tables.users.filters.addNewFilter({
      field: { label: 'Team binding Team', value: 'team_binding__team', type: 'choice' },
      operator: { label: 'is equal to', related_models: null, value: 'exact' },
      value: { value: teamId, display_name: title },
    });
    history.push('/settings/users');
  };
  return (
    <div
      onClick={onClick}
      className={classNames('fiq-tree-item', `fiq-tree-item--${type}`, {
        'fiq-tree-item--clickable': !!onClick,
      })}
    >
      <div size="large" className="fiq-tree-item__title-section">
        <div className="fiq-tree-item__expendable-icon">{!!onClick && <Icon name="CaretDown" />}</div>
        <div className="fiq-tree-item__title tc-heading-s">
          <HyperLink>
            <span onClick={onTeamClick}>{title}</span>
          </HyperLink>
          <div className="tc-micro-regular text-neutral-500">{description}</div>
        </div>
      </div>
      {children && <div className="fiq-tree-item__content">{children}</div>}
    </div>
  );
};

TreeItem.propTypes = {
  title: string.isRequired,
  children: node,
  onClick: func,
  description: string,
  teamId: number.isRequired,
  history: object.isRequired,
  type: oneOf([
    types.ROOT,
    types.LEVEL_1,
    types.LEVEL_2,
    types.LEVEL_3,
    types.LEVEL_4,
    types.LEVEL_5,
  ]),
};

TreeItem.defaultProps = {
  children: null,
  onClick: null,
  type: types.ROOT,
  description: '',
};

export default withRouter(TreeItem);
