import React, { PureComponent } from 'react';
import { InfoBox } from 'tc-biq-design-system';
import { object, func, number } from 'prop-types';
import WithErrorBoundary from 'App/components/hoc/withErrorBoundary';
import { inject, observer } from 'mobx-react';

const propTypes = {
  fetchActivities: func.isRequired,
  onNewActivity: func.isRequired,
  channel: object.isRequired,
  counter: number.isRequired,
};

const MAX_COUNT = 10;
const COUNT_MAX_TEXT = `${MAX_COUNT}+`;

const text = { NEW_ACTIVITIES: 'new activities', NEW_ACTIVITY: 'new activity' };

class NewNotifications extends PureComponent {
  componentDidMount() {
    const { channel, onNewActivity } = this.props;
    if (channel) {
      channel.bind('new-activity', (data) => {
        onNewActivity(data);
      });
    }
  }

  render() {
    const { fetchActivities, counter } = this.props;
    const counterText = counter > MAX_COUNT ? COUNT_MAX_TEXT : counter;
    const activitiesText = counter > 1 ? text.NEW_ACTIVITIES : text.NEW_ACTIVITY;
    return (
      <div>
        {counter ? (
          <div className="fiq-activity-stream__notifications">
            <InfoBox onClick={() => fetchActivities(false)}>
              <span className="fiq-activity-stream__notifications__content-text text-primary-500 tc-paragraph-strong">
                {`${counterText} ${activitiesText}`}
              </span>
            </InfoBox>
          </div>
        ) : null}
      </div>
    );
  }
}

NewNotifications.propTypes = propTypes;

export default WithErrorBoundary(inject(stores => ({
  fetchActivities: stores.activityStream.fetchActivities,
  onNewActivity: stores.activityStream.onNewActivity,
  counter: stores.activityStream.newActivitiesCounter,
}))(observer(NewNotifications)));
