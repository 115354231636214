import zendesk from 'assets/images/zendesk-logo.svg';
import helpscout from 'assets/images/helpscout-logo.svg';
import gmail from 'assets/images/gmail.svg';
import yahoo from 'assets/images/yahoo.svg';
import outlook from 'assets/images/microsoft-outlook.svg';
import exchange from 'assets/images/microsoft-exchange.svg';

const getTicketImgConfig = (provider) => {
  if (provider === 'Zendesk') {
    return {
      src: zendesk,
      label: 'Zendesk',
      background: 'neutral-200',
    };
  }

  if (provider === 'Help Scout') {
    return {
      src: helpscout,
      label: 'Help Scout',
      background: 'primary-100',
    };
  }

  return null;
};

const getEmailImgConfig = (provider) => {
  if (provider === 'gmail') {
    return {
      src: gmail,
      label: 'Gmail',
      background: 'neutral-200',
    };
  }

  if (provider === 'outlook') {
    return {
      src: outlook,
      label: 'Outlook',
      background: 'neutral-200',
    };
  }

  if (provider === 'exchange') {
    return {
      src: exchange,
      label: 'Exchange',
      background: 'neutral-200',
    };
  }

  if (provider === 'yahoo') {
    return {
      src: yahoo,
      label: 'Yahoo',
      background: 'neutral-200',
    };
  }
  return null;
};

export default {
  tickets_ticket: getTicketImgConfig,
  tickets_ticketcomment: getTicketImgConfig,
  communications_emaillog: getEmailImgConfig,
};
