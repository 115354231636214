import React from 'react';

import Page from 'App/components/Page';
import WhatsAppLogsTable, { tableConfig } from 'Communications/components/WhatsAppLogsTable';

const bread = [
  { label: 'Communications' },
  { label: 'WhatsApp Log', route: '/communications/whatsapp-logs' },
];

const exportDataOptions = { permission: 'communications_whatsapplog', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

const text = {
  WHATSAPP_LOGS: 'WhatsApp Logs',
};

const WhatsAppLogsPage = () => (
  <Page bread={bread} title={text.WHATSAPP_LOGS} style={{ margin: '0 20px' }} exportDataOptions={exportDataOptions}>
    <WhatsAppLogsTable />
  </Page>
);

export default WhatsAppLogsPage;
