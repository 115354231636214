import React, { Component, Fragment } from 'react';
import { object, bool, func } from 'prop-types';
import { Sidepanel } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import { closeOverlay } from 'App/services/overlayService';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import SidepanelFooter from 'App/components/SidepanelFooter';
import Loader from 'App/components/Loader/Loader';
import { toJS } from 'mobx';

const propTypes = {
  parameters: object,
  visible: bool,
  fetchFields: func.isRequired,
  loadingFields: bool.isRequired,
  fields: object.isRequired,
  createEditTeam: func.isRequired,
  errors: object,
  fetchData: func.isRequired,
  resetFields: func.isRequired,
  submitInProgress: bool.isRequired,
  teamData: object.isRequired,
  fetchTeamData: func.isRequired,
  loadingTeamData: bool.isRequired,
  validateForm: func.isRequired,
};

const defaultProps = {
  parameters: null,
  visible: false,
  errors: null,
};

const text = {
  SUCCESS: 'Successfully created team',
  ERROR: 'Failed to create team',
  TITLE_CREATE: 'Create team',
  TITLE_EDIT: 'Edit team',
  BUTTON_LABELS_CREATE: {
    confirm: 'Create team',
    cancel: 'Discard',
  },
  BUTTON_LABELS_EDIT: {
    confirm: 'Save changes',
    cancel: 'Discard changes',
  },
};

export const SIDEPANEL_ID = 'CREATE_EDIT_TEAM';

const footer = (execute, close, requestInProgress, isEdit) => () => (
  <SidepanelFooter
    execute={execute}
    close={close}
    submitInProgress={requestInProgress}
    buttonLabels={isEdit ? text.BUTTON_LABELS_EDIT : text.BUTTON_LABELS_CREATE}
    confirmColor="primary"
  />
);

class CreateEditTeam extends Component {
  close = () => {
    const { resetFields } = this.props;
    resetFields();
    closeOverlay(SIDEPANEL_ID);
  };

  createEditTeam = async () => {
    const { createEditTeam, parameters, validateForm } = this.props;
    if (!validateForm()) return;
    await createEditTeam({
      id: parameters.id,
      errorMsg: text.ERROR,
      successMsg: text.SUCCESS,
      cb: this.close,
    });
  };

  fetchFields = () => {
    const { fetchFields, fields, loadingFields } = this.props;
    if (isEmpty(fields) && !loadingFields) fetchFields();
  };

  fetchInitialData = () => {
    const {
      fetchTeamData,
      parameters: { id },
    } = this.props;
    fetchTeamData(id);
  };

  renderFields = () => {
    const { fields, loadingFields } = this.props;
    if (isEmpty(fields) || loadingFields) return null;
    const formattedFields = fieldsResolver(fields);
    return (
      <Fragment>
        {formattedFields.map(({ id, read_only, ...props }) => (
          <Field {...props} disabled={read_only} key={id} formId="createEditTeam" />
        ))}
      </Fragment>
    );
  };

  render() {
    const {
      visible,
      loadingFields,
      parameters,
      teamData,
      submitInProgress,
      loadingTeamData,
    } = this.props;
    if (!visible) return null;
    this.fetchFields();
    if (parameters.id && isEmpty(teamData) && !loadingTeamData) this.fetchInitialData();
    return (
      <Sidepanel
        icon="Pen"
        visible={visible}
        title={parameters.id ? text.TITLE_EDIT : text.TITLE_CREATE}
        onCloseIconClick={this.close}
        footerRender={footer(this.createEditTeam, this.close, submitInProgress, !!parameters.id)}
      >
        <Loader visible={loadingFields} />
        {this.renderFields()}
      </Sidepanel>
    );
  }
}

CreateEditTeam.propTypes = propTypes;
CreateEditTeam.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  loadingFields: stores.teams.requestInProgress.teamFieldsDef,
  submitInProgress: stores.teams.requestInProgress.createEditTeam,
  fields: toJS(stores.teams.teamFieldsDef),
  fetchFields: stores.teams.fetchTeamsOptions,
  createEditTeam: stores.teams.createEditTeam,
  errors: stores.teams.errors.createEditTeam,
  fetchData: stores.teams.fetchTeamsData,
  fetchTeamData: stores.teams.fetchTeamData,
  teamData: stores.teams.teamData,
  loadingTeamData: stores.teams.requestInProgress.teamData,
  resetFields: stores.teams.resetFields,
  validateForm: stores.forms.createEditTeam.validateForm,
}))(observer(CreateEditTeam));
