import Pusher from 'pusher-js';

const HOST = PROD_HOST || DEMO_HOST;

// eslint-disable-next-line import/prefer-default-export
export const newPusher = async token => new Pusher(PUSHER_APP_KEY, {
  cluster: PUSHER_APP_CLUSTER,
  authEndpoint: `${HOST}${APP_ROOT}/pusher/auth/`,
  auth: {
    headers: { Authorization: `Bearer ${token}` },
  },
});
