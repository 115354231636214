import React from 'react';
import { Dropdown, Space, Icon } from 'tc-biq-design-system';
import { string, array } from 'prop-types';
import ActionItem from '../ActionItem';

import './ActionItemDropdown.scss';

const propTypes = {
  icon: string.isRequired,
  actions: array.isRequired,
  label: string,
};

const defaultProps = {
  label: '',
};

const ActionsDropdown = ({ actions }) => (
  <div className="fiq-action-item-dropdown__list">
    {actions.map(action => (
      <div key={action.label} className="list-item" onClick={action.onClick}>
        <Icon colorName="text-neutral-600" name={action.icon} size="small" />
        <Space size={8} />
        <div className="label">{action.label}</div>
      </div>
    ))}
  </div>
);

ActionsDropdown.propTypes = { actions: array.isRequired };

const customList = actions => [{ item: () => <ActionsDropdown actions={actions} /> }];

const ActionItemDropdown = ({ icon, label, actions }) => {
  const customHeader = toggle => <ActionItem icon={icon} label={label} onClick={toggle} />;
  return (
    <div className="fiq-action-item-dropdown">
      <Dropdown openLeft customHeader={customHeader} customList={customList(actions)} />
    </div>
  );
};

ActionItemDropdown.propTypes = propTypes;
ActionItemDropdown.defaultProps = defaultProps;

export default ActionItemDropdown;
