import React, { Fragment, useState, useEffect } from 'react';
import { Icon, Space, Button } from 'tc-biq-design-system';
import { object, number, func, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';

import Field, { fieldsResolverObject } from 'App/components/FieldRenderer';
import NonFieldErrors from 'App/components/NonFieldErrors';
import { openPreviewParameters } from 'Settings/Sections/Webhooks/components/overlays/PreviewParameters';
import formatDataCyString from 'App/services/utilities/formatDataCyString';

import './WebhookTriggers.scss';

const propTypes = {
  webhook: object.isRequired,
  updateWebhookTriggers: func.isRequired,
  eventTriggerErrors: string,
};

const defaultProps = {
  eventTriggerErrors: null,
};

const text = {
  ADD: 'Add trigger event',
  PLACEHOLDER: 'Select event...',
  PREVIEW: 'Preview parameters',
};

const EventBlock = inject(stores => ({
  eventFieldDef: stores.webhooks.fieldsDef.trigger_events,
}))(
  observer(({ event, eventFieldDef, index, removeEvent, updateEvent }) => {
    const [eventValue, setEventValue] = useState(event);
    const fieldProps = eventFieldDef ? fieldsResolverObject({ event: eventFieldDef }) : {};
    const isAdd = isEmpty(event);
    const onChange = (name, value) => {
      updateEvent(value, index);
    };
    const deleteEvent = () => {
      removeEvent(index);
    };
    const openSidepanel = () => openPreviewParameters(eventValue);

    useEffect(() => {
      setEventValue(event);
    }, [event]);

    return (
      <Fragment>
        <div className="fiq-webhook-triggers__block">
          <div className="event">
            {isAdd && (
              <Field
                {...fieldProps.event}
                many={false}
                name="event"
                onChange={onChange}
                value={eventValue}
                placeholder={text.PLACEHOLDER}
                afterFieldSpaceSize={0}
              />
            )}
            {!isAdd && <div data-cy={`name-${formatDataCyString(event.name)}`}>{event.name}</div>}
          </div>
          <Space size={24} />
          <div data-cy="preview-parameters">
            <Button
              size="small"
              disabled={!eventValue && isAdd}
              color="transparent"
              onClick={openSidepanel}
            >
              {text.PREVIEW}
            </Button>
          </div>
          <Space size={16} />
          <div data-cy="delete-event">
            <Icon style={{ cursor: 'pointer' }} name="Delete" size="small" onClick={deleteEvent} />
          </div>
        </div>
      </Fragment>
    );
  }),
);
EventBlock.propTypes = {
  event: object.isRequired,
  index: number.isRequired,
};

const WebhookTriggers = ({ webhook, updateWebhookTriggers, eventTriggerErrors }) => {
  const [events, setEvents] = useState([{}]);
  const addEvent = () => setEvents([...events, {}]);
  const removeEvent = (index) => {
    const eventsCopy = [...events];
    eventsCopy[index] = null;
    const filteredEvents = eventsCopy.filter(event => event);
    updateWebhookTriggers(filteredEvents);
    setEvents(filteredEvents.length ? filteredEvents : [{}]);
  };
  const updateEvent = (data, index) => {
    const eventsCopy = [...events];
    eventsCopy[index] = data;
    updateWebhookTriggers(eventsCopy);
    setEvents(eventsCopy);
  };

  useEffect(() => {
    if (webhook.trigger_events) setEvents(webhook.trigger_events);
  }, [webhook]);

  return (
    <div className="fiq-webhook-triggers">
      <div className="fiq-webhook-triggers__block-wrapper">
        {events.map((event, index) => (
          <EventBlock
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-event`}
            event={event}
            index={index}
            updateEvent={updateEvent}
            removeEvent={removeEvent}
          />
        ))}
      </div>
      {eventTriggerErrors && <NonFieldErrors errors={eventTriggerErrors} />}
      <Space size={16} />
      <div className="fiq-webhook-triggers__add-event" onClick={addEvent}>
        <Icon name="Plus" colorName="text-primary-500" />
        <Space size={8} />
        <div className="text-primary-500 tc-paragraph-strong">{text.ADD}</div>
      </div>
    </div>
  );
};

WebhookTriggers.propTypes = propTypes;
WebhookTriggers.defaultProps = defaultProps;

export default inject(stores => ({
  webhook: stores.webhooks.webhook,
  updateWebhookTriggers: stores.webhooks.updateWebhookTriggers,
  eventTriggerErrors: stores.forms.webhookForm.fieldErrors.trigger_events,
}))(observer(WebhookTriggers));
