import { isEmpty, cloneDeep } from 'lodash';
import fieldTypes from 'App/enums/fieldTypes';

export {
  formatFieldsToArray,
  extractCursorQuery,
  mapColumns,
  filterVisibleFields,
  excludeFilterFields,
  setUrlQueryParams,
  resetUrlQueryParams,
  formatFilterQueries,
  isPaginationDisabled,
};

function isPaginationDisabled(useOffset, offset, cursor, arrow, count) {
  if (useOffset) {
    if (arrow === 'prev') return !offset;
    if (arrow === 'next') return (count < 10) || (count < (offset * 2));
  }

  return !cursor[arrow];
}

function extractCursorQuery(urlString) {
  const url = new URL(urlString);
  return url.searchParams.get('cursor');
}

function isSortable(type) {
  return !(type === fieldTypes.NESTED_OBJECT
    || type === fieldTypes.GENERIC_RELATION
    || type === fieldTypes.METHOD_FIELD
  );
}

function mapColumns(columns, tableModifiers, customColumns, setDefaultRenderer) {
  const result = [];
  Object.keys(columns).forEach(key => result.push({ key, ...columns[key] }));
  const extendedColumns = [...result, ...customColumns];
  return extendedColumns.map(column => ({
    ...setDefaultRenderer(column),
    headerName: column.label,
    field: column.key,
    unSortIcon: true,
    sortable: isSortable(column.type),
    resizable: true,
    ...tableModifiers[column.key],
    comparator: () => 0, // Disable client sorting because we have BE sorting instead
  }));
}

function filterVisibleFields(fields) {
  const temp = {};
  Object.keys(fields).forEach((key) => {
    if (fields[key].visible) temp[key] = fields[key];
  });
  return temp;
}

function excludeFilterFields(fields, excluded) {
  return Object.keys(fields).reduce(
    (acc, key) => (excluded.includes(key) ? acc : { ...acc, [key]: fields[key] }),
    {},
  );
}

function formatFieldsToArray(fields) {
  return Object.keys(cloneDeep(fields))
    .map(key => ({ ...fields[key], key }))
    .filter(field => field.key !== 'id');
}

function setUrlQueryParams(queries, optionFields) {
  if (isEmpty(optionFields)) return;
  const baseUrl = [
    window.location.protocol,
    '//',
    window.location.host,
    window.location.pathname,
  ].join('');
  const url = new URL(baseUrl);
  Object.keys(queries).forEach((key) => {
    if (key === 'cursor') {
      url.searchParams.append(key, decodeURIComponent(queries[key]));
    } else {
      url.searchParams.append(key, queries[key]);
    }
  });
  window.history.replaceState({}, '', url);
}

function resetUrlQueryParams() {
  const baseUrl = [
    window.location.protocol,
    '//',
    window.location.host,
    window.location.pathname,
  ].join('');
  window.history.replaceState({}, '', baseUrl);
}

function formatFilterQueries(query) {
  return Object.keys(query).reduce((acc, key) => {
    if (Array.isArray(query[key])) {
      return { ...acc, [key]: query[key].join(',') };
    }
    if (key === 'segment_ids') {
      // API receives multiple IDs, but UI is set only for one
      return { ...acc, [key]: query[key].toString() };
    }
    return { ...acc, [key]: query[key] };
  }, {});
}
