import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { withComboxManager } from 'tc-biq-design-system';
import { toJS } from 'mobx';
import { array, bool, func, number, object } from 'prop-types';
import { newPusher } from 'App/services/pusherService';

import Loader from 'App/components/Loader';
import NoData from 'App/components/NoData/NoData';
import If from 'App/components/If/index';
import InfiniteScroll from 'App/components/InfiniteScroll';
import { formatByDate } from 'App/services/utilities/dateGroupUtils';
import DateGroup from './DateGroup';
import NewNotifications from './NewNotifications';

const propTypes = {
  activities: array.isRequired,
  hasMore: bool.isRequired,
  requestInProgress: bool.isRequired,
  fetchActivities: func.isRequired,
  userId: number.isRequired,
  checkHighlight: func.isRequired,
  incrementCommentsCounter: func.isRequired,
  initThread: func.isRequired,
  threads: object.isRequired,
  hideThread: func.isRequired,
  comboxManager: object.isRequired,
  loadMore: func.isRequired,
  getToken: func.isRequired,
};

class ActivityStreamBody extends PureComponent {
  async componentWillMount() {
    const { userId, incrementCommentsCounter, getToken } = this.props;
    const token = await getToken();
    this.pusher = await newPusher(token);
    this.channel = this.pusher.subscribe(`private-activity-stream-${userId}`);
    this.channel.bind('new-comment', ({ activity_id }) => {
      incrementCommentsCounter(activity_id);
    });
  }

  componentWillUnmount() {
    this.channel?.unbind();
    this.pusher?.unsubscribe(this.channel.name);
  }

  fetchActivities = async () => {
    const { fetchActivities } = this.props;
    await fetchActivities(true);
  };

  render() {
    const {
      hasMore,
      threads,
      hideThread,
      initThread,
      activities,
      requestInProgress,
      checkHighlight,
      comboxManager,
      loadMore,
    } = this.props;

    const dateGroups = formatByDate(activities);

    return (
      <InfiniteScroll
        hasMore={hasMore}
        fetchData={this.fetchActivities}
        isLoading={requestInProgress}
        style={{
          overflowY: 'auto',
          maxHeight: '90vh',
          width: '100%',
        }}
      >
        {this.channel && <NewNotifications channel={this.channel} />}
        {Object.entries(dateGroups).map(([dateGroupLabel, groupActivities], index) => (
          <DateGroup
            key={`${dateGroupLabel}`}
            groupTitle={dateGroupLabel}
            activities={groupActivities}
            isFirst={index === 0}
            checkHighlight={checkHighlight}
            initThread={initThread}
            hideThread={hideThread}
            threads={toJS(threads)}
            comboxManager={comboxManager}
            loadMore={loadMore}
          />
        ))}
        <Loader visible={requestInProgress} />
        <If condition={activities.length === 0 && !requestInProgress}>
          <div style={{ width: '100%', height: '500px' }}>
            <NoData />
          </div>
        </If>
      </InfiniteScroll>
    );
  }
}

ActivityStreamBody.propTypes = propTypes;

export default withComboxManager(inject(stores => ({
  activities: stores.activityStream.activities,
  hasMore: stores.activityStream.hasMore,
  requestInProgress: stores.activityStream.requestInProgress.activityStream,
  fetchActivities: stores.activityStream.fetchActivities,
  setInitialFilters: stores.activityStream.setInitialFilters,
  highlightCount: stores.activityStream.highlightCount,
  userId: stores.loginStore.user.id,
  checkHighlight: stores.activityStream.checkHighlight,
  incrementCommentsCounter: stores.activityStream.incrementCommentsCounter,
  initThread: stores.activityStream.initThread,
  hideThread: stores.activityStream.hideThread,
  threads: stores.activityStream.threads,
  loadMore: stores.activityStream.loadMore,
  getToken: stores.loginStore.getToken,
}))(observer(ActivityStreamBody)));
