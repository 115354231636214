import React from 'react';
import { string } from 'prop-types';
import moment from 'moment';
import { Icon, Space } from 'tc-biq-design-system';

const propTypes = {
  value: string,
};

const defaultProps = {
  value: null,
};

const DateTimeCell = ({ value }) => {
  const date = moment(value, moment.ISO_8061);
  if (!date.isValid()) {
    return <div>-</div>;
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center', minWidth: '140px' }}>
      {moment(value).format('DD-MM-YYYY')}
      <Space size={8} />
      <Icon name="Clock" colorName="text-neutral-500" size="small" />
      <Space size={4} />
      <span className="text-neutral-500">{moment(value).format('HH:mm:ss')}</span>
    </div>
  );
};

DateTimeCell.propTypes = propTypes;
DateTimeCell.defaultProps = defaultProps;
export default DateTimeCell;
