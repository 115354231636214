
import http from 'App/services/http';

const fetchMetrics = () => http.get('/metrics/');
const fetchMetricsData = query => http.post('/metrics/', { ...query });


export {
  fetchMetrics,
  fetchMetricsData,
};
