import React, { useState, useEffect } from 'react';
import { TimeDelay, Space } from 'tc-biq-design-system';
import { string, object, oneOfType, func, number, bool } from 'prop-types';

const propTypes = {
  name: string.isRequired,
  label: string,
  value: oneOfType([string, object]),
  onChange: func.isRequired,
  afterFieldSpaceSize: number,
  hasError: bool,
  helpText: string,
  disabled: bool,
};

const defaultProps = {
  label: '',
  hasError: false,
  afterFieldSpaceSize: 18,
  value: null,
  helpText: null,
  disabled: false,
};

const MAX_DELAY = 999999999;

const DELAY_TYPE = {
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days',
};

const delayTypeOptions = [
  {
    display_name: 'Minutes',
    value: DELAY_TYPE.MINUTES,
  },
  {
    display_name: 'Hours',
    value: DELAY_TYPE.HOURS,
  },
  {
    display_name: 'Days',
    value: DELAY_TYPE.DAYS,
  },
];

const getDelayTypeOption = type => delayTypeOptions.find(({ value }) => type === value);

const getDelayValues = (value) => {
  if (!value) {
    return [0, 0, 0];
  }

  const [days, hoursMinutes] = value.split(' ');
  const [hours, minutes] = (hoursMinutes || value).split(':');
  const values = [Number(days || 0), Number(hours), Number(minutes)];
  // NOTE: Here we round the value to a lower unit, because BE can return
  // values spread on different units e.g. 1 day and 2 hours becomes 26 hours
  // with this calculation.
  if (values[0] && values[1]) {
    values[1] += values[0] * 24;
    values[0] = 0;
  }
  if (values[1] && values[2]) {
    values[2] += values[1] * 60;
    values[1] = 0;
  }
  return values;
};

const getDelay = (value, delayType) => {
  const [days, hours, minutes] = getDelayValues(value);
  switch (delayType) {
    case DELAY_TYPE.MINUTES:
      return minutes;
    case DELAY_TYPE.HOURS:
      return hours;
    case DELAY_TYPE.DAYS:
      return days;
    default:
      return 0;
  }
};

const getDelayType = (value, currentDelayType) => {
  const [days, hours, minutes] = getDelayValues(value);

  if (days === 0 && hours === 0 && minutes === 0) {
    return currentDelayType;
  }

  if (days > 0) {
    return getDelayTypeOption('days');
  }

  if (hours > 0) {
    return getDelayTypeOption('hours');
  }

  return getDelayTypeOption('minutes');
};

const formatDuration = (delay, delayType) => {
  const cappedDelay = delay > 0 ? Math.min(delay, MAX_DELAY) : Math.max(delay, 0);
  switch (delayType) {
    case DELAY_TYPE.MINUTES:
      return `0 00:${cappedDelay}:00`;
    case DELAY_TYPE.HOURS:
      return `0 ${cappedDelay}:00:00`;
    case DELAY_TYPE.DAYS:
      return `${cappedDelay} 00:00:00`;
    default:
      return '0 00:00:00';
  }
};

const DurationField = (props) => {
  const { name, value, onChange, afterFieldSpaceSize } = props;
  const [delayType, setDelayType] = useState(getDelayTypeOption('minutes'));

  useEffect(() => {
    setDelayType(getDelayType(value, delayType));
  }, [value]);

  const delay = getDelay(value, delayType.value);

  return (
    <div data-cy={name}>
      <TimeDelay
        {...props}
        delayType={delayType}
        delay={delay}
        setDelayType={(changedDelayType) => {
          onChange(name, formatDuration(delay, changedDelayType.value));
          setDelayType(changedDelayType);
        }}
        setDelay={(changedDelay) => {
          onChange(name, formatDuration(changedDelay, delayType.value));
        }}
        delayTypeOptions={delayTypeOptions}
      />
      {afterFieldSpaceSize && <Space size={afterFieldSpaceSize} />}
    </div>
  );
};

DurationField.propTypes = propTypes;
DurationField.defaultProps = defaultProps;

export default DurationField;
