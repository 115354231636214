import React, { PureComponent, Fragment } from 'react';
import { Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Page from 'App/components/Page';
import InviteUsersHome from 'Settings/Sections/Users/components/InviteUsersHome';
import InviteUsersTable from 'Settings/Sections/Users/components/InviteUsersTable';
import { openOverlay } from 'App/services/overlayService';
import AddUser from 'Settings/Sections/Users/components/sidepanels/AddUser';
import AddSingleUser from '../../components/sidepanels/AddSingleUser';

const bread = [
  { label: 'Settings' },
  { label: 'Users', route: '/settings/users' },
  { label: 'Invite users', route: '/settings/users/invite' },
];

const text = {
  INVITE_USERS: 'Invite users',
  SEND_INVITATION: 'Send Invitation',
  DISCARD: 'Discard',
};

const SIDEPANEL_ID = 'ADD_USER';

const InviteUsers = inject(stores => ({
  inviteUsers: stores.usersStore.inviteUsers,
  hasUsers: stores.usersStore.hasUsers,
}))(
  observer(({ inviteUsers, hasUsers }) => (
    <Button disabled={!hasUsers} onClick={inviteUsers}>
      {text.SEND_INVITATION}
    </Button>
  )),
);

class InviteUsersPage extends PureComponent {
  addUser = () => {
    openOverlay(SIDEPANEL_ID);
  };

  headerActions = () => {
    const { history } = this.props; // eslint-disable-line
    return (
      <Fragment>
        <Button color="ghost" onClick={() => history.push('/settings/users')}>
          {text.DISCARD}
        </Button>
        <InviteUsers />
      </Fragment>
    );
  };

  render() {
    return (
      <Page
        headerActions={this.headerActions}
        bread={bread}
        title={text.INVITE_USERS}
        style={{ margin: '32px' }}
      >
        <InviteUsersHome />
        <InviteUsersTable />
        <AddUser />
        <AddSingleUser />
      </Page>
    );
  }
}

InviteUsersPage.displayName = text.INVITE_USERS;

export default withRouter(InviteUsersPage);
