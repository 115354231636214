import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { object, func } from 'prop-types';
import { ComboxProvider } from 'tc-biq-design-system';
import { isEmpty } from 'lodash';

// Components
import CampaignHeader from 'Marketing/components/CampaignHeader';
import CampaignChart from 'Marketing/components/CampaignChart';
import CampaignTabs from 'Marketing/components/CampaignTabs';
import PreviewCampaignDetails, {
  openCampaignDetails,
} from 'Marketing/components/overlays/PreviewCampaignDetails/PreviewCampaignDetails';
import PreviewMessage, { openPreviewMessage } from 'Marketing/components/overlays/PreviewMessage';
import PreviewCampaign, {
  openPreviewCampaign,
} from 'Marketing/components/overlays/PreviewCampaign';

// Services
import { fetchKPIs } from 'Marketing/services/CampaignService';

const propTypes = {
  match: object.isRequired,
  campaign: object.isRequired,
  fetchCampaign: func.isRequired,
  fetchEventMetadata: func.isRequired,
  fetchSendToQueryBuilderMetadata: func.isRequired,
  resetCampaign: func.isRequired,
};

const text = {
  SENT: 'Sent',
  FAILED: 'Failed',
  OPENED: 'Opened',
  CLICKED: 'Clicked',
  CONVERTED: 'Converted',
  REPORTED_AS_SPAM: 'Reported as spam',
  UNSUBSCRIPTIONS: 'Unsubscriptions',
};

const emptyKpiData = [
  { text: text.SENT, percent: '-', value: '-' },
  { text: text.FAILED, percent: '-', value: '-' },
  { text: text.OPENED, percent: '-', value: '-' },
  { text: text.CLICKED, percent: '-', value: '-' },
  { text: text.CONVERTED, percent: '-', value: '' },
  { text: text.REPORTED_AS_SPAM, percent: '-', value: '-' },
  { text: text.UNSUBSCRIPTIONS, percent: '-', value: '-' },
];

const toPercentage = (all, part) => (part ? `${Math.round((part / all) * 100)}%` : '-');

const formatKpiData = (data) => {
  const { clicks, failed, opens, reported_as_spam, sent, unsubscribed } = data;

  const failedPercent = toPercentage(sent, failed);
  const openedPercent = toPercentage(sent, opens);
  const spamPercent = toPercentage(sent, reported_as_spam);
  const clickedPercent = toPercentage(sent, clicks);
  const unsubscriptionsPercent = toPercentage(sent, unsubscribed);

  const convertedValue = data.conversions
    .map(item => item.count)
    .reduce((prev, curr) => prev + curr, 0);
  const convertedPercent = toPercentage(sent, convertedValue);

  return [
    { text: text.SENT, percent: sent, value: sent },
    { text: text.FAILED, percent: failedPercent, value: failed },
    { text: text.OPENED, percent: openedPercent, value: opens },
    { text: text.CLICKED, percent: clickedPercent, value: clicks },
    { text: text.CONVERTED, percent: convertedPercent, value: convertedValue },
    { text: text.REPORTED_AS_SPAM, percent: spamPercent, value: reported_as_spam },
    { text: text.UNSUBSCRIPTIONS, percent: unsubscriptionsPercent, value: unsubscribed },
  ];
};

const CampaignDetailsPage = ({
  campaign,
  match,
  fetchCampaign,
  fetchSendToQueryBuilderMetadata,
  fetchEventMetadata,
  resetCampaign,
}) => {
  const [kpiData, setKpiData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const { campaignId } = match.params;

  const openDetails = () => openCampaignDetails(campaign);

  const fetchData = async () => {
    await fetchCampaign(campaignId);
  };

  const fetchKpiData = async () => {
    const { data } = await fetchKPIs(campaignId);
    setKpiData(formatKpiData(data));
    setChartData(data.conversions);
  };

  useEffect(() => {
    if (campaign.goal_event_type) fetchEventMetadata(campaign.goal_event_type.id);
    if (campaign.send_to_query) fetchSendToQueryBuilderMetadata();
  }, [campaign]);

  useEffect(() => {
    if (!isEmpty(campaign) && campaign.status !== 'Draft') {
      fetchKpiData();
    } else {
      setKpiData(emptyKpiData);
      setChartData([]);
    }
  }, [campaign.status]);

  useEffect(() => {
    fetchData();
    return () => resetCampaign();
  }, []);

  return (
    <ComboxProvider>
      <CampaignHeader
        campaign={campaign}
        goBackIcon
        openDetails={openDetails}
        openPreviewMessage={openPreviewMessage}
        openPreviewCampaign={openPreviewCampaign}
        kpiData={kpiData}
      />
      <CampaignChart data={chartData} />
      <CampaignTabs campaignId={campaignId} />
      <PreviewCampaignDetails />
      <PreviewMessage />
      <PreviewCampaign />
    </ComboxProvider>
  );
};

CampaignDetailsPage.propTypes = propTypes;

export default inject(stores => ({
  fetchCampaign: stores.campaigns.fetchCampaignData,
  campaign: stores.campaigns.campaign,
  fetchSendToQueryBuilderMetadata: stores.campaigns.fetchSendToQueryBuilderMetadata,
  fetchEventMetadata: stores.campaigns.fetchEventMetadata,
  resetCampaign: stores.campaigns.resetCampaign,
}))(observer(withRouter(CampaignDetailsPage)));
