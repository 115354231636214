const normalizeKey = key => key
  .toLowerCase()
  .split('_')
  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');

const normalizeValue = value => value
  .toLowerCase()
  .replace('payload', '')
  .split('.')
  .map(s => normalizeKey(s))
  .join(' ');

const templatePropertyResolver = (properties) => {
  const options = [];

  const iterate = (obj, parents) => {
    Object.keys(obj).forEach((key) => {
      const property = obj[key];
      const keys = parents ? parents.concat([key]) : [key];

      if (property.type === 'nested object') {
        iterate(property.children, keys);
      } else {
        const value = keys.join('.');
        options.push({
          display_name: normalizeValue(value),
          value: `{{${value}}}`,
        });
      }
    });
  };

  iterate(properties, null);

  return options;
};

export default templatePropertyResolver;
