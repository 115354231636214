import React from 'react';
import { withI18n } from '@lingui/react';

import GridComponentFactory from 'App/components/grid';
import modifiers from './modifier';

export const tableConfig = {
  tableUrl: '/audit-logs/',
  tableKey: 'auditLogs',
};

const { GridComponent } = GridComponentFactory(tableConfig);

const AuditLogTable = () => <GridComponent  modifiers={modifiers} />;

export default withI18n()(AuditLogTable);
