import React from 'react';

import Page from 'App/components/Page';
import CallLogsTable, { tableConfig } from 'Communications/components/CallLogsTable';

const bread = [
  { label: 'Communications' },
  { label: 'Call Log', route: '/communications/call-logs' },
];
const exportDataOptions = { permission: 'communications_calllog', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

const text = {
  CALL_LOG: 'Call Log',
};

const CallLogsPage = () => (
  <Page bread={bread} title={text.CALL_LOG} style={{ margin: '0 20px' }} exportDataOptions={exportDataOptions}>
    <CallLogsTable />
  </Page>
);

export default CallLogsPage;
