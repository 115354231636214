import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Row, Space } from 'tc-biq-design-system';

import DisplayDetail from 'App/components/DisplayDetail';

const formatDate = date => moment(date, moment.ISO_8601).format('MMMM Do YYYY, \\at HH:mm');

const text = {
  EXTERNAL_ID: 'External ID',
  ASSET: 'Asset',
  AMOUNT: 'Amount',
  FEE: 'Fee',
  GATEWAY: 'Gateway',
  TIME: 'Time',
  ACCOUNT: 'Account',
};

const WithdrawalDetails = inject(stores => ({
  created: stores.transactions.withdrawalData.created,
  type: stores.transactions.withdrawalData.type,
  gateway: stores.transactions.withdrawalData.gateway,
  externalId: stores.transactions.withdrawalData.external_id,
  amount: stores.transactions.withdrawalData.amount,
  fee: stores.transactions.withdrawalData.fee,
  asset: stores.transactions.withdrawalData.amount_asset,
  account: stores.transactions.withdrawalData.wallet.account.provider,
}))(
  observer(({ created, amount, fee, asset, gateway, externalId, account }) => {
    const time = created ? formatDate(created) : '';
    return (
      <Fragment>
        <Row gutter={0}>
          <div style={{ display: 'flex', width: '100%' }}>
            <DisplayDetail header={text.EXTERNAL_ID} value={externalId} />
            <DisplayDetail header={text.ASSET} value={asset} />
            <DisplayDetail header={text.AMOUNT} value={amount} />
            <DisplayDetail header={text.FEE} value={fee} />
            <DisplayDetail header={text.GATEWAY} value={gateway} />
            <DisplayDetail header={text.TIME} value={time} />
          </div>
        </Row>
        <Space size={8} />
        <Row gutter={0}>
          <div style={{ display: 'flex', width: '100%' }}>
            <DisplayDetail header={text.ACCOUNT} value={account} />
          </div>
        </Row>
      </Fragment>
    );
  }),
);

export default WithdrawalDetails;
