import React, { PureComponent } from 'react';

import Page from 'App/components/Page';
import { Button } from 'tc-biq-design-system';

import DocumentTypesTable, { tableConfig } from 'Settings/Sections/Documents/components/DocumentTypeTable';
import { openOverlay } from 'App/services/overlayService';
import { hasAccess } from 'App/services/permissionsService';

const text = {
  SYSTEM_LABEL: 'Settings',
  DOCUMENT_TYPES_LABEL: 'Document types',
  ADD_DOCUMENT_TYPE_LABEL: 'Create Document type',
};

const bread = [
  { label: text.SYSTEM_LABEL, route: '/settings' },
  { label: text.DOCUMENT_TYPES_LABEL, route: '/settings/document-types' },
];

const exportDataOptions = { permission: 'documents_documenttype', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

class DocumentTypesPage extends PureComponent {
  headerActions = () => {
    if (!hasAccess('documents_documenttype', 'create')) return null;
    return (
      <Button onClick={() => openOverlay('ADD_EDIT_DOCUMENT_TYPE', { type: 'add' })}>
        {text.ADD_DOCUMENT_TYPE_LABEL}
      </Button>
    );
  };

  render() {
    return (
      <Page
        headerActions={this.headerActions}
        bread={bread}
        title={text.DOCUMENT_TYPES_LABEL}
        style={{ margin: '0 20px' }}
        exportDataOptions={exportDataOptions}
      >
        <DocumentTypesTable />
      </Page>
    );
  }
}

export default DocumentTypesPage;
