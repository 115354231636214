import React from 'react';
import { Input } from 'tc-biq-design-system';
import { func } from 'prop-types';
import useEnterPress from './hooks/useEnterPress';

const propTypes = {
  onChange: func.isRequired,
  onEnter: func,
};

const defaultProps = {
  onEnter: () => null,
};

const TextInput = ({ onEnter, onChange, ...props }) => {
  const onKeyPress = useEnterPress(onEnter);
  return (
    <Input
      {...props}
      onChange={e => onChange(e.target.value)}
      data-recording-ignore="mask"
      onKeyPress={onKeyPress}
    />
  );
};

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;

export default TextInput;
