import React, { useContext, useState } from 'react';
import { Input } from 'tc-biq-design-system';

import SavedFiltersAccordion from './SavedFiltersAccordion';
import SavedFilter from './SavedFilter';
import { SavedFiltersContext } from '../FiltersProvider';

import './SavedFiltersDropdown.scss';

const text = {
  PLACEHOLDER: 'Search',
  FAVORITES: 'Favorites',
  CREATED: 'Created by me',
  SHARED: 'Shared with me',
  NO_FILTERS: 'No saved filters',
  ALL: 'All',
};

const AccordionBody = ({ filters }) => {
  if (!filters || filters.length === 0) return <div className="fiq-saved-filters-dropdown__no-filters">{text.NO_FILTERS}</div>;
  return filters.map(filter => <SavedFilter key={filter.filterId} filter={filter} />);
};

const SavedFiltersDropdown = () => {
  const [searchTerm, updateSearchTerm] = useState('');
  const {
    filtersStore: { updateActiveFilters, updateFilterQuery, emptyActiveFilter },
    savedFilters,
    fetchData,
    fetchSavedFilters,
    setWithCount,
  } = useContext(SavedFiltersContext);

  const onInputChange = (event) => {
    updateSearchTerm(event.target.value);
    fetchSavedFilters(event);
  };

  const onClickAll = () => {
    updateActiveFilters([]);
    updateFilterQuery({});
    emptyActiveFilter();
    setWithCount(true);
    fetchData();
  };

  return (
    <div className="fiq-saved-filters-dropdown">
      <div className="fiq-saved-filters-dropdown__search">
        <Input
          typo="text"
          icon="Search"
          value={searchTerm}
          iconPosition="left"
          placeholder={text.PLACEHOLDER}
          onChange={onInputChange}
        />
      </div>
      <div className="fiq-saved-filters-dropdown__all tc-micro-strong" onClick={onClickAll}>
        {text.ALL}
      </div>
      <SavedFiltersAccordion title={text.FAVORITES} isOpen>
        <AccordionBody filters={savedFilters ? savedFilters.favorites : []} />
      </SavedFiltersAccordion>
      <SavedFiltersAccordion title={text.CREATED} isOpen>
        <AccordionBody filters={savedFilters ? savedFilters.created : []} />
      </SavedFiltersAccordion>
      <SavedFiltersAccordion title={text.SHARED} isOpen>
        <AccordionBody filters={savedFilters ? savedFilters.shared : []} />
      </SavedFiltersAccordion>
    </div>
  );
};

export default SavedFiltersDropdown;
