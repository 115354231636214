import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { object, func } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { isEmpty } from 'lodash';

import Page from 'App/components/Page';
import Stepper from 'App/components/Stepper';
import { states } from 'Marketing/utils/campaign';

import CampaignWorkflow from '../../components/CampaignWorkflow';
import CampaignForm from '../../components/CampaignForm';

export const stepKeys = {
  DETAILS: 'details',
  WORKFLOW: 'workflow',
  GOAL: 'goal',
};

const text = {
  TITLE: 'Campaigns',
  SINGLE_TITLE: 'Campaign',
};

const bread = name => [{ label: text.TITLE, route: '/marketing/campaigns' }, { label: name }];

const isDetailsDone = campaign => !!campaign?.send_to_type && !!campaign?.start_datetime;

const isWorkflowDone = (campaign, workflow) => workflow?.blocks && workflow.blocks.length > 1;

const getSteps = () => [
  {
    key: stepKeys.DETAILS,
    title: 'Campaign  details',
    component: () => <CampaignForm state={states.DETAILS} />,
    isDone: isDetailsDone,
    isDisabled: () => false,
  },
  {
    key: stepKeys.WORKFLOW,
    title: 'Workflow',
    component: isActive => <CampaignWorkflow isActive={isActive} />,
    isDone: isWorkflowDone,
    isDisabled: campaign => !isDetailsDone(campaign),
  },
  {
    key: stepKeys.GOAL,
    title: 'Set goal',
    component: () => <CampaignForm state={states.GOAL} />,
    isDisabled: (campaign, workflow) => !isWorkflowDone(campaign, workflow),
    isDone: campaign => campaign?.goal_attribution_window && campaign?.goal_event_type,
  },
];

const propTypes = {
  match: object.isRequired,
  history: object.isRequired,
  campaign: object.isRequired,
  workflow: object.isRequired,
  fetchCampaign: func.isRequired,
};

const CampaignsSinglePage = ({ match, history, campaign, workflow, fetchCampaign }) => {
  const { campaignId, step } = match.params;
  const [steps, setSteps] = useState([]);
  const [stepResults, setStepResults] = useState({ done: [], disabled: [] });
  const [activeStep, setActiveStep] = useState(stepKeys.DETAILS);

  const onStepChange = async (key) => {
    history.push(`/marketing/campaigns/${campaignId}/setup/${key}`);
  };

  const validateCampaign = () => {
    const result = steps.reduce(
      (acc, { isDone, isDisabled, key }) => ({
        disabled: isDisabled(campaign, workflow) ? [...acc.disabled, key] : acc.disabled,
        done: isDone(campaign, workflow) ? [...acc.done, key] : acc.done,
      }),
      { done: [], disabled: [] },
    );
    setStepResults(result);
  };

  useEffect(() => {
    fetchCampaign(campaignId);
  }, [campaignId]);

  useEffect(() => {
    setSteps(getSteps());
  }, [campaign]);

  useEffect(() => {
    if (step) setActiveStep(step);
  }, [step]);

  useEffect(() => {
    if (campaign?.id) validateCampaign();
  }, [campaign, steps, workflow]);

  if (!isEmpty(campaign) && campaign.status !== 'Draft') {
    return <Redirect to="/no-permission" />;
  }

  return (
    <Page bread={bread(campaign?.name)} title={`${text.SINGLE_TITLE} ${campaign?.name}`}>
      <Stepper
        steps={steps}
        onChange={onStepChange}
        activeStep={activeStep}
        disabledSteps={stepResults.disabled}
        doneSteps={stepResults.done}
      />
    </Page>
  );
};

CampaignsSinglePage.propTypes = propTypes;

export default inject(stores => ({
  updateCampaign: stores.campaigns.updateCampaign,
  campaign: stores.campaigns.campaign,
  workflow: stores.campaigns.workflow,
  fetchCampaign: stores.campaigns.fetchCampaignData,
}))(observer(withRouter(CampaignsSinglePage)));
