import React, { PureComponent } from 'react';
import { Icon, Space } from 'tc-biq-design-system';

import GridComponentFactory from 'App/components/grid';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
// import http from 'App/services/http';
import modifiers from './modifiers';

import './ExportData.scss';

const tableConfig = {
  tableUrl: '/users/data-exports/',
  tableKey: 'exportData',
};

const text = {
  TITLE: 'My data exports',
};

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);


class ExportData extends PureComponent {
  render() {
    return (
      <div className="fiq-user-export-data">
        <div className="fiq-user-export-data__title">
          <Icon name="Download" />
          <Space size={12} />
          <div className="tc-heading-s">{text.TITLE}</div>
        </div>
        <GridComponent
          height="calc(100vh - 450px)"
          modifiers={modifiers(gridActions.fetchTableData)}
        />
      </div>
    );
  }
}

export default withErrorBoundary(ExportData);
