export default {
  users_user: '/users/',
  users_managedcolumns: '/users/managed-columns/',
  users_team: '/users/teams/',
  users_role: '/users/roles/',
  contact_access_groups_contactaccessgroup: '/contact-access-groups/',
  contacts_contact: '/contacts/',
  contacts_tag: '/tags/',
  finances_wallet: '/finances/wallets/',
  finances_paymenttransaction: '/finances/payment-transactions/',
  finances_tradeorder: '/finances/trade-order/',
  finances_tradetransaction: '/finances/trade-transactions/',
  finances_tradeposition: '/finances/trade-positions/',
  tasks_task: '/tasks/',
  documents_documenttype: '/documents/types/',
  finances_withdrawrequest: '/finances/withdraw-requests/',
  contacts_whitelabel: '/whitelabels/',
  automations_segment: '/automations/segments/',
  marketing_trackinglink: '/marketing/tracking-links/',
  automations_audience: '/automations/audiences/',
  email_templates: '/communications/templates/',
  custom_properties: '/custom-properties/',
  communications_template: '/communications/templates/',
  events_coreevent: '/events/core/',
};
