import React, { useState, useEffect, FC } from 'react';
import { notifier, Col } from 'tc-biq-design-system';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Loader from 'App/components/Loader';
import moment from 'moment';

import auditLogService from 'Settings/Sections/AuditLog/services/auditLogService';
import Page from 'App/components/Page';
import {
  AuditLogSingleTable,
  LogDetails,
} from 'Settings/Sections/AuditLog/components';
import { LogDetailData } from 'Settings/Sections/AuditLog/components/LogDetails';

import './AuditLogSinglePage.scss';

const bread = (id: string) => [
  { label: 'Settings', route: '/settings' },
  { label: 'Audit logs', route: '/settings/audit-logs' },
  { label: id, route: `/settings/audit-logs/${id}` },
];

const text = {
  AUDIT_LOG: 'Audit log',
};

export interface AuditLogSinglePageProps extends RouteComponentProps<any> {
  logId: string;
}

const AuditLogSinglePage: FC<AuditLogSinglePageProps> = ({ match }) => {
  const [requestInProgress, setInProgress] = useState<boolean>(false);
  const [data, setData] = useState<LogDetailData | null>(null);
  const { logId } = match.params;

  const fetchAuditLogData = async () => {
    try {
      setInProgress(true);
      const response = await auditLogService.fetchAuditLogDetails(logId);
      setData(response.data);
    } catch {
      notifier.error('Error while fetching data');
    } finally {
      setInProgress(false);
    }
  };

  useEffect(() => {
    fetchAuditLogData();
  }, []);

  if (requestInProgress || !data) return <Loader visible />;

  const { timestamp, action_type } = data;

  return (
    <Page
      bread={bread(logId)}
      goBackIcon
      title={`${text.AUDIT_LOG} ${action_type} ${moment(timestamp).format(
        'YYYY-DD-MM [at] HH:mm:ss',
      )}`}
    >
      <div className="fiq-audit-log-single">
        <Col sm="100%" md="100%" lg="70%">
          <div className="fiq-audit-log-single__main">
            <LogDetails data={data} />
            <AuditLogSingleTable data={data} />
          </div>
        </Col>
      </div>
    </Page>
  );
};

export default withRouter(AuditLogSinglePage);
