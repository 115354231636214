import React from 'react';
import { Pill } from 'tc-biq-design-system';
import { string } from 'prop-types';

const propTypes = {
  value: string.isRequired,
};

const USER_TYPES = {
  CREATED: 'status01',
  UPDATED: 'primary',
  READ: 'status03',
  DELETED: 'status04',
};

const ActionTypeCell = ({ value }) => (
  <Pill style={{ textAlign: 'center' }} type={USER_TYPES[value.toUpperCase()]}>
    {value}
  </Pill>
);
ActionTypeCell.propTypes = propTypes;

export default ActionTypeCell;
