import React, { PureComponent, Fragment } from 'react';
import { notifier } from 'tc-biq-design-system';

import GridComponentFactory from 'App/components/grid';
import { openSaveEditFilter, FiltersServiceFactory } from 'App/components/Filters';
import { hasAccess } from 'App/services/permissionsService';
import modifiers from './modifiers';

const text = {
  DELETE: 'Delete',
  CANCEL: 'Cancel',
  DELETE_LABEL: 'Are you sure you want to delete filter?',
  DELETE_ERROR: 'Failed to delete filter',
  DELETE_SUCCESS: 'Successfully deleted filter',
  EDIT_SUCCESS: 'Successfully edited filter',
  EDIT_ERROR: 'Failed to edit filter',
};

const customColumns = [
  {
    key: 'actions',
  },
];

const tableConfig = {
  tableUrl: '/users/saved-filters/',
  tableKey: 'savedFilters',
};

const listToOmit = ['filters'];

const { deleteSavedFilter } = FiltersServiceFactory(tableConfig.tableUrl);

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const singleActions = ({ onEdit, onDelete }) => [
  {
    icon: { name: 'Edit', color: 'text-neutral-900', size: 'small' },
    onClick: onEdit,
    label: 'Edit',
    visibility: hasAccess('users_savedfilter', 'update'),
  },
  {
    icon: { name: 'Delete', color: 'text-neutral-900', size: 'small' },
    onClick: onDelete,
    label: 'Delete',
    visibility: hasAccess('users_savedfilter', 'delete'),
    popconfirm: {
      type: 'destructive',
      placement: 'top',
      label: text.DELETE_LABEL,
      buttonLabels: {
        confirm: text.DELETE,
        cancel: text.CANCEL,
      },
    },
  },
].filter(action => action.visibility);

class SavedFiltesTable extends PureComponent {
  deleteSavedFilter = async (data) => {
    try {
      await deleteSavedFilter(data.id);
      notifier.success(text.DELETE_SUCCESS);
      gridActions.fetchTableData();
    } catch {
      notifier.error(text.DELETE_ERROR);
    }
  };

  editSavedFilter = (data) => {
    openSaveEditFilter({ id: data.id });
  };

  render() {
    return (
      <Fragment>
        <GridComponent
          customColumns={customColumns}
          modifiers={modifiers(
            singleActions({ onEdit: this.editSavedFilter, onDelete: this.deleteSavedFilter }),
          )}
          listToOmit={listToOmit}
        />
      </Fragment>
    );
  }
}

export default SavedFiltesTable;
