import React, { Component } from 'react';
import { Icon } from 'tc-biq-design-system';

import UserData from './UserData';
import EditBasicInfo from './sidepanel/EditBasicInfo';
import './BasicInfo.scss';

const text = {
  BASIC_INFO: 'View more info',
};

class BasicInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  toggleDetails = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  render() {
    const { open } = this.state;

    return (
      <div className="biq-profile__basic-info">
        {open ? <UserData /> : null}

        <div className="biq-profile__basic-info__toggle tc-paragraph-strong">
          <span onClick={this.toggleDetails}>
            {text.BASIC_INFO}

            <span className="biq-profile__basic-info__caret">
              {open ? (
                <Icon name="CaretUp" color="text-primary-500" />
              ) : (
                <Icon name="CaretDown" color="text-primary-500" />
              )}
            </span>
          </span>
        </div>
        <EditBasicInfo />
      </div>
    );
  }
}

export default BasicInfo;
