import { action, observable, computed, runInAction, makeObservable } from 'mobx';
import { find } from 'lodash';
import { notifier } from 'tc-biq-design-system';

import http from 'App/services/http';

import {
  formatFieldsToArray,
  extractCursorQuery,
  mapColumns,
  filterVisibleFields,
  resetUrlQueryParams,
  formatFilterQueries,
} from './gridStoreUtils';

const initialState = {
  data: [],
  optionFields: {},
  columns: [],
  paginationQuery: {
    ordering: '-id',
    limit: 25,
    offset: null,
    cursor: null,
    with_count: true,
  },
  filterQuery: {},
  cursor: {
    prev: null,
    next: null,
  },
};

const text = {
  EXPORT_SUCCESS: 'Data export successfully initiated, check My data exports on User Profile',
  EXPORT_FAILED: 'Failed to initate data export',
};

export default class GridStore {
  constructor(FiltersStore) {
    makeObservable(this, {
      data: observable,
      optionFields: observable,
      columns: observable,
      visibleFields: observable,
      paginationQuery: observable,
      customQuery: observable,
      initialFilterQuery: observable,
      cursor: observable,
      count: observable,
      columnManager: observable,
      setData: action.bound,
      fetchNextPage: action.bound,
      setDirection: action.bound,
      setCursors: action.bound,
      setCount: action.bound,
      resetCursor: action.bound,
      changePageSize: action.bound,
      setOrdering: action.bound,
      setOptionsFields: action.bound,
      addInitialFilters: action.bound,
      setFilterOptions: action.bound,
      addCustomQuery: action.bound,
      resetCustomQuery: action.bound,
      resetTable: action.bound,
      updateFieldVisibility: action.bound,
      toggleAllFieldsVisibility: action.bound,
      setColumnsState: action.bound,
      resetColumnsState: action.bound,
      exportData: action.bound,
      query: computed,
      withCount: computed,
      currentFilters: computed,
    });

    this.filters = FiltersStore;
  }

  data = [];

  optionFields = {};

  columns = [];

  visibleFields = {};

  paginationQuery = {
    ordering: '-id',
    limit: 25,
    offset: null,
    cursor: null,
    with_count: true,
  };

  customQuery = {};

  initialFilterQuery = {};

  cursor = {
    prev: null,
    next: null,
  };

  count = 0;

  columnManager = {
    allFields: [],
    columnsState: [],
  };

  setData(data) {
    this.data = data;
  }

  fetchNextPage(next, prev, limit, ordering) {
    this.pagination = {
      next,
      prev,
      limit,
      ordering,
    };
  }

  setDirection(direction, useOffset) {
    if (direction === 'next') {
      runInAction(() => {
        this.paginationQuery.cursor = this.cursor.next;
        if (useOffset) {
          this.paginationQuery.offset += this.paginationQuery.limit;
          this.paginationQuery.cursor = null;
        }
      });
    }
    if (direction === 'prev') {
      runInAction(() => {
        this.paginationQuery.cursor = this.cursor.prev;
        if (useOffset) {
          this.paginationQuery.offset -= this.paginationQuery.limit;
          this.paginationQuery.cursor = null;
        }
      });
    }
  }

  setCursors(next, prev) {
    this.cursor.next = next ? extractCursorQuery(next) : null;
    this.cursor.prev = prev ? extractCursorQuery(prev) : null;
  }

  setCount(count) {
    this.count = count;
  }

  setWithCount = (withCount) => {
    this.paginationQuery.with_count = withCount;
  }

  resetCursor() {
    this.paginationQuery.cursor = null;
  }

  changePageSize(pageSize) {
    this.paginationQuery.limit = pageSize;
  }

  setOrdering(ordering) {
    this.paginationQuery.ordering = ordering;
    this.paginationQuery.cursor = null;
  }

  mapFiltersFromUrl(filters) {
    this.filters.mapFiltersFromUrl(filters);
  }

  setOptionsFields({
    fields,
    modifiers,
    customColumns,
    setDefaultRenderer,
    filterOptions,
    viewName,
  }) {
    this.filters.updateViewName(viewName);
    this.setFilterOptions(filterOptions);
    this.optionFields = fields;
    this.visibleFields = filterVisibleFields(fields);
    this.filters.updateFields(fields, filterOptions.excluded);
    this.columns = mapColumns(this.visibleFields, modifiers, customColumns, setDefaultRenderer);
    this.columnManager = {
      allFields: formatFieldsToArray(this.optionFields),
      columnsState: formatFieldsToArray(this.visibleFields),
    };
  }

  addInitialFilters(filters) {
    this.initialFilterQuery = filters;
  }

  setFilterOptions(filterOptions) {
    this.filterOptions = { ...filterOptions };
  }

  addCustomQuery(customQuery) {
    this.customQuery = customQuery;
  }

  resetCustomQuery() {
    this.customQuery = {};
  }

  resetTable() {
    this.paginationQuery = { ...initialState.paginationQuery };
    this.data = [...initialState.data];
    this.columns = [...initialState.columns];
    this.visibleFields = { ...initialState.visibleFields };
    this.optionFields = {};
    this.filters.resetFilters();
    resetUrlQueryParams();
  }

  // Column manager methods
  updateFieldVisibility(key) {
    const { allFields } = this.columnManager;
    const checkedField = find(allFields, { key });
    checkedField.visible = !checkedField.visible;
    this.columnManager.columnsState = allFields.filter(field => field.visible);
  }

  toggleAllFieldsVisibility(allVisible) {
    const updatedFields = this.columnManager.allFields.map(field => ({
      ...field,
      visible: allVisible || false,
    }));
    this.columnManager.columnsState = updatedFields.filter(field => field.visible);
    this.columnManager.allFields = updatedFields;
  }

  setColumnsState(columnsState) {
    this.columnManager.columnsState = columnsState;
  }

  resetColumnsState() {
    this.columnManager = {
      allFields: formatFieldsToArray(this.optionFields),
      columnsState: formatFieldsToArray(this.visibleFields),
    };
  }

  async exportData({ path }) {
    const payload = {
      path,
      export_filters: formatFilterQueries(this.filters.query),
      export_fields: Object.keys(this.visibleFields),
    };
    try {
      await http.post('/users/data-exports/', payload);
      notifier.success(text.EXPORT_SUCCESS);
    } catch {
      notifier.error(text.EXPORT_FAILED);
    }
  }

  get withCount() {
    return this.paginationQuery.with_count;
  }

  get currentFilters() {
    return {
      ...this.filters.query,
      ...this.initialFilterQuery,
    };
  }

  // Table query with all parameters
  get query() {
    return {
      ...this.paginationQuery,
      ...this.filters.query,
      ...this.initialFilterQuery,
      ...this.customQuery,
    };
  }
}
