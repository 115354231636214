import React, { PureComponent } from 'react';
import { Modal, Button, Space, notifier } from 'tc-biq-design-system';
import { array, bool, func, string } from 'prop-types';
import arrayMove from 'array-move';
import { inject, observer } from 'mobx-react';

import { closeOverlay } from 'App/services/overlayService';
import ColumnsVisibility from './ColumnsVisibility';
import ColumnBoxView from './ColumnBoxView';

import './ColumnManager.scss';

const MODAL_ID = 'MANAGE_GRID_COLUMNS';

const text = {
  TITLE: 'Manage columns',
  CANCEL: 'Cancel',
  APPLY: 'Apply',
  SELECTED_COLUMNS: 'Selected columns',
};

const propTypes = {
  submit: func.isRequired,
  fetchTableData: func.isRequired,
  visible: bool.isRequired,
  columnsState: array.isRequired,
  allFields: array.isRequired,
  setColumnsState: func.isRequired,
  resetFields: func.isRequired,
  updateFieldVisibility: func.isRequired,
  toggleAllFieldsVisibility: func.isRequired,
  tableId: string.isRequired,
};

const modalFooter = (apply, close) => () => (
  <div>
    <Button onClick={close} color="ghost">
      {text.CANCEL}
    </Button>
    <Button color="primary" onClick={apply}>
      {text.APPLY}
    </Button>
  </div>
);

const ColumnManager = inject(stores => ({
  visible: stores.overlayStore.overlay.MANAGE_GRID_COLUMNS,
  parameters: stores.overlayStore.overlay.parameters,
}))(observer(
  class ColumnManager extends PureComponent {
    onSortChange = ({ oldIndex, newIndex }) => {
      const { setColumnsState, columnsState } = this.props;
      const reorderedFields = arrayMove(columnsState, oldIndex, newIndex);
      setColumnsState(reorderedFields);
    };

    close = () => {
      const { resetFields } = this.props;
      resetFields();
      closeOverlay(MODAL_ID);
    };

    apply = () => {
      const { columnsState, submit, fetchTableData } = this.props;
      const payload = columnsState.filter(field => field.key !== 'id').map(field => field.key);
      submit(payload).then(
        () => {
          fetchTableData();
          this.close();
        },
        () => notifier.error('Failed to save columns changes!'),
      );
    };

    render() {
      const {
        visible,
        allFields,
        columnsState,
        toggleAllFieldsVisibility,
        updateFieldVisibility,
        tableId,
      } = this.props;

      const allVisible = columnsState.length === allFields.length;
      const allHidden = columnsState.length === 0;

      return (
        <Modal
          size="large"
          icon="Upload"
          title={text.TITLE}
          footerRender={modalFooter(this.apply, this.close)}
          visible={visible}
        >
          <div className="biq-modal-body">
            <ColumnsVisibility
              columns={allFields}
              tableId={tableId}
              onToggle={updateFieldVisibility}
            />

            <div className="biq-modal-body__section" style={{ position: 'relative', bottom: '10px' }}>
              <Space size={10} />
              <span style={{ position: 'relative', top: '1px', fontSize: '13px' }}>
                {text.SELECTED_COLUMNS}
                {' '}
                {columnsState.length}
              </span>

              <div className="action-buttons">
                <Button
                  color="transparent"
                  size="small"
                  onClick={() => toggleAllFieldsVisibility(true)}
                  disabled={allVisible}
                >
                  Add all
                </Button>
                <Button
                  color="transparent"
                  size="small"
                  onClick={() => toggleAllFieldsVisibility()}
                  disabled={allHidden}
                >
                  Remove all
                </Button>
              </div>

              <ColumnBoxView
                columns={columnsState}
                toggle={updateFieldVisibility}
                onSortChange={this.onSortChange}
              />
            </div>
          </div>
        </Modal>
      );
    }
  },
));


ColumnManager.wrappedComponent.propTypes = propTypes;

export default ColumnManager;
