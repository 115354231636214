import { action, observable, computed, runInAction, makeObservable } from 'mobx';
import { flatten } from 'lodash';
import { hasAccess } from 'App/services/permissionsService';
import allItems, { Item } from './routes';


/*
 * checkNavigatorAccess function
 * @param items
 * @returns array of navItems that have permission
 * @descritption filters Navigation items per permissions in MainSidebar
 * Conditions:
 * - if navItem has route and permission;
 * - if navItem has subItems and at least one subItem has permission;
 * - if navItem has sections and if at least one section has item with permission;
 */

const checkNavigatorAccess = (items: Item[]) => {
  const filteredItems: Item[] = [];
  items.forEach((item: Item) => {
    if (item.separator) filteredItems.push(item);
    if (item.permission) {
      if (hasAccess(item.permission, 'read')) filteredItems.push(item);
      if (Array.isArray(item.permission)
        && item.permission.some(value => hasAccess(value, 'read'))) filteredItems.push(item);
    } else if (item.subItems) {
      const temp = { ...item };
      temp.subItems = temp.subItems?.filter(subItem => hasAccess(subItem.permission, 'read'));
      if (temp.subItems && temp.subItems.length > 0) filteredItems.push(temp);
    } else if (item.sections) {
      const temp = { ...item };
      temp.sections = temp.sections && temp.sections
        .slice()
        .map(section => section.filter(sectionItem => hasAccess(sectionItem.permission, 'read')));
      if (flatten(temp.sections).length > 0) filteredItems.push(temp);
    }
  });
  return filteredItems;
};

const getHomeRoute = (items: Item[]) => {
  if (items[0].subItems) return items[0].subItems[0].route;
  if (items[0].route) return items[0].route;
  return '/';
};

export interface INavigatorStore {
  allItems: Item[] | [];
  visibleItems: Item[] | [];
  homeRoute: string;
}

export default class NavigatorStore implements INavigatorStore {
  allItems: Item[] = allItems || [];

  visibleItems: Item[] = [];

  homeRoute = '/';

  constructor() {
    makeObservable(this, {
      allItems: observable,
      visibleItems: observable,
      homeRoute: observable,
      filterVisibleItems: action.bound,
      setHomeRoute: action.bound,
      hasNavItems: computed,
    });
  }

  filterVisibleItems() {
    runInAction(() => {
      this.visibleItems = checkNavigatorAccess(this.allItems);
      this.homeRoute = this.hasNavItems ? getHomeRoute(this.visibleItems) : '/no-permission';
    });
  }

  setHomeRoute(route: string) {
    runInAction(() => {
      this.homeRoute = route;
    });
  }

  get hasNavItems() {
    return !!this.visibleItems.length;
  }
}
