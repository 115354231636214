import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { object, bool, func } from 'prop-types';
import { inject, observer } from 'mobx-react';

import Page from 'App/components/Page';
import Loader from 'App/components/Loader';
import WebhookForm from 'Settings/Sections/Webhooks/components/WebhookForm';
import PreviewParameters from 'Settings/Sections/Webhooks/components/overlays/PreviewParameters';

import './WebhookUpdatePage.scss';

const propTypes = {
  match: object.isRequired,
  loadingFieldsDef: bool.isRequired,
  fetchFieldsDef: func.isRequired,
  fetchWebhookData: func.isRequired,
  loadingData: bool.isRequired,
};

const text = {
  CREATE_TITLE: 'Create a webhook',
  EDIT_TITILE: 'Edit a webhook',
};

const WebhookUpdatePage = ({
  match, loadingFieldsDef, fetchFieldsDef, fetchWebhookData, loadingData,
}) => {
  const { webhookId } = match.params;
  const title = webhookId ? text.EDIT_TITILE : text.CREATE_TITLE;
  const fetchFields = async () => {
    await fetchFieldsDef();
  };
  const fetchData = async () => {
    await fetchWebhookData(webhookId);
  };

  useEffect(() => {
    if (!loadingFieldsDef) fetchFields();
  }, []);

  useEffect(() => {
    if (webhookId) fetchData();
  }, [webhookId]);

  return (
    <Page
      title={title}
      goBackIcon={null}
      style={{ minHeight: 'calc(100vh - 142px', overflow: 'visible' }}
    >
      <div className="fiq-webhook-page">
        <Loader visible={loadingFieldsDef || loadingData} />
        {!(loadingFieldsDef || loadingData) && <WebhookForm />}
      </div>
      <PreviewParameters />
    </Page>
  );
};

WebhookUpdatePage.propTypes = propTypes;

export default inject(stores => ({
  fetchFieldsDef: stores.webhooks.fetchFieldsDef,
  loadingFieldsDef: stores.webhooks.requestInProgress.fieldsDef,
  loadingData: stores.webhooks.requestInProgress.fetchWebhook,
  fetchWebhookData: stores.webhooks.fetchWebhookData,
}))(observer(withRouter(WebhookUpdatePage)));
