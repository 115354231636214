import { action, runInAction, observable, makeObservable } from 'mobx';

import { fetchContactMifidData } from 'Contacts/services/contactsService';

export default class MifidStore {
  mifidData = [];

  requestInProgress = false;

  errors = null;

  constructor() {
    makeObservable(this, {
      mifidData: observable,
      requestInProgress: observable,
      errors: observable,
      fetchMifidData: action.bound,
    });
  }

  async fetchMifidData(id) {
    this.requestInProgress = true;
    try {
      const response = await fetchContactMifidData(id);
      runInAction(() => {
        this.mifidData = response.data.results;
        this.requestInProgress = false;
      });
    } catch (err) {
      runInAction(() => {
        this.requestInProgress = false;
        this.errors = err.data;
      });
    }
  }
}
