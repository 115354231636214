import http from 'App/services/http';

const fetchTradeData = id => http.get(`/finances/trade-transactions/${id}/`);

const fetchPaymentData = id => http.get(`/finances/payment-transactions/${id}/`);

const fetchOrderData = id => http.get(`/finances/trade-order/${id}/`);

const fetchWithdrawalData = id => http.get(`/finances/withdraw-requests/${id}/`);

const fetchWalletData = id => http.get(`/finances/wallets/${id}/`);

const fetchLedgerData = (id, query) => http.get(`/finances/wallets/${id}/ledger/`, { params: query });

const fetchWalletBalance = id => http.get(`/finances/wallets/${id}/balance/`);

const fetchPositionData = id => http.get(`/finances/trade-positions/${id}/`);

const fetchCardData = id => http.get(`/finances/cards/${id}/`);

export {
  fetchTradeData,
  fetchPaymentData,
  fetchWalletData,
  fetchLedgerData,
  fetchWalletBalance,
  fetchOrderData,
  fetchWithdrawalData,
  fetchPositionData,
  fetchCardData,
};
