import { observable, action, computed, runInAction, makeObservable } from 'mobx';

import stores from 'App/rootStore';
import { fetchUserPermissions } from 'App/services/permissionsService';

export interface Permission {
  create: boolean;
  delete: boolean;
  export: boolean;
  object_type: string;
  read: boolean;
  update: boolean;
}

export interface InProgress {
  [key: string]: boolean;
}

export interface IPermissionsStore {
  userPermissions: Permission[] | [];
  requestInProgress: InProgress
}

export default class PermissionsStore implements IPermissionsStore {
  userPermissions: Permission[] = [];

  requestInProgress = {
    fetchPermissions: false,
  };

  constructor() {
    makeObservable(this, {
      userPermissions: observable,
      requestInProgress: observable,
      setUserPermissions: action.bound,
      fetchUserPermissions: action.bound,
      permissionsLoaded: computed,
    });
  }

  setUserPermissions(permissions: Permission[]) {
    this.userPermissions = permissions;
  }

  async fetchUserPermissions(onError: () => void) {
    this.requestInProgress.fetchPermissions = true;
    try {
      const response = await fetchUserPermissions();
      runInAction(() => {
        this.setUserPermissions(response.data);
        stores.navigator.filterVisibleItems();
      });
    } catch (e) {
      onError();
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchPermissions = false;
      });
    }
  }

  get permissionsLoaded() {
    return this.userPermissions.length > 0;
  }
}
