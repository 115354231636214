import React from 'react';
import { withI18n } from '@lingui/react';
import { t } from '@lingui/macro';
import no_permissions from 'assets/images/no-permissions.svg';

import { NoContentPage } from 'App/components/NoContentPage';

const text = {
  BODY: i18n => i18n._(t`You don't have permission to access this page`),
  BODY_FULL_SCREEN: i18n => i18n._(t`You don't have any permissions. Please contact your Admin`),
  TITLE: i18n => i18n._(t`No permission.`),
};

// eslint-disable-next-line react/prop-types
const NoPermissionPage = ({ i18n, fullScreen }) => (
  <NoContentPage
    fullScreen={fullScreen}
    image={<img src={no_permissions} alt="No Permissions" />}
    title={text.TITLE(i18n)}
    body={fullScreen ? text.BODY_FULL_SCREEN(i18n) : text.BODY(i18n)}
  />
);

export default withI18n()(NoPermissionPage);
