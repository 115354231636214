import React from 'react';
import { Icon, Select } from 'tc-biq-design-system';
import classNames from 'classnames';
import { func, object, array, number, string, bool } from 'prop-types';

import './CursorPagination.scss';

import { isPaginationDisabled } from './gridStoreUtils';

const text = {
  TOTAL_COUNT: 'Total',
};

const propTypes = {
  onNext: func.isRequired,
  onPrev: func.isRequired,
  cursor: object.isRequired,
  onPageSizeChange: func.isRequired,
  sizeOptions: array.isRequired,
  pageSize: number.isRequired,
  pageSizerOpeningDirection: string,
  useOffset: bool,
  offset: number,
  count: number,
};

const defaultProps = {
  pageSizerOpeningDirection: 'up',
  useOffset: false,
  offset: null,
  count: 0,
};

const CursorPagination = ({
  onNext,
  onPrev,
  onPageSizeChange,
  cursor,
  sizeOptions,
  pageSizerOpeningDirection,
  pageSize,
  useOffset,
  offset,
  count,
}) => {
  const pageSizeOptions = sizeOptions?.map(option => ({ id: option, label: option }));
  const onChange = option => onPageSizeChange(option.id);
  const value = pageSize ? { id: pageSize, label: pageSize } : pageSizeOptions[0];
  const isPrevPaginationDisabled = isPaginationDisabled(useOffset, offset, cursor, 'prev');
  const isNextPaginationDisabled = isPaginationDisabled(useOffset, offset, cursor, 'next', count);
  return (
    <div className="fiq-cursor-pagination">
      <div className="fiq-cursor-pagination__count">
        {text.TOTAL_COUNT}: {count}
      </div>
      <Select
        arrowRenderer={() => (
          <div className="fiq-cursor-pagination__select-arrow">
            <Icon name="CaretDown" size="extraSmall" />
          </div>
        )}
        type="single"
        openingDirection={pageSizerOpeningDirection}
        options={pageSizeOptions}
        value={value}
        clearable={false}
        onChange={onChange}
      />
      <div
        onClick={onPrev}
        className={classNames('fiq-cursor-pagination__prev', { disabled: isPrevPaginationDisabled })}
      >
        <Icon
          colorName={!isPrevPaginationDisabled ? 'text-neutral-50' : 'text-neutral-900'}
          name="CaretLeft"
          size="extraSmall"
        />
      </div>
      <div
        onClick={onNext}
        className={classNames('fiq-cursor-pagination__next', { disabled: isNextPaginationDisabled })}
      >
        <Icon
          colorName={!isNextPaginationDisabled ? 'text-neutral-50' : 'text-neutral-900'}
          name="CaretRight"
          size="extraSmall"
        />
      </div>
    </div>
  );
};

CursorPagination.propTypes = propTypes;
CursorPagination.defaultProps = defaultProps;

export default CursorPagination;
