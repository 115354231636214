import http from 'App/services/http';
import viewNameEnum from 'App/enums/viewNameEnum';

export default function (apiUrl) {
  const fetchSavedFilters = ({ userId, viewName, search }) => http.get(`/users/${userId}/filters/`, { params: { filter__view_name: viewName, search } });

  const updateSavedFilter = ({ userId, payload, filterId }) => http.patch(`/users/${userId}/filters/${filterId}/`, { ...payload, path: `/api${apiUrl}` });

  const fetchSavedFilter = (id, viewName) => http.get(`/users/saved-filters/${id}/`, { params: { filter__view_name: viewName } });

  const fetchSavedFilterOptions = () => http.options('/users/saved-filters/');

  const editSavedFilter = (id, payload) => http.put(`/users/saved-filters/${id}/`, {
    ...payload,
    path: payload.view_name ? `${viewNameEnum[payload.view_name]}` : `/api${apiUrl}`,
  });

  const deleteSavedFilterBinding = (userId, filterId) => http.delete(`/users/${userId}/filters/${filterId}/`);

  const deleteSavedFilter = id => http.delete(`/users/saved-filters/${id}/`);

  const saveFilters = payload => http.post('/users/saved-filters/', { ...payload, path: `/api${apiUrl}` });

  const saveFilter = (payload, filterId) => http.put(`/users/saved-filters/${filterId}/`, { ...payload, path: `/api${apiUrl}` });

  return {
    fetchSavedFilters,
    fetchSavedFilter,
    fetchSavedFilterOptions,
    editSavedFilter,
    saveFilters,
    saveFilter,
    updateSavedFilter,
    deleteSavedFilterBinding,
    deleteSavedFilter,
  };
}
