/* eslint-disable no-undef */
import { init as initCommandbar } from 'commandbar';

const init = () => {
  if (!COMMANDBAR_KEY) return;
  initCommandbar(COMMANDBAR_KEY);
};

const assignUser = (user) => {
  if (!user?.id) return;
  if (!window.CommandBar) return;

  window.CommandBar.boot(user.id);
};

const addContext = (...context) => {
  if (!window.CommandBar) return;
  window.CommandBar.addContext(...context);
};

const addRouter = (func) => {
  if (!window.CommandBar) return;
  window.CommandBar.addRouter(func);
};

const addCallback = (...args) => {
  if (!window.CommandBar) return;
  window.CommandBar.addCallback(...args);
};

const removeCallback = (key) => {
  if (!window.CommandBar) return;
  window.CommandBar.removeCallback(key);
};

const addMenuCommands = (menu = []) => {
  if (!window.CommandBar) return;

  const addCommands = (items, parent = null) => {
    items.forEach((item) => {
      const text = parent ? `${parent.label} -> ${item.label}` : item.label;
      if (item.route) {
        window.CommandBar.addCommand({
          text: `Go to ${text}`,
          name: item.route,
          template: {
            type: 'link',
            value: item.route,
            operation: 'router',
          },
        });
      }


      if (item.subItems) addCommands(item.subItems, item);
    });
  };

  addCommands(menu);
};

export {
  init,
  assignUser,
  addContext,
  addRouter,
  addCallback,
  removeCallback,
  addMenuCommands,
};
