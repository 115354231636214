import { observable, action, runInAction, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';

import {
  fetchContactDocuments,
  fetchContactDocumentsOptions,
  approveDocument,
  rejectDocument,
  deleteDocument,
  uploadDocument,
} from 'Contacts/components/Sections/Documents/documentsService';
import stores from 'App/rootStore';
import { parseParamString } from 'App/components/Filters/filterStoreUtils';

const text = {
  DELETE_SUCCESS: 'Document deleted successfully!',
  DELETE_FAILURE: 'Failed to delete document',
  APPROVE: 'Document approved successfully.',
  REJECT: 'Document rejected successfully',
};

export default class DocumentsStore {
  data = [];

  fieldDefs = {};

  hasMore = false;

  query = {
    cursor: '',
  };

  requestInProgress = {
    data: false,
    review: false,
    delete: false,
    upload: false,
  };

  constructor() {
    makeObservable(this, {
      data: observable,
      fieldDefs: observable,
      hasMore: observable,
      query: observable,
      requestInProgress: observable,
      fetchContactDocuments: action.bound,
      fetchDocumentsOptions: action.bound,
      uploadDocument: action.bound,
      reviewDocument: action.bound,
      deleteDocument: action.bound,
    });
  }

  async fetchContactDocuments(isScrollFetch) {
    const { id } = stores.contact.profile.contactData;
    this.requestInProgress.data = true;
    try {
      const response = await fetchContactDocuments(id, this.query);
      const { next, results } = response.data;
      const params = parseParamString(next);
      runInAction(() => {
        this.hasMore = !!next;
        this.query.cursor = params.cursor;
        this.data = isScrollFetch ? [...this.data, ...results] : results;
        this.requestInProgress.data = false;
      });
    } catch (e) {
      runInAction(() => {
        this.requestInProgress.data = false;
        notifier.error(e);
      });
    }
  }

  async fetchDocumentsOptions() {
    const { id } = stores.contact.profile.contactData;
    const response = await fetchContactDocumentsOptions(id);
    runInAction(() => {
      this.fieldDefs = response.data.actions.POST;
    });
  }

  async uploadDocument() {
    const { id } = stores.contact.profile.contactData;
    this.requestInProgress.upload = true;
    const { data, setFieldsErrors } = stores.forms.uploadDocForm;
    try {
      await uploadDocument(id, data);
      runInAction(() => {
        this.requestInProgress.upload = false;
      });
    } catch (e) {
      setFieldsErrors(e.response.data);
      runInAction(() => {
        this.requestInProgress.upload = false;
      });
    }
  }

  async reviewDocument(docId, type) {
    const { id } = stores.contact.profile.contactData;
    const { data, setFieldsErrors } = stores.forms.rejectDocForm;
    this.requestInProgress.review = true;
    try {
      if (type === 'approve') {
        await approveDocument(id, docId);
        notifier.success(text.APPROVE);
      } else {
        await rejectDocument(id, docId, data.reject_reason);
        notifier.errors(text.REJECT);
      }
      runInAction(() => {
        this.requestInProgress.review = false;
      });
    } catch (e) {
      if (e.response) setFieldsErrors(e.response.data);
      runInAction(() => {
        this.requestInProgress.review = false;
      });
    }
  }

  async deleteDocument(docId) {
    const { id } = stores.contact.profile.contactData;
    this.requestInProgress.delete = true;
    try {
      await deleteDocument(id, docId);
      runInAction(() => {
        this.requestInProgress.delete = false;
        notifier.success(text.DELETE_SUCCESS);
      });
    } catch (e) {
      runInAction(() => {
        this.requestInProgress.delete = false;
        notifier.error(text.DELETE_FAILURE);
      });
    }
  }
}
