import React from 'react';
import { inject, observer } from 'mobx-react';
import { string, array } from 'prop-types';
import { DatePickerRange, Icon, Tooltip } from 'tc-biq-design-system';
import WithErrorBoundary from 'App/components/hoc/withErrorBoundary';
import Page from 'App/components/Page';
import DashboardGrid from 'Dashboard/components/DashboardGrid';

const DASHBOARDS = {
  FINANCE: 'Finance',
};

const HeaderActions = inject(stores => ({
  setDashboardInterval: stores.dashboardStore.setDashboardInterval,
  dashboardInterval: stores.dashboardStore.dashboardInterval,
}))(
  observer(
    ({ setDashboardInterval, dashboardInterval }) => (
      <DatePickerRange
        onChange={e => setDashboardInterval(e)}
        startDate={dashboardInterval.startDate}
        endDate={dashboardInterval.endDate}
        predefinedRange
        startDateModifiers={{
          popperModifiers: {
            offset: {
              enabled: true,
            },
          },
        }}
        endDateModifiers={{
          popperModifiers: {
            offset: {
              enabled: true,
            },
          },
        }}
      />
    ),
  ),
);

const propTypes = {
  title: string,
  tooltip: string,
  widgets: array,
  layout: array,
};

const defaultProps = {
  title: '',
  tooltip: '',
  widgets: null,
  layout: [],
};

const DashboardPage = ({ title, tooltip, widgets, layout }) => (
  <Page
    title={title}
    goBackIcon={false}
    showDatePicker
    hideHeader
  >
    <div className="biq-header">
      <div className="page-info">
        <span className="title">
          {title}
          <Tooltip title={tooltip}>
            <Icon colorName="text-neutral-500" style={{ marginLeft: '17px' }} name="Warning" />
          </Tooltip>
        </span>
      </div>
      <div className="actions">
        <HeaderActions />
      </div>
    </div>
    <div style={{ padding: '0 20px' }}>
      <DashboardGrid widgets={widgets} layout={layout} isFinance={title === DASHBOARDS.FINANCE} />
    </div>
  </Page>
);

DashboardPage.propTypes = propTypes;
DashboardPage.defaultProps = defaultProps;

export default WithErrorBoundary(DashboardPage);
