import React from 'react';
import {
  AmountCell,
  RenderIconNameCell,
  RouteCell,
  SideCell,
  WalletCell,
} from 'App/components/gridCellRenderers';
import hideCols from 'App/services/utilities/hideCols';

const modifiers = {
  ...hideCols(['state']),
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/transactions/positions/'),
  },
  side: {
    cellRendererFramework: SideCell,
  },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => <WalletCell data={value} />,
  },
  asset: {
    cellRendererFramework: RenderIconNameCell({ type: 'asset' }),
  },
  amount: {
    cellRendererFramework: AmountCell,
  },
};

export default modifiers;
