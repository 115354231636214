import React from 'react';
import { DashboardCard } from 'tc-biq-design-system';
import { string, func, bool, array } from 'prop-types';

import NoData from 'App/components/NoData/NoData';
import { ContactCell } from 'App/components/gridCellRenderers';

import currencyFormatter from 'App/services/utilities/currencyFormatter';

const propTypes = {
  title: string.isRequired,
  description: string,
  loading: bool,
  onClick: func,
  data: array,
};

const defaultProps = {
  description: '',
  loading: false,
  data: [],
  onClick: null,
};

const List = ({ title, data, description, loading, onClick }) => {
  if (!data.length) return <NoData />;
  return (
    <DashboardCard
      title={title}
      description={description}
      loading={loading}
      onClick={onClick}
    >
      <div style={{ padding: '10px 16px' }}>
        {data.map(({ contact, value }) => (
          <div key={contact.id} style={{ display: 'flex', alignItems: 'center' }}>
            <ContactCell
              id={contact.id}
              fullName={`${contact.first_name} ${contact.last_name}`}
              avatar={contact.avatar}
            />
            <span>{currencyFormatter(value, 'USD')}</span>
          </div>
        ))}
      </div>
    </DashboardCard>
  );
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;
