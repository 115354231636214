import React from 'react';
import { HyperLink, Icon } from 'tc-biq-design-system';
import { Link } from 'react-router-dom';

import './RouteCell.scss';
// eslint-disable-next-line
const RouteCell = href => ({ data, value, hrefValue, onPreview }) => {
  return (
    <div className="fiq-route-cell">
      <HyperLink>
        <Link to={`${href}${hrefValue || value}`}>{value}</Link>
      </HyperLink>
      {onPreview && <Icon name="View" colorName="text-primary-500" onClick={() => onPreview(data)} />}
    </div>
  );
};

export default RouteCell;
