import SendEmail from './SendEmail';
import actionTypes from '../types';

const text = {
  LABEL: 'Send an email',
};

const ACTION_ICON = 'Mail';

export default {
  name: 'sendEmail',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.TOP,
  handler: (comboxManager) => {
    const comboxOptions = {
      title: text.LABEL,
      id: 'send-email-combox',
    };

    comboxManager.add(SendEmail, comboxOptions);
  },
};
