import React, { Fragment } from 'react';
import GridComponentFactory from 'App/components/grid';
import { hasAccess } from 'App/services/permissionsService';
import DeleteTemplate, { openDeleteTemplate } from 'Communications/components/overlays/DeleteTemplate';
import modifiers from './modifiers';

const tableConfig = {
  tableKey: 'templates',
  tableUrl: '/communications/templates/',
};

const text = {
  EDIT: 'Edit',
  CLONE: 'Clone',
  START: 'Start',
  DELETE: 'Delete template',
  DELETE_SUCCESS: 'Successfully deleted template',
  DELETE_FAILED: 'Field to delete template',
};

const customColumns = [
  {
    key: 'actions',
    headerName: '',
  },
];

const singleActions = () => (data, history) => [
  {
    data: data.id,
    label: text.EDIT,
    action: id => history.push(`/communications/templates/${id}`),
    icon: 'Edit',
    visibility: hasAccess('communications_template', 'update'),
  },
  {
    data: data.id,
    label: text.CLONE,
    action: id => history.push(`/communications/templates/${id}/clone`),
    icon: 'Duplicate',
    visibility: hasAccess('communications_template', 'create'),
  },
  {
    data: { templateId: data.id, templateName: data.name },
    label: text.DELETE,
    action: templateInfo => openDeleteTemplate(templateInfo),
    icon: 'Delete',
    visibility: hasAccess('communications_template', 'delete'),
  },

].filter(action => action.visibility);

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const TemplatesTable = () => (
  <Fragment>
    <GridComponent
      customColumns={customColumns}
      modifiers={modifiers(singleActions())}
      hideColumnManager
      defaultFilters={{ is_shadow_copy: false }}
    />
    <DeleteTemplate onSuccess={gridActions.fetchTableData} />
  </Fragment>

);

export default TemplatesTable;
