import React, { PureComponent } from 'react';
import { func, object, bool } from 'prop-types';
import { ComboxFooter } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import Field from 'App/components/FieldRenderer';

const propTypes = {
  closeCombox: func,
  errors: object,
  sendSms: func,
  requestInProgress: bool,
  data: object,
};

const defaultProps = {
  closeCombox: null,
  errors: null,
  sendSms: null,
  requestInProgress: false,
  data: {},
};

const text = {
  CONFIRM_TEXT: 'Send',
};

const MessageBodyField = () => (
  <Field
    id="message_body"
    name="message_body"
    type="textarea"
    formId="sendSmsForm"
    className="tc-paragraph-regular"
    rows={10}
  />
);

const ObserverComboxFooter = inject(stores => ({
  errors: stores.contact.profile.errors,
  sendSms: stores.contact.profile.sendSms,
  requestInProgress: stores.contact.profile.requestInProgress.sendSms,
  data: toJS(stores.forms.sendSmsForm.data),
}))(observer(
  class ObserverComboxFooter extends PureComponent {
    onSuccess = async () => {
      const { sendSms, closeCombox, errors } = this.props;
      await sendSms();
      if (closeCombox && !errors.sendSms) closeCombox();
    };

    render() {
      const { requestInProgress, data } = this.props;
      const isDisabled = !data.message_body;

      return (
        <ComboxFooter
          onConfirm={this.onSuccess}
          isLoading={requestInProgress}
          confirmText={text.CONFIRM_TEXT}
          isDisabled={isDisabled}
        />
      );
    }
  },
));

ObserverComboxFooter.wrappedComponent.propTypes = propTypes;
ObserverComboxFooter.wrappedComponent.defaultProps = defaultProps;

const SendSms = inject(stores => ({
  resetFields: stores.forms.sendSmsForm.resetFieldsData,
}))(observer(
  class SendSms extends PureComponent {
    componentDidMount() {
      const { resetFields } = this.props;
      resetFields();
    }

    render() {
      const { closeCombox } = this.props;
      return (
        <div>
          <MessageBodyField />
          <ObserverComboxFooter closeCombox={closeCombox} />
        </div>
      );
    }
  },
));


SendSms.wrappedComponent.propTypes = {
  resetFields: func.isRequired,
  closeCombox: func.isRequired,
};


export default SendSms;
