/* eslint-disable react/prop-types */
import React from 'react';
import { Pill } from 'tc-biq-design-system';
import hideCols from 'App/services/utilities/hideCols';
import moment from 'moment';

import { ContactCell, StatusCell, YesNoCell, DateTimeCell } from 'App/components/gridCellRenderers';
import NoDataDash from 'App/components/NoDataDash';

const headerNames = {
  REPORTED_AS_SPAM: 'Reported as spam',
  GOAL_COMPLETED: 'Goals completed',
  UNSUBSCRIBED: 'Unsubscribed',
  LATEST_DELIVERY_TIME: 'Latest Delivery Time',
};

const SuccessChannelStatuses = ['Delivered', 'Successful'];

const PillCell = ({ value }) => <Pill type="neutral">{value || 0}</Pill>;

const modifiers = {
  ...hideCols(['id', 'is_goal_fulfilled', 'channel_logs']),
  contact: {
    cellRendererFramework: ({ value }) => (value ? (
      <ContactCell
        id={value.id}
        avatar={value.avatar}
        fullName={`${value.first_name} ${value.last_name}`}
      />
    ) : (
      <NoDataDash />
    )),
  },
  status: {
    cellRendererFramework: ({ data }) => {
      const values = data.channel_logs.reduce((acc, c) => {
        acc[c.status] = c.status in acc ? acc[c.status] + 1 : 1;
        return acc;
      }, {});
      return Object.keys(values).reduce((acc, status) => {
        acc.push(<StatusCell value={`${values[status]} ${status}`} />);
        return acc;
      }, []);
    },
  },
  latest_delivery_time: {
    cellRendererFramework: ({ data }) => {
      const time = data.channel_logs
        .filter(c => SuccessChannelStatuses.includes(c.status))
        .reduce(
          (acc, c) => (acc
            ? acc > moment(c.created, moment.ISO_8061) || acc
            : moment(c.created, moment.ISO_8061)),
          null,
        );
      return <DateTimeCell value={time} />;
    },
    headerName: headerNames.LATEST_DELIVERY_TIME,
  },
  opened: {
    cellRendererFramework: ({ data }) => (
      <PillCell value={data.channel_logs.reduce((acc, c) => acc + c.opened, 0)} />
    ),
  },
  clicked: {
    cellRendererFramework: ({ data }) => (
      <PillCell value={data.channel_logs.reduce((acc, c) => acc + c.clicked, 0)} />
    ),
  },
  reported_as_spam: {
    cellRendererFramework: ({ data }) => (
      <YesNoCell value={data.channel_logs.some(c => c.reported_as_spam)} />
    ),
    headerName: headerNames.REPORTED_AS_SPAM,
  },
  unsubscribed: {
    cellRendererFramework: ({ data }) => (
      <YesNoCell value={data.channel_logs.some(c => c.unsubscribed)} />
    ),
    headerName: headerNames.UNSUBSCRIBED,
  },
  goal_completed: {
    cellRendererFramework: ({ data }) => <YesNoCell value={data.is_goal_fulfilled} />,
    headerName: headerNames.GOAL_COMPLETED,
  },
};

export default modifiers;
