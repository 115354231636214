import React from 'react';
import { object, func, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Numeric from 'Dashboard/components/layouts/Numeric';

const text = {
  TITLE: 'New contacts',
  DESCRIPTION: 'Total number of new (non-lead) contacts in selected time period',
};

const propTypes = {
  data: object,
  fetchMetricsDataInProgress: bool,
  addNewFilter: func,
  history: object.isRequired,
  dashboardInterval: object.isRequired,
};

const defaultProps = {
  data: {},
  fetchMetricsDataInProgress: false,
  addNewFilter: null,
};

const filters = (startDate, endDate) => [
  {
    field: { label: 'Created', value: 'created', type: 'datetime' },
    ...(startDate === endDate
      ? { operator: { label: 'on', value: 'date', related_models: null } }
      : { operator: { label: 'between', value: 'range', related_models: null } }),
    ...(startDate === endDate
      ? { value: startDate }
      : { value: [`${startDate}T00:00:00Z`, `${endDate}T23:59:59Z`] }),
  },
  {
    field: { label: 'Status', value: 'status', type: 'choice' },
    operator: { label: 'is not equal to', value: 'exact!', related_models: null },
    value: { value: 'Lead', display_name: 'Lead' },
  },
];

const NewContactsNumeric = ({
  data, fetchMetricsDataInProgress, addNewFilter, history, dashboardInterval,
}) => {
  const { value, percentage, isGrowing } = data;
  const onClick = () => {
    const { startDate, endDate } = dashboardInterval;
    filters(startDate, endDate).forEach(addNewFilter);
    history.push('/contacts');
  };
  return (
    <Numeric
      title={text.TITLE}
      description={text.DESCRIPTION}
      value={value}
      percentage={percentage}
      isGrowing={isGrowing}
      loading={fetchMetricsDataInProgress}
      onClick={onClick}
      isCount
    />
  );
};

NewContactsNumeric.propTypes = propTypes;
NewContactsNumeric.defaultProps = defaultProps;

export default {
  component: inject(stores => ({
    data: stores.dashboardStore.contactsData.newContactsNumericData,
    fetchMetricsDataInProgress: stores.dashboardStore.fetchMetricsDataInProgress,
    addNewFilter: stores.tables.contacts.filters.addNewFilter,
    dashboardInterval: stores.dashboardStore.dashboardInterval,
  }))(observer(withRouter(NewContactsNumeric))),
};
