import React from 'react';
import { bool, object } from 'prop-types';
import { Sidepanel } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { RouteCell, StatusCell } from 'App/components/gridCellRenderers';

import './CampaignsSidepanel.scss';

const SIDEPANEL_ID = 'CAMPAIGNS_SIDEPANEL';

const text = {
  SIDEPANEL_TITLE: 'Campaigns',
};

export const openCampaignsSidepanel = (campaigns) => {
  openOverlay(SIDEPANEL_ID, { campaigns });
};

export const closeCampaignsSidepanel = () => {
  closeOverlay(SIDEPANEL_ID);
};

const CampaignNameCell = ({ data, value }) => RouteCell('/marketing/campaigns-overview/')({ hrefValue: data.id, value });

// eslint-disable-next-line react/prop-types
const CampaignsTable = ({ campaigns }) => (
  <div className="info-sidepanel__table">
    {campaigns.map(item => (
      <div key={item.id} className="info-sidepanel__table__item">
        <CampaignNameCell data={item} value={item.name} />
        <StatusCell value={item.status} />
      </div>
    ))}
  </div>
);


const CampaignsSidepanel = ({
  visible,
  parameters,
}) => {
  if (!visible) return null;
  return (
    <Sidepanel
      visible={visible}
      hideFooter
      title={text.SIDEPANEL_TITLE}
      onCloseIconClick={closeCampaignsSidepanel}
      icon="Marketing"
    >
      <CampaignsTable campaigns={parameters.campaigns} />
    </Sidepanel>
  );
};

CampaignsSidepanel.propTypes = {
  visible: bool,
  parameters: object,
};

CampaignsSidepanel.defaultProps = {
  visible: false,
  parameters: {},
};

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
}))(observer(CampaignsSidepanel));
