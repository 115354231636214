/* eslint-disable react/prop-types */
import React from 'react';
import { inject, observer } from 'mobx-react';

import ConditionGroup from 'App/components/ConditionGroup';
import ConditionRuleFactory from 'App/components/QueryBuilderFactory/ConditionRuleFactory';
import ConditionEventFactory from 'App/components/QueryBuilderFactory/ConditionEventFactory';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import './QueryBuilder.scss';

const QueryBulderFactory = ({
  storeKey,
  isEventBased,
  storeFieldKey = 'queryBuilder',
  initialConditionPlaceholder,
}) => {
  const { ConditionRule } = ConditionRuleFactory(storeKey, storeFieldKey, isEventBased);
  const { ConditionEvent } = ConditionEventFactory(storeKey, storeFieldKey);
  const renderRule = (rule, identifier, isLastSibling) => {
    if (isEventBased) {
      return rule.is_attribute ? (
        <ConditionRule rule={rule} parentIdentifier={identifier} />
      ) : (
        <ConditionEvent rule={rule} parentIdentifier={identifier} isLastSibling={isLastSibling} />
      );
    }
    return <ConditionRule rule={rule} parentIdentifier={identifier} />;
  };

  const QueryBuilder = inject(stores => ({
    rules: stores[storeKey][storeFieldKey].queries.rules,
    condition: stores[storeKey][storeFieldKey].queries.condition,
    addCondition: stores[storeKey][storeFieldKey].addCondition,
    removeCondition: stores[storeKey][storeFieldKey].removeCondition,
    updateCondition: stores[storeKey][storeFieldKey].updateCondition,
  }))(
    observer(
      ({
        rules,
        condition,
        fields,
        addCondition,
        removeCondition,
        updateCondition,
        style,
        defaultConditionValue,
        liteConditionHeader,
      }) => (
        <div className="fiq-query-builder" style={style}>
          <ConditionGroup
            fields={fields}
            initial
            initialPlaceholder={initialConditionPlaceholder}
            rules={rules}
            condition={condition}
            addCondition={addCondition}
            removeCondition={removeCondition}
            updateCondition={updateCondition}
            isEventBased={isEventBased}
            defaultConditionValue={defaultConditionValue}
            liteConditionHeader={liteConditionHeader}
          >
            {renderRule}
          </ConditionGroup>
        </div>
      ),
    ),
  );

  return {
    QueryBuilder: withErrorBoundary(QueryBuilder),
  };
};

export default QueryBulderFactory;
