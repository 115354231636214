import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { object, func, oneOfType, number, string, bool } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import FiqAccordion from 'App/components/FiqAccordion';
import Loader from 'App/components/Loader';
import NoData from 'App/components/NoData';
import If from 'App/components/If';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import './SignupDetails.scss';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
  fetchSignupData: func.isRequired,
  signupData: object.isRequired,
  requestInProgress: bool.isRequired,
};

const text = {
  QUESTION: 'question',
  ANSWER: 'answer',
};

const renderValue = (value) => {
  if (typeof value !== 'boolean') return value;

  return value ? 'True' : 'False';
};

const renderDetailsSection = (data) => {
  const keys = Object.keys(data);
  const keysToOmit = ['answer', 'question'];
  const includeQuestionAndAnswer = keys.some(item => keysToOmit.includes(item));
  const filteredKeys = keys.filter(dataKey => !keysToOmit.includes(dataKey));

  if (includeQuestionAndAnswer) {
    return (
      <Fragment>
        <div className="content" key="question">
          <p className="field">{text.QUESTION}</p>
          <p className="answer">{data.question}</p>
        </div>
        <div className="content" key="answer">
          <p className="field">{text.ANSWER}</p>
          <p className="answer">{data.answer}</p>
        </div>
        {
          filteredKeys.map(key => (
            <div className="content" key={key}>
              <p className="field">{key}</p>
              <p className="answer">{renderValue(data[key])}</p>
            </div>
          ))
        }
      </Fragment>
    );
  }

  return keys.map(key => (
    <div className="content" key={key}>
      <p className="field">{key}</p>
      <p className="answer">{renderValue(data[key])}</p>
    </div>
  ));
};

const renderSignupData = (data) => {
  const dataKeys = Object.keys(data);
  return dataKeys.map(key => (
    <div className="accordion-section" key={key}>
      <FiqAccordion title={key}>{renderDetailsSection(data[key])}</FiqAccordion>
    </div>
  ));
};


const SignupDetails = inject(stores => ({
  fetchSignupData: stores.contact.signupDetails.fetchSignupData,
  signupData: stores.contact.signupDetails.signupData,
  requestInProgress: stores.contact.signupDetails.requestInProgress,
}))(observer(
  class SignupDetails extends Component {
    componentDidMount() {
      this.fetchSignupInfo();
    }

    fetchSignupInfo = async () => {
      const { fetchSignupData, id } = this.props;
      await fetchSignupData(id);
    };

    render() {
      const { signupData, requestInProgress } = this.props;
      return (
        <div className="fiq-profile__sections__wrapper signup-details">
          <If condition={isEmpty(signupData)}>
            <NoData text="No Signup data" />
          </If>
          <Loader visible={requestInProgress} />
          <If condition={!requestInProgress}>{renderSignupData(signupData)}</If>
        </div>
      );
    }
  },
));


SignupDetails.wrappedComponent.propTypes = propTypes;

export default withErrorBoundary(SignupDetails);
