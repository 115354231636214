import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { object, array } from 'prop-types';
import { Col, Space, notifier, Pill, Tooltip } from 'tc-biq-design-system';
import ReactHtmlParser from 'react-html-parser';

import Page from 'App/components/Page';
import Loader from 'App/components/Loader';
import DetailsCardWrapper from 'App/components/DetailsCardWrapper';
import DataAccordion from 'App/components/DataAccordion';
import { fetchEmailLog } from 'Communications/services/CommunicationsService';
import { EmailLogDetails } from 'Communications/components';
import WithErrorBoundary from 'App/components/hoc/withErrorBoundary';
import removeGlobalStyles from 'App/services/utilities/removeGlobalStyles';

import './EmailLogSinglePage.scss';

const propTypes = {
  match: object.isRequired,
};

const bread = id => [
  { label: 'Communications' },
  { label: 'EmailLogs', route: '/communications/email-logs' },
  { label: id, route: `/communications/email-logs/${id}` },
];

const text = {
  EMAIL_LOG: 'Email Log',
  MESSAGE_BODY: 'Message Body',
  ATTACHMENTS: 'Attachments',
};

const Attachments = ({ attachments }) => (
  <div className="fiq-email-log-single__attachments">
    {attachments.map(({ file, name }) => (
      <Tooltip
        position="bottom"
        content={(
          <span
            style={{
              width: '250xp',
              overflow: 'hidden',
              wordBreak: 'break-all',
              whiteSpace: 'normal',
            }}
          >
            {file}
          </span>
        )}
      >
        <Pill
          onClick={() => {
            window.open(file, '_blank');
          }}
        >
          {name}
        </Pill>
      </Tooltip>
    ))}
  </div>
);

Attachments.propTypes = {
  attachments: array.isRequired,
};

class EmailLogSinglePage extends PureComponent {
  constructor(props) {
    super(props);
    this.id = props.match.params.emailLogId;
    this.state = {
      data: null,
      requestInProgress: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ requestInProgress: true });
    try {
      const response = await fetchEmailLog(this.id);
      this.setState({ data: response.data, requestInProgress: false });
    } catch (err) {
      notifier.error('Failed to fetch Email log data');
      this.setState({ requestInProgress: false });
    }
  };

  render() {
    const { data, requestInProgress } = this.state;
    if (requestInProgress) return <Loader visible />;
    const { contact, status, message_body, attachments, id } = data;
    const formatedAttachments = (attachments || []).map(({ name, file }) => ({
      file,
      name: name.split('/').pop(),
    }));

    return (
      <Page
        bread={bread(this.id)}
        title={`${text.EMAIL_LOG} ${id}`}
        style={{ marginTop: '24px', overflow: 'auto' }}
      >
        <div className="fiq-email-log-single">
          <Col xs="100%" lg="70%">
            <DetailsCardWrapper contact={contact} status={status} title={text.EMAIL_LOG}>
              <EmailLogDetails {...data} />
            </DetailsCardWrapper>
            <Space size={12} />
            <DataAccordion title={text.MESSAGE_BODY} toggled>
              {ReactHtmlParser(message_body, { transform: removeGlobalStyles })}
            </DataAccordion>
            {!!formatedAttachments.length && (
              <DataAccordion title={text.ATTACHMENTS} toggled>
                <Attachments attachments={formatedAttachments} />
              </DataAccordion>
            )}
          </Col>
        </div>
      </Page>
    );
  }
}

EmailLogSinglePage.propTypes = propTypes;

export default WithErrorBoundary(withRouter(EmailLogSinglePage));
