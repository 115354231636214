import React from 'react';
import { object, func, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Button, Row, Col, InfoBox, Space } from 'tc-biq-design-system';
import { isEmpty } from 'lodash';

import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import Loader from 'App/components/Loader';
import UserAvatar from 'Settings/Sections/Users/components/UserAvatar';
import { hasAccess } from 'App/services/permissionsService';
import UploadEditAvatar from 'Settings/Sections/Users/components/sidepanels/UploadEditAvatar';

import './UserDetails.scss';

const propTypes = {
  user: object.isRequired,
  updateUserDetails: func.isRequired,
  userFields: object.isRequired,
  submitInProgress: bool.isRequired,
  isCompeekConfigured: bool.isRequired,
};

const text = {
  SAVE: 'Save changes',
  UPDATE_MSG: {
    ERROR: 'Failed to update user details',
    SUCCESS: 'Successfully updated user details',
  },
  USER_DELETED: 'This User has been deleted.',
  NOTE: 'Note:',
};

const fieldsToObject = fields => fields.reduce(
  (acc, field) => ({
    ...acc,
    [field.id]: { ...field },
  }),
  {},
);

const UserDetails = ({
  user,
  updateUserDetails,
  userFields,
  submitInProgress,
  loggedUser,
}) => {
  const formattedFields = isEmpty(userFields) ? {} : fieldsToObject(fieldsResolver(userFields));
  if (isEmpty(formattedFields)) return <Loader visible />;
  const {
    first_name,
    last_name,
    phone,
    language,
    username,
    email,
    role_bindings__role,
    team_binding__team,
    contact_access_group_bindings__contact_access_group,
    voip_agent_id,
  } = formattedFields;
  const selfUpdate = user.id === loggedUser.id;
  const editUser = () => updateUserDetails({ id: user.id, messages: text.UPDATE_MSG, selfUpdate});
  const hasPermission = hasAccess('users_user', 'update');

  return (
    <Row gutter={0}>
      <Col md="100%" lg="50%" style={{ margin: '0 auto' }}>
        <div className="fiq-user-details">
          <div className="fiq-user-details__header">
            <UserAvatar user={user} />
          </div>
          <div className="fiq-user-details__body">
            <div className="fiq-user-details__fields-section">
              {user.is_deleted && (
                <div>
                  <InfoBox type="warning" header={text.NOTE}>
                    {text.USER_DELETED}
                  </InfoBox>
                  <Space size={8} />
                </div>
              )}
              <Field {...first_name} formId="userDetailsForm" data-recording-ignore="mask" />
              <Field {...last_name} formId="userDetailsForm" data-recording-ignore="mask" />
              <Field {...phone} formId="userDetailsForm" data-recording-ignore="mask" />
              <Field {...language} formId="userDetailsForm" />
            </div>
            <div className="fiq-user-details__separator" />
            <div className="fiq-user-details__fields-section">
              <Field {...username} formId="userDetailsForm" data-recording-ignore="mask" />
              <Field {...email} disabled formId="userDetailsForm" data-recording-ignore="mask" />
            </div>
            <div className="fiq-user-details__separator" />
            <div className="fiq-user-details__fields-section">
              <Field
                {...role_bindings__role}
                name="role_bindings"
                label="Role"         
                formId="userDetailsForm"
                data-recording-ignore="mask"
                disabled={selfUpdate}
              />
              <Field
                {...team_binding__team}
                label="Team"
                name="team_binding"
                formId="userDetailsForm"
                data-recording-ignore="mask"
                disabled={selfUpdate}
              />
              <Field
                {...contact_access_group_bindings__contact_access_group}
                label="Contact access group"
                name="contact_access_group_bindings"
                formId="userDetailsForm"
                data-recording-ignore="mask"
                disabled={selfUpdate}
              />
            </div>
            <div className="fiq-user-details__separator" />
            <div className="fiq-user-details__fields-section">
              <Field
                {...voip_agent_id}
                formId="userDetailsForm"
                data-recording-ignore="mask"
              />
            </div>
          </div>
          {!user.is_deleted && (
            <div className="fiq-user-details__footer">
              <Button loading={submitInProgress} disabled={!hasPermission} onClick={editUser}>
                {text.SAVE}
              </Button>
            </div>
          )}
        </div>
        <UploadEditAvatar />
      </Col>
    </Row>
  );
};

UserDetails.propTypes = propTypes;

export default inject(stores => ({
  updateUserDetails: stores.usersStore.updateUserDetails,
  submitInProgress: stores.usersStore.requestInProgress.updateUser,
  userFields: stores.usersStore.userFields,
  user: stores.usersStore.user,
  loggedUser: stores.loginStore.user,
}))(observer(UserDetails));
