import React from 'react';
import { number, oneOfType, string } from 'prop-types';
import { Tab, Tabs, Space } from 'tc-biq-design-system';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import ReferralsTable from './ReferralsTable';
import DepthFilter from './DepthFilter';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
};

const text = {
  DIRECT: 'Direct',
  SUB_REFERRALS: 'Sub Referrals',
};

const renderDepthFilter = fetchTableData => <DepthFilter fetchTableData={fetchTableData} />;

const Referrals = ({ id }) => (
  <Tabs>
    <Tab title={text.DIRECT}>
      <Space size={12} />
      <ReferralsTable
        contactId={id}
        tableKey="contactDirectReferrals"
        defaultFilters={{ depth: 1 }}
      />
    </Tab>
    <Tab title={text.SUB_REFERRALS}>
      <Space size={12} />
      <ReferralsTable
        contactId={id}
        tableKey="contactSubReferrals"
        renderCustomFilterComponent={renderDepthFilter}
        initialFilters={{ depth: 2 }}
      />
    </Tab>
  </Tabs>
);

Referrals.propTypes = propTypes;

export default withErrorBoundary(Referrals);
