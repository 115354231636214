import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { object, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import withFilters from 'App/components/hoc/withFilters';

import Numeric from 'Dashboard/components/layouts/Numeric';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import { buildQueryForPreviousInterval, buildNumeric } from 'Dashboard/store/utils';

const text = {
  TITLE: 'Lead to live',
  DESCRIPTION: 'Total number of contacts who moved from lead to live in selected time period',
};

const METRIC = 'contact_live_conversions';

const propTypes = {
  dashboardInterval: object,
  addNewFilter: func,
  history: object.isRequired,
};

const defaultProps = {
  dashboardInterval: {},
  addNewFilter: null,
};

const filters = (startDate, endDate) => [
  {
    field: { label: 'Created', value: 'created', type: 'datetime' },
    ...(startDate === endDate
      ? { operator: { label: 'on', value: 'date', related_models: null } }
      : { operator: { label: 'between', value: 'range', related_models: null } }),
    ...(startDate === endDate
      ? { value: startDate }
      : { value: [`${startDate}T00:00:00Z`, `${endDate}T23:59:59Z`] }),
  },
];


const DemoToLiveNumeric = inject(stores => ({
  dashboardInterval: stores.dashboardStore.dashboardInterval,
  addNewFilter: stores.tables.contacts.filters.addNewFilter,
}))(observer(
  class DemoToLiveNumeric extends Component {
    constructor() {
      super();
      this.state = {
        fetchInProgress: false,
        data: {
          value: 0,
          percentage: 0,
          isGrowing: false,
        },
        error: null,
      };
    }

    onQueryReady = async (query) => {
      const { error } = this.state;
      const { start_date, end_date } = query;

      this.setState({
        fetchInProgress: true,
      });

      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: start_date,
          end_of_interval: end_date,
          granularity: 'all',
        },
      };

      const response = await Promise.all([
        fetchMetricsData(formattedQuery),
        fetchMetricsData(buildQueryForPreviousInterval(formattedQuery)),
      ]).catch(errorResponse => this.setState({ error: errorResponse }));

      if (error) return;
      const [current, previous] = response.map(item => item.data);
      const { currency } = current;

      const data = buildNumeric(current.count_lead_to_live, previous.count_lead_to_live, currency);

      this.setState({
        fetchInProgress: false,
        data,
      });
    };

    handleCardClick = () => {
      const { addNewFilter, history, dashboardInterval } = this.props;
      const { startDate, endDate } = dashboardInterval;
      filters(startDate, endDate).forEach(addNewFilter);
      history.push('/contacts');
    };

    render() {
      const { fetchInProgress, data } = this.state;
      const { value, percentage, isGrowing } = data;

      return (
        <Numeric
          title={text.TITLE}
          description={text.DESCRIPTION}
          value={value}
          percentage={percentage}
          isGrowing={isGrowing}
          loading={fetchInProgress}
          onClick={this.handleCardClick}
          isCount
        />
      );
    }
  },
));


DemoToLiveNumeric.wrappedComponent.propTypes = propTypes;
DemoToLiveNumeric.wrappedComponent.defaultProps = defaultProps;

export default {
  component: withRouter(withFilters(DemoToLiveNumeric)),
};
