import React, { useState } from 'react';
import { string, array } from 'prop-types';
import { Accordion, Icon, Space } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import Field from 'App/components/FieldRenderer';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import uniqBy from 'lodash/uniqBy';


const text = {
  TEAM: 'Team',
  ASSIGNEE: 'Assignee',
  REMOVE_TITLE: 'Remove assigned managers',
};

const managersEnum = {
  salesTeam: 'sales_manager',
  complianceTeam: 'compliance_manager',
  retentionTeam: 'retention_manager',
};

const formID = 'assignToForm';

const renderHeader = (icon, headerText) => (
  <div className="info-wrapper">
    <Icon colorName="text-primary-400" bgColorName="bck-primary-100" name={icon} size="small" />
    <Space size={5} />
    <span className="info-wrapper__header tc-paragraph-strong">{headerText}</span>
  </div>
);

const AssignTo = ({
  headerText,
  teamName,
  assignName,
  removeAssignName,
  selectedItems,
  title,
}) => {
  const [isAccordionOpen, toggleAccordion] = useState(false);

  const loadManagers = async () => {
    try {
      const getManagers = selectedUsers => selectedUsers
        .filter(user => user[managersEnum[teamName]])
        .map(userWithManager => ({
          display_name: extractDisplayName(userWithManager[managersEnum[teamName]]),
          value: userWithManager[managersEnum[teamName]].id,
        }));
      return uniqBy(getManagers(selectedItems), 'value');
    } catch {
      return [];
    }
  };


  return (
    <Accordion
      title={title}
      visible={isAccordionOpen}
      onClick={() => toggleAccordion(!isAccordionOpen)}
    >
      <div className="assign-to">
        {renderHeader('User', headerText)}
        <Field
          async
          formId={formID}
          name={assignName}
          type="related field"
          labelKey="display_name"
          valueKey="value"
          label={text.ASSIGNEE}
          related_model={{
            display_name: 'users',
            value: 'users_user',
          }}
        />
      </div>
      <div className="assign-to assign-to--withBorder">
        {renderHeader('Delete', text.REMOVE_TITLE)}
        <Field
          async
          formId={formID}
          labelKey="display_name"
          loadOptions={loadManagers}
          name={removeAssignName}
          type="related field"
          valueKey="value"
        />
      </div>
    </Accordion>
  );
};

AssignTo.propTypes = {
  headerText: string.isRequired,
  teamName: string.isRequired,
  assignName: string.isRequired,
  removeAssignName: string.isRequired,
  selectedItems: array.isRequired,
  title: string.isRequired,
};

export default inject(stores => ({
  updateFieldValue: stores.forms.assignToForm.updateFieldValue,
  submitInProgress: stores.contact.profile.requestInProgress.assignManager,
}))(observer(AssignTo));
