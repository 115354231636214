import React from 'react';
import { object } from 'prop-types';
import { inject, observer } from 'mobx-react';

const propTypes = {
  errors: object.isRequired,
};

const UploadErrors = ({ errors }) => (
  <div>
    {errors.file && <div className="tc-micro-strong text-status04-500">{errors.file}</div>}
    {errors.file_backside && (
      <div className="tc-micro-strong text-status04-500">{errors.file_backside}</div>
    )}
  </div>
);

UploadErrors.propTypes = propTypes;

export default inject(stores => ({
  errors: stores.forms.uploadDocForm.fieldErrors,
}))(observer(UploadErrors));
