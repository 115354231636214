import React from 'react';

import Page from 'App/components/Page';
import AuditLogTable, {
  tableConfig,
} from 'Settings/Sections/AuditLog/components/AuditLogTable';

import './AuditLogPage.scss';

const bread = [
  { label: 'Settings', route: '/settings' },
  { label: 'Audit log', route: '/settings/audit-logs' },
];
const exportDataOptions = {
  permission: 'audit_logs_auditlog',
  path: `/api${tableConfig.tableUrl}`,
  tableKey: tableConfig.tableKey,
};

const text = {
  TITLE: 'Audit logs',
};

const AuditLogPage = () => (
  <Page
    bread={bread}
    title={text.TITLE}
    style={{ margin: '0 20px' }}
    exportDataOptions={exportDataOptions}
  >
    <AuditLogTable />
  </Page>
);

export default AuditLogPage;
