import React from 'react';
import { object } from 'prop-types';
import { Space } from 'tc-biq-design-system';

import { SideCell, StatusCell } from 'App/components/gridCellRenderers';

const propTypes = {
  children: object.isRequired,
};

// eslint-disable-next-line
const DetailsMain = ({ title, side, status }) => (
  <div className="fiq-contact-card-wrapper__body__details__main">
    <span className="tc-paragraph-strong">{title}</span>
    <Space size={20} />
    {side && <SideCell value={side} />}
    <Space size={8} />
    {status && <StatusCell value={status} />}
  </div>
);

const CardDetails = ({ children, ...props }) => (
  <div className="fiq-contact-card-wrapper__body__details">
    <DetailsMain {...props} />
    <Space size={24} />
    {children}
  </div>
);

CardDetails.propTypes = propTypes;

export default CardDetails;
