import React, { useState } from 'react';
import classNames from 'classnames';
import { Pill } from 'tc-biq-design-system';
import { object, func } from 'prop-types';

import SegmentFilterDropdown from './SegmentFilterDropdown';

const text = {
  SEGMENT: 'Segment',
};

const propTypes = {
  activeSegment: object,
  removeFilter: func.isRequired,
};

const defaultProps = {
  activeSegment: null,
};

const SegmentFilter = ({ activeSegment, removeFilter, ...props }) => {
  const [addFilterInProgress, toggleDropdown] = useState(false);
  const open = () => toggleDropdown(true);
  const close = () => toggleDropdown(false);
  return (
    <div className="fiq-filters__add-filter">
      <div className={classNames({ hidden: addFilterInProgress })}>
        <Pill
          big
          icon={activeSegment ? 'Close' : 'CaretDown'}
          onIconClick={activeSegment ? removeFilter : open}
          onClick={open}
        >
          {activeSegment ? `${text.SEGMENT}: ${activeSegment.name}` : text.SEGMENT}
        </Pill>
      </div>
      <SegmentFilterDropdown
        hidden={!addFilterInProgress}
        closeDropdown={close}
        initialSegment={activeSegment}
        {...props}
      />
    </div>
  );
};

SegmentFilter.propTypes = propTypes;
SegmentFilter.defaultProps = defaultProps;

export default SegmentFilter;
