import React, { useEffect } from 'react';
import { Button } from 'tc-biq-design-system';
import { func, string } from 'prop-types';

import { hasAccess } from 'App/services/permissionsService';
import * as CommandbarService from 'App/services/commandbarService';

import './ExportData.scss';

const propTypes = {
  exportData: func.isRequired,
  path: string.isRequired,
  permission: string.isRequired,
};

const text = {
  EXPORT: 'Export',
  CURRENT: 'Current page',
  ALL: 'All',
};

const EXPORT_CURRENT_DATA = 'export-current-table';

const ExportData = ({ exportData, path, permission }) => {
  if (!hasAccess(permission, 'export')) return null;
  const onClick = () => exportData({ path });
  useEffect(() => {
    CommandbarService.addCallback(EXPORT_CURRENT_DATA, onClick);
    return () => CommandbarService.removeCallback(EXPORT_CURRENT_DATA);
  }, []);
  return (
    <div className="fiq-export-data">
      <Button
        onClick={onClick}
      >
        {text.EXPORT}
      </Button>
    </div>
  );
};

ExportData.propTypes = propTypes;

export default ExportData;
