import React, { Fragment } from 'react';
import { string, object } from 'prop-types';
import { Space } from 'tc-biq-design-system';
import moment from 'moment';
import classNames from 'classnames';

import { UserCell } from 'App/components/gridCellRenderers';

const propTypes = {
  dateString: string.isRequired,
  label: string.isRequired,
  reviewer: object,
};
const defaultProps = { reviewer: null };

const DateRecord = ({ dateString, label, reviewer }) => {
  const date = moment(dateString, moment.ISO_8601).format('YYYY-DD-MM');
  const time = moment(dateString, moment.ISO_8601).format('HH:mm:ss');
  return (
    <div
      className={classNames('fiq-documents__uploaded', {
        'fiq-documents__uploaded--reviewed': !!reviewer,
      })}
    >
      <div className="tc-micro-regular text-neutral-500">{`${label}: `}</div>
      <Space size={8} />
      {reviewer && (
        <Fragment>
          <UserCell
            id={reviewer.id}
            fullName={`${reviewer.first_name} ${reviewer.last_name}`}
            avatar={reviewer.avatar}
          />
          <Space size={8} />
          |
          <Space size={8} />
        </Fragment>
      )}
      <div className="tc-paragraph-regular">{date}</div>
      <Space size={4} />
      <div>&#183;</div>
      <Space size={4} />
      <div className="tc-micro-regular text-neutral-700">{time}</div>
    </div>
  );
};

DateRecord.propTypes = propTypes;
DateRecord.defaultProps = defaultProps;

export default DateRecord;
