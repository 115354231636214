import React from 'react';
import { inject, observer } from 'mobx-react';
import { array, bool, func } from 'prop-types';

import Loader from 'App/components/Loader';
import NoData from 'App/components/NoData/NoData';
import If from 'App/components/If/index';
import InfiniteScroll from 'App/components/InfiniteScroll';
import { formatByDate } from 'App/services/utilities/dateGroupUtils';
import NotificationCard from './NotificationCard';
import DateGroup from './DateGroup/DateGroup';

const propTypes = {
  notifications: array.isRequired,
  hasMore: bool.isRequired,
  requestInProgress: bool.isRequired,
  fetchNotifications: func.isRequired,
};

const NotificationGroup = ({ groupLabel, notifications }) => notifications.map(notification => (
  <NotificationCard key={notification.id} groupTitle={groupLabel} notification={notification} />
));

const NotificationsBody = ({ hasMore, notifications, requestInProgress, fetchNotifications }) => {
  const fetchData = async () => {
    await fetchNotifications({ isScrollFetch: true });
  };

  const dateGroups = formatByDate(notifications);

  return (
    <InfiniteScroll
      hasMore={hasMore}
      fetchData={fetchData}
      isLoading={requestInProgress}
      style={{
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 350px)',
        width: '100%',
      }}
    >
      <Loader visible={requestInProgress} />
      {Object.entries(dateGroups).map(([dateGroupLabel, groupNotifications]) => (
        <DateGroup key={`${dateGroupLabel}`} groupTitle={dateGroupLabel}>
          <NotificationGroup groupLabel={dateGroupLabel} notifications={groupNotifications} />
        </DateGroup>
      ))}
      <If condition={notifications.length === 0 && !requestInProgress}>
        <div style={{ width: '100%', height: '500px' }}>
          <NoData />
        </div>
      </If>
    </InfiniteScroll>
  );
};

NotificationsBody.propTypes = propTypes;

export default inject(stores => ({
  notifications: stores.userNotifications.notifications,
  hasMore: stores.userNotifications.hasMore,
  requestInProgress: stores.userNotifications.requestInProgress.notifications,
  fetchActivities: stores.userNotifications.fetchNotifications,
  fetchNotifications: stores.userNotifications.fetchNotifications,
}))(observer(NotificationsBody));
