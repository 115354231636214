export default {
  finances_wallet: '/transactions/wallets',
  finances_paymenttransaction: '/transactions/payments',
  finances_tradetransaction: '/transactions/trades',
  finances_withdrawrequest: '/transactions/withdrawals',
  communications_emaillog: '/communications/email-logs',
  communications_smslog: '/communications/sms-logs',
  communications_whatsapplog: '/communications/whatsapp-logs',
  contacts_contact: '/contacts',
  users_user: '/settings/users',
  contacts_note: contactId => `/contacts/${contactId}/notes`,
  contacts_contactsignupinfo: contactId => `/contacts/${contactId}/signup-details`,
  editor: '/editor',
  contacts_tag: contactId => `/contacts/${contactId}`,
  automations_rule: '/automation/rules',
};
