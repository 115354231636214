import http from 'App/services/http';

const fetchCustomPropertiesOptions = () => http.options('/custom-properties/');

const fetchCustomProperty = id => http.get(`/custom-properties/${id}/`);

const editCustomProperty = (id, body) => http.put(`/custom-properties/${id}/`, body);

const createCustomProperty = body => http.post('/custom-properties/', body);

const deleteCustomProperty = id => http.delete(`/custom-properties/${id}/`);

export {
  fetchCustomPropertiesOptions,
  fetchCustomProperty,
  editCustomProperty,
  createCustomProperty,
  deleteCustomProperty,
};
