import React from 'react';
import { Icon } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

const propTypes = {
  history: object.isRequired,
  style: object,
};

const defaultProps = { style: { cursor: 'pointer' } };

const GoBack = ({ history, style }) => (
  <div style={style} onClick={history.goBack} className="fiq-user-header__go-back">
    <Icon name="ArrowLeft" colorName="text-neutral-900" />
  </div>
);

GoBack.propTypes = propTypes;
GoBack.defaultProps = defaultProps;

export default withRouter(GoBack);
