import React from 'react';
import { Button } from 'tc-biq-design-system';
import { func, bool, shape, string, object, any } from 'prop-types';

const text = {
  CANCEL: 'Cancel',
  CONFIRM: 'Save',
};

const labels = {
  cancel: string,
  confirm: any,
};

const defaultLabels = {
  cancel: text.CANCEL,
  confirm: text.CONFIRM,
};

const propTypes = {
  buttonLabels: shape(labels),
  execute: func.isRequired,
  close: func,
  submitInProgress: bool,
  disableSubmit: bool,
  confirmColor: string,
  cancelColor: string,
  style: object,
  btnConfirmStyle: object,
};

const defaultProps = {
  close: null,
  buttonLabels: defaultLabels,
  submitInProgress: false,
  disableSubmit: false,
  confirmColor: 'confirmation',
  cancelColor: 'ghost',
  btnConfirmStyle: {},
  style: {},
};

const DEFAULT_STYLE = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const SidepanelFooter = ({
  execute,
  close,
  submitInProgress,
  disableSubmit,
  buttonLabels,
  confirmColor,
  cancelColor,
  style,
  btnConfirmStyle,
}) => (
  <div style={{ ...DEFAULT_STYLE, ...style }}>
    {!!buttonLabels.cancel && (
      <Button color={cancelColor} onClick={() => close()}>
        {buttonLabels.cancel}
      </Button>
    )}
    {!!buttonLabels.confirm && (
      <Button
        disabled={submitInProgress || disableSubmit}
        loading={submitInProgress}
        color={confirmColor}
        onClick={execute}
        style={btnConfirmStyle}
      >
        {buttonLabels.confirm}
      </Button>
    )}
  </div>
);

SidepanelFooter.propTypes = propTypes;
SidepanelFooter.defaultProps = defaultProps;

export default SidepanelFooter;
