import React, { useEffect } from 'react';
import { Button } from 'tc-biq-design-system';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import CustomPropertiesTable from 'Settings/Sections/CustomProperties/components/CustomPropertiesTable';
import { openCreateEditCustomProperty } from 'Settings/Sections/CustomProperties/components/overlays';

const text = {
  PAGE_TITLE: 'Custom properties',
  CREATE_PROPERTY: 'Create property',
};
const bread = [{ label: 'Settings', route: '/settings' }, { label: 'Custom properties', route: '/settings/custom-properties' }];

const CustomPropertiesPage = ({ match }) => {
  useEffect(() => {
    const { action } = match.params;
    if (action === 'create') openCreateEditCustomProperty();
  }, []);
  const headerActions = () => (
    <Button onClick={() => openCreateEditCustomProperty()}>{text.CREATE_PROPERTY}</Button>
  );
  return (
    <Page
      goBackIcon
      style={{ margin: '0 20px' }}
      title={text.PAGE_TITLE}
      headerActions={headerActions}
      bread={bread}
    >
      <CustomPropertiesTable />
    </Page>
  );
};

CustomPropertiesPage.propTypes = { match: object.isRequired };

export default CustomPropertiesPage;
