import React, { PureComponent } from 'react';
import { Space, Upload } from 'tc-biq-design-system';
import { func, object } from 'prop-types';
import { inject, observer } from 'mobx-react';

const propTypes = {
  file: object,
  file_backside: object,
  document_type: object,
  updateFieldValue: func.isRequired,
  resetFieldError: func.isRequired,
  errors: object.isRequired,
};

const defaultProps = {
  file: null,
  file_backside: null,
  document_type: null,
};

const text = {
  UPLOAD_FILE: {
    initial: {
      title: 'Click here to upload file',
      subtitle: 'Drag and drop files or click to browse',
    },
    loading: {
      title: 'Uploading file',
      subtitle: 'This may take a second, please do not leave the page',
    },
  },
  UPLOAD_FILE_BACKSIDE: {
    initial: {
      title: 'Click here to upload file backside',
      subtitle: 'Drag and drop files or click to browse',
    },
    loading: {
      title: 'Uploading file backside',
      subtitle: 'This may take a second, please do not leave the page',
    },
  },
};

const UploadSided = inject(stores => ({
  updateFieldValue: stores.forms.uploadDocForm.updateFieldValue,
  file: stores.forms.uploadDocForm.data.file,
  file_backside: stores.forms.uploadDocForm.data.file_backside,
  document_type: stores.forms.uploadDocForm.data.document_type,
  fetchContactDocuments: stores.contact.documents.fetchContactDocuments,
  errors: stores.forms.uploadDocForm.fieldErrors,
  resetFieldError: stores.forms.uploadDocForm.resetFieldError,
}))(observer(
  class UploadSided extends PureComponent {
    uploadFile = (uploadedFile) => {
      const { updateFieldValue, resetFieldError, errors } = this.props;
      if (errors.file) resetFieldError('file');
      updateFieldValue('file', uploadedFile?.[0]);
    };

    uploadFileBackside = (uploadedFile) => {
      const { updateFieldValue, resetFieldError, errors } = this.props;
      if (errors.file) resetFieldError('file_backside');
      updateFieldValue('file_backside', uploadedFile[0]);
    };

    render() {
      const { file, file_backside, document_type } = this.props;

      return (
        <div>
          <div style={{ cursor: 'pointer' }}>
            <Upload
              onDrop={this.uploadFile}
              file={file}
              status={file ? 'success' : 'initial'}
              localization={text.UPLOAD_FILE}
            />
          </div>
          <Space size={12} />
          {document_type && document_type.two_sided && (
            <div style={{ cursor: 'pointer' }}>
              <Upload
                onDrop={this.uploadFileBackside}
                file={file_backside}
                status={file_backside ? 'success' : 'initial'}
                localization={text.UPLOAD_FILE_BACKSIDE}
              />
            </div>
          )}
        </div>
      );
    }
  },
));

UploadSided.wrappedComponent.propTypes = propTypes;
UploadSided.wrappedComponent.defaultProps = defaultProps;

export default UploadSided;
