import React from 'react';
import { Icon, Space, Detail } from 'tc-biq-design-system';
import gmail from 'assets/images/gmail.svg';
import yahoo from 'assets/images/yahoo.svg';
import outlook from 'assets/images/microsoft-outlook.svg';
import exchange from 'assets/images/microsoft-exchange.svg';
import { string } from 'prop-types';

const propTypes = {
  provider: string.isRequired,
  email: string.isRequired,
};

const text = {
  INBOX_TYPE: 'Inbox type:',
};

const mailInboxEnum = {
  gmail: {
    path: gmail,
    alt: 'Gmail',
  },
  outlook: {
    path: outlook,
    alt: 'Outlook',
  },
  eas: {
    path: exchange,
    alt: 'Exchange',
  },
  yahoo: {
    path: yahoo,
    alt: 'Yahoo',
  },
};

const InboxInfo = ({ provider, email }) => (
  <div className="fiq-user-email-integrations__inbox-info">
    {!mailInboxEnum[provider] && (
      <Icon name="Mail" size="extraLarge" colorName="text-neutral-600" />
    )}
    {mailInboxEnum[provider] && (
      <img src={mailInboxEnum[provider].path} alt={mailInboxEnum[provider].alt} />
    )}
    <Space size={16} />
    <Detail entity={`${text.INBOX_TYPE} ${provider}`} value={email} />
  </div>
);

InboxInfo.propTypes = propTypes;

export default InboxInfo;
