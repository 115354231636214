const extractDisplayName = (data) => {
  if (!data) return '-';
  const {
    first_name,
    last_name,
    email,
    name,
    provider,
    display_name,
    label,
    ad_network,
    segment,
    id,
    asset,
    is_deleted,
  } = data;
  const getDisplayName = () => {
    if (display_name) return display_name;
    if (first_name || last_name) return `${first_name} ${last_name}`;
    if (email) return email;
    if (name) return name;
    if (label) return label;
    if (provider) return `${provider[0].toUpperCase()}${provider.substring(1)}`;
    if (typeof data === 'string' || !Number.isNaN(+data)) return data;
    if (ad_network) return `${segment.name} - ${ad_network}`;
    if (asset && id) return `${asset} – ${id}`;
    if (id) return `${id}`;
    return 'N/A';
  };
  const displayName = getDisplayName();
  return is_deleted ? `[deleted] ${displayName}` : displayName;
};

export default extractDisplayName;
