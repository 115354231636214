import React from 'react';
import { object } from 'prop-types';
import ContactCell from './ContactCell';
import UserCell from './UserCell';
import RouteCell from './RouteCell';

const propTypes = {
  value: object.isRequired,
};

const GenericObjectCell = ({ value }) => {
  if (!value) {
    return null;
  }
  const { id, object_type, display_name, avatar } = value;
  // Add object type to switch statement to process it.
  switch (object_type) {
    case 'users_user':
      return <UserCell id={id} fullName={display_name} avatar={avatar} />;
    case 'contacts_contact':
      return <ContactCell id={id} fullName={display_name} avatar={avatar} />;
    case 'events_eventtype':
      return RouteCell('/settings/events/')({ hrefValue: id, value: display_name });
    case 'communications_emaillog':
      return RouteCell('/communications/email-logs/')({ hrefValue: id, value: display_name });
    case 'communications_smslog':
      return RouteCell('/communications/sms-logs/')({ hrefValue: id, value: display_name });
    case 'communications_whatsapplog':
      return RouteCell('/communications/whatsapp-logs/')({ hrefValue: id, value: display_name });
    case 'marketing_campaign':
      return RouteCell('/marketing/campaigns/')({ hrefValue: id, value: display_name });
    default:
      return <span>{display_name}</span>;
  }
};

GenericObjectCell.propTypes = propTypes;
export default GenericObjectCell;
