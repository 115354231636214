import hideCols from 'App/services/utilities/hideCols';
import { ToggleCell, actionWrapper } from 'App/components/gridCellRenderers';

const modifier = ({ toggle, actions }) => ({
  ...hideCols(['id']),
  is_active: {
    width: 20,
    headerName: '',
    pinned: 'left',
    enableFiltering: false,
    cellRendererFramework: ToggleCell(toggle),
  },
  actions: {
    pinned: 'right',
    headerName: '',
    maxWidth: 50,
    enableFiltering: false,
    cellRendererFramework: actionWrapper(actions),
    label: '',
    sortable: false,
  },
});

export default modifier;
