const errorFormatter = (errors) => {
  let temp = {};
  Object.keys(errors).forEach((key) => {
    let error = typeof errors[key] === 'object' ? errors[key][0] : errors[key];
    if (typeof error === 'object') {
      error = errors[key].map(errorFormatter);
    }

    temp = {
      ...temp,
      [key]: error,
    };
  });
  return temp;
};

export default errorFormatter;
