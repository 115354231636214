import React, { Fragment } from 'react';
import { Modal, Button } from 'tc-biq-design-system';
import { object, bool, func } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { closeOverlay, openOverlay } from 'App/services/overlayService';

const propTypes = {
  visible: bool,
  parameters: object,
  handleOnChange: func,
  onToggle: func,
};

const defaultProps = {
  visible: false,
  parameters: null,
  handleOnChange: null,
  onToggle: null,
};

const SIDEPANEL_ID = 'COPY_TEMPLATE_CONTENT';

const text = {
  CONTENT_TEXT: 'Do you want to copy all the content from the default template?',
  TITLE: '',
  COPY_TEMPLATE: 'Copy template',
  CANCEL: 'Cancel',
};

const close = () => closeOverlay(SIDEPANEL_ID);
export const openCopyTemplateContent = templateInfo => openOverlay(
  SIDEPANEL_ID,
  templateInfo,
);

const customFooter = (confirm, cancel, isLoading) => () => (
  <Fragment>
    <Button color="ghost" onClick={cancel}>
      {text.CANCEL}
    </Button>
    <Button disabled={isLoading} color="primary" onClick={confirm} loading={isLoading}>
      {text.COPY_TEMPLATE}
    </Button>
  </Fragment>
);

const CopyTemplateContent = ({ handleOnChange, onToggle, visible, parameters }) => {
  if (!parameters) return null;
  const { content, metadata } = parameters;

  const onConfirm = () => {
    handleOnChange('content', content);
    if (metadata) handleOnChange('metadata', metadata);
    onClose(true);
  };

  const onClose = () => { close(); onToggle(true); };

  return (
    <Modal
      closable
      visible={visible}
      title={text.TITLE}
      confirmText={text.COPY_TEMPLATE}
      type="info"
      footerRender={customFooter(onConfirm, onClose)}
      onCloseIconClick={close}
    >
      <span>{text.CONTENT_TEXT}</span>
    </Modal>
  );
};

CopyTemplateContent.propTypes = propTypes;
CopyTemplateContent.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
}))(observer(CopyTemplateContent));
