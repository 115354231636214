import React, { Component } from 'react';
import { Icon, Upload } from 'tc-biq-design-system';
import { string, object, func, boolean } from 'prop-types';
import classNames from 'classnames';
import { EditorState, RichUtils, ContentState, convertFromHTML } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import { isEmpty } from 'lodash';

import './Editor.scss';

const text = {
  EMAIL_PLACEHOLDER: 'Type the content of your email...',
  DELETE_CONTENT: 'Delete content',
};

const inactiveColorName = 'text-neutral-400';
const activeColorName = 'text-neutral-1000';

const leftToolbarEnum = {
  BOLD: {
    icon: 'Bold',
    type: 'BOLD',
  },
  ITALIC: {
    icon: 'Italic',
    type: 'ITALIC',
  },
  UNDERLINE: {
    icon: 'Underline',
    type: 'UNDERLINE',
  },
};

const propTypes = {
  placeholder: string,
  value: string,
  form: object.isRequired,
  onChange: func.isRequired,
  name: string.isRequired,
  onDropFiles: func,
  deleteOption: boolean,
};

const defaultProps = {
  placeholder: text.EMAIL_PLACEHOLDER,
  value: '',
  onDropFiles: null,
  deleteOption: false,
};

class FiqEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorFocused: false,
    };
  }

  onChange = (editorState) => {
    const { onChange, name } = this.props;
    onChange(name, editorState);
  };

  onEditorFocus = () => {
    this.setState({
      editorFocused: true,
    });
  };

  onFocusOut = () => {
    this.setState({ editorFocused: false });
  };

  toogleOption = (_, type) => {
    const { value: editorState } = this.props;
    this.editor.focus();
    this.onChange(RichUtils.toggleInlineStyle(editorState, type));
  };

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  setEditorReference = (ref) => {
    this.editor = ref;
  };

  deleteContent = () => {
    this.onChange(getEmptyState());
  };

  render() {
    const { editorFocused } = this.state;
    const { placeholder, form, name, onDropFiles, deleteOption } = this.props;
    // eslint-disable-next-line max-len
    const editorState = form.data[name] || !isEmpty(form.data[name]) ? form.data[name] : getEmptyState();

    return (
      <div
        onFocus={this.onEditorFocus}
        onBlur={this.onFocusOut}
        handleKeyCommand={this.handleKeyCommand}
        className={classNames('fiq-editor', {
          'fiq-editor--focus': editorFocused,
        })}
      >
        <Editor
          ref={this.setEditorReference}
          placeholder={placeholder}
          editorState={editorState}
          onChange={this.onChange}
          handleKeyCommand={this.handleKeyCommand}
        />
        <div
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          className="fiq-editor__left-toolbar"
        >
          {Object.keys(leftToolbarEnum).map(key => (
            <div
              type="button"
              onMouseDown={(e) => {
                this.toogleOption(e, leftToolbarEnum[key].type);
              }}
            >
              <Icon
                name={leftToolbarEnum[key].icon}
                size="small"
                key={key}
                colorName={
                  editorState.getCurrentInlineStyle().has(leftToolbarEnum[key].type)
                    ? activeColorName
                    : inactiveColorName
                }
              />
            </div>
          ))}
        </div>
        <div className="fiq-editor__right-toolbar">
          {onDropFiles && (
            <Upload
              multiple
              onDrop={(uploadedFiles) => {
                onDropFiles(uploadedFiles);
              }}
            >
              <Icon size="small" name="Paperclip" colorName={inactiveColorName} />
            </Upload>
          )}
          {deleteOption && (
            <div className="fiq-editor__right-toolbar__delete-content" onClick={this.deleteContent}>
              <span className="tc-paragraph-strong text-primary-500">{text.DELETE_CONTENT}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const getEmptyState = () => EditorState.createEmpty();

export const stateFromHtml = (html) => {
  const blocksFromHTML = convertFromHTML(html);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  return EditorState.createWithContent(state);
};

FiqEditor.propTypes = propTypes;
FiqEditor.defaultProps = defaultProps;

export default FiqEditor;
