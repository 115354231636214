import React, { useState, useEffect, Fragment } from 'react';
import { Sidepanel, Radio, Card, Space, Icon, notifier } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { bool, object, func } from 'prop-types';
import { pick, isEmpty, omit } from 'lodash';
import classNames from 'classnames';

import SidepanelFooter from 'App/components/SidepanelFooter';
import { openOverlay, closeOverlay } from 'App/services/overlayService';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import Loader from 'App/components/Loader/Loader';

import './SaveEditFilter.scss';

export const SIDEPANEL_ID = 'SAVE_EDIT_FILTER';

export const closeSaveEditFilter = () => closeOverlay(SIDEPANEL_ID);

export const openSaveEditFilter = data => openOverlay(SIDEPANEL_ID, data);

const SHARE_FIELDS_KEYS = ['teams', 'roles', 'users', 'available_for_owner'];

const propTypes = {
  visible: bool,
  parameters: object,
  fetchSavedFilter: func.isRequired,
  fetchSavedFilterOptions: func.isRequired,
  fetchSavedFilters: func.isRequired,
  createEditFilters: func.isRequired,
  resetFieldsData: func.isRequired,
  setFieldsData: func.isRequired,
  formData: object.isRequired,
  updateFieldValue: func.isRequired,
  onSuccess: func,
  validateForm: func.isRequired,
};

const defaultProps = {
  visible: false,
  parameters: null,
  onSuccess: null,
};

const text = {
  TITLE_SAVE: 'Save filters',
  TITLE_EDIT: 'Edit filters',
  SHARE: 'Share with',
  DEFAULT: 'Default',
  FAVORITE: 'Favorite',
  PRIVATE: 'Private',
  CUSTOM: 'Custom',
  SAVE_ERROR: 'Failed to save filter',
  SAVE_SUCCESS: 'Succesfully saved filter',
  FETCH_FAILED: 'Failed to retrieve data',
  FIELDS_FAILED: 'Something went wrong, contact support',
  BUTTON_LABELS: isEdit => (isEdit
    ? {
      confirm: 'Save changes',
      cancel: 'Discard changes',
    }
    : {
      confirm: 'Save filter',
      cancel: 'Discard',
    }),
};

const CARD_STYLE = {
  header: { display: 'flex', alignItems: 'center', height: '44px' },
  content: { padding: '0' },
};

// eslint-disable-next-line react/prop-types
const customFooter = ({ execute, close, submitInProgress, buttonLabels }) => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={buttonLabels}
  />
);

const headerTemplate = () => (
  <div className="fiq-save-edit-filter__header">
    <Icon name="Share" colorName="text-primary-500" bgColorName="bck-primary-100" />
    <Space size={12} />
    <span className="tc-regular-strong">{text.SHARE}</span>
  </div>
);

const SaveEditFilter = ({
  visible,
  parameters,
  fetchSavedFilter,
  fetchSavedFilterOptions,
  setFieldsData,
  createEditFilters,
  fetchSavedFilters,
  onSuccess,
  resetFieldsData,
  formData,
  updateFieldValue,
  validateForm,
}) => {
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loadingFields, setLoadingFields] = useState(false);
  const [savingInProgress, setInProgress] = useState(false);
  const [shareWith, toggleShareWith] = useState('private');
  const [savedFilter, setSavedFilter] = useState(null);
  const [fieldsDef, setFieldsDef] = useState(null);
  const [formattedFields, setForrmattedFields] = useState([]);
  const isEdit = !!savedFilter;

  const fetchFields = async () => {
    setLoadingFields(true);
    try {
      const response = await fetchSavedFilterOptions();
      const fields = pick(response.data.actions.POST, SHARE_FIELDS_KEYS);
      setForrmattedFields(fieldsResolver(fields));
      setFieldsDef(fields);
    } catch {
      notifier.error(text.FIELDS_FAILED);
    } finally {
      setLoadingFields(false);
    }
  };

  const fetchFilter = async () => {
    setLoadingFilter(true);
    try {
      const responseSavedFilter = await fetchSavedFilter(parameters.id);
      setSavedFilter(responseSavedFilter.data);
      updateFieldValue('name', responseSavedFilter.data.name);
      if (hasSharedData(fieldsDef, responseSavedFilter.data, shareWith)) {
        presetData(responseSavedFilter.data);
      }
    } catch (err) {
      setSavedFilter({});
      notifier.error(text.FETCH_FAILED);
    } finally {
      setLoadingFilter(false);
    }
  };

  useEffect(() => {
    if (visible && !savedFilter && parameters && parameters.id && fieldsDef) fetchFilter();
  }, [fieldsDef]);

  useEffect(() => {
    if (!fieldsDef && visible) fetchFields();
  }, [visible]);

  const onToggleShareType = ({ target }) => {
    toggleShareWith(target.value);
  };

  const close = () => {
    closeSaveEditFilter();
    resetFieldsData();
    setSavedFilter(null);
    toggleShareWith('private');
    setFieldsDef(null);
  };

  const save = async () => {
    if (!validateForm()) return;
    setInProgress(true);
    const payload = {
      ...omit(savedFilter, SHARE_FIELDS_KEYS),
      ...formatFormData(formData, shareWith),
    };
    try {
      await createEditFilters(formData.name, payload);
      notifier.success(text.SAVE_SUCCESS);
      setInProgress(false);
      fetchSavedFilters();
      if (onSuccess) onSuccess();
      close();
    } catch (errors) {
      notifier.error(text.SAVE_ERROR);
      setInProgress(false);
    }
  };

  const presetData = (filter) => {
    toggleShareWith('custom');
    setFieldsData(filter);
  };

  return (
    <Sidepanel
      title={isEdit ? text.TITLE_EDIT : text.TITLE_SAVE}
      icon={isEdit ? 'Pen' : 'Save'}
      visible={visible}
      closable={false}
      footerRender={() => customFooter({
        execute: save,
        close,
        submitInProgress: savingInProgress,
        buttonLabels: text.BUTTON_LABELS(isEdit),
      })
      }
    >
      {!loadingFilter && !loadingFields && (
        <Fragment>
          <Field label="Name" id="name" name="name" formId="savedFilterForm" validate="required" />
          <Space size={16} />
          <Card headerTemplate={headerTemplate} style={CARD_STYLE}>
            <div className="fiq-save-edit-filter__content">
              <Radio.Group name="name" value={shareWith} onChange={onToggleShareType}>
                <Radio value="private" checked={shareWith === 'private'}>
                  {text.PRIVATE}
                </Radio>
                <Space size={16} />
                <Radio value="custom" checked={shareWith === 'custom'}>
                  {text.CUSTOM}
                </Radio>
              </Radio.Group>
              <div
                className={classNames('fiq-save-edit-filter__share', {
                  'fiq-save-edit-filter__share--visible': shareWith === 'custom',
                })}
              >
                <Space size={16} />
                <div>
                  {shareWith === 'custom'
                    && formattedFields.map(({ id, ...props }) => (
                      <Field {...props} id={id} key={id} formId="savedFilterForm" />
                    ))}
                </div>
              </div>
            </div>
          </Card>
        </Fragment>
      )}
      <Loader visible={loadingFilter || loadingFields} />
    </Sidepanel>
  );
};

SaveEditFilter.propTypes = propTypes;
SaveEditFilter.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  resetFieldsData: stores.forms.savedFilterForm.resetFieldsData,
  setFieldsData: stores.forms.savedFilterForm.setFieldsData,
  formData: stores.forms.savedFilterForm.data,
  updateFieldValue: stores.forms.savedFilterForm.updateFieldValue,
  validateForm: stores.forms.savedFilterForm.validateForm,
}))(observer(SaveEditFilter));

function formatFormData(data, shareWith) {
  return Object.keys(data).reduce((acc, key) => {
    if (key === 'teams' || key === 'roles' || key === 'users') {
      return {
        ...acc,
        ...(data[key].length
          && shareWith === 'custom' && { [key]: data[key].map(record => record.value) }),
      };
    }
    return { ...acc, [key]: data[key] };
  }, {});
}

function hasSharedData(fields, savedFilter, shareWith) {
  if (shareWith === 'custom' || !savedFilter) return false;
  return (
    !isEmpty(fields)
    && (savedFilter.teams.length || savedFilter.roles.length || savedFilter.users.length)
  );
}
