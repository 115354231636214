import http from 'App/services/http';

const ManageUsersServiceFactory = (endpoint) => {
  const addBindings = (id, users) => http.post(`/${endpoint}/${id}/bindings/`, users);

  const removeBindings = (id, users) => http.delete(`/${endpoint}/${id}/bindings/delete/`, { data: users });

  const removeAllBindings = id => http.delete(`/${endpoint}/${id}/bindings/delete-all/`);

  const fetchUserBindings = (id, params) => http.get(`/${endpoint}/${id}/bindings/`, { params });

  return {
    addBindings,
    removeBindings,
    removeAllBindings,
    fetchUserBindings,
  };
};

export default ManageUsersServiceFactory;
