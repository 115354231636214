import LogCall from './LogCall';
import actionTypes from '../types';

const text = {
  LABEL: 'Manually log',
};

const ACTION_ICON = 'Plus';

export default {
  name: 'ManuallyLog',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.TOP,
  dropdownActions: comboxManager => [
    {
      icon: 'Phone',
      label: 'Log a call',
      onClick: () => {
        const comboxOptions = {
          title: text.LABEL,
          id: 'log-call',
        };
        comboxManager.add(LogCall, comboxOptions);
      },
    },
  ],
  handler: null,
};
