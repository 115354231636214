import compose from 'lodash/fp/compose';
import moment from 'moment';

import extractNestedFields from 'App/services/utilities/extractNestedFields';

const formatFields = fields => Object.keys(fields)
  .map(key => ({
    ...fields[key],
    id: key,
    name: key,
    validate: fields[key].required ? 'required' : null,
    label:
        fields[key].required && fields[key].label ? `${fields[key].label}*` : fields[key].label,
  }))
  .filter(field => field.name !== 'avatar');

const propResolver = fields => fields.map((field) => {
  switch (field.type) {
    case 'date':
    case 'datetime':
      return {
        ...field,
        disabled: field.read_only,
        date: moment().format('DD YY MMMM'),
      };
    case 'choice':
    case 'generic relation':
    case 'related field':
      return {
        ...field,
        disabled: field.read_only,
        options: field.choices,
        labelKey: 'display_name',
        valueKey: 'value',
      };
    case 'boolean':
      return {
        ...field,
        disabled: field.read_only,
        description: field.label,
        label: '',
        checked: false,
      };
    case 'integer':
      return {
        ...field,
        disabled: field.read_only,
        type: 'number',
      };
    case 'duration':
      return {
        ...field,
        disabled: field.read_only,
      };
    case 'query':
      return {
        ...field,
        disabled: field.read_only,
      };
    case 'float':
      return {
        ...field,
        disabled: field.read_only,
      };
    default:
      return { ...field, disabled: field.read_only, type: 'text' };
  }
});

const toObject = fields => fields.reduce((acc, field) => ({ ...acc, [field.id]: field }), {});

const fieldsResolver = compose(propResolver, formatFields, extractNestedFields);

const fieldsResolverObject = compose(toObject, propResolver, formatFields, extractNestedFields);

export { fieldsResolverObject };

export default fieldsResolver;
