import React from 'react';
import { string } from 'prop-types';
import { Icon, Space } from 'tc-biq-design-system';

import './NoData.scss';

const propTypes = {
  text: string,
  icon: string,
  iconColor: string,
};

const defaultProps = {
  text: 'No data',
  icon: 'Warning',
  iconColor: 'text-status03-400',
};

const NoData = ({ text, icon, iconColor }) => (
  <div className="fiq-no-data tc-paragraph-strong">
    <Icon colorName={iconColor} name={icon} size="large" />
    <Space size={8} />
    {text}
  </div>
);

NoData.propTypes = propTypes;
NoData.defaultProps = defaultProps;

export default NoData;
