import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'tc-biq-design-system';
import { func, object, bool, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { isEditState, states } from 'Marketing/utils/campaign';
import { stepKeys } from 'Marketing/pages/CampaignsSinglePage/CampaignsSinglePage';

const propTypes = {
  createCampaign: func.isRequired,
  updateCampaign: func.isRequired,
  requestInProgress: bool.isRequired,
  history: object.isRequired,
  match: object.isRequired,
  hasErrorsForStartCampaign: bool.isRequired,
  hasTemplateErrors: bool.isRequired,
  state: string,
  startCampaign: func.isRequired,
  validateForm: func.isRequired,
};

const defaultProps = {
  state: states.CREATE,
};

const text = {
  DISCARD: 'Discard',
  START_CAMPAIGN: 'Start campaign',
  SAVE_AS_DRAFT: 'Save as draft',
  NEXT: 'Next',
};

const CampaignFormFooter = ({
  createCampaign,
  updateCampaign,
  requestInProgress,
  history,
  match,
  hasErrorsForStartCampaign,
  hasTemplateErrors,
  state,
  startCampaign,
  validateForm,
}) => {
  const { campaignId } = match.params;
  const onDiscard = () => history.push('/marketing/campaigns');
  const onStart = async () => {
    if (isEditState(state)) {
      if (state === states.DETAILS) {
        const success = await updateCampaign(campaignId, true);
        if (success) {
          history.push(`/marketing/campaigns/${campaignId}/setup/${stepKeys.WORKFLOW}`);
        }
      } else {
        const success = await updateCampaign(campaignId, false);
        if (success) {
          await startCampaign({ campaignId, history });
        }
      }
    } else {
      if (!validateForm()) return;
      await createCampaign({ history });
    }
  };

  const saveAsDraft = () => {
    if (isEditState(state)) {
      updateCampaign(campaignId, true);
    }
  };

  return (
    <div className="fiq-campaign-form__footer">
      <Button onClick={onDiscard} color="ghost">
        {text.DISCARD}
      </Button>
      {state !== states.CREATE && (
        <div className="draft">
          <Button
            loading={requestInProgress}
            disabled={requestInProgress}
            onClick={saveAsDraft}
            color="ghost"
          >
            {text.SAVE_AS_DRAFT}
          </Button>
        </div>
      )}
      <Button
        disabled={requestInProgress || hasErrorsForStartCampaign || hasTemplateErrors}
        loading={requestInProgress}
        onClick={onStart}
      >
        {state === states.GOAL ? text.START_CAMPAIGN : text.NEXT}
      </Button>
    </div>
  );
};

CampaignFormFooter.propTypes = propTypes;
CampaignFormFooter.defaultProps = defaultProps;

export default inject(stores => ({
  createCampaign: stores.campaigns.createCampaign,
  updateCampaign: stores.campaigns.updateCampaign,
  startCampaign: stores.campaigns.startCampaign,
  hasErrorsForStartCampaign: stores.campaigns.hasErrorsForStartCampaign,
  hasTemplateErrors: !isEmpty(stores.forms.channelEmailForm.fieldErrors),
  requestInProgress: stores.campaigns.updateCreateInProgress,
  validateForm: stores.forms.campaignForm.validateForm,
}))(observer(withRouter(CampaignFormFooter)));
