import React, { useState, useCallback, FC } from 'react';
import { inject, observer } from 'mobx-react';
import { closeOverlay } from 'App/services/overlayService';
import { Button, Modal, InfoBox, Checkbox } from 'tc-biq-design-system';

import './ApiKeysModal.scss';
import { StoreInterface } from 'App/rootStore';

const MODAL_ID = 'API_KEYS_MODAL';

const text = {
  API_KEY_LABEL: 'API key',
  API_SECRET_LABEL: 'API secret',
  WARNING_HEADER: 'Warning: You will see the secret only once',
  WARNING_TEXT:
    'As a safety measure, we will not show this secret again.'
    + ' Make sure to save it in a secure location.',
  CONFIRM_TEXT: 'I have saved the key and secret',
  SUCCESS: 'API key created successfully',
  CLOSE: 'Close Window',
};

export type ApiKey = {
  key: string;
  secret: string;
};

export interface ApiKeysModalProps {
  resetPlainApiKey?: () => void;
  visible?: boolean;
  plainApiKey?: ApiKey;
}

const ApiKeysModal: FC<ApiKeysModalProps> = ({
  visible,
  plainApiKey,
  resetPlainApiKey,
}) => {
  const [checked, setChecked] = useState(false);
  const toggleCheck = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  const close = () => {
    if (resetPlainApiKey) resetPlainApiKey();
    setChecked(false);
    closeOverlay(MODAL_ID);
  };

  const ApiFooter = () => (
    <div>
      <Button
        color={!checked ? 'ghost' : 'primary'}
        disabled={!checked}
        onClick={close}
      >
        {text.CLOSE}
      </Button>
    </div>
  );

  return (
    <Modal
      visible={visible && !!plainApiKey}
      title={text.SUCCESS}
      icon="Approve"
      type="success"
      size="large"
      footerRender={ApiFooter}
      onCloseIconClick={close}
    >
      <InfoBox header={text.WARNING_HEADER} type="warning">
        {text.WARNING_TEXT}
      </InfoBox>
      <div>
        <div className="api-key__item">
          <small>{text.API_KEY_LABEL}</small>
          <div>{plainApiKey && plainApiKey.key}</div>
        </div>
        <div className="api-key__item">
          <small>{text.API_SECRET_LABEL}</small>
          <div>{plainApiKey && plainApiKey.secret}</div>
        </div>
      </div>
      <Checkbox onChange={toggleCheck} checked={checked}>
        <span>{text.CONFIRM_TEXT}</span>
      </Checkbox>
    </Modal>
  );
};

export default inject((stores: StoreInterface) => ({
  visible: stores.overlayStore.overlay[MODAL_ID],
  plainApiKey: stores.apiKeys.plainApiKey,
  resetPlainApiKey: stores.apiKeys.resetPlainApiKey,
}))(observer(ApiKeysModal));
