import http from 'App/services/http';

const toggleActiveState = (id, value) => http.patch(`/users/${id}/`, { is_active: value });

const checkEmailExists = payload => http.post('/users/check-email/', payload);

const inviteUsers = payload => http.post('/users/invite/', payload);

const userOptions = () => http.options('/users/');

const fetchUser = (id, params) => http.get(`/users/${id}/`, { params });

const searchUsers = params => http.get('/users/', { params });

const editUserDetails = (id, payload) => http.put(`users/${id}/`, payload);

const updateUser = (id, payload) => http.patch(`users/${id}/`, payload);

const deleteUser = id => http.delete(`/users/${id}/`);

// eslint-disable-next-line no-undef
const resendInvite = params => http.post('users/resend-invites/', { client_id: AUTH0_CLIENT_ID, ...params });

const fetchNotifications = () => http.get('/users/notifications/');

const uploadAvatar = (id, data) => {
  const payload = {};
  if (data.avatar) {
    payload.avatar = data.avatar;
  }

  const body = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    body.append(key, value);
  });
  const config = { headers: { 'content-type': 'multipart/form-data' } };

  return http.patch(`users/${id}/`, body, config);
};

// eslint-disable-next-line no-undef
const changePassword = () => http.post('/users/change-password/', { client_id: AUTH0_CLIENT_ID });

const fetchEmailInbox = () => http.get('/communications/email-inbox');

const deleteEmailInbox = id => http.delete(`/communications/email-inbox/${id}/`);

const revokeEmailInbox = id => http.patch(`/communications/email-inbox/${id}/revoke/`);

const addEmailInboxSignature = (id, signature) => http.patch(`/communications/email-inbox/${id}/`, { signature });

const connectEmailInbox = redirectUrl => http.get('/nylas/auth/', { params: { redirect_url: redirectUrl } });

const reconnectEmailInbox = (email, redirect_url) => http.get('/nylas/auth/', { params: { email, redirect_url } });

const bulkMoveToTeam = payload => http.post('/users/move-to-team/', payload);

const bulkAddCags = payload => http.post('/users/bulk-add-cag/', payload);

const bulkRemoveCags = payload => http.post('/users/bulk-remove-cags/', payload);

const bulkAddRoles = payload => http.post('/users/bulk-add-roles/', payload);

const bulkRemoveRoles = payload => http.post('/users/bulk-remove-roles/', payload);

const bulkArchive = payload => http.post('/users/bulk-archive/', payload);

export {
  toggleActiveState,
  checkEmailExists,
  inviteUsers,
  fetchUser,
  userOptions,
  editUserDetails,
  deleteUser,
  resendInvite,
  searchUsers,
  updateUser,
  uploadAvatar,
  changePassword,
  fetchEmailInbox,
  deleteEmailInbox,
  revokeEmailInbox,
  addEmailInboxSignature,
  connectEmailInbox,
  reconnectEmailInbox,
  fetchNotifications,
  bulkMoveToTeam,
  bulkAddCags,
  bulkRemoveCags,
  bulkAddRoles,
  bulkRemoveRoles,
  bulkArchive,
};
