import React from 'react';
import { InfoBox } from 'tc-biq-design-system';
import { string } from 'prop-types';

const propTypes = {
  label: string.isRequired,
  requiredPermissions: string.isRequired,
};

const text = {
  HEADER: 'No permission',
  NO_PERMISSION: (label, permissions) => `No permission for field "${label}". Required permisisons: "${permissions}"`,
};

const NoPermissionInfo = ({ label, requiredPermissions }) => (
  <InfoBox header={text.HEADER} type="error">
    {text.NO_PERMISSION(label, requiredPermissions)}
  </InfoBox>
);

NoPermissionInfo.propTypes = propTypes;

export default NoPermissionInfo;
