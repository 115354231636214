import React from 'react';
import { Button, Space } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object, func, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { hasAccess } from 'App/services/permissionsService';

import './RuleFooter.scss';

const propTypes = {
  match: object.isRequired,
  createRule: func.isRequired,
  history: object.isRequired,
  loading: bool.isRequired,
  updateRule: func.isRequired,
  resetRule: func.isRequired,
  validateForm: func.isRequired,
};

const text = {
  DISCARD: 'Discard',
  DISCARD_CHANGE: 'Discard changes',
  SAVE: 'Save changes',
  ADD: 'Create rule',
};

const RuleFooter = ({
  match, history, loading, createRule, updateRule, resetRule, validateForm }) => {
  const { ruleId, cloneId } = match.params;
  const isAdd = ruleId === 'create' || cloneId;
  const goToRulesTable = () => {
    history.push('/automation/rules');
    resetRule();
  };
  const addRule = () => validateForm() && createRule(history);
  const editRule = () => validateForm() && updateRule({ id: ruleId, history });
  const createPermission = hasAccess('automations_rule', 'create');
  const updatePermission = hasAccess('automations_rule', 'update');
  const hasPermission = isAdd ? createPermission : updatePermission;
  return (
    <div className="fiq-rule-footer">
      <Button onClick={goToRulesTable} color="ghost">
        {isAdd ? text.DISCARD : text.DISCARD_CHANGE}
      </Button>
      <Space size={4} />
      <Button disabled={!hasPermission} loading={loading} onClick={isAdd ? addRule : editRule}>
        {isAdd ? text.ADD : text.SAVE}
      </Button>
    </div>
  );
};

RuleFooter.propTypes = propTypes;

export default inject(stores => ({
  loading: stores.rules.requestInProgress.createRule,
  createRule: stores.rules.createRule,
  updateRule: stores.rules.updateRule,
  resetRule: stores.rules.resetRule,
  validateForm: stores.forms.ruleForm.validateForm,
}))(observer(withRouter(RuleFooter)));
