import React, { useEffect } from 'react';
import Page from 'App/components/Page';
import { withRouter } from 'react-router-dom';
import { object, func, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Col, Row, Space } from 'tc-biq-design-system';

import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import Loader from 'App/components/Loader';
import RuleTriggerQueryBuilder from 'Automation/components/RuleTriggerQueryBuilder';
import RuleActions from 'Automation/components/RuleActions';
import RuleFooter from 'Automation/components/RuleFooter';
import BlockTitle from 'App/components/BlockTitle';

import './RulesSinglePage.scss';

const propTypes = {
  match: object.isRequired,
  fetchRuleOptions: func.isRequired,
  fieldsDef: object.isRequired,
  loadingFields: bool.isRequired,
  loadingData: bool.isRequired,
  fetchRule: func.isRequired,
  resetRule: func.isRequired,
  disableRuleTrigger: bool.isRequired,
};

const text = {
  TITLE: 'Rules',
  CREATE_TITLE: 'Create a rule',
  EDIT_TITLE: 'Edit a rule',
  TRIGGER_TITLE: 'Set a rule trigger',
};

const bread = id => [
  { label: 'Automation' },
  { label: text.TITLE, route: '/automation/rules' },
  { label: id },
];

const toObject = (fields) => {
  const temp = {};
  fields.forEach((field) => {
    temp[field.id] = field;
  });
  return temp;
};

const RulesSinglePage = ({
  match,
  fetchRuleOptions,
  fieldsDef,
  loadingFields,
  loadingData,
  fetchRule,
  resetRule,
  disableRuleTrigger,
}) => {
  const { ruleId, cloneId } = match.params;
  const title = ruleId === 'create' || cloneId ? text.CREATE_TITLE : text.EDIT_TITLE;
  const fieldsProps = toObject(fieldsResolver(fieldsDef));
  const fetchData = async () => {
    await fetchRuleOptions();
    if (ruleId !== 'create') {
      await fetchRule(ruleId);
    }
  };

  useEffect(() => {
    fetchData();
  }, [ruleId]);

  useEffect(() => {
    resetRule();
  }, []);

  return (
    <Page bread={bread(ruleId)} title={title} goBackIcon={null} style={{ padding: '30px', overflow: 'auto' }}>
      <Loader visible={loadingFields || loadingData} />
      {!loadingFields && !loadingData && (
        <div className="fiq-rules-single-page">
          <div className="fiq-rules-single-page__name">
            <Field name="name" {...fieldsProps.name} formId="ruleForm" label="Rule name" />
          </div>
          <div className="fiq-rules-single-page__body">
            <Row gutter={0} style={{ minHeight: 'calc(100vh - 490px)' }}>
              <Col gutter={0} xs="68%">
                <div className="fiq-rules-single-page__trigger">
                  <BlockTitle title={text.TRIGGER_TITLE} icon="Clock" />
                  <Space size={32} />
                  <Field
                    name="trigger_event"
                    {...fieldsProps.trigger_event}
                    formId="ruleForm"
                    label=""
                    disabled={disableRuleTrigger}
                  />
                  <Space size={32} />
                  <RuleTriggerQueryBuilder />
                </div>
              </Col>
              <Col gutter={0} xs="2%">
                <div className="fiq-rules-single-page__separator" />
              </Col>
              <Col gutter={0} xs="30%">
                <RuleActions />
              </Col>
            </Row>
          </div>
          <RuleFooter />
        </div>
      )}
    </Page>
  );
};

RulesSinglePage.propTypes = propTypes;

export default inject(stores => ({
  fetchRuleOptions: stores.rules.fetchRuleOptions,
  fetchRule: stores.rules.fetchRule,
  fieldsDef: stores.rules.fieldsDef,
  resetRule: stores.rules.resetRule,
  loadingFields: stores.rules.requestInProgress.ruleOptions,
  loadingData: stores.rules.requestInProgress.fetchRule,
  disableRuleTrigger: stores.rules.disableRuleTrigger,
}))(observer(withRouter(RulesSinglePage)));
