import React from 'react';

import { StatusCell, WalletCell, RouteCell } from 'App/components/gridCellRenderers';
import hideCols from 'App/services/utilities/hideCols';

export default {
  ...hideCols(['contact']),
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/transactions/cards/'),
  },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => <WalletCell data={value} />,
  },
  status: {
    cellRendererFramework: StatusCell,
  },
  sortable: false,
};
