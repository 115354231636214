import React, { Fragment } from 'react';
import { HyperLink } from 'tc-biq-design-system';
import { Link } from 'react-router-dom';
import { object, number, string, array } from 'prop-types';
import moment from 'moment';
import compose from 'lodash/fp/compose';
import { groupBy } from 'lodash';

import routeObjectTypeEnum from 'App/enums/routeObjectTypeEnum';
import OverlayAction from 'ActivityStream/components/ActivityStreamBody/OverlayAction';

export { formatDate, formatTitle, formatByDate };

const dateGroupLabels = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
};

const formatDate = (dateGroup, published) => {
  const formatteDate = moment.utc(published).local();
  const date = Object.values(dateGroupLabels).includes(dateGroup)
    ? dateGroup
    : formatteDate.format('MMM Do YYYY');
  return `${date}, at ${formatteDate.format('HH:mm')}`;
};

const parseLinkObjects = stringValue => [...stringValue.matchAll(/(\[[\s\S][^\][]*?\]\(.*?\))/g)];

const formatLinkObjects = matchedValues => matchedValues.map(hit => ({
  displayName: hit[0].match(/\[([\s\S]*?)\]\(/)[1],
  type: hit[0].match(/\]\((.*?)\)/)[1].split('|')[0],
  id: hit[0].match(/\]\((.*?)\)/)[1].split('|')[1],
  startIndex: hit.index,
  holderString: hit[0],
}));

const extractLinkObjects = compose(formatLinkObjects, parseLinkObjects);

const overlayEnum = {
  // eslint-disable-next-line react/prop-types
  tasks_task: ({ linkObject }) => <OverlayAction linkObject={linkObject} />,
  // eslint-disable-next-line react/prop-types
  documents_document: ({ linkObject, contactId }) => (
    <OverlayAction linkObject={linkObject} contactId={contactId} />
  ),
  // eslint-disable-next-line react/prop-types
  events_activitycomment: ({ linkObject, contactId, activity }) => (
    <OverlayAction linkObject={linkObject} contactId={contactId} activity={activity} />
  ),
  // eslint-disable-next-line react/prop-types
  users_dataexport: ({ linkObject, history }) => (
    <OverlayAction linkObject={linkObject} history={history} />
  ),
};

const getActionType = (type) => {
  const singlePageroute = routeObjectTypeEnum[type] || null;
  const overlayAction = overlayEnum[type] || null;
  const isExternalUrl = type === 'url';
  return [singlePageroute, overlayAction, isExternalUrl];
};

const HyperLinkObject = ({ linkObject, contactId, activity }) => {
  const [singlePageRoute, overlayAction, isExternalUrl] = getActionType(linkObject.type);
  const route = typeof singlePageRoute === 'function'
    ? singlePageRoute(contactId || linkObject.id)
    : `${singlePageRoute}/${linkObject.id}/`;
  return (
    <HyperLink>
      {singlePageRoute && (
        <Link to={route}>
          <span data-recording-sensitive>{linkObject.displayName}</span>
        </Link>
      )}
      {overlayAction && overlayAction({ linkObject, contactId, activity })}
      {isExternalUrl && (
        <a data-recording-sensitive href={linkObject.id} target="_blank" rel="noopener noreferrer">
          {linkObject.displayName}
        </a>
      )}
      {!singlePageRoute && !overlayAction && !isExternalUrl && (
        <span data-recording-sensitive>{linkObject.displayName}</span>
      )}
    </HyperLink>
  );
};

HyperLinkObject.propTypes = {
  linkObject: object.isRequired,
  contactId: number,
  activity: object.isRequired,
};
HyperLinkObject.defaultProps = { contactId: null };

const RenderElement = ({ text, linkObjects, index, lastIndex, contactId, activity }) => {
  let linkObject = 0;
  if (text === '*' && linkObjects.length) {
    // eslint-disable-next-line
    linkObject = linkObjects.splice(0, 1)[0];
  }
  return (
    <Fragment>
      {index !== 0 && <span> </span>}
      {linkObject ? (
        <HyperLinkObject contactId={contactId} linkObject={linkObject} activity={activity} />
      ) : (
        <span>{text}</span>
      )}
      {index !== lastIndex && <span> </span>}
    </Fragment>
  );
};

RenderElement.propTypes = {
  text: string.isRequired,
  linkObjects: array.isRequired,
  index: number.isRequired,
  lastIndex: number.isRequired,
  contactId: number,
  activity: object.isRequired,
};
RenderElement.defaultProps = { contactId: null };

const TextlineToElement = ({ textLine, linkObjects, contactId, activity }) => {
  const stringArray = textLine.split(' ');
  return (
    <div>
      {stringArray.map((stringPiece, index) => (
        <RenderElement
          // eslint-disable-next-line
          key={`${stringPiece}-${index}`}
          text={stringPiece}
          index={index}
          contactId={contactId}
          linkObjects={linkObjects}
          lastIndex={stringArray.length - 1}
          activity={activity}
        />
      ))}
    </div>
  );
};

TextlineToElement.propTypes = {
  textLine: string.isRequired,
  linkObjects: array.isRequired,
  contactId: number,
  activity: object.isRequired,
};
TextlineToElement.defaultProps = { contactId: null };

const TitleBuilder = ({ stringText, objects, contactId, activity }) => {
  let cleanString = stringText;
  const linkObjects = [...objects];
  linkObjects.forEach(({ holderString }) => {
    cleanString = cleanString.replace(holderString, '*');
  });
  const TextLines = cleanString.split('\n');
  return (
    <span className="tc-paragraph-regular">
      {TextLines.map(textLine => (
        <TextlineToElement
          contactId={contactId}
          key={textLine}
          textLine={textLine}
          linkObjects={linkObjects}
          activity={activity}
        />
      ))}
    </span>
  );
};

TitleBuilder.propTypes = {
  stringText: string.isRequired,
  objects: array.isRequired,
  contactId: number,
  activity: object.isRequired,
};
TitleBuilder.defaultProps = { contactId: null };

const formatTitle = (activity) => {
  const regex = RegExp(/(\[[\s\S][^\][]*?\]\(.*?\))/, 'g');
  const { contact } = activity;
  const id = contact ? contact.id : null;
  let contentString = activity.content;
  if (contentString.includes('↵')) {
    contentString = contentString.split('↵').join('\n');
  }
  if (regex.test(contentString)) {
    const linkObjects = extractLinkObjects(contentString);
    return (
      <TitleBuilder
        activity={activity}
        contactId={id}
        stringText={activity.content}
        objects={linkObjects}
      />
    );
  }
  return activity.content;
};

export const formatDateGroupLabel = (recordDate) => {
  const currentWeek = moment().format('w');
  const lastWeek = moment().subtract(1, 'week').format('w');
  const today = moment().format('DD MM YYYY');
  const yesterday = moment().subtract(1, 'days').format('DD MM YYYY');

  const isSameYear = recordDate.format('YYYY') === moment().format('YYYY');

  if (recordDate.format('DD MM YYYY') === today) return dateGroupLabels.TODAY;
  if (recordDate.format('DD MM YYYY') === yesterday) return dateGroupLabels.YESTERDAY;
  if (recordDate.format('w') === currentWeek) return recordDate.format('dddd');
  if (recordDate.format('w') === lastWeek) return 'Last week';
  if (recordDate.format('MM') === moment().format('MM')) return recordDate.format('MMMM Do');
  if (recordDate.format('MM') < moment().format('MM') && isSameYear) return recordDate.format('MMMM');
  return recordDate.format('MMMM YYYY');
};

// eslint-disable-next-line max-len
const formatByDate = records => groupBy(records, record => formatDateGroupLabel(moment.utc(record.timestamp).local()));
