import React, { Component } from 'react';
import { notifier } from 'tc-biq-design-system';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import withFilters from 'App/components/hoc/withFilters';

import SegmentList from 'Dashboard/components/layouts/SegmentList';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import { fetchSegmentsByIds } from 'Automation/services/segmentsService';
import run from 'App/services/utilities/run';

const text = {
  TITLE: 'Segments - high exit rate',
  DESCRIPTION: 'Segments with highest exit rate in selected time period',
  ERROR_MESSAGE: 'Something went wrong. Please contact admin.',
  TABLE_TITLE: 'Exit Count',
};

const METRIC = 'segment_exit_rates';

const propTypes = {
  history: object.isRequired,
};

const defaultProps = {};

class SegmentsExitRateList extends Component {
  constructor() {
    super();
    this.state = {
      fetchInProgress: false,
      data: [],
    };
  }

    onQueryReady = async (query) => {
      const { start_date, end_date } = query;

      this.setState({
        fetchInProgress: true,
      });

      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: start_date,
          end_of_interval: end_date,
          granularity: 'all',
          dimensions: ['segment_id'],
          limit: 5,
          order_by: ['-count'],
        },
      };

      const [err, metricData] = await run(fetchMetricsData(formattedQuery));

      if (err) {
        notifier.error(text.ERROR_MESSAGE);
      }

      const segmentIDs = metricData.map(e => e.segment_id);

      if (isEmpty(segmentIDs)) {
        this.setState({
          fetchInProgress: false,
          data: [],
        });
        return;
      }

      const [segmentError, { results }] = await run(fetchSegmentsByIds(segmentIDs));

      if (segmentError) {
        notifier.error(text.ERROR_MESSAGE);
        return;
      }

      const data = metricData.map(item => ({
        segment: results.find(segment => segment.id === item.segment_id),
        value: item.count,
      }));

      this.setState({
        fetchInProgress: false,
        data,
      });
    }

    handleCardClick = () => {
      const { history } = this.props;
      history.push('/automation/segments');
    }

    render() {
      const { fetchInProgress, data } = this.state;

      return (
        <SegmentList
          title={text.TITLE}
          description={text.DESCRIPTION}
          data={data}
          loading={fetchInProgress}
          onClick={this.handleCardClick}
          tableTitle={text.TABLE_TITLE}
        />
      );
    }
}

SegmentsExitRateList.propTypes = propTypes;
SegmentsExitRateList.defaultProps = defaultProps;

export default {
  component: withRouter(withFilters(SegmentsExitRateList)),
};
