import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { array, func, object, bool } from 'prop-types';
import { Space } from 'tc-biq-design-system';
import classNames from 'classnames';

import AddMore from './AddMore';

const propTypes = {
  eventTypes: array.isRequired,
  fetchActivities: func.isRequired,
  addTypeFilter: func.isRequired,
  activeType: object,
  defaultEvents: array.isRequired,
  clearHighlights: func.isRequired,
};

const defaultProps = {
  activeType: null,
};

const text = {
  SHOWING: 'Showing:',
  ALL: 'All',
};

const EventPill = ({ active, onClick, event }) => (
  <div
    onClick={() => onClick(event)}
    className={classNames('fiq-activity-stream__event-type-pill', 'tc-paragraph-regular', {
      'fiq-activity-stream__event-type-pill--active': active,
      'text-neutral-50': active,
    })}
  >
    <span className={classNames({ 'text-primary-500': !active, 'tc-paragraph-strong': !active })}>
      {event.displayName}
    </span>
  </div>
);

EventPill.propTypes = {
  active: bool,
  onClick: func.isRequired,
  event: object.isRequired,
};
EventPill.defaultProps = { active: false };

const ActivityStreamTypeFilter = inject(stores => ({
  defaultEvents: stores.activityStream.defaultEvents,
  activeType: stores.activityStream.type,
  eventTypes: stores.activityStream.eventTypes,
  addTypeFilter: stores.activityStream.addTypeFilter,
  fetchActivities: stores.activityStream.fetchActivities,
  clearHighlights: stores.activityStream.clearHighlights,
}))(observer(
  class ActivityStreamTypeFilter extends Component {
    componentWillUnmount() {
      const { addTypeFilter } = this.props;
      addTypeFilter();
    }

    applyTypeFilter = (activeType) => {
      const { addTypeFilter, clearHighlights } = this.props;
      clearHighlights();
      addTypeFilter(activeType);
      this.fetchActivities();
    };

    fetchActivities = async () => {
      const { fetchActivities } = this.props;
      await fetchActivities();
    };

    render() {
      const { eventTypes, activeType, defaultEvents } = this.props;
      const otherEvents = eventTypes.filter(event => !defaultEvents.includes(event.value));
      return (
        <div className="fiq-activity-stream__type-filters">
          <span>{text.SHOWING}</span>
          <Space size={20} />
          <EventPill
            active={!activeType || !activeType.value}
            onClick={this.applyTypeFilter}
            event={{ displayName: text.ALL, value: null }}
          />
          {eventTypes.length
            && defaultEvents.map((event_name) => {
              const event = eventTypes.find(e => e.value === event_name);
              return (
                <Fragment key={event.value}>
                  <Space size={16} />
                  <EventPill
                    active={activeType && activeType.value === event.value}
                    onClick={this.applyTypeFilter}
                    event={event}
                  />
                </Fragment>
              );
            })}
          {activeType && activeType.value && !defaultEvents.includes(activeType.value) && (
            <Fragment>
              <Space size={16} />
              <EventPill active onClick={this.applyTypeFilter} event={activeType} />
            </Fragment>
          )}
          <Space size={20} />
          <AddMore onClick={this.applyTypeFilter} events={otherEvents} />
        </div>
      );
    }
  },
));


ActivityStreamTypeFilter.wrappedComponent.propTypes = propTypes;
ActivityStreamTypeFilter.wrappedComponent.defaultProps = defaultProps;

export default ActivityStreamTypeFilter;
