import React from 'react';
import UserDetails from './UserDetails';

export default {
  label: 'Details',
  id: 'my-details',
  Component: () => <UserDetails />,
  permission: 'users_user',
  visibility: () => true,
};
