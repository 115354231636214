import { observable, action, runInAction, computed, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import { isEmpty, omit, pick } from 'lodash';
import moment from 'moment';
import run from 'App/services/utilities/run';

import channelEnum from 'App/enums/channelEnum';
import {
  fetchCampaignData,
  createCampaign,
  updateCampaign,
  fetchCampaignsOptions,
  sendTestEmail,
  startCampaign,
  fetchJourneyStats,
} from 'Marketing/services/CampaignService';
import stores from 'App/rootStore';
import { fetchEventDefinitionData } from 'Settings/Sections/Events/services/EventsService';
import {
  formatEventLabel,
  formatEventNestedKeys,
} from 'App/components/QueryBuilderFactory/queryBuilderStoreUtils';
import formatPayload from 'App/services/utilities/formatPayload';
import formatQueryRulesArrayValues from 'App/services/utilities/formatQueryRulesArrayValues';
import fetchContactQueryBuilderMetadata from 'App/services/utilities/fetchContactQueryBuilderMetadata';
import displayError from 'App/services/utilities/displayError';

const text = {
  FETCH_CAMPAIGN_FAILED: 'Failed to fetch campaign',
  UPDATE_SUCCESS: 'Successfully updated campaign',
  UPDATE_FAILED: 'Failed to update campaign',
  CREATE_SUCCESS: 'Successfully created campaign',
  CREATE_DRAFT_SUCCESS: 'Campaign created as draft',
  CREATE_FAILED: 'Failed to create campaign',
  OPTIONS_FAILED: 'Failed to get fields data',
  TEST_MESSAGE_SUCCESS: 'Test message sent. Please check your inbox',
  TEST_MESSAGE_FAILED: 'Failed to send test email',
  START_SUCCESS: 'Successfully started campaign',
  START_FAILED: 'Failed to start campaign',
  EMAIL_CONTENT_REQUIRED: "It's required to design the template, in order to create campaign",
};

export const SEND_TO_TRIGGER_TYPES = ['Contacts who enter', 'Contacts who exit'];

export default class CampaignsStore {
  constructor(sendToQueryBuilderStore, goalQueryBuilderStore, workflowQueryBuilderStore) {
    makeObservable(this, {
      campaign: observable,
      journeyStats: observable,
      fieldsDef: observable,
      workflow: observable,
      requestInProgress: observable,
      errors: observable,
      isRecurringEnabled: observable,
      isEndingCampaign: observable,
      fetchCampaignData: action.bound,
      updateCampaign: action.bound,
      createCampaign: action.bound,
      startCampaign: action.bound,
      fetchCampaignsOptions: action.bound,
      fetchSendToQueryBuilderMetadata: action.bound,
      fetchEventMetadata: action.bound,
      fetchJourneyStats: action.bound,
      sendTestEmail: action.bound,
      toggleRecurringCampaign: action.bound,
      toggleEndingCampaign: action.bound,
      setWorkflow: action.bound,
      resetForm: action.bound,
      resetCampaign: action.bound,
      updateCreateInProgress: computed,
      hasErrorsForStartCampaign: computed,
    });

    this.sendToQueryBuilder = sendToQueryBuilderStore;
    this.goalQueryBuilder = goalQueryBuilderStore;
    this.workflowQueryBuilder = workflowQueryBuilderStore;
  }

  campaign = {};

  journeyStats = {};

  fieldsDef = {};

  workflow = {};

  requestInProgress = {
    fetchCampaign: false,
    updateCampaign: false,
    createCampaign: false,
    deleteCampaign: false,
    startCampaign: false,
    eventMetadata: false,
    contactPayloadMetadata: false,
    fetchOptions: false,
    sendTestEmail: false,
    fetchJourneyStats: false,
  };

  errors = {};

  isRecurringEnabled = false;

  isEndingCampaign = false;

  async fetchCampaignData(id) {
    this.requestInProgress.fetchCampaign = true;
    const { setFieldsData } = stores.forms.campaignForm;
    try {
      const response = await fetchCampaignData(id);
      runInAction(() => {
        this.campaign = response.data;
        this.workflow = response.data.workflow;
      });
      setFieldsData(formatCampaignFormData(this.campaign));
      if (this.campaign.recurring_type) {
        this.toggleRecurringCampaign(true);
      }
      if (
        this.campaign.end_datetime
        && hasEnding(this.campaign.send_to_type, this.isRecurringEnabled)
      ) {
        this.toggleEndingCampaign(true);
      }
    } catch {
      notifier.error(text.FETCH_CAMPAIGN_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchCampaign = false;
      });
    }
  }

  async updateCampaign(id, showSuccessMessage) {
    this.requestInProgress.updateCampaign = true;
    const payload = formatCampaignPayload(
      this.sendToQueryBuilder.queries,
      this.goalQueryBuilder.queries,
      this.isRecurringEnabled,
      this.isEndingCampaign,
    );
    try {
      const { data: campaign } = await updateCampaign(id, payload);
      if (showSuccessMessage) {
        notifier.success(text.UPDATE_SUCCESS);
      }
      runInAction(() => {
        this.campaign = {
          ...this.campaign,
          ...campaign,
        };
      });
      return true;
    } catch (e) {
      if (e?.response?.data) {
        setErrors(e.response.data);
        displayError(e.response.data);
      } else {
        notifier.error(text.UPDATE_FAILED);
      }
      return false;
    } finally {
      runInAction(() => {
        this.requestInProgress.updateCampaign = false;
      });
    }
  }

  async createCampaign({ history }) {
    this.requestInProgress.createCampaign = true;
    const payload = formatCampaignPayload(
      this.sendToQueryBuilder.queries,
      this.goalQueryBuilder.queries,
      this.isRecurringEnabled,
      this.isEndingCampaign,
    );
    try {
      const response = await createCampaign(payload);
      notifier.success(text.CREATE_SUCCESS);
      history.push(`/marketing/campaigns/${response.data.id}/setup`);
    } catch (e) {
      if (e?.response?.data) {
        setErrors(e.response.data);
        displayError(e.response.data);
      }
      notifier.error(text.CREATE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.createCampaign = false;
      });
    }
  }

  async startCampaign({ history, campaignId }) {
    this.requestInProgress.startCampaign = true;
    try {
      await startCampaign(campaignId);
      notifier.success(text.START_SUCCESS);
      history.push('/marketing/campaigns');
    } catch (e) {
      if (e?.response?.data) {
        setErrors(e.response.data);
        displayError(e.response.data);
      } else {
        notifier.error(text.START_FAILED);
      }
    } finally {
      runInAction(() => {
        this.requestInProgress.startCampaign = false;
      });
    }
  }

  async fetchCampaignsOptions() {
    this.requestInProgress.fetchOptions = true;
    try {
      const response = await fetchCampaignsOptions();
      runInAction(() => {
        this.fieldsDef = response.data.actions.POST;
      });
    } catch {
      notifier.error(text.OPTIONS_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchOptions = false;
      });
    }
  }

  async fetchSendToQueryBuilderMetadata() {
    this.requestInProgress.contactPayloadMetadata = true;
    try {
      const { fields, fieldsMetadata } = await fetchContactQueryBuilderMetadata();
      runInAction(() => {
        this.sendToQueryBuilder.setFields(fields);
        this.sendToQueryBuilder.setFieldsMetadata(fieldsMetadata);
      });
    } catch (e) {
      runInAction(() => {
        this.errors.contactPayloadMetadata = e.data;
      });
    } finally {
      const { send_to_query } = this.campaign;
      if (send_to_query) {
        this.sendToQueryBuilder.setQueries(send_to_query);
      }
      runInAction(() => {
        this.requestInProgress.contactPayloadMetadata = false;
      });
    }
  }

  async fetchEventMetadata(triggerEventId) {
    this.requestInProgress.eventMetadata = true;
    try {
      const response = await fetchEventDefinitionData(triggerEventId);
      const { payload, old_payload } = response.data.template_properties;
      runInAction(() => {
        this.goalQueryBuilder.setFields({});
        this.goalQueryBuilder.setFieldsMetadata(
          formatEventNestedKeys({
            payload,
            old_payload,
          }),
          formatEventLabel,
        );
      });
    } catch (e) {
      runInAction(() => {
        this.errors.eventMetadata = e.data;
      });
    } finally {
      const { goal_event_type, goal_query } = this.campaign;
      if (goal_event_type && goal_event_type.id === triggerEventId && goal_query) {
        this.goalQueryBuilder.setQueries(goal_query);
      } else if (goal_event_type && goal_event_type.id !== triggerEventId) {
        this.goalQueryBuilder.resetQueries();
      }
      runInAction(() => {
        this.requestInProgress.eventMetadata = false;
      });
    }
  }

  async fetchJourneyStats(id) {
    this.requestInProgress.fetchJourneyStats = true;
    const [err, data] = await run(fetchJourneyStats(id));

    if (err) {
      runInAction(() => {
        this.errors.fetchJourneyStats = err;
        this.requestInProgress.fetchJourneyStats = false;
      });
      return;
    }

    runInAction(() => {
      this.journeyStats = data;
      this.requestInProgress.fetchJourneyStats = false;
    });
  }

  async sendTestEmail(content) {
    this.requestInProgress.sendTestEmail = true;
    const { channel } = stores.forms.campaignForm.data;
    const { resetFieldsData } = stores.forms.sendTestMailForm;
    const mail = stores.forms.sendTestMailForm.data.reply_to_email;
    const { message_body } = stores.forms.channelMessengerForm.data;
    const { data } = stores.forms.channelEmailForm;
    const formatedData = {
      body: content,
      from_email: data.from_email,
      reply_to_email: mail,
      subject: data.subject,
    };

    const payload = {
      channel: channel.value,
      ...(channel.value === channelEnum.EMAIL && { email_message: { ...formatedData } }),
      ...(channel.value !== channelEnum.EMAIL && { messanger_message: { message_body } }),
    };
    try {
      await sendTestEmail(payload);
      notifier.info(text.TEST_MESSAGE_SUCCESS);
    } catch {
      notifier.error(text.TEST_MESSAGE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.sendToEmail = false;
      });
      resetFieldsData();
    }
  }

  toggleRecurringCampaign(value) {
    this.isRecurringEnabled = value;
    if (!value && this.isEndingCampaign) {
      this.toggleEndingCampaign(false);
    }
  }

  toggleEndingCampaign(value) {
    this.isEndingCampaign = value;
  }

  setWorkflow(workflow) {
    this.workflow = workflow;
  }

  resetForm() {
    stores.forms.channelEmailForm.resetFieldsData();
    stores.forms.campaignForm.resetFieldsData();
    stores.forms.channelMessengerForm.resetFieldsData();
    this.resetCampaign();
    this.goalQueryBuilder.resetQueries();
    this.sendToQueryBuilder.resetQueries();
    this.fieldsDef = {};
    this.isRecurringEnabled = false;
    this.isEndingCampaign = false;
  }

  resetCampaign() {
    this.campaign = {};
    this.workflow = {};
  }

  get updateCreateInProgress() {
    return this.requestInProgress.createCampaign || this.requestInProgress.updateCampaign;
  }

  // eslint-disable-next-line class-methods-use-this
  get hasErrorsForStartCampaign() {
    return (
      !isEmpty(stores.forms.campaignForm.fieldErrors)
      || !isEmpty(stores.forms.channelMessengerForm.fieldErrors)
      || (this.isRecurringEnabled && isEmpty(stores.forms.campaignForm.data.recurring_type))
      || (this.isEndingCampaign && isEmpty(stores.forms.campaignForm.data.end_datetime))
    );
  }
}

function formatEndDatetime(value) {
  return value ? moment.utc(value, moment.ISO_8601).format('YYYY-MM-DD HH:mm') : value;
}

function formatStartDatetime(value) {
  return moment(value, 'YYYY-MM-DD', true).isValid()
    ? moment.utc(value).utc()
    : moment(value, moment.ISO_8601).utc();
}

function formatCampaignPayload(sendToQuery, goalQuery, isRecurring, isEnding) {
  const { data } = stores.forms.campaignForm;
  const { start_datetime, end_datetime, recurring_time, ...payload } = isRecurring
    ? data
    : Object.keys(data).reduce((acc, k) => {
      acc[k] = k.includes('recurring') ? null : data[k];
      return acc;
    }, {});

  return {
    ...formatPayload(payload),
    goal_query: !isEmpty(goalQuery.rules) ? formatQueryRulesArrayValues(goalQuery) : null,
    recurring_time: recurring_time ? moment(recurring_time, 'HH:mm').utc().format('HH:mm') : null,
    send_to_query: !isEmpty(sendToQuery.rules) ? formatQueryRulesArrayValues(sendToQuery) : null,
    end_datetime: isEnding ? formatEndDatetime(end_datetime) : null,
    start_datetime: start_datetime ? formatStartDatetime(start_datetime) : null,
  };
}

function setErrors(errorData) {
  const { setFieldsErrors, resetFieldError } = stores.forms.campaignForm;
  const setTemplateErrors = stores.forms.channelEmailForm.setFieldsErrors;
  const fieldErrors = omit(errorData, [
    'non_field_errors',
    'email_from',
    'reply_to',
    'subject',
    'template',
    'goal_query',
    'send_to_query',
  ]);
  const templateErrors = pick(errorData, 'template');
  setFieldsErrors(fieldErrors);
  setTemplateErrors(templateErrors);
  if (errorData.email_message) {
    resetFieldError('email_message');
    stores.forms.channelEmailForm.setFieldsErrors(errorData.email_message);
  }
  if (errorData.messanger_message) {
    resetFieldError('messanger_message');
    stores.forms.channelMessengerForm.setFieldsErrors(errorData.messanger_message);
  }
}

// eslint-disable-next-line
const hasEnding = (sendToType, isRecurringCampaign) => SEND_TO_TRIGGER_TYPES.includes(sendToType) || isRecurringCampaign;

function formatCampaignFormData(data) {
  const { send_to_type, send_to_segment, goal_event_type, recurring_time } = data;
  return {
    ...data,
    recurring_time: moment.utc(recurring_time, 'HH:mm').local().format('HH:mm'),
    send_to_type: {
      value: send_to_type,
      display_name: send_to_type,
    },
    send_to_segment: {
      value: send_to_segment?.id,
      display_name: send_to_segment?.name,
    },
    goal_event_type: {
      ...goal_event_type,
      value: goal_event_type?.id,
      display_name: goal_event_type?.name,
    },
  };
}

export { hasEnding };
