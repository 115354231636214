import G6 from '@antv/g6';

import { drawPlusIcon } from '../utils';
import { ADD_BLOCK_STATE } from '../consts';

const stateStyles = {
  [ADD_BLOCK_STATE.SELECTED]: {
    'plus-shape-background': {
      opacity: 1,
    },
  },
};

G6.registerNode(
  'workflowAddNode',
  {
    afterDraw(_, group) {
      drawPlusIcon(group, 0, 0);
    },
    options: {
      stateStyles: {
        ...stateStyles,
      },
    },
  },
  'circle',
);
