import React, { useState, useEffect } from 'react';
import { Select, Space } from 'tc-biq-design-system';
import { func, string, any, array, oneOfType, object, number } from 'prop-types';
import { omit, isEmpty } from 'lodash';

import loadOptions from 'App/services/utilities/loadOptions';
import setInitialValue from 'App/services/utilities/selectFieldSetInitalValue';
import getRelatedModels from 'App/services/utilities/getRelatedModels';
import NoPermissionInfo from 'App/components/NoPermissionInfo';

const propTypes = {
  onChange: func.isRequired,
  name: string.isRequired,
  options: array,
  value: oneOfType([object, string, array, number]),
  type: string.isRequired,
  related_model: object,
  related_models: any,
  valueFormatter: func,
  afterFieldSpaceSize: number,
  filterParams: object,
};

const defaultProps = {
  value: null,
  options: [],
  related_model: null,
  related_models: null,
  valueFormatter: null,
  afterFieldSpaceSize: 18,
  filterParams: {},
};

const searchableSelectInputs = ['related field', 'generic relation'];

export const DEBOUNCE_VALUE = 500;

const SelectField = (props) => {
  const {
    onChange,
    name,
    options,
    value,
    type,
    related_model,
    related_models,
    valueFormatter,
    afterFieldSpaceSize,
    filterParams,
  } = props;
  const [initialValue, updateInitialValue] = useState(null);
  const [noPermission, setNoPermission] = useState(false);
  const fieldProps = options
    ? omit(props, ['read_only', 'formId', 'afterFieldSpaceSize'])
    : omit(props, ['read_only', 'formId', 'afterFieldSpaceSize', 'options']);
  const selectType = searchableSelectInputs.includes(type) ? 'search' : type;
  const relatedModels = getRelatedModels(related_models, related_model);
  const onError = () => setNoPermission(true);
  // eslint-disable-next-line max-len
  const asyncProps = relatedModels
    ? { async: true, loadOptions: loadOptions(filterParams, relatedModels, onError) }
    : {};
  const onSelected = (fieldValue) => {
    updateInitialValue(fieldValue);
    onChange(name, fieldValue);
  };

  useEffect(() => { updateInitialValue(value); }, [value]);

  useEffect(() => {
    if (Number.isInteger(value) || !isEmpty(value)) {
      setInitialValue({
        value,
        options,
        relatedModels,
        valueFormatter,
        updateInitialValue: onSelected,
        type,
      });
    }
  }, []);

  if (noPermission) {
    const requiredPermissions = relatedModels.map(model => model.display_name).join(', ');
    return (
      <NoPermissionInfo
        label={fieldProps.label || fieldProps.fieldLabel}
        requiredPermissions={requiredPermissions}
      />
    );
  }

  return (
    <div data-cy={name} data-recording-ignore="mask">
      <Select
        dataRecording
        joinValues
        {...asyncProps}
        {...fieldProps}
        type={fieldProps.many ? 'multi' : selectType}
        value={initialValue || value}
        onChange={onSelected}
        debounceInterval={DEBOUNCE_VALUE}
      />
      <Space size={afterFieldSpaceSize} />
    </div>
  );
};

SelectField.propTypes = propTypes;
SelectField.defaultProps = defaultProps;

export default SelectField;
