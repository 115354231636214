import React, { PureComponent, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { object, func, array, bool, string } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Col, Table, Space, HyperLink, Icon, Button } from 'tc-biq-design-system';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Page from 'App/components/Page';
import Loader from 'App/components/Loader';
import { ContactCell, DateTimeCell, AmountCell } from 'App/components/gridCellRenderers';
import { openOverlay } from 'App/services/overlayService';
import PreviewMetaData, { SIDEPANEL_ID } from 'App/components/CommonOverlays/PreviewMetaData';

import './WalletSinglePage.scss';
import moment from 'moment';
import If from 'App/components/If/index';
import { hasAccess } from 'App/services/permissionsService';
import RenderIcon from '../../../App/components/RenderIcon/RenderIcon';

const propTypes = {
  match: object.isRequired,
  fetchWalletData: func.isRequired,
  walletData: object.isRequired,
  ledgerData: array.isRequired,
  hasMore: bool.isRequired,
  requestInProgress: bool.isRequired,
  fetchLedgerData: func.isRequired,
  resetData: func.isRequired,
};

const bread = id => [
  { label: 'Transactions' },
  { label: 'Wallets', route: '/transactions/wallets' },
  { label: id, route: `/transactions/wallets/${id}` },
];

const text = {
  AVAILABLE: 'Available',
  RESERVED: 'Reserved',
  DEBT: 'Debt',
  TOTAL: 'Total',
  LOADING: 'Loading...',
  WALLET: 'Wallet',
  VIEW_METADATA: 'View Metadata',
  METADATA: 'Metadata',
};

const directionTypeMap = {
  In: {
    color: 'text-status01-500',
    icon: 'Deposit',
  },
  Out: {
    color: 'text-status04-500',
    icon: 'Withdrawal',
  },
  'In Reservation': {
    color: 'text-status01-500',
    icon: 'Deposit',
  },
  'Out Reservation': {
    color: 'text-status02-500',
    icon: 'Pending',
  },
};

const transactionObjectType = {
  finances_paymenttransaction: {
    route: '/transactions/payments',
    displayNameChoices: {
      'In Transaction': 'Deposit',
      'Out Transaction': 'Withdrawal',
      'Out Fee': 'Payment fee',
      'In Fee': 'Payment fee',
      'In Reservation': 'Payment reservation release',
      'Out Reservation': 'Payment reservation',
      'In Margin': 'Payment margin transaction',
      'Out Margin': 'Payment margin transaction',
    },
    defaultDisplayName: 'Payment',
  },
  finances_tradetransaction: {
    route: '/transactions/trades',
    displayNameChoices: {
      'In Transaction': 'Trade transaction',
      'Out Transaction': 'Trade transaction',
      'Out Fee': 'Trade fee',
      'In Fee': 'Trade fee',
      'In Reservation': 'Trade transaction',
      'Out Reservation': 'Trade transaction',
    },
    defaultDisplayName: 'Trade',
  },
  finances_tradeorder: {
    route: '/transactions/orders',
    displayNameChoices: {
      'In Transaction': 'Order transaction',
      'Out Transaction': 'Order transaction',
      'Out Fee': 'Order fee',
      'In Fee': 'Order fee',
      'In Reservation': 'Order reservation release',
      'Out Reservation': 'Order reservation',
      'In Margin': 'Order margin transaction',
      'Out Margin': 'Order margin transaction',
    },
    defaultDisplayName: 'Order',
  },
  finances_tradeposition: {
    route: '/transactions/positions',
    displayNameChoices: {
      'In Transaction': 'Position profit',
      'Out Transaction': 'Position profit',
      'Out Fee': 'Position fee',
      'In Fee': 'Position fee',
      'In Reservation': 'Position',
      'Out Reservation': 'Position',
    },
    defaultDisplayName: 'Position',
  },
};

const capitalize = value => (value ? value.charAt(0).toUpperCase() + value.slice(1) : null);

const RelatedTransaction = ({ transaction, direction, reference }) => {
  if (!transaction && !reference) {
    return null;
  }

  if (!transaction) {
    return capitalize(reference);
  }

  const { id, object_type } = transaction;
  const { route, displayNameChoices, defaultDisplayName } = transactionObjectType[object_type];

  const displayName = displayNameChoices[direction] || defaultDisplayName;

  if (route) {
    return (
      <HyperLink>
        <Link to={`${route}/${id}`}>{displayName}</Link>
      </HyperLink>
    );
  }

  return <span>{displayName}</span>;
};
RelatedTransaction.propTypes = {
  transaction: object.isRequired,
  direction: string.isRequired,
  reference: string.isRequired,
};

const TransactionIcon = ({ color, icon }) => (
  <div
    className={classNames('fiq-wallet-single__transaction__icon', {
      in: icon === 'Deposit',
      out: icon === 'Withdrawal',
      reservation: icon === 'Pending',
    })}
  >
    <Icon colorName={color} name={icon} />
  </div>
);
TransactionIcon.propTypes = { color: string.isRequired, icon: string.isRequired };

const renderType = (data) => {
  const type = data.direction.includes('Reservation')
    ? directionTypeMap[data.direction]
    : directionTypeMap[data.direction.includes('In') ? 'In' : 'Out'];
  return (
    <div className="fiq-wallet-single__transaction">
      <TransactionIcon color={type.color} icon={type.icon} />
      <Space size={8} />
      <RelatedTransaction
        transaction={data.related_transaction}
        direction={data.direction}
        reference={data?.metadata?.reference}
      />
    </div>
  );
};

const renderContext = (metadata) => {
  if (!metadata) {
    return null;
  }

  const { meta, reference } = metadata;

  if (!reference) {
    return null;
  }

  if (!meta) {
    return reference;
  }

  const valueOrDash = value => value || '-';
  const valueOrEmpty = value => value || '';

  switch (reference) {
    case 'order':
      return `${valueOrDash(meta.baseInstrument?.name)} order at price ${valueOrDash(meta.price)}`;
    case 'payment':
      return `${valueOrEmpty(meta.paymentMethod)} ${valueOrDash(meta.paymentType)}`.trim();
    case 'cardPayment':
      return `${valueOrEmpty(meta.merchantName)} ${valueOrDash(
        meta.transactionType?.description,
      )}`.trim();
    case 'conversion':
      return `${valueOrDash(meta.fromCurrencyId)} to ${valueOrDash(
        meta.toCurrenctId,
      )} conversion at ${valueOrDash(meta.conversionRate)}`;
    case 'compoundCharge':
      return 'Margin interest charge';
    case 'delisting':
      return `${valueOrDash(meta.symbolName)} delisting automatic sale at ${valueOrDash(
        meta.price,
      )}`;
    case 'dividend':
      return `${valueOrDash(meta.dividendInstrument?.name)} dividend payout at ${valueOrDash(
        meta.payoutAmount,
      )}`;
    case 'split':
      return `${valueOrDash(meta.baseInstrument?.name)} ${valueOrDash(meta.splitRate)} split`;
    default:
      return reference;
  }
};

const renderMetadata = metadata => (metadata ? (
  <Button
    color="transparent"
    onClick={() => openOverlay(SIDEPANEL_ID, {
      data: metadata.meta ? metadata.meta : metadata,
      title: text.METADATA,
    })
      }
  >
    {text.VIEW_METADATA}
  </Button>
) : null);

const cols = [
  { title: 'Type', key: 'related_transaction', render: renderType },
  {
    title: 'Context',
    key: 'context',
    render: ({ metadata }) => capitalize(renderContext(metadata)),
  },
  // eslint-disable-next-line
  { title: 'Amount', key: 'value', render: ({ value }) => <AmountCell value={value} /> },
  {
    title: 'Created at',
    key: 'created',
    // eslint-disable-next-line
    render: ({ created }) => <DateTimeCell value={created} />,
  },
  {
    title: 'Metadata',
    key: 'metadata',
    // eslint-disable-next-line
    render: ({ metadata }) => renderMetadata(metadata),
  },
];

// eslint-disable-next-line
const WalletDetail = ({ asset, created, available, reserved, total, debt }) => {
  const date = moment(created, moment.ISO_8601).format('YYYY-MM-DD \\• HH:mm:ss');
  return (
    <Fragment>
      <div className="fiq-wallet-single__detail">
        <div
          className={classNames('fiq-wallet-single__icon', {
            [`asset-${asset}`]: asset,
          })}
        >
          <RenderIcon type="custom" iconSize={20} icon={asset} />
        </div>
        <Space size={12} />
        <div className="fiq-wallet-single__column">
          <div className="tc-paragraph-strong">{asset}</div>
          <div style={{ whiteSpace: 'nowrap' }} className="tc-paragraph-regular text-neutral-500">
            {date}
          </div>
        </div>
      </div>
      <div className="fiq-wallet-single__balance">
        <BalanceDetail label={text.TOTAL} value={total} />
        <BalanceDetail label={text.AVAILABLE} value={available} />
        <BalanceDetail label={text.RESERVED} value={reserved} />
        <BalanceDetail label={text.DEBT} value={debt} />
      </div>
    </Fragment>
  );
};

// eslint-disable-next-line
const BalanceDetail = ({ label, value }) => {
  return (
    <div className="fiq-wallet-single__header__column">
      <div className="tc-regular-strong">{value || '-'}</div>
      <Space size={4} />
      <div className="tc-micro-regular text-neutral-500">{label}</div>
    </div>
  );
};

class WalletSinglePage extends PureComponent {
  constructor(props) {
    super(props);
    this.id = props.match.params.walletId;
  }

  componentDidMount() {
    const { resetData } = this.props;
    resetData();
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.walletId !== match.params.walletId) {
      const { resetData } = this.props;
      resetData();
      this.id = match.params.walletId;
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { fetchWalletData, fetchLedgerData } = this.props;
    await fetchWalletData(this.id);
    if (hasAccess('finances_ledgertransaction', 'read')) await fetchLedgerData(this.id);
  };

  fetchOnScroll = async () => {
    const { fetchLedgerData } = this.props;
    await fetchLedgerData(this.id, true);
  };

  render() {
    const { walletData, ledgerData, hasMore, requestInProgress } = this.props;
    if (isEmpty(walletData)) return <Loader visible />;
    const { contact, created, external_id, asset, available, reserved, total, debt } = walletData;
    return (
      <Page
        bread={bread(this.id)}
        title={`${text.WALLET} ${asset} ${external_id || ''}`}
        style={{ margin: '24px 20px 0 20px' }}
      >
        <div className="fiq-wallet-single__wrapper">
          <Col sm="100%" lg="90%">
            <div className="fiq-wallet-single">
              <div className="fiq-wallet-single__header">
                <ContactCell
                  id={contact.id}
                  fullName={`${contact.first_name} ${contact.last_name}`}
                  avatar={contact.avatar}
                />
                <WalletDetail
                  created={created}
                  asset={asset}
                  available={available}
                  reserved={reserved}
                  total={total}
                  debt={debt}
                />
              </div>
              <If condition={hasAccess('finances_ledgertransaction', 'read')}>
                <div className="fiq-wallet-single__body">
                  <div className="fiq-wallet-single__table">
                    <Table
                      striped
                      cols={cols}
                      data={ledgerData}
                      fixedHeader
                      infiniteScrollProps={{
                        fetchData: this.fetchOnScroll,
                        hasMore,
                        isLoading: requestInProgress,
                      }}
                    />
                    <PreviewMetaData />
                  </div>
                </div>
              </If>
            </div>
          </Col>
        </div>
      </Page>
    );
  }
}

// WalletSinglePage.wrappedComponent.propTypes = propTypes;

export default withRouter(inject(stores => ({
  fetchWalletData: stores.transactions.fetchWalletData,
  fetchLedgerData: stores.transactions.fetchLedgerData,
  walletData: stores.transactions.walletData,
  ledgerData: stores.transactions.ledgerData,
  requestInProgress: stores.transactions.requestInProgress.ledgerData,
  hasMore: stores.transactions.hasMore,
  resetData: stores.transactions.resetData,
}))(observer(WalletSinglePage)));
