import React from 'react';
import { inject, observer } from 'mobx-react';
import { object, func } from 'prop-types';
import { withRouter } from 'react-router-dom';

import LineChartLayout from 'Dashboard/components/layouts/LineChart';
import getGranularity from 'App/services/utilities/getGranularity';

const text = {
  TITLE: 'Interactions',
  DESCRIPTION:
    'Total number of communication interactions with contacts, in selected time period.',
};

const propTypes = {
  dashboardInterval: object,
  addNewFilter: func,
  history: object.isRequired,
  interactionsData: object,
};

const defaultProps = {
  dashboardInterval: {},
  addNewFilter: null,
  interactionsData: {},
};

const lines = [
  { type: 'monotone', dataKey: 'SMS', stroke: '#0579FC', strokeWidth: 2, dot: false, key: 'sms' },
  {
    type: 'monotone',
    dataKey: 'Emails',
    stroke: '#F5D556',
    strokeWidth: 2,
    dot: false,
    key: 'emails',
  },
  {
    type: 'monotone',
    dataKey: 'Calls',
    stroke: '#F76868',
    strokeWidth: 2,
    dot: false,
    key: 'calls',
  },
  {
    type: 'monotone',
    dataKey: 'WhatsApp',
    stroke: '#0EB55F',
    strokeWidth: 2,
    dot: false,
    key: 'whatsapp',
  },
];

const formatData = (data, granularity) => {
  const object_b = Object.keys(data).reduce((acc, metric) => {
    const values = data[metric];
    values.forEach((item) => {
      acc[item[granularity]] = acc[item[granularity]] || {
        Calls: 0,
        Emails: 0,
        SMS: 0,
        WhatsApp: 0,
      };
      acc[item[granularity]][metric] += item.count;
    });
    return acc;
  }, {});
  const result = Object.keys(object_b)
    .sort()
    .reduce((acc, key) => {
      const item = object_b[key];
      acc.push({
        [granularity]: key,
        ...item,
      });
      return acc;
    }, []);
  return result;
};

const filters = (startDate, endDate) => [
  {
    field: { label: 'Created', value: 'created', type: 'datetime' },
    ...(startDate === endDate
      ? { operator: { label: 'on', value: 'date', related_models: null } }
      : { operator: { label: 'between', value: 'range', related_models: null } }),
    ...(startDate === endDate
      ? { value: startDate }
      : { value: [`${startDate}T00:00:00Z`, `${endDate}T23:59:59Z`] }),
  },
];

const InteractionsChart = ({ addNewFilter, history, dashboardInterval, interactionsData }) => {
  const handleCardClick = () => {
    const { startDate, endDate } = dashboardInterval;
    filters(startDate, endDate).forEach(addNewFilter);
    history.push('/communications/email-logs');
  };
  const { startDate, endDate } = dashboardInterval;
  const granularity = getGranularity(startDate, endDate);
  const data = formatData(interactionsData, granularity);

  return (
    <LineChartLayout
      title={text.TITLE}
      description={text.DESCRIPTION}
      dimensions={[]}
      inProgress={false}
      data={data}
      xAxisKey={granularity}
      handleCardClick={handleCardClick}
      lines={lines}
    />
  );
};

InteractionsChart.propTypes = propTypes;
InteractionsChart.defaultProps = defaultProps;

export default {
  component: inject(stores => ({
    dashboardInterval: stores.dashboardStore.dashboardInterval,
    addNewFilter: stores.tables.contacts.filters.addNewFilter,
    interactionsData: stores.dashboardStore.interactionsData,
  }))(observer(withRouter(InteractionsChart))),
};
