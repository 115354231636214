import React, { useEffect, useRef, useState } from 'react';
import { Space, Button, Icon } from 'tc-biq-design-system';
import { func, bool } from 'prop-types';
import classNames from 'classnames';
import Field from 'App/components/FieldRenderer';

const propTypes = {
  closeDropdown: func.isRequired,
  hidden: bool.isRequired,
  onChange: func,
};

const defaultProps = {
  onChange: null,
};

const text = {
  PLACEHOLDER: 'Select property',
  ADD_CUSTOM_PROPERTY: 'Add custom property',
  ADD: 'Add',
};

function isReactSelect(element) {
  if (!element) return false;
  const isCloseIcon = element.classList.contains('Select-value-icon');
  const isReactSelectEl = element.id.indexOf('react-select') !== -1;
  if (isReactSelectEl || isCloseIcon) {
    return true;
  }
  return false;
}

const AddCustomPropertyDropdown = ({
  closeDropdown,
  hidden,
  onChange,
}) => {
  const dropdownRef = useRef(null);
  const [customProperty, setCustomProperty] = useState(null);

  const handleClick = ({ target }) => {
    if (dropdownRef.current?.contains(target) || isReactSelect(target)) return;
    close();
  };

  const close = () => {
    setCustomProperty(null);
    closeDropdown();
  };

  const handleOnChange = (name, value) => {
    setCustomProperty(value);
  };

  const add = () => {
    onChange(customProperty);
    closeDropdown();
  };

  useEffect(() => {
    if (hidden) {
      setCustomProperty(null);
      document.removeEventListener('mousedown', handleClick, false);
    } else {
      document.addEventListener('mousedown', handleClick, false);
    }
  }, [hidden]);

  return (
    <div className="fiq-add-custom-property__wrapper">
      <div
        ref={dropdownRef}
        className={classNames('fiq-add-custom-property__dropdown', {
          hidden,
        })}
      >
        <div className="fiq-add-custom-property__heading">
          <span className="tc-paragraph-strong">{text.ADD_CUSTOM_PROPERTY}</span>
          <span onClick={close}>
            <Icon name="Close" size="small" />
          </span>
        </div>
        {!hidden && (
        <Field
          type="related field"
          labelKey="label"
          valueKey="value"
          id="custom-properties"
          name="custom-properties"
          placeholder={text.PLACEHOLDER}
          related_model={{
            display_name: 'custom-properties',
            value: 'custom_properties',
          }}
          value={customProperty}
          onChange={handleOnChange}
        />
        )}
        <Space size={12} />
        <Button style={{ margin: '0' }} onClick={add}>
          {text.ADD}
        </Button>
        <Space size={12} />
      </div>
    </div>
  );
};

AddCustomPropertyDropdown.propTypes = propTypes;
AddCustomPropertyDropdown.defaultProps = defaultProps;

export default AddCustomPropertyDropdown;
