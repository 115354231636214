import React, { Fragment, useEffect } from 'react';
import { Sidepanel, Space, Button, notifier } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { any, func, object } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { createTrackingLink, editTrackingLink } from 'Marketing/services/TrackingLinksService';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';

const propTypes = {
  visible: any,
  parameters: object,
  fetchTrackingLinkFields: func.isRequired,
  setTrackingLinkData: func.isRequired,
  trackingLinksFields: object.isRequired,
  formData: object.isRequired,
  onSuccess: func.isRequired,
  setFieldsErrors: func.isRequired,
  resetFieldsData: func.isRequired,
  resetFields: func.isRequired,
  trackingLink: object.isRequired,
  validateForm: func.isRequired,
};

const defaultProps = { visible: null, parameters: {} };

const SIDEPANEL_ID = 'CREATE_EDIT_TRACKING_LINK';

const text = {
  DISCARD: 'Discard',
  EDIT_TRACKING_LINK: 'Save changes',
  CREATE_TRACKING_LINK: 'Create tracking link',
  SUCCESS_EDIT: 'Successfully edited tracking link',
  SUCCESS_CREATE: 'Successfully created tracking link',
  AUDIENCE: 'Audience',
};

const formatBody = (data) => {
  const { audience } = data;
  return {
    ...data,
    ...(audience && { audience: audience.id }),
  };
};

export const openCreateEditTrackingLink = (
  trackingLinkId,
  isClone,
) => openOverlay(SIDEPANEL_ID, { trackingLinkId, isClone });
export const closeCreateEditTrackingLink = () => closeOverlay(SIDEPANEL_ID);

const CustomFooter = inject(stores => ({
  hasErrors: !isEmpty(stores.forms.editUser.fieldErrors),
}))(
  observer(({ execute, cancel, hasErrors, isEdit }) => (
    <Fragment>
      <Button color="ghost" onClick={cancel}>
        {text.DISCARD}
      </Button>
      <Button disabled={hasErrors} onClick={execute}>
        {isEdit ? text.EDIT_TRACKING_LINK : text.CREATE_TRACKING_LINK}
      </Button>
    </Fragment>
  )),
);

const CreateEditTrackingLink = ({
  visible,
  trackingLinksFields,
  parameters,
  setTrackingLinkData,
  fetchTrackingLinkFields,
  setFieldsErrors,
  onSuccess,
  resetFields,
  resetFieldsData,
  formData,
  trackingLink,
  validateForm,
}) => {
  const close = () => {
    resetFields();
    resetFieldsData();
    closeCreateEditTrackingLink();
  };

  const edit = async () => {
    if (!validateForm()) return;
    try {
      await editTrackingLink(trackingLinkId, formatBody(formData));
      close();
      notifier.success(text.SUCCESS_EDIT);
      await onSuccess();
    } catch (err) {
      if (err.response && err.response.data) setFieldsErrors(err.response.data);
    }
  };

  const create = async () => {
    if (!validateForm()) return;
    try {
      await createTrackingLink(formatBody(formData));
      close();
      await onSuccess();
      notifier.success(text.SUCCESS_CREATE);
    } catch (err) {
      if (err.response && err.response.data) setFieldsErrors(err.response.data);
    }
  };

  const formattedFields = isEmpty(trackingLinksFields) ? [] : fieldsResolver(trackingLinksFields);
  const { isClone, trackingLinkId } = parameters;
  const isEdit = !!trackingLinkId && !isClone;

  useEffect(() => {
    if (isEmpty(trackingLinksFields)) {
      fetchTrackingLinkFields();
    }

    if (isEdit || isClone) {
      setTrackingLinkData(trackingLinkId, isClone);
    }
  }, [isEdit, isClone, trackingLinkId]);

  const title = (isEdit && `Edit ${trackingLink.name}`)
    || (isClone && `Clone of ${trackingLink.name}`)
    || text.CREATE_TRACKING_LINK;

  return (
    <Sidepanel
      title={title}
      type="info"
      icon="Pen"
      visible={visible}
      onCloseIconClick={close}
      footerRender={() => (
        <CustomFooter isEdit={isEdit} execute={isEdit ? edit : create} cancel={close} />
      )}
    >
      <Space size={18} />
      {formattedFields
        .filter(f => !f.read_only)
        .map(({ id, read_only, ...props }) => (
          <Field {...props} disabled={read_only} key={id} formId="createEditTrackingLink" />
        ))}
    </Sidepanel>
  );
};

CreateEditTrackingLink.propTypes = propTypes;
CreateEditTrackingLink.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  fetchTrackingLinkFields: stores.trackingLinks.fetchTrackingLinkFields,
  setTrackingLinkData: stores.trackingLinks.setTrackingLinkData,
  trackingLinksFields: stores.trackingLinks.trackingLinksFields,
  setFieldsErrors: stores.forms.createEditTrackingLink.setFieldsErrors,
  resetFieldsData: stores.forms.createEditTrackingLink.resetFieldsData,
  resetFields: stores.trackingLinks.resetTrackingLinksFields,
  formData: stores.forms.createEditTrackingLink.data,
  trackingLink: stores.trackingLinks.trackingLink,
  validateForm: stores.forms.createEditTrackingLink.validateForm,
}))(observer(CreateEditTrackingLink));
