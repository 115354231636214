import { observable, action, runInAction, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import displayError from 'App/services/utilities/displayError';
import { isEmpty } from 'lodash';

import {
  fetchTemplate,
  createTemplate,
  updateTemplate,
  fetchTemplatesOptions,
} from 'Communications/services/CommunicationsService';
import { sendTestEmail } from 'Marketing/services/CampaignService';
import stores from 'App/rootStore';

const text = {
  FETCH_TEMPLATE_FAILED: 'Failed to fetch template',
  UPDATE_SUCCESS: 'Successfully updated template',
  UPDATE_FAILED: 'Failed to update template',
  CREATE_SUCCESS: 'Successfully created template',
  CREATE_DRAFT_SUCCESS: 'Template created as draft',
  CREATE_FAILED: 'Failed to create template',
  OPTIONS_FAILED: 'Failed to get fields data',
  TEST_MESSAGE_SUCCESS: 'Test message sent. Please check your inbox',
  TEST_MESSAGE_FAILED: 'Failed to send test email',
  START_SUCCESS: 'Successfully started template',
  START_FAILED: 'Failed to start template',
  CONTENT_REQUIRED: 'It’s required to design the template content, in order to create it',
};

const TEMPLATE_TRANSACTION_TYPE = 'Transactional';

export default class TemplatesStore {
  constructor(queryBuilderStore) {
    makeObservable(this, {
      template: observable,
      fieldsDef: observable,
      requestInProgress: observable,
      errors: observable,
      fetchTemplateData: action.bound,
      fetchTemplatesOptions: action.bound,
      createTemplate: action.bound,
      updateTemplate: action.bound,
      sendTestEmail: action.bound,
      resetForm: action.bound,
    });

    this.queryBuilder = queryBuilderStore;
  }

  template = {};

  fieldsDef = {};

  requestInProgress = {
    fetchTemplate: false,
    fetchOptions: false,
    updateCreateTemplate: false,
  };

  errors = {};

  async fetchTemplateData(id, cloneId) {
    this.requestInProgress.fetchTemplate = true;
    const { setFieldsData } = stores.forms.templateForm;
    try {
      const { data } = await fetchTemplate(id);
      runInAction(() => {
        this.template = data;
      });
      if (cloneId) {
        setFieldsData(formatTemplateFormData({ ...data, name: `Copy of ${data.name}` }));
      } else {
        setFieldsData(formatTemplateFormData(data));
      }
    } catch {
      notifier.error(text.FETCH_TEMPLATE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchTemplate = false;
      });
    }
  }

  async fetchTemplatesOptions() {
    this.requestInProgress.fetchOptions = true;
    try {
      const response = await fetchTemplatesOptions();
      runInAction(() => {
        this.fieldsDef = response.data.actions.POST;
      });
    } catch {
      notifier.error(text.OPTIONS_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchOptions = false;
      });
    }
  }

  async createTemplate({ history }) {
    this.requestInProgress.updateCreateInProgress = true;
    const { data, setFieldsErrors } = stores.forms.templateForm;
    if (isEmpty(data.content)) {
      notifier.error(text.CONTENT_REQUIRED);
      return;
    }
    try {
      await createTemplate(formatTemplatePayload(data));
      notifier.success(text.CREATE_SUCCESS);
      history.push('/communications/templates');
    } catch (e) {
      if (e?.response?.data) {
        setFieldsErrors(e.response.data);
        displayError(e.response.data);
      } else {
        notifier.error(text.CREATE_FAILED);
      }
    } finally {
      runInAction(() => {
        this.requestInProgress.updateCreateInProgress = false;
      });
    }
  }

  async updateTemplate(templateId, { history }) {
    this.requestInProgress.updateCreateInProgress = true;
    const { data, setFieldsErrors } = stores.forms.templateForm;
    if (isEmpty(data.content)) {
      notifier.error(text.CONTENT_REQUIRED);
      return;
    }
    try {
      await updateTemplate(formatTemplatePayload(data), templateId);
      notifier.success(text.UPDATE_SUCCESS);
      history.push('/communications/templates');
    } catch (e) {
      if (e?.response?.data) {
        setFieldsErrors(e.response.data);
        displayError(e.response.data);
      } else {
        notifier.error(text.CREATE_FAILED);
      }
    } finally {
      runInAction(() => {
        this.requestInProgress.updateCreateInProgress = false;
      });
    }
  }

  async sendTestEmail(content) {
    this.requestInProgress.sendTestEmail = true;
    const { channel } = stores.forms.templateForm.data;
    const { resetFieldsData } = stores.forms.sendTestMailForm;
    const mail = stores.forms.sendTestMailForm.data.reply_to_email;
    const { data } = stores.forms.templateForm;
    const formatedData = {
      body: content,
      from_email: data.email_from,
      reply_to_email: mail,
      subject: data.subject,
    };
    const payload = {
      channel: channel.value,
      ...(channel.value === 'Email' && { email_message: { ...formatedData } }),
    };
    try {
      await sendTestEmail(payload);
      notifier.info(text.TEST_MESSAGE_SUCCESS);
    } catch {
      notifier.error(text.TEST_MESSAGE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.sendToEmail = false;
      });
      resetFieldsData();
    }
  }

  resetForm() {
    const { resetFieldsData } = stores.forms.templateForm;
    resetFieldsData();
    this.template = {};
    this.queryBuilder.resetQueries();
    this.fieldsDef = {};
  }
}

const formatTemplatePayload = data => ({
  ...data,
  channel: data?.channel?.value,
  event_type:
    data?.type?.value === TEMPLATE_TRANSACTION_TYPE && data?.event_type ? data.event_type.id : null,
  type: data?.type?.value,
  is_active: !!data?.is_active,
});

function formatTemplateFormData(data) {
  const { channel, event_type } = data;
  return {
    ...data,
    channel: { value: channel, display_name: channel },
    ...(event_type && {
      event_type: { ...event_type, value: event_type.id, display_name: event_type.name },
    }),
  };
}
