import React from 'react';
import EmailIntegrations from './EmailIntegrations';

export default {
  label: 'Email integrations',
  id: 'email-integrations',
  Component: () => <EmailIntegrations />,
  permission: 'users_user',
  visibility: ({ userId, loggedUserId }) => userId === loggedUserId,
};
