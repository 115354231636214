import cloneDeep from 'lodash/cloneDeep';

const updateNestedValue = (values, index, key, value) => {
  const newValues = cloneDeep(values);
  const fieldRow = newValues[index];
  if (fieldRow) {
    fieldRow[key] = value;
  } else {
    newValues.push({
      [key]: value,
    });
  }

  return newValues;
};

export const updateNestedError = (errors, index, key, value) => {
  const newErrors = cloneDeep(errors);
  const fieldRow = newErrors[index];
  if (fieldRow) {
    fieldRow[key] = value;
  } else {
    newErrors.push({
      [key]: value,
    });
  }

  return newErrors;
};

export default updateNestedValue;
