/* eslint-disable max-len */
import { observable, action, runInAction, computed, makeObservable } from 'mobx';

import isEmpty from 'lodash/isEmpty';
import {
  fetchCagData,
  addContactAccessGroup,
  deleteContactAccessGroup,
  editContactAccessGroup,
} from 'Settings/Sections/ContactAccessGroup/services/ContactAccessGroupService';
import formatQueryRulesArrayValues from 'App/services/utilities/formatQueryRulesArrayValues';
import { notifier } from 'tc-biq-design-system';
import fetchContactQueryBuilderMetadata from 'App/services/utilities/fetchContactQueryBuilderMetadata';
import {
  formatEventLabel,
  formatEventNestedKeys,
  queryFlattener,
} from 'App/components/QueryBuilderFactory/queryBuilderStoreUtils';

export default class ContactAccessGroupStore {
  constructor(queryBuilderStore, manageUsersStore) {
    makeObservable(this, {
      requestInProgress: observable,
      errors: observable,
      cagData: observable,
      fields: observable,
      cagName: observable,
      cagManagerVisiblity: observable,
      fetchQueryBuilderMetadata: action.bound,
      fetchCagData: action.bound,
      updateCagName: action.bound,
      updateCagManagerVisibility: action.bound,
      addEditContactAccessGroup: action.bound,
      removeContactAccessGroup: action.bound,
      resetCagData: action.bound,
      hasErrors: computed,
    });
    this.queryBuilder = queryBuilderStore;
    this.manageUsers = manageUsersStore;
  }

  requestInProgress = {
    cagData: false,
    contactAccessGroupAction: false,
  };

  errors = {
    cagData: null,
    cagName: null,
    contactAccessGroupAction: null,
  };

  // CAG Single Page observables
  cagData = {};

  fields = {};

  cagName = '';

  cagManagerVisiblity = false;

  // CAG Actions

  async fetchQueryBuilderMetadata() {
    this.requestInProgress.cagData = true;
    try {
      const { fields, fieldsMetadata } = await fetchContactQueryBuilderMetadata();
      runInAction(() => {
        this.fields = fields;
        this.queryBuilder.setFields(queryFlattener(fields));
        this.queryBuilder.setFieldsMetadata(
          formatEventNestedKeys(fieldsMetadata),
          formatEventLabel,
        );
        this.requestInProgress.cagData = false;
      });
    } catch (e) {
      runInAction(() => {
        this.requestInProgress.cagData = false;
      });
    }
  }

  async fetchCagData(id) {
    this.requestInProgress.cagData = true;
    try {
      const response = await fetchCagData(id);
      const { query, name, is_accessible_to_managers_only } = response.data;
      runInAction(() => {
        this.cagManagerVisiblity = is_accessible_to_managers_only;
        this.cagData = response.data;
        if (query) {
          this.queryBuilder.setQueries(query);
        }
        this.cagName = name;
        this.requestInProgress.cagData = false;
      });
    } catch (e) {
      runInAction(() => {
        this.errors.cagData = e.response.data;
        this.requestInProgress.cagData = false;
      });
    }
  }

  updateCagName({ target }) {
    this.errors.cagName = null;
    this.cagName = target.value;
  }

  updateCagManagerVisibility({ target }) {
    this.cagManagerVisiblity = target.checked;
  }

  async addEditContactAccessGroup(id, history) {
    this.requestInProgress.contactAccessGroupAction = true;
    const payload = {
      name: this.cagName,
      query: !isEmpty(this.queryBuilder.queries.rules)
        ? formatQueryRulesArrayValues(this.queryBuilder.queries)
        : null,
      is_accessible_to_managers_only: this.cagManagerVisiblity,
    };
    try {
      if (id) {
        await editContactAccessGroup(id, payload);
      } else {
        await addContactAccessGroup(payload);
      }
      runInAction(() => {
        this.requestInProgress.contactAccessGroupAction = false;
      });
      history.push('/settings/contact-access-groups');
    } catch (err) {
      runInAction(() => {
        if (err.response && err.response.data.detail) {
          notifier.error(err.response.data.detail);
        } else {
          const { data } = err.response;
          if (data && data.name) {
            const [error] = data.name;
            this.errors.cagName = error;
          }
        }
        this.requestInProgress.contactAccessGroupAction = false;
      });
    }
  }

  async removeContactAccessGroup(id) {
    this.errors.contactAccessGroupAction = null;
    this.requestInProgress.contactAccessGroupAction = true;
    try {
      await deleteContactAccessGroup(id);
      runInAction(() => {
        this.requestInProgress.contactAccessGroupAction = false;
      });
    } catch (e) {
      runInAction(() => {
        this.errors.contactAccessGroupAction = e.response.data;
        this.requestInProgress.contactAccessGroupAction = false;
      });
    }
  }

  resetCagData() {
    this.queryBuilder.setQueries({ condition: 'AND', rules: [] });
    this.cagName = '';
    this.cagData = {};
    this.cagManagerVisiblity = false;
    this.errors.cagName = null;
    this.errors.contactAccessGroupAction = null;
  }

  get hasErrors() {
    return this.errors.contactAccessGroupAction || this.errors.cagName;
  }
}
