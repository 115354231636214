import { bool, func, number, string } from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Notification, Row } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';

import AppHeader from 'App/components/AppHeader';
import CommonOverlays from 'App/components/CommonOverlays';
import { newPusher } from 'App/services/pusherService';
import MainRouter from './MainRouter';
import MainSidebar from './MainSidebar';

import './Main.scss';

const propTypes = {
  hasNavItems: bool.isRequired,
  homePage: string.isRequired,
  userId: number.isRequired,
  getSocketNotifications: func.isRequired,
  addSocketNotificationId: func.isRequired,
  count: number.isRequired,
  getToken: func,
};

const defaultProps = {
  getToken: null,
};

const MainLayout = ({
  hasNavItems,
  homePage,
  userId,
  getSocketNotifications,
  addSocketNotificationId,
  count,
  getToken,
}) => {
  const [pusher, setPusher] = useState({ Pusher: null, Channel: null });

  useEffect(() => {
    const fetchPusher = async () => {
      const token = await getToken();
      const Pusher = await newPusher(token);
      const Channel = Pusher.subscribe(`private-notification-${userId}`);
      Channel.bind('new-notification', (data) => {
        addSocketNotificationId(data.id);
      });
      setPusher({ Pusher, Channel });
    };
    if (getToken && !pusher.Channel) fetchPusher();
    return () => {
      if (pusher.Channel) pusher.Channel.unbind();
      if (pusher.Pusher) pusher.Pusher.unsubscribe(pusher.Channel.name);
    };
  }, [getToken]);

  return (
    <Fragment>
      {hasNavItems && (
        <div className="fiq__main-page tc-paragraph-regular">
          <div className="fiq__main-page__sidebar">
            <MainSidebar />
          </div>
          <div className="fiq__main-page__content">
            <AppHeader getSocketNotifications={getSocketNotifications} count={count} />
            <Row gutter={0}>
              <Col gutter={0}>
                <MainRouter homePage={homePage} />
              </Col>
            </Row>
          </div>
          <Notification transition="slide" duration={4} />
          <CommonOverlays />
        </div>
      )}
    </Fragment>
  );
};

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

export default inject(stores => ({
  hasNavItems: stores.navigator.hasNavItems,
  homePage: stores.navigator.homeRoute,
  userId: stores.loginStore.user.id,
  getToken: stores.loginStore.getToken,
  getSocketNotifications: stores.userNotifications.getSocketNotifications,
  count: stores.userNotifications.socketNotificationsCount,
  addSocketNotificationId: stores.userNotifications.addSocketNotificationId,
}))(observer(MainLayout));
