import http from 'App/services/http';


const fetchTasksData = query => http.get('/tasks/', { params: query });

const fetchSingleTask = id => http.get(`/tasks/${id}/`);

const fetchTasksOptionData = () => http.options('/tasks/');

const fetchAutocompleteChoices = (url, value) => http.get(`${url}?search=${value}`);

const addTask = payload => http.post('/tasks/', payload);

const editTask = (id, payload) => http.patch(`/tasks/${id}/`, payload);

const deleteTask = id => http.delete(`/tasks/${id}/`);

export {
  fetchTasksData,
  fetchSingleTask,
  fetchTasksOptionData,
  fetchAutocompleteChoices,
  addTask,
  editTask,
  deleteTask,
};
