import React from 'react';
import { Sidepanel, Space } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import { convertToRaw } from 'draft-js';

import { closeOverlay } from 'App/services/overlayService';
import SidepanelFooter from 'App/components/SidepanelFooter';
import Field from 'App/components/FieldRenderer';
import { getEmptyState, stateFromHtml } from 'App/components/Editor';
import convertRawValueToHtml from 'App/services/utilities/draftJsUtils';
import InboxInfo from 'Settings/Sections/Users/components/UserSections/EmailIntegrations/InboxInfo';

export const SIDEPANEL_ID = 'ADD_SIGNATURE';

const text = {
  ADD_SIGNATURE: 'Add signature',
  EDIT_SIGNATURE: 'Edit signature',
  TYPE_SIGNATURE: 'Type the signature content...',
};

const BUTTON_LABELS = {
  confirm: 'Add signature',
  cancel: 'Discard',
};

const customFooter = (execute, close, submitInProgress) => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={BUTTON_LABELS}
  />
);

const AddSignature = inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  updateFieldValue: stores.forms.addEmailInboxSignature.updateFieldValue,
  data: stores.forms.addEmailInboxSignature.data,
  addSignature: stores.usersStore.addEmailInboxSignature,
  requestInProgress: stores.usersStore.requestInProgress.addSignature,
}))(
  observer(({ visible, parameters, updateFieldValue, data, addSignature, requestInProgress }) => {
    if (isEmpty(parameters)) return null;

    const { id, signature, email, provider } = parameters;
    updateFieldValue('signature', (signature && stateFromHtml(signature)) || getEmptyState());

    const closeSidepanel = () => closeOverlay(SIDEPANEL_ID);
    const submit = async () => {
      const signatureContent = data.signature.getCurrentContent();
      // eslint-disable-next-line
      const htmlSignature =
        (signatureContent.hasText() && convertRawValueToHtml(convertToRaw(signatureContent)))
        || null;
      await addSignature(id, htmlSignature);
      closeSidepanel();
    };

    return (
      <Sidepanel
        title={(signature && text.EDIT_SIGNATURE) || text.ADD_SIGNATURE}
        visible={visible}
        icon={(signature && 'Edit') || 'Plus'}
        onCloseIconClick={() => closeOverlay(SIDEPANEL_ID)}
        footerRender={() => customFooter(submit, closeSidepanel, requestInProgress)}
      >
        <div>
          <InboxInfo email={email} provider={provider} />
          <Space size={32} />
          <Field
            id="signature"
            name="signature"
            type="editor"
            formId="addEmailInboxSignature"
            className="tc-paragraph-regular"
            placeholder={text.TYPE_SIGNATURE}
            deleteOption
          />
        </div>
      </Sidepanel>
    );
  }),
);

export default AddSignature;
