import React from 'react';
import { MultiInput } from 'tc-biq-design-system';
import { array, func } from 'prop-types';

const propTypes = {
  value: array,
  onChange: func.isRequired,
};

const defaultProps = {
  value: [],
};

const TextInputMulti = ({ value, onChange, ...props }) => {
  const updateValue = (entries) => {
    onChange(entries.map(entry => entry.value || entry));
  };

  return <MultiInput type="text" value={value} onChange={updateValue} {...props} />;
};

TextInputMulti.propTypes = propTypes;
TextInputMulti.defaultProps = defaultProps;

export default TextInputMulti;
