import React, { useContext, Fragment } from 'react';
import { Icon, Pill, Space, notifier } from 'tc-biq-design-system';
import { object } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { openSaveEditFilter } from '../sidepanels/SaveEditFilter';
import { SavedFiltersContext } from '../FiltersProvider';

import './SavedFilter.scss';

const propTypes = {
  filter: object.isRequired,
  user: object.isRequired,
};

const text = {
  SET_AS_DEFAULT: 'Set as default',
  DEFAULT: 'Default',
  DELETE_SUCCESS: 'Deleted filter successfully',
  DELETE_FAILURE: 'Failed to delete filter',
  UPDATE_SUCCESS: 'Successfully updated filter',
  UPDATE_FAILURE: 'Failed to update filter',
};

const SavedFilter = ({ filter, user }) => {
  const {
    service: { deleteSavedFilterBinding, updateSavedFilter },
    filtersStore: { setActiveFilter },
    fetchSavedFilters,
    fetchData,
    setWithCount,
  } = useContext(SavedFiltersContext);
  const { name, isFavorite, isDefault, filterId, owner } = filter;

  const edit = () => openSaveEditFilter(filter);

  const setFilter = () => {
    setActiveFilter(filterId);
    setWithCount(true);
    fetchData();
  };

  const deleteFilter = async () => {
    try {
      await deleteSavedFilterBinding(user.id, filterId);
      notifier.success(text.DELETE_SUCCESS);
      fetchSavedFilters();
    } catch {
      notifier.error(text.DELETE_FAILURE);
    }
  };

  const updateFavorite = async () => {
    try {
      await updateSavedFilter({ userId: user.id, filterId, payload: { is_favorite: !isFavorite } });
      notifier.success(text.UPDATE_SUCCESS);
      fetchSavedFilters();
    } catch {
      notifier.error(text.UPDATE_FAILURE);
    }
  };

  const updateDefault = async () => {
    try {
      await updateSavedFilter({ userId: user.id, filterId, payload: { is_default: !isDefault } });
      notifier.success(text.UPDATE_SUCCESS);
      fetchSavedFilters();
    } catch {
      notifier.error(text.UPDATE_FAILURE);
    }
  };

  return (
    <div className="fiq-saved-filter" onClick={setFilter}>
      <div onClick={e => e.stopPropagation()}>
        {isFavorite && (
          <Icon name="Star" colorName="text-primary-500" size="small" onClick={updateFavorite} />
        )}
        {!isFavorite && (
          <Icon name="Star" colorName="text-neutral-300" size="small" onClick={updateFavorite} />
        )}
      </div>
      <div className="fiq-saved-filter__name">
        <div className="truncate-text">{name}</div>
        {!isDefault && (
          <div className="fiq-saved-filter__set-default tc-micro-strong" onClick={updateDefault}>
            {text.SET_AS_DEFAULT}
          </div>
        )}
        {isDefault && (
          <Pill type="neutral" onClick={updateDefault}>
            {text.DEFAULT}
          </Pill>
        )}
      </div>
      <div className="fiq-saved-filter__actions" onClick={e => e.stopPropagation()}>
        {user.id === owner && (
          <Fragment>
            <Icon name="Edit" colorName="text-neutral-700" size="small" onClick={edit} />
            <Space size={8} />
            <Icon name="Delete" colorName="text-neutral-700" size="small" onClick={deleteFilter} />
          </Fragment>
        )}
      </div>
    </div>
  );
};

SavedFilter.propTypes = propTypes;

export default inject(stores => ({
  user: stores.loginStore.user,
}))(observer(SavedFilter));
