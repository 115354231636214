/* eslint-disable react/prop-types */
import React from 'react';
import { object } from 'prop-types';
import { inject, observer } from 'mobx-react';
import RuleList from 'App/components/RuleList';
import { Space } from 'tc-biq-design-system';
import moment from 'moment';
import './Goal.scss';

const propTypes = {
  campaign: object,
  formattedFields: object,
};

const defaultProps = {
  campaign: {},
  formattedFields: null,
};

const text = {
  ADD: 'Add',
  EVENT: 'Event',
  ATTRIBUTION_WINDOW: 'Attribution window',
};

const EventComponent = ({ event, attributionWindow }) => (
  <div className="campaign-goal__event">
    <div className="info">
      <p className="text-neutral-500">{text.EVENT}</p>
      <p>{event.name}</p>
    </div>
    <div className="info">
      <p className="text-neutral-500">{text.ATTRIBUTION_WINDOW}</p>
      <p>{attributionWindow ? moment.duration(attributionWindow).humanize() : '-'}</p>
    </div>
  </div>
);

const Goal = ({ campaign, formattedFields }) => (
  <div className="campaign-goal">
    <EventComponent
      event={campaign.goal_event_type}
      attributionWindow={campaign.goal_attribution_window}
    />
    {campaign.goal_query && (
      <div>
        <Space size={16} />
        <RuleList item={campaign.goal_query} formattedFields={formattedFields} />
      </div>
    )}
  </div>
);

Goal.propTypes = propTypes;
Goal.defaultProps = defaultProps;

export default inject(stores => ({
  formattedFields: stores.campaigns.goalQueryBuilder.fields,
}))(observer(Goal));
