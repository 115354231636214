import React, { PureComponent } from 'react';
import { number, oneOfType, string } from 'prop-types';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';

import './CardPaymentsTable.scss';

const propTypes = {
  id: oneOfType([number, string]).isRequired,
};

const text = {
  CARD_PAYMENTS: 'Card Payments',
};

const tableConfig = cardId => ({
  tableUrl: `/finances/payment-transactions/?card=${cardId}`,
  tableKey: 'cardPayments',
});

const sizeOptions = [5, 10, 50, 100, 200];

class CardPaymentsTable extends PureComponent {
  render() {
    const { id } = this.props;
    const { GridComponent } = GridComponentFactory(tableConfig(id));
    return (
      <div className="card-payments-wrapper">
        <div className="card-payments-wrapper__header">
          <span className="tc-heading-s">{text.CARD_PAYMENTS}</span>
        </div>
        <div className="card-payments-wrapper__table">
          <GridComponent
            sizeOpitons={sizeOptions}
            height="20vh"
            modifiers={modifiers}
            defaultPageSize={5}
          />
        </div>
      </div>
    );
  }
}

CardPaymentsTable.propTypes = propTypes;
export default withErrorBoundary(CardPaymentsTable);
