import { notifier } from 'tc-biq-design-system';
import { isArray, isEmpty, isObject, isString } from 'lodash';
import getErrorMessage from 'App/services/utilities/getErrorMessage';

const ERRORS_TO_OMIT = [
  'This field may not be blank.',
  'This field is required.',
  'This field may not be null.',
];

export default function displayError(error) {
  if (isArray(error)) {
    // Handle edit role error messages
    const firstError = error.filter(item => !isEmpty(item))[0];
    if (isObject(firstError)) {
      return Object.keys(firstError).forEach(key => displayError(firstError[key]));
    }
    return ERRORS_TO_OMIT.includes(error[0]) ? null : notifier.error(getErrorMessage(error[0]));
  }
  if (isString(error)) {
    return notifier.error(getErrorMessage(error));
  }
  return Object.keys(error).forEach(key => displayError(error[key]));
}
