import React from 'react';
import { DatePicker } from 'tc-biq-design-system';
import { string, bool } from 'prop-types';

const propTypes = {
  value: string,
  withPortal: bool,
};

const defaultProps = {
  value: null,
  withPortal: false,
};

const DateInput = ({ withPortal, ...props }) => {
  const { value } = props;
  return <DatePicker {...props} portalId={withPortal ? 'app' : null} date={value} />;
};

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default DateInput;
