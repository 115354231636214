import React from 'react';
import { string } from 'prop-types';
import { Pill } from 'tc-biq-design-system';

export const sideePillTypeMap = {
  Buy: 'status01',
  Sell: 'status04',
};

const propTypes = {
  value: string.isRequired,
};

const SideCell = ({ value }) => <Pill type={sideePillTypeMap[value]}>{value}</Pill>;

SideCell.propTypes = propTypes;
export default SideCell;
