import React, { Fragment } from 'react';
import { Modal, Button, notifier } from 'tc-biq-design-system';
import { object, bool, func } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { deleteTemplate } from 'Communications/services/CommunicationsService';

const propTypes = {
  visible: bool,
  parameters: object,
  onSuccess: func.isRequired,
};

const defaultProps = {
  visible: false,
  parameters: null,
};

const SIDEPANEL_ID = 'DELETE_TEMPLATE';

const text = {
  DELETE_Q: templateName => `Are you sure you want to delete "${templateName}" template permanently?`,
  TITLE: 'Delete template',
  DELETE_TEMPLATE: 'Delete template',
  CANCEL: 'Cancel',
  SUCCESS_DELETED: 'Successfully deleted template',
  DEFAULT_ERROR: 'Something went wrong.',
};

const close = () => closeOverlay(SIDEPANEL_ID);
export const openDeleteTemplate = templateInfo => openOverlay(
  SIDEPANEL_ID,
  { ...templateInfo },
);

const customFooter = (confirm, cancel, isLoading) => () => (
  <Fragment>
    <Button color="ghost" onClick={cancel}>
      {text.CANCEL}
    </Button>
    <Button disabled={isLoading} color="destructive" onClick={confirm} loading={isLoading}>
      {text.DELETE_TEMPLATE}
    </Button>
  </Fragment>
);

const DeleteTemplate = ({ visible, parameters, onSuccess }) => {
  if (!parameters) return null;
  const { templateName, templateId } = parameters;
  const removeTemplate = async () => {
    try {
      await deleteTemplate(templateId);
      if (onSuccess) onSuccess();
      notifier.success(text.SUCCESS_DELETED);
      close();
    } catch (e) {
      if (e?.response?.data) {
        notifier.error(e.response.data[0]);
      } else {
        notifier.error(text.DEFAULT_ERROR);
      }
    }
  };
  return (
    <Modal
      closable
      visible={visible}
      title={text.TITLE}
      confirmText={text.DELETE_TEMPLATE}
      icon="Delete"
      type="error"
      footerRender={customFooter(removeTemplate, close)}
      onCloseIconClick={close}
    >
      <span>{text.DELETE_Q(templateName)}</span>
    </Modal>
  );
};

DeleteTemplate.propTypes = propTypes;
DeleteTemplate.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
}))(observer(DeleteTemplate));
