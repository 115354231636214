import React, { PureComponent } from 'react';

import Page from 'App/components/Page';
import CardsTable, { tableConfig } from 'Transactions/components/CardsTable';

const bread = [{ label: 'Transactions' }, { label: 'Cards', route: '/transactions/cards' }];

const exportDataOptions = { permission: 'finances_card', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

class CardsPage extends PureComponent {
  render() {
    return (
      <Page bread={bread} title="Cards" style={{ margin: '0 20px' }} exportDataOptions={exportDataOptions}>
        <CardsTable />
      </Page>
    );
  }
}

export default CardsPage;
