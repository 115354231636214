import React, { PureComponent } from 'react';
import { array, func, string } from 'prop-types';
import { Input } from 'tc-biq-design-system';

import VisibilityCheckboxFactory from './VisibilityCheckboxFactory';

const filterColumns = filter => (column) => {
  const filteredColumns = column.label
    ? column.label.toLowerCase().includes(filter.toLowerCase())
    : true;
  return filteredColumns;
};

const propTypes = {
  columns: array,
  onToggle: func.isRequired,
  tableId: string.isRequired,
};

const defaultProps = {
  columns: [],
};

class ColumnsVisibility extends PureComponent {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = { filter: '' };
  }

  handleInputChange = (filter) => {
    this.setState({ filter });
  };

  renderFields() {
    const { columns, onToggle, tableId } = this.props;
    const { filter } = this.state;

    return columns.filter(filterColumns(filter)).map((field) => {
      const VisibilityCheckbox = VisibilityCheckboxFactory({
        tableId,
        fieldKey: field.key,
      });
      return <VisibilityCheckbox key={field.key} onChange={onToggle} />;
    });
  }

  render() {
    const { filter } = this.state;

    return (
      <div className="biq-modal-body__section">
        <div className="search-box">
          <Input
            id="columns-search"
            placeholder="Search"
            iconPosition="left"
            icon="Search"
            value={filter}
            onChange={e => this.handleInputChange(e.target.value)}
          />
        </div>

        <div className="columns">{this.renderFields()}</div>
      </div>
    );
  }
}

ColumnsVisibility.propTypes = propTypes;
ColumnsVisibility.defaultProps = defaultProps;
export default ColumnsVisibility;
