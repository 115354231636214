import http from 'App/services/http';

const fetchTrackingLinkOptions = () => http.options('/marketing/tracking-links/');

const fetchTrackingLink = id => http.get(`/marketing/tracking-links/${id}/`);

const editTrackingLink = (id, body) => http.put(`/marketing/tracking-links/${id}/`, body);

const createTrackingLink = body => http.post('/marketing/tracking-links/', body);

const deleteTrackingLink = id => http.delete(`/marketing/tracking-links/${id}/`);

export {
  fetchTrackingLinkOptions,
  fetchTrackingLink,
  editTrackingLink,
  createTrackingLink,
  deleteTrackingLink,
};
