import React from 'react';

import { Button } from 'tc-biq-design-system';

import { openOverlay } from 'App/services/overlayService';
import { WalletCell, AmountCell } from 'App/components/gridCellRenderers';
import { SIDEPANEL_ID } from 'App/components/CommonOverlays/PreviewMetaData';
import hideCols from 'App/services/utilities/hideCols';

const text = {
  VIEW_METADATA: 'View Details',
  METADATA: 'Metadata',
};

// eslint-disable-next-line
const DetailsCell = ({ data }) => data.metadata ? (
  <Button
    color="transparent"
    onClick={() => openOverlay(SIDEPANEL_ID, {
      data: data.metadata?.accountDetails || data.metadata,
      title: text.METADATA,
    })
      }
  >
    {text.VIEW_METADATA}
  </Button>
) : null;

export default {
  ...hideCols(['contact', 'metadata']),
  id: {
    pinned: 'left',
    width: 50,
  },
  balance: {
    cellRendererFramework: AmountCell,
  },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => <WalletCell data={value} />,
  },
  accountDetails: {
    cellRendererFramework: DetailsCell,
  },
  sortable: false,
};
