import React from 'react';
import { ActivityBox, Icon } from 'tc-biq-design-system';
import { string, object } from 'prop-types';

import { formatDate, formatTitle } from 'App/services/utilities/dateGroupUtils';

import './NotificationCard.scss';

const propTypes = {
  notification: object.isRequired,
  groupTitle: string.isRequired,
};

const NotificationCard = ({ notification, groupTitle }) => (
  <div
    style={{
      boxShadow: '0 0 10px 0 rgba(41, 45, 51, 0.1)',
    }}
  >
    <ActivityBox>
      <div className="fiq-notification-card">
        <div className="fiq-notification-card__text tc-paragraph-regular">
          {formatTitle(notification)}
        </div>
        <div className="fiq-notification-card__date">
          <Icon colorName="text-neutral-400" name="Clock" size="small" />
          <span className="tc-paragraph-regular text-neutral-400">
            {formatDate(groupTitle, notification.timestamp)}
          </span>
        </div>
      </div>
    </ActivityBox>
  </div>
);

NotificationCard.propTypes = propTypes;

export default NotificationCard;
