import React from 'react';
import { number, string, bool } from 'prop-types';
import AvatarNameCell from './AvatarNameCell';

const propTypes = {
  id: number.isRequired,
  fullName: string.isRequired,
  avatar: string,
  size: string,
  displaySubString: string,
  hideAvatar: bool,
};

const defaultProps = {
  avatar: null,
  size: 'regular',
  displaySubString: '',
  hideAvatar: false,
};

const ContactCell = ({ id, fullName, avatar, size, displaySubString, hideAvatar }) => (
  <AvatarNameCell
    id={id}
    type="Contact"
    displayString={fullName}
    displaySubString={displaySubString}
    avatarSource={avatar}
    route="/contacts/"
    size={size}
    hideAvatar={hideAvatar}
  />
);

ContactCell.propTypes = propTypes;
ContactCell.defaultProps = defaultProps;
export default ContactCell;
