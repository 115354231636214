import React, { useState } from 'react';
import { Icon, Button, notifier } from 'tc-biq-design-system';
import { string, object } from 'prop-types';
import http from 'App/services/http';

const text = {
  RETRY: 'Retry',
  RETRY_FAILED: 'Retry Failed',
};


// eslint-disable-next-line react/prop-types
const DownloadFile = onSuccess => ({ data, value }) => {
  const isFailed = data.state === 'Failed';
  const [retryInProgress, setRetryInProgress] = useState(false);
  const [showRetry, setShowRetry] = useState(isFailed);
  const refreshFileDownload = async () => {
    setRetryInProgress(true);
    try {
      await http.post(`/users/data-exports/${data.id}/retry/`);
      setShowRetry(false);
      onSuccess();
    } catch {
      notifier.error(text.RETRY_FAILED);
    } finally {
      setRetryInProgress(false);
    }
  };
  if (value) {
    return (
      <div className="download-cell">
        <a href={value} download><Icon name="Download" colorName="text-neutral-900" /></a>
      </div>
    );
  }
  if (isFailed && showRetry) {
    return <Button icon="Pending" loading={retryInProgress} onClick={refreshFileDownload} color="transparent">{text.RETRY}</Button>;
  }
  return null;
};

DownloadFile.propTypes = {
  data: object.isRequired,
  value: string,
};
DownloadFile.defaultProps = { value: null };

export default DownloadFile;
