import React, { Component, Fragment } from 'react';
import { Sidepanel, Space, Checkbox, Button } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { object, func } from 'prop-types';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { pick } from 'lodash';
import isEmpty from 'lodash/isEmpty';

import Field, { fieldsResolver } from 'App/components/FieldRenderer';

const propTypes = {
  overlay: object.isRequired,
  parameters: object,
  addUser: func.isRequired,
  userFields: object.isRequired,
  resetUserFields: func.isRequired,
  fetchUserFields: func.isRequired,
  resetFieldsData: func.isRequired,
  setAddUserData: func.isRequired,
  validateForm: func.isRequired,
};

const defaultProps = { parameters: {} };

const SIDEPANEL_ID = 'ADD_USER';
const MODAL_ID = 'ADD_SINGLE_USER';

const text = {
  DISCARD: 'Discard',
  ADD_USER: 'Add User',
  ADD_ANOTHER: 'Add another user',
  EDIT_USER: 'Edit User',
};

const FIELDS = [
  'first_name',
  'last_name',
  'email',
  'contact_access_group_bindings',
  'team_binding',
  'role_bindings',
];

const CustomFooter = inject(stores => ({
  hasErrors: !isEmpty(stores.forms.addUser.fieldErrors),
}))(
  observer(({ execute, cancel, executeButtonLabel, addAnotherHandler, addAnother, hasErrors }) => (
    <Fragment>
      <Checkbox checked={addAnother} onChange={addAnotherHandler}>
        {text.ADD_ANOTHER}
      </Checkbox>
      <Button color="ghost" onClick={cancel}>
        {text.DISCARD}
      </Button>
      <Button disabled={hasErrors} onClick={execute}>
        {executeButtonLabel}
      </Button>
    </Fragment>
  )),
);

const AddUser = inject(stores => ({
  addUser: stores.usersStore.addUser,
  overlay: stores.overlayStore.overlay,
  parameters: stores.overlayStore.overlay.parameters,
  userFields: stores.usersStore.userFields,
  resetUserFields: stores.usersStore.resetUserFields,
  fetchUserFields: stores.usersStore.fetchUserFields,
  resetFieldsData: stores.forms.addUser.resetFieldsData,
  setAddUserData: stores.usersStore.setAddUserData,
  validateForm: stores.forms.addUser.validateForm,
}))(observer(
  class AddUser extends Component {
    constructor(props) {
      super(props);
      this.state = {
        addAnother: false,
      };
    }

    toggleAddAnother = (event) => {
      this.setState({ addAnother: event.target.checked });
    };

    submitUser = async () => {
      const { addUser, parameters, resetUserFields, resetFieldsData, validateForm } = this.props;
      if (!validateForm()) return;
      const { addAnother } = this.state;
      const success = await addUser(parameters);
      if (success && !addAnother) openOverlay(MODAL_ID); 
  
      // Clear fields without closing sidepanel
      if (success) {
        resetUserFields();
        resetFieldsData();
      }
    };

    close = () => {
      const { resetUserFields, resetFieldsData } = this.props;
      resetUserFields();
      resetFieldsData();
      closeOverlay(SIDEPANEL_ID);
    };

    render() {
      const {
        overlay,
        addUser,
        userFields,
        setAddUserData,
        fetchUserFields,
        parameters,
      } = this.props; // eslint-disable-line
      const { addAnother } = this.state;
      if (isEmpty(userFields)) fetchUserFields();
      const fields = pick(userFields, FIELDS);
      const formattedFields = isEmpty(userFields) ? [] : fieldsResolver(fields);
      if (!isEmpty(parameters)) setAddUserData(parameters.id);
      const title = isEmpty(parameters) ? text.ADD_USER : text.EDIT_USER;
      return (
        <Sidepanel
          type="info"
          icon="Plus"
          title={title}
          visible={overlay.ADD_USER}
          onCloseIconClick={this.close}
          onConfirm={addUser}
          footerRender={() => (
            <CustomFooter
              execute={this.submitUser}
              cancel={this.close}
              executeButtonLabel={title}
              addAnotherHandler={this.toggleAddAnother}
              addAnother={addAnother}
            />
          )}
        >
          <Space size={18} />
          {formattedFields.map(({ id, read_only, ...props }) => {
            if (id === 'team_binding__team') {
              return (
                <Field
                  {...props}
                  disabled={read_only}
                  key={id}
                  formId="addUser"
                  label="Team"
                  name="team"
                />
              );
            }
            if (id === 'contact_access_group_bindings__contact_access_group') {
              return (
                <Field
                  {...props}
                  disabled={read_only}
                  key={id}
                  formId="addUser"
                  label="Contact access group"
                  name="contact_access_group"
                  multi
                />
              );
            }
            if (id === 'role_bindings__role') {
              return (
                <Field
                  {...props}
                  disabled={read_only}
                  key={id}
                  formId="addUser"
                  label="Role"
                  name="role"
                  multi
                />
              );
            }
            return (
              <Field {...props} validate="required" disabled={read_only} key={id} formId="addUser" data-recording-ignore="mask" />
            );
          })}
        </Sidepanel>
      );
    }
  },
));


AddUser.wrappedComponent.propTypes = propTypes;
AddUser.wrappedComponent.defaultProps = defaultProps;

export default AddUser;
