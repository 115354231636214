import React from 'react';
import { inject, observer } from 'mobx-react';
import { Input } from 'tc-biq-design-system';
import { func, string, object } from 'prop-types';

const propTypes = {
  updateFieldValue: func.isRequired,
  name: string.isRequired,
  error: object,
};

const defaultProps = {
  error: {},
};

const text = {
  NAME: 'Name',
};

const NameField = ({ name, updateFieldValue, error }) => (
  <Input
    style={{ width: '60%' }}
    value={name}
    name="roleName"
    onChange={e => updateFieldValue('name', e.target.value)}
    label={text.NAME}
    helpText={error?.name}
    hasError={!!error}
  />
);

NameField.propTypes = propTypes;
NameField.defaultProps = defaultProps;

export default inject(stores => ({
  name: stores.roles.fields.name,
  updateFieldValue: stores.roles.updateFieldValue,
  error: stores.roles.errors.addEditRole,
}))(observer(NameField));
