import React, { Component } from 'react';
import withFilters from 'App/components/hoc/withFilters';
import run from 'App/services/utilities/run';

import BarChart from 'Dashboard/components/layouts/BarChart';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import getGranularity from 'App/services/utilities/getGranularity';

const text = {
  TITLE: 'Visits per Tracking Link',
  DESCRIPTION: 'Total number of visits per Tracking link in selected time period.',
};

const METRIC = 'tracking_link_visits';

// eslint-disable-next-line react/prop-types
const renderTooltip = ({ payload, label }) => (
  <div className="custom-tooltip">
    <div className="custom-tooltip__label">{label}</div>
    {payload.map(item => (item.value
      ? (
        <div key={item.name} className="custom-tooltip__value" style={{ color: item.color }}>
          {`${item.name}: ${item.value}`}
        </div>
      ) : null))}
  </div>
);

const getBars = keys => keys.map(key => ({
  color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
  key,
  dataKey: key,
  label: key,
  type: 'monotone',
  strokeWidth: 2,
  dot: false,
}));

const formatData = (data, granularity) => {
  const initalValues = data
    .filter(item => item.link_name)
    .map(item => item.link_name)
    .reduce((accumulator, item) => ({ ...accumulator, [item]: 0 }), {});
  const formatedData = data.filter(item => item.link_name).reduce((acc, elem) => {
    const { link_name } = elem;
    const selectedDate = elem[granularity];
    const obj = {
      ...acc,
      [selectedDate]: {
        ...initalValues,
        ...acc[selectedDate],
        [link_name]: acc[selectedDate] && acc[selectedDate][link_name]
          ? (acc[selectedDate][link_name] += elem.visits)
          : (elem.visits),
      } };
    return obj;
  }, {});
  return Object.keys(formatedData).map(key => ({ [granularity]: key, ...formatedData[key] }));
};

class TrackingLinksVisitsChart extends Component {
  constructor() {
    super();
    this.state = {
      fetchInProgress: false,
      granularity: 'day',
      data: [],
      trackingLinks: [],
    };
  }

    onQueryReady = async (query) => {
      const { start_date, end_date } = query;

      const granularity = getGranularity(start_date, end_date);
      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: start_date,
          end_of_interval: end_date,
          granularity,
          dimensions: ['link_name'],
        },
      };

      const [err, data] = await run(fetchMetricsData(formattedQuery));
      if (err) return;

      const trackingLinks = [...new Set(
        data
          .filter(item => item.link_name)
          .map(item => item.link_name),
      )];

      this.setState({
        granularity,
        fetchInProgress: false,
        data: formatData(data, granularity),
        trackingLinks,
      });
    }

    render() {
      const { granularity, fetchInProgress, data, trackingLinks } = this.state;

      return (
        <BarChart
          title={text.TITLE}
          description={text.DESCRIPTION}
          inProgress={fetchInProgress}
          bars={getBars(trackingLinks)}
          data={data}
          xAxisKey={granularity}
          hideTotal
          height={290}
          renderTooltip={renderTooltip}
        />
      );
    }
}

export default {
  component: withFilters(TrackingLinksVisitsChart),
};
