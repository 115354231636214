/* eslint-disable react/prop-types */
import React, { PureComponent, Fragment } from 'react';
import { notifier, Button } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { func } from 'prop-types';

import GridComponentFactory from 'App/components/grid';
import { openOverlay } from 'App/services/overlayService';
import { toggleActiveState, resendInvite } from 'Settings/Sections/Users/services/UsersService';
import EditUserDetails from 'Settings/Sections/Users/components/sidepanels/EditUserDetails';
import BulkMoveToTeam, {
  openBulkMoveToTeam,
} from 'Settings/Sections/Users/components/sidepanels/MoveToTeam/BulkMoveToTeam';
import BulkManageRoles, {
  openBulkManageRoles,
} from 'Settings/Sections/Users/components/sidepanels/ManageRoles/BulkManageRoles';
import BulkManageCags, {
  openBulkManageCags,
} from 'Settings/Sections/Users/components/sidepanels/ManageCags/BulkManageCags';
import BulkArchive, {
  openBulkArchive,
} from 'Settings/Sections/Users/components/sidepanels/Archive/BulkArchive';
import buildBulkParams from 'App/services/utilities/buildBulkParams';
import { hasAccess } from 'App/services/permissionsService';
import modifiers from './modifiers';

const propTypes = {
  deleteUser: func.isRequired,
  resendInvite: func.isRequired,
};

export const tableConfig = {
  tableUrl: '/users/',
  tableKey: 'users',
};

const text = {
  DELETE_USER: 'Are you sure that you want to delete this user?',
  CANCEL: 'No',
  CONFIRM: 'Delete',
  INVITE_SENT: 'Invites sent successfully',
  INVITE_FAILED: 'Invite sent failed',
  FAILED_USER_STATUS_CHANGE: 'Failed to change User status',
};

const SIDEPANEL_HANDLERS = {
  BULK_MOVE_TO_TEAM: openBulkMoveToTeam,
  BULK_MANAGE_ROLES: openBulkManageRoles,
  BULK_MANAGE_CAGS: openBulkManageCags,
  BULK_ARCHIVE: openBulkArchive,
};

const handleResendInvites = onSuccess => (selectedItems, selectedAll) => {
  resendInvite(buildBulkParams(selectedItems, selectedAll, tableConfig.tableKey)).then(
    () => {
      notifier.success(text.INVITE_SENT);
      onSuccess();
    },
    () => notifier.error(text.INVITE_FAILED),
  );
};

const BulkActionWrapper = ({ icon, label, sidepanelId, onClick }) => ({
  selectedItems,
  selectedAll,
}) => (
  <Button
    color="transparent"
    size="small"
    onClick={
      onClick
        ? () => onClick(selectedItems, selectedAll)
        : () => SIDEPANEL_HANDLERS[sidepanelId](selectedItems, selectedAll)
    }
    icon={icon}
  >
    {label}
  </Button>
);

const bulkActions = onSuccess => [
  {
    Component: BulkActionWrapper({
      icon: 'Teams',
      label: 'Move to Team',
      sidepanelId: 'BULK_MOVE_TO_TEAM',
    }),
  },
  {
    Component: BulkActionWrapper({
      icon: 'Roles',
      label: 'Manage Roles',
      sidepanelId: 'BULK_MANAGE_ROLES',
    }),
  },
  {
    Component: BulkActionWrapper({
      icon: 'Ib',
      label: 'Manage Contact Access Groups',
      sidepanelId: 'BULK_MANAGE_CAGS',
    }),
  },
  {
    Component: BulkActionWrapper({
      icon: 'ArrowDown',
      label: 'Archive',
      sidepanelId: 'BULK_ARCHIVE',
    }),
  },
  {
    Component: BulkActionWrapper({
      icon: 'Pending',
      label: 'Re-invite',
      onClick: handleResendInvites(onSuccess),
    }),
  },
];

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const customColumns = [
  {
    key: 'actions',
    headerName: '',
  },
];

const singleActions = ({ onEdit, onDelete }) => [
  {
    icon: { name: 'Edit', color: 'text-neutral-900', size: 'small' },
    onClick: onEdit,
    label: 'Edit',
    visibility: hasAccess('users_user', 'update'),
  },
  {
    icon: { name: 'Delete', color: 'text-neutral-900', size: 'small' },
    onClick: onDelete,
    label: 'Delete',
    popconfirm: {
      type: 'destructive',
      placement: 'bottom',
      icon: 'Delete',
      label: text.DELETE_USER,
      buttonLabels: { cancel: text.CANCEL, confirm: text.CONFIRM },
    },
    visibility: hasAccess('users_user', 'delete'),
  },
];

const UsersTable = inject(stores => ({
  deleteUser: stores.usersStore.deleteUser,
  resendInvite: stores.usersStore.resendInvite,
}))(observer(
  class UsersTable extends PureComponent {
    constructor() {
      super();

      this.state = {
        gridApi: null,
      };
    }

    toggleActive = async (data, value) => {
      try {
        await toggleActiveState(data.id, value);
        gridActions.fetchTableData();
      } catch (err) {
        notifier.error(text.FAILED_USER_STATUS_CHANGE);
      }
    };

    editUser = (data) => {
      openOverlay('EDIT_USER_DETAILS', data);
    };

    deleteUser = async (data) => {
      const { deleteUser } = this.props;
      await deleteUser(data.id);
      gridActions.fetchTableData();
    };

    resendInvite = (id) => {
      // eslint-disable-next-line no-shadow
      const { resendInvite } = this.props;
      // TODO: Handle invite user failure.
      resendInvite(id).then(
        () => notifier.success(text.INVITE_SENT),
        () => notifier.error(text.INVITE_FAILED),
      );
    };

    setApiGrid = (gridApi) => {
      this.setState({ gridApi });
    };

    onSuccess = () => {
      const { gridApi } = this.state;
      gridApi.deselectAll();
      gridActions.fetchTableData();
    };

    render() {
      return (
        <Fragment>
          <GridComponent
            checkboxSelection
            modifiers={modifiers({
              toggle: this.toggleActive,
              resend: this.resendInvite,
              actions: singleActions({ onEdit: this.editUser, onDelete: this.deleteUser }).filter(
                action => action.visibility,
              ),
            })}
            customColumns={customColumns}
            bulkActions={bulkActions(this.onSuccess)}
            getGridApi={gridApi => this.setApiGrid(gridApi)}
          />
          <EditUserDetails onSuccess={this.onSuccess} />
          <BulkMoveToTeam onSuccess={this.onSuccess} />
          <BulkManageRoles onSuccess={this.onSuccess} />
          <BulkManageCags onSuccess={this.onSuccess} />
          <BulkArchive onSuccess={this.onSuccess} />
        </Fragment>
      );
    }
  },
));


UsersTable.wrappedComponent.propTypes = propTypes;
UsersTable.displayName = 'UsersTable';

export default UsersTable;
