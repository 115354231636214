import React, { Fragment, useState } from 'react';
import { Modal, Button, Upload, notifier } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { bool, object, func, array } from 'prop-types';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { isEmpty } from 'lodash';
import { downloadCsvSample } from 'Contacts/services/contactsService';

import './BulkLeadImportModal.scss';


const propTypes = {
  visible: bool,
  optionalFields: array,
  updateFieldValue: func,
  resetFieldError: func,
  errors: object,
  file: object,
  bulkImportLeads: func,
};

const defaultProps = {
  visible: false,
  optionalFields: [],
  updateFieldValue: null,
  resetFieldError: null,
  errors: null,
  file: null,
  bulkImportLeads: null,
};

const MODAL_ID = 'BULK_IMPORT_LEAD';

const obligatoryKeys = ['email'];

export const openBulkLeadImportModal = () => openOverlay(MODAL_ID);
export const closeBulkLeadImportModal = () => closeOverlay(MODAL_ID);

const text = {
  TITLE: 'Upload new leads',
  CANCEL: 'Cancel',
  DOWNLOAD_SAMPLE: 'Download sample .csv file',
  UPLOAD_TEXT: {
    initial: {
      title: '*Click to upload a file in .csv format.',
      subtitle: 'The limit is 1500 rows',
    },
  },
  WRONG_FILE_TYPE: 'The uploaded file must be .csv format',
  REQUIRED_COLUMNS: 'Required columns:',
  OPTIONAL_COLUMNS: 'Optional columns:',
};

const CustomFooter = inject(stores => ({
  hasErrors: !isEmpty(stores.forms.leadImport.fieldErrors),
}))(
  observer(({ execute, cancel, download, downloadInProgress, executeButtonLabel, hasErrors }) => (
    <Fragment>
      <Button loading={downloadInProgress} color="transparent" onClick={download}>
        {text.DOWNLOAD_SAMPLE}
      </Button>
      <Button color="ghost" onClick={cancel}>
        {text.CANCEL}
      </Button>
      <Button disabled={hasErrors} onClick={execute}>
        {executeButtonLabel}
      </Button>
    </Fragment>
  )),
);


const BulkLeadImportModal = ({
  visible,
  updateFieldValue,
  resetFieldError,
  errors,
  file,
  bulkImportLeads,
  optionalFields,
}) => {
  const [downloadInProgress, setDownloadProgress] = useState(false);
  const close = () => {
    closeBulkLeadImportModal();
    updateFieldValue('leads_file', null);
    resetFieldError('leads_file');
  };

  const onDrop = (uploadedFile) => {
    if (errors.leads_file) resetFieldError('leads_file');

    if (!uploadedFile) {
      updateFieldValue('leads_file', null);
      return;
    }

    if (!uploadedFile[0].name.endsWith('.csv')) {
      notifier.error(text.WRONG_FILE_TYPE);
      return;
    }

    updateFieldValue('leads_file', uploadedFile[0]);
  };

  const submit = async () => {
    const success = await bulkImportLeads();
    if (success) close();
  };

  const download = async () => {
    setDownloadProgress(true);
    const { data } = await downloadCsvSample();
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'sample.csv';
    hiddenElement.click();
    setDownloadProgress(false);
  };


  return (
    <Modal
      closable
      title={text.TITLE}
      icon="Plus"
      size="large"
      visible={visible}
      onCloseIconClick={close}
      footerRender={() => (
        <CustomFooter
          execute={submit}
          cancel={close}
          download={download}
          executeButtonLabel="Add"
          downloadInProgress={downloadInProgress}
        />
      )}
    >
      <div className="fiq-add-lead-bulk">
        <div className="fiq-add-lead-bulk__field-info">
          <div className="section">
            <strong>{text.REQUIRED_COLUMNS}</strong>
            <ul>
              {obligatoryKeys.map(key => <li key={key}>{key}</li>)}
            </ul>
          </div>
          <div className="section">
            <strong>{text.OPTIONAL_COLUMNS}</strong>
            <ul className="optional-fields">
              {optionalFields.map(key => <li key={key}>{key}</li>)}
            </ul>
          </div>
        </div>
        <Upload file={file} status={file ? 'success' : 'initial'} onDrop={onDrop} localization={text.UPLOAD_TEXT} />
      </div>
    </Modal>
  );
};

BulkLeadImportModal.propTypes = propTypes;
BulkLeadImportModal.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[MODAL_ID],
  errors: stores.forms.uploadDocForm.fieldErrors,
  resetFieldError: stores.forms.uploadDocForm.resetFieldError,
  updateFieldValue: stores.forms.uploadDocForm.updateFieldValue,
  file: stores.forms.uploadDocForm.data.leads_file,
  bulkImportLeads: stores.contact.profile.bulkImportLeads,
  optionalFields: stores.contact.profile.optionalFields,
}))(observer(BulkLeadImportModal));
