import { observable, action, runInAction, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import { isEmpty, find } from 'lodash';

import {
  createRule,
  updateRule,
  fetchRuleOptions,
  fetchActions,
  fetchRule,
} from 'Automation/services/rulesService';
import { fetchEventDefinitionData } from 'Settings/Sections/Events/services/EventsService';
import stores from 'App/rootStore';
import formatPayload from 'App/services/utilities/formatPayload';
import {
  formatEventLabel,
  formatEventNestedKeys,
} from 'App/components/QueryBuilderFactory/queryBuilderStoreUtils';
import formatQueryRulesArrayValues from 'App/services/utilities/formatQueryRulesArrayValues';

const text = {
  CREATE_SUCCESS: 'Successfully created rule',
  CREATE_FAILED: 'Failed to create rule',
  UPDATE_SUCCESS: 'Successfully edited rule',
  UPDATE_FAILED: 'Failed to edit rule',
};

export default class RulesStore {
  constructor(queryBuilderStore) {
    makeObservable(this, {
      requestInProgress: observable,
      errors: observable,
      rule: observable,
      fieldsDef: observable,
      eventMetadata: observable,
      actions: observable,
      actionsMetadata: observable,
      actionChoices: observable,
      disableRuleTrigger: observable,
      toggleRuleTrigger: action.bound,
      createRule: action.bound,
      updateRule: action.bound,
      fetchRuleOptions: action.bound,
      fetchRule: action.bound,
      fetchEventMetadata: action.bound,
      setQueries: action.bound,
      fetchActions: action.bound,
      addAction: action.bound,
      removeRuleAction: action.bound,
      updateActionId: action.bound,
      updateActionForm: action.bound,
      resetRule: action.bound,
    });

    this.queryBuilder = queryBuilderStore;
  }

  requestInProgress = {
    updateRule: false,
    createRule: false,
    fetchRule: false,
    ruleOptions: false,
    eventMetadata: false,
    actions: false,
  };

  errors = {
    updateRule: null,
    createRule: null,
    fetchRule: null,
  };

  rule = {};

  fieldsDef = {};

  eventMetadata = {};

  actions = [
    {
      actionId: null,
      actionForm: {},
    },
  ];

  actionsMetadata = null;

  actionChoices = [];

  disableRuleTrigger = false;

  toggleRuleTrigger(isDisabled) {
    runInAction(() => {
      this.disableRuleTrigger = isDisabled;
    });
  }

  async createRule(history) {
    this.requestInProgress.createRule = true;
    const { data, setFieldsErrors } = stores.forms.ruleForm;
    const { queries } = this.queryBuilder;
    const payload = {
      name: data.name,
      trigger_event: data.trigger_event && data.trigger_event.value,
      is_active: true,
      query: !isEmpty(queries.rules) ? formatQueryRulesArrayValues(queries) : null,
      action_parameters: formatActionsPayload(this.actions),
    };
    try {
      await createRule(payload);
      notifier.success(text.CREATE_SUCCESS);
      history.push('/automation/rules');
    } catch (e) {
      runInAction(() => {
        this.errors.createRule = e.data;
      });
      if (e.response && e.response.data) {
        setFieldsErrors(e.response.data);
      }
      notifier.error(text.CREATE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.createRule = false;
      });
    }
  }

  async updateRule({ id, ruleData, history }) {
    this.requestInProgress.updateRule = true;
    const { data, setFieldsErrors } = stores.forms.ruleForm;
    const { queries } = this.queryBuilder;
    const payload = ruleData || {
      name: data.name,
      trigger_event: data.trigger_event && data.trigger_event.value,
      is_active: true,
      query: !isEmpty(queries.rules) ? formatQueryRulesArrayValues(queries) : null,
      action_parameters: formatActionsPayload(this.actions),
    };
    try {
      await updateRule(id, payload);
      notifier.success(text.UPDATE_SUCCESS);
      history.push('/automation/rules');
    } catch (err) {
      runInAction(() => {
        this.errors.updateRule = err.response;
      });
      if (err.response && err.response.data) setFieldsErrors(err.response.data);
      notifier.error(text.UPDATE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.updateRule = false;
      });
    }
  }

  async fetchRuleOptions() {
    this.requestInProgress.ruleOptions = true;
    try {
      const response = await fetchRuleOptions();
      runInAction(() => {
        this.fieldsDef = response.data.actions.POST;
      });
    } catch (e) {
      runInAction(() => {
        this.errors.options = e.data;
      });
    } finally {
      runInAction(() => {
        this.requestInProgress.ruleOptions = false;
      });
    }
  }

  async fetchRule(id) {
    this.requestInProgress.fetchRule = true;
    const { setFieldsData } = stores.forms.ruleForm;
    try {
      const response = await fetchRule(id);
      const { name, trigger_event, actions, action_parameters } = response.data;
      runInAction(() => {
        this.rule = response.data;
        this.actions = formatRuleActions(
          actions,
          action_parameters,
          formatOptions(this.actionsMetadata),
        );
      });
      setFieldsData({
        name,
        trigger_event: {
          ...trigger_event,
          value: trigger_event.id,
          display_name: trigger_event.name,
        },
      });
    } catch (e) {
      runInAction(() => {
        this.errors.fetchRule = e.response;
      });
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchRule = false;
      });
    }
  }

  async fetchEventMetadata(triggerEventId) {
    this.requestInProgress.eventMetadata = true;
    try {
      const response = await fetchEventDefinitionData(triggerEventId);
      const { payload, old_payload } = response.data.template_properties;
      runInAction(() => {
        this.queryBuilder.setFields({});
        this.queryBuilder.setFieldsMetadata(
          formatEventNestedKeys({
            payload,
            old_payload,
          }),
          formatEventLabel,
        );
      });
    } catch (e) {
      runInAction(() => {
        this.errors.eventMetadata = e.data;
      });
    } finally {
      const { trigger_event } = this.rule;
      if (trigger_event && trigger_event.id === triggerEventId) {
        this.setQueries();
      }
      runInAction(() => {
        this.requestInProgress.eventMetadata = false;
      });
    }
  }

  setQueries() {
    if (this.rule.query && this.rule.query.rules) {
      this.queryBuilder.setQueries(this.rule.query);
    }
  }

  async fetchActions() {
    this.requestInProgress.actions = true;
    try {
      const response = await fetchActions();
      runInAction(() => {
        this.actionsMetadata = response.data;
        this.actionChoices = formatOptions(this.actionsMetadata);
      });
    } catch (e) {
      runInAction(() => {
        this.errors.actions = e.data;
      });
    } finally {
      runInAction(() => {
        this.requestInProgress.actions = false;
      });
    }
  }

  addAction() {
    this.actions = [
      ...this.actions,
      {
        actionId: null,
        actionForm: {},
      },
    ];
  }

  removeRuleAction(actionIndex) {
    this.actions[actionIndex] = null;
    this.actions = this.actions.filter(ruleAction => ruleAction);
  }

  updateActionId(actionIndex, data) {
    this.actions[actionIndex].actionId = data;
    this.actions[actionIndex].actionForm = {};
  }

  updateActionForm({ actionIndex, key, value }) {
    this.actions[actionIndex].actionForm[key] = value;
  }

  resetRule() {
    const { resetFieldsData } = stores.forms.ruleForm;
    resetFieldsData();
    this.rule = {};
    this.eventMetadata = {};
    this.actions = [
      {
        actionId: null,
        actionForm: {},
      },
    ];
    this.actionsMetadata = null;
    this.actionChoices = [];
    this.queryBuilder.resetQueries();
  }
}

function formatActionsPayload(actions) {
  return actions.map((ruleAction) => {
    const { actionId, actionForm } = ruleAction;
    if (!actionId) return null;
    return {
      [actionId.value]: formatPayload(actionForm),
    };
  });
}

function formatRuleActions(ruleActions, ruleActionParameters, options) {
  return ruleActions.map((ruleAction, index) => ({
    actionId: find(options, { value: ruleAction }),
    actionForm: ruleActionParameters[index][ruleAction],
  }));
}

function formatOptions(actionsMeta) {
  return Object.keys(actionsMeta).map(key => ({
    value: key,
    display_name: key
      .split('.')[0]
      .toLowerCase()
      .split('_')
      .map((string, index) => {
        if (index === 0) {
          return string.charAt(0).toUpperCase() + string.substring(1);
        }
        return string;
      })
      .join(' '),
  }));
}
