import React, { useState, useEffect, Fragment } from 'react';
import { Select, Input, Space } from 'tc-biq-design-system';
import { number, func, array, oneOfType, string } from 'prop-types';

const propTypes = {
  numOfTimes: oneOfType([number, string]),
  lastDaysNum: oneOfType([number, string]),
  parentIdentifier: array.isRequired,
  updateEvent: func.isRequired,
};

const defaultProps = {
  numOfTimes: 1,
  lastDaysNum: NaN,
};

const text = {
  AT_LEAST: 'At least',
  TIME: 'time',
  EVER: 'ever',
  IN_LAST: 'in last',
  DAYS: 'days',
};

const timeOptions = [
  { value: NaN, display_name: text.EVER },
  { value: true, display_name: text.IN_LAST },
];

const EventTimeQuery = ({ numOfTimes, lastDaysNum, parentIdentifier, updateEvent }) => {
  const [timeCount, setTimeCount] = useState(numOfTimes);
  const [daysCount, setDaysCount] = useState(lastDaysNum);
  const [timeOccurence, setTimeOccurence] = useState(lastDaysNum ? timeOptions[1] : timeOptions[0]);

  const updateTimeCount = ({ target }) => {
    setTimeCount(target.value);
    updateEvent({ no_times: +target.value }, parentIdentifier);
  };

  const updateDaysCount = ({ target }) => {
    setDaysCount(target.value);
    updateEvent({ last_n_days: +target.value }, parentIdentifier);
  };

  const updateTimeOccurence = (occurence) => {
    setTimeOccurence(occurence);
    if (!occurence.value) {
      setDaysCount(NaN);
      updateEvent({ last_n_days: NaN }, parentIdentifier);
    } else {
      updateDaysCount({ target: +lastDaysNum });
    }
  };

  useEffect(() => {
    updateEvent({ no_times: numOfTimes }, parentIdentifier);
    updateEvent({ last_n_days: NaN }, parentIdentifier);
  }, []);

  return (
    <div className="fiq-condition-event__time-query">
      <div>{text.AT_LEAST}</div>
      <Space size={8} />
      <Input
        style={{ maxWidth: '60px' }}
        type="number"
        value={timeCount}
        onChange={updateTimeCount}
        min={0}
      />
      <Space size={8} />
      <div>{text.TIME}</div>
      <Space size={8} />
      <Select
        clearable={false}
        valueKey="value"
        labelKey="display_name"
        options={timeOptions}
        value={timeOccurence}
        onChange={updateTimeOccurence}
      />
      {!!timeOccurence.value && (
        <Fragment>
          <Space size={8} />
          <div>{text.DAYS}</div>
          <Space size={8} />
          <Input
            style={{ maxWidth: '60px' }}
            type="number"
            value={daysCount}
            onChange={updateDaysCount}
            min={0}
          />
        </Fragment>
      )}
    </div>
  );
};

EventTimeQuery.propTypes = propTypes;
EventTimeQuery.defaultProps = defaultProps;

export default EventTimeQuery;
