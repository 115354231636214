import React, { Fragment } from 'react';
import { Modal, Button, Pill } from 'tc-biq-design-system';
import { object, bool, func } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { closeOverlay } from 'App/services/overlayService';

const propTypes = {
  visible: bool,
  parameters: object,
  deleteRole: func.isRequired,
  requestInProgress: bool.isRequired,
  onSuccess: func.isRequired,
};

const defaultProps = {
  visible: false,
  parameters: null,
};

const SIDEPANEL_ID = 'DELETE_ROLE';

const text = {
  DELETE_Q: 'Are you sure you want to delete role ',
  TITLE: 'Delete role',
  DELETE_ROLE: 'Delete role',
  CANCEL: 'Cancel',
};

const close = () => closeOverlay(SIDEPANEL_ID);

const customFooter = (confirm, cancel, isLoading) => () => (
  <Fragment>
    <Button color="ghost" onClick={cancel}>
      {text.CANCEL}
    </Button>
    <Button disabled={isLoading} color="destructive" onClick={confirm} loading={isLoading}>
      {text.DELETE_ROLE}
    </Button>
  </Fragment>
);

const DeleteRole = ({ visible, parameters, deleteRole, requestInProgress, onSuccess }) => {
  if (!parameters) return null;
  const { roleName, roleId } = parameters;
  const removeRole = async () => {
    await deleteRole(roleId);
    if (onSuccess) onSuccess();
    close();
  };
  return (
    <Modal
      closable
      visible={visible}
      title={text.TITLE}
      confirmText={text.DELETE_ROLE}
      icon="Delete"
      type="error"
      footerRender={customFooter(removeRole, close, requestInProgress)}
      onCloseIconClick={close}
    >
      <span>{text.DELETE_Q}</span>
      <Pill>{roleName}</Pill>
    </Modal>
  );
};

DeleteRole.propTypes = propTypes;
DeleteRole.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  deleteRole: stores.roles.deleteRole,
  requestInProgress: stores.roles.requestInProgress.deleteRole,
}))(observer(DeleteRole));
