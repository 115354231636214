/* eslint-disable @typescript-eslint/no-explicit-any */
import { observable, action, makeObservable } from 'mobx';

export interface Parameters {
  [key: string]: any;
}

export interface Overlay {
  [key: string]: any;
  parameters?: Parameters;
}

export interface IOverlayStore {
  overlay: Overlay;
}

export default class OverlayStore implements IOverlayStore {
  overlay = {};

  constructor() {
    makeObservable(this, {
      overlay: observable,
      open: action.bound,
      close: action.bound,
    });
  }

  open(key: string, data: any = {}) {
    this.overlay = {
      [key]: true,
      parameters: data,
    };
  }

  close(key: string) {
    this.overlay = {
      [key]: false,
      parameters: {},
    };
  }
}
