import { i18n } from '@lingui/core';
import { en, fr } from 'make-plural/plurals';

import enMessages from 'locales/en/messages';
import frMessages from 'locales/fr/messages';

export const locals = {
  EN: 'en',
  FR: 'fr',
};

const localMessages = {
  [locals.EN]: enMessages,
  [locals.FR]: frMessages,
};

i18n.loadLocaleData({
  [locals.EN]: { plurals: en },
  [locals.FR]: { plurals: fr },
});


export const changeLanguage = (local) => {
  const { messages } = localMessages[local];
  i18n.load(local, messages);
  i18n.activate(local);
};
