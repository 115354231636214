import moment from 'moment';

const dateTimeOptions = [
  [3, 'minutes'],
  [5, 'minutes'],
  [10, 'minutes'],
  [15, 'minutes'],
  [20, 'minutes'],
  [30, 'minutes'],
  [45, 'minutes'],
  [1, 'hour'],
  [2, 'hour'],
  [3, 'hour'],
  [6, 'hours'],
  [12, 'hours'],
  [1, 'day'],
  [2, 'days'],
  [1, 'week'],
  [2, 'weeks'],
  [3, 'weeks'],
  [1, 'month'],
  [2, 'months'],
  [6, 'months'],
  [1, 'year'],
];

const dateOptions = [
  [7, 'days'],
  [14, 'days'],
  [1, 'month'],
  [2, 'months'],
  [6, 'months'],
  [1, 'year'],
];

function mapDateChoices(options) {
  return options.map(d => ({
    display_name: `${d[0]} ${d[1]}`,
    value: moment.duration(d[0], d[1]).asMinutes(),
  }));
}

export const dateTimeChoices = mapDateChoices(dateTimeOptions);
export const dateChoices = mapDateChoices(dateOptions);
