import React, { useEffect } from 'react';
import { object, bool, func } from 'prop-types';
import { Modal, Space } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { isEmpty } from 'lodash';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import SidepanelFooter from 'App/components/SidepanelFooter';
import Loader from 'App/components/Loader';
import { hasAccess } from 'App/services/permissionsService';

import './PreviewCampaign.scss';

const SIDEPANEL_ID = 'PREVIEW_CAMPAIGN';

export const closePreviewCampaign = () => closeOverlay(SIDEPANEL_ID);
export const openPreviewCampaign = data => openOverlay(SIDEPANEL_ID, data);

const propTypes = {
  parameters: object,
  visible: bool,
  requestInProgress: bool.isRequired,
  fetchCampaign: func.isRequired,
  campaign: object,
  resetCampaignData: func.isRequired,
  history: object.isRequired,
};

const defaultProps = {
  campaign: null,
  parameters: null,
  visible: false,
};

const text = {
  SUBJECT: 'Subject:',
  SEND_FROM: 'Send from:',
  REPLY_TO: 'Reply to:',
  BUTTON_LABELS: {
    confirm: 'Edit message',
  },
};

const getIcon = (icon) => {
  const icons = {
    Email: 'Mail',
    SMS: 'SingleChat',
    WhatsApp: 'WhatsApp',
  };

  if (icons[icon]) return icons[icon];
  return 'Mail';
};

const customFooter = (history, id, edit, permission) => () => (permission ? (
  <SidepanelFooter
    execute={edit}
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    style={{ justifyContent: 'flex-end', width: '100%' }}
  />
) : null);

const PreviewCampaign = ({
  visible,
  parameters,
  requestInProgress,
  fetchCampaign,
  campaign,
  resetCampaignData,
  history,
}) => {
  const close = () => {
    const shouldResetCampaign = !history.location.pathname.includes('/campaigns-details');
    if (shouldResetCampaign) resetCampaignData();
    closePreviewCampaign();
  };
  const title = parameters?.name ? parameters.name : '';
  const editMessage = () => history.push(`/marketing/campaigns/${parameters.id}`);
  let canEdit = false;

  useEffect(() => {
    if (parameters?.id) {
      fetchCampaign(parameters.id);
      canEdit = hasAccess('marketing_campaign', 'update') && parameters.status === 'Draft';
    }
  }, [parameters]);

  if (!parameters || (!campaign.message && !campaign.template)) return null;

  return (
    <Modal
      closable
      title={title}
      icon={getIcon(campaign.channel)}
      size="large"
      visible={visible}
      onCloseIconClick={close}
      footerRender={customFooter(history, parameters.id, editMessage, canEdit)}
    >
      <Loader visible={requestInProgress || isEmpty(campaign)} />
      {!isEmpty(campaign) && !requestInProgress && (
        <div className="fiq-preview-campaign">
          {campaign.message?.from_email && (
          <div className="fiq-preview-campaign__title">
            <div className="text-neutral-500">{text.SEND_FROM}</div>
            <Space size={8} />
            <div>{campaign.message.from_email}</div>
          </div>
          )}

          {campaign.message?.reply_to_email && (
          <div className="fiq-preview-campaign__title">
            <div className="text-neutral-500">{text.REPLY_TO}</div>
            <Space size={8} />
            <div>{campaign.message.reply_to_email}</div>
          </div>
          )}
          {campaign.message?.subject && (
            <div className="fiq-preview-campaign__title">
              <div className="text-neutral-500">{text.SUBJECT}</div>
              <Space size={8} />
              <div>{campaign.message.subject}</div>
            </div>
          )}
          <div className="fiq-preview-campaign__body">
            {ReactHtmlParser(campaign.template?.content?.[0]?.content)}
          </div>
        </div>
      )}
    </Modal>
  );
};

PreviewCampaign.propTypes = propTypes;
PreviewCampaign.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  campaign: stores.campaigns.campaign,
  fetchCampaign: stores.campaigns.fetchCampaignData,
  requestInProgress: stores.campaigns.requestInProgress.fetchCampaign,
  resetCampaignData: stores.campaigns.resetCampaign,
}))(observer(withRouter(PreviewCampaign)));
