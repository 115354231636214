// FINANCE DASHBOARD WIDGETS
import IncomingFunds from './IncomingFunds';
import IncomingFundsTotal from './IncomingFundsTotal';
import OutgoingFunds from './OutgoingFunds';
import OutgoingFundsTotal from './OutgoingFundsTotal';
import IncomingCountNumeric from './IncomingCountNumeric';
import AverageIncomingNumeric from './AverageIncomingNumeric';
import AverageFTDNumeric from './AverageFTDNumeric';
import NetIncomeNumeric from './NetIncomeNumeric';
import FTDCountNumeric from './FTDCountNumeric';
import RedepositsCountNumeric from './RedepositsCountNumeric';
import IncomingTotalNumeric from './IncomingTotalNumeric';
import FTDTotalNumeric from './FTDTotalNumeric';
import RedepositsTotalNumeric from './RedepositsTotalNumeric';
import OutgoingTotalNumeric from './OutgoingTotalNumeric';
import OutgoingCountNumeric from './OutgoingCountNumeric';
import AverageOutgoingNumeric from './AverageOutgoingNumeric';

// SALES DASHBOARD WIDGETS
import NewContactsChart from './NewContactsChart';
import LeadToLiveNumeric from './LeadToLiveNumeric';
import DemoToLiveNumeric from './DemoToLiveNumeric';
import CallsNumeric from './CallsNumeric';
import SmsNumeric from './SmsNumeric';
import EmailsNumeric from './EmailsNumeric';
import WhatsAppNumeric from './WhatsAppNumeric';
import InteractionsChart from './InteractionsChart';
import HighValueContactsList from './HighValueContactsList';
import LowValueContactsList from './LowValueContactsList';
import NewContactsNumeric from './NewContactsNumeric';
import ContactsByStateChart from './ContactsByStateChart';
import ContactsPerStatusChart from './ContactsPerStatusChart';
import LeadsChart from './LeadsChart';
import LeadsNumeric from './LeadsNumeric';
import ActiveContactsChart from './ActiveContactsChart';
import ActiveContactsNumeric from './ActiveContactsNumeric';

// MARKETING DASHBOARD WIDGETS
import ConversionCampaignChart from './ConversionCampaignChart';
import ActiveCampaignsNumeric from './ActiveCampaignsNumeric';
import FinishedCampaignsNumeric from './FinishedCampaignsNumeric';
import SegmentsEnterRateList from './SegmentsEnterRateList';
import SegmentsExitRateList from './SegmentsExitRateList';
import SegmentsRetentionRateList from './SegmentsRetentionRateList';
import NewLeadsChart from './NewLeadsChart';
import LeadToDemoChart from './LeadToDemoChart';
import LeadToLiveChart from './LeadToLiveChart';
import TrackingLinksChart from './TrackingLinksChart';
import TrackingLinksVisitsChart from './TrackingLinksVisitsChart';

const financeDashboard = [
  IncomingFunds,
  IncomingFundsTotal,
  OutgoingFunds,
  OutgoingFundsTotal,
  IncomingCountNumeric,
  AverageIncomingNumeric,
  AverageFTDNumeric,
  NetIncomeNumeric,
  FTDCountNumeric,
  RedepositsCountNumeric,
  IncomingTotalNumeric,
  FTDTotalNumeric,
  RedepositsTotalNumeric,
  OutgoingTotalNumeric,
  OutgoingCountNumeric,
  AverageOutgoingNumeric,
];

const salesDashboard = [
  NewContactsChart,
  NewContactsNumeric,
  LeadToLiveNumeric,
  DemoToLiveNumeric,
  CallsNumeric,
  SmsNumeric,
  EmailsNumeric,
  WhatsAppNumeric,
  InteractionsChart,
  HighValueContactsList,
  LowValueContactsList,
  ContactsByStateChart,
  ContactsPerStatusChart,
  LeadsChart,
  LeadsNumeric,
  ActiveContactsChart,
  ActiveContactsNumeric,
];

const marketingDashboard = [
  ConversionCampaignChart,
  ActiveCampaignsNumeric,
  FinishedCampaignsNumeric,
  SegmentsEnterRateList,
  SegmentsExitRateList,
  SegmentsRetentionRateList,
  NewLeadsChart,
  LeadToDemoChart,
  LeadToLiveChart,
  TrackingLinksChart,
  TrackingLinksVisitsChart,
];

export { financeDashboard, salesDashboard, marketingDashboard };
