import React from 'react';
import { oneOfType, object, string } from 'prop-types';

const propTypes = {
  value: oneOfType([object, string]),
};

const defaultProps = {
  value: null,
};

const SmartlookCell = ({ value }) => (
  value ? <div className="truncate-text" data-recording-sensitive>{value}</div> : <span>-</span>
);

SmartlookCell.propTypes = propTypes;
SmartlookCell.defaultProps = defaultProps;
export default SmartlookCell;
