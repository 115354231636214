export default function getRandomColor(color) {
  const a = Math.floor((Math.random() * 125) + 100);
  if (color === 'red') {
    return rgbToHex(a, 0, 0);
  }
  return rgbToHex(0, a, 0);
}

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}
