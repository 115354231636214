import React, { PureComponent } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Modal, Upload, Pill, Space, Button } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { bool, object, func } from 'prop-types';

import { closeOverlay } from 'App/services/overlayService';
import SidepanelFooter from 'App/components/SidepanelFooter';

import './UploadEditAvatar.scss';

export const SIDEPANEL_ID = 'UPLOAD_EDIT_AVATAR';

const propTypes = {
  visible: bool,
  parameters: object,
  uploadAvatar: func.isRequired,
  resetErrors: func.isRequired,
  setErrors: func.isRequired,
  errors: object,
  requestInProgress: bool.isRequired,
};

const defaultProps = {
  errors: null,
  parameters: null,
  visible: false,
};

const text = {
  REMOVE: 'Remove existing image',
  ZOOM: 'Zoom',
  UPLOAD_FAIL: 'Failed to upload image, check if file uploaded is valid image format',
  NOTIFY_MSGS: {
    success: 'Successfully changed avatar',
    error: 'Failed to change avatar',
  },
  BUTTON_LABELS: {
    confirm: 'Upload',
    cancel: 'Cancel',
  },
};

const customFooter = ({ execute, close, submitInProgress }) => () => (
  <SidepanelFooter
    execute={execute}
    close={close}
    submitInProgress={submitInProgress}
    buttonLabels={text.BUTTON_LABELS}
    confirmColor="primary"
  />
);

class UploadEditAvatar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      avatar: null,
      zoom: 1,
    };
  }

  close = () => {
    this.setState({ avatar: null, zoom: 1 }, () => {
      closeOverlay(SIDEPANEL_ID);
    });
  };

  uploadFile = (file) => {
    const { resetErrors } = this.props;
    resetErrors('uploadAvatar');
    this.setState({ avatar: file[0] });
  };

  zoomIn = () => {
    const { zoom } = this.state;
    if (+zoom === 2) return;
    this.setState({ zoom: (+zoom + 0.1).toFixed(1) });
  };

  zoomOut = () => {
    const { zoom } = this.state;
    if (+zoom === 0) return;
    this.setState({ zoom: (+zoom - 0.1).toFixed(1) });
  };

  uploadAvatar = async () => {
    const {
      parameters: { user },
      uploadAvatar,
      errors,
    } = this.props;
    let file = null;
    if (this.editor) {
      const dataURL = this.editor.getImageScaledToCanvas().toDataURL('image/png');
      file = dataURLtoFile(dataURL, `user_${user.id}_avatar.png`);
    }

    if (!errors && file) {
      await uploadAvatar({
        payload: { avatar: file },
        id: user.id,
        notifyMsgs: text.NOTIFY_MSGS,
      });
      this.close();
    }
  };

  setEditorRef = (editor) => {
    this.editor = editor;
  };

  removeAvatar = () => {
    const { resetErrors } = this.props;
    resetErrors('uploadAvatar');
    this.setState({ avatar: null, zoom: 1 });
  };

  setError = () => {
    const { setErrors } = this.props;
    setErrors('uploadAvatar', text.UPLOAD_FAIL);
  };

  render() {
    const { visible, requestInProgress, errors } = this.props;
    const { avatar, zoom } = this.state;
    return (
      <Modal
        visible={visible}
        title="Upload avatar"
        confirmText="Upload"
        icon="Upload"
        footerRender={customFooter({
          execute: this.uploadAvatar,
          close: this.close,
          submitInProgress: requestInProgress,
        })}
      >
        <div className="fiq-avatar-modal">
          {!avatar && <Upload onDrop={this.uploadFile} file={avatar} />}
          {avatar && (
            <div className="fiq-avatar-modal__avatar">
              <AvatarEditor
                ref={this.setEditorRef}
                image={avatar}
                width={240}
                height={240}
                border={0}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={zoom}
                borderRadius={120}
                crossOrigin="anonymous"
                onLoadFailure={this.setError}
              />
              <div className="fiq-avatar-modal__zoom">
                {+zoom === 0.1 ? (
                  <Pill type="neutral" icon="Debit" />
                ) : (
                  <Pill onIconClick={this.zoomOut} onClick={this.zoomOut} icon="Debit" />
                )}
                <Space size={12} />
                <div>{text.ZOOM}</div>
                <Space size={12} />
                {+zoom === 1.9 ? (
                  <Pill type="neutral" icon="Credit" />
                ) : (
                  <Pill onIconClick={this.zoomIn} onClick={this.zoomIn} icon="Credit" />
                )}
              </div>
              <Space size={12} />
              <Button onClick={this.removeAvatar} color="transparent">
                {text.REMOVE}
              </Button>
              {errors && <div className="tc-paragraph-strong text-status04-400">{errors}</div>}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

UploadEditAvatar.propTypes = propTypes;
UploadEditAvatar.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  uploadAvatar: stores.usersStore.uploadAvatar,
  errors: stores.usersStore.errors.uploadAvatar,
  setErrors: stores.usersStore.setErrors,
  resetErrors: stores.usersStore.resetErrors,
  requestInProgress: stores.usersStore.requestInProgress.uploadAvatar,
}))(observer(UploadEditAvatar));

// copied function from stackoverflow
function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
