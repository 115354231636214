import React, { Fragment, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { object, func, bool } from 'prop-types';
import classNames from 'classnames';
import { Radio, Space } from 'tc-biq-design-system';

import condition from 'assets/images/entry-condition.svg';
import conditionActive from 'assets/images/entry-condition-active.svg';
import trigger from 'assets/images/entry-trigger.svg';
import triggerActive from 'assets/images/entry-trigger-active.svg';

import Field from 'App/components/FieldRenderer';
import QueryBuilderFactory from 'App/components/QueryBuilderFactory';
import Loader from 'App/components/Loader';
import FieldSectionTitle from '../FieldSectionTitle';
import SectionWrapper from '../SectionWrapper';
import { SEND_TO_TRIGGER_TYPES } from '../../../store/CampaignsStore';

const propTypes = {
  typeProps: object.isRequired,
  segmentProps: object.isRequired,
  send_to_type: object,
  updateFieldValue: func.isRequired,
  loadingQueryBuilderMetadata: bool.isRequired,
  fetchQueryBuilderMetadata: func.isRequired,
};

const defaultProps = { send_to_type: null };

const text = {
  ENTRY: 'Entry',
  TO: 'To',
  SEGMENT: 'Segment',
  MEET_CONDITIONS: 'They meet conditions',
  MEET_CONDITIONS_DESCRIPTION:
    'Contacts who fulfill specified conditions will enter campaign.',
  TRIGGER: 'Trigger',
  TRIGGER_DESCRIPTION:
    'Once a Contact enters or leaves a segment he will become a part of the campaign',
};

const { QueryBuilder } = QueryBuilderFactory({
  storeKey: 'campaigns',
  storeFieldKey: 'sendToQueryBuilder',
  initialConditionPlaceholder: 'Optionally add conditions to filter the selected contacts',
});

const SendToFields = ({
  typeProps,
  segmentProps,
  send_to_type,
  updateFieldValue,
  loadingQueryBuilderMetadata,
  fetchQueryBuilderMetadata,
}) => {
  const [isTriggerEntry, setTriggerEntry] = useState(
    SEND_TO_TRIGGER_TYPES.includes(send_to_type?.value) ? 'true' : 'false',
  );
  const [sendToTypeOptions, setSendToTypeOptions] = useState(typeProps.options);
  const [isInitial, setInitial] = useState(true);
  const isTriggerChecked = isTriggerEntry === 'true';

  const getSendToTypeValue = (sendToType, options) => {
    if (!sendToType) return options[0];
    return options.find(option => option.value === sendToType.value) || options[0];
  };

  const setEntry = (value) => {
    setTriggerEntry(value);
    updateFieldValue('send_to_segment', null);
  };

  useEffect(() => {
    if (send_to_type && SEND_TO_TRIGGER_TYPES.includes(send_to_type.value)) {
      setTriggerEntry('true');
    }

    if (!send_to_type || send_to_type.value === 'All contacts') {
      updateFieldValue('send_to_segment', null);
    }
  }, [send_to_type]);

  useEffect(() => {
    if (isTriggerChecked) {
      // eslint-disable-next-line
      const triggerOptions = typeProps.options.filter(option => SEND_TO_TRIGGER_TYPES.includes(option.value));
      setSendToTypeOptions(triggerOptions);
      updateFieldValue('send_to_type', getSendToTypeValue(send_to_type, triggerOptions));
    } else {
      const conditionOptions = typeProps.options.filter(
        option => !SEND_TO_TRIGGER_TYPES.includes(option.value),
      );
      setSendToTypeOptions(conditionOptions);
      updateFieldValue('send_to_type', getSendToTypeValue(send_to_type, conditionOptions));
      if (!isInitial) updateFieldValue('end_datetime', null);
    }
    setInitial(false);
  }, [isTriggerChecked]);

  useEffect(() => {
    fetchQueryBuilderMetadata();
  }, []);

  return (
    <SectionWrapper.FieldWrapper>
      <FieldSectionTitle title={text.ENTRY} />
      <Radio.Group
        name="name"
        value={isTriggerEntry}
        onChange={event => setEntry(event.target.value)}
        horizontal
      >
        <Radio
          value="false"
          checked={!isTriggerChecked}
          className={classNames('fiq-campaign-form__entry-radio', {
            active: !isTriggerChecked,
          })}
        >
          <img src={!isTriggerChecked ? conditionActive : condition} alt="Condition" />
          <div className="fiq-campaign-form__entry-radio__content">
            <span className="tc-paragraph-strong">{text.MEET_CONDITIONS}</span>
            <span className="text-neutral-500">{text.MEET_CONDITIONS_DESCRIPTION}</span>
          </div>
        </Radio>
        <Radio
          value="true"
          checked={isTriggerChecked}
          className={classNames('fiq-campaign-form__entry-radio', {
            active: isTriggerChecked,
          })}
        >
          <img src={isTriggerChecked ? triggerActive : trigger} alt="Trigger" />
          <div className="fiq-campaign-form__entry-radio__content">
            <span className="tc-paragraph-strong">{text.TRIGGER}</span>
            <span className="text-neutral-500">{text.TRIGGER_DESCRIPTION}</span>
          </div>
        </Radio>
      </Radio.Group>
      <Space size={12} />
      <Field {...typeProps} options={sendToTypeOptions} label={text.TO} formId="campaignForm" />
      {send_to_type && send_to_type.value !== 'All contacts' && (
        <Field {...segmentProps} label={text.SEGMENT} formId="campaignForm" />
      )}
      {send_to_type && (
        <Fragment>
          <Loader visible={loadingQueryBuilderMetadata} />
          {!loadingQueryBuilderMetadata && (
            <QueryBuilder style={{ marginTop: '0', padding: '0', overflow: 'visible' }} />
          )}
        </Fragment>
      )}
    </SectionWrapper.FieldWrapper>
  );
};
SendToFields.propTypes = propTypes;
SendToFields.defaultProps = defaultProps;

export default inject(stores => ({
  send_to_type: stores.forms.campaignForm.data.send_to_type,
  updateFieldValue: stores.forms.campaignForm.updateFieldValue,
  fetchQueryBuilderMetadata: stores.campaigns.fetchSendToQueryBuilderMetadata,
  loadingQueryBuilderMetadata: stores.campaigns.requestInProgress.contactPayloadMetadata,
}))(observer(SendToFields));
