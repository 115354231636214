export default {
  'activity-list': '/api/activity/',
  'auditlog-list': '/api/audit-logs/',
  'emaillog-list': '/api/communications/email-logs/',
  'smslog-list': '/api/communications/sms-logs/',
  'useremaillog-list': '/api/communications/user-email-logs/',
  'whatsapplog-list': '/api/communications/whatsapp-logs/',
  'contactaccessgroup-list': '/api/contact-access-groups/',
  'contact-list': '/api/contacts/',
  'contact-activity-list': '/api/contacts/<parent_contact>/activity/',
  'contact-payment-transactions-list': '/api/contacts/<parent_contact>/payment-transactions/',
  'contact-trade-positions-list': '/api/contacts/<parent_contact>/trade-positions/',
  'contact-trade-transactions-list': '/api/contacts/<parent_contact>/trade-transactions/',
  'contact-wallets-list': '/api/contacts/<parent_contact>/wallets/ ',
  'contact-withdraw-requests-list': '/api/contacts/<parent_contact>/withdraw-requests/',
  'contact-referrals-list': '/api/contacts/<parent_contact>/wallets/',
  'document-types-list': '/api/documents/types/',
  'events-list': '/api/events/',
  'paymenttransaction-list': '/api/finances/payment-transactions/',
  'tradeorder-list': '/api/finances/trade-order/',
  'tradetransaction-list': '/api/finances/trade-transactions/',
  'tradeposition-list': '/api/finances/trade-positions/',
  'wallets-list': '/api/finances/wallets/',
  'wallet-ledger-list': '/api/finances/wallets/<parent_wallet>/ledger/',
  'withdrawrequest-list': '/api/finances/withdraw-requests/',
  'tasks-list': '/api/tasks/',
  'user-list': '/api/users/',
  'user-permissions': '/api/users/<pk>/permissions/',
  'role-list': '/api/users/roles/',
  'teams-list': '/api/users/teams/',
  'whitelabel-list': '/api/whitelabels/',
  'campaign-list': '/api/marketing/campaigns/',
  'template-list': '/api/communications/templates/',
};
