import React, { useState } from 'react';
import { string, bool, func, array, number } from 'prop-types';
import { Input, Space, Icon, Pill, Button } from 'tc-biq-design-system';

import { UserCell } from 'App/components/gridCellRenderers';
import InfiniteScroll from 'App/components/InfiniteScroll';
import Loader from 'App/components/Loader';
import extractDisplayName from 'App/services/utilities/extractDisplayName';

import './UsersList.scss';

const propTypes = {
  users: array.isRequired,
  onUserClick: func.isRequired,
  icon: string.isRequired,
  fetchDataOnSearch: func.isRequired,
  hasMore: bool.isRequired,
  isLoading: bool.isRequired,
  userCountLabel: string,
  userCount: number,
  removeAll: func,
  disableAction: bool,
  bindingType: string,
};

const text = {
  SEARCH: 'Search',
  USERS: 'Users',
  REMOVE_ALL: 'Remove all',
};

const defaultProps = {
  removeAll: null,
  userCount: 0,
  userCountLabel: text.USERS,
  disableAction: false,
  bindingType: '',
};

const INFINITE_SCROLL_STYLE = {
  marginTop: '24px',
  height: '300px',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
};

const createBindingTypeChecker = (bindingType) => {
  const bindingKey = `${bindingType}_binding`;
  const bindingNameKey = `${bindingType}_name`;
  const getBindDisplayName = user => user[bindingKey][bindingNameKey];
  const hasBindingType = user => !!user[bindingKey];

  return {
    getBindDisplayName,
    hasBindingType,
  };
};

const UsersListHeader = ({ userCountLabel, userCount, removeAll }) => (
  <div className="fiq-users-list__header">
    <div>
      {!!userCount && (
        <Pill type="neutral" icon="User" iconPosition="left">
          {`${userCountLabel} ${userCount}`}
        </Pill>
      )}
      {!userCount && (
        <div className="tc-paragraph-regular text-neutral-700">
          {`${userCountLabel} ${userCount || ''}`}
        </div>
      )}
    </div>
    {removeAll && (
      <Button color="transparent" size="small" icon="Plus" onClick={removeAll}>
        {text.REMOVE_ALL}
      </Button>
    )}
  </div>
);
UsersListHeader.propTypes = {
  userCount: number.isRequired,
  userCountLabel: string.isRequired,
  removeAll: func,
};
UsersListHeader.defaultProps = { removeAll: null };

const UsersList = ({
  users,
  fetchDataOnSearch,
  hasMore,
  isLoading,
  userCount,
  userCountLabel,
  onUserClick,
  icon,
  removeAll,
  disableAction,
  bindingType,
}) => {
  const [searchTerm, updateSearchTerm] = useState('');

  const onSearch = () => {
    fetchDataOnSearch(searchTerm);
  };

  const { hasBindingType, getBindDisplayName } = createBindingTypeChecker(bindingType);

  const fetchOnScroll = () => fetchDataOnSearch('', true);
  return (
    <div data-recording-ignore="mask" className="fiq-users-list">
      <Input
        name="search-bind-users"
        style={{ width: '100%' }}
        placeholder={text.SEARCH}
        iconPosition="left"
        icon="Search"
        onChange={e => updateSearchTerm(e.target.value)}
        onKeyUp={onSearch}
        value={searchTerm}
      />
      <Space size={8} />
      <UsersListHeader
        userCount={userCount}
        userCountLabel={userCountLabel}
        removeAll={removeAll}
      />
      <InfiniteScroll
        hasMore={hasMore}
        isLoading={isLoading}
        fetchData={fetchOnScroll}
        style={INFINITE_SCROLL_STYLE}
      >
        {users.map(user => (
          <div key={user.id} className="fiq-users-list__user">
            <UserCell
              fullName={extractDisplayName(user)}
              subTitle={hasBindingType(user) ? `Team: ${getBindDisplayName(user)}` : ''}
              {...user}
            />
            {!(disableAction && hasBindingType(user)) && (
              <Icon onClick={() => onUserClick(user)} style={{ cursor: 'pointer' }} name={icon} />
            )}
          </div>
        ))}
        <Loader visible={isLoading} />
      </InfiniteScroll>
    </div>
  );
};

UsersList.propTypes = propTypes;
UsersList.defaultProps = defaultProps;

export default UsersList;
