import React, { PureComponent } from 'react';
import { Accordion, Icon, Space, Row, Col } from 'tc-biq-design-system';
import { func, string, bool, number, array } from 'prop-types';
import classNames from 'classnames';

import If from 'App/components/If';
import NoTasksBar from './NoTasksBar';
import TasksAccordionBody from './TasksAccordionBody';

const propTypes = {
  accordionId: string.isRequired,
  data: array.isRequired,
  requestInProgress: bool.isRequired,
  hasMore: bool.isRequired,
  count: number.isRequired,
  isOpen: bool,
  fetchTasks: func.isRequired,
  fetchAllTasks: func.isRequired,
  title: string.isRequired,
};

const defaultProps = {
  isOpen: false,
};

const text = {
  CONNECTED_TO: 'Connected to',
  ASSIGNED_TO: 'Assigned to',
  OVER_DUE: 'Over due',
};

const headerTemplate = (title, isOpen, toggle, taskNum, accordionId) => (
  <div
    className={classNames('fiq-tasks__accordion-header', 'tc-paragraph-strong', {
      [accordionId]: accordionId,
      'text-neutral-50': accordionId === 'overDue',
    })}
    onClick={toggle}
  >
    <Row gutter={0} style={{ width: '100%', overflow: 'auto' }}>
      <Col xs="2%" gutter={0}>
        <span>
          <If condition={!isOpen}>
            <Icon size="small" name="CaretRight" />
          </If>
          <If condition={isOpen}>
            <Icon size="small" name="CaretDown" />
          </If>
        </span>
      </Col>
      <Col xs="50%" gutter={0}>
        <div className="fiq-tasks__accordion-header-title">
          <div className="tc-paragraph-strong">{title}</div>
          <Space size={8} />
          <div className="tc-paragraph-regular">{`(${taskNum})`}</div>
        </div>
      </Col>
      <Col xs="15%" gutter={0}>
        <If condition={isOpen}>
          <div>{text.ASSIGNED_TO}</div>
        </If>
      </Col>
      <Col xs="32%" gutter={0}>
        <If condition={isOpen}>
          <div>{text.CONNECTED_TO}</div>
        </If>
      </Col>
    </Row>
  </div>
);

class TasksAccordion extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen,
    };
  }

  toggleAccordion = () => {
    const { isOpen } = this.state;
    const { data } = this.props;
    if (data.length === 0) {
      return;
    }
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { isOpen } = this.state;
    const {
      count,
      data,
      hasMore,
      requestInProgress,
      title,
      fetchTasks,
      fetchAllTasks,
      accordionId,
    } = this.props;
    const hasData = data.length > 0;
    return (
      <div className="fiq-tasks__accordion">
        {hasData && (
          <Accordion
            visible={isOpen}
            headerTemplate={headerTemplate(title, isOpen, this.toggleAccordion, count, accordionId)}
            title=""
          >
            <TasksAccordionBody
              accordionId={accordionId}
              fetchData={fetchTasks}
              fetchAllData={fetchAllTasks}
              tasks={data}
              hasMore={hasMore}
              isLoading={requestInProgress}
            />
          </Accordion>
        )}
        {!hasData && <NoTasksBar label={accordionId.toLowerCase()} />}
      </div>
    );
  }
}

TasksAccordion.propTypes = propTypes;
TasksAccordion.defaultProps = defaultProps;

export default TasksAccordion;
