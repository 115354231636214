import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { t } from '@lingui/macro';
import { object } from 'prop-types';
import { Button } from 'tc-biq-design-system';
import { withI18n } from '@lingui/react';
import { withAuth0 } from '@auth0/auth0-react';

import infuse_logo from 'assets/images/infuse-logo.svg';
import auth_logo from 'assets/images/auth0logo.svg';

import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import './LoginPage.scss';

const propTypes = {
  i18n: object.isRequired,
  auth0: object.isRequired,
};

const CURRENT_YEAR = new Date().getFullYear();

const text = i18n => ({
  LOGIN: i18n._(t`Login`),
  FOOTER: year => `TradeCore - ${year} Copyright`,
  TITLE: {
    prefix: 'Log in to',
    sufix: 'to continue to infuse',
  },
});

const Logo = () => (
  <div className="fiq-login__content__logo">
    <img src={infuse_logo} alt="Logo" />
  </div>
);

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.text = text(props.i18n);
  }

  componentDidMount() {
    document.title = this.text.LOGIN;
  }

  render() {
    const { auth0 } = this.props;
    const { loginWithRedirect } = auth0;
    return (
      <div className="fiq-login">
        <div className="fiq-login__content">
          {Logo()}
          <div className="fiq-login__content__card">
            <div className="fiq-login__content__card__title text-neutral-600 tc-paragraph-regular">
              {this.text.TITLE.prefix}
              <img src={auth_logo} alt="Auth0Logo" />
              {this.text.TITLE.sufix}
            </div>
            <Button onClick={() => loginWithRedirect()}>{this.text.LOGIN}</Button>
            <div className="fiq-login__content__footer text-neutral-600 tc-paragraph-regular">{this.text.FOOTER(CURRENT_YEAR)}</div>
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = propTypes;

export default withI18n()(withErrorBoundary(withAuth0(inject('loginStore')(observer(LoginPage)))));
