import React from 'react';
import { object, string } from 'prop-types';
import { isEmpty } from 'lodash';

import { ContactCell, RenderIconNameCell } from 'App/components/gridCellRenderers';
import CardDetails from './CardDetails';

import './DetailsCardWrapper.scss';

const propTypes = {
  children: object,
  contact: object.isRequired,
  wallet: object.isRequired,
  title: string,
  side: string,
  status: string,
};

const defaultProps = {
  children: null,
  title: '',
  side: '',
  status: '',
};

// eslint-disable-next-line
const DetailsCardHeader = ({ contact, wallet }) => {
  if (isEmpty(contact)) return null;
  const { first_name, last_name, id, avatar } = contact;
  const WalletCell = RenderIconNameCell({
    type: 'asset',
    route: '/transactions/wallets/',
    backgroundSize: 32,
  });
  return (
    <div className="fiq-contact-card-wrapper__header">
      <ContactCell fullName={`${first_name} ${last_name}`} id={id} avatar={avatar} />
      {!isEmpty(wallet) && <WalletCell value={wallet.asset} data={wallet} />}
    </div>
  );
};

const DetailsCardWrapper = ({ children, contact, wallet, status, side, title }) => (
  <div className="fiq-contact-card-wrapper">
    <DetailsCardHeader contact={contact} wallet={wallet} />
    <div className="fiq-contact-card-wrapper__body">
      <CardDetails status={status} side={side} title={title}>
        {children}
      </CardDetails>
    </div>
  </div>
);

DetailsCardWrapper.propTypes = propTypes;
DetailsCardWrapper.defaultProps = defaultProps;

export default DetailsCardWrapper;
