import React from 'react';
import { Space, LineChart, Detail } from 'tc-biq-design-system';
import { array, string } from 'prop-types';

const propTypes = {
  metricsData: array.isRequired,
  granularity: string,
  chartData: array.isRequired,
};

const defaultProps = {
  granularity: 'day',
};

const text = {
  HEADER: 'Performance',
};

const lines = [
  {
    type: 'monotone',
    dataKey: 'reported_as_spam',
    stroke: '#0579FC',
    strokeWidth: 2,
    dot: false,
    key: 'reported_as_spam',
  },
  {
    type: 'monotone',
    dataKey: 'unsubscribed',
    stroke: '#F5D556',
    strokeWidth: 2,
    dot: false,
    key: 'unsubscribed',
  },
  {
    type: 'monotone',
    dataKey: 'bounced',
    stroke: '#F76868',
    strokeWidth: 2,
    dot: false,
    key: 'bounced',
  },
  {
    type: 'monotone',
    dataKey: 'undelivered',
    stroke: '#0EB55F',
    strokeWidth: 2,
    dot: false,
    key: 'undelivered',
  },
];

const Performance = ({ metricsData, granularity, chartData }) => (
  <div className="fiq-campaign-overview__content">
    <Space size={18} />
    <div>
      <div style={{ padding: '20px' }} className="tc-heading-s">
        {text.HEADER}
      </div>
      <div className="fiq-campaign-overview__content__kpi-list">
        {metricsData.map(item => (
          <div key={item.display_name} className="fiq-campaign-overview__content__kpi-list__item">
            <Detail entity={item.display_name} value={item.value} reverse />
            {item.percent && <div className="percent">{item.percent}</div>}
          </div>
        ))}
      </div>
      <Space size={18} />
      <div>
        <LineChart
          dataKeyX={granularity}
          lines={lines}
          data={chartData}
        />
      </div>
    </div>
  </div>
);

Performance.propTypes = propTypes;
Performance.defaultProps = defaultProps;

export default Performance;
