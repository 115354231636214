import { inject, observer } from 'mobx-react';

import ConditionRule from './ConditionRule';

const ConditionRuleFactory = (storeKey, storeFieldKey, isEventBased) => ({
  ConditionRule: inject(stores => ({
    removeCondition: stores[storeKey][storeFieldKey].removeCondition,
    updateConditionRule: stores[storeKey][storeFieldKey].updateConditionRule,
    fields: stores[storeKey][storeFieldKey].fields,
    isEventBased,
  }))(observer(ConditionRule)),
});

export { ConditionRule };

export default ConditionRuleFactory;
