import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import GridComponentFactory from 'App/components/grid';
import { hasAccess } from 'App/services/permissionsService';
import CreateEditTrackingLink, { openCreateEditTrackingLink } from 'Marketing/components/overlays/CreateEditTrackingLink';
import PreviewTrackingLink from 'Marketing/components/overlays/PreviewTrackingLink';

import modifiers from './modifiers';


const tableConfig = {
  tableKey: 'trackingLinks',
  tableUrl: '/marketing/tracking-links/',
};

const text = {
  EDIT: 'Edit',
  CLONE: 'Clone',
  START: 'Start',
  DELETE: 'Delete tracking link',
  PREVIEW: 'Preview',
  DELETE_SUCCESS: 'Successfully deleted tracking link',
  DELETE_FAILED: 'Field to delete tracking link',
};

const customColumns = [
  {
    key: 'actions',
    headerName: '',
  },
];

const singleActions = actions => data => [
  {
    data: data.id,
    label: text.EDIT,
    action: itemData => actions.edit(itemData),
    icon: 'Edit',
    visibility: hasAccess('marketing_trackinglink', 'update'),
  },
  {
    data: data.id,
    label: text.CLONE,
    action: itemData => actions.clone(itemData),
    icon: 'Duplicate',
    visibility: hasAccess('marketing_trackinglink', 'create'),
  },
].filter(action => action.visibility);

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

// eslint-disable-next-line react/prop-types
const TrackingLinksTable = ({ match }) => {
  const edit = data => openCreateEditTrackingLink(data, false);
  const clone = data => openCreateEditTrackingLink(data, true);

  useEffect(() => {
    if (match.params.action === 'create') openCreateEditTrackingLink(null, null);
  }, []);

  return (
    <Fragment>
      <GridComponent
        modifiers={modifiers(singleActions({ edit, clone }))}
        hideColumnManager
        customColumns={customColumns}
      />
      <CreateEditTrackingLink onSuccess={gridActions.fetchTableData} />
      <PreviewTrackingLink />
    </Fragment>
  );
};

export default withRouter(TrackingLinksTable);
