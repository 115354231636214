import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import GridComponentFactory from 'App/components/grid';
import CreateEditCustomProperty, { openCreateEditCustomProperty } from 'Settings/Sections/CustomProperties/components/overlays';
import { hasAccess } from 'App/services/permissionsService';
import modifiers from './modifiers';

const propTypes = {
};

const text = {
  EDIT: 'Edit property',
  CLONE: 'Clone',
};

const tableConfig = {
  tableUrl: '/custom-properties/',
  tableKey: 'customProperties',
};

const customColumns = [
  {
    key: 'actions',
    headerName: '',
  },
];

const listToOmit = ['permissions', 'created', 'updated'];

const singleActions = () => data => [
  {
    data: data.id,
    label: text.EDIT,
    action: id => openCreateEditCustomProperty(id),
    icon: 'Edit',
    visibility: hasAccess('custom_properties_customproperty', 'update'),
  },
].filter(action => action.visibility);

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const CustomPropertiesTable = () => (
  <Fragment>
    <GridComponent
      customColumns={customColumns}
      modifiers={modifiers(singleActions())}
      listToOmit={listToOmit}
    />
    <CreateEditCustomProperty onSuccess={gridActions.fetchTableData} />
  </Fragment>
);

CustomPropertiesTable.propTypes = propTypes;

export default withRouter(CustomPropertiesTable);
