import React, { useCallback, useState, useEffect } from 'react';
import { object, string, array, shape, func } from 'prop-types';
import { Icon } from 'tc-biq-design-system';
import classnames from 'classnames';

import fieldTypes from 'App/enums/fieldTypes';
import isEmpty from 'lodash/isEmpty';
import FilterInput from '../Filters/FilterInput';

import './QuickFilter.scss';

const enterInputs = [
  fieldTypes.STRING,
  fieldTypes.INTEGER,
  fieldTypes.FLOAT,
  fieldTypes.DECIMAL,
  fieldTypes.EMAIL,
];

const operatorShape = {
  value: string.isRequired,
  label: string.isRequired,
  relatedModels: array,
  activeFilters: array,
};

const propTypes = {
  field: object.isRequired,
  fields: object.isRequired,
  operator: shape(operatorShape).isRequired,
  applyFilter: func,
  removeFilter: func,
  activeFilters: array,
};

const defaultProps = {
  applyFilter: () => null,
  removeFilter: () => null,
  activeFilters: [],
};

const QuickFilter = ({ field, fields, operator, applyFilter, removeFilter, activeFilters }) => {
  const [value, setValue] = useState(undefined);
  // Apply change
  const onApply = useCallback((data) => {
    applyFilter({
      field,
      operator,
      value: data,
    });
  }, [field, operator, value]);

  // Set value and apply changes
  const onChange = useCallback((data) => {
    setValue(data);
    const { type } = field;
    if (!enterInputs.includes(type)) {
      onApply(data);
    }
  }, [onApply]);

  // Clear filter
  const onClear = useCallback(() => {
    removeFilter({
      field,
      operator,
      value,
    });
    setValue(undefined);
  }, [removeFilter, field, value, operator]);

  useEffect(() => {
    const activeFilter = activeFilters.find(
      ({ field: f, operator: o }) => f.value === field.value && o.value === operator.value,
    );
    if (activeFilter) {
      setValue(activeFilter.value);
    } else if (value) {
      setValue(undefined);
    }
  }, [activeFilters]);

  const showClear = !isEmpty(value);
  return (
    <div className="fiq-quick-filter">
      <div className="fiq-quick-filter__input">
        <FilterInput
          selectedField={field}
          fields={fields}
          fieldLabel={field.label}
          operator={operator}
          relatedModels={field.related_models}
          onChange={onChange}
          onEnter={onApply}
          value={value}
          withPortal
        />
      </div>
      <div className={classnames('fiq-quick-filter__clear', { 'fiq-quick-filter__clear--hide': !showClear })}>
        <Icon name="Close" size="small" onClick={onClear} />
      </div>
    </div>
  );
};

QuickFilter.propTypes = propTypes;
QuickFilter.defaultProps = defaultProps;

export default QuickFilter;
