import React from 'react';
import { bool } from 'prop-types';
import { Pill } from 'tc-biq-design-system';

const propTypes = {
  value: bool,
};

const defaultProps = {
  value: null,
};

const valueGetter = (value) => {
  const map = {
    true: 'Yes',
    false: 'No',
  };
  return map[value] || value;
};

const types = {
  true: 'status01',
  false: 'status04',
};

const YesNo = ({ value }) => (value !== null
  ? <Pill type={types[value]}>{valueGetter(value)}</Pill> : <div>-</div>);

YesNo.propTypes = propTypes;
YesNo.defaultProps = defaultProps;
export default YesNo;
