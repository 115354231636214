import React from 'react';
import { Icon } from 'tc-biq-design-system';
import { string, func } from 'prop-types';

import './ActionItem.scss';

const propTypes = {
  icon: string.isRequired,
  onClick: func.isRequired,
  label: string,
};

const defaultProps = {
  label: '',
};

const ActionItem = ({ icon, label, onClick }) => (
  <span title={label} className="fiq-action-item" onClick={onClick}>
    <Icon name={icon} colorName="text-primary-500" />
  </span>
);

ActionItem.propTypes = propTypes;
ActionItem.defaultProps = defaultProps;

export default ActionItem;
