import compose from 'lodash/fp/compose';
import getRelatedModels from './getRelatedModels';

const formatLookups = (selectedField, allFields) => {
  const { lookups, related_models, related_model } = allFields[selectedField.value];
  return {
    lookups,
    related_models: getRelatedModels(related_models, related_model),
  };
};

const formatOperatorsOptions = ({ lookups, related_models }) => {
  const queryOptions = Object.keys(lookups).map(key => ({
    label: lookups[key],
    value: key,
    related_models,
  }));
  return queryOptions;
};

const formatOperators = compose(
  formatOperatorsOptions,
  formatLookups,
);

export default formatOperators;
