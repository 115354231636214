import React, { PureComponent } from 'react';

import Page from 'App/components/Page';
import TradesTable, { tableConfig } from 'Transactions/components/TradesTable';

const bread = [{ label: 'Transactions' }, { label: 'Trades', route: '/transactions/trades' }];
const exportDataOptions = { permission: 'finances_tradetransaction', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };
class TradesPage extends PureComponent {
  render() {
    return (
      <Page bread={bread} title="Trades" style={{ margin: '0 20px' }} exportDataOptions={exportDataOptions}>
        <TradesTable />
      </Page>
    );
  }
}

export default TradesPage;
