import React, { useEffect } from 'react';
import { Icon, Space } from 'tc-biq-design-system';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { array, bool, func, number } from 'prop-types';
import classNames from 'classnames';

import { formatTitle } from 'App/services/utilities/dateGroupUtils';

import './UserNotifications.scss';
import Loader from 'App/components/Loader/Loader';

const propTypes = {
  notifications: array.isRequired,
  isLoading: bool.isRequired,
  getSocketNotifications: func.isRequired,
  socketNotificationsCount: number.isRequired,
};

const text = {
  SEE_ALL: 'See all notifications',
  FETCH_FAILED: 'Failed to fetch notifications',
  NO_NOTIFICATIONS: 'No new notifications',
};

const UserNotificationsDropdown = ({
  isLoading,
  notifications,
  getSocketNotifications,
  socketNotificationsCount,
}) => {
  const fetchData = async () => {
    await getSocketNotifications();
  };

  useEffect(() => {
    fetchData();
  }, [socketNotificationsCount]);

  return (
    <div className="fiq-user-notifications__dropdown">
      <Loader visible={isLoading} />
      {notifications.length === 0 && !isLoading && (
        <li className="fiq-user-notifications__no-data">
          <div className="tc-paragraph-strong text-primary-700">{text.NO_NOTIFICATIONS}</div>
        </li>
      )}
      {notifications.map(notification => (
        <li
          key={notification.id}
          className={classNames('fiq-user-notifications__item', {
            'fiq-user-notifications__item--active': notification.highlighted,
          })}
        >
          <div>
            <Icon colorName="text-primary-500" bgColorName="bck-primary-100" name="At" />
          </div>
          <Space size={8} />
          <div className="content">{formatTitle(notification)}</div>
        </li>
      ))}
      <Link to="/user/notifications" style={{ textDecoration: 'none' }}>
        <li className="fiq-user-notifications__see-all">
          <div className="text-primary-500 tc-paragraph-strong">{text.SEE_ALL}</div>
        </li>
      </Link>
    </div>
  );
};

UserNotificationsDropdown.propTypes = propTypes;

export default inject(stores => ({
  fetchNotifications: stores.userNotifications.fetchNotifications,
  isLoading: stores.userNotifications.requestInProgress.notifications,
  notifications: stores.userNotifications.socketNotifications,
  socketNotificationsCount: stores.userNotifications.socketNotificationsCount,
  getSocketNotifications: stores.userNotifications.getSocketNotifications,
}))(observer(UserNotificationsDropdown));
