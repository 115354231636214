import React from 'react';
import { Checkbox, Space } from 'tc-biq-design-system';
import { func, string, any, number } from 'prop-types';
import omit from 'lodash/omit';

const propTypes = {
  onChange: func.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  value: any,
  afterFieldSpaceSize: number,
};

const defaultProps = {
  value: false,
  afterFieldSpaceSize: 18,
};

const CheckboxField = (props) => {
  const { name, description, onChange, value, afterFieldSpaceSize } = props;
  const fieldProps = omit(props, ['read_only', 'formId', 'afterFieldSpaceSize']);
  return (
    <div data-cy={name}>
      <Checkbox {...fieldProps} checked={value} onChange={e => onChange(name, e.target.checked)}>
        {description}
      </Checkbox>
      <Space size={afterFieldSpaceSize} />
    </div>
  );
};

CheckboxField.propTypes = propTypes;
CheckboxField.defaultProps = defaultProps;

export default CheckboxField;
