import React, { useEffect, useRef, useState } from 'react';
import { Space, Button, Icon, Select } from 'tc-biq-design-system';
import { func, bool, any, string, object } from 'prop-types';
import classNames from 'classnames';
import { get, isEmpty } from 'lodash';
import templatePropertyResolver from 'App/services/utilities/templatePropertyResolver';

const propTypes = {
  updateFieldValue: func.isRequired,
  closeDropdown: func.isRequired,
  hidden: bool.isRequired,
  children: any.isRequired,
  fieldKey: string.isRequired,
  attributes: object,
  onChange: func,
};

const defaultProps = {
  onChange: null,
  attributes: {},
};

const text = {
  PLACEHOLDER: 'Select attribute',
  ADD_ATTRIBUTE: 'Add attribute',
  ADD_EVENT_ATTRIBUTE: 'Add event attribute',
};

function isReactSelect(element) {
  if (!element) return false;
  const isCloseIcon = element.classList.contains('Select-value-icon');
  const isReactSelectEl = element.id.indexOf('react-select') !== -1;
  if (isReactSelectEl || isCloseIcon) {
    return true;
  }
  return false;
}

const AddEventAttributeDropdown = ({
  closeDropdown,
  hidden,
  updateFieldValue,
  fieldKey,
  children,
  attributes,
  onChange,
}) => {
  const dropdownRef = useRef(null);
  const textareaRef = useRef(null);
  const [event, setEvent] = useState(null);
  const [eventOptions, setEventOptions] = useState([]);

  const loadOptions = () => {
    let textareaValue = '';
    if (textareaRef) {
      textareaValue = get(textareaRef.current.children[0], 'children[0].children[1].value')
                   || get(textareaRef.current.children[0], 'children[1].children[0].value')
                   || '';
    }
    const formatedAttributes = isEmpty(attributes) ? [] : templatePropertyResolver(attributes);
    const options = formatedAttributes.filter(
      attrEvent => !textareaValue.includes(attrEvent.value),
    );
    setEventOptions(options);
  };

  const handleClick = ({ target }) => {
    if (dropdownRef.current?.contains(target) || isReactSelect(target)) return;
    close();
  };

  const close = () => {
    setEvent(null);
    closeDropdown();
  };

  const handleOnChange = (value) => {
    setEvent(value);
  };

  const add = () => {
    let fieldValue = '';
    const formattedValue = ` ${event.value} `;
    const textarea = get(textareaRef.current.children[0], 'children[0].children[1]')
                  || get(textareaRef.current.children[0], 'children[1].children[0]');
    if (textarea.selectionStart || textarea.selectionStart === 0) {
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      fieldValue = textarea.value.substring(0, startPos)
        + formattedValue
        + textarea.value.substring(endPos, textarea.value.length);
    } else {
      fieldValue += formattedValue;
    }
    updateFieldValue(fieldKey, fieldValue);
    if (onChange) onChange(fieldKey, fieldValue);
    closeDropdown();
  };

  useEffect(() => {
    if (hidden) {
      setEvent(null);
      loadOptions();
      document.removeEventListener('mousedown', handleClick, false);
    } else {
      document.addEventListener('mousedown', handleClick, false);
    }
  }, [hidden, attributes]);

  return (
    <div className="fiq-add-event-attribute__wrapper">
      <div
        ref={dropdownRef}
        className={classNames('fiq-add-event-attribute__dropdown', {
          hidden,
        })}
      >
        <div className="fiq-add-event-attribute__heading">
          <span className="tc-paragraph-strong">{text.ADD_EVENT_ATTRIBUTE}</span>
          <span onClick={close}>
            <Icon name="Close" size="small" />
          </span>
        </div>
        {!hidden && (
        <Select
          type="search"
          labelKey="display_name"
          valueKey="value"
          options={eventOptions}
          placeholder={text.PLACEHOLDER}
          value={event}
          onChange={handleOnChange}
        />
        )}
        <Space size={12} />
        <Button style={{ margin: '0' }} onClick={add}>
          {text.ADD_ATTRIBUTE}
        </Button>
        <Space size={12} />
      </div>
      <div ref={textareaRef}>{children}</div>
    </div>
  );
};

AddEventAttributeDropdown.propTypes = propTypes;
AddEventAttributeDropdown.defaultProps = defaultProps;

export default AddEventAttributeDropdown;
