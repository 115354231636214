import React from 'react';
import { MultiActions, Pill } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import hideCols from 'App/services/utilities/hideCols';
import moment from 'moment';

import { UserCell, StatusCell, RouteCell, DateTimeCell } from 'App/components/gridCellRenderers';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import NoDataDash from 'App/components/NoDataDash';

// eslint-disable-next-line react/prop-types
const MultiActionCell = getActions => withRouter(({ data, history }) => {
  const actions = getActions(data, history);
  if (actions.length === 0) return null;
  return <MultiActions actions={actions} />;
});

// eslint-disable-next-line react/prop-types
const CampaignNameCell = ({ data, value }) => {
  if (data.status === 'Draft') {
    return RouteCell('/marketing/campaigns/')({ hrefValue: data.id, value });
  }

  return RouteCell('/marketing/campaigns-overview/')({ hrefValue: data.id, value });
};

// eslint-disable-next-line react/prop-types
const RenderGoalCount = ({ data }) => (
  <Pill type="neutral">{`${data.contacts_fulfilled_goal_count} / ${data.contacts_count}`}</Pill>
);

// eslint-disable-next-line react/prop-types
const TimeCell = ({ value }) => (value ? (
  <span>
    {moment
      .utc(value, 'HH:mm')
      .local()
      .format('HH:mm')}
  </span>
) : (
  <span>-</span>
));

const modifiers = actions => ({
  ...hideCols([
    'id',
    'goal_query',
    'goal_attribution_window',
    'send_to_type',
    'send_to_query',
    'template',
    'send_to_segment',
    'email_message',
    'messanger_message',
    'duration',
    'description',
    'created',
    'updated',
    'recurring_type_value',
  ]),
  name: { cellRendererFramework: CampaignNameCell },
  actions: {
    cellRendererFramework: MultiActionCell(actions),
    pinned: 'right',
    width: 50,
    unSortIcon: false,
    headerName: '',
    valueGetter(params) {
      return params.data.status;
    },
  },
  goal_event_type: { cellRendererFramework: RenderGoalCount, headerName: 'Goal' },
  updated_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => data.updated_by ? (
      <UserCell
        id={data.updated_by.id}
        avatar={data.updated_by.avatar}
        fullName={extractDisplayName(data.updated_by)}
        subTitle={<DateTimeCell value={data.updated} />}
      />
    ) : (
      <NoDataDash />
    ),
  },
  recurring_time: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => <TimeCell value={value} />,
  },
  created_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => (
      <UserCell
        id={data.created_by.id}
        avatar={data.created_by.avatar}
        fullName={extractDisplayName(data.created_by)}
        subTitle={<DateTimeCell value={data.created} />}
      />
    ),
  },
  status: { cellRendererFramework: StatusCell },
  // eslint-disable-next-line react/prop-types
  channel: { cellRendererFramework: ({ value }) => <Pill type="neutral">{value}</Pill> },
});

export default modifiers;
