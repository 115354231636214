import G6 from '@antv/g6';
import isEmpty from 'lodash/isEmpty';

import three_dots from 'assets/images/block-three-dots.svg';
import { getBlockIcon, truncateText } from '../utils';
import { blockActionIconMap, BLOCK_ACTION, BLOCK_STATE, BLOCK_TYPE } from '../consts';

const style = {
  RECT_WRAPPER: {
    DEFAULT: {
      fill: '#fff',
      stroke: 'rgba(0,0,0, 0.03)',
      shadowOffsetX: 0,
      shadowOffsetY: 4,
      shadowColor: 'rgba(0, 0, 0, 0.06)',
      shadowBlur: 8,
      radius: 6,
    },
  },
  TITLE: {
    DEFAULT: {
      font: 'Source Sans Pro,Arial,sans-serif',
      fontSize: 12,
      lineHeight: 20,
      fontWeight: 400,
      textBaseline: 'top',
      fill: 'rgb(0,0,0, 0.8)',
    },
  },
  DESC: {
    DEFAULT: {
      font: 'Source Sans Pro,Arial,sans-serif',
      fontSize: 12,
      lineHeight: 20,
      fontWeight: 400,
      textBaseline: 'top',
      fill: '#a3b0bf',
    },
  },
  RECT_DROPDOWN_BUTTON: {
    DEFAULT: {
      stroke: '#d1d5db',
      fill: 'white',
      cursor: 'pointer',
      radius: 6,
    },
  },
  DROPDOWN_BACKGROUND: {
    DEFAULT: {
      shadowOffsetX: 0,
      shadowOffsetY: 4,
      shadowColor: 'rgba(0, 0, 0, 0.06)',
      shadowBlur: 8,
      radius: 6,
      fill: '#fff',
      stroke: 'rgba(0,0,0, 0.03)',
    },
  },
  DROPDOWN_ACTION: {
    DEFAULT: {
      radius: 6,
      cursor: 'pointer',
      fill: 'rgba(0,0,0,0)',
    },
  },
  DROPDOWN_ITEM_TEXT: {
    DEFAULT: {
      font: 'Source Sans Pro,Arial,sans-serif',
      fontSize: 12,
      lineHeight: 20,
      fontWeight: 400,
      textBaseline: 'top',
      fill: 'rgb(0,0,0, 0.8)',
    },
  },
};

const stateStyles = {
  [BLOCK_STATE.BLOCK_SELECTED]: {
    stroke: 'rgba(0, 107, 230, 0.5)',
  },
  [BLOCK_STATE.BLOCK_DISABLED]: {
    opacity: 0.7,
  },
};

G6.registerNode('workflowTriggerNode', {
  draw(cfg, group) {
    const { blockType } = cfg;
    const width = 40;
    const height = 40;
    const baseX = -width / 2;
    const baseY = -height / 2;

    const container = group.addShape('rect', {
      attrs: {
        x: baseX,
        y: baseY,
        width,
        height,
        ...style.RECT_WRAPPER.DEFAULT,
      },
      name: 'trigger-wrapper',
    });

    const img = getBlockIcon(blockType, cfg.payload?.properties);
    const imgWidth = 18;
    const imgHeight = 18;
    const imgX = baseX + (width - imgWidth) / 2;
    const imgY = baseY + (height - imgHeight) / 2;

    if (img) {
      group.addShape('image', {
        attrs: {
          x: imgX,
          y: imgY,
          width: imgWidth,
          height: imgHeight,
          img,
        },
        name: 'trigger-image',
      });
    }

    return container;
  },
});

G6.registerNode(
  'workflowDefaultNode',
  {
    shapeType: 'workflowDefaultNode',
    getDimensions(cfg) {
      const { blockType, size } = cfg;
      const [w, h] = size;
      let width = w;
      let height = h;
      if (blockType === BLOCK_TYPE.EXIT) {
        width *= 0.6;
        height *= 0.75;
      }
      const baseX = -width / 2;
      const baseY = -height / 2;

      return {
        width,
        height,
        baseX,
        baseY,
      };
    },
    drawShape(cfg, group) {
      const { blockType, isOverview } = cfg;
      const cursor = blockType === BLOCK_TYPE.EXIT ? 'default' : 'pointer';
      const { width, height, baseX, baseY } = this.getDimensions(cfg);

      // Wrapper
      const container = group.addShape('rect', {
        attrs: {
          x: baseX,
          y: baseY,
          radius: 6,
          width,
          height,
          cursor,
          ...style.RECT_WRAPPER.DEFAULT,
        },
        name: 'node-wrapper',
      });

      const img = getBlockIcon(blockType, cfg.payload?.properties);
      const imgSize = 28;
      const imgX = baseX + 16;
      const imgY = baseY + (height - imgSize) / 2;
      const imgWidth = imgSize;
      const imgHeight = imgSize;

      // Block Icon
      if (img) {
        group.addShape('image', {
          attrs: {
            x: imgX,
            y: imgY,
            width: imgWidth,
            height: imgHeight,
            img,
          },
          name: 'node-image',
        });
      }

      const { text, description } = cfg;
      const textX = imgX + imgSize + 20;
      let textY = imgY;

      // Block title
      if (text) {
        if (blockType === BLOCK_TYPE.EXIT && !description) {
          textY += 10;
        }
        group.addShape('text', {
          attrs: {
            x: textX,
            y: textY,
            text: truncateText(text, 33),
            ...style.TITLE.DEFAULT,
          },
          name: 'node-title',
        });
      }

      // Block description

      if (description) {
        group.addShape('text', {
          attrs: {
            x: textX,
            y: textY + 20,
            text: description,
            ...style.DESC.DEFAULT,
          },
          name: 'node-description',
        });
      }

      // Dropdown
      if (!isEmpty(cfg.actions) && !isOverview) {
        const dropdownButtonX = baseX + width - 35;
        const dropdownButtonY = baseY + 10;
        const dropdownButtonWidth = 24;
        const dropdownButtonHeight = 24;
        const dropdownButtonImgWidth = 4;
        const dropdownButtonImgHeight = 12;

        // Dropdown button
        group.addShape('rect', {
          attrs: {
            x: dropdownButtonX,
            y: dropdownButtonY,
            width: dropdownButtonWidth,
            height: dropdownButtonHeight,
            ...style.RECT_DROPDOWN_BUTTON.DEFAULT,
            action: BLOCK_ACTION.TOGGLE_DROPDOWN,
          },
          name: 'node-dropdown-button',
        });

        group.addShape('image', {
          attrs: {
            x: dropdownButtonX + (dropdownButtonWidth - dropdownButtonImgWidth) / 2,
            y: dropdownButtonY + (dropdownButtonHeight - dropdownButtonImgHeight) / 2,
            width: dropdownButtonImgWidth,
            height: dropdownButtonImgHeight,
            img: three_dots,
            cursor: 'pointer',
            action: BLOCK_ACTION.TOGGLE_DROPDOWN,
          },
          name: 'node-dropdown-image',
        });

        // Dropdown menu
        this.drawDropdown(cfg, group);
      }

      return container;
    },
    drawDropdown(cfg, group) {
      const { dropdown } = this.options;
      const { width, baseX, baseY } = this.getDimensions(cfg);
      const { dropdown: { open: openDropdown } = {} } = cfg;
      const dropdownButtonY = baseY + 10;
      const dropdownButtonHeight = 24;
      const dropdownItemHeight = 40;
      const dropdownSeparatorHeight = 1;
      const dropdownWidth = 224;
      const dropdownHeight = cfg.actions.length * (dropdownItemHeight + dropdownSeparatorHeight);
      const dropdownX = baseX + (width - dropdownWidth);
      const dropdownY = dropdownButtonY + dropdownButtonHeight + 5;

      if (dropdown.open || openDropdown) {
        group.toFront();
        const dropdownGroup = group.addGroup({
          id: 'dropdown-group',
        });
        dropdownGroup.addShape('rect', {
          attrs: {
            x: dropdownX,
            y: dropdownY,
            width: dropdownWidth,
            height: dropdownHeight,
            ...style.DROPDOWN_BACKGROUND.DEFAULT,
          },
          name: 'node-dropdown-background',
        });

        cfg.actions.forEach((action, i) => {
          const dropdownItemX = dropdownX;
          const dropdownItemY = dropdownY + dropdownItemHeight * i + dropdownSeparatorHeight * i;

          const dropdownImage = blockActionIconMap[action];
          dropdownGroup.addShape('rect', {
            attrs: {
              x: dropdownItemX,
              y: dropdownItemY,
              width: dropdownWidth,
              height: dropdownItemHeight,
              ...style.DROPDOWN_ACTION.DEFAULT,
              action,
            },
            name: `node-dropdown-action-${action}`,
          });

          dropdownGroup.addShape('image', {
            attrs: {
              x: dropdownItemX + 10,
              y: dropdownItemY + 10,
              img: dropdownImage,
              width: 20,
              height: 20,
              action,
            },
            name: `node-dropdown-action-image-${action}`,
          });

          dropdownGroup.addShape('text', {
            attrs: {
              x: dropdownItemX + 40,
              y: dropdownItemY + 15,
              text: action,
              ...style.DROPDOWN_ITEM_TEXT.DEFAULT,
              action,
            },
            name: `node-dropdown-action-text-${action}`,
          });

          dropdownGroup.addShape('rect', {
            attrs: {
              x: dropdownItemX,
              y: dropdownItemY + dropdownItemHeight,
              width: dropdownWidth,
              height: dropdownSeparatorHeight,
              fill: '#f3f4f6',
              opacity: 0,
            },
            name: `node-dropdown-separator-${action}`,
          });
        });
      }
    },
    update(cfg, node) {
      const {
        dropdown: { open: openDropdown },
      } = cfg;
      const group = node.getContainer();
      const dropdownGroup = group.find(element => element.get('id') === 'dropdown-group');
      if (dropdownGroup && !openDropdown) {
        dropdownGroup.remove();
      } else if (!dropdownGroup && openDropdown) {
        this.drawDropdown(cfg, group);
      }
    },
    options: {
      dropdown: {
        open: false,
      },
      stateStyles: {
        ...stateStyles,
      },
    },
  },
  'single-node',
);
