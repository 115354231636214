import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Spinner, Space, Pill, Row, Col } from 'tc-biq-design-system';
import { object, func, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';

import Page from 'App/components/Page/Page';
import WebhookLogsTable from '../../components/WebhookLogsTable';
import PreviewParameters, {
  openPreviewParameters,
} from '../../components/overlays/PreviewParameters/PreviewParameters';

import './WebhookSinglePage.scss';

const text = {
  WEBHOOK: 'Webhook',
  TRIGGER_EVENT: 'Trigger event',
  COPY: 'Copy',
};

const tableConfig = webhookId => ({
  tableUrl: `/automations/webhooks/${webhookId}/logs/`,
  tableKey: 'webhookSingleLogs',
});

const propTypes = {
  match: object.isRequired,
  fetchWebhookData: func.isRequired,
  webhook: object.isRequired,
  isLoading: bool.isRequired,
};

const WebhookSinglePage = ({ match, fetchWebhookData, webhook, isLoading }) => {
  const {
    params: { webhookId },
  } = match;

  useEffect(() => {
    fetchWebhookData(webhookId);
  }, [webhookId]);

  const { name, url, trigger_events } = webhook;

  return (
    <Page
      title={`${text.WEBHOOK} ${name || ''}`}
      style={{ minHeight: 'calc(100vh - 142px)', overflow: 'visible' }}
    >
      <Row className="fiq-single-webhook" style={{ justifyContent: 'center' }}>
        <Col lg="80%" md="100%" sm="100%">
          <div className="fiq-single-webhook">
            {isLoading && <Spinner />}
            {!isLoading && (
              <div className="fiq-webhook-page">
                <div className="fiq-single-webhook__header">
                  <p className="tc-heading-m">{name}</p>
                  <p>{url}</p>
                </div>
                <Space size={24} />
                <div className="fiq-single-webhook__trigger">
                  <p>{text.TRIGGER_EVENT}</p>
                  <div className="fiq-single-webhook__trigger-events">
                    {(trigger_events || []).map(event => (
                      <Pill
                        key={event.id}
                        onClick={() => {
                          openPreviewParameters(event);
                        }}
                      >
                        {event.name}
                      </Pill>
                    ))}
                  </div>
                </div>
                <Space size={24} />
                {webhookId && (
                  <WebhookLogsTable height="45vh" tableConfig={tableConfig(webhookId)} />
                )}
              </div>
            )}
          </div>
          <PreviewParameters />
        </Col>
      </Row>
    </Page>
  );
};

WebhookSinglePage.propTypes = propTypes;

export default inject(stores => ({
  fetchWebhookData: stores.webhooks.fetchWebhookData,
  webhook: stores.webhooks.webhook,
  isLoading: stores.webhooks.requestInProgress.fetchWebhook,
}))(observer(withRouter(WebhookSinglePage)));
