import React from 'react';
import { Input } from 'tc-biq-design-system';
import { func, number, string, oneOfType } from 'prop-types';
import { omit } from 'lodash';

const propTypes = {
  onChange: func.isRequired,
  value: oneOfType([number, string]),
  name: string.isRequired,
};

const defaultProps = { value: '' };

const NumberField = (props) => {
  const { onChange, value, name } = props;
  const fieldProps = omit(props, ['read_only', 'formId', 'visible', 'many', 'custom_property']);

  const disableNonNumbers = (event) => {
    if (!value && (event.keyCode === 69 || event.keyCode === 190)) {
      event.preventDefault();
    }
  };

  // Validates number input so that it matches the following regexp
  // It matches any amount of numbers followed by an optional dot and up to 8 numbers after the dot
  const validateInput = (event) => {
    const nextValue = event.target.value;
    const pattern = new RegExp(/^\d*.?\d{0,8}$/);
    if (nextValue && pattern.test(nextValue)) return parseFloat(nextValue);
    return '';
  };

  return (
    <Input
      {...fieldProps}
      type="number"
      onKeyDown={e => disableNonNumbers(e)}
      onChange={e => onChange(name, validateInput(e))}
    />
  );
};

NumberField.propTypes = propTypes;
NumberField.defaultProps = defaultProps;

export default NumberField;
