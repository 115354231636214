/* eslint-disable react/prop-types */
import React from 'react';
import { MultiActions, Pill } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import hideCols from 'App/services/utilities/hideCols';

import { UserCell, DateTimeCell } from 'App/components/gridCellRenderers';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import NoDataDash from 'App/components/NoDataDash';
import stores from 'App/rootStore';
import { openPreviewTrackingLink } from 'Marketing/components/overlays/PreviewTrackingLink';

const contactsTableKey = 'contacts';

const filter = data => ({
  field: {
    label: 'Tracking link ID',
    type: 'string',
    value: 'tracking_link__id',
  },
  operator: {
    label: 'is equal to',
    related_models: null,
    value: 'exact',
  },
  value: data.id,
});

const MultiActionCell = getActions => withRouter(({ data, history }) => {
  const actions = getActions(data, history);
  if (actions.length === 0) return null;
  return <MultiActions actions={actions} />;
});

const TrackingLinkNameCell = (props) => {
  const { value, node } = props;
  const { data } = node;
  if (!value) return <div>-</div>;
  return (
    <div onClick={() => openPreviewTrackingLink(data)} className="tc-paragraph-regular">
      <div className="like-a-link">{value}</div>
    </div>
  );
};

export const ContactCountRenderer = withRouter(({ data, history }) => {
  const trackingLinkFilter = filter(data);
  const onClick = () => {
    stores.tables[contactsTableKey].filters.addNewFilter(trackingLinkFilter);
    history.push('/contacts/');
  };
  return (
    <Pill
      style={{ width: '50px', display: 'flex', justifyContent: 'space-between' }}
      icon="Client"
      color="neutral-400"
      onClick={onClick}
      onIconClick={onClick}
    >
      {data.contacts}
    </Pill>
  );
});

const modifiers = actions => ({
  ...hideCols([
    'id',
    'description',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'created',
    'updated',
    'tracking_id',
    'link_visits',
    'audience',
  ]),
  name: { cellRendererFramework: TrackingLinkNameCell },
  actions: {
    cellRendererFramework: MultiActionCell(actions),
    pinned: 'right',
    width: 50,
    unSortIcon: false,
    headerName: '',
  },
  updated_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => data.updated_by ? (
      <UserCell
        id={data.updated_by.id}
        avatar={data.updated_by.avatar}
        fullName={extractDisplayName(data.updated_by)}
        subTitle={<DateTimeCell value={data.updated} />}
      />
    ) : (
      <NoDataDash />
    ),
  },
  created_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => (
      <UserCell
        id={data.created_by.id}
        avatar={data.created_by.avatar}
        fullName={extractDisplayName(data.created_by)}
        subTitle={<DateTimeCell value={data.created} />}
      />
    ),
  },
  contacts: { cellRendererFramework: ContactCountRenderer },
  url: {
    unSortIcon: false,
    sortable: false,
  },
});

export default modifiers;
