import React, { Fragment, useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Sidepanel, Space, Button, notifier, InfoBox, Icon } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { any, func, object } from 'prop-types';

import { omit, isEmpty } from 'lodash';

import toSnakeCase from 'App/services/utilities/toSnakeCase';
import {
  fetchCustomProperty,
  fetchCustomPropertiesOptions,
  createCustomProperty,
  editCustomProperty,
} from 'Settings/Sections/CustomProperties/services/CustomPropertiesService';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import run from '../../../../../App/services/utilities/run';
import formatPayload from '../../../../../App/services/utilities/formatPayload';

import './CreateEditCustomProperty.scss';

const propTypes = {
  visible: any,
  parameters: object,
  onSuccess: func.isRequired,
  form: object.isRequired,
  setFieldsErrors: func.isRequired,
  updateFieldValue: func.isRequired,
  resetFieldsData: func.isRequired,
  setFieldsData: func.isRequired,
  validateForm: func.isRequired,
};

const defaultProps = { visible: null, parameters: {} };

const SIDEPANEL_ID = 'CREATE_EDIT_CUSTOM_PROPERTY';

const text = {
  DISCARD: 'Discard',
  EDIT_CUSTOM_PROPERTY: 'Save changes',
  CREATE_CUSTOM_PROPERTY: 'Create property',
  SUCCESS_EDIT: 'Successfully edited property',
  SUCCESS_CREATE: 'Successfully created property',
  WARNING_MESSAGE:
    'Once created, the Custom property can’t be deleted. Make sure your input is correct.',
  FIELD_ATTRIBUTES: 'Field attributes',
};

const editableFields = ['label', 'description'];

export const openCreateEditCustomProperty = customPropertyId => openOverlay(SIDEPANEL_ID, {
  customPropertyId });
export const closeCreateEditCustomProperty = () => closeOverlay(SIDEPANEL_ID);

// eslint-disable-next-line
const CustomFooter = ({ execute, cancel, hasErrors, isEdit }) => (
  <Fragment>
    <Button color="ghost" onClick={cancel}>
      {text.DISCARD}
    </Button>
    <Button disabled={hasErrors} onClick={execute}>
      {isEdit ? text.EDIT_CUSTOM_PROPERTY : text.CREATE_CUSTOM_PROPERTY}
    </Button>
  </Fragment>
);

const CreateEditCustomProperty = ({
  visible, parameters, onSuccess, form,
  setFieldsErrors, updateFieldValue, resetFieldsData,
  setFieldsData, validateForm,
}) => {
  const ref = useRef({
    isNameEdited: false,
  });
  const [fields, setFields] = useState({});
  const { customPropertyId } = parameters;
  const isEdit = !!customPropertyId;

  const close = () => {
    ref.current.isNameEdited = false;
    resetFieldsData();
    closeCreateEditCustomProperty();
  };

  const edit = async () => {
    if (!validateForm()) return;
    const { data } = form;
    const payload = formatPayload(data);
    const [err, response] = await run(editCustomProperty(customPropertyId, payload));
    if (err) {
      setFieldsErrors(err.response.data);
      return;
    }
    if (response) {
      onSuccess();
      notifier.success(text.SUCCESS_EDIT);
      close();
    }
  };

  const create = async () => {
    if (!validateForm()) return;
    const { data } = form;
    const payload = formatPayload(data);
    const [err, response] = await run(createCustomProperty(payload));
    if (err) {
      setFieldsErrors(err.response.data);
      return;
    }
    if (response) {
      onSuccess();
      notifier.success(text.SUCCESS_CREATE);
      close();
    }
  };

  useEffect(() => {
    const fetchFields = async () => {
      const response = await fetchCustomPropertiesOptions();
      const fieldsToOmit = ['created_by', 'created', 'updated', 'updated_by'];
      const fieldsOptions = response.data.actions.POST;
      setFields(omit(fieldsOptions, fieldsToOmit));
    };
    if (isEmpty(fields)) fetchFields();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchCustomProperty(customPropertyId);
      const fieldTypeChoices = fields.field_type?.choices || [];
      setFieldsData({
        ...data,
        field_type: fieldTypeChoices.find(({ value }) => value === data.field_type),
      });
    };
    if (isEdit) fetchData();
  }, [customPropertyId]);

  const handleOnChange = useCallback((name, value) => {
    if (name === 'name') {
      ref.current.isNameEdited = true;
    }

    const { isNameEdited } = ref.current;

    if (name === 'label' && !isNameEdited && !isEdit) {
      const nameValue = toSnakeCase(value);
      updateFieldValue('name', nameValue);
    }

    updateFieldValue(name, value);
  }, [updateFieldValue, isEdit]);

  const { data, fiedlErrors } = form;

  const formFields = useMemo(() => (isEmpty(fields) ? [] : fieldsResolver(fields))
    .filter(f => !f.read_only)
    .map(({ id, read_only, name, ...props }) => {
      if (name === 'field_type' && (!isEdit || !isEmpty(data[name]))) {
        return (
          <Fragment key={id}>
            <div className="field-header">
              <Icon
                colorName="text-primary-500"
                bgColorName="bck-primary-100"
                name="CustomProperties"
              />
              <Space size={16} />
              <span className="tc-paragraph-strong">{text.FIELD_ATTRIBUTES}</span>
            </div>
            <Space size={18} />
            <Field
              {...props}
              name={name}
              disabled={read_only || (isEdit && !editableFields.includes(name))}
              formId="customProperties"
            />
          </Fragment>
        );
      }
      return (
        <Field
          {...props}
          name={name}
          disabled={read_only || (isEdit && !editableFields.includes(name))}
          formId="customProperties"
          onChange={(name === 'label' || name === 'name') && handleOnChange}
          key={id}
        />
      );
    }), [fields, isEdit]);

  const title = (isEdit && `Edit ${data?.label}`) || text.CREATE_CUSTOM_PROPERTY;
  return (
    <Sidepanel
      title={title}
      type="info"
      icon="Pen"
      visible={visible}
      onCloseIconClick={close}
      footerRender={() => (
        <CustomFooter
          hasErrors={!isEmpty(fiedlErrors)}
          isEdit={isEdit}
          execute={isEdit ? edit : create}
          cancel={close}
        />
      )}
    >
      <Space size={18} />
      {formFields}
      {!isEdit && <InfoBox type="warning">{text.WARNING_MESSAGE}</InfoBox>}
    </Sidepanel>
  );
};

CreateEditCustomProperty.propTypes = propTypes;
CreateEditCustomProperty.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  setFieldsErrors: stores.forms.customProperties.setFieldsErrors,
  updateFieldValue: stores.forms.customProperties.updateFieldValue,
  form: stores.forms.customProperties,
  resetFieldsData: stores.forms.customProperties.resetFieldsData,
  setFieldsData: stores.forms.customProperties.setFieldsData,
  validateForm: stores.forms.customProperties.validateForm,
}))(observer(CreateEditCustomProperty));
