import stores from 'App/rootStore';

const buildBulkParams = (selectedItems, selectedAll, tableKey) => {
  if (selectedAll) {
    return { filters: stores.tables[tableKey].filters.query };
  }
  return { ids: selectedItems.map(item => item.id) };
};

export default buildBulkParams;
