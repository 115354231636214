import React, { PureComponent } from 'react';

import Page from 'App/components/Page';
import SavedFiltersTable from 'Settings/Sections/SavedFilters/components/SavedFiltersTable';

const text = {
  SYSTEM_LABEL: 'Settings',
  TITLE: 'Saved filters',
};

const bread = [
  { label: text.SYSTEM_LABEL, route: '/settings' },
  { label: text.TITLE, route: '/settings/saved-filters' },
];

class DocumentTypesPage extends PureComponent {
  render() {
    return (
      <Page bread={bread} title={text.TITLE} style={{ margin: '0 20px' }}>
        <SavedFiltersTable />
      </Page>
    );
  }
}

export default DocumentTypesPage;
