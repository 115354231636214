import React, { useState, useEffect } from 'react';
import { Select, Button, Space, Icon, Dropdown } from 'tc-biq-design-system';
import { func, string, bool } from 'prop-types';

const liteHeaderProps = {
  condition: string.isRequired,
  updateCondition: func.isRequired,
  addCondition: func.isRequired,
  defaultConditionValue: string,
};

const liteHeaderDefaultProps = {
  defaultConditionValue: '',
};

const defaultHeaderProps = {
  ...liteHeaderProps,
  removeCondition: func.isRequired,
  initial: bool.isRequired,
  addConditionGroup: func.isRequired,
  noRules: bool.isRequired,
  isEventBased: bool.isRequired,
};

const headerProps = {
  ...defaultHeaderProps,
  lite: bool,
};

const defaultProps = {
  lite: false,
};

const text = {
  MATCH: 'Match',
  ADD_RULE: 'Add condition',
  ADD_GROUP: 'Add subgroup',
  AND: 'All conditions',
  OR: 'At least one condition',
  REMOVE: 'Remove group',
  REMOVE_ALL: 'Remove all',
  EVENT: 'Event',
  ATTRIBUTE: 'Attribute',
};

const CONDITION_OPTIONS = [
  { displayName: text.AND, value: 'AND' },
  { displayName: text.OR, value: 'OR' },
];

const getConditionValue = (condition, defaultValue) => {
  if (defaultValue) {
    return CONDITION_OPTIONS.find(option => option.value === defaultValue);
  }

  return condition ? { displayName: text[condition], value: condition } : CONDITION_OPTIONS[0];
};

const ConditionSelect = ({ condition, updateCondition, defaultValue }) => {
  const value = getConditionValue(condition, defaultValue);
  const [selectedCondition, selectCondition] = useState(value);
  const onChange = (selected) => {
    updateCondition(selected.value);
    selectCondition(selected);
  };

  useEffect(() => {
    if (defaultValue) updateCondition(defaultValue);
  }, [defaultValue]);

  return (
    <Select
      style={{ width: '200px' }}
      type="single"
      options={CONDITION_OPTIONS}
      onChange={onChange}
      labelKey="displayName"
      valueKey="value"
      value={selectedCondition}
      clearable={false}
      disabled={!!defaultValue}
    />
  );
};

ConditionSelect.propTypes = {
  condition: string.isRequired,
  updateCondition: func.isRequired,
  defaultValue: string,
};

ConditionSelect.defaultProps = { defaultValue: null };

const AddConditionSelect = ({ addCondition }) => {
  const customHeader = toggle => (
    <Button size="small" icon="CaretDown" iconPosition="right" onClick={toggle}>
      {text.ADD_RULE}
    </Button>
  );

  const addAttribute = () => addCondition({ is_attribute: true });

  const list = [
    { label: text.EVENT, onClick: addCondition },
    { label: text.ATTRIBUTE, onClick: addAttribute },
  ];

  return <Dropdown customHeader={customHeader} list={list} />;
};

AddConditionSelect.propTypes = { addCondition: func.isRequired };

const AddCondition = ({ addCondition }) => (
  <Button size="small" icon="Plus" onClick={addCondition}>
    {text.ADD_RULE}
  </Button>
);

AddCondition.propTypes = { addCondition: func.isRequired };

const AddGroup = ({ addGroup, noRules }) => (
  <Button disabled={noRules} color="ghost" size="small" icon="Plus" onClick={addGroup}>
    {text.ADD_GROUP}
  </Button>
);

AddGroup.propTypes = { addGroup: func.isRequired, noRules: bool.isRequired };

const RemoveCondition = ({ removeCondition, initial }) => (
  <div className="fiq-condition-group__remove-all" onClick={removeCondition}>
    <Icon size="small" name="Delete" colorName="text-neutral-600" />
    <Space size={4} />
    <div className="text-neutral-600">{initial ? text.REMOVE_ALL : text.REMOVE}</div>
  </div>
);

RemoveCondition.propTypes = { removeCondition: func.isRequired, initial: bool.isRequired };

const ConditionGroupLiteHeader = ({
  condition,
  updateCondition,
  addCondition,
  defaultConditionValue,
}) => (
  <div className="fiq-condition-group__header">
    <div className="fiq-condition-group__condition">
      <ConditionSelect
        condition={condition}
        updateCondition={updateCondition}
        defaultValue={defaultConditionValue}
        lite
      />
    </div>
    <div className="fiq-condition-group__add-remove">
      <AddCondition addCondition={addCondition} />
    </div>
  </div>
);

ConditionGroupLiteHeader.propTypes = liteHeaderProps;
ConditionGroupLiteHeader.defaultProps = liteHeaderDefaultProps;

const ConditionGroupDefaultHeader = ({
  condition,
  updateCondition,
  addCondition,
  addConditionGroup,
  removeCondition,
  initial,
  noRules,
  isEventBased,
  defaultConditionValue,
}) => (
  <div className="fiq-condition-group__header">
    <div className="fiq-condition-group__condition">
      <div>{text.MATCH}</div>
      <Space size={8} />
      <ConditionSelect
        condition={condition}
        updateCondition={updateCondition}
        defaultValue={defaultConditionValue}
      />
    </div>
    <div className="fiq-condition-group__add-remove">
      {isEventBased ? (
        <AddConditionSelect addCondition={addCondition} />
      ) : (
        <AddCondition addCondition={addCondition} />
      )}
      <Space size={8} />
      <AddGroup addGroup={addConditionGroup} noRules={noRules} />
      <Space size={24} />
      <RemoveCondition removeCondition={removeCondition} initial={initial} />
    </div>
  </div>
);

ConditionGroupDefaultHeader.propTypes = defaultHeaderProps;

const ConditionGroupHeader = ({ lite, ...props }) => (
  !lite ? <ConditionGroupDefaultHeader {...props} /> : <ConditionGroupLiteHeader {...props} />);

ConditionGroupHeader.propTypes = headerProps;
ConditionGroupHeader.defaultProps = defaultProps;

export default ConditionGroupHeader;
