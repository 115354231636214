import React from 'react';
import { Select } from 'tc-biq-design-system';
import { string } from 'prop-types';

import loadOptions from 'App/services/utilities/loadOptions';

const propTypes = {
  listUrl: string,
  queryKey: string,
};

const defaultProps = {
  listUrl: '',
  queryKey: '',
};

const MultipleSelect = ({ listUrl, queryKey, ...props }) => (
  <Select
    {...props}
    isMulti
    clearable={false}
    loadOptions={loadOptions(listUrl, queryKey)}
    async={!!listUrl}
  />
);

MultipleSelect.defaultProps = defaultProps;
MultipleSelect.propTypes = propTypes;

export default MultipleSelect;
