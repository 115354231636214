import React from 'react';

import Page from 'App/components/Page';
import CampaignForm from 'Marketing/components/CampaignForm';

const text = {
  TITLE: 'Create Campaign',
};

const bread = [{ label: text.TITLE, route: '/marketing/campaigns' }];

const CampaignCreatePage = () => (
  <Page bread={bread} title={text.TITLE}>
    <CampaignForm />
  </Page>
);

export default CampaignCreatePage;
