import store from 'App/rootStore';

function openOverlay(key, data) {
  store.overlayStore.open(key, data);
}

function closeOverlay(key) {
  store.overlayStore.close(key);
}

export {
  openOverlay,
  closeOverlay,
};
