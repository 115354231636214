/* eslint-disable max-len */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { find, isEmpty } from 'lodash';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import SettingsPanel from 'Settings/components/SettingsPanel';
import { NoPermissionPage } from 'App/components/NoContentPage';

const propTypes = {
  settings: object.isRequired,
};

const bread = [{ label: 'Settings', route: '/settings' }];

const text = {
  SETTINGS: 'Settings',
  USER_MANAGEMENT: 'User management',
  SYSTEM_MANAGEMENT: 'System management',
  CONFIGURATION: 'Configuration',
  CUSTOM_PROPERTIES: 'Custom properties',
};

const SettingsPage = ({ settings }) => {
  if (!settings) return <NoPermissionPage />;
  const [UserManagement, SystemManagement, Configuration] = settings.sections;
  return (
    <Page title={text.SETTINGS} style={{ margin: '0 20px' }} bread={bread}>
      <div style={{ width: '60%', margin: '0 auto' }}>
        {!isEmpty(UserManagement) && <SettingsPanel title={text.USER_MANAGEMENT} sections={UserManagement} />}
        {!isEmpty(SystemManagement) && <SettingsPanel title={text.SYSTEM_MANAGEMENT} sections={SystemManagement} />}
        {!isEmpty(Configuration) && (
          <SettingsPanel title={text.CONFIGURATION} sections={Configuration} />
        )}
      </div>
    </Page>
  );
};

SettingsPage.propTypes = propTypes;

export default inject(stores => ({
  settings: find(stores.navigator.visibleItems, { route: '/settings' }),
}))(observer(SettingsPage));
