import React, { useEffect, useState } from 'react';
import { object, func, number, bool } from 'prop-types';
import { Sidepanel, notifier, Radio } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import run from 'App/services/utilities/run';
import { editContactBasicInfo } from 'Contacts/services/contactsService';
import SidepanelFooter from 'App/components/SidepanelFooter';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import moment from 'moment';

const SIDEPANEL_ID = 'EDIT_CUSTOM_PROPERTY';

const text = {
  DISCARD: 'Discard changes',
  SAVE: 'Save changes',
  EDIT_SUCCESS: 'Successfully edited property',
  TRUE: 'true',
  FALSE: 'false',
};

export const openEditCustomPropertySidepanel = ({ field, name, value, type }) => {
  openOverlay(SIDEPANEL_ID, { field, name, value, type });
};

export const closeEditCustomPropertySidepanel = () => {
  closeOverlay(SIDEPANEL_ID);
};

const footerRender = (submit, onClose, disableSubmit) => () => (
  <SidepanelFooter
    execute={submit}
    close={onClose}
    disableSubmit={disableSubmit}
    buttonLabels={{ confirm: text.SAVE, cancel: text.DISCARD }}
    confirmColor="primary"
  />
);

const EditCustomPropertySidepanel = ({
  visible,
  parameters,
  contactOptions,
  contactId,
  setContactData,
}) => {
  const [propertyValue, setValue] = useState(null);
  const { field, name, value, type } = parameters;
  const formatedField = fieldsResolver(contactOptions).find(item => item.name === name);

  useEffect(() => {
    if (!value) {
      setValue(null);
    } else if (type === 'date') {
      setValue(moment(value).format('YYYY-MM-DD'));
    } else {
      setValue(`${value}`);
    }
  }, [value]);

  const handleOnChange = (fieldName, fieldValue) => {
    setValue(fieldValue);
  };

  const submit = async () => {
    const payload = {
      [name]: propertyValue,
    };
    const [err, response] = await run(editContactBasicInfo(contactId, payload));
    if (err) notifier.error(err);
    if (response) {
      setContactData(response);
      notifier.success(text.EDIT_SUCCESS);
      onClose();
    }
  };

  const onClose = () => {
    setValue(null);
    closeEditCustomPropertySidepanel();
  };

  return (
    <Sidepanel
      visible={visible}
      footerRender={footerRender(submit, onClose, false)}
      title={field}
      onCloseIconClick={onClose}
      icon="CustomProperties"
    >
      {type === 'boolean' ? (
        <Radio.Group name="name" value={propertyValue} onChange={event => handleOnChange('', event.target.value)}>
          <Radio value="true" checked={propertyValue === 'true'}>
            {text.TRUE}
          </Radio>
          <Radio value="false" checked={propertyValue === 'false'}>
            {text.FALSE}
          </Radio>
        </Radio.Group>
      ) : (
        <Field
          {...formatedField}
          onChange={handleOnChange}
          value={propertyValue}
        />
      )}
    </Sidepanel>

  );
};

EditCustomPropertySidepanel.propTypes = {
  parameters: object,
  visible: bool,
  contactOptions: object.isRequired,
  contactId: number,
  setContactData: func,
};

EditCustomPropertySidepanel.defaultProps = {
  parameters: {},
  visible: false,
  contactId: null,
  setContactData: null,
};

export default inject(stores => ({
  parameters: stores.overlayStore.overlay.parameters,
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  contactOptions: stores.contact.profile.contactOptions,
  setContactData: stores.contact.profile.setContactData,
}))(observer(EditCustomPropertySidepanel));
