export default {
  contacts_contact: { background_color: 'primary-100', icon_color: 'primary-500', name: 'User' },
  contacts_note: { background_color: 'neutral-300', icon_color: 'neutral-700', name: 'Notes' },
  contacts_contactsignupinfo: {
    background_color: 'status03-100',
    icon_color: 'status03-500',
    name: 'User',
  },
  contacts_nationalityidentifier: {
    background_color: 'status03-100',
    icon_color: 'status03-500',
    name: 'User',
  },
  contacts_tagbinding: {
    background_color: 'status03-100',
    icon_color: 'status03-500',
    name: 'Tag',
  },
  contacts_kyc: { background_color: 'status03-100', icon_color: 'status03-500', name: 'User' },
  communications_emaillog: {
    background_color: 'status01-100',
    icon_color: 'status01-500',
    name: 'Mail',
  },
  communications_smslog: {
    background_color: 'status01-100',
    icon_color: 'status01-500',
    name: 'SingleChat',
  },
  communications_whatsapplog: {
    background_color: 'status01-100',
    icon_color: 'status01-500',
    name: 'WhatsApp',
  },
  communications_calllog: {
    background_color: 'status01-100',
    icon_color: 'status01-500',
    name: 'Phone',
  },
  documents_document: {
    background_color: 'status03-100',
    icon_color: 'status03-500',
    name: 'Duplicate',
  },
  tasks_task: {
    background_color: 'status03-100',
    icon_color: 'status03-500',
    name: 'CalendarCreate',
  },
  finances_account: { background_color: 'primary-100', icon_color: 'primary-500', name: 'Account' },
  finances_wallet: { background_color: 'primary-100', icon_color: 'primary-500', name: 'Account' },
  finances_withdrawrequest: {
    icon_color: 'status04-500',
    background_color: 'status04-100',
    name: 'Withdrawal',
  },
  finances_paymenttransaction: {
    background_color: 'primary-100',
    icon_color: 'primary-500',
    name: 'Payments',
  },
  finances_tradetransaction: {
    background_color: 'primary-100',
    icon_color: 'primary-500',
    name: 'InternalTransaction',
  },
  defaultIcon: { background_color: 'neutral-300', icon_color: 'neutral-700', name: 'Info' },
};
