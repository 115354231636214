/* eslint react/prop-types: 0 */
import React from 'react';
import { Checkbox } from 'tc-biq-design-system';
import { bool } from 'prop-types';

const propTypes = {
  value: bool.isRequired,
};

const CheckboxCell = (onToggle, hasAccess) => ({ value, data }) => (
  <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Checkbox disabled={!hasAccess} onChange={newVal => onToggle(data, newVal)} checked={value} />
  </div>
);

CheckboxCell.propTypes = propTypes;

export default CheckboxCell;
