import React, { PureComponent } from 'react';

import Page from 'App/components/Page';
import PositionsTable, { tableConfig } from 'Transactions/components/PositionsTable';

const bread = [{ label: 'Transactions' }, { label: 'Positions', route: '/transactions/positions' }];
const exportDataOptions = { permission: 'finances_tradeposition', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

const text = {
  POSITIONS: 'Positions',
};

class PositionsPage extends PureComponent {
  render() {
    return (
      <Page bread={bread} title={text.POSITIONS} style={{ margin: '0 20px' }} exportDataOptions={exportDataOptions}>
        <PositionsTable />
      </Page>
    );
  }
}

export default PositionsPage;
