import React, { PureComponent, Fragment } from 'react';
import { Select, Space, Icon, Pill } from 'tc-biq-design-system';
import compose from 'lodash/fp/compose';
import { isEmpty } from 'lodash';
import { object, func, array, bool } from 'prop-types';

import FilterInput from 'App/components/Filters/FilterInput';
import getRelatedModels from 'App/services/utilities/getRelatedModels';

import './ConditionRule.scss';

const propTypes = {
  fields: object.isRequired,
  removeCondition: func.isRequired,
  rule: object,
  parentIdentifier: array.isRequired,
  updateConditionRule: func.isRequired,
  isEventBased: bool,
};

const defaultProps = {
  rule: {},
  isEventBased: false,
};

const text = {
  PLACEHOLDER: 'Select field',
  ATTRIBUTE: 'Attribute',
};

// eslint-disable-next-line
const FieldInput = ({ fields, selectedField, onChange }) => {
  const fieldOptions = Object.keys(fields)
    .sort()
    .reverse()
    .map(key => ({
      label: fields[key].label,
      value: key,
      type: fields[key].type,
    }));

  return (
    <div className="fiq-condition-rule__field">
      <Select
        placeholder={text.PLACEHOLDER}
        clearable={false}
        type="search"
        valueKey="value"
        value={selectedField}
        options={fieldOptions}
        onChange={onChange}
        joinValues
      />
    </div>
  );
};

// eslint-disable-next-line
const OperatorField = ({ selectedOperator, operators, onChange }) => {
  return (
    <div className="fiq-condition-rule__operator">
      <Select
        placeholder="operator"
        clearable={false}
        type="search"
        valueKey="value"
        value={selectedOperator}
        onChange={onChange}
        options={operators}
        joinValues
      />
    </div>
  );
};

const formatInitialValues = (rule, fieldDef) => {
  const { field, operator, value } = rule;
  return {
    field: {
      label: fieldDef.label,
      value: field,
      type: fieldDef.type,
    },
    operator: {
      label: fieldDef.lookups[operator],
      value: operator,
      related_models: getRelatedModels(fieldDef.related_models, fieldDef.related_model),
    },
    value,
  };
};

const formatLookups = (selectedField, allFields) => {
  const { lookups, related_models, related_model } = allFields[selectedField];
  return {
    lookups,
    related_models: getRelatedModels(related_models, related_model),
  };
};

const formatOperatorsOptions = ({ lookups, related_models }) => {
  const queryOptions = Object.keys(lookups).map(key => ({
    label: lookups[key],
    value: key,
    related_models,
  }));
  return queryOptions;
};

const formatOperators = compose(formatOperatorsOptions, formatLookups);
class ConditionRule extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedField: null,
      selectedOperator: null,
      operators: [],
      filterValue: '',
    };
  }

  componentDidMount() {
    this.setInitialValues();
  }

  componentDidUpdate(prevProps) {
    const { rule } = this.props;
    const { selectedField } = this.state;
    if (
      (!prevProps.rule && rule)
      || rule.field !== prevProps.rule.field
      || (rule.field && !selectedField)
    ) {
      this.setInitialValues();
    }
  }

  componentWillUnmount() {
    this.resetConditionRule();
  }

  setField = (selectedField) => {
    const { fields } = this.props;
    const operators = formatOperators(selectedField.value, fields);
    this.setState(
      { selectedField, operators, selectedOperator: null, filterValue: '' },
      this.updateConditionField,
    );
  };

  setOperator = (selectedOperator) => {
    this.setState({ selectedOperator, filterValue: '' }, this.updateConditionField);
  };

  setFilterValue = (value) => {
    this.setState({ filterValue: value }, this.updateConditionField);
  };

  setInitialValues = () => {
    const { fields, rule } = this.props;
    const fieldDef = rule ? fields[rule.field] : null;
    if (fieldDef && !isEmpty(fields)) {
      const { field, operator, value } = formatInitialValues(rule, fieldDef);
      this.setField(field);
      this.setOperator(operator && operator.value ? operator : null);
      this.setFilterValue(value);
    }
    // NOTE: If there's only one field available, we pre-select that field
    // automatically for better UX.
    if (!fieldDef && !isEmpty(fields) && Object.keys(fields).length === 1) {
      const { id, type, label } = fields[Object.keys(fields)[0]];
      this.setField({ label, type, value: id });
    }
  };

  updateConditionField = () => {
    const { parentIdentifier, updateConditionRule } = this.props;
    const { selectedField, selectedOperator, filterValue } = this.state;
    const operator = selectedOperator ? selectedOperator.value : null;
    updateConditionRule(
      {
        field: selectedField.value,
        type: selectedField.type,
        operator,
        value: filterValue,
      },
      parentIdentifier,
    );
  };

  resetConditionRule = () => {
    this.setState({
      selectedField: null,
      selectedOperator: null,
      operators: [],
      filterValue: '',
    });
  };

  removeRule = () => {
    const { removeCondition, parentIdentifier } = this.props;
    this.resetConditionRule();
    removeCondition(parentIdentifier);
  };

  render() {
    const { selectedField, selectedOperator, operators, filterValue } = this.state;
    const { fields, rule, isEventBased } = this.props;

    return (
      <div className="fiq-condition-rule">
        {isEventBased && (
          <Fragment>
            <Pill big style={{ marginRight: '0' }}>
              {text.ATTRIBUTE}
            </Pill>
            <Space size={12} />
          </Fragment>
        )}
        <FieldInput
          fields={fields}
          selectedField={selectedField}
          onChange={this.setField}
          initialValue={rule.field}
        />
        <Space size={12} />
        {!!selectedField && (
          <OperatorField
            selectedOperator={selectedOperator}
            operators={operators}
            onChange={this.setOperator}
          />
        )}
        <Space size={12} />
        {!!selectedOperator && (
          <div className="fiq-condition-rule__filter">
            <FilterInput
              value={filterValue}
              selectedField={selectedField}
              fields={fields}
              operator={selectedOperator}
              onChange={this.setFilterValue}
              relatedModels={selectedOperator.related_models}
              fieldLabel={selectedField.label}
            />
            <Space size={12} />
          </div>
        )}
        <div onClick={this.removeRule} style={{ cursor: 'pointer' }}>
          <Icon name="Delete" size="small" />
        </div>
      </div>
    );
  }
}

ConditionRule.propTypes = propTypes;
ConditionRule.defaultProps = defaultProps;

export default ConditionRule;
