import http from 'App/services/http';

const updateRule = (id, payload) => http.put(`/automations/rules/${id}/`, payload);

const createRule = payload => http.post('/automations/rules/', payload);

const deleteRule = id => http.delete(`/automations/rules/${id}/`);

const fetchRuleOptions = () => http.options('/automations/rules/');

const fetchRule = id => http.get(`/automations/rules/${id}/`);

const fetchActions = () => http.get('/automations/actions/');

export { updateRule, fetchRule, createRule, deleteRule, fetchRuleOptions, fetchActions };
