import uniq from 'lodash/uniq';

/*
  Helper to format loadOptions callback in Async Select component
*/

import MODELS_URL from 'App/enums/relatedModelsUrls';
import http from 'App/services/http';
import extractDisplayName from 'App/services/utilities/extractDisplayName';

export const extractSearchPaths = (models) => {
  if (!models || !models.length) return null;
  return uniq(
    models.map((model) => {
      const url = MODELS_URL[model.value];
      return url || `/${model.value.split('_')[0]}/`;
    }),
  );
};

const loadOptions = (filterParams, relatedModels, onError) => async (value) => {
  const searchPaths = extractSearchPaths(relatedModels);
  if (searchPaths) {
    const promises = searchPaths.map(path => http.get(`${path}?search=${value}`, { params: filterParams }));
    const responses = await Promise.allSettled(promises);
    let options = [];
    responses.forEach((response, index) => {
      if (response.reason?.response?.status === 403) onError();
      if (response.status === 'fulfilled') {
        options = [
          ...options,
          ...response.value.data.results.map(result => ({
            ...result,
            value: result.id,
            display_name: extractDisplayName(result),
            relatedModel: relatedModels[index].value,
          })),
        ];
      }
    });
    return options;
  }
  return [];
};

export default loadOptions;
