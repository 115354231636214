import React from 'react';
import { Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import CagTable from 'Settings/Sections/ContactAccessGroup/components/CagTable';

const propTypes = {
  history: object.isRequired,
};

const bread = [
  { label: 'Settings', route: '/settings' },
  { label: 'Contact access groups', route: '/settings/contact-access-groups' },
];

const text = {
  CAG_LABEL: 'Contact access groups',
  HEADER_ACTION: 'Create access group',
};

const headerActions = history => () => (
  <Button onClick={() => history.push('/settings/contact-access-groups/create-group')}>
    {text.HEADER_ACTION}
  </Button>
);

const ContactAccessGroupPage = ({ history }) => (
  <Page
    title={text.CAG_LABEL}
    style={{ margin: '0 20px' }}
    bread={bread}
    headerActions={headerActions(history)}
  >
    <CagTable />
  </Page>
);

ContactAccessGroupPage.propTypes = propTypes;

export default withRouter(ContactAccessGroupPage);
