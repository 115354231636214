import { find } from 'lodash';

import { extractSearchPaths } from 'App/services/utilities/loadOptions';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import http from 'App/services/http';

const fetchInitialSelectValue = async ({ value, relatedModels, updateInitialValue }) => {
  const searchPaths = extractSearchPaths(relatedModels);
  const isArray = Array.isArray(value);
  const path = isArray
    ? `${searchPaths[0]}?id__in=${value.join(',')}&limit=${Math.min(value.length, 200)}`
    : `${searchPaths[0]}?id=${value}`;
  // NOTE: We include deleted objects in the initial fetch in order
  // to populate the input only.
  const response = await http.get(`${path}&include_deleted=true`);
  let data;
  const { results } = response.data;
  if (isArray) {
    data = results.map(record => ({
      ...record,
      value: record.id,
      display_name: extractDisplayName(record),
    }));
  } else {
    data = { ...results[0], value: results[0].id, display_name: extractDisplayName(results[0]) };
  }
  updateInitialValue(data);
};

const isObjectValues = value => Array.isArray(value) && value.every(element => typeof element === 'object' && element !== null);

const getValue = (value, options, type) => {
  if (value && value.display_name && value.value) return value;
  if (value && isObjectValues(value)) {
    return value.map(record => ({
      value: record.id || record.value,
      display_name: extractDisplayName(record),
    }));
  }
  if (value && type === 'multi') {
    return value.map(element => find(options, { value: element }));
  }
  if (options && options.length) return find(options, { value });
  return value;
};

const checkIDValues = (value) => {
  if (Array.isArray(value) && value.every(record => Number.isInteger(record))) {
    return true;
  }
  if (Number.isInteger(value)) {
    return true;
  }
  return false;
};

const setInitialValue = async ({
  value,
  options,
  relatedModels,
  updateInitialValue,
  valueFormatter,
  type,
}) => {
  const isAsyncInitialFetch = relatedModels && value && checkIDValues(value);
  if (isAsyncInitialFetch) {
    await fetchInitialSelectValue({ value, relatedModels, updateInitialValue });
  } else {
    const formattedValue = valueFormatter
      ? valueFormatter(getValue(value, options))
      : getValue(value, options, type);
    updateInitialValue(formattedValue);
  }
};

export default setInitialValue;
