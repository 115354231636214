/* eslint react/prop-types: 0 */
import React from 'react';
import { Toggle } from 'tc-biq-design-system';
import { bool } from 'prop-types';

import './ToggleCell.scss';

const propTypes = {
  value: bool.isRequired,
};

const ToggleCell = (onToggle, hasAccess) => ({ value, data }) => (
  <Toggle
    className="fiq-toggle-override"
    disabled={!hasAccess}
    onChange={newVal => onToggle(data, newVal)}
    checked={value}
  />
);

ToggleCell.propTypes = propTypes;

export default ToggleCell;
