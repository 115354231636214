import moment from 'moment';

const formatMetrics = data => data.reduce((acc, elem) => {
  acc[elem.name] = {
    ...elem,
    dimensions: formatDimensions(elem.dimensions),
  };
  return acc;
}, {});

const formatDimensions = dimensions => dimensions.map(dimension => ({
  value: dimension,
  label:
    dimension[0].toUpperCase()
    + dimension
      .slice(1)
      .split('_')
      .join(' '),
}));

const setGranularity = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const duration = moment.duration(end.diff(start));
  const days = duration.asDays();
  if (days < 8) return 'day';
  if (days < 31) return 'week';
  return 'month';
};

const calculateFinanceNumericsData = (
  incomingData,
  incomingOldData,
  outgoingData,
  outgoingOldData,
) => {
  const values = {
    ...calculateIncomingValues(incomingData, outgoingData.total_amount),
    ...calculateOutgoingValues(outgoingData),
  };
  const oldValues = {
    ...calculateIncomingValues(incomingOldData, outgoingOldData.total_amount),
    ...calculateOutgoingValues(outgoingOldData),
  };

  const currency = (incomingData.length && incomingData[0].currency)
    || (incomingOldData.length && incomingOldData[0].currency)
    || outgoingData.currency || outgoingOldData.currency || null;

  return Object.keys(values).reduce(
    (acc, key) => ({
      ...acc,
      [key]: buildNumeric(values[key], oldValues[key], currency),
    }),
    {},
  );
};

const calculateIncomingValues = (data, outgoingTotal) => {
  const sums = data.reduce(
    (acc, item) => {
      if (item.is_first_time_deposit) {
        acc.FTDTotal += +item.total_amount;
        acc.FTDCount += item.count;
        return acc;
      }
      acc.redepositsTotal += +item.total_amount;
      acc.redepositsCount += item.count;
      return acc;
    },
    { FTDCount: 0, redepositsCount: 0, FTDTotal: 0, redepositsTotal: 0 },
  );

  const incomingTotal = sums.FTDTotal + sums.redepositsTotal;
  const incomingCount = sums.FTDCount + sums.redepositsCount;
  const FTDAverage = getAverage(sums.FTDTotal, sums.FTDCount);
  const incomingAverage = getAverage(incomingTotal, incomingCount);
  const netIncome = incomingTotal - +outgoingTotal;

  return {
    ...sums,
    incomingTotal,
    incomingCount,
    incomingAverage,
    FTDAverage,
    netIncome,
  };
};

const calculateOutgoingValues = data => ({
  outgoingTotal: data.total_amount,
  outgoingCount: data.count,
  outgoingAverage:
    data.total_amount && data.count ? (+data.total_amount / data.count).toFixed(2) : 0,
});

const getAverage = (total, count) => {
  if (total && count) return (total / count).toFixed(2);
  return 0;
};

const buildNumeric = (value, oldValue, currency = 'USD') => ({
  value: value || 0,
  percentage: calculatePercentages(value, oldValue),
  isGrowing: +value > +oldValue,
  currency,
});

const calculatePercentages = (first_value, second_value) => {
  const firstValue = +first_value;
  const secondValue = +second_value;
  if (!secondValue && firstValue) return '100';
  if (firstValue === secondValue) return 0;
  return (((firstValue - secondValue) / (secondValue || 1)) * 100).toFixed(2);
};

const buildQueryForPreviousInterval = (body) => {
  const { query, ...metricValues } = body;
  const { start_of_interval, end_of_interval, ...queryValues } = query;
  return {
    ...metricValues,
    query: {
      ...buildPreviousInterval(start_of_interval, end_of_interval),
      ...queryValues,
    },
  };
};

const buildPreviousInterval = (startOfInterval, endOfInterval) => {
  const diffInDays = Math.abs(
    moment(startOfInterval, 'YYYY-MM-DD')
      .startOf('day')
      .diff(moment(endOfInterval, 'YYYY-MM-DD').startOf('day'), 'days'),
  ) + 1;

  const start_of_interval = moment(startOfInterval).subtract(diffInDays, 'd').format('YYYY-MM-DD');
  const end_of_interval = moment(startOfInterval).subtract(1, 'd').format('YYYY-MM-DD');

  return {
    start_of_interval,
    end_of_interval,
  };
};

export {
  calculateFinanceNumericsData,
  formatMetrics,
  setGranularity,
  buildQueryForPreviousInterval,
  buildNumeric,
  buildPreviousInterval,
};
